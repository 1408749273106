import React, { Fragment } from 'react';
import { ScrollView, View } from 'react-native';
import s from './styles';
import { TouchableItem, Text, FontIcon, Separator } from '../../../ReusableComponents/index';
import { colors, rs } from '../../../../styles';
import { NavigationService } from '../../../../services';

const ConditionItem = ({ condition, isSelected, onSelectCondition }) => (
  <Fragment>
    <TouchableItem
      style={[s.itemContainer, isSelected && s.activeItemContainer]}
      useOpacity={false}
      onPress={() => {
        onSelectCondition(condition.id);
        NavigationService.pop();
      }}
    >
      <Fragment>
        <View style={[rs.row, rs.marginBottom, rs.alignCenter]}>
          <Text large semiBold style={rs.flexGrow}>
            {condition.title}
          </Text>
          {isSelected && (
            <FontIcon
              name="checkmark_filled"
              color={colors.secondary}
              size={26}
              inCircle={false}
              withoutRTLScale
            />
          )}
        </View>
        <Text medium color={colors.gray} style={s.descriptionText}>
          {condition.subtitle}
        </Text>
      </Fragment>
    </TouchableItem>
    <View style={s.separator} />
  </Fragment>
);

const Conditions = ({ route }) => {
  const conditions = route.params.conditions;
  const selectedConditionId = route.params.selectedConditionId;
  const onSelectCondition = route.params.onSelectCondition;

  return (
    <ScrollView style={[rs.flex, rs.backgroundWhite]}>
      {conditions.map((condition) => (
        <ConditionItem
          key={condition.id}
          condition={condition}
          isSelected={condition.id === selectedConditionId}
          onSelectCondition={onSelectCondition}
        />
      ))}
    </ScrollView>
  );
};

export default Conditions;
