import { createSelector } from 'reselect';
import * as R from 'ramda';

export const getCommunityCities = createSelector(
  [R.pathOr([], ['communityCities', 'cityIds']), R.pathOr({}, ['communityCities', 'cityEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export default {
  getCommunityCities,
};
