import React, { Fragment, useEffect, useState } from 'react';
import { KeyboardAvoidingView, View, Linking } from 'react-native';
import * as RNLocalize from 'react-native-localize';
import { connect } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import FeatherIcon from 'react-native-vector-icons/Feather';
import T from 'prop-types';
import { authOperations } from '../../../../store/auth';
import s from './styles';
import { colors, dimensions, reusableStyles, rs } from '../../../../styles';
import { isRTL } from '../../../../utils/rtlHelper';
import { Container, Text, TouchableItem, Input, Image } from '../../../ReusableComponents';

import strings from '../../../../localization';
import SelectCountryModal from './SelectCountryModal';
import NavigationService from '../../../../services/NavigationService';
import { isIos } from '../../../../utils/detectDevice';
import screens from '../../../../navigation/screens';
import { getPhoneNumberCountry } from '../../../../utils/localizationHelper';
import {
  PRIVACY_POLICY_URL_BR,
  PRIVACY_POLICY_URL_EN,
  PRIVACY_POLICY_URL_HE,
  TERMS_AND_CONDITIONS_URL_BR,
  TERMS_AND_CONDITIONS_URL_EN,
  TERMS_AND_CONDITIONS_URL_HE,
} from '../../../../api/urls';

const getTermsAndConditionsUrl = (langaugeCode) => {
  switch (langaugeCode) {
    case 'pt':
      return TERMS_AND_CONDITIONS_URL_BR;
    case 'he':
      return TERMS_AND_CONDITIONS_URL_HE;
    default:
      return TERMS_AND_CONDITIONS_URL_EN;
  }
};


const getPrivacyPolicyUrl = (localeCode) => {
  switch (localeCode) {
    case 'pt':
      return PRIVACY_POLICY_URL_BR;
    case 'he':
      return PRIVACY_POLICY_URL_HE;
    default:
      return PRIVACY_POLICY_URL_EN;
  }
}

const Login = ({ navigation, signInWithPhoneNumber }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneNumberInternationalFormat, setPhoneNumberInternationalFormat] = useState(null);
  const [isChecked, setChecked] = useState(true);
  const [isValidPhoneNumber, setValidPhoneNumber] = useState(false);
  const languageCode = RNLocalize.getLocales()[0].languageCode;

  const isDisabledNext = !isChecked || !isValidPhoneNumber;

  const defaultCountry = getPhoneNumberCountry();
  const [country, setCountry] = useState(defaultCountry);

  useEffect(() => {
    navigation.setParams({
      isDisabledNext: isDisabledNext,
    });
  }, [isDisabledNext]);

  useEffect(() => {
    navigation.setParams({
      nextClicked: nextClicked,
    });
  }, [country, phoneNumberInternationalFormat]);

  useEffect(() => {
    onNumberChange(phoneNumber);
  }, [country]);

  const nextClicked = () => {
    const countryCode = country.dialCode;
    navigation.setParams({ isDisabledNext: true });
    // AnalyticsService.logEvent(analyticsEventTypes.onboarding_confirm_phone_number);
    NavigationService.navigate(screens.CodeVerification, {
      phoneNumber: phoneNumberInternationalFormat,
      onSendCodeAgain: () => signInWithPhoneNumber(phoneNumberInternationalFormat, countryCode),
    });

    signInWithPhoneNumber(phoneNumberInternationalFormat, countryCode);
  };

  const onNumberChange = (value) => {
    setPhoneNumber(value);

    const numberWithCode = `+${country.dialCode}${value}`;

    const parsedPhoneNumber = parsePhoneNumberFromString(numberWithCode);

    if (parsedPhoneNumber) {
      const formattedPhoneNumber = parsedPhoneNumber.formatInternational().replace(/ /g, '');
      setPhoneNumberInternationalFormat(formattedPhoneNumber);
      const isNewNumberValid = parsedPhoneNumber.isValid();
      if (isValidPhoneNumber !== isNewNumberValid) {
        setValidPhoneNumber(isNewNumberValid);
      }
    }
  };

  const onShowModal = () => {
    setModalVisible(true);
  };

  const onHideModal = () => {
    setModalVisible(false);
  };

  const onOpenPrivacy = () => {
    Linking.openURL(getPrivacyPolicyUrl(languageCode));
  };

  const onOpenTerms = () => {
    Linking.openURL(getTermsAndConditionsUrl(languageCode));
  };

  return (
    <Container>
      <KeyboardAvoidingView
        behavior={isIos ? 'padding' : null}
        style={[rs.flex, rs.webContainer]}
        keyboardVerticalOffset={isIos ? 65 : 0}
      >
        <Container style={rs.alignCenter}>
          <Image style={s.image} source={{ uri: 'phone' }} resizeMode="contain" />

          <Text medium alignCenter style={s.title}>
            {strings.login.enter_phone_number}
          </Text>

          <TouchableItem useOpacity={false} style={s.selectCountryButton} onPress={onShowModal}>
            <Fragment>
              <Text medium color={colors.blueLighter}>
                {country.name}
              </Text>
              <FeatherIcon
                name="chevron-right"
                color="#BFBFC1"
                style={reusableStyles.rtlScaleStyle}
                size={dimensions.iconSize}
              />
            </Fragment>
          </TouchableItem>

          <View style={s.inputContainer}>
            {!isRTL && (
              <View style={s.numberCodeContainer}>
                <Text large color={colors.black} style={rs.smallPaddingHorizontal}>
                  +{country.dialCode}
                </Text>
              </View>
            )}
            <Input
              style={s.input}
              autoFocus
              keyboardType="phone-pad"
              placeholder={strings.login.your_phone_number}
              maxLength={11}
              onChangeText={onNumberChange}
              textAlign="left"
              value={phoneNumber}
            />

            {isRTL && (
              <View style={s.numberCodeContainer}>
                <Text large color={colors.black} style={[rs.smallPaddingRight, rs.paddingLeft]}>
                  +{country.dialCode}
                </Text>
              </View>
            )}
          </View>

          <SelectCountryModal
            modalVisible={modalVisible}
            onHideModal={onHideModal}
            onChange={setCountry}
          />
        </Container>

        <View style={s.bottomContainer}>
          <Text xsmall>{strings.login.by_signing_up_you_agree}</Text>
          <View style={rs.row}>
            <TouchableItem onPress={onOpenTerms}>
              <Text xsmall color={colors.blueLight}>
                {strings.login.terms_and_conditions}
              </Text>
            </TouchableItem>
            <Text xsmall> {strings.login.and} </Text>
            <TouchableItem onPress={onOpenPrivacy}>
              <Text xsmall color={colors.blueLight}>
                {strings.login.privacy_policy}
              </Text>
            </TouchableItem>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

Login.propTypes = {
  signInWithPhoneNumber: T.func.isRequired,
};

export default connect(null, (dispatch) => ({
  signInWithPhoneNumber: (phoneNumber, countryCode) =>
    dispatch(authOperations.signInWithPhoneNumber(phoneNumber, countryCode)),
}))(Login);
