import { StyleSheet } from 'react-native';
import { colors, fontSizes, dimensions } from '../../../../../styles';

export default StyleSheet.create({
  contentContainer: {
    backgroundColor: colors.grayLighter,
  },
  sectionHeaderContainer: {
    height: 40,
    justifyContent: 'center',
    paddingHorizontal: dimensions.medium,
    width: '100%',
    backgroundColor: colors.grayLighter,
  },
  grayBackground: {
    backgroundColor: colors.grayLighter,
  },
  iosBounceBackground: {
    backgroundColor: colors.grayLighter,
    height: 1000,
    position: 'absolute',
    bottom: -1000,
    left: 0,
    right: 0,
  },
});
