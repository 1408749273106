import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';
import types from './types';

const initialState = {
  wallet: {
    // balance_amount: 133
    // group_id: 0
    // hold_balance: 2
    // id: 0
    // token_icon: "https://storage.googleapis.com/user-avatars-prod/Giveitt_1587451036989.png"
    // token_id: 0
  },
  rewardWallet: {
    // balance_amount: 133
    // group_id: 0
    // hold_balance: 2
    // id: 0
    // token_icon: "https://storage.googleapis.com/user-avatars-prod/Giveitt_1587451036989.png"
    // token_id: 0
  },
};

const communityInfoReducer = handleActions(
  {
    [types.SET_WALLETS]: mergeDeep(({ payload }, state) => ({
      wallet: payload.assembledWallet,
      rewardWallet: payload.rewardWallet,
    })),
    [types.UPDATE_WALLET_BALANCE]: mergeDeep(({ payload }) => ({
      wallet: {
        balance_amount: payload.balanceAmount,
      },
    })),
  },
  initialState,
);

export default communityInfoReducer;
