class FilterService {
  constructor() {
    this._selector = null;
  }

  init(ref) {
    if (this._selector) {
      return;
    }
    this._selector = ref;
  }

  destroy() {
    this._selector = null;
  }

  show(params) {
    this._selector.show(params);
  }

  hide() {
    this._selector.hide();
  }
}

const filterService = new FilterService();

export default filterService;
