import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';

import types from './types';

const initialState = {
  groupId: null,
  refundPolicyId: null,
};

const createLotReducer = handleActions(
  {
    [types.SAVE_GROUP_FOR_NEXT_LOT]: mergeDeep(({ payload }, state) => ({
      groupId: payload.groupId,
    })),
    [types.SAVE_REFUND_POLICY_FOR_NEXT_LOT]: mergeDeep(({ payload }, state) => ({
      refundPolicyId: payload,
    })),
  },
  initialState,
);

export default createLotReducer;
