import React, { useEffect } from 'react';
import { View, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Text, AutoWidthButton } from '../../ReusableComponents';
import s from './styles';
import strings from '../../../localization';
import { colors, rs } from '../../../styles';
import { AnalyticsService, NavigationService } from '../../../services';
import screens from '../../../navigation/screens';
import { isSmallDevice } from '../../../utils/detectDevice';
import { connect } from 'react-redux';
import { lotsOperations } from '../../../store/lots';
import * as analyticsEventTypes from '../../../constants/analyticsEventTypes';
import { isRTL } from '../../../utils/rtlHelper';
import { communityOperations } from '../../../store/communityInfo';
import { getIsUSDiscoveryZone, getSchoolExtension } from '../../../store/userInfo/selectors';
import { appOperations } from '../../../store/app';
import { homeOperations } from '../../../store/home';
import { getIsBrazilCommunity } from '../../../store/communityInfo/selectors';

const Welcome = ({
  route,
  schoolExtension,
  isUSDiscoveryZone,
  isBrazilCommunity,
  setFeedLotFiltersWithoutFetchingItems,
  getUserCommunity,
  fetchAppData,
  itemsStartLoading,
  fetchChatsData,
  getCommunityHomePage,
}) => {
  const rewardAmount = route.params?.rewardAmount ?? 0;

  useEffect(() => {
    onFetchData();
  }, []);

  const onLetsGetStarted = async () => {
    if (isUSDiscoveryZone) {
      setFeedLotFiltersWithoutFetchingItems({ schoolExtension });
    } else {
      setFeedLotFiltersWithoutFetchingItems({ isOnlyVerifiedSellers: !isBrazilCommunity });
    }

    AnalyticsService.logEvent(analyticsEventTypes.onboarding_welcome_start);
    NavigationService.reset(screens.AuthorizedApplication, {
      screen: screens.TabNavigator,
      params: { screen: screens.MarketplaceTab, params: { screen: screens.Marketplace } },
    });
  };

  const onFetchData = async () => {
    itemsStartLoading();
    // temporary fix, because home tab page is not rendering for some reasons
    await getUserCommunity();
    fetchAppData();
    fetchChatsData();
    getCommunityHomePage();
  };

  return (
    <SafeAreaView style={s.contentContainer}>
      <View style={s.titleContainer}>
        <Text bold style={s.titleText} alignCenter>
          {strings.login.welcome_to}
          <Text color={colors.violet} bold style={s.titleText}>
            {strings.main.app_name}
          </Text>
        </Text>
      </View>

      <Text large semiBold alignCenter style={rs.paddingHorizontal}>
        {strings.login.buy_without_spending_money}
      </Text>

      <Image
        style={s.image}
        source={{ uri: isSmallDevice ? 'welcome_small' : 'welcome' }}
        resizeMode="contain"
      />

      <Text headline bold style={[rs.bigMarginBottom, rs.paddingHorizontal]} alignCenter>
        {strings.login.you_received}{' '}
        <Text color={colors.blueLight} bold headline>
          {rewardAmount} {strings.invite_friends.shareitt_points}
        </Text>
        {isRTL ? ' ' : '\n'}
        {strings.login.as_gift}
      </Text>

      <AutoWidthButton title={strings.login.lets_get_started} onPress={onLetsGetStarted} />
    </SafeAreaView>
  );
};

export default connect(
  (state) => ({
    isUSDiscoveryZone: getIsUSDiscoveryZone(state),
    isBrazilCommunity: getIsBrazilCommunity(state),
    schoolExtension: getSchoolExtension(state),
  }),
  (dispatch) => ({
    getCommunityHomePage: () => dispatch(homeOperations.getCommunityHomePage()),
    setFeedLotFiltersWithoutFetchingItems: (feedLotFilters) =>
      dispatch(lotsOperations.setFeedLotFiltersWithoutFetchingItems(feedLotFilters)),
    itemsStartLoading: () => dispatch(lotsOperations.itemsStartLoading()),
    getUserCommunity: () => dispatch(communityOperations.getUserCommunity()),

    fetchAppData: () => dispatch(appOperations.fetchAppData()),
    fetchChatsData: () => dispatch(appOperations.fetchChatsData()),
  }),
)(Welcome);
