import React, { Fragment } from 'react';
import { Image, Animated } from 'react-native';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import {
  Separator,
  Text,
  View,
  Container,
  AnimatedOpacityHeader,
  TouchableItem,
} from '../../../ReusableComponents';
import { rs, colors } from '../../../../styles';
import s from './styles';
import strings from '../../../../localization';
import { getAppLanguage } from '../../../../store/app/selectors';
import shippingInfo from '../../../../constants/shippingInfo';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';

const ShippingInfo = () => {
  const language = useSelector(getAppLanguage);
  const scrollY = new Animated.Value(0);

  const onOpenFAQ = () => {
    NavigationService.navigate(screens.FAQ);
  };
  return (
    <Container>
      <AnimatedOpacityHeader scrollY={scrollY} title={shippingInfo.title[language]} />

      <Animated.ScrollView
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
          useNativeDriver: true,
        })}
        scrollEventThrottle={8}
      >
        <View style={s.headerImageContainer}>
          <Image source={{ uri: 'shipping' }} style={s.headerImage} />
        </View>

        <View style={s.sectionHeaderContainer}>
          <Text semiBold medium>
            {shippingInfo.title[language]}
          </Text>
        </View>

        <View style={[rs.paddingHorizontal, rs.backgroundWhite]}>
          <View style={rs.bigMarginVertical}>
            {R.is(String, shippingInfo.body[language]) ? (
              <Text color={colors.gray} style={s.descriptionText}>
                {shippingInfo.body[language]}
              </Text>
            ) : (
              <Fragment>
                <Text color={colors.gray} style={s.descriptionText}>
                  {shippingInfo.body[language].heading}
                </Text>

                {shippingInfo.body[language].list.map((item, index) => {
                  return (
                    <View style={rs.marginVertical} key={item.id}>
                      <View style={[rs.row]}>
                        <Text semiBold style={[rs.absolute, rs.smallMarginLeft]}>
                          {index + 1}.
                        </Text>
                        <Text semiBold style={[s.descriptionText, rs.bigMarginHorizontal]}>
                          {item.title}{' '}
                          <Text style={s.descriptionText} color={colors.gray}>
                            {item.body}
                          </Text>
                        </Text>
                      </View>
                    </View>
                  );
                })}

                <Text color={colors.gray} style={s.descriptionText}>
                  {shippingInfo.body[language].footer}
                </Text>
              </Fragment>
            )}
          </View>
        </View>

        <View style={rs.paddingHorizontal}>
          <Separator />
        </View>

        <TouchableItem style={[rs.bigPaddingVertical, rs.paddingHorizontal]} onPress={onOpenFAQ}>
          <Text style={s.descriptionText}>
            {strings.support.for_anything_else_check_FAQ}{' '}
            <Text semiBold color={colors.blueLight}>
              {strings.support.faq}
            </Text>
          </Text>
        </TouchableItem>
      </Animated.ScrollView>
    </Container>
  );
};

export default ShippingInfo;
