import styled from 'styled-components/native';
import { colors } from '../../../styles';

export const StyledModal = styled.View`
  align-items: center;
  justify-content: center;
`;

export const RootContainer = styled.View`
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: ${colors.white};
`;

export const SwipeableModal = styled.View`
  justify-content: center;
  margin: 0;
`;

export const SwipeableModalContainer = styled.View`
  flex: 1;
  justify-content: center;
  overflow: hidden;
`;

export const FullScreenModal = styled.View`
  flex: 1;
  margin: 0;
  background-color: ${colors.white};
`;

export const FullScreenContainer = styled.View`
  flex: 1;
  justify-content: center;
`;
