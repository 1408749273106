/* eslint-disable */
import React, { Component } from 'react';
import { LayoutAnimation } from 'react-native';
import LottieView from 'lottie-react-native';
import styled from 'styled-components/native';
import Spinner from '../Spinner';
import { colors } from '../../../styles';
import { isWeb } from '../../../utils/detectDevice';

const ContentContainer = styled.View`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const CentredContainer = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const OpacityBackground = styled.View`
  background-color: ${colors.black};
  opacity: 0.1;
  position: absolute;
  width: 100%;
  height: 100%;
`;

// const Spinner = styled.ActivityIndicator`
//   z-index: 1;
// `;

class RootLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    LayoutAnimation.easeInEaseOut();
  }

  componentWillUnmount() {
    LayoutAnimation.easeInEaseOut();
  }

  showLoader = () => {
    this.setState({ show: true });
  };

  hideLoader = () => {
    this.setState({ show: false });
  };

  render() {
    const { show } = this.state;
    return show ? (
      <ContentContainer>
        <CentredContainer>
          {/* <OpacityBackground /> */}
          {/* <Spinner /> */}
          {/*<LottieView*/}
          {/*  loop*/}
          {/*  autoSize*/}
          {/*  style={{*/}
          {/*    width: 150,*/}
          {/*    height: 150,*/}
          {/*  }}*/}
          {/*  source={require('../../../assets/animations/loading.json')}*/}
          {/*  autoPlay*/}
          {/*/>*/}
          <Spinner {...(isWeb ? { size: 'large' } : {})} />
        </CentredContainer>
      </ContentContainer>
    ) : null;
  }
}

export default RootLoader;
