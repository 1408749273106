import api from './api';

export default {
  getMyFollowersForHomePage: (communityId) => api.get(`getMyFollowersForHomePage/${communityId}`),
  getTopItemsForHome: (community_id, excluded_ids) =>
    api.post('getTopItemsForHome', {
      community_id,
      excluded_ids,
    }),

  getMostFollowedUsersForHome: (communityId) =>
    api.get(`getMostFollowedUsersForHome/${communityId}`),

  getRewardsForHome: (communityId) => api.get(`getRewardsForHome/${communityId}`),

  getItemsNearMeForHome: ({ communityId, latitude, longitude, excludedIds }) =>
    api.post('getItemsNearMeForHome', {
      community_id: communityId,
      latitude,
      longitude,
      excluded_ids: excludedIds,
    }),

  getTopSellersForHome: (communityId) => api.get(`getTopSellersForHome/${communityId}`),

  getMyLastSellersForHome: (communityId) => api.get(`getMyLastSellersForHome/${communityId}`),

  getTopUploadedCategoriesForHome: (communityId) =>
    api.get(`getTopUploadedCategoriesForHome/${communityId}`),

  getMyTopCategoriesForHome: (communityId) => api.get(`getMyTopCategoriesForHome/${communityId}`),

  getNewestItemsInCommunityGroupsForHome: (communityId) =>
    api.get(`getNewestItemsInCommunityGroupsForHome/${communityId}`),

  getRecentFollowingItemsForHome: (community_id, excluded_ids) =>
    api.post('getRecentFollowingItemsForHome', { community_id, excluded_ids }),

  getDiscountedItemsForHome: (community_id, excluded_ids) =>
    api.post('getDiscountedItemsForHome', {
      community_id,
      excluded_ids,
    }),

  getSellersNearMeForHome: (communityId) => api.get(`getSellersNearMeForHome/${communityId}`),
  // getSellersNearMeForHome: (communityId) => api.get(`getSellersNearMeForHome`),

  getItemsNearPrimaryLocationForHome: (community_id, excluded_ids) =>
    api.post('getItemsNearPrimaryLocationForHome', { community_id, excluded_ids }),

  getNewestItemsForHome: (community_id, excluded_ids) =>
    api.post('getNewestItemsForHome', {
      community_id,
      excluded_ids,
    }),
};
