import React, { useState } from 'react';
import { connect } from 'react-redux';
import UserItemsView from './UserItemsView';

const UserItems = ({ communities, route }) => {
  const userId = route.params.userId;
  const sharedCommunityIds = route.params.sharedCommunityIds;
  const [selectedCommunityIds, setSelectedCommunityIds] = useState([]);

  return (
    <UserItemsView
      userId={userId}
      communities={communities}
      selectedCommunityIds={selectedCommunityIds}
      sharedCommunityIds={sharedCommunityIds}
      onSelectCommunityIds={setSelectedCommunityIds}
    />
  );
};

export default connect((state, { navigation }) => ({
  communities: [],
}))(UserItems);
