import { createSelector } from 'reselect';
import * as R from 'ramda';
import { NEW_GROUP_USER } from '../../constants/rewardTypes';

export const getRewards = createSelector(
  [R.pathOr([], ['rewards', 'rewardIds']), R.pathOr({}, ['rewards', 'rewardEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

const getRewardSortingPosition = (section) =>
  ({
    common: 0,
    upload: 1,
    buy: 2,
    sell: 3,
    referral: 4,
    wish_fulfillment: 5,
  }[section]);

export const getRewardsSectionList = createSelector([getRewards], (rewards) => {
  const filteredRewards = rewards.filter((reward) => reward.reward_type !== NEW_GROUP_USER);

  const sections = {};

  filteredRewards.forEach((reward) => {
    sections[reward.section] = sections[reward.section]
      ? [...sections[reward.section], reward]
      : [reward];
  });

  const sectionList = [];

  R.forEachObjIndexed((val, key) => {
    sectionList.push({ type: key, data: val });
  }, sections);

  return R.sortBy((item) => getRewardSortingPosition(item.type), sectionList);
});

export const getWelcomeReward = createSelector([getRewards], (rewards) =>
  rewards.find((reward) => reward.reward_type === NEW_GROUP_USER),
);

export const getRewardsEarnedAmount = createSelector([getRewards], (rewards) => {
  const amount = R.reduce(
    (accum, current) => R.add(accum, R.prop('given_reward_amount', current)),
    0,
    rewards,
  );
  return amount || 0;
});

export const getRewardsMaxAmount = createSelector([getRewards], (rewards) =>
  R.reduce((accum, current) => R.add(accum, R.prop('max_reward_amount', current)), 0, rewards),
);

export const getRewardsProgress = createSelector(
  [R.pathOr([], ['rewards', 'rewardsProgress'])],
  (data) => R.sortWith([R.ascend(R.prop('order'))], data),
);

export const getUserInfoRewards = createSelector(
  [R.pathOr([], ['rewards', 'userInfoRewards'])],
  // (data) => R.sortWith([R.ascend(R.prop('is_reached')), R.descend(R.prop('reached_at'))], data),
  (data) => R.sortWith([R.ascend(R.prop('order'))], data),
);

export default {
  getRewards,
  getRewardsSectionList,
  getRewardsEarnedAmount,
  getRewardsMaxAmount,
  getWelcomeReward,
  getRewardsProgress,
  getUserInfoRewards,
};
