import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Container, Spinner } from '../../../ReusableComponents';
import { rs } from '../../../../styles';
import { FlatList, RefreshControl } from 'react-native';
import { connect } from 'react-redux';
import { rewardsOperations, rewardSelectors } from '../../../../store/rewards';
import UserInfoRewardsList from './components/UserInfoRewardsList';
import RewardProgressItem from './components/RewardProgressItem';
import FooterComponent from './components/FooterComponent';

const InviteFriendsInfo = ({
  route,
  isLoading,
  userInfoRewards,
  rewardsProgress,
  getUserInfoRewards,
  getRewardsProgress,
}) => {
  const [isRefreshing, setRefreshing] = useState(false);

  useEffect(() => {
    getUserInfoRewards();
    getRewardsProgress();
  }, []);

  const onRefresh = async () => {
    setRefreshing(true);
    await getUserInfoRewards();
    await getRewardsProgress();
    setRefreshing(false);
  };

  if ((R.isEmpty(userInfoRewards) || R.isEmpty(rewardsProgress)) && isLoading) {
    return (
      <Container style={rs.justifyCenter}>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={rewardsProgress}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => <RewardProgressItem item={item} />}
        ListHeaderComponent={() => <UserInfoRewardsList data={userInfoRewards} />}
        ListFooterComponent={() => <FooterComponent />}
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
        refreshing={isRefreshing}
      />
    </Container>
  );
};

export default connect(
  (state) => ({
    isLoading: state.rewards.isLoadingRewardsProgress || state.isLoadingUserInfoRewards,
    userInfoRewards: rewardSelectors.getUserInfoRewards(state),
    rewardsProgress: rewardSelectors.getRewardsProgress(state),
  }),
  (dispatch) => ({
    getUserInfoRewards: () => dispatch(rewardsOperations.getUserInfoRewards()),
    getRewardsProgress: () => dispatch(rewardsOperations.getRewardsProgress()),
  }),
)(InviteFriendsInfo);
