export default {
  SET_COMMUNITY_GROUPS: 'communities/SET_COMMUNITY_GROUPS',
  SET_COMMUNITIES: 'communities/SET_COMMUNITIES',
  SET_JOINED_COMMUNITIES: 'communities/SET_JOINED_COMMUNITIES',
  SET_INSIDE_COMMUNITY_ID: 'communities/SET_INSIDE_COMMUNITY_ID',
  SET_COMMUNITY_INFO: 'communities/SET_COMMUNITY_INFO',
  CHANGE_STATUS_TO_PENDING_APPROVAL: 'communities/CHANGE_STATUS_TO_PENDING_APPROVAL',
  USER_BECOME_COMMUNITY_MEMBER: 'communities/USER_BECOME_COMMUNITY_MEMBER',
  USER_LEFT_COMMUNITY: 'communities/USER_LEFT_COMMUNITY',
  APPROVE_COMMUNITY_RULES: 'communities/APPROVE_COMMUNITY_RULES',
  APPROVE_ITEM_RULES: 'communities/APPROVE_ITEM_RULES',
  APPROVE_WISH_RULES: 'communities/APPROVE_WISH_RULES',
  INCREASE_MEMBERS_COUNT: 'communities/INCREASE_MEMBERS_COUNT',
  MARK_COMMUNITY_AS_VISITED: 'communities/MARK_COMMUNITY_AS_VISITED',
  SET_ALL_COMMUNITIES_ON_FEED: 'communities/SET_ALL_COMMUNITIES_ON_FEED',
};
