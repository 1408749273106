import React, { Fragment } from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import T from 'prop-types';
import { dimensions, colors, rs } from '../../styles';
import Text from './Text';
import TouchableItem from './TouchableItem';
import Icon from './Icon';

const s = StyleSheet.create({
  listContentContainer: {
    paddingHorizontal: dimensions.halfMedium + dimensions.halfMedium / 2,
    minHeight: 80,
  },
  checkIcon: {
    position: 'absolute',
    top: 3,
    right: 3,
  },
  conditionContainer: {
    alignItems: 'center',
    paddingVertical: dimensions.halfMedium,
    marginHorizontal: dimensions.halfMedium / 2,
  },
  titleContainer: {
    height: 32,
    width: 116,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: dimensions.halfMedium,
    borderRadius: 16,
    borderColor: colors.gray,
    borderWidth: 1,
    marginBottom: dimensions.halfMedium,
  },
  activeCondition: {
    backgroundColor: colors.blueLight,
    borderColor: colors.blueLight,
  },
});

const ConditionsList = ({
  conditionsList,
  onSelectCondition,
  activeConditionId,
  multiple,
  activeConditionIds,
}) => (
  <ScrollView
    horizontal
    showsHorizontalScrollIndicator={false}
    contentContainerStyle={s.listContentContainer}
  >
    {conditionsList.map((condition) => {
      const isActive = multiple
        ? activeConditionIds.includes(condition.id)
        : activeConditionId === condition.id;
      return (
        <TouchableItem
          key={condition.title}
          onPress={() => onSelectCondition(condition.id)}
          style={s.conditionContainer}
        >
          <Fragment>
            {/*{multiple && isActive && (*/}
            {/*  <Icon name="check-circle" size={16} color={colors.white} style={s.checkIcon} />*/}
            {/*)}*/}
            <View style={[s.titleContainer, isActive && s.activeCondition]}>
              <Text alignCenter semiBold color={isActive ? colors.white : null}>
                {condition.title}
              </Text>
            </View>
            <Text xsmall alignCenter color={colors.gray}>
              {condition.subtitle}
            </Text>
          </Fragment>
        </TouchableItem>
      );
    })}
  </ScrollView>
);

ConditionsList.propTypes = {
  conditionsList: T.array,
  onSelectCondition: T.func,
  activeConditionId: T.number,
  multiple: T.bool,
  activeConditionIds: T.array,
};

export default ConditionsList;
