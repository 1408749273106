import React, { Component } from 'react';
import styled from 'styled-components/native';
import T from 'prop-types';
import { connect } from 'react-redux';
import { StyleSheet, View, Image } from 'react-native';
import debounce from 'debounce';
import RoundButton from '../../Buttons/RoundButton';
import Slider from '../../Slider';
import Text from '../../Text';
import { dimensions, colors, rs } from '../../../../styles';
import strings from '../../../../localization';
import { ContentContainer, AdminAvatar } from './styles';

const MainContainer = styled.View`
  padding-bottom: ${dimensions.doubleMedium};
  padding-horizontal: ${dimensions.doubleMedium};
  width: ${dimensions.width - 4 * dimensions.medium};
`;

const OldPrice = styled(Text)`
  text-decoration-line: line-through;
`;

const SliderContainer = styled.View`
  width: 100%;
  height: 40;
  margin-bottom: ${dimensions.medium};
  flex-direction: row;
  align-items: center;
`;

const StyledSlider = styled(Slider)`
  flex: 1;
`;

const s = StyleSheet.create({
  priceContainer: {
    flex: 1,
    height: 80,
    borderRadius: dimensions.borderRadius,
    borderWidth: 2,
    borderColor: colors.grayLight,
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceVerticalSeparator: {
    width: dimensions.doubleMedium,
  },
  rateText: {
    textAlign: 'right',
    marginLeft: dimensions.medium,
  },
  imageBackground: {
    height: 90,
    width: 90,
    borderRadius: 45,
    marginTop: -45,
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingTop: dimensions.medium,
  },
  image: {
    height: 54,
    width: 54,
  },
});

class Discount extends Component {
  state = {
    rate: 20,
  };

  setRate = debounce((value) => {
    this.setState({ rate: value });
  }, 10);

  render() {
    const { rate } = this.state;
    const { onPress, params, discountDetails } = this.props;

    // const {
    //   max_discounts_per_item,
    //   max_discounts_per_period,
    //   period_in_days,
    //   discounts_used,
    // } = discountDetails;

    const { price, discountsUsedForItem, onSubmit } = params;

    const newPrice = Math.round(price - (price * rate) / 100) || 1;

    return (
      <ContentContainer>
        <View style={s.imageBackground}>
          <Image style={s.image} source={{ uri: 'discount.png' }} />
        </View>
        <MainContainer>
          <Text semiBold large alignCenter style={rs.bigMarginBottom}>
            {strings.discounts.set_discount_title}
          </Text>

          <View style={[rs.row]}>
            <View style={s.priceContainer}>
              <Text color={colors.gray} style={rs.smallMarginBottom}>
                {strings.discounts.original_price}
              </Text>

              <OldPrice large bold color={colors.gray}>
                {price}
              </OldPrice>
            </View>
            <View style={s.priceVerticalSeparator} />

            <View style={s.priceContainer}>
              <Text style={rs.smallMarginBottom} color={colors.gray}>
                {strings.discounts.discounted_price}
              </Text>

              <Text large bold color={colors.violet}>
                {newPrice}
              </Text>
            </View>
          </View>

          <Text alignCenter style={rs.bigMarginTop}>
            {strings.discounts.set_discount}
          </Text>
          <SliderContainer>
            <StyledSlider
              step={10}
              minimumValue={20}
              maximumValue={90}
              value={rate}
              onValueChange={this.setRate}
            />
            <Text semiBold large color={colors.violet} style={s.rateText}>
              {rate} %
            </Text>
          </SliderContainer>

          {/*<Text>*/}
          {/*  {discountsUsedForItem} {strings.discounts.out_of} {max_discounts_per_item}{' '}*/}
          {/*  {strings.discounts.item_discounts_used}*/}
          {/*</Text>*/}

          {/*<TextSeparator />*/}

          {/*<Text>*/}
          {/*  {discounts_used} {strings.discounts.out_of} {max_discounts_per_period}{' '}*/}
          {/*  {strings.discounts.account_discounts_used}*/}
          {/*  <Text color={colors.gray}>*/}
          {/*    {strings.discounts.last_30_days.replace('XX', period_in_days)}*/}
          {/*  </Text>*/}
          {/*</Text>*/}

          <View style={[rs.row, rs.bigMarginTop]}>
            <RoundButton
              withMarginRight
              secondaryType
              fullWidth
              title={strings.common.cancel}
              onPress={onPress}
            />
            <RoundButton
              fullWidth
              title={strings.discounts.discount_item}
              onPress={() => {
                onSubmit(rate);
                onPress();
              }}
            />
          </View>
        </MainContainer>
      </ContentContainer>
    );
  }
}

Discount.propTypes = {
  params: T.shape({
    price: T.number,
    discountsUsedForItem: T.number,
    onSubmit: T.func,
  }),
  discountDetails: T.shape({
    max_discounts_per_item: T.number.isRequired,
    max_discounts_per_period: T.number.isRequired,
    period_in_days: T.number.isRequired,
    discounts_used: T.number.isRequired,
  }),
  onPress: T.func.isRequired,
};

export default connect((state) => ({
  discountDetails: state.subscriptions.discounts,
}))(Discount);
