import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import ImagePicker from 'react-native-image-crop-picker';
import moment from 'moment';
import T from 'prop-types';
import * as R from 'ramda';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import {
  GenderSelector,
  UserAvatar,
  View,
  Text,
  TouchableItem,
  Separator,
  InputWithTitleAndValidation,
} from '../../../ReusableComponents';
import { userOperations } from '../../../../store/userInfo';
import s from './styles';
import { darkBlueHeaderStyle } from '../../../../styles/headerStyle';
import { colors, rs, dimensions } from '../../../../styles';
import { getParamOr } from '../../../../utils/navHelper';
import { NavigationService } from '../../../../services';
import strings from '../../../../localization';
import { MIN_USER_AGE } from '../../../../constants/user';
import { day } from '../../../../utils/dateHelper';
import screens from '../../../../navigation/screens';
import { getAppLocaleCode } from '../../../../store/app/selectors';
import { isURL } from '../../../../utils/stringHelper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { isWeb } from '../../../../utils/detectDevice';

const TIMER_INITIAL_VALUE = 30;

const EditUserInfo = ({ userInfo, updateUserInfo, sendEmailVerification, navigation, route, appLocaleCode }) => {
  const isNewUser = route.params?.isNewUser ?? false;
  const [userAvatar, setUserAvatar] = useState(isNewUser ? '' : userInfo.profile_image);
  const [userName, setUserName] = useState(isNewUser ? '' : userInfo.name);
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const birthdayDefaultValue = (userInfo.birth_date && new Date(userInfo.birth_date)) || null;
  const [birthday, setBirthday] = useState(birthdayDefaultValue);
  const [gender, setGender] = useState(userInfo.gender);
  const [email, setEmail] = useState(userInfo.email);
  const [isInvalidEmail, setInvalidEmail] = useState(false);
  const [foundationName, setFoundationName] = useState(userInfo.foundation_name ?? '');
  const [foundationDescription, setFoundationDescription] = useState(userInfo.foundation_description ?? '');

  useEffect(() => {
    if (!isNewUser) {
      navigation.setParams({
        isValid: true,
      });
    }
  }, []);

  useEffect(() => {
    const isValid = !isInvalidEmail && !!userName && userName.trim().length > 1;

    navigation.setParams({ updateInfo: updateInfo, isValid });
  }, [userName, userAvatar, email, gender, birthday, foundationName, foundationDescription]);

  // This is a workaround for android devices
  // because onKeyPress event handler doesn't work as expected
  const onChangeUserName = (value) => {
    // check if it's backspace key pressed
    if (value.length < userName.length) {
      setUserName(value);
      return;
    }

    const userNameRegExp = new RegExp(/^[a-zA-Z\u0590-\u05fe\s]+$/);
    const [key] = R.difference(value, userName);
    const isValidKey = userNameRegExp.test(key);

    if (isValidKey) {
      setUserName(value);
    }
  };

  const updateInfo = async () => {
    const name = userName.trim();

    await updateUserInfo({
      userName: name,
      userAvatar: isURL(userAvatar) ? null : userAvatar,
      gender,
      birthDate: birthday,
      ...(userInfo.is_foundation ? { foundationName, foundationDescription } : {}),
    });

    NavigationService.goBack();
  };

  const openImagePicker = () => {
    ImagePicker.openPicker({
      width: 600,
      height: 600,
      cropping: true,
      compressImageMaxWidth: 600,
      compressImageMaxHeight: 600,
      cropperCircleOverlay: false,
      mediaType: 'photo',
      smartAlbums: ['UserLibrary', 'PhotoStream', 'Bursts'],
      cropperToolbarColor: colors.white,
    })
      .then((image) => {
        setUserAvatar(R.prop('path', image));
      })
      .catch((err) => {

      });
  };

  const onOpenEditNickname = () => {
    NavigationService.navigate(screens.EditNickname);
  };

  const onOpenEditEmail = () => {
    NavigationService.navigate(screens.AddEmail);
  };

  const showDatePicker = () => {
    setDatePickerVisibility(true);
  };

  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };

  const handleConfirm = (date) => {
    // https://github.com/mmazzarolo/react-native-modal-datetime-picker#why-does-the-picker-show-up-twice-on-android
    // datepicker should be hidden before any other action on Android to prevent unnecessary duplicated appearance
    hideDatePicker();
    setBirthday(date);
  };

  const onChangeEmail = async (value) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const isInvalid = !reg.test(value) && !R.isEmpty(value);

    setInvalidEmail(isInvalid);
    setEmail(value);
  };

  const onPressVerifyEmail = async () => {
    await sendEmailVerification(userInfo.email);
  };

  const maxUserBirthDate = moment().subtract(MIN_USER_AGE, 'years').toDate();

  return (
    <KeyboardAwareScrollView
      extraScrollHeight={dimensions.doubleMedium}
      enableResetScrollToCoords={false}
      keyboardShouldPersistTaps="handled"
      style={[rs.backgroundWhite, isWeb && rs.webContainer]}
    >
      <View style={s.avatarWithInfoContainer}>
        <TouchableItem onPress={openImagePicker}>
          <UserAvatar big uri={userAvatar} withEditLabel={!!userAvatar} />
        </TouchableItem>
      </View>

      <View style={[rs.paddingHorizontal, rs.bigPaddingTop, rs.backgroundGray]}>
        <InputWithTitleAndValidation
          title={`*${strings.user_info.display_name}`}
          autoCapitalize="none"
          minLength={5}
          underlineColorAndroid="transparent"
          placeholder={strings.login.type}
          maxLength={25}
          onChangeText={onChangeUserName}
          value={userName}
          errorText={!userName.length && strings.user_info.name_validation_error}
          useForcedRTL
        />
      </View>

      <Separator small color={colors.grayLight} />

      <TouchableItem onPress={onOpenEditNickname} style={s.nicknameButton}>
        <Text medium>{strings.user_info.username}</Text>
        {userInfo.nickname ? (
          <Text color={colors.blueLight} style={rs.marginTop}>
            {userInfo.nickname}
          </Text>
        ) : (
          <Text color={colors.grayBorder} style={rs.marginTop}>
            {strings.user_info.username_placeholder}
          </Text>
        )}
      </TouchableItem>

      <View style={rs.paddingHorizontal}>
        <Separator />
      </View>

      <TouchableItem onPress={showDatePicker} style={s.nicknameButton}>
        <Text medium>{strings.user_info.date_of_birth}</Text>
        {!!birthday ? (
          <Text color={colors.blueLight} style={rs.marginTop}>
            {day(birthday)}
          </Text>
        ) : (
          <Text color={colors.grayBorder} style={rs.marginTop}>
            {strings.user_info.date_of_birth_placeholder}
          </Text>
        )}
      </TouchableItem>

      <View style={rs.paddingHorizontal}>
        <Separator />
      </View>

      <View style={[rs.paddingHorizontal, rs.bigPaddingVertical, rs.backgroundWhite]}>
        <GenderSelector selectedGender={gender} onSelect={setGender} />
      </View>

      <View style={rs.paddingHorizontal}>
        <Separator />
      </View>
      {/*<View style={[rs.paddingHorizontal, rs.bigMarginTop, rs.backgroundWhite]}>*/}
      {/*  <InputWithTitleAndValidation*/}
      {/*    autoCapitalize="none"*/}
      {/*    title={strings.user_info.email_address}*/}
      {/*    maxLength={30}*/}
      {/*    minLength={5}*/}
      {/*    placeholder={strings.user_info.email_placeholder}*/}
      {/*    onChangeText={onChangeEmail}*/}
      {/*    value={email}*/}
      {/*    underlineColorAndroid="transparent"*/}
      {/*    errorText={*/}
      {/*      email && email.length && isInvalidEmail*/}
      {/*        ? strings.user_info.email_validation_error*/}
      {/*        : null*/}
      {/*    }*/}
      {/*  />*/}
      {/*</View>*/}

      <TouchableItem onPress={onOpenEditEmail} style={s.nicknameButton}>
        <Fragment>
          <Text medium>{strings.user_info.email_address}</Text>
          {userInfo.email ? (
            <Text color={colors.blueLight} style={rs.marginTop}>
              {userInfo.email}
            </Text>
          ) : (
            <Text color={colors.grayBorder} style={rs.marginTop}>
              {strings.user_info.email_placeholder}
            </Text>
          )}
          {!!userInfo.email && !userInfo.is_email_verified && (
            <Text color={colors.red} style={s.emailValidationText}>
              {strings.user_info.verify_email_validation}{' '}
              <Text onPress={onPressVerifyEmail} style={s.emailValidationButtonText}>
                {strings.login.send_code_again}
              </Text>
            </Text>
          )}
        </Fragment>
      </TouchableItem>

      <DateTimePickerModal
        isVisible={isDatePickerVisible}
        mode="date"
        locale={appLocaleCode}
        date={birthday || maxUserBirthDate}
        maximumDate={maxUserBirthDate}
        onConfirm={handleConfirm}
        onCancel={hideDatePicker}
        cancelTextIOS={strings.common.cancel}
        confirmTextIOS={strings.common.confirm}
        headerTextIOS={strings.user_info.pick_a_date}
      />

      {!!userInfo.is_foundation && (
        <Fragment>
          <Separator small color={colors.grayLight} />

          <View style={[rs.paddingHorizontal, rs.bigPaddingTop, rs.backgroundGray]}>
            <InputWithTitleAndValidation
              title={strings.user_info.foundation_name}
              autoCapitalize="none"
              minLength={5}
              underlineColorAndroid="transparent"
              placeholder={strings.user_info.foundation_name_placeholder}
              maxLength={25}
              onChangeText={setFoundationName}
              value={foundationName}
              useForcedRTL
            />
          </View>

          <Separator small color={colors.grayLight} />

          <View style={[rs.paddingHorizontal, rs.bigPaddingTop, rs.backgroundGray]}>
            <InputWithTitleAndValidation
              title={strings.user_info.foundation_description}
              autoCapitalize="none"
              underlineColorAndroid="transparent"
              placeholder={strings.user_info.foundation_description_placeholder}
              maxLength={120}
              onChangeText={setFoundationDescription}
              value={foundationDescription}
              useForcedRTL
            />
          </View>

          <Separator small color={colors.grayLight} />
        </Fragment>
      )}
    </KeyboardAwareScrollView>
  );
};

EditUserInfo.propTypes = {
  userInfo: T.object.isRequired,
  updateUserInfo: T.func.isRequired,
};

export default connect(
  (state) => ({
    userInfo: state.userInfo,
    appLocaleCode: getAppLocaleCode(state),
  }),
  (dispatch) => ({
    updateUserInfo: (params) => dispatch(userOperations.updateUserInfo(params)),
    sendEmailVerification: (email) => dispatch(userOperations.sendEmailVerification(email)),
  }),
)(EditUserInfo);
