import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Alert, SectionList, View } from 'react-native';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { colors } from '../../../../styles';
import strings from '../../../../localization';
import {
  Container,
  EmptyList,
  EmptyListWithImage,
  Separator,
  Text,
} from '../../../ReusableComponents';
import { userLocationsOperations, userLocationsSelectors } from '../../../../store/userLocations';
import LocationItem from './components/LocationItem';
import NavigationService from '../../../../services/NavigationService';
import screens from '../../../../navigation/screens';
import locationsTypes from '../../../../constants/locationsTypes';
import s from './style';

@connectActionSheet
class UserLocations extends Component {
  componentDidMount() {
    const { navigation, getUserLocations } = this.props;
    getUserLocations();
    navigation.setParams({ onAddLocation: this.onAddLocation });
  }

  onRemoveLocation = (locationId) => {
    const { removeLocation } = this.props;
    Alert.alert('', strings.locations.sure_delete, [
      {
        text: strings.common.deactivate,
        onPress: () => {
          removeLocation(locationId);
        },
      },
      {
        text: strings.common.cancel,
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
    ]);
  };

  onSetPrimaryLocation = (locationId) => {
    const { setPrimaryLocation } = this.props;
    setPrimaryLocation(locationId);
  };

  onPressOption = (location) => {
    const { showActionSheetWithOptions } = this.props;
    const isLocationCanBePrimary =
      location.is_active && !location.is_primary && location.type !== locationsTypes.SHIPPER;

    const optionsVisibilityMap = {
      [strings.locations.delete_location]: location.is_active,
      [strings.locations.activate_location]: !location.is_active,
      [strings.locations.edit_location]: true,
      [strings.locations.set_primary]: isLocationCanBePrimary,
      [strings.common.cancel]: true,
    };

    const options = Object.keys(optionsVisibilityMap).filter((key) => optionsVisibilityMap[key]);

    const destructiveButtonIndex = location.is_active ? 0 : null;
    const cancelButtonIndex = options.length - 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0 && location.is_active) {
          if (location.is_primary) {
            Alert.alert(
              strings.locations.delete_location,
              strings.locations.cant_remove_primary_location,
              [
                {
                  text: strings.common.ok,
                  onPress: () => {},
                },
              ],
            );
          } else {
            setTimeout(() => this.onRemoveLocation(location.id), 1);
          }
        }

        if (buttonIndex === 0 && !location.is_active) {
          setTimeout(() => this.props.activateLocation(location.id), 1);
        }

        if (buttonIndex === 1) {
          setTimeout(() => this.onEditLocation(location), 1);
        }

        if (buttonIndex === 2 && isLocationCanBePrimary) {
          setTimeout(() => this.onSetPrimaryLocation(location.id), 1);
        }
      },
    );
  };

  onAddLocationCallBack = (location) => {
    const { createLocation, user } = this.props;
    if (user.is_shipper) {
      setTimeout(() => {
        NavigationService.navigate(screens.CreateLocation, { location });
      }, 1);
    } else {
      createLocation({
        location,
        type: locationsTypes.USER,
      });
    }
  };

  onAddLocation = () => {
    NavigationService.navigate(screens.Location, {
      selectLocations: this.onAddLocationCallBack,
    });
  };

  onEditLocation = (location) => {
    if (location.type === locationsTypes.USER) {
      NavigationService.navigate(screens.Location, {
        location,
        selectLocations: this.props.editLocation,
      });
    } else {
      NavigationService.navigate(screens.CreateLocation, { location, isEdit: true });
    }
  };

  render() {
    const { userLocations } = this.props;

    return (
      <Container style={s.contentContainer}>
        <SectionList
          sections={userLocations}
          keyExtractor={(item) => item.id}
          keyboardShouldPersistTaps="handled"
          stickySectionHeadersEnabled={false}
          renderSectionHeader={({ section: { title } }) => (
            <View style={s.sectionHeaderContainer}>
              <Text semiBold medium>
                {title}
              </Text>
            </View>
          )}
          ListEmptyComponent={
            <EmptyListWithImage
              image="empty_state_search"
              text={strings.locations.addresses_empty_list}
              onPress={this.onAddLocation}
              buttonTitle={strings.locations.add_address}
            />
          }
          ItemSeparatorComponent={() => <Separator color={colors.grayLight} />}
          renderItem={({ item }) => (
            <LocationItem location={item} onPressOption={this.onPressOption} />
          )}
        />
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    user: state.userInfo,
    userLocations: userLocationsSelectors.getUserLocationsSectionList(state),
  }),
  (dispatch) => ({
    removeLocation: (locationId) => dispatch(userLocationsOperations.removeLocation(locationId)),
    activateLocation: (locationId) =>
      dispatch(userLocationsOperations.activateLocation(locationId)),
    createLocation: ({ location, type }) =>
      dispatch(userLocationsOperations.createLocation({ location, type })),
    editLocation: (location) => dispatch(userLocationsOperations.editLocation(location)),
    setPrimaryLocation: (locationId) =>
      dispatch(userLocationsOperations.setPrimaryLocation(locationId)),
    getUserLocations: () => dispatch(userLocationsOperations.getUserLocations()),
  }),
)(UserLocations);
