import React from 'react';
import T from 'prop-types';
import { StyleSheet, ScrollView } from 'react-native';
import strings from '../../../../../localization';
import marketingNotificationGroups from '../../../../../constants/marketingNotificationGroups';
import { NavigationService } from '../../../../../services';
import { Text, TouchableItem } from '../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../styles';

const s = StyleSheet.create({
  itemContainer: {
    height: 80,
    justifyContent: 'center',
    paddingHorizontal: dimensions.medium,
  },
  activeItemContainer: {
    backgroundColor: colors.grayLighter,
  },
  itemTitle: {
    lineHeight: 20,
  },
});

const NotificationTargetGroupScreen = ({ route }) => {
  const { selectedTargetGroupId, onSelectTargetGroup } = route.params;
  const options = Object.values(marketingNotificationGroups).map((id) => ({
    id,
    title: strings.community_notification_groups[id],
  }));

  return (
    <ScrollView style={rs.backgroundWhite}>
      {options.map((option) => {
        const onPress = () => {
          onSelectTargetGroup(option.id);
          NavigationService.pop();
        };

        const isSelected = option.id === selectedTargetGroupId;

        return (
          <TouchableItem
            onPress={onPress}
            style={[s.itemContainer, isSelected && s.activeItemContainer]}
            useOpacity={false}
          >
            <Text medium semiBold style={s.itemTitle}>
              {option.title}
            </Text>
          </TouchableItem>
        );
      })}
    </ScrollView>
  );
};

NotificationTargetGroupScreen.propTypes = {
  route: T.shape({
    params: T.shape({
      selectedTargetGroupId: T.string,
      onSelectTargetGroup: T.func,
    }),
  }).isRequired,
};

export default NotificationTargetGroupScreen;
