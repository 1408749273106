import api from './api';
import { LOTS_LIMIT } from '../constants/listLimits';

export default {
  getPendingItems: ({ group_ids = [], skip = 0 }) =>
    api.post('admin/getPendingItems', {
      group_ids,
      skip,
      limit: LOTS_LIMIT,
    }),

  declinePendingItem: (item_id) =>
    api.post('admin/declinePendingItem', {
      item_id,
    }),

  approvePendingItem: (item_id) =>
    api.post('admin/approvePendingItem', {
      item_id,
    }),

  approveUserToUploadItems: (user_id_to_approve) =>
    api.post('admin/approveUserToUploadItems', {
      user_id_to_approve,
    }),

  suspendUserToUploadItems: (user_id_to_suspend) =>
    api.post('admin/suspendUserToUploadItems', {
      user_id_to_suspend,
    }),
};
