import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { mergeDeep } from '../../utils/stateHelper';
import * as types from './types';

const initialState = {
  isLoading: false,
  isLoadingMore: false,
  isRefreshing: false,
  existMore: true,

  unreadCount: 0,

  notificationIds: [],
  notificationEntities: {},
};

const reducer = handleActions(
  {
    [types.NOTIFICATIONS_START]: mergeDeep(() => ({
      isLoading: true,
    })),

    [types.NOTIFICATIONS_REFRESH_START]: mergeDeep(() => ({
      isRefreshing: true,
    })),

    [types.NOTIFICATIONS_ERROR]: mergeDeep(() => ({
      isLoading: false,
      isLoadingMore: false,
      isRefreshing: false,
    })),

    [types.NOTIFICATIONS_SUCCESS]: mergeDeep(({ payload }) => ({
      notificationEntities: payload.normalizedNotifications.notificationEntities,
      notificationIds: payload.normalizedNotifications.notificationIds,
      unreadCount: payload.unreadCount,
      isLoading: false,
      isRefreshing: false,
      existMore: payload.existMore,
    })),

    [types.NOTIFICATIONS_MORE_START]: mergeDeep(() => ({
      isLoadingMore: true,
    })),

    [types.NOTIFICATIONS_MORE_SUCCESS]: mergeDeep(({ payload }, state) => ({
      notificationEntities: payload.normalizedNotifications.notificationEntities,
      notificationIds: [
        ...state.notificationIds,
        ...payload.normalizedNotifications.notificationIds,
      ],
      unreadCount: 0,
      isLoadingMore: false,
      existMore: payload.existMore,
    })),

    [types.MARK_NOTIFICATIONS_AS_READ]: mergeDeep(() => ({
      unreadCount: 0,
    })),

    [types.MARK_NOTIFICATION_AS_OPEN]: mergeDeep(({ payload }) => ({
      notificationEntities: {
        [payload.notificationId]: {
          is_opened: true,
        },
      },
    })),

    [types.ADD_NEW_NOTIFICATION]: mergeDeep(({ payload }, state) => ({
      notificationEntities: {
        [payload.notification.id]: payload.notification,
      },
      notificationIds: R.uniq([payload.notification.id, ...state.notificationIds]),
      unreadCount: state.unreadCount + 1,
    })),
  },
  initialState,
);

export default reducer;
