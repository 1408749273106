import React, { Fragment, memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { FontIcon, Separator, Text, TouchableItem } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import { colors, dimensions, rs } from '../../../../../styles';

const s = StyleSheet.create({
  contentContainer: {
    paddingTop: 20,
    paddingBottom: dimensions.doubleMedium,
    paddingHorizontal: dimensions.medium,
    alignItems: 'center',
  },
  rootContainer: {
    // backgroundColor: colors.grayLighter,
  },
});

const SectionHeader = memo(({ type }) => {
  const title = {
    wish_fulfillment: strings.rewards.rewards_for_wish_fulfillment,
    wish: 'WISH',
    upload: strings.rewards.rewards_for_uploading_items,
    buy: strings.rewards.rewards_for_buying_items,
    sell: strings.rewards.rewards_for_selling_items,
    common: 'COMMON',
    referral: strings.rewards.rewards_for_friends_referral,
  }[type];

  return (
    <View style={s.rootContainer}>
      {type !== 'upload' && <Separator color={colors.grayBorder} />}
      <View style={s.contentContainer}>
        <Text style={rs.smallMarginBottom} semiBold medium>
          {title}
        </Text>
        <Separator />
      </View>
    </View>
  );
});

export default SectionHeader;
