import React, { Fragment } from 'react';
import * as R from 'ramda';
import { BrandView, BrandNameText } from './styles';

class Info extends React.PureComponent {
  render() {
    const { brand, onSelect, selectedBrand } = this.props;

    const isActive =
      Number(brand.id) === Number(R.prop('id', selectedBrand)) ||
      (brand.id === 'no_brand' && !R.prop('id', selectedBrand));

    return (
      <Fragment>
        <BrandView onPress={onSelect} useOpacity={false}>
          <BrandNameText isActive={isActive}>{brand.name}</BrandNameText>
        </BrandView>
      </Fragment>
    );
  }
}

export default Info;
