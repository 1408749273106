import toastTypes from '../constants/toastTypes';
import strings from '../localization';

class ToastsService {
  constructor() {
    this._toast = null;
  }

  init(ref) {
    if (this._toast) {
      return;
    }
    this._toast = ref;
  }

  destroy() {
    this._toast = null;
  }

  showNotification({ data, tokenIcon, subtitle, title, body, onPress }) {
    this._toast.show({
      type: toastTypes.NOTIFICATION,
      headerIconUrl: tokenIcon,
      headerTitle: title,
      title: subtitle,
      body,
      data,
      onPress,
    });
  }

  showError(message) {
    this._toast.show({
      type: toastTypes.ERROR,
      title: message,
    });
  }

  showSuccess(message) {
    this._toast.show({
      type: toastTypes.SUCCESS,
      title: message || strings.error_messages.error,
    });
  }
}

const toastsService = new ToastsService();

export default toastsService;
