export const GREETING_HANDSHAKE = 0;
export const BUYER_MADE_OFFER = 1;
// @DEPRECATED - we are not using this type anymore, because of multiple ask-to-buy
export const ITEM_NOT_AVAILABLE_ON_MARKET = 2;
export const ITEM_RETURNED_TO_MARKET = 3;
export const BUYER_CANCELLED_OFFER = 4;
export const SELLER_REFUSED_OFFER = 5;
export const SELLER_CONFIRMED_OFFER = 6;
export const ITEM_DELIVERED_TO_BUYER = 7;
export const ITEM_SOLD = 8;
export const USER_CREATED_ITEM_ON_YOUR_WISH = 9;
export const ADMIN_APPROVED_JOIN_TO_GROUP = 10;
export const ADMIN_DECLINED_JOIN_TO_GROUP = 11;
export const BUYER_LEFT_COMMUNITY = 12;
export const SELLER_LEFT_COMMUNITY = 13;
export const USER_WISH_HAS_BEEN_FULLFILLED = 14;
export const NEW_ISSUE_TRANSACTION_IN_LIST = 15;
export const USER_ADD_TO_NEW_GROUP = 16;
export const NEW_TRANSFER_TRANSACTION_IN_LIST = 17;
export const SELLER_REMOVED_ITEM = 18;
export const ADMIN_REMOVED_SELLER_ITEM = 19;
export const USER_REACHED_GOAL = 20;
export const USER_HAVE_PROGRESS_ON_GOAL = 21;
export const NEW_CHAT_MESSAGE = 22;
export const NEW_REFUND_TRANSACTION_IN_LIST = 23;
export const NEW_FOLLOWER = 24;
export const NEW_JOIN_REQUEST = 25;
// @deprecated
export const NEW_FAVORITE_ITEM = 26;
//
export const FAVORITE_ITEM_SOLD = 27;
export const FAVORITE_ITEM_DISCOUNT = 28;
export const USER_LIKED_YOUR_ITEM = 29;

// export const SELLER_PROPOSED_ITEM_TO_WISH = 31;
export const REQUESTER_REJECTED_OFFER = 32;
export const NEW_ISSUE_BONUS_TRANSACTION_IN_LIST = 36;
export const ADMIN_APPROVED_PENDING_ITEM = 37;
export const ITEM_HAS_BEEN_MARKED_AS_TOP = 38;
export const NEW_ITEM_TAGGING = 39;
export const BIRTHDAY_BONUS_NOTIFICATION = 40;
export const MARKETING_NOTIFICATION = 41;
export const NEW_DONATION_TRANSACTION = 42;
