import strings from '../localization';

export default [
  {
    code: 'en',
    title: strings.languages.english,
  },
  {
    code: 'he',
    title: strings.languages.hebrew,
  },
  {
    code: 'br',
    title: strings.languages.portuguese,
  },
  {
    code: 'de',
    title: strings.languages.german,
  },
  // {
  //   code: 'ar',
  //   title: strings.languages.arabic,
  // },
  // {
  //   code: 'nl',
  //   title: strings.languages.dutch,
  // },
];
