import { createAction } from 'redux-actions';
import * as types from './types';

export const notificationsStart = createAction(types.NOTIFICATIONS_START);
export const notificationsSuccess = createAction(types.NOTIFICATIONS_SUCCESS);
export const notificationsError = createAction(types.NOTIFICATIONS_ERROR);
export const notificationsMoreStart = createAction(types.NOTIFICATIONS_MORE_START);
export const notificationsMoreSuccess = createAction(types.NOTIFICATIONS_MORE_SUCCESS);
export const notificationsRefreshStart = createAction(types.NOTIFICATIONS_REFRESH_START);
export const notificationsRefreshSuccess = createAction(types.NOTIFICATIONS_REFRESH_SUCCESS);

export const markNotificationsAsRead = createAction(types.MARK_NOTIFICATIONS_AS_READ);
export const markNotificationAsOpen = createAction(types.MARK_NOTIFICATION_AS_OPEN);

export const addNewNotification = createAction(types.ADD_NEW_NOTIFICATION);
