import React, { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import T from 'prop-types';
import {
  Container,
  FilterHorizontalList,
  FilterOptions,
  FilterButton,
} from '../../../ReusableComponents';
import strings from '../../../../localization';
import UserItems from '../components/UserItems';
import { dimensions, rs } from '../../../../styles';
import { sortingTypes } from '../../../../constants/sortingTypes';

const s = StyleSheet.create({
  filterContainer: {
    height: 28 + dimensions.medium,
  },
});

const UserItemsView = ({
  userId,
  communities,
  selectedCommunityIds,
  onSelectCommunityIds,
  sortingType,
  onSetSortingType,
  defaultTabIndex,
  isCurrentUserItems,
  sharedCommunityIds,
}) => {
  const sortingTypesList = [
    {
      type: sortingTypes.by_newest,
      title: strings.sorting.newest,
    },
    {
      type: sortingTypes.by_oldest,
      title: strings.sorting.oldest,
    },
  ];

  return (
    <Container>
      <View style={s.filterContainer}>
        <FilterHorizontalList style={rs.backgroundWhite}>
          <Fragment>
            <FilterOptions
              primaryButtonTitle={strings.groups.groups}
              options={communities}
              optionKeyName="id"
              optionTitleName="group_name"
              selectedOptionIds={selectedCommunityIds}
              onChange={onSelectCommunityIds}
              isSquare
            />
            <FilterButton
              primaryButtonTitle={strings.groups.groups}
              options={communities}
              optionKeyName="id"
              optionTitleName="group_name"
              selectedOptionIds={selectedCommunityIds}
              onChange={onSelectCommunityIds}
              isSquare
            />
            {isCurrentUserItems && (
              <FilterButton
                options={sortingTypesList}
                selectedOptionIds={[sortingType]}
                onChange={onSetSortingType}
                optionKeyName="type"
                optionTitleName="title"
                primaryButtonTitle={strings.sorting.sort_by}
                filterTitle={strings.filters.sort_results_by}
                isActive={sortingType !== sortingTypes.by_newest}
                isMultipleSelection={false}
                isSquare
              />
            )}
          </Fragment>
        </FilterHorizontalList>
      </View>

      <UserItems
        defaultTabIndex={defaultTabIndex}
        userId={userId}
        isCurrentUserItems={isCurrentUserItems}
        isShowPurchasedItems={isCurrentUserItems}
        sharedCommunityIds={selectedCommunityIds}
        sortingType={sortingType}
        // sharedCommunityIds={
        //   isCurrentUserItems
        //     ? selectedCommunityIds
        //     : R.isEmpty(selectedCommunityIds)
        //     ? sharedCommunityIds
        //     : selectedCommunityIds
        // }
      />
    </Container>
  );
};

UserItemsView.propTypes = {
  userId: T.number.isRequired,
  communities: T.array,
  selectedCommunityIds: T.array,
  onSelectCommunityIds: T.func,
  sortingType: T.string,
  onSetSortingType: T.func,
  defaultTabIndex: T.number,
  isCurrentUserItems: T.bool,
};

export default UserItemsView;
