import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../../../styles';

export default StyleSheet.create({
  contentContainer: {},
  itemImage: {
    height: 44,
    width: 44,
    backgroundColor: colors.grayLighter,
    borderRadius: 3,
    marginRight: dimensions.medium,
  },
  button: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: dimensions.halfMedium,
    backgroundColor: colors.white,
    paddingHorizontal: dimensions.medium,
  },
});
