import { createAction } from 'redux-actions';
import * as types from './types';

export const setHomeItems = createAction(types.SET_HOME_ITEMS);
export const setHomeData = createAction(types.SET_HOME_DATA);

export const loadingStart = createAction(types.LOADING_START);
export const loadingSuccess = createAction(types.LOADING_SUCCESS);

export const setTopItems = createAction(types.SET_TOP_ITEMS);
export const setMostFollowedUsers = createAction(types.SET_MOST_FOLLOWED_USERS);
export const setMyFollowers = createAction(types.SET_MY_FOLLOWERS);
export const setTopSellers = createAction(types.SET_TOP_SELLERS);
export const setMySellers = createAction(types.SET_MY_SELLERS);
export const setItemsFromMembersIFollow = createAction(types.SET_ITEMS_FROM_MEMBERS_I_FOLLOW);
export const setSellersNearMe = createAction(types.SET_SELLERS_NEAR_ME);
export const setItemsNearMe = createAction(types.SET_ITEMS_NEAR_ME);
export const setDiscountedItems = createAction(types.SET_DISCOUNTED_ITEMS);
export const setMyTopCategories = createAction(types.SET_MY_TOP_CATEGORIES);
export const setTopUploadedCategories = createAction(types.SET_TOP_UPLOADED_CATEGORIES);
export const setRewards = createAction(types.SET_REWARDS);
export const setCities = createAction(types.SET_CITIES);
export const setCampuses = createAction(types.SET_CAMPUSES);
export const setNewestitemsInCommunityGroups = createAction(
  types.SET_NEWEST_ITEMS_IN_COMMUNITY_GROUPS,
);
export const setNewestItems = createAction(types.SET_NEWEST_ITEMS);

export const addExcludedItemIdsCache = createAction(types.ADD_EXCLUDED_ITEM_IDS_CACHE);
