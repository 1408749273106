import React, { useState } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import { sortingTypes } from '../../../../constants/sortingTypes';
import { groupsSelectors } from '../../../../store/groups';
import UserItemsView from './UserItemsView';

const CurrentUserItems = ({ userId, groups, route }) => {
  const defaultTabIndex = route.params?.defaultTabIndex ?? 0;
  const [selectedCommunityIds, setSelectedCommunityIds] = useState([]);
  const [sortingType, setSortingType] = useState(sortingTypes.by_newest);

  const onSetSortingType = (keys) => {
    if (keys[0] !== sortingType) {
      setSortingType(keys[0]);
    }
  };

  return (
    <UserItemsView
      userId={userId}
      communities={groups}
      defaultTabIndex={defaultTabIndex}
      onSelectCommunityIds={setSelectedCommunityIds}
      selectedCommunityIds={selectedCommunityIds}
      sortingType={sortingType}
      onSetSortingType={onSetSortingType}
      isCurrentUserItems
    />
  );
};

CurrentUserItems.propTypes = {
  userId: T.number.isRequired,
  groups: T.array,
};

export default connect((state) => ({
  groups: groupsSelectors.getCommunityGroups(state),
  userId: state.userInfo.id,
}))(CurrentUserItems);
