import React from 'react';
import { connect } from 'react-redux';
import Button from '../../Buttons/Button';
import Text from '../../Text';
import {
  ContentContainer,
  AdminAvatar,
  MainContainer,
  Title,
  Description,
  ButtonContainer,
} from './styles';
import strings from '../../../../localization';
import { colors, rs } from '../../../../styles';
import { groupsOperations, groupsSelectors } from '../../../../store/groups';

const LeaveCommunity = ({ onPress, communityName, leaveCommunity }) => (
  <ContentContainer>
    <AdminAvatar source={{ uri: 'round_logo' }} />
    <MainContainer>
      <Text large semiBold alignCenter color={colors.blueLighter} style={rs.bigMarginBottom}>
        {strings.settings.leave_community_title.replace('XX', communityName)}
      </Text>
      <Description>{strings.settings.leave_community_text}</Description>

      <ButtonContainer>
        <Button withMarginRight secondaryType title={strings.common.cancel} onPress={onPress} />

        <Button
          title={strings.common.leave}
          onPress={() => {
            leaveCommunity();
            onPress();
          }}
        />
      </ButtonContainer>
    </MainContainer>
  </ContentContainer>
);

export default connect(
  state => ({
    communityName: '',
  }),
  dispatch => ({
    leaveCommunity: () => {
      dispatch(groupsOperations.leaveCommunity());
    },
  }),
)(LeaveCommunity);
