import * as R from 'ramda';
import { createSelector } from 'reselect';

export const getFoundationsList = createSelector(
  [R.pathOr([], ['foundations', 'ids']), R.pathOr({}, ['foundations', 'entities'])],
  (ids, entities) => {
    return ids.map((id) => {
      const entity = entities[id];

      return {
        id: entity.user_id,
        name: entity.foundation_name || entity.user_name,
        description: entity.foundation_description,
        image: entity.user_image,
        website: entity.foundation_website_link,
        totalDonationAmount: entity.total_donation_amount,
      };
    });
  },
);

export default {
  getFoundationsList,
};
