import * as actions from './actions';
import rewardsApi from '../../api/rewards';
import { normalize } from '../../utils/stateHelper';
import accountApi from '../../api/account';

export const getRewards = () => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  dispatch(actions.rewardsStart());
  try {
    const data = await rewardsApi.getBadgeRewards(communityId);

    const normalizedRewards = normalize(data, 'rewardIds', 'rewardEntities');

    dispatch(actions.rewardsSuccess(normalizedRewards));
  } catch (err) {
    dispatch(actions.rewardsError(err.message));
  }
};

const getUserReferralRewards = () => (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  return accountApi
    .getReferralRewards(communityId)
    .then((response) => response)
    .catch((err) => {});
};

const getUserReferrals = (skip) => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  try {
    return await rewardsApi.getReferrals(communityId, skip);
  } catch (err) {
    return null;
  }
};

const getUserReferralsWhoSoldItem = (skip) => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  try {
    return await rewardsApi.getReferralsWhoSoldItem(communityId, skip);
  } catch (err) {
    return null;
  }
};
const getUserInfoRewards = () => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;
  dispatch(actions.userInfoRewardsStart());
  try {
    const data = await rewardsApi.getUserInfoRewards(communityId);
    dispatch(actions.userInfoRewardsSuccess(data));
  } catch (err) {
    dispatch(actions.userInfoRewardsError());
  }
};

const getRewardsProgress = () => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;
  dispatch(actions.rewardsProgressStart());
  try {
    const data = await rewardsApi.getRewardsProgress(communityId);
    dispatch(actions.rewardsProgressSuccess(data));
  } catch (err) {
    dispatch(actions.rewardsProgressError());
  }
};

export default {
  getRewards,
  getUserReferralRewards,
  updateReward: actions.updateReward,
  getUserReferrals,
  getUserReferralsWhoSoldItem,
  getUserInfoRewards,
  getRewardsProgress,
};
