import styled from 'styled-components/native';
import { colors, dimensions, fontSizes } from '../../../styles';
import { halfMedium } from '../../../styles/dimensions';
import TouchableItem from '../TouchableItem';
import Text from '../Text';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  contentContainer: {
    transform: [{ scaleX: -1 }],
    alignItems: 'flex-end',
  },
  listContentContainer: {
    paddingHorizontal: dimensions.halfMedium + dimensions.halfMedium / 2,
  },
  list: {
    backgroundColor: colors.white,
    marginTop: dimensions.halfMedium,
    marginBottom: dimensions.halfMedium,
  },
});

const SizeItemContainer = styled(TouchableItem)`
  margin-horizontal: ${halfMedium / 2};
  transform: scaleX(-1);
  min-width: 74px;
  padding-horizontal: ${halfMedium};
  height: 32px;
  padding-horizontal: 8px;
  border-width: 1px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? colors.blueLight : colors.white)};
  border-color: ${({ isActive }) => (isActive ? colors.blueLight : colors.gray)};
`;

const SizeTitle = styled(Text)`
  text-align: center;
  color: ${({ isActive }) => (isActive ? colors.white : colors.gray)};
  text-align: center;
`;

export { SizeItemContainer, SizeTitle };
