import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import T from 'prop-types';
import Text from './Text';
import { rs } from '../../styles';
import { isRTL } from '../../utils/rtlHelper';

const s = StyleSheet.create({
  descriptionText: {
    lineHeight: 20,
  },
});
const RuleItem = memo(({ title, description, index }) => {
  return (
    <View style={rs.marginVertical}>
      <View style={[rs.row, rs.alignCenter]}>
        <Text semiBold style={[s.descriptionText, rs.absolute, rs.smallMarginLeft]}>
          {isRTL && '.'}
          {index + 1}
          {!isRTL && '.'}
        </Text>
        <Text semiBold style={[s.descriptionText, rs.bigMarginHorizontal]}>
          {title}
        </Text>
      </View>
      <Text style={[s.descriptionText, rs.bigMarginHorizontal, rs.verySmallPaddingTop]}>
        {description}
      </Text>
    </View>
  );
});

RuleItem.propTypes = {
  title: T.string.isRequired,
  description: T.string.isRequired,
  index: T.number.isRequired,
};

const Rules = memo(({ rules }) => {
  return (
    <View style={rs.smallMarginVertical}>
      {rules.map(({ title, description }, index) => (
        <RuleItem key={title} title={title} description={description} index={index} />
      ))}
    </View>
  );
});

Rules.propTypes = {
  rules: T.array.isRequired,
};

export default Rules;
