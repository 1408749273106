import React from 'react';
import { connect } from 'react-redux';
import Button from '../../Buttons/Button';
import {
  Title,
  ContentContainer,
  AdminAvatar,
  MainContainer,
  Description,
  ButtonContainer,
} from './styles';
import strings from '../../../../localization';
import { offersOperations } from '../../../../store/offers';
import * as offerActions from '../../../../store/offers/offerActions';
import { LoadingService } from '../../../../services';

const Repost = ({ params, performAction, onPress }) => {
  const { offerId } = params;

  const onNoPress = async () => {
    LoadingService.showSuccessLoader();
    await performAction(offerId, offerActions.SELLER_CONFIRMED_OFFER, false);
    LoadingService.hideSuccessLoader();
    onPress();
  };

  const onYesPress = async () => {
    LoadingService.showSuccessLoader();
    await performAction(offerId, offerActions.SELLER_CONFIRMED_OFFER, true);
    LoadingService.hideSuccessLoader();
    onPress();
  };

  return (
    <ContentContainer>
      <AdminAvatar source={{ uri: 'round_logo' }} />
      <MainContainer>
        <Description>{strings.settings.keep_item_alive}</Description>

        <ButtonContainer>
          <Button
            secondaryType
            withMarginRight
            title={strings.common.no_thanks}
            onPress={onNoPress}
          />
          <Button title={strings.common.yes} onPress={onYesPress} />
        </ButtonContainer>
      </MainContainer>
    </ContentContainer>
  );
};

export default connect(
  state => ({}),
  dispatch => ({
    performAction: (offerId, actionId, has_multiple_supply) => {
      dispatch(offersOperations.performAction(offerId, actionId, has_multiple_supply));
    },
  }),
)(Repost);
