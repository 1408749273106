import { createAction } from 'redux-actions';
import * as types from './types';

export const chatsStart = createAction(types.CHATS_START);
export const chatsSuccess = createAction(types.CHATS_SUCCESS);
export const chatsError = createAction(types.CHATS_ERROR);
export const resetSearchedChats = createAction(types.RESET_SEARCHED_CHATS);

export const addNewChat = createAction(types.ADD_NEW_CHAT);
export const updateChat = createAction(types.UPDATE_CHAT);

export const chatInfoStart = createAction(types.CHAT_INFO_START);
export const chatInfoSuccess = createAction(types.CHAT_INFO_SUCCESS);

export const addChatEntity = createAction(types.ADD_CHAT_ENTITY);
export const resetTemporaryChat = createAction(types.RESET_TEMPORARY_CHAT);

export const setMessage = createAction(types.SET_MESSAGE);
export const updateMessage = createAction(types.UPDATE_MESSAGE);
export const removeMessage = createAction(types.REMOVE_MESSAGE);

export const messagesStart = createAction(types.MESSAGES_START);
export const messagesSuccess = createAction(types.MESSAGES_SUCCESS);

export const setInsideRoomId = createAction(types.SET_INSIDE_ROOM_ID);

export const markChatAsRead = createAction(types.MARK_CHAT_AS_READ);
export const increaseChatUnreadCount = createAction(types.INCREASE_CHAT_UNREAD_COUNT);
export const increaseSupportChatUnreadCount = createAction(types.INCREASE_SUPPORT_CHAT_UNREAD_COUNT);
export const increaseTotalUnreadCount = createAction(types.INCREASE_TOTAL_UNREAD_COUNT);
export const increaseSupportChatsTotalUnreadCount = createAction(
  types.INCREASE_SUPPORT_CHATS_TOTAL_UNREAD_COUNT,
);
export const setTotalUnreadCount = createAction(types.SET_TOTAL_UNREAD_COUNT);
export const setSupportChatsTotalUnreadCount = createAction(
  types.SET_SUPPORT_CHATS_TOTAL_UNREAD_COUNT,
);

export const archiveChats = createAction(types.ARCHIVE_CHATS);
export const unarchiveChats = createAction(types.UNARCHIVE_CHATS);
export const markChatMessagesAsRead = createAction(types.MARK_CHAT_MESSAGES_AS_READ);
export const setUserTypingMessage = createAction(types.SET_USER_TYPING_MESSAGE);
