import { combineReducers } from 'redux';
import AppData from './reducers';
import auth from './auth';
import chats from './chats';
import app from './app';
import lots from './lots/reducers';
import rewards from './rewards';
import shipping from './shipping';
import userLocations from './userLocations';
import userInfo from './userInfo';
import followers from './followers';
import communityMembers from './communityMembers';
import groups from './groups/reducers';
import notifications from './notifications';
import transactions from './transactions';
import offers from './offers';
import wallets from './wallets';
import subscriptions from './subscriptions';
import feedSearch from './feedSearch';
import guides from './guides';
import communityInfo from './communityInfo';
import home from './home';
import createLot from './createLot';
import communityCities from './communityCities';
import campuses from './campuses';
import foundations from './foundations';

export default combineReducers({
  AppData,
  auth,
  chats,
  app,
  lots,
  rewards,
  shipping,
  communityMembers,
  userLocations,
  userInfo,
  followers,
  groups,
  notifications,
  transactions,
  offers,
  wallets,
  subscriptions,
  feedSearch,
  guides,
  communityInfo,
  home,
  createLot,
  communityCities,
  campuses,
  foundations,
});
