import React from 'react';
import Button from '../../Buttons/Button';
import {
  ContentContainer,
  AdminAvatar,
  MainContainer,
  Title,
  Description,
  ButtonContainer,
} from './styles';
import strings from '../../../../localization';

const Info = ({ onPress, params }) => (
  <ContentContainer>
    <AdminAvatar source={{ uri: 'round_logo' }} />
    <MainContainer>
      <Title semiBold>{params.title}</Title>
      <Description>{params.description}</Description>

      <ButtonContainer>
        <Button withMarginRight title={strings.common.ok} onPress={onPress} />
        {params.onSubmit && (
          <Button
            title={strings.common.ok}
            onPress={() => {
              params.onSubmit();
              onPress();
            }}
          />
        )}
      </ButtonContainer>
    </MainContainer>
  </ContentContainer>
);

export default Info;
