import React from 'react';
import Button from '../../Buttons/Button';
import {
  ContentContainer,
  AdminAvatar,
  MainContainer,
  Title,
  Description,
  ButtonContainer,
} from './styles';
import strings from '../../../../localization';

const Error = ({ onPress, params }) => (
  <ContentContainer>
    <AdminAvatar source={{ uri: 'round_logo' }} resizeMode="contain" />
    <MainContainer>
      <Title>{params.title}</Title>

      <Description>{params.subtitle}</Description>
      <ButtonContainer>
        <Button title={strings.common.ok} onPress={onPress} />
      </ButtonContainer>
    </MainContainer>
  </ContentContainer>
);

export default Error;
