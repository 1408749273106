import React from 'react';
import styled from 'styled-components/native';
import Text from '../Text';
import TouchableItem from '../TouchableItem';
import { colors, dimensions } from '../../../styles';

const ButtonContainer = styled(TouchableItem)`
  height: 40px;
  align-items: center;
  justify-content: center;
  padding-horizontal: ${({ withSmallPadding }) =>
    withSmallPadding ? dimensions.medium : dimensions.doubleMedium};
  background-color: ${({ disabled }) => (disabled ? colors.grayLighter : colors.blueLight)};
  background-color: ${({ dark }) => (dark ? colors.white : colors.blueLight)};
  ${({ color, disabled }) => (color && !disabled ? `background-color: ${color}` : '')};
  border-color: ${({ dark }) => (dark ? colors.gray : colors.blueLight)};
  ${({ color, disabled }) => (color && !disabled ? `border-color: ${color}` : '')};
  border-radius: 4px;
  margin-right: ${({ withMarginRight }) => (withMarginRight ? dimensions.medium : 0)};
`;

const ButtonText = styled(Text)`
  color: ${({ disabled, dark }) => (disabled || dark ? colors.text : colors.white)};
`;

const Button = ({ disabled, title, onPress, withMarginRight, withSmallPadding, dark, color }) => (
  <ButtonContainer
    useOpacity={false}
    disabled={disabled}
    withMarginRight={withMarginRight}
    withSmallPadding={withSmallPadding}
    onPress={onPress}
    dark={dark}
    color={color}
  >
    <ButtonText medium bold disabled={disabled} dark={dark}>
      {title}
    </ButtonText>
  </ButtonContainer>
);

export default Button;
