import React, { Fragment, memo } from 'react';
import T from 'prop-types';
import { View } from 'react-native';
import { CheckBox, Text, Separator } from '../../../../ReusableComponents';
import { colors, rs } from '../../../../../styles';
import { Box, TitleContainer } from './styles';
import Title from './Title';
import strings from '../../../../../localization';

const PublicWIshToggle = memo(({ isPublic, onPublicToggle, wisherName, groupName }) => (
  <Fragment>
    <Box>
      <TitleContainer>
        <Text medium>{strings.lots.show_wish_fulfillment_to_title}</Text>
      </TitleContainer>

      <View style={rs.smallPaddingHorizontal}>
        <CheckBox
          checked={!isPublic}
          onToggle={() => onPublicToggle(false)}
          label={strings.lots.show_wish_fulfillment_to.replace('USER_NAME', wisherName)}
        />

        <CheckBox
          checked={isPublic}
          onToggle={() => onPublicToggle(true)}
          label={strings.lots.show_wish_fulfillment_to_community.replace(
            'COMMUNITY_NAME',
            groupName,
          )}
        />
      </View>
    </Box>
  </Fragment>
));

PublicWIshToggle.propTypes = {
  isPublic: T.bool,
  onPublicToggle: T.func,
  wisherName: T.string,
  communityName: T.string,
};

export default PublicWIshToggle;
