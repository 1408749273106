import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { groupsSelectors } from '../../../../store/groups';

const withSelectedGroupId = (Comp) => {
  const Wrapper = (props) =>
    class extends React.Component {
      state = {
        selectedGroupId: null,
      };

      componentDidMount() {
        const { groups, lastLotGroupId } = this.props;

        const isIncludesLastLotGroup = R.findIndex(R.propEq('id', lastLotGroupId))(groups) !== -1;
        const selectedGroupId = isIncludesLastLotGroup ? lastLotGroupId : groups[0].id;

        this.setState({
          selectedGroupId,
        });
      }

      setSelectedGroupId = (selectedGroupId) => {
        this.setState({ selectedGroupId: Number(selectedGroupId) });
      };

      render() {
        const { selectedGroupId } = this.state;
        return (
          <Comp
            {...this.props}
            selectedGroupId={selectedGroupId}
            setSelectedGroupId={this.setSelectedGroupId}
          />
        );
      }
    };

  return connect((state) => ({
    groups: groupsSelectors.getCommunityGroups(state),
    lastLotGroupId: state.createLot.groupId,
  }))(Wrapper());
};

export default withSelectedGroupId;
