/* eslint-disable react/no-unused-state */
import React from 'react';
import { connect } from 'react-redux';
import debounce from 'debounce';
import { TabView } from 'react-native-tab-view';
import { SearchBarContainer } from './styles';
import { dimensions } from '../../../styles';
import { headerStyleTransparentWithBackground } from '../../../styles/headerStyle';
import {
  Container,
  View,
  SearchInput,
  SelectableUserItem,
  UsersList,
  Spinner,
  TabBar,
  EmptyListWithImage,
} from '../../ReusableComponents';
import NavigationService from '../../../services/NavigationService';
import strings from '../../../localization';
import screens from '../../../navigation/screens';
import { followersOperations } from '../../../store/followers';
import { FOLLOWINGS, FOLLOWERS } from '../../../constants/followTypes';
import { COMMUNITY_MEMBERS_LIMIT } from '../../../constants/listLimits';
import { groupsSelectors } from '../../../store/groups';
import CenteredView from '../../ReusableComponents/Containers/CenteredView';
import ChatsList from '../ChatsGroup/ChatListsGroup/components/ChatsList';

class Followers extends React.Component {
  static navigationOptions = ({ navigation }) => ({
    ...headerStyleTransparentWithBackground,
    title: navigation.getParam('name'),
    headerRight: <View />,
  });

  state = {
    userId: '',

    followersSearchValue: '',
    followers: '',
    isLoadingFollowers: false,
    isLoadingMoreFollowers: false,
    isExistMoreFollowers: true,

    followingsSearchValue: '',
    followings: '',
    isLoadingFollowings: false,
    isLoadingMoreFollowings: false,
    isExistMoreFollowings: true,

    index: 0,
    routes: [
      { key: FOLLOWERS, title: strings.follow.followers },
      { key: FOLLOWINGS, title: strings.follow.followings },
    ],
  };

  componentWillMount() {
    const { route } = this.props;

    const type = route.params.type;

    this.setState({
      index: type === FOLLOWERS ? 0 : 1,
    });
  }

  async componentDidMount() {
    const { route, getFollowers, getFollowings } = this.props;
    this.setState({ isLoadingFollowers: true });
    this.setState({ isLoadingFollowings: true });

    const userId = route.params.userId;
    const followersTotalCount = route.params.followersTotalCount;
    const followingsTotalCount = route.params.followingsTotalCount;

    this.setState({
      userId,
      routes: [
        { key: FOLLOWERS, title: `${followersTotalCount} ${strings.follow.followers}` },
        { key: FOLLOWINGS, title: `${followingsTotalCount} ${strings.follow.followings}` },
      ],
    });

    const followersData = await getFollowers(userId);
    this.setState({
      followers: followersData.followers,
      isExistMoreFollowers: followersData.followers.length === COMMUNITY_MEMBERS_LIMIT,
      isLoadingFollowers: false,
      // followersTotalCount: followersData.total_count,
    });

    const followingsData = await getFollowings(userId);

    this.setState({
      followings: followingsData.followings,
      isExistMoreFollowings: followingsData.followings.length === COMMUNITY_MEMBERS_LIMIT,
      isLoadingFollowings: false,
      // followersTotalCount: followingsData.total_count,
    });
  }

  getFollowersDebounce = debounce(async (value) => {
    const { getFollowers } = this.props;
    const { userId } = this.state;

    const { followers } = await getFollowers(userId, value);
    this.setState({
      followers,
      isExistMoreFollowers: followers.length === COMMUNITY_MEMBERS_LIMIT,
      isLoadingFollowers: false,
    });
  }, 400);

  getFollowingsDebounce = debounce(async (value) => {
    const { getFollowings } = this.props;
    const { userId } = this.state;

    const { followings } = await getFollowings(userId, value);
    this.setState({
      followings,
      isExistMoreFollowings: followings.length === COMMUNITY_MEMBERS_LIMIT,
      isLoadingFollowings: false,
    });
  }, 400);

  onChangeFollowersSearch = (value) => {
    this.setState({
      followersSearchValue: value,
      isLoadingFollowers: true,
      followers: [],
    });
    this.getFollowersDebounce(value);
  };

  onChangeFollowingsSearch = (value) => {
    this.setState({
      followingsSearchValue: value,
      isLoadingFollowings: true,
      followings: [],
    });
    this.getFollowingsDebounce(value);
  };

  onLoadMoreFollowers = async () => {
    const {
      followersSearchValue,
      followers,
      userId,
      isExistMoreFollowers,
      isLoadingFollowers,
      isLoadingMoreFollowers,
    } = this.state;
    const { getFollowers } = this.props;

    if (!isExistMoreFollowers || isLoadingFollowers || isLoadingMoreFollowers) return;

    this.setState({ isLoadingMoreFollowers: true });

    const followersData = await getFollowers(userId, followersSearchValue, followers.length);

    this.setState({
      followers: [...followers, ...followersData.followers],
      isExistMoreFollowers: followersData.followers.length === COMMUNITY_MEMBERS_LIMIT,
      isLoadingMoreFollowers: false,
      // followersTotalCount: followersData.total_count,
    });
  };

  onLoadMoreFollowings = async () => {
    const {
      followingsSearchValue,
      userId,
      isExistMoreFollowings,
      followings,
      isLoadingFollowings,
      isLoadingMoreFollowings,
    } = this.state;
    const { getFollowings } = this.props;

    if (!isExistMoreFollowings || isLoadingFollowings || isLoadingMoreFollowings) return;
    this.setState({ isLoadingMoreFollowings: true });

    const followingsData = await getFollowings(userId, followingsSearchValue, followings.length);

    this.setState({
      followings: [...followings, ...followingsData.followings],
      isExistMoreFollowings: followingsData.followings.length === COMMUNITY_MEMBERS_LIMIT,
      isLoadingMoreFollowings: false,
      // followersTotalCount: followersData.total_count,
    });
  };

  onSelectUser = ({ user_name, profile_image, id }) => {
    NavigationService.push(screens.UserProfile, {
      name: user_name,
      userId: id,
      profile_image,
    });
  };

  render() {
    const {
      followers,
      followersSearchValue,
      isLoadingFollowers,
      isLoadingMoreFollowers,

      followings,
      followingsSearchValue,
      isLoadingFollowings,
      isLoadingMoreFollowings,
    } = this.state;

    return (
      <Container>
        <TabView
          lazy
          renderLazyPlaceholder={() => <Spinner />}
          initialLayout={{
            height: 0,
            width: dimensions.width,
          }}
          renderTabBar={(props) => <TabBar renderBadge={this.renderBadge} {...props} />}
          navigationState={this.state}
          onIndexChange={(index) => this.setState({ index })}
          renderScene={({ route }) => {
            switch (route.key) {
              case FOLLOWERS:
                return (
                  <UsersList
                    ListHeaderComponent={
                      <SearchBarContainer>
                        <SearchInput
                          placeholder={strings.other.search}
                          value={followersSearchValue}
                          onChangeText={this.onChangeFollowersSearch}
                        />
                      </SearchBarContainer>
                    }
                    ListItem={({ item }) => (
                      <SelectableUserItem
                        name={item.user_name}
                        profileImage={item.profile_image}
                        onSelect={() => this.onSelectUser(item)}
                      />
                    )}
                    data={followers}
                    isLoading={isLoadingFollowers}
                    isLoadingMore={isLoadingMoreFollowers}
                    onLoadMore={this.onLoadMoreFollowers}
                    ListEmptyComponent={() => (
                      <EmptyListWithImage
                        image="empty_state_followers"
                        text={
                          !followersSearchValue
                            ? strings.follow.no_followers_results
                            : strings.follow.no_results_search
                        }
                      />
                    )}
                  />
                );
              case FOLLOWINGS:
                return (
                  <UsersList
                    ListHeaderComponent={
                      <SearchBarContainer>
                        <SearchInput
                          placeholder={strings.other.search}
                          value={followingsSearchValue}
                          onChangeText={this.onChangeFollowingsSearch}
                        />
                      </SearchBarContainer>
                    }
                    ListItem={({ item }) => (
                      <SelectableUserItem
                        name={item.user_name}
                        profileImage={item.profile_image}
                        onSelect={() => this.onSelectUser(item)}
                      />
                    )}
                    data={followings}
                    isLoading={isLoadingFollowings}
                    isLoadingMore={isLoadingMoreFollowings}
                    onLoadMore={this.onLoadMoreFollowings}
                    ListEmptyComponent={() => (
                      <EmptyListWithImage
                        image="empty_state_followers"
                        text={
                          !followingsSearchValue
                            ? strings.follow.no_following_results
                            : strings.follow.no_results_search
                        }
                      />
                    )}
                  />
                );

              default:
                return null;
            }
          }}
        />
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    // userId: state.userInfo.id,
  }),
  (dispatch) => ({
    getFollowers: (userId, searchTerm, skip) =>
      dispatch(followersOperations.getFollowers(userId, searchTerm, skip)),
    getFollowings: (userId, searchTerm, skip) =>
      dispatch(followersOperations.getFollowings(userId, searchTerm, skip)),
  }),
)(Followers);
