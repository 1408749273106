export default {
  title: {
    he: 'איך מעבירים את הפריטים בין המשתמשים?',
    en: 'How are items delivered? ',
    br: 'Como os itens são entregues?',
    de: 'Wie werden die Artikel geliefert?',
  },
  body: {
    he:
      'כשמדברים על מסחר חברתי, אנו מתייחסים גם לאופן העברת הפריטים שאתם מוסרים או מקבלים. העיקרון הבסיסי, ואפשרות המשלוח הראשונה והמומלצת היא איסוף עצמי של המקבל מהמוסר (או להפך). התיאום מתבצע בצ׳אט בין המקבל למוסר ומאפשר לכם למצוא את הדרך הקלה והנוחה.\nבנוסף, אנו מאפשרים לכם להיעזר בממשק שינועים המורכב ממשתמשים בדיוק כמוכם, שישמחו לסייע לכם בהעברת פריטים מהמוסר אל נקודות איסוף נוספות, בתמורה לנקודות Shareitt. לקבלת מידע נוסף בנושא שינועים, נא בידקו את עמוד השאלות ותשובות. \n\nבמקביל אנו מאפשרים גישה לשירותי שילוח רגילים במשלוח עד הבית כמו דואר ישראל, Boxit וכו׳. הנ״ל מתבצע ישירות מול החברות השותפות ומתומחר בשקלים ולא בנקודות Shareitt. \nאת אפשרויות השילוח בוחר המוסר כאשר הוא מעלה פריט למסירה. האפשרויות השונות מוצגות בעמוד המוצר כחלק מהמידע על הפריט ולרוב מהוות שיקול בהחלטה על אטרקטיביות הפריט.',
    en: {
      heading:
        'Shareitt is a social commerce platform, and delivery has a social impact. Here are Shareitt\u0027s delivery options:',
      list: [
        {
          id: '0',
          title: 'Pick-up - the simplest and most recommended delivery method.',
          body:
            'Pick-up by the buyer from the seller (or vice versa) is coordinated between the two via chat, and they decide what is most convenient for them.',
        },
        {
          id: '1',
          title: 'Other Shareitt users who serve as "couriers".',
          body:
            'These users transfer items from the seller to different collection points in return for Shareitt coins. Contact us via the Shareitt chat, and we\u0027ll be happy to provide further information.',
        },
        {
          id: '2',
          title: 'Conventional delivery services such as post and parcel lockers (eg. Boxit).',
          body:
            'When you chose this option, you will be referred to the relevant delivery service, which you pay for with shekels.',
        },
      ],
      footer:
        'The seller lists the available delivery options when uploading an item for sale. The options appear on the Item Page and are often an important consideration for buyers.',
    },
    br: {
      heading:
        'Shareitt é uma plataforma de comércio social, e a entrega tem um impacto social. Aqui estão as opções de entrega do Shareitt:',
      list: [
        {
          id: '0',
          title: 'Buscar o item - o método de entrega mais simples e recomendado.',
          body:
            'O comprador ir buscar o produto com o vendedor ou o contrário (ou vice-versa) é coordenada entre os dois pelo chat, e eles decidem o que for mais conveniente para eles.',
        },
        {
          id: '1',
          title: 'Outros usuários do Shareitt que atuam como "mensageiros".',
          body:
            'Esses usuários transferem itens do vendedor para diferentes pontos de coleta em troca de moedas Shareitt. Entre em contato conosco pelo chat do Shareitt e teremos prazer em fornecer mais informações.',
        },
        {
          id: '2',
          title: 'Serviços de entrega convencionais, como correio e portadores.',
          body:
            'Ao escolher esta opção, você será encaminhado para o serviço de entrega terceirizado, que você paga com real.',
        },
      ],
      footer:
        'O vendedor lista as opções de entrega disponíveis ao subir um item para venda. As opções aparecem na página do item e geralmente são uma consideração importante para os compradores.',
    },
    de: {
      heading:
        'Als Community-Plattform setzt Shareitt auch bei der Auslieferung nach Möglichkeit auf das Vertrauen durch direkte Kontakte. Hier sind die Shareitt Lieferoptionen:',
      list: [
        {
          id: '0',
          title: 'Persönliche Übergabe - die einfachste Methode.',
          body:
            'Die Abholung, bzw Übergabe wird zwischen den beiden Parteien per Chat koordiniert, und sie entscheiden, was für sie am bequemsten ist.',
        },
        {
          id: '1',
          title: 'Shareitt Boten/Kuriere',
          body:
            'Diese Personen bringen die Artikel an vereinbarte Übergabe- und Lagerorte und erhalten dafür Shareitt-Punkte. Kontaktiere uns über den Shareitt-Chat, und wir werden dir gerne weitere Informationen geben.',
        },
        {
          id: '2',
          title: 'Herkömmliche Lieferdienste wie Post und Paketschliessfächer',
          Body:
            'Wenn du diese Option wählst, wirst du an den entsprechenden Lieferdienst weitergeleitet, den du zu den üblichen Konditionen (z.B. in Franken/Euro) bezahlst.',
        },
      ],
      footer:
        'Der Anbieter listet die verfügbaren Lieferoptionen auf, wenn ein Artikel hochgeladen wird. Die Optionen erscheinen auf der Artikelseite und sind oft für beide Seiten ein wichtiges Kriterium für den Erfolg des Tauschens.',
    },
  },
};
