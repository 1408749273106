import React from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import { View, Container } from '../../../ReusableComponents';
import { colors, rs } from '../../../../styles';
import { headerStyleTransparent } from '../../../../styles/headerStyle';
import s from './styles';
import { SeparatorBlock } from '../../../ReusableComponents/RootModal/modals/Rules';
import strings from '../../../../localization';
import Text from '../../../ReusableComponents/Text';
import { groupsSelectors } from '../../../../store/groups';

class ExchangeRules extends React.Component {
  static navigationOptions = () => ({
    ...headerStyleTransparent,
    title: strings.exchange_rules.title,
    headerRight: <View />,
  });

  render() {
    const { groupInfo, route } = this.props;
    const item_title = route.params.item_title;

    const {
      min_weeks_in_community,
      min_transaction_count_in_community,
      last_month_transaction_count_in_community,
    } = groupInfo.exchange_rules;

    const rules = [
      {
        order: 1,
        description: strings.exchange_rules.first.replace(
          /__WEEKS_IN_COMMUNITY__/,
          min_weeks_in_community,
        ),
      },
      {
        order: 2,
        description: strings.exchange_rules.second.replace(
          /__COUNT_OF_OVERALL_TRANSACTIONS__/,
          min_transaction_count_in_community,
        ),
      },
      {
        order: 3,
        description: strings.exchange_rules.third.replace(
          /__COUNT_OF_TRANSACTIONS_WITHIN_LAST_MONTH__/,
          last_month_transaction_count_in_community,
        ),
      },
      {
        order: 4,
        description: strings.exchange_rules.fourth.replace(/__ITEM_TITLE__/, item_title),
      },
    ];

    return (
      <Container>
        <SeparatorBlock />
        <View style={s.contentContainer}>
          <Text medium semiBold style={rs.marginBottom}>
            {strings.exchange_rules.description}
          </Text>
          {rules.map((rule, index) => (
            <View style={s.itemContainer} key={rule.order}>
              <View style={s.numberContainer}>
                <Text medium semiBold>
                  {index + 1}.
                </Text>
              </View>
              <Text medium>{rule.description}</Text>
            </View>
          ))}
          <Text medium style={rs.marginTop} color={colors.gray}>
            {strings.exchange_rules.remark}
          </Text>
          <Text medium style={rs.smallMarginTop} color={colors.gray}>
            *{strings.exchange_rules.fifth}
          </Text>
        </View>
      </Container>
    );
  }
}

ExchangeRules.propTypes = {
  groupInfo: T.object,
};

export default connect((state, { route }) => ({
  groupInfo: groupsSelectors.getCommunityInfoByCommunityId(
    state,
    route.params.communityId,
  ),
}))(ExchangeRules);
