/* eslint-disable */
import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import { WebView } from 'react-native-webview';
import styled from 'styled-components/native';
import T from 'prop-types';
import Container from '../../Containers/Container';
import ScreenHeader from '../../ScreenHeader';
import Spinner from '../../Spinner';
import BackButton from '../../NavigationButtons/BackButton';
import Text from '../../Text';
import { dimensions, colors } from '../../../../styles';
import { isIos } from '../../../../utils/detectDevice';

const s = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.transparent,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

class WebViewModal extends Component {
  render() {
    const {
      onClose,
      params: { headerTitle, url },
    } = this.props;

    return (
      <Container>
        <ScreenHeader headerTitle={headerTitle} onClose={onClose} />

        <WebView
          javaScriptEnabled
          domStorageEnabled
          startInLoadingState
          renderLoading={() => <Spinner />}
          mediaPlaybackRequiresUserAction
          source={{ uri: url }}
          style={s.wrapper}
        />
      </Container>
    );
  }
}

WebViewModal.propTypes = {
  params: T.shape({
    url: T.string,
    headerTitle: T.string,
  }),
  onClose: T.func,
};

export default WebViewModal;
