import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { TouchableItem } from '../../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../../styles';

export default StyleSheet.create({
  grayBackground: {
    backgroundColor: colors.grayLighter,
  },
  typeIconContainer: {
    height: 26,
    width: 26,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 13,
    position: 'absolute',
    left: 39,
    bottom: 10,
    ...rs.shadow,
  },
});

export const RootContainer = styled.View`
  width: 100%;
  background-color: ${({ isSelected }) => (isSelected ? colors.blueLightest : colors.white)};
`;

export const ContentContainer = styled(TouchableItem)`
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-vertical: ${dimensions.medium};
`;

export const MainContainer = styled.View`
  flex: 1;
  justify-content: space-between;
  flex-direction: row;
  padding-horizontal: ${dimensions.medium};
`;

export const SecondContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const ChatListDetailContainer = styled.View`
  max-width: 75%;
  justify-content: space-around;
`;

export const BubbleNotification = styled.View`
  min-width: 16;
  height: 16;
  padding-horizontal: 4;
  border-width: 1;
  border-color: ${colors.blueLight};
  border-radius: 8;
  align-items: center;
  justify-content: center;
  margin-right: ${dimensions.halfMedium};
`;

export const CheckIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ImageThumbnail = styled.Image`
  height: 14;
  width: 14;
  margin-right: ${dimensions.halfMedium};
  border-radius: 2;
`;
