import { StyleSheet } from 'react-native';
import { colors, dimensions, rs } from '../../../../styles';

export default StyleSheet.create({
  fakeBackground: {
    backgroundColor: colors.secondary,
    width: '100%',
    position: 'absolute',
    height: 300,
  },

  contentContainer: {
    width: '100%',
    alignItems: 'center',
    zIndex: 5,
  },

  backgroundContainer: {
    width: '100%',
    alignItems: 'center',
    paddingBottom: dimensions.doubleMedium,
    backgroundColor: colors.secondary,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    ...rs.shadow,
  },

  credibilityImage: {
    height: 24,
    width: 19,
    marginLeft: dimensions.halfMedium,
  },
});
