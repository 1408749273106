export default {
  israel: {
    currencyIconName: 'original-price',
    currencySymbol: '₪',
  },
  netherlands: {
    currencyIconName: 'original-price-euro',
    currencySymbol: '€',
  },
};
