import React, { useEffect } from 'react';
import { StyleSheet, Animated, Keyboard, View, ImageBackground, Easing } from 'react-native';
import { useSelector } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';
import strings from '../../../localization';
import { isIos } from '../../../utils/detectDevice';
import { getAppLanguage } from '../../../store/app/selectors';
import { BackButton, Text, Separator } from '../../ReusableComponents';
import { colors, dimensions, rs } from '../../../styles';

const s = StyleSheet.create({
  background: {
    width: dimensions.width,
    height: dimensions.height,
    opacity: 1,
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    padding: dimensions.doubleMedium,
  },
  separatorContainer: {
    width: '50%',
  },
});

const AnimatedText = Animated.createAnimatedComponent(Text);
const AnimatedView = Animated.createAnimatedComponent(View);

const BirthdayRewardDetailsScreen = ({ route }) => {
  const { member_name, note, amount } = route.params.bonus;
  const language = useSelector(getAppLanguage);

  let titleOpacity = new Animated.Value(0);
  let separatorOpacity = new Animated.Value(0);
  let descriptionOpacity = new Animated.Value(0);

  useEffect(() => {
    if (isIos) {
      Keyboard.dismiss();
    }

    animateTitle();
    animateSeparator();
    animateDescription();
  }, []);

  const animateTitle = () => {
    Animated.timing(titleOpacity, {
      toValue: 1,
      duration: 800,
      delay: 100,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  };

  const animateSeparator = () => {
    Animated.timing(separatorOpacity, {
      toValue: 1,
      duration: 500,
      delay: 300,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  };

  const animateDescription = () => {
    Animated.timing(descriptionOpacity, {
      toValue: 1,
      duration: 500,
      delay: 400,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  };

  const description = note[language].replace(/__AMOUNT__/, amount);

  return (
    <View style={rs.flex}>
      <ImageBackground
        source={{ uri: 'birthday_background' }}
        resizeMode="cover"
        style={s.background}
      >
        <SafeAreaView edges={['top']} />

        <BackButton isClose />

        <View style={s.contentContainer}>
          <AnimatedText
            xxbig
            bold
            alignCenter
            color={colors.text}
            style={{ opacity: titleOpacity }}
          >
            {strings.transactions.birthday_reward_title.toUpperCase()}
          </AnimatedText>
          <AnimatedText
            xxbig
            bold
            alignCenter
            color={colors.violet}
            style={{ opacity: titleOpacity }}
          >
            {member_name.toUpperCase()}!
          </AnimatedText>

          <AnimatedView
            style={[rs.bigMarginVertical, s.separatorContainer, { opacity: separatorOpacity }]}
          >
            <Separator color={colors.text} small />
          </AnimatedView>

          <AnimatedText
            bold
            medium
            alignCenter
            color={colors.text}
            style={{ opacity: descriptionOpacity }}
          >
            {description}
          </AnimatedText>
        </View>
      </ImageBackground>
    </View>
  );
};

export default BirthdayRewardDetailsScreen;
