import api from './api';
import { toFormData } from '../utils/formDataHelper';
import { COMMUNITY_MEMBERS_LIMIT, LOTS_LIMIT } from '../constants/listLimits';
import { getImageType } from '../utils/stringHelper';

export default {
  getUserCommunity: () => api.get(`getUserCommunity`),

  getCommunityGroups: (communityId) => api.get(`getCommunityGroups/${communityId}`),

  getCommunityHomePage: (communityId) => api.get(`getCommunityHomePage/${communityId}`),

  getCommunities: (userId) => api.get(`getCommunities/${userId}`),

  getJoinedCommunities: () => api.get(`getJoinedCommunities`),

  getGroupInfo: (groupId, accountId) => api.get(`getGroupInfo/${groupId}/${accountId}`),

  getGroupActivities: (groupId) => api.get(`getGroupActivities/${groupId}`),

  leaveCommunity: (groupId, userId) =>
    api.post('leaveCommunity', { account_id: userId, group_id: groupId }),

  getGroupItemsByFilters: ({
    userId,
    distance,
    groupIds,
    categories,
    pageIndex,
    brands,
    cities,
    campuses,
    sizes,
    withShipping,
    isSearchByFollowings,
    isSearchByFavorites,
    isSearchByDiscount,
    isSearchByPrimaryLocation,
    isOnlyFeaturedItems,
    isOnlyVerifiedSellers,
    conditions,
    searchTerm,
    sortBy,
    priceRange,
    coordinates,
    skip = 0,
    schoolExtension,
    isOnlyPublic,
  }) =>
    api.post('getGroupItemsByFilters', {
      user_id: userId,
      distance,
      group_ids: groupIds,
      categories,
      page_index: pageIndex || 0,
      brands,
      cities,
      campuses,
      sizes,
      with_shipping: withShipping,
      search_by_following: isSearchByFollowings,
      search_by_favorites: isSearchByFavorites,
      search_by_primary_location: isSearchByPrimaryLocation,
      has_discount: isSearchByDiscount,
      has_top_items: isOnlyFeaturedItems,
      only_verified: isOnlyVerifiedSellers,
      conditions,
      skip,
      limit: LOTS_LIMIT,
      search_term: searchTerm,
      sort_by: sortBy,
      price_range: priceRange,
      coordinates,
      school_extension: schoolExtension,
      only_public: isOnlyPublic,
    }),

  getCommunitySoldItemsByFilters: ({ groupIds = [], categories = [], skip = 0 }) =>
    api.post('getCommunitySoldItemsByFilters', {
      group_ids: groupIds,
      categories: categories,
      skip,
      limit: LOTS_LIMIT,
    }),

  getGroupMembers: (groupId, searchTerm, skip = 0, limit = COMMUNITY_MEMBERS_LIMIT) =>
    api.get(`getGroupMembers/${groupId}?limit=${limit}&skip=${skip}&search_term=${searchTerm}`),

  getCommunityMembers: ({
    communityId,
    searchTerm,
    skip = 0,
    limit = COMMUNITY_MEMBERS_LIMIT,
    filterBy,
    excludeCurrentUser = false,
  }) => {
    const queryString = `limit=${limit}&skip=${skip}&search_term=${searchTerm}&filter_by=${filterBy}&exclude_current_user=${excludeCurrentUser}`;
    return api.get(`getCommunityMembers/${communityId}?${queryString}`);
  },

  getAllGroupMembers: (groupId) => api.get(`getGroupMembers/${groupId}`),

  addGroupMembers: (admin_id, group_id, usersPhoneNumbersNumbers) =>
    api.post('addGroupMembers', {
      admin_id,
      group_id,
      group_members: usersPhoneNumbersNumbers,
    }),

  updateGeneralCommunityInfo: ({
    group_name,
    group_id,
    token_name,
    token_icon,
    background_image,
  }) => {
    const form = new FormData();
    form.append('group_id', group_id);
    form.append('group_name', group_name);
    form.append('token_name', token_name);

    form.append(
      'token_icon',
      token_icon
        ? {
            uri: token_icon,
            name: `token_icon.${getImageType(token_icon)}`,
            type: `image/${getImageType(token_icon)}`,
          }
        : '',
    );
    form.append(
      'background_image',
      background_image
        ? {
            uri: background_image,
            name: `background_image.${getImageType(background_image)}`,
            type: `image/${getImageType(background_image)}`,
          }
        : '',
    );
    return api.put('admin/updateGeneralCommunityInfo', form);
  },

  getCommunityRules: (groupId) => api.get(`communityRules/${groupId}`),

  getItemRules: (groupId) => api.get(`itemRules/${groupId}`),

  getWishRules: (groupId) => api.get(`wishRules/${groupId}`),

  approveCommunityRules: (groupId, userId) =>
    api.post('group/approveCommunityRules', {
      group_id: groupId,
      user_id: userId,
    }),

  approveItemRules: (groupId, userId) =>
    api.post('group/approveItemRules', {
      group_id: groupId,
      user_id: userId,
    }),

  approveWishRules: (groupId, userId) =>
    api.post('group/approveWishRules', {
      group_id: groupId,
      user_id: userId,
    }),

  sendJoinRequest: (groupId, userId) =>
    api.post('group/newJoinRequest', {
      group_id: groupId,
      user_id: userId,
    }),

  sendRejoinRequest: (groupId, userId) =>
    api.post('group/rejoinRequest', {
      group_id: groupId,
      user_id: userId,
    }),

  getJoinGroupQuestions: (groupId) => api.get(`groupQuestions/${groupId}`),

  createJoinGroupAnswers: (groupId, userId, answers) =>
    api.post('createGroupMemberAnswers', {
      group_id: groupId,
      user_id: userId,
      answers,
    }),

  getJoinGroupAnswers: (groupId, userId) => api.get(`groupMemberAnswers/${groupId}/${userId}`),

  getAdminsForUserCommunities: () => api.get(`getAdminsForUserCommunities`),

  approveCommunitySlider: (groupId) =>
    api.post('approveCommunitySlider', {
      group_id: groupId,
    }),

  getGroupSliderImages: (groupId) => api.get(`getGroupSliderImages/${groupId}`),

  checkTokenNameAvailability: (tokenName) => api.get(`checkTokenNameAvailibility/${tokenName}`),

  getCategoryTemplates: () => api.get('getCategoryTemplates'),

  createCommunity: ({
    communityName,
    tokenName,
    communityLang = 'en',
    categoriesTemplateId,
    isOpen,
    isOpenForVisitors,
  }) =>
    api.post('createCommunity', {
      community_name: communityName,
      token_name: tokenName,
      community_lang: communityLang,
      categories_template_id: categoriesTemplateId,
      is_open: isOpen,
      is_open_for_visitors: isOpenForVisitors,
    }),
};
