import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { I18nManager, View } from 'react-native';
import appsFlyer from 'react-native-appsflyer';
import RNRestart from 'react-native-restart';
import * as Sentry from '@sentry/react-native';
import SplashScreen from 'react-native-splash-screen';
import DeviceInfo from 'react-native-device-info';
import { Settings } from 'react-native-fbsdk-next';
import { authOperations } from '../../../store/auth';
import userBlockOperations from '../../../store/user/operations';
import screens from '../../../navigation/screens';
import { getLanguage } from '../../../utils/localizationHelper';
import { isRTL } from '../../../utils/rtlHelper';
import { isIos, platform } from '../../../utils/detectDevice';
import {
  NavigationService,
  AnalyticsService,
  PermissionService,
  ModalsService,
} from '../../../services';
import authToken from '../../../utils/authToken';
import auth from '../../../api/auth';
import api from '../../../api/api';
import io from '../../../api/io';
import strings from '../../../localization';
import { appOperations } from '../../../store/app';
import { setLocale } from '../../../utils/dateHelper';
import { chatsOperations } from '../../../store/chats';
import { userOperations } from '../../../store/userInfo';
import { notificationOperations } from '../../../store/notifications';
import { ENV } from '../../../../config/env';
import { getFeedLotFilters } from '../../../store/lots/selectors';
import { lotsOperations } from '../../../store/lots';
import { communitySelectors } from '../../../store/communityInfo';
import modalTypes from '../../../constants/modalTypes';
import appStates from '../../../constants/appStates';

class AuthLanding extends React.Component {
  componentDidMount() {
    const { handleDeepLink } = this.props;
    this.setupLocalization();
    this.checkAuthentication();
    handleDeepLink();
    this.updateMarketplaceFilters();
  }

  setUserPreferencesForAnalytics = () => {
    const { userInfo } = this.props;
    const { id, name } = userInfo;

    AnalyticsService.setUserPreferences(id, name);
    Sentry.setUser({ id: `${id}`, username: name });
  };

  initializeAppsFlyer = () => {
    appsFlyer.initSdk(
      {
        devKey: 'NqNRSaa5VmgRvuUbgY9U8E',
        isDebug: ENV !== 'production',
        appId: '1449704865',
        onInstallConversionDataListener: true,
        timeToWaitForATTUserAuthorization: 120,
        // anonymizeUser: isIos,
      },
      (result) => {
        console.log(result);
      },
      (error) => {
        console.error(error);
      },
    );
  };

  authorizeUser = async (should_sign_legal_documents) => {
    const {
      isAdmin,
      userInfo,
      onNewMessage,
      onMarkAsRead,
      onMarkMessageAsRead,
      onUserTypingMessage,
      onBlockFromAnotherUser,
      checkNotificationsPermissions,
      getCurrentUserInfo,
      setLocationPermission,
    } = this.props;

    const { id } = userInfo;

    this.initializeAppsFlyer();

    // if fo some reasons persist was purged, get user profile again and save into redux
    if (!id) {
      await getCurrentUserInfo();
    }
    if (isIos) {
      await PermissionService.checkAndRequestIosAppTrackingTransparencyPermission();
      Settings.initializeSDK();
    }
    const isGrantedLocationPermission = await PermissionService.checkGrantedGeolocationPermission();
    setLocationPermission(isGrantedLocationPermission);

    if (userInfo.has_email_verification) {
      NavigationService.reset(screens.CampusEmailConfirmationStack);
    } else if (userInfo.isReferralRequired) {
      NavigationService.reset(screens.RequiredReferralCode);
    } else {
      NavigationService.reset(screens.AuthorizedApplication);
    }
    if (should_sign_legal_documents) {
      setTimeout(() => {
        ModalsService.showMandatoryResponseModal(modalTypes.LEGAL_INFO);
      }, 300);
    }
    onNewMessage();
    if (isAdmin) {
      onMarkAsRead();
    }
    onMarkMessageAsRead();
    onUserTypingMessage();
    onBlockFromAnotherUser();
    checkNotificationsPermissions();

    this.setUserPreferencesForAnalytics();
  };

  setupLocalization = () => {
    const { language, setLanguage } = this.props;
    let appLanguage = language;

    if (!appLanguage) {
      appLanguage = getLanguage();
      setLanguage(appLanguage);
    }

    setLocale(appLanguage);

    const isForceRTL = appLanguage === 'he' || appLanguage === 'ar';

    I18nManager.forceRTL(isForceRTL);
    I18nManager.allowRTL(isForceRTL);

    if ((!isForceRTL && isRTL) || (isForceRTL && !isRTL)) {
      setTimeout(() => RNRestart.Restart(), 1);
    }

    strings.setLanguage(appLanguage);
  };

  checkAuthentication = async () => {
    const { logOut, setUserInfo, setUserOriginInfo, getPromotionInfo, logAppEntry } = this.props;
    // logOut(); return;
    const accessToken = await authToken.getAccessToken();

    if (accessToken) {
      const appVersion = DeviceInfo.getVersion();
      const {
        is_authorized,
        should_force_update,
        should_sign_legal_documents,
        user_info,
      } = await auth.checkAuthentication({
        accessToken,
        appVersion,
        platform,
      });

      setUserInfo(user_info);

      if (should_force_update) {
        NavigationService.reset(screens.ForceUpdate);
      } else if (is_authorized) {
        api.setToken(accessToken);
        io.setToken(accessToken);
        this.authorizeUser(should_sign_legal_documents);
      } else {
        const refreshToken = await authToken.getRefreshToken();

        try {
          const { access_token, refresh_token } = await auth.refreshToken(refreshToken);
          authToken.setTokens(access_token, refresh_token);
          api.setToken(access_token);
          io.setToken(access_token);
          this.authorizeUser(should_sign_legal_documents);
        } catch (e) {
          if (R.pathOr(null, ['response', 'status'], e) === 401) {
            logOut();
          }
        }
      }
      SplashScreen.hide();

      getPromotionInfo();
      logAppEntry(appStates.INACTIVE);
    } else {
      appsFlyer.onInstallConversionData((res) => {
        const isFirstLaunch = res?.data?.is_first_launch;
        if (isFirstLaunch && JSON.parse(isFirstLaunch) === true) {
          const af_status = res?.data?.af_status ?? null;
          const campaign = res?.data?.campaign ?? null;
          setUserOriginInfo({ af_status, campaign });
        }
      });

      this.initializeAppsFlyer();

      NavigationService.reset(screens.UnauthorizedApplication);
    }
  };

  updateMarketplaceFilters = () => {
    const { feedLotFilters, setFeedLotFilters } = this.props;

    if (feedLotFilters.isOnlyVerifiedSellers) {
      setFeedLotFilters({ isOnlyVerifiedSellers: false });
    }
  };

  render() {
    return <View />;
  }
}

export default connect(
  (state) => ({
    userInfo: state.userInfo,
    language: state.app.language,
    feedLotFilters: getFeedLotFilters(state),
    isAdmin: communitySelectors.getIsUserAdmin(state),
  }),
  (dispatch) => ({
    logOut: () => dispatch(authOperations.logOut()),
    setUserOriginInfo: ({ af_status, campaign }) =>
      dispatch(authOperations.setUserOriginInfo({ af_status, campaign })),
    setLanguage: (language) => dispatch(appOperations.setLanguage(language)),
    logAppEntry: (appState) => dispatch(appOperations.logAppEntry(appState)),
    handleDeepLink: () => dispatch(appOperations.handleDeepLink()),
    setLocationPermission: (isGranted) => dispatch(appOperations.setLocationPermission(isGranted)),
    onNewMessage: () => dispatch(chatsOperations.onNewMessage()),
    onMarkAsRead: () => dispatch(chatsOperations.onMarkAsRead()),
    onMarkMessageAsRead: () => dispatch(chatsOperations.onMarkMessageAsRead()),
    onUserTypingMessage: () => dispatch(chatsOperations.onUserTypingMessage()),
    onBlockFromAnotherUser: () => dispatch(userBlockOperations.onBlockFromAnotherUser()),
    getCurrentUserInfo: () => dispatch(userOperations.getCurrentUserInfo()),
    setUserInfo: (userInfo) => dispatch(userOperations.setUserInfo(userInfo)),
    checkNotificationsPermissions: () =>
      dispatch(notificationOperations.checkNotificationsPermissions()),
    setFeedLotFilters: (filters) => dispatch(lotsOperations.setFeedLotFilters(filters)),
    getPromotionInfo: () => dispatch(appOperations.getPromotionInfo()),
  }),
)(AuthLanding);
