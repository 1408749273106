import { StyleSheet } from 'react-native';
import { colors, dimensions, rs } from '../../../../../styles';
import { isIos, isIphoneX } from '../../../../../utils/detectDevice';

const styles = StyleSheet.create({
  sectionHeaderContainer: {
    paddingBottom: 10,
    paddingTop: 20,
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.grayLighter,
  },
  keyboardAvoidingView: {
    flex: 1,
    marginBottom: isIos ? dimensions.doubleMedium : 0,
  },
  buttonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.halfMedium,
    marginBottom: isIphoneX ? dimensions.doubleMedium : 0,
  },
  walletsContainer: {
    paddingVertical: dimensions.doubleMedium,
    backgroundColor: colors.white,
    zIndex: -1,
    flexDirection: 'row',
    paddingHorizontal: dimensions.halfMedium,
  },
  walletBackgroundContainer: {
    alignItems: 'center',
    flex: 1,
    marginHorizontal: dimensions.halfMedium,
    borderRadius: 5,
    ...rs.shadow,
  },
  walletImage: {
    borderRadius: 5,
  },
  balanceBackground: {
    height: 64,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedWalletBorder: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    borderColor: colors.secondary,
    borderWidth: 2,
    borderRadius: 5,
  },
  selectedMembersContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    paddingVertical: dimensions.medium,
    paddingHorizontal: 8,
    backgroundColor: colors.white,
  },
  amountInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
  },
  userSelectorContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    paddingHorizontal: dimensions.medium,
    paddingTop: dimensions.doubleMedium,
    alignItems: 'center',
  },
  amountInput: {
    fontSize: 24,
    fontFamily: 'Rubik-Medium',
    paddingVertical: dimensions.medium,
  },
  tokenIcon: {
    height: 20,
    width: 20,
    marginRight: dimensions.halfMedium,
  },
  noteContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    paddingHorizontal: dimensions.medium,
    justifyContent: 'space-between',
    width: '100%',
  },
  notesInput: {
    width: '80%',
    height: 100,
    paddingTop: 20,
    textAlignVertical: 'top',
  },
  charactersCountText: {
    paddingTop: 20,
  },
});

export default styles;
