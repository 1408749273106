import React from 'react';
import styled from 'styled-components/native';
import { colors, dimensions } from '../../styles';
import Text from './Text';
import strings from '../../localization';

const Container = styled.View`
  height: 100%;
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
`;
const RelativeContainer = styled.View`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const Background = styled.View`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${colors.gray};
  opacity: 0.8;
`;

const LabelText = styled(Text)`
  color: ${colors.white};
`;

const ItemNotAvailableLabel = ({ title }) => (
  <Container>
    <RelativeContainer>
      <Background />
      <LabelText large>{title || strings.items.item_not_available}</LabelText>
    </RelativeContainer>
  </Container>
);

export default ItemNotAvailableLabel;
