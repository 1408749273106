import React, { Fragment } from 'react';
import styled from 'styled-components/native';
import Text from '../Text';
import TouchableItem from '../TouchableItem/index';
import { colors, dimensions } from '../../../styles';

const ButtonContainer = styled(TouchableItem)`
  flex: 1;
  height: ${({ medium }) => (medium ? 36 : 40)};
  align-items: center;
  justify-content: center;
  background-color: ${({ disabled }) => (disabled ? colors.grayLight : colors.blueLight)};
  border-radius: ${dimensions.borderRadius};
  margin-right: ${({ withMarginRight }) => (withMarginRight ? dimensions.halfXLarge : 0)};
`;

const ButtonText = styled(Text)`
  color: ${({ disabled }) => (disabled ? colors.gray : colors.white)};
`;

const SecondaryButtonContainer = styled(ButtonContainer)`
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${({ disabled }) => (disabled ? colors.grayLight : colors.blueLight)};
  margin-right: ${({ withMarginRight }) => (withMarginRight ? dimensions.halfXLarge : 0)};
`;

const SecondaryButtonText = styled(Text)`
  color: ${({ disabled }) => (disabled ? colors.gray : colors.blueLight)};
`;

const Button = ({ disabled, title, onPress, withMarginRight, medium, secondaryType = false }) => (
  <Fragment>
    {secondaryType ? (
      <SecondaryButtonContainer
        useOpacity={false}
        disabled={disabled}
        withMarginRight={withMarginRight}
        onPress={onPress}
        medium={medium}
      >
        <SecondaryButtonText medium disabled={disabled}>
          {title}
        </SecondaryButtonText>
      </SecondaryButtonContainer>
    ) : (
      <ButtonContainer
        useOpacity={false}
        disabled={disabled}
        withMarginRight={withMarginRight}
        onPress={onPress}
        medium={medium}
      >
        <ButtonText medium semiBold disabled={disabled}>
          {title}
        </ButtonText>
      </ButtonContainer>
    )}
  </Fragment>
);

export default Button;
