import React, { useEffect, useState, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, FilterBottomSheet } from '../../ReusableComponents';
import { communityOperations } from '../../../store/communityInfo';
import { appOperations } from '../../../store/app';
import { homeOperations } from '../../../store/home';
import MainTab from './MainTab';
import { rs } from '../../../styles';
import { isWeb } from '../../../utils/detectDevice';

const Home = ({ getUserCommunity, getCommunityHomePage, fetchAppData, fetchChatsData }) => {
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await getUserCommunity();

    getCommunityHomePage();
    fetchAppData();
    fetchChatsData();
  };

  return (
    <Container>
      <MainTab />
    </Container>
  );
};

export default connect(null, (dispatch) => ({
  getUserCommunity: () => dispatch(communityOperations.getUserCommunity()),
  getCommunityHomePage: () => dispatch(homeOperations.getCommunityHomePage()),
  fetchAppData: () => dispatch(appOperations.fetchAppData()),
  fetchChatsData: () => dispatch(appOperations.fetchChatsData()),
}))(Home);
