import React, { Fragment, memo } from 'react';
import T from 'prop-types';
import s from './styles';
import { Text, TouchableItem, View } from '../../../../../ReusableComponents';
import strings from '../../../../../../localization';
import { colors, rs } from '../../../../../../styles';
import { numberWithCommas } from '../../../../../../utils/stringHelper';
import { Image, StyleSheet } from 'react-native';

const CityItem = memo(({ item, onPress }) => {
  const { name, image, active_items_count } = item;
  return (
    <TouchableItem style={s.button} onPress={() => onPress(item)}>
      <Fragment>
        <Image
          source={{ uri: image || 'city_placeholder' }}
          style={s.itemImage}
          resizeMode="cover"
        />
        <View>
          <Text medium numberOfLines={1} ellipsizeMode="tail">
            {name}
          </Text>
          {!!active_items_count && (
            <Text color={colors.gray} style={rs.verySmallPaddingTop}>
              {`${numberWithCommas(active_items_count)} ${strings.groups.active_items}`}
            </Text>
          )}
        </View>
      </Fragment>
    </TouchableItem>
  );
});

CityItem.propTypes = {
  onPress: T.func,
  id: T.number,
  activeItemsCount: T.number,
  name: T.string,
  image: T.string,
};

export default CityItem;
