/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { SafeAreaView, View } from 'react-native';

const StatusBarPadding = () => (
  <SafeAreaView
    forceInset={{
      top: 'never',
      bottom: 'always',
    }}
  >
    <View style={{ height: 60 }} />
  </SafeAreaView>
);

export default StatusBarPadding;
