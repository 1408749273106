import React, { memo } from 'react';
import { FlatList, RefreshControl, StyleSheet } from 'react-native';
import T from 'prop-types';
import { colors, dimensions, rs } from '../../../../styles';
import { CenteredView } from '../../../ReusableComponents';
import Spinner from '../../../ReusableComponents/Spinner';

const s = StyleSheet.create({
  listContentContainer: {
    backgroundColor: colors.grayBackground,
    paddingTop: dimensions.halfMedium / 2,
    ...rs.tabBarPadding,
  },
  list: {
    backgroundColor: colors.grayBackground,
  },
});

const OffersList = memo(
  ({
    isLoading,
    isRefreshing,
    isLoadingMore,
    onLoadMore,
    onRefresh,
    ListEmptyComponent,
    ...listProps
  }) => (
    <FlatList
      keyExtractor={(item) => item.id.toString()}
      contentContainerStyle={s.listContentContainer}
      style={s.list}
      refreshControl={
        onRefresh ? <RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} /> : null
      }
      refreshing={isRefreshing}
      onEndReachedThreshold={0.5}
      onEndReached={onLoadMore || null}
      ListEmptyComponent={
        <CenteredView>{isLoading ? <Spinner /> : <ListEmptyComponent />}</CenteredView>
      }
      ListFooterComponent={isLoadingMore && <Spinner />}
      {...listProps}
    />
  ),
);

OffersList.propTypes = {
  isLoading: T.bool,
  isRefreshing: T.bool,
  isLoadingMore: T.bool,
  onLoadMore: T.func,
  onRefresh: T.func,
  ListEmptyComponent: T.elementType,
};

export default OffersList;
