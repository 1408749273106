import React from 'react';
import { View } from 'react-native';
import TouchableItem from '../TouchableItem';
import Text from '../Text';
import AngleIcon from '../AngleIcon';
import Image from '../Image';
import { dimensions, colors, rs } from '../../../styles';
import { StyleSheet } from 'react-native';
import strings from '../../../localization';
import { AnalyticsService, NavigationService } from '../../../services';
import screens from '../../../navigation/screens';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    height: 84,
    paddingVertical: dimensions.medium,
    paddingLeft: dimensions.medium,
    paddingRight: dimensions.doubleMedium,
    borderRadius: 4,
    borderTopWidth: 4,
    borderBottomWidth: 4,
    borderColor: '#EFF1F2',
    alignItems: 'center',
    marginTop: 30,
    backgroundColor: colors.white,
  },
  text: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    lineHeight: 22,
  },
  image: {
    height: 64,
    width: 100,
  },
});

const WishesBanner = ({ analyticsEventType }) => {
  const onPress = () => {
    if (analyticsEventType) {
      AnalyticsService.logEvent(analyticsEventType);
    }
    NavigationService.navigate(screens.Wishes);
  };
  return (
    <TouchableItem onPress={onPress}>
      <View style={s.contentContainer}>
        <Image style={s.image} source={{ uri: 'fulfil_wish_banner' }} resizeMode="contain" />
        <Text medium semiBold style={[s.text, rs.paddingHorizontal]}>
          {strings.home.wishes_banner}
        </Text>

        <AngleIcon color={colors.gray} size={26} />
      </View>
    </TouchableItem>
  );
};

export default WishesBanner;
