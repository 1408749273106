import styled from 'styled-components/native';
import { fontSizes, colors, dimensions } from '../../../styles/index';
import Text from '../Text';

const Container = styled.TouchableOpacity`
  padding-horizontal: ${dimensions.medium};
  justify-content: center;
  min-height: 48px;
`;

const Title = styled(Text)`
  font-size: ${fontSizes.medium - 1};
  color: ${({ disabled }) => (disabled ? colors.gray : colors.blueLight)};
`;

export { Container, Title };
