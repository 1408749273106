import React, { Component } from 'react';
import { Animated } from 'react-native';
import { connect } from 'react-redux';
import { lotsOperations } from '../../../../store/lots';
import ItemDetailsView from './components/ItemDetailsView';
import { Container } from '../../../ReusableComponents';

class SoldItemDetails extends Component {
  state = {
    item: {},
    scrollY: new Animated.Value(0),
  };

  async componentDidMount() {
    const { getItemDetails, route } = this.props;
    const item = route.params.item;

    this.setState({
      item: { ...item, images: [item.item_image] },
    });

    const { images, locations } = await getItemDetails(item.id);

    this.setState({
      item: { ...item, images, locations },
    });
  }

  render() {
    const { scrollY, item } = this.state;

    return (
      <Container>
        <ItemDetailsView
          scrollY={scrollY}
          item={item}
          isCurrentUser={false}
          hideBottomBar
          hideFavorites
          isSoldItem
        />
      </Container>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    getItemDetails: (itemId) => dispatch(lotsOperations.getItemDetails(itemId)),
  }),
)(SoldItemDetails);
