import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../styles';

const tokenImageSize = 60;
const tokenImageContainerSize = tokenImageSize + 10;

export default StyleSheet.create({
  tokenImageContainer: {
    width: tokenImageContainerSize,
    height: tokenImageContainerSize,
    borderRadius: tokenImageContainerSize / 2,
    borderWidth: 3,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.white,
    marginBottom: dimensions.halfMedium,
  },
  list: {
    alignSelf: 'center',
  },
  tokenButton: {
    alignItems: 'center',
    width: 90,
    justifyContent: 'center',
    height: tokenImageContainerSize + 64,
    marginHorizontal: dimensions.halfMedium,
  },
  tokenImage: {
    height: tokenImageSize,
    width: tokenImageSize,
    borderRadius: tokenImageSize / 2,
  },
  spinnerContainer: {
    height: tokenImageContainerSize + 64,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
