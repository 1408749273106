import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { mergeDeep } from '../../utils/stateHelper';
import * as types from './types';
import {
  ASK_TO_BUY,
  CONFIRM_CANCEL_PURCHASE,
  OFFER_CONFIRMED,
  ITEM_DELIVERED,
  ITEM_NOT_AVAILABLE,
  ITEM_SOLD,
  SELLER_LEFT_COMMUNITY,
  BUYER_LEFT_COMMUNITY,
  ITEM_REMOVED,
} from './offerStatuses';

const initialState = {
  isLoadingOffer: false,
  offerIds: [],
  offerEntities: {},

  buyingOffers: {
    askedToBuy: {
      offerIds: [],
      isLoading: false,
      isLoadingMore: false,
      isExistMore: true,
    },
    pendingDelivery: {
      offerIds: [],
      isLoading: false,
      isLoadingMore: false,
      isExistMore: true,
    },
    searched: {
      offerIds: [],
      isLoading: false,
      isLoadingMore: false,
      isExistMore: true,
    },
  },

  sellingOffers: {
    askedToBuy: {
      offerIds: [],
      isLoading: false,
      isLoadingMore: false,
      isExistMore: true,
    },
    pendingDelivery: {
      offerIds: [],
      isLoading: false,
      isLoadingMore: false,
      isExistMore: true,
    },
    searched: {
      offerIds: [],
      isLoading: false,
      isLoadingMore: false,
      isExistMore: true,
    },
  },

  wishFulfillmentOffers: {
    offerIds: [],
    isLoading: false,
    isLoadingMore: false,
    isExistMore: true,
  },

  offersCounters: {
    active_buying_offers_count: 0,
    active_selling_offers_count: 0,
    wishes_with_active_offers_count: 0,
  },
};

const reducer = handleActions(
  {
    [types.SET_OFFERS]: (state, { payload }) => ({
      offerEntities: payload.normalizedOffers.offerEntities,
      offerIds: payload.normalizedOffers.offerIds,
    }),

    [types.SET_OFFER_COUNTERS]: mergeDeep(({ payload }, state) => ({
      offersCounters: payload,
    })),

    [types.ADD_NEW_BUYING_OFFER]: mergeDeep(({ payload }, state) => ({
      // because buyer can't create offer from buying screen, don't need add id
      offerEntities: {
        [payload.offer.id]: payload.offer,
      },
    })),

    [types.ADD_NEW_SELLING_OFFER]: mergeDeep(({ payload }, state) => ({
      offerEntities: {
        [payload.offer.id]: payload.offer,
      },
      sellingOffers: {
        askedToBuy: {
          offerIds: R.uniq([payload.offer.id, ...state.sellingOffers.askedToBuy.offerIds]),
        },
        searched: {
          offerIds: R.uniq([payload.offer.id, ...state.sellingOffers.searched.offerIds]),
        },
      },
    })),

    [types.ADD_NEW_WISH_FULFILLMENT_OFFER]: mergeDeep(({ payload }, state) => ({
      offerEntities: {
        [payload.offer.id]: payload.offer,
      },
      wishFulfillmentOffers: {
        offerIds: R.uniq([payload.offer.id, ...state.wishFulfillmentOffers.offerIds]),
      },
    })),

    [types.UPDATE_OFFER_STATUS]: mergeDeep(({ payload }, state) => {
      const updatedState = {};

      const offerId = Number(payload.offerId);
      const offer = state.offerEntities[offerId];

      if (!R.isNil(offer) && !!offer.buyer_id) {
        const isBuyingOffer = payload.userId === Number(R.propOr(0, 'buyer_id', offer));
        const entityName = isBuyingOffer ? 'buyingOffers' : 'sellingOffers';

        const withoutInAskedToBuy = () => {
          if (!R.isEmpty(state[entityName].askedToBuy.offerIds)) {
            updatedState[entityName] = {
              ...updatedState[entityName],
              askedToBuy: {
                offerIds: R.without([offerId], state[entityName].askedToBuy.offerIds),
              },
            };
          }
        };

        const withoutInPendingDelivery = () => {
          if (!R.isEmpty(state[entityName].pendingDelivery.offerIds)) {
            updatedState[entityName] = {
              ...updatedState[entityName],
              pendingDelivery: {
                offerIds: R.without([offerId], state[entityName].pendingDelivery.offerIds),
              },
            };
          }
        };

        const withoutInSearched = () => {
          if (!R.isEmpty(state[entityName].searched.offerIds)) {
            updatedState[entityName] = {
              ...updatedState[entityName],
              searched: {
                offerIds: R.without([offerId], state[entityName].searched.offerIds),
              },
            };
          }
        };

        const addInAskedToBuy = () => {
          updatedState[entityName] = {
            ...updatedState[entityName],
            askedToBuy: {
              offerIds: R.uniq([offerId, ...state[entityName].askedToBuy.offerIds]),
            },
          };
        };
        const addInSearched = () => {
          updatedState[entityName] = {
            ...updatedState[entityName],
            searched: {
              offerIds: R.uniq([offerId, ...state[entityName].searched.offerIds]),
            },
          };
        };
        const addInPendingDelivery = () => {
          updatedState[entityName] = {
            ...updatedState[entityName],
            pendingDelivery: {
              offerIds: R.uniq([offerId, ...state[entityName].pendingDelivery.offerIds]),
            },
          };
        };

        const withoutInWishFulfillmentOffers = () => {
          if (!R.isEmpty(state.wishFulfillmentOffers.offerIds)) {
            updatedState.wishFulfillmentOffers = {
              offerIds: R.without([offerId], state.wishFulfillmentOffers.offerIds),
            };
          }
        };

        switch (Number(payload.offerStatusId)) {
          case ASK_TO_BUY: {
            withoutInAskedToBuy();
            withoutInSearched();
            withoutInPendingDelivery();
            break;
          }
          case CONFIRM_CANCEL_PURCHASE: {
            addInAskedToBuy();
            addInSearched();
            break;
          }
          case OFFER_CONFIRMED: {
            withoutInAskedToBuy();
            addInPendingDelivery();
            break;
          }
          case ITEM_DELIVERED: {
            withoutInPendingDelivery();
            withoutInSearched();
            withoutInWishFulfillmentOffers();
            break;
          }
          case ITEM_SOLD:
          case SELLER_LEFT_COMMUNITY:
          case BUYER_LEFT_COMMUNITY:
          case ITEM_NOT_AVAILABLE:
          case ITEM_REMOVED: {
            withoutInAskedToBuy();
            withoutInPendingDelivery();
            withoutInSearched();
            withoutInWishFulfillmentOffers();
            break;
          }
          default: {
            break;
          }
        }
      }

      return {
        ...updatedState,
        offerEntities: {
          [payload.offerId]: {
            status_id: payload.offerStatusId,
          },
        },
      };
    }),

    [types.UPDATE_OFFER]: mergeDeep(({ payload }) => ({
      offerEntities: {
        [payload.offer.id]: payload.offer,
      },
    })),

    [types.UPDATE_OFFERS]: mergeDeep(({ payload }) => ({
      offerEntities: payload.normalizedOffers.offerEntities,
    })),

    /** BUYING OFFERS */

    [types.BUYING_OFFERS_START]: mergeDeep(({ payload }) => ({
      buyingOffers: {
        [payload.entityName]: {
          [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: true,
        },
      },
    })),

    [types.BUYING_OFFERS_ERROR]: mergeDeep(({ payload }) => ({
      buyingOffers: {
        [payload.entityName]: {
          [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
        },
      },
    })),

    [types.BUYING_OFFERS_SUCCESS]: mergeDeep(({ payload }, state) => ({
      offerEntities: payload.offerEntities,
      buyingOffers: {
        [payload.entityName]: {
          offerIds: payload.isLoadMore
            ? R.uniq([
                ...R.path(['buyingOffers', payload.entityName, 'offerIds'], state),
                ...payload.offerIds,
              ])
            : payload.offerIds,
          isExistMore: payload.isExistMore,
          [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
        },
      },
    })),

    /** SELLING OFFERS */

    [types.SELLING_OFFERS_START]: mergeDeep(({ payload }) => ({
      sellingOffers: {
        [payload.entityName]: {
          [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: true,
        },
      },
    })),

    [types.SELLING_OFFERS_ERROR]: mergeDeep(({ payload }) => ({
      sellingOffers: {
        [payload.entityName]: {
          [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
        },
      },
    })),

    [types.SELLING_OFFERS_SUCCESS]: mergeDeep(({ payload }, state) => ({
      offerEntities: payload.offerEntities,
      sellingOffers: {
        [payload.entityName]: {
          offerIds: payload.isLoadMore
            ? R.uniq([
                ...R.path(['sellingOffers', payload.entityName, 'offerIds'], state),
                ...payload.offerIds,
              ])
            : payload.offerIds,
          isExistMore: payload.isExistMore,
          [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
        },
      },
    })),

    /** WISH FULFILLMENT OFFERS */

    [types.WISH_FULFILLMENT_OFFERS_START]: mergeDeep(({ payload }) => ({
      wishFulfillmentOffers: {
        [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: true,
      },
    })),

    [types.WISH_FULFILLMENT_OFFERS_ERROR]: mergeDeep(({ payload }) => ({
      wishFulfillmentOffers: {
        [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
      },
    })),

    [types.WISH_FULFILLMENT_OFFERS_SUCCESS]: mergeDeep(({ payload }, state) => ({
      offerEntities: payload.offerEntities,
      wishFulfillmentOffers: {
        offerIds: payload.isLoadMore
          ? R.uniq([...R.path(['wishFulfillmentOffers', 'offerIds'], state), ...payload.offerIds])
          : payload.offerIds,
        isExistMore: payload.isExistMore,
        [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
      },
    })),

    /** SINGLE OFFER */

    [types.OFFER_START]: mergeDeep(({ payload }) => ({
      isLoadingOffer: true,
    })),

    [types.OFFER_ERROR]: mergeDeep(({ payload }) => ({
      isLoadingOffer: false,
    })),

    [types.OFFER_SUCCESS]: mergeDeep(({ payload }) => ({
      isLoadingOffer: false,
      offerEntities: R.isEmpty(payload.offer)
        ? {}
        : {
            [payload.offer.id]: payload.offer,
          },
    })),
  },
  initialState,
);

export default reducer;
