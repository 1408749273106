import React from 'react';
import { View, StyleSheet } from 'react-native';
import T from 'prop-types';
import Icon from '../Icon';
import TouchableItem from '../TouchableItem';
import Text from '../Text';
import { colors, rs } from '../../../styles';

const s = StyleSheet.create({
  icon: {
    paddingTop: 4,
    paddingLeft: 1,
  },
  container: {
    width: '100%',
  },
});

const HeaderTitleButtonWithChevron = ({ title, onPress }) => (
  <TouchableItem onPress={onPress} style={s.container}>
    <View style={[rs.row, rs.alignItems, rs.justifyCenter]}>
      <Text semiBold large>
        {title}
      </Text>
      <Icon style={s.icon} name="chevron-down" size={18} color={colors.text} />
    </View>
  </TouchableItem>
);

HeaderTitleButtonWithChevron.propTypes = {
  title: T.string,
  onPress: T.func,
};

export default HeaderTitleButtonWithChevron;
