import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Alert } from 'react-native';
import {
  View,
  Input,
  Text,
  Separator,
  Button,
  Container,
  TouchableItem,
} from '../../../ReusableComponents';
import { colors, rs } from '../../../../styles';
import { notificationOperations } from '../../../../store/notifications';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import strings from '../../../../localization';
import marketingNotificationGroups from '../../../../constants/marketingNotificationGroups';
import s from './styles';

const SendNotificationsScreen = ({
  navigation,
  route,
  sendNotificationToCommunity,
  sendMarketingNotification,
}) => {
  const [targetUserGroup, setTargetUserGroup] = useState(marketingNotificationGroups.ALL_USERS);
  const [appAction, setAppAction] = useState(null);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  useEffect(() => {
    const isValidate = !!title && !!body && body.trim().length > 4;
    navigation.setParams({ onSubmit, isValidate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, body, targetUserGroup, appAction]);

  const onPressTargetGroup = () => {
    NavigationService.navigate(screens.NotificationTargetGroup, {
      selectedTargetGroupId: targetUserGroup,
      onSelectTargetGroup: setTargetUserGroup,
    });
  };

  const onPressAppAction = () => {
    NavigationService.navigate(screens.NotificationAppAction, {
      selectedAppAction: appAction,
      onSelectAppAction: setAppAction,
    });
  };

  const onSubmitHandler = () => {
    navigation.setParams({ isValidate: false });
    const isMarketingNotification = !!appAction;
    const params = {
      title: title.trim(),
      body: body.trim(),
      ...(isMarketingNotification && { targetGroupId: targetUserGroup, appAction }),
    };

    const operation = isMarketingNotification
      ? sendMarketingNotification
      : sendNotificationToCommunity;

    operation(params);
  };

  const onSubmit = () => {
    Alert.alert('', strings.send_notifications.send_notifications_description, [
      {
        text: strings.common.confirm,
        onPress: onSubmitHandler,
      },
      {
        text: strings.common.cancel,
        onPress: () => {},
        style: 'cancel',
      },
    ]);
  };

  return (
    <Container>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="handled"
      >
        <View style={s.amountInputContainer}>
          <Text medium style={[rs.bigPaddingTop, rs.paddingBottom, rs.backgroundGray]}>
            {strings.settings.target_group}
          </Text>

          <TouchableItem onPress={onPressTargetGroup} style={rs.bigPaddingVertical}>
            <Text medium>{strings.community_notification_groups[targetUserGroup]}</Text>
          </TouchableItem>
        </View>

        <View style={s.amountInputContainer}>
          <Text medium style={[rs.bigPaddingTop, rs.paddingBottom, rs.backgroundGray]}>
            {strings.settings.app_action}
          </Text>

          <TouchableItem onPress={onPressAppAction} style={rs.bigPaddingVertical}>
            {!!appAction ? (
              <Text medium>{appAction.toUpperCase()}</Text>
            ) : (
              <Text color={colors.grayBorder} small>
                {strings.settings.app_action_placeholder}
              </Text>
            )}
          </TouchableItem>
        </View>

        <View style={s.amountInputContainer}>
          <Text medium style={[rs.bigPaddingTop, rs.paddingBottom, rs.backgroundGray]}>
            {strings.common.title}
          </Text>

          <Input
            maxLength={32}
            onChangeText={setTitle}
            value={title}
            style={rs.bigPaddingVertical}
            placeholder={strings.send_notifications.title_placeholder}
            underlineColorAndroid="transparent"
          />
        </View>

        <Separator />

        <View style={s.amountInputContainer}>
          <Text medium style={[rs.bigPaddingTop, rs.paddingBottom, rs.backgroundGray]}>
            {strings.send_notifications.body}
          </Text>

          <Input
            maxLength={105}
            onChangeText={setBody}
            value={body}
            placeholder={strings.send_notifications.body_placeholder}
            underlineColorAndroid="transparent"
            multiline
            numberOfLines={4}
            style={s.bodyInput}
          />
        </View>

        <View style={s.buttonContainer}>
          <Button
            disabled={!route.params?.isValidate ?? false}
            onPress={onSubmit}
            title={strings.common.submit}
          />
        </View>
      </KeyboardAwareScrollView>
    </Container>
  );
};

export default connect(
  (state) => ({
    // adminCommunities: [],
  }),
  (dispatch) => ({
    sendNotificationToCommunity: (params) =>
      dispatch(notificationOperations.sendNotificationToCommunity(params)),
    sendMarketingNotification: (params) =>
      dispatch(notificationOperations.sendMarketingNotification(params)),
  }),
)(SendNotificationsScreen);
