import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';
import * as types from './types';

const initialState = {
  isLoading: false,
  isLoadingMore: false,
  isExistMore: true,
  cityIds: [],
  cityEntities: {},
};

const reducer = handleActions(
  {
    [types.CITIES_START]: mergeDeep(({ payload }) => ({
      [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: true,
    })),

    [types.CITIES_ERROR]: mergeDeep(({ payload }) => ({
      [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
    })),

    [types.CITIES_SUCCESS]: mergeDeep(({ payload }, state) => ({
      cityEntities: payload.cityEntities,
      cityIds: payload.isLoadMore ? [...state.cityIds, ...payload.cityIds] : payload.cityIds,
      isExistMore: payload.isExistMore,
      [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
    })),
  },
  initialState,
);

export default reducer;
