import React, { memo } from 'react';
import { View } from 'react-native';
import T from 'prop-types';
import { TextButton } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import { ModalsService } from '../../../../../services';
import modalTypes from '../../../../../constants/modalTypes';
import ruleTypes from '../../../../../constants/ruleTypes';
import { rs } from '../../../../../styles';

const RulesHeader = ({ isItem, communityId, communityName }) => (
  <View style={[rs.paddingHorizontal, rs.smallPaddingVertical]}>
    <TextButton
      title={`${communityName} ${
        isItem ? strings.rules.item_rules_title : strings.rules.wish_rules_title
      }`}
      onPress={() =>
        ModalsService.showFullScreenModal(modalTypes.RULES, {
          type: isItem ? ruleTypes.ITEM_RULES : ruleTypes.WISH_RULES,
          communityId,
        })
      }
    />
  </View>
);

RulesHeader.propTypes = {
  isItem: T.bool,
  communityName: T.string,
};

export default RulesHeader;
