import React from 'react';
import T from 'prop-types';
import { StyleSheet, FlatList, ImageBackground } from 'react-native';
import { View, Text, Icon, TouchableItem } from '../../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../../styles';

const s = StyleSheet.create({
  mainContainer: {
    paddingHorizontal: dimensions.halfMedium,
    paddingVertical: dimensions.medium,
  },
  itemContainer: {
    width: 68,
    paddingHorizontal: dimensions.halfMedium,
    alignItems: 'center',
  },
  imageContainer: {
    width: 42,
    height: 42,
    borderRadius: 21,
    marginBottom: dimensions.halfMedium,
  },
  image: {
    borderRadius: 21,
  },
  buttonContainer: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: colors.grayLight,
    justifyContent: 'center',
    alignItems: 'center',

    position: 'absolute',
    top: 0,
    right: 0,
    transform: [{ translateX: 7 }],
  },
});

const SelectedUsersList = ({ users, onPressUser }) => {
  return (
    <View style={s.mainContainer}>
      <FlatList
        data={users}
        keyExtractor={({ id }) => id.toString()}
        renderItem={({ item: user }) => {
          const onPress = () => {
            onPressUser(user);
          };

          return (
            <View style={s.itemContainer}>
              <ImageBackground
                source={{ uri: user.profile_image }}
                style={s.imageContainer}
                imageStyle={s.image}
                resizeMode="cover"
              >
                <TouchableItem onPress={onPress} style={s.buttonContainer}>
                  <Icon name="x" size={14} color={colors.white} />
                </TouchableItem>
              </ImageBackground>

              <Text xxsmall alignCenter>
                {user.name}
              </Text>
            </View>
          );
        }}
        listContainerStyle={rs.smallPaddingHorizontal}
        showsHorizontalScrollIndicator={false}
        horizontal
      />
    </View>
  );
};

SelectedUsersList.propTypes = {
  users: T.arrayOf(
    T.shape({
      id: T.oneOfType([T.number, T.string]).isRequired,
      name: T.string,
      profile_image: T.string,
    }),
  ).isRequired,
  onPressUser: T.func.isRequired,
};

export default SelectedUsersList;
