import React from 'react';
import { StyleSheet, ActivityIndicator } from 'react-native';
import { colors, dimensions } from '../../styles';
import { isAndroid, isWeb } from '../../utils/detectDevice';

const s = StyleSheet.create({
  spinner: {
    margin: dimensions.medium,
  },
});

const Spinner = ({ style, ...props }) => (
  <ActivityIndicator
    style={[s.spinner, style]}
    {...props}
    color={isAndroid || isWeb ? colors.violet : null}
  />
);

export default Spinner;
