import { handleActions } from 'redux-actions';
import { mergeDeep, mergeIn } from '../../utils/stateHelper';
import types from './types';
import appTypes from '../app/types';

const initialState = {
  userOriginInfo: {
    campaign_name: null,
    conversion_type: null,
  },
  isAuthorized: false,
  isSuspended: false,
};

const authReducer = handleActions(
  {
    [types.SET_USER_ORIGIN_INFO]: mergeDeep(({ payload }) => ({
      userOriginInfo: {
        campaign_name: payload.campaign,
        conversion_type: payload.af_status,
      },
    })),
    [types.SET_AUTHORIZATION]: mergeDeep(({ payload }) => ({
      ...payload,
    })),
    [appTypes.RESET_STATE]: mergeIn({
      ...initialState,
    }),
  },
  initialState,
);

export default authReducer;
