import * as R from 'ramda';
import * as actions from './actions';
import communitiesApi from '../../api/communities';
import { ACCOUNT_SUSPENDED_BY_ADMIN } from '../../../config/appConstants/httpCodes';
import { NavigationService } from '../../services';
import screens from '../../navigation/screens';

export const getUserCommunity = () => async (dispatch) => {
  try {
    const communityInfo = await communitiesApi.getUserCommunity();
    dispatch(actions.setCommunity({ communityInfo }));
  } catch (e) {
    if (R.path(['response', 'status'], e) === ACCOUNT_SUSPENDED_BY_ADMIN) {
      NavigationService.reset(screens.SuspendedUser);
    }
  }
};

export default {
  getUserCommunity,
};
