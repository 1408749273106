import discountsApi from '../../api/discounts';
import accountApi from '../../api/account';
import { setSubscriptionsDetails, increaseUsedDiscountsCounter } from './actions';
import { lotsOperations } from '../lots';

const getSubscriptionDetails = () => async (dispatch) => {
  try {
    const data = await accountApi.getSubscriptionDetails();
    dispatch(setSubscriptionsDetails(data));
  } catch (err) {}
};

const applyDiscount = (itemId, discountRate) => async (dispatch) => {
  try {
    await discountsApi.applyDiscount({ itemId, discountRate });
    dispatch(increaseUsedDiscountsCounter());
    dispatch(lotsOperations.getItemInfo(itemId));
  } catch (err) {
    dispatch(getSubscriptionDetails());
  }
};

export default {
  increaseUsedDiscountsCounter,
  getSubscriptionDetails,
  applyDiscount,
};
