import { createSelector } from 'reselect';
import * as R from 'ramda';

export const getItems = createSelector(
  [R.pathOr([], ['lots', 'itemIds']), R.pathOr({}, ['lots', 'itemEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getItemsTotalCount = createSelector(
  [R.pathOr(0, ['lots', 'itemsTotalCount'])],
  (count) => count,
);

export const getWishesTotalCount = createSelector(
  [R.pathOr(0, ['lots', 'wishesTotalCount'])],
  (count) => count,
);

export const getFilteredGroups = createSelector(
  [R.pathOr([], ['lots', 'feedLotFilters', 'groupIds']), R.pathOr({}, ['groups', 'groupEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getFilteredGroupsForWishes = createSelector(
  [R.pathOr([], ['lots', 'feedWishFilters', 'groupIds']), R.pathOr({}, ['groups', 'groupEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getFilteredGroup = createSelector(
  [R.pathOr([], ['lots', 'feedLotFilters', 'groupIds']), R.pathOr({}, ['groups', 'groupEntities'])],
  (ids, entities) => {
    return R.isEmpty(ids) ? null : entities[ids[0]];
  },
);

export const getWishes = createSelector(
  [R.pathOr([], ['lots', 'wishIds']), R.pathOr({}, ['lots', 'wishEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

const entityInfoDefaultValue = {
  ids: [],
  totalAmount: 0,
  isLoading: false,
  isLoadingMore: false,
  isExistMore: true,
};

const getUserLotsData = (entityInfo, entities) => {
  const { ids, totalAmount, isLoading, isLoadingMore, isExistMore } = entityInfo;

  return {
    data: ids.map((id) => entities[id]),
    totalAmount,
    isLoading,
    isLoadingMore,
    isExistMore,
  };
};

export const getUserActiveItemsData = createSelector(
  [
    (state, userId) =>
      R.pathOr(entityInfoDefaultValue, ['lots', 'userLots', userId, 'userActiveItems'], state),
    R.pathOr({}, ['lots', 'itemEntities']),
  ],
  getUserLotsData,
);

export const getUserSoldItemsData = createSelector(
  [
    (state, userId) =>
      R.pathOr(entityInfoDefaultValue, ['lots', 'userLots', userId, 'userSoldItems'], state),
  ],
  ({ items, totalAmount, isLoading, isLoadingMore, isExistMore }) => ({
    data: items || [],
    totalAmount,
    isLoading,
    isLoadingMore,
    isExistMore,
  }),
);

export const getUserPurchasedItemsData = createSelector(
  [
    (state, userId) =>
      R.pathOr(entityInfoDefaultValue, ['lots', 'userLots', userId, 'userPurchasedItems'], state),
  ],
  ({ items, totalAmount, isLoading, isLoadingMore, isExistMore }) => ({
    data: items || [],
    totalAmount,
    isLoading,
    isLoadingMore,
    isExistMore,
  }),
);

export const getUserWishesData = createSelector(
  [
    (state, userId) =>
      R.pathOr(entityInfoDefaultValue, ['lots', 'userLots', userId, 'userWishes'], state),
    R.pathOr({}, ['lots', 'wishEntities']),
  ],
  getUserLotsData,
);

export const getWishInfoById = createSelector(
  [R.pathOr([], ['lots', 'wishEntities']), (_, wishId) => wishId],
  (entities, id) => R.propOr({}, id, entities),
);

export const getItemInfoById = createSelector(
  [R.pathOr([], ['lots', 'itemEntities']), (_, itemId) => itemId],
  (entities, id) => R.propOr({}, id, entities),
);

export const getWishInfoByItemId = createSelector(
  [getItemInfoById, R.pathOr([], ['lots', 'wishEntities'])],
  (item, wishEntities) => {
    return R.propOr(null, item.wish_id, wishEntities);
  },
);

export const getSavedItems = createSelector(
  [R.pathOr([], ['lots', 'savedItems', 'ids']), R.pathOr({}, ['lots', 'itemEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getPendingItems = createSelector(
  [R.pathOr([], ['lots', 'pendingItems', 'ids']), R.pathOr({}, ['lots', 'itemEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getFeedLotFilters = createSelector(
  [R.pathOr({}, ['lots', 'feedLotFilters'])],
  (filters) => filters,
);

export const getFeedWishFilters = createSelector(
  [R.pathOr({}, ['lots', 'feedWishFilters'])],
  (filters) => filters,
);

export const getItemsBySellerId = createSelector(
  [R.pathOr({}, ['lots', 'itemEntities']), (_, sellerId) => sellerId],
  (entities, sellerId) => {
    return Object.values(entities).filter(
      (entity) => parseInt(entity?.seller_id, 10) === parseInt(sellerId, 10),
    );
  },
);

export default {
  getItems,
  getItemsTotalCount,
  getWishesTotalCount,
  getFilteredGroups,
  getFilteredGroup,
  getWishes,
  getUserActiveItemsData,
  getUserSoldItemsData,
  getUserPurchasedItemsData,
  getUserWishesData,
  getWishInfoById,
  getItemInfoById,
  getWishInfoByItemId,
  getSavedItems,
  getPendingItems,
  getFeedLotFilters,
  getFeedWishFilters,
  getFilteredGroupsForWishes,
  getItemsBySellerId,
};
