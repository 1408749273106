import { createAction, createActions } from 'redux-actions';
import types from './types';
import { RESET_GROUP_STATE } from '../constants';

export const setConnection = createAction(types.SET_CONNECTION);
export const setLanguage = createAction(types.SET_LANGUAGE);
export const setNotificationsPermissions = createAction(types.SET_NOTIFICATIONS_PERMISSION);
export const setLocationPermission = createAction(types.SET_LOCATION_PERMISSION);
export const setGeolocationCoordinates = createAction(types.SET_GEOLOCATION_COORDINATES);
export const resetGroupState = createAction(RESET_GROUP_STATE);

export const setFeedColumnStyle = createAction(types.SET_FEED_COLUMN_STYLE);

export const resetState = createAction(types.RESET_STATE);

export const setLastAccessDateInCurrentSession = createAction(
  types.SET_LAST_ACCESS_DATE_IN_CURRENT_SESSION,
);

export const setContactsPermission = createAction(types.SET_CONTACTS_PERMISSION);
export const setContacts = createAction(types.SET_CONTACTS);
