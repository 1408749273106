/* eslint-disable*/
import * as React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { colors, dimensions } from '../../styles';
import Text from './Text';

export const LABEL_POSITION = {
  RIGHT: 'right',
  LEFT: 'left',
};

export default class CircleCheckBox extends React.Component {
  static defaultProps = {
    checked: false,
    disabled: false,
    outerSize: 18,
    filterSize: 15,
    innerSize: 11,
    outerColor: colors.blueLight,
    filterColor: colors.white,
    innerColor: colors.blueLight,
    label: '',
    labelPosition: LABEL_POSITION.RIGHT,
    styleLabel: {},
  };

  constructor(props) {
    super(props);
    const outerSize =
      parseInt(props.outerSize) < 10 || isNaN(parseInt(props.outerSize))
        ? 10
        : parseInt(props.outerSize);
    const filterSize =
      parseInt(props.filterSize) > outerSize + 3 || isNaN(parseInt(props.filterSize))
        ? outerSize - 3
        : parseInt(props.filterSize);
    const innerSize =
      parseInt(props.innerSize) > filterSize + 5 || isNaN(parseInt(props.innerSize))
        ? filterSize - 5
        : parseInt(props.innerSize);

    const customStyle = StyleSheet.create({
      _circleOuterStyle: {
        width: outerSize,
        height: outerSize,
        borderRadius: outerSize / 2,
        backgroundColor: props.outerColor,
      },
      _circleFilterStyle: {
        width: filterSize,
        height: filterSize,
        borderRadius: filterSize / 2,
        backgroundColor: props.filterColor,
      },
      _circleInnerStyle: {
        width: innerSize,
        height: innerSize,
        borderRadius: innerSize / 2,
        backgroundColor: props.innerColor,
      },
    });

    this.state = {
      customStyle,
    };
  }

  render() {
    return (
      <TouchableOpacity disabled={this.props.disabled} onPress={this._onToggle.bind(this)}>
        <View style={[styles.checkBoxContainer, this.props.styleCheckboxContainer]}>
          {this._renderLabel(LABEL_POSITION.LEFT)}
          <View style={[styles.alignStyle, this.state.customStyle._circleOuterStyle]}>
            <View style={[styles.alignStyle, this.state.customStyle._circleFilterStyle]}>
              {this._renderInner()}
            </View>
          </View>
          {this._renderLabel(LABEL_POSITION.RIGHT)}
        </View>
      </TouchableOpacity>
    );
  }

  _onToggle() {
    if (this.props.onToggle) {
      this.props.onToggle(!this.props.checked);
    }
  }

  _renderInner() {
    return this.props.checked ? (
      <View style={this.state.customStyle._circleInnerStyle} />
    ) : (
      <View />
    );
  }

  _renderLabel(position) {
    return this.props.label.length > 0 && position === this.props.labelPosition ? (
      <Text style={[styles.checkBoxLabel, this.props.styleLabel]}>{this.props.label}</Text>
    ) : (
      <View />
    );
  }
}

const styles = StyleSheet.create({
  checkBoxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: dimensions.halfMedium,
  },
  alignStyle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkBoxLabel: {
    marginLeft: dimensions.medium,
    marginRight: dimensions.medium,
  },
});
