import React, { memo } from 'react';
import T from 'prop-types';
import { StyleSheet, Image, FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import { View, Text, TouchableItem, FastImage } from '../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../styles';
import strings from '../../../../../localization';
import { NavigationService } from '../../../../../services';
import rewardAmauntTypes from '../../../../../constants/rewardAmauntTypes';
import { getIsUnverifiedUserEmail } from '../../../../../store/userInfo/selectors';
import { getPersonalRewardNavigationScreen } from '../../../../../utils/personalRewards';

const s = StyleSheet.create({
  itemContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 80,
    backgroundColor: colors.white,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3,
    elevation: 4,
    marginHorizontal: dimensions.medium,
    marginVertical: dimensions.halfMedium,
    paddingHorizontal: dimensions.medium,
  },

  itemImage: {
    height: 80 - dimensions.doubleMedium,
    width: 54,
    marginRight: dimensions.medium,
  },
  rewardAmountContainer: {
    height: '100%',
    width: 78,
    borderLeftWidth: 1,
    borderLeftColor: colors.grayLight,
    justifyContent: 'center',
    paddingLeft: dimensions.medium,
  },
});

const rewardColor = {
  BUY_FIRST_ITEM_COUNT: '#59B88C',
  SELL_FIRST_ITEM_COUNT: '#079189',
  UPLOAD_FIRST_ITEM_COUNT: '#189BC9',
  NEW_REFERRED_GROUP_USER: '#E97881',
  FIRST_FULFILLMENT_ITEM_SOLD: '#F28C3B',
};

const Item = memo(({ item }) => {
  const isUnverifiedEmail = useSelector(getIsUnverifiedUserEmail);

  const onPress = () => {
    const screen = getPersonalRewardNavigationScreen(item.app_action, { isUnverifiedEmail });
    NavigationService.navigate(screen);
  };

  const rewardColor = {
    BUY_FIRST_ITEM_COUNT: '#59B88C',
    SELL_FIRST_ITEM_COUNT: '#079189',
    UPLOAD_FIRST_ITEM_COUNT: '#189BC9',
    NEW_REFERRED_GROUP_USER: '#E97881',
    FIRST_FULFILLMENT_ITEM_SOLD: '#F28C3B',
  }[item.reward_type];

  return (
    <TouchableItem onPress={onPress} style={s.itemContainer}>
      <FastImage source={{ uri: item.icon_url }} style={s.itemImage} />
      <View style={rs.flex}>
        <Text semiBold medium>
          {item.short_title}
        </Text>

        <Text color={colors.gray} style={rs.verySmallPaddingTop}>
          <Text color={rewardColor || colors.blueLight} style={rs.verySmallPaddingTop} semiBold>
            {item.count_to_next_goal}{' '}
            {strings.rewards[item.section === 'referral' ? 'referrals' : 'items']}
          </Text>{' '}
          {strings.rewards.to_next_badge}
        </Text>
      </View>
      <View style={s.rewardAmountContainer}>
        <Text color={colors.gray}>
          {item.type === rewardAmauntTypes.fixed
            ? strings.notifications.earn
            : strings.notifications.earn_up_to}{' '}
        </Text>
        <Text color={rewardColor || colors.blueLight} style={rs.verySmallPaddingTop} semiBold>
          {item.max_reward_amount} {strings.rewards.points}
        </Text>
      </View>
    </TouchableItem>
  );
});

Item.propTypes = {
  // profileImage: T.string,
};

export default Item;
