import React, { memo, Fragment } from 'react';
import T from 'prop-types';
import { View, FlatList } from 'react-native';
import { rs } from '../../../styles';
import s from './styles';
import TouchableItem from '../TouchableItem';
import FastImage from '../FastImage';
import Text from '../Text';
import NavigationService from '../../../services/NavigationService';
import screens from '../../../navigation/screens';
import { AnalyticsService } from '../../../services';
import { isAndroid, isWeb } from '../../../utils/detectDevice';
import { isRTL } from '../../../utils/rtlHelper';

const ANDROID_ITEMS_COUNT = 10;

const Item = memo(({ item, onSelect }) => (
  <TouchableItem style={[s.itemContainer]} onPress={() => onSelect(item)}>
    <Fragment>
      <FastImage style={s.image} source={{ uri: item.profile_image }} />

      <Text xsmall alignCenter numberOfLines={2}>
        {item.name}
      </Text>
    </Fragment>
  </TouchableItem>
));

const HorizontalUsersList = memo(({ users, analyticsEventType }) => {
  // https://github.com/facebook/react-native/issues/19150
  const data = isAndroid && isRTL ? users.slice(0, ANDROID_ITEMS_COUNT) : users;

  const onSelect = (user) => {
    if (analyticsEventType) {
      AnalyticsService.logEvent(analyticsEventType);
    }
    NavigationService.navigate(screens.UserProfile, {
      name: user.name,
      userId: user.id,
      profile_image: user.profile_image,
    });
  };

  return (
    <View style={s.contentContainer}>
      <FlatList
        horizontal
        inverted
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={[s.listContentContainerStyle, isWeb && rs.webContainer]}
        data={data}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => <Item item={item} onSelect={onSelect} />}
      />
    </View>
  );
});

HorizontalUsersList.propTypes = {
  users: T.array,
};

export default HorizontalUsersList;
