import React, { Fragment, memo } from 'react';
import { View } from 'react-native';
import { colors, rs } from '../../../../../styles';
import { ItemRulesButtonContainer, RequiredText } from './styles';
import strings from '../../../../../localization';
import { Separator } from '../../../../ReusableComponents';

const RulesHeader = memo(() => (
  <View style={[rs.paddingHorizontal, rs.paddingTop]}>
    <RequiredText semiBold color={colors.redDarker}>
      {strings.lots.required_cc}
    </RequiredText>
  </View>
));

export default RulesHeader;
