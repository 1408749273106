import React, { Fragment, memo } from 'react';
import {
  CommunityMembersList,
  FilterButton,
  FilterHorizontalList,
  View,
} from '../../../../ReusableComponents';
import { rs } from '../../../../../styles';
import strings from '../../../../../localization';
import communityMemberFilters from '../../../../../constants/communityMemberFilters';
import MembersListSectionTitle from '../../../MembersScreen/components/MembersListSectionTitle';
import MembersHistory from '../../../MembersScreen/components/MembersHistory';

const filterTitleMap = {
  [communityMemberFilters.ALL]: strings.members.filter_by,
  [communityMemberFilters.TOP_SELLERS]: strings.members.top_sellers,
  [communityMemberFilters.NEAR_ME]: strings.members.near_me,
  [communityMemberFilters.MOST_FOLLOWED]: strings.members.most_followed,
  [communityMemberFilters.PURCHASED_FROM]: strings.members.purchased_from,
};

const MembersScene = memo(
  ({
    sortOptions,
    membersFilter,
    onSetFilter,
    onPressResetFilter,
    searchTerm,
    onSaveMemberSearch,
    communityMembersSearchHistory,
    showMembersSearchHistory,
    onRemoveMemberSearch,
  }) => {
    return (
      <Fragment>
        <View>
          <FilterHorizontalList style={[rs.backgroundWhite]}>
            <FilterButton
              options={sortOptions}
              selectedOptionIds={[membersFilter]}
              onChange={onSetFilter}
              onPressResetFilter={onPressResetFilter}
              optionKeyName="type"
              optionTitleName="title"
              primaryButtonTitle={filterTitleMap[membersFilter]}
              filterTitle={strings.members.filter_members_by}
              isActive={membersFilter !== communityMemberFilters.ALL}
              isMultipleSelection={false}
              resetOnActivePress
            />
          </FilterHorizontalList>
        </View>

        <CommunityMembersList
          searchValue={searchTerm}
          onPress={onSaveMemberSearch}
          filter={membersFilter}
          extraData={communityMembersSearchHistory}
          ListHeaderComponent={
            <Fragment>
              {showMembersSearchHistory && (
                <Fragment>
                  <MembersListSectionTitle title={strings.members.recent} />

                  <MembersHistory
                    communityMembersSearchHistory={communityMembersSearchHistory}
                    onSaveMemberSearch={onSaveMemberSearch}
                    onRemoveMemberSearch={onRemoveMemberSearch}
                  />

                  <MembersListSectionTitle title={strings.members.members} />
                </Fragment>
              )}
            </Fragment>
          }
        />
      </Fragment>
    );
  },
);

export default MembersScene;
