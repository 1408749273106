import React, { useCallback, useState } from 'react';

const usePopover = (ref) => {
  const [isVisible, setVisible] = useState(false);
  const [targetLayout, setTargetLayout] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const handleMountPress = useCallback(() => {
    ref.current?.measureInWindow((x, y, width, height) => {
      setTargetLayout({
        x,
        y,
        width,
        height,
      });
      setVisible((prevState) => !prevState);
    });
  }, []);

  const hide = useCallback(() => {
    setVisible(false);
  }, []);

  return { isVisible, targetLayout, handleMountPress, hide };
};

export default usePopover;
