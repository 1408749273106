import React, { memo, Fragment } from 'react';
import { RefreshControl, StyleSheet, View, FlatList } from 'react-native';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../Spinner';
import TripleColumnsLotListItem from '../ListItems/Lots/TripleColumnsLotListItem';
import CenteredView from '../Containers/CenteredView';
import { EmptyListWithImage } from '../EmptyList';
import strings from '../../../localization';
import { dimensions, colors, rs } from '../../../styles';
import DoubleColumnsLotListItem from '../ListItems/Lots/DoubleColumnsLotListItem';
import itemTypes from '../../../constants/itemTypes';
import { AnalyticsService, NavigationService } from '../../../services';
import screens from '../../../navigation/screens';
import { formatDoubleColumns, formatTripleColumns } from '../../../utils/multipleListColumsHelper';
import { lotsOperations } from '../../../store/lots';
import { isIos } from '../../../utils/detectDevice';
// import { Fade, Placeholder, PlaceholderLine, PlaceholderMedia } from 'rn-placeholder';

const s = StyleSheet.create({
  contentContainerStyle: {
    backgroundColor: colors.white,
    paddingVertical: dimensions.medium,
    ...rs.tabBarPadding,
  },
  // eslint-disable-next-line react-native/no-color-literals
  grayBackground: {
    backgroundColor: '#f5f5f5',
  },
  columnWrapperStyle: {
    justifyContent: 'center',
  },
  emptyListStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  jcCenter: {
    justifyContent: 'center',
  },
  jcSpaceAround: {
    justifyContent: 'space-around',
  },
});

// const EmptyPlaceholder = () => (
//   <Placeholder Animation={Fade}>
//     <View style={[rs.row, rs.smallPaddingHorizontal, rs.marginBottom]}>
//       <View
//         style={[
//           rs.smallMarginHorizontal,
//           { borderWidth: 1, borderColor: colors.grayLighter, borderRadius: 3 },
//         ]}
//       >
//         <PlaceholderMedia size={(dimensions.width - dimensions.medium * 3) / 2} />
//         <PlaceholderLine
//           width={20}
//           height={14}
//           style={[rs.marginBottom, rs.smallMarginTop, rs.marginHorizontal]}
//         />
//         <PlaceholderLine
//           width={50}
//           height={14}
//           style={[rs.smallMarginBottom, rs.marginHorizontal]}
//         />
//       </View>
//       <View
//         style={[
//           rs.smallMarginHorizontal,
//           { borderWidth: 1, borderColor: colors.grayLighter, borderRadius: 3 },
//         ]}
//       >
//         <PlaceholderMedia size={(dimensions.width - dimensions.medium * 3) / 2} />
//         <PlaceholderLine
//           width={20}
//           height={14}
//           style={[rs.marginBottom, rs.smallMarginTop, rs.marginHorizontal]}
//         />
//         <PlaceholderLine
//           width={50}
//           height={14}
//           style={[rs.smallMarginBottom, rs.marginHorizontal]}
//         />
//       </View>
//     </View>
//   </Placeholder>
// );

const LotsList = memo(
  ({
    forwardedRef,
    data,
    isLoading,
    ListHeaderComponent,
    emptyText,
    doubleColumnLists,
    isLoadingMore,
    onLoadMore,
    isRefreshing,
    onRefresh,
    isSoldList,
    itemType = null,
    onEndReachedThreshold = 0.9,
    ListEmptyComponent,
    contentContainerStyle,
    analyticsEventType,
    screenName = null,
    ...listProps
  }) => {
    const userId = useSelector((state) => state.userInfo.id);
    const dispatch = useDispatch();

    const onPress = (lot) => {
      if (analyticsEventType) {
        AnalyticsService.logEvent(analyticsEventType);
      }
      if (lot.is_wish) {
        NavigationService.push(screens.WishDetails, { wishId: lot.id });
      } else if (isSoldList) {
        NavigationService.navigate(screens.SoldItemDetails, { item: lot });
      } else if (itemType === itemTypes.SOLD || itemType === itemTypes.PURCHASED) {
        NavigationService.push(screens.SnapshotItemDetailsScreen, { item: lot });
      } else {
        NavigationService.push(screens.ItemDetails, { itemId: lot.id, itemType, screenName });
      }
    };

    const onToggleFavorites = (item) => {
      const { mark_as_favorite } = item;

      if (mark_as_favorite) {
        dispatch(lotsOperations.removeItemFromFavorites(item.id));
      } else {
        dispatch(lotsOperations.addItemToFavorites(item.id));
      }
    };

    return (
      <FlatList
        ref={forwardedRef}
        contentContainerStyle={[s.contentContainerStyle, contentContainerStyle]}
        columnWrapperStyle={[s.columnWrapperStyle]}
        emptyListStyle={s.emptyListStyle}
        key={doubleColumnLists ? 2 : 3}
        scrollEventThrottle={100}
        numColumns={doubleColumnLists ? 2 : 3}
        data={doubleColumnLists ? formatDoubleColumns(data) : formatTripleColumns(data)}
        extraData={data}
        showsVerticalScrollIndicator={false}
        keyExtractor={(lot) => lot.id}
        refreshControl={
          onRefresh ? <RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} /> : null
        }
        refreshing={isRefreshing}
        onEndReachedThreshold={onEndReachedThreshold}
        onEndReached={onLoadMore || null}
        renderItem={({ item, index }) =>
          doubleColumnLists ? (
            <DoubleColumnsLotListItem
              onPress={onPress}
              onToggleFavorites={onToggleFavorites}
              lot={item}
              isSoldItem={isSoldList}
              itemType={itemType}
              currentUserId={userId}
            />
          ) : (
            <TripleColumnsLotListItem
              onPress={onPress}
              onToggleFavorites={onToggleFavorites}
              lot={item}
              index={index}
              isSoldItem={isSoldList}
              itemType={itemType}
              currentUserId={userId}
            />
          )
        }
        ListHeaderComponent={
          <Fragment>
            {!!ListHeaderComponent && ListHeaderComponent()}
            {doubleColumnLists && <View style={{ height: dimensions.halfMedium }} />}
          </Fragment>
        }
        ListEmptyComponent={
          <CenteredView>
            {isLoading ? (
              <Spinner />
            ) : ListEmptyComponent ? (
              <ListEmptyComponent />
            ) : (
              <EmptyListWithImage
                image="empty_state_search"
                text={emptyText || strings.emptyLists.emptyGroupItemsList}
              />
            )}
          </CenteredView>
        }
        ListFooterComponent={isLoadingMore && <Spinner />}
        // https://devunet.atlassian.net/browse/SHAREITT-2018
        // removed for iOS to prevent UI issues (sometimes <FlatList /> doesn't render with this prop)
        removeClippedSubviews={!isIos}
        {...listProps}
      />
    );
  },
);

LotsList.propTypes = {
  data: T.array,
  isLoading: T.bool,
  ListHeaderComponent: T.node,
  emptyText: T.string,
  doubleColumnLists: T.bool,
  isLoadingMore: T.bool,
  onLoadMore: T.func,
  isRefreshing: T.bool,
  onRefresh: T.func,
  isSoldList: T.bool,
};

export default LotsList;
