import * as R from 'ramda';
import api from './api';
import { toFormData } from '../utils/formDataHelper';
import { getImageType } from '../utils/stringHelper';
import { COMMUNITY_MEMBERS_LIMIT } from '../constants/listLimits';

export default {
  getCurrentUserInfo: () => api.get(`getCurrentUserInfo`),

  updateInfo: ({
    userName,
    userAvatar,
    email,
    gender,
    birthDate,
    hasEmailSubscription,
    foundationName,
    foundationDescription,
  }) => {
    const form = new FormData();
    form.append('account_name', userName);

    if (email) {
      form.append('email', email);
    }

    form.append('gender', gender ? gender : '');

    if (!R.isNil(hasEmailSubscription)) {
      form.append('has_email_subscription', !!hasEmailSubscription);
    }

    if (!!birthDate) {
      form.append('birth_date', birthDate.getTime());
    }

    if (userAvatar) {
      form.append('user_avatar', {
        uri: userAvatar,
        name: `userAvatar.${getImageType(userAvatar)}`,
        type: `image/${getImageType(userAvatar)}`,
      });
    }

    if (!R.isNil(foundationName)) {
      form.append('foundation_name', foundationName);
    }

    if (!R.isNil(foundationDescription)) {
      form.append('foundation_description', foundationDescription);
    }

    return api.post('account/updateUserInfo', form);
  },

  updateCampusEmail: (email) => api.post('/account/updateCampusEmail', { email }),

  checkNicknameAvailability: (nickname) => api.get(`checkNicknameAvailability/${nickname}`),

  updateNickname: (nickname) => api.post('account/updateNickname', { nickname }),

  applyReferralCode: (referral_code, has_welcome_reward = false) =>
    api.post('applyReferralCode', {
      referral_code,
      has_welcome_reward,
    }),

  applyCode: (code) => api.post('applyCode', { code }),

  getAllContacts: (contacts) => api.post('getAllContacts', contacts),

  createGroup: ({
    account_id,
    group_name,
    token_icon,
    token_name,
    eos_token_name,
    group_members,
  }) =>
    api.post(
      'createNewGroup',
      toFormData({ account_id, group_name, token_icon, token_name, eos_token_name, group_members }),
    ),

  checkEOSTokenName: (eosTokenName) =>
    api.post('isAvilableEosTokenName', {
      eos_token_name: eosTokenName,
    }),

  getReferralRewards: (communityId) => api.get(`getReferralRewards/${communityId}`),

  sendReport: ({ reporter_id, item_id, profile_id, report_reason, report_type }) =>
    api.post('createReport', {
      reporter_id,
      item_id,
      profile_id,
      report_reason,
      report_type,
    }),

  validateDeepLink: ({ action_type, id }) =>
    api.post('validateDeepLink', {
      action_type,
      id,
    }),

  getAssembledWallet: () => api.get('getAssembledWallet'),

  getRewardWallet: () => api.get('getRewardWallet'),

  getSubscriptionDetails: () => api.get(`getSubscriptionDetails`),

  getUserCredibilityDetails: (userId) => api.get(`getUserCredibilityDetails/${userId}`),

  getUserEndorsements: ({ communityId, userId }) =>
    api.post('getUserEndorsements', {
      community_id: communityId,
      endorsed_user_id: userId,
    }),

  endorseUser: ({ communityId, userId, endorsementId }) =>
    api.post('endorseUser', {
      community_id: communityId,
      endorsement_id: endorsementId,
      endorsed_user_id: userId,
    }),

  unselectUserEndorsement: ({ communityId, userId, endorsementId }) =>
    api.post('unselectUserEndorsement', {
      community_id: communityId,
      endorsement_id: endorsementId,
      endorsed_user_id: userId,
    }),

  getMembersWhoEndorsedUser: ({ communityId, userId, endorsementId, skip = 0, limit = 100 }) =>
    api.post('getMembersWhoEndorsedUser', {
      community_id: communityId,
      endorsement_id: endorsementId,
      endorsed_user_id: userId,
      limit,
      skip,
    }),

  getAllBuyersList: ({ communityId, skip = 0, limit = COMMUNITY_MEMBERS_LIMIT }) =>
    api.post('getAllBuyersList', {
      community_id: communityId,
      limit,
      skip,
    }),
  sendEmailVerification: (email) => api.put('sendEmailVerification', { email }),

  verifyEmail: (code) => api.put('verifyEmail', { verification_code: code }),

  verifyCampusEmail: (code) => api.put('account/verifyCampusEmail', { code }),

  getIsMemberAlreadyEndorsed: (user_id, community_id) =>
    api.get(`getIsMemberAlreadyEndorsed?user_id=${user_id}&community_id=${community_id}`),
  logAppEntry: (app_state) => api.post('account/logAppEntry', { app_state }),
};
