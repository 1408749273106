import React from 'react';
import { Image } from 'react-native';
import strings from '../../../../localization';
import { openInStore } from '../../../../utils/linkingHelper';
import { colors, rs } from '../../../../styles';
import { headerStyleTransparent } from '../../../../styles/headerStyle';
import { Text, CenteredView, StaticHeightButton } from '../../../ReusableComponents';

const ForceUpdate = () => {
  return (
    <CenteredView style={rs.bigPaddingHorizontal}>
      <Image
        source={{ uri: 'force_update' }}
        resizeMode="contain"
        style={{ height: 140, width: 140 }}
      />
      <Text title style={rs.bigMarginVertical} semiBold alignCenter>
        {strings.app_update.title}
      </Text>
      <Text medium alignCenter style={rs.bigMarginBottom}>
        {strings.app_update.description}
      </Text>
      <StaticHeightButton title={strings.app_update.update_app} onPress={openInStore} />
    </CenteredView>
  );
};

export default ForceUpdate;

ForceUpdate.navigationOptions = () => ({
  title: null,
  ...headerStyleTransparent,
});
