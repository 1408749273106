import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import Button from '../../Buttons/Button';
import Text from '../../Text';
import { colors, dimensions, fontSizes } from '../../../../styles';
import strings from '../../../../localization';

const ContentContainer = styled.View`
  align-items: center;
`;

const AdminAvatar = styled.Image`
  height: 68px;
  width: 68px;
  margin-top: -34px;
`;

const MainContainer = styled.View`
  padding-vertical: ${dimensions.doubleMedium};
  padding-horizontal: ${dimensions.doubleMedium};
  width: ${dimensions.width - 3 * dimensions.medium};
`;

const ButtonContainer = styled.View`
  width: 100%;
  height: 40px;
  margin-top: 72px;
`;

const Title = styled(Text)`
  font-size: 28px;
  margin-bottom: 25px;
  text-align: center;
`;

const Description = styled(Text)`
  font-size: ${fontSizes.large};
  text-align: center;
  margin-bottom: ${dimensions.halfIndent};
`;

const RefundMissingFunds = ({ onPress }) => (
  <ContentContainer>
    <AdminAvatar source={{ uri: 'round_logo' }} />

    <MainContainer>
      <Title>{strings.modals.refund_missing_funds_title}</Title>
      <Description>{strings.modals.refund_missing_funds_text}</Description>

      <ButtonContainer>
        <Button title={strings.common.ok} onPress={onPress} />
      </ButtonContainer>
    </MainContainer>
  </ContentContainer>
);

export default RefundMissingFunds;
