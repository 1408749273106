import api from './api';
import { COMMUNITY_MEMBERS_LIMIT } from '../constants/listLimits';

export default {
  getBadgeRewards: (communityId) => api.get(`getBadgeRewards/${communityId}`),

  getUserInfoRewards: (communityId) => api.get(`getUserInfoRewards/${communityId}`),

  getRewardsProgress: (communityId) => api.get(`getRewardsProgress/${communityId}`),

  getReferrals: (communityId, skip = '', limit = COMMUNITY_MEMBERS_LIMIT) =>
    api.get(`getReferrals/${communityId}?limit=${limit}&skip=${skip}`),

  getReferralsWhoSoldItem: (communityId, skip = '', limit = COMMUNITY_MEMBERS_LIMIT) =>
    api.get(`getReferralsWhoSoldItem/${communityId}?limit=${limit}&skip=${skip}`),
};
