import React, { Fragment } from 'react';
import T from 'prop-types';
import styled from 'styled-components/native';
import memberStatuses from '../../../constants/memberStatuses';
import UserAvatar from '../UserAvatar';
import AngleIcon from '../AngleIcon';
import { colors, dimensions } from '../../../styles';
import Text from '../Text';
import TouchableItem from '../TouchableItem';

const MainContainer = styled(TouchableItem)`
  flex-direction: row;
  align-items: center;
  height: 64px;
  padding-right: ${dimensions.medium};
`;

const InfoView = styled.View`
  justify-content: center;
`;

const DetailContainer = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MemberStatus = styled(Text)`
  color: ${props => (props.color ? props.color : colors.GreenLight)};
  margin-top: 4px;
`;

const statusColor = {
  active: colors.GreenLight,
  waiting_approval: colors.PinkLight,
};

class SelectableUserItem extends React.PureComponent {
  render() {
    const { profileImage, status, name, onSelect } = this.props;

    return (
      <MainContainer useOpacity={false} onPress={onSelect}>
        <Fragment>
          <UserAvatar uri={profileImage} />

          <DetailContainer>
            <InfoView>
              <Text medium>{name}</Text>
              <MemberStatus xsmall color={statusColor[status]}>
                {memberStatuses[status]}
              </MemberStatus>
            </InfoView>
          </DetailContainer>

          <AngleIcon />
        </Fragment>
      </MainContainer>
    );
  }
}

SelectableUserItem.propTypes = {
  profileImage: T.string,
  status: T.string,
  name: T.string,
  onSelect: T.func.isRequired,
};

export default SelectableUserItem;
