import React, { Fragment, memo } from 'react';
import * as R from 'ramda';
import { View } from 'react-native';
import T from 'prop-types';
import {
  AngleIcon,
  FontIcon,
  Separator,
  Text,
  TouchableItem,
} from '../../../../ReusableComponents';
import { colors, rs } from '../../../../../styles';
import Title from './Title';
import s, { BoxButton, TitleContainer } from './styles';
import strings from '../../../../../localization';
import NavigationService from '../../../../../services/NavigationService';
import screens from '../../../../../navigation/screens';

const BrandsSelector = memo(
  ({ isCommunityHasBrands, onSelectBrand, selectedBrand, brands }) =>
    isCommunityHasBrands && (
      <TouchableItem
        style={s.buttonContainer}
        useOpacity={false}
        onPress={() =>
          NavigationService.navigate(screens.Brands, { onSelectBrand, brands, selectedBrand })
        }
      >
        <Fragment>
          <Text medium style={s.buttonTitle}>
            {strings.lots.brand}
          </Text>
          {selectedBrand && selectedBrand.name ? (
            <Text semiBold color={colors.blueLight}>
              {selectedBrand.name}
            </Text>
          ) : (
            <Text color={colors.gray}>{strings.filters.select_category}</Text>
          )}

          <AngleIcon />
        </Fragment>
      </TouchableItem>
    ),
);

BrandsSelector.propTypes = {
  brands: T.array,
  isCommunityHasBrands: T.bool,
  onSelectBrand: T.func,
  selectedBrand: T.object,
};

export default BrandsSelector;
