import React, { Fragment, memo } from 'react';
import { StyleSheet } from 'react-native';
import { Text, View, TouchableItem, AngleIcon, Image } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import { numberWithCommas } from '../../../../../utils/stringHelper';
import { colors, dimensions, rs } from '../../../../../styles';

const s = StyleSheet.create({
  button: {
    flexDirection: 'row',
    paddingVertical: dimensions.small,
    alignItems: 'center',
  },

  backgroundImage: {
    height: 70,
    width: 70,
    borderRadius: 35,
  },
  groupInfoContainer: {
    marginLeft: 20,
    justifyContent: 'center',
    paddingHorizontal: dimensions.medium,
  },
});

const GroupItem = memo(
  ({ onPress, id, name, activeItemsCount, backgroundImage, isAllGroups, withArrow = true }) => (
    <View style={rs.paddingHorizontal}>
      <View style={rs.backgroundWhite}>
        <TouchableItem style={s.button} onPress={() => onPress(id, name)}>
          <Fragment>
            {isAllGroups ? (
              <Image
                style={s.backgroundImage}
                resizeMode="cover"
                source={{
                  uri: backgroundImage,
                }}
                ext="jpg"
              />
            ) : (
              <Image
                style={s.backgroundImage}
                resizeMode="cover"
                source={{
                  uri: backgroundImage,
                }}
              />
            )}

            <View style={s.groupInfoContainer}>
              <Text large semiBold numberOfLines={1} ellipsizeMode="tail">
                {name}
              </Text>
              {!!activeItemsCount && (
                <Text color={colors.gray} style={rs.smallMarginTop}>
                  {`${numberWithCommas(activeItemsCount)} ${strings.groups.active_items}`}
                </Text>
              )}
            </View>

            {withArrow && <AngleIcon color={colors.gray} />}
          </Fragment>
        </TouchableItem>
      </View>
    </View>
  ),
);

export default GroupItem;
