import { StyleSheet } from 'react-native';
import { dimensions } from '../../../../styles';
import { isIphoneX } from '../../../../utils/detectDevice';

export default StyleSheet.create({
  infoIconContainer: {
    position: 'absolute',
    right: 0,
  },
  titleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingHorizontal: dimensions.doubleMedium + 6,
  },
  boxContainer: {
    paddingVertical: dimensions.medium,
  },
  summaryText: {
    textAlign: 'center',
  },
  amountInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
  },
  amountInput: {
    fontSize: 34,
    fontFamily: 'Assistant-Bold',
  },
  errorMessageContainer: {
    height: dimensions.halfMedium * 3,
    alignSelf: 'center',
    justifyContent: 'flex-end',
  },
  subtitle: {
    paddingHorizontal: dimensions.medium,
    paddingBottom: dimensions.halfMedium,
  },
  buttonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.halfMedium,
    marginBottom: isIphoneX ? dimensions.doubleMedium : dimensions.medium,
  },
});
