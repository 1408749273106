import React from 'react';
import { View } from 'react-native';
import TouchableItem from '../TouchableItem';
import Text from '../Text';
import { dimensions, colors, rs } from '../../../styles';
import { StyleSheet } from 'react-native';
import strings from '../../../localization';
import { AnalyticsService, NavigationService } from '../../../services';
import screens from '../../../navigation/screens';
import Icon from '../Icon';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    height: 70,
    paddingBottom: dimensions.medium,
    paddingLeft: dimensions.medium,
    paddingRight: dimensions.doubleMedium,
    borderBottomWidth: 4,
    borderColor: '#EFF1F2',
    alignItems: 'center',
    marginBottom: dimensions.doubleMedium,
  },
  text: {
    lineHeight: 22,
  },
  image: {
    height: 64,
    width: 100,
  },

  closeIconButton: {
    position: 'absolute',
    top: dimensions.halfMedium,
    right: dimensions.halfMedium,
  },
});

const GetLocation = ({ analyticsEventType, onClose }) => {
  const onPress = () => {
    if (analyticsEventType) {
      AnalyticsService.logEvent(analyticsEventType);
    }
    NavigationService.navigate(screens.EarnTokens);
  };
  return (
    <TouchableItem onPress={onPress}>
      <View style={s.contentContainer}>
        <Text medium semiBold style={[s.text, rs.paddingHorizontal]}>
          {strings.marketplace.location_banner}
        </Text>
      </View>

      {!!onClose && (
        <TouchableItem onPress={onClose} style={s.closeIconButton}>
          <Icon name={'x'} size={16} color={colors.grayBorder} />
        </TouchableItem>
      )}
    </TouchableItem>
  );
};

export default GetLocation;
