import React from 'react';
import T from 'prop-types';
import { StyleSheet, View } from 'react-native';
import Icon from '../../../../ReusableComponents/Icon';
import { colors } from '../../../../../styles';

const s = StyleSheet.create({
  checkboxContainer: {
    width: 16,
    height: 16,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.grayLight,
    backgroundColor: colors.white,
    marginTop: 3,
  },
  checkboxContainerSelected: {
    borderColor: colors.blueLight,
    backgroundColor: colors.blueLight,
  },
});

const CheckBoxIcon = ({ selected, style }) => {
  return (
    <View style={[s.checkboxContainer, selected && s.checkboxContainerSelected, style]}>
      <Icon name="check" size={14} color={colors.white} withoutRTLScale />
    </View>
  );
};

CheckBoxIcon.propTypes = {
  selected: T.bool,
  style: T.any,
};

CheckBoxIcon.defaultProps = {
  selected: false,
  style: null,
};

export default CheckBoxIcon;
