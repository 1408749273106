import { StyleSheet } from 'react-native';
import colors from '../../../styles/colors';
import { dimensions, rs } from '../../../styles';

export default StyleSheet.create({
  contentContainer: {},
  itemContainer: {
    paddingRight: dimensions.halfMedium,
  },
  listContentContainerStyle: {
    paddingHorizontal: dimensions.medium,
  },
});
