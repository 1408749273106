import React from 'react';
import LocalizedStrings from 'react-native-localization';
import en from './en.json';
import he from './he.json';
import de from './de.json';
import nl from './nl.json';
import ar from './ar.json';
import br from './br.json';

const translations = {
  en,
  de,
  he,
  nl,
  ar,
  br,
};

const strings = new LocalizedStrings(translations);
export default strings;
