import React from 'react';
import { View, StyleSheet } from 'react-native';
import { colors, dimensions, rs } from '../../styles';
import { TouchableItem, Text, Image, Popover } from '../../components/ReusableComponents';

import strings from '../../localization';
import { NavigationService } from '../../services';
import screens from '../screens';
import HeaderPostButton from './HeaderPostButton';
import HeaderMenu from './HeaderMenu';
import LanguageSelector from './LanguageSelector';

const HeaderNavBar = () => {
  const onLogoPress = () => {
    NavigationService.navigate(screens.Home);
  };

  const onOpenMarketplacePress = () => {
    NavigationService.navigate(screens.MarketplaceTab);
  };

  return (
    <View style={s.container}>
      <View style={[rs.row, rs.alignCenter, rs.fullWidth]}>
        <View style={[rs.row, rs.flexGrow, rs.alignCenter]}>
          <TouchableItem onPress={onLogoPress}>
            <Image style={s.logo} source={{ uri: 'text_logo' }} resizeMode="contain" />
          </TouchableItem>

          <TouchableItem style={s.button} onPress={onOpenMarketplacePress}>
            <Text color={colors.darkCyan} size={'medium'}>
              {strings.items.marketplace}
            </Text>
          </TouchableItem>

          <HeaderPostButton />
        </View>

        <View style={[rs.row, rs.alignCenter]}>
          <LanguageSelector />
          <HeaderMenu />
        </View>
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    height: 80,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: dimensions.doubleMedium,

    backgroundColor: colors.white,
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayLighter,
  },

  logo: {
    width: 120,
    height: 60,
  },

  notSelectedTex: {
    opacity: 0.2,
  },

  languageContainer: {
    paddingRight: dimensions.large,
    flexDirection: 'row',
    alignItems: 'center',
  },

  button: {
    paddingVertical: dimensions.small,
    paddingHorizontal: dimensions.small,
  },
  popoverOption: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    paddingHorizontal: dimensions.halfMedium,
    paddingVertical: 10,
  },
});

export default HeaderNavBar;
