import * as React from 'react';
import styled from 'styled-components/native';
import { StyleSheet, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import strings from '../../localization';
import appLanguages from '../../constants/appLanguages';
import { FontIcon, Text, TouchableItem } from '../../components/ReusableComponents';
import Popover, { usePopover } from '../../components/ReusableComponents/PortalPopover';
import { colors, dimensions } from '../../styles';
import { appOperations } from '../../store/app';

const StyledTouchableOpacity = styled.TouchableOpacity`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  margin-right: 1rem;
`;

const s = StyleSheet.create({
  popoverOption: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    paddingHorizontal: dimensions.halfMedium,
    paddingVertical: 10,
  },
});

const getLanguageTitle = (language) => {
  switch (language) {
    case appLanguages.EN:
      return strings.languages.english;
    case appLanguages.HE:
      return strings.languages.hebrew;
    case appLanguages.BR:
      return strings.languages.portuguese;
    case appLanguages.DE:
      return strings.languages.german;
    default:
      return strings.languages.language;
  }
};

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const wrapperRef = React.useRef(null);
  const language = useSelector((state) => state.app.language);
  const { isVisible, targetLayout, handleMountPress, hide } = usePopover(wrapperRef);

  return (
    <View>
      <StyledTouchableOpacity ref={wrapperRef} onPress={handleMountPress}>
        <FontIcon name="Settings_language" size={20} color={colors.text} withoutRTLScale />
        <Text>{language?.toUpperCase()}</Text>
      </StyledTouchableOpacity>

      {isVisible && (
        <Popover onPress={hide} targetLayout={targetLayout}>
          <View>
            {Object.values(appLanguages).map((languageCode) => {
              const onPress = async () => {
                hide();
                await dispatch(appOperations.setLanguage(languageCode));
                window.location.reload();
              };

              return (
                <TouchableItem key={languageCode} onPress={onPress} style={s.popoverOption}>
                  <Text semiBold>{getLanguageTitle(languageCode)}</Text>
                </TouchableItem>
              );
            })}
          </View>
        </Popover>
      )}
    </View>
  );
};

export default LanguageSelector;
