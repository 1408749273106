import { handleActions } from 'redux-actions';
import { mergeDeep, mergeIn } from '../../utils/stateHelper';

import types from './types';
import { RESET_GROUP_STATE } from '../constants';

const initialState = {
  followersCount: 0,
  followingsCount: 0,
};

const followersReducer = handleActions(
  {
    [types.SET_FOLLOWER_COUNTS]: mergeDeep(({ payload }, state) => ({
      followersCount: payload.followersCount,
      followingsCount: payload.followingsCount,
    })),
    [types.INCREASE_FOLLOWINGS_COUNTER]: mergeDeep(({ payload }, state) => ({
      followingsCount: state.followingsCount + 1,
    })),
    [types.INCREASE_FOLLOWERS_COUNTER]: mergeDeep(({ payload }, state) => ({
      followersCount: state.followersCount + 1,
    })),
    [types.REDUCE_FOLLOWINGS_COUNTER]: mergeDeep(({ payload }, state) => ({
      followingsCount: state.followingsCount - 1,
    })),
  },
  initialState,
);

export default followersReducer;
