import React, { memo } from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import T from 'prop-types';
import Text from './Text';
import Input from './Input';
import { colors, dimensions, rs } from '../../styles';
import strings from '../../localization';
import { getIsRTLString } from '../../utils/rtlHelper';

const s = StyleSheet.create({
  rootContainer: {
    paddingBottom: dimensions.medium,
  },
  contentContainer: {
    width: '100%',
  },
  textInput: {
    flex: 1,
    paddingVertical: dimensions.medium,
    color: colors.blueLight,
  },
  inputContainer: {
    alignItems: 'center',
  },
  statusText: {},
  spinner: {
    paddingBottom: dimensions.halfMedium,
  },
  helperText: {
    lineHeight: 20,
  },
});

const getInputAlignment = (inputValue, placeholder, useForcedRTL) => {
  const isRTLPlaceholder = getIsRTLString(placeholder);
  const isRTLValue = getIsRTLString(inputValue);
  if ((isRTLPlaceholder && (!inputValue || isRTLValue)) || (useForcedRTL && isRTLValue)) {
    return 'right';
  }

  return 'left';
};

const InputWithTitleAndValidation = memo(
  ({
    title,
    value,
    maxLength,
    placeholder,
    onChangeText,
    withValidation,
    withValidationAtRight,
    isLoading,
    isAvailable,
    helperText,
    errorText,
    minLength,
    inputStyle,
    inputContainerStyle,
    infoTextContainerStyle,
    useForcedRTL,
    ...inputProps
  }) => (
    <View style={s.rootContainer}>
      <View style={[s.contentContainer, inputContainerStyle]}>
        {!!title && <Text medium>{title}</Text>}

        <View style={[rs.row, s.inputContainer]}>
          {withValidationAtRight &&
            !!value &&
            value.length >= minLength &&
            (isLoading ? (
              <ActivityIndicator style={s.spinner} />
            ) : (
              <Text xsmall style={s.statusText} bol color={isAvailable ? colors.green : colors.red}>
                {isAvailable ? strings.common.available : strings.common.taken}
              </Text>
            ))}

          <Input
            style={[s.textInput, inputStyle]}
            maxLength={maxLength}
            onChangeText={onChangeText}
            placeholderTextColor={colors.grayBorder}
            value={value}
            placeholder={placeholder}
            underlineColorAndroid="transparent"
            textAlign={getInputAlignment(value, placeholder, useForcedRTL)}
            {...inputProps}
          />

          {withValidation &&
            !!value &&
            value.length >= minLength &&
            (isLoading ? (
              <ActivityIndicator style={s.spinner} />
            ) : (
              <Text xsmall style={s.statusText} bol color={isAvailable ? colors.green : colors.red}>
                {isAvailable ? strings.common.available : strings.common.taken}
              </Text>
            ))}
        </View>
      </View>
      <View style={infoTextContainerStyle}>
        {!!errorText && (
          <Text color={colors.red} style={[s.helperText, rs.smallMarginBottom]}>
            {errorText}
          </Text>
        )}
        {!!helperText && (
          <Text color={colors.gray} style={s.helperText}>
            {helperText}
          </Text>
        )}
      </View>
    </View>
  ),
);

InputWithTitleAndValidation.propTypes = {
  isLoading: T.bool,
  isAvailable: T.bool,
  withValidation: T.bool,
  withValidationAtRight: T.bool,
  value: T.string,
  title: T.string,
  placeholder: T.string,
  onChangeText: T.func,
  maxLength: T.number,
  useForcedRTL: T.bool,
};

InputWithTitleAndValidation.defaultProps = {
  useForcedRTL: false,
};

export default InputWithTitleAndValidation;
