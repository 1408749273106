import * as React from 'react';
import T from 'prop-types';
import IcoMoon from 'react-icomoon';
import iconSet from '../../../assets/fonts/icons/selection.json';
import { colors, dimensions } from '../../../styles';

const Icon = ({ name, color, size, style, withoutRTLScale }) => (
  <IcoMoon
    iconSet={iconSet}
    icon={name}
    size={size}
    color={color}
    // TODO: somehow it can cause error (Failed to set an indexed property on 'CSSStyleDeclaration': Index property setter is not supported)
    // style={style}
  />
);

Icon.propTypes = {
  name: T.string.isRequired,
  color: T.string,
  size: T.number,
  style: T.any,
};

Icon.defaultProps = {
  color: colors.grayDarker,
  size: dimensions.iconSize,
};

export default Icon;
