/* eslint-disable max-len */
import api from './api';
import { COMMUNITY_MEMBERS_LIMIT } from '../constants/listLimits';

export default {
  getUserInfo: (userId) => api.get(`getUserInfo/${userId}`),

  getPendingApprovalGroupMembers: (
    groupId,
    adminId,
    searchTerm,
    skip = 0,
    limit = COMMUNITY_MEMBERS_LIMIT,
  ) =>
    api.get(
      `group/getPendingApprovalGroupMembers/${adminId}/${groupId}?limit=${limit}&skip=${skip}&search_term=${searchTerm}`,
    ),

  getDeclinedGroupMembers: (
    groupId,
    adminId,
    searchTerm,
    skip = 0,
    limit = COMMUNITY_MEMBERS_LIMIT,
  ) =>
    api.get(
      `group/getDeclinedGroupMembers/${adminId}/${groupId}?limit=${limit}&skip=${skip}&search_term=${searchTerm}`,
    ),

  declineGroupMember: ({ adminId, userId, groupId }) =>
    api.post(`group/declineGroupMember`, { admin_id: adminId, user_id: userId, group_id: groupId }),

  approveGroupMember: ({ adminId, userId, groupId }) =>
    api.post(`group/approveGroupMember`, { admin_id: adminId, user_id: userId, group_id: groupId }),

  getRecentMembersSearches: (communityId) => api.get(`getRecentMembersSearches/${communityId}`),

  saveMemberSearch: ({ userId, communityId }) =>
    api.post('saveMemberSearch', { user_account_id: userId, community_id: communityId }),

  removeMemberSearch: ({ userId, communityId }) =>
    api.post('removeMemberSearch', { user_account_id: userId, community_id: communityId }),
};
