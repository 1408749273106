import styled from 'styled-components/native';
import { fontSizes, colors } from '../../styles';
import { isWeb } from '../../utils/detectDevice';

const getFontFamily = ({ bold, extraBold, semiBold, light, extraLight }) => {
  let fontFamily = isWeb ? 'Rubik' : 'Rubik-Regular';

  if (light) fontFamily = isWeb ? 'Rubik' : 'Rubik-Regular';
  if (extraBold) fontFamily = 'Rubik-Bold';
  if (semiBold) fontFamily = isWeb ? 'Rubik-SemiBold' : 'Rubik-Medium';
  if (bold) fontFamily = isWeb ? 'Rubik-Bold' : 'Rubik-SemiBold';
  if (extraLight) fontFamily = isWeb ? 'Rubik' : 'Rubik-Regular';

  return `font-family: ${fontFamily}`;
};

const getFontSize = ({
  title = false,
  headline = false,
  large = false,
  medium = false,
  small = false,
  xsmall = false,
  xxsmall = false,
  xbig = false,
  xxbig = false,
}) => {
  let fontSize = fontSizes.small;

  if (title) fontSize = fontSizes.title;
  if (headline) fontSize = fontSizes.headline;
  if (large) fontSize = fontSizes.large;
  if (medium) fontSize = fontSizes.medium;
  if (small) fontSize = fontSizes.small;
  if (xsmall) fontSize = fontSizes.xsmall;
  if (xxsmall) fontSize = fontSizes.xxsmall;
  if (xbig) fontSize = fontSizes.xbig;
  if (xxbig) fontSize = fontSizes.xxbig;

  return `font-size: ${fontSize}`;
};

const Text = styled.Text`
  text-align: ${({ alignCenter }) => (alignCenter ? 'center' : 'left')};
  font-size: ${fontSizes.medium};
  color: ${(props) => props.color || colors.text};
  font-family: Rubik;
  ${(props) => getFontFamily(props)};
  ${(props) => getFontSize(props)};
`;

export default Text;
