import { Platform, Dimensions } from 'react-native';
import DeviceInfo from 'react-native-device-info';

const { width, height } = Dimensions.get('window');
const iPhoneWithNotchDimensions = [780, 812, 844, 896, 926, 926];

export const isAndroid = Platform.OS === 'android';

export const isIos = Platform.OS === 'ios';
export const isWeb = Platform.OS === 'web';
export const platform = Platform.OS;

export const { isTVOS, isPad } = Platform;

export const isSimulator = async () => {
  return await DeviceInfo.isEmulatorSync();
};

export const isSmallAndroid = isAndroid && height < 600 && width < 400;
export const isSmallIos = isIos && height <= 667 && width <= 375;
export const isSmallDevice = isSmallAndroid || isSmallIos;

export const isIphoneX =
  (isIos &&
    !isPad &&
    !isTVOS &&
    (iPhoneWithNotchDimensions.includes(height) || iPhoneWithNotchDimensions.includes(width))) ||
  height === 896 ||
  width === 896;

export const getIsDeviceWithNotch = () => {
  return DeviceInfo.hasNotch();
};
