import { StyleSheet } from 'react-native';
import colors from '../../../styles/colors';
import { dimensions, rs } from '../../../styles';

export default StyleSheet.create({
  contentContainer: {
    // transform: [{ scaleX: -1 }],
    // alignItems: 'flex-end',
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 80,
    borderRadius: 4,
    backgroundColor: colors.white,
    marginHorizontal: dimensions.medium,
    marginVertical: dimensions.halfMedium,
    paddingHorizontal: dimensions.medium,
    borderWidth: dimensions.hairline,
    borderColor: colors.grayLight,
    ...rs.shadow,
  },
  image: {
    height: 80 - dimensions.medium,
    width: 80 - dimensions.medium,
    marginRight: dimensions.medium,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  listContentContainerStyle: {},
});
