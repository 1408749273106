import { StyleSheet } from 'react-native';
import { dimensions, colors } from '../../../../../../styles';

export default StyleSheet.create({
  selectedFiltersAmountContainer: {
    height: 20,
    minWidth: 20,
    paddingHorizontal: 4,
    borderRadius: 4,
    marginRight: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.blueLight,
  },
  buttonContainer: {
    // transform: [{ scaleX: -1 }],
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 50,
    marginHorizontal: 4,
    height: 32,
    paddingLeft: 12,

    borderRadius: 16,
    borderWidth: 1,
    backgroundColor: colors.white,
    borderColor: colors.grayLight,
  },
  squareButtonContainer: {
    borderRadius: 5,
    paddingHorizontal: 10,
  },
  icon: {
    paddingHorizontal: 12,
  },
  filterIcon: {
    marginRight: 4,
  },
  squareButtonFilterIcon: {
    marginRight: 8,
  },
});
