import { useDebouncedCallback } from 'use-debounce';

export default (callback, delay = 1000) => {
  const [callbackWithOnce] = useDebouncedCallback(callback, delay, {
    // with these options, a callback will be invoked immediately but only once during a specified delay
    leading: true,
    trailing: false,
  });

  return callbackWithOnce;
};
