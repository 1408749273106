import React from 'react';
import { ActivityIndicator } from 'react-native';
import styled from 'styled-components/native';

const LoaderContainer = styled.View`
  width: 100%;
`;

const ModalBackground = styled.View`
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background-color: #00000040;
`;

const LoaderWrapper = styled.View`
  background-color: #00000090;
  height: 100;
  width: 100;
  border-radius: 10;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const LoaderModal = styled.Modal``;

const Loader = ({ showLoader }) => {
  return (
    <LoaderContainer>
      {showLoader ? (
        <LoaderModal
          transparent
          animationType="none"
          visible={showLoader}
          onRequestClose={() => {}}
        >
          <ModalBackground>
            <LoaderWrapper>
              <ActivityIndicator size="large" animating color="#fff" />
            </LoaderWrapper>
          </ModalBackground>
        </LoaderModal>
      ) : null}
    </LoaderContainer>
  );
};

export default Loader;
