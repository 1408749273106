import React from 'react';
import { View, ScrollView, Text} from '../../../ReusableComponents';
import { headerStyle, rs } from '../../../../styles';
import { AboutText } from './styles';
import strings from '../../../../localization';

const InviteFriendsInfo = ({ route }) => {
  const {
    referral_joined_community_reward,
    referral_reward,
    referral_sold_first_item_reward,
    max_token_rewards,
    max_reward_amount_per_month,
  } = route.params.referralRewards;

  const aboutText = strings.invite_friends.about_text
    .replace('referral_joined_community_reward', referral_joined_community_reward)
    .replace('referral_uploaded_first_item_reward', referral_sold_first_item_reward)
    .replace('max_token_rewards', max_token_rewards)
    .replace('referral_reward', referral_reward)
    .replace('max_reward_amount_per_month', max_reward_amount_per_month);

  return (
    <ScrollView>
      <View style={[rs.bigPaddingHorizontal, rs.bigPaddingVertical]}>
        <Text large bold style={rs.bigMarginBottom}>
          {strings.invite_friends.about_title}
        </Text>

        <AboutText medium>{aboutText}</AboutText>
      </View>
    </ScrollView>
  );
};

export default InviteFriendsInfo;
