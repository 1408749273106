import { StyleSheet } from 'react-native';
import { dimensions, colors } from '../../../../styles';

export default StyleSheet.create({
  buttonContainer: {
    flex: 1,
    paddingHorizontal: dimensions.medium,
    paddingTop: dimensions.doubleMedium,
  },
  contentContainer: {
    flex: 1,
  },
  titleText: {
    textAlign: 'center',
    marginHorizontal: dimensions.doubleMedium,
    marginVertical: dimensions.doubleMedium,
  },
  amountInputContainer: {
    width: '100%',
    paddingHorizontal: dimensions.medium,
  },
  amountInput: {
    fontSize: 34,
    fontFamily: 'Assistant-Bold',
  },
  balanceText: {
    textAlign: 'center',
    marginTop: 4,
  },
  noteTitleText: {
    marginTop: dimensions.medium * 3,
    marginBottom: dimensions.halfMedium,
  },
  bodyInput: {
    paddingHorizontal: dimensions.medium,
    paddingTop: dimensions.medium,
    marginBottom: dimensions.halfMedium,
    textAlignVertical: 'top',
    height: 100,
  },
});
