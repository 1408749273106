import React from 'react';
import Button from '../../Buttons/Button';
import {
  ContentContainer,
  AdminAvatar,
  MainContainer,
  Title,
  Description,
  ButtonContainer,
} from './styles';
import strings from '../../../../localization';
import CenteredView from '../../Containers/CenteredView';

import { NavigationService } from '../../../../services';

const Info = ({ onPress, params }) => (
  <CenteredView>
    <MainContainer>
      <Title semiBold>{params.title}</Title>
      <Description>{params.description}</Description>

      <ButtonContainer>
        <Button
          title={strings.common.ok}
          onPress={() => {
            NavigationService.goBack();
            onPress();
          }}
        />
      </ButtonContainer>
    </MainContainer>
  </CenteredView>
);

export default Info;
