const formatTripleColumns = (data) => {
  if (data.length < 1) return [];

  const result = [...data];

  if (result.length % 3 !== 0) result.push({});
  if (result.length % 3 !== 0) result.push({});

  return result;
};

const formatDoubleColumns = (data) => {
  if (data.length < 1) return [];

  const result = [...data];

  if (result.length % 2 !== 0) result.push({});

  return result;
};

export { formatTripleColumns, formatDoubleColumns };
