import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';

import types from './types';

const initialState = {
  searchHistory: [],
  itemsSearchHistory: [],
  wishesSearchHistory: [],
};

const feedSearchesReducer = handleActions(
  {
    [types.SET_ITEMS_SEARCH_HISTORY]: mergeDeep(({ payload }) => ({
      itemsSearchHistory: payload,
    })),
    [types.SET_WISHES_SEARCH_HISTORY]: mergeDeep(({ payload }) => ({
      wishesSearchHistory: payload,
    })),
    [types.REMOVE_ITEM_SEARCH_TERM]: (state, { payload: searchTermId }) => ({
      itemsSearchHistory: state.itemsSearchHistory.filter(({ id }) => id !== searchTermId),
    }),
    [types.REMOVE_WISH_SEARCH_TERM]: (state, { payload: searchTermId }) => ({
      wishesSearchHistory: state.wishesSearchHistory.filter(({ id }) => id !== searchTermId),
    }),
  },
  initialState,
);

export default feedSearchesReducer;
