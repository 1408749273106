import { StyleSheet } from 'react-native';
import { colors, dimensions, rs } from '../../../../styles';
import { isIos, isIphoneX } from '../../../../utils/detectDevice';

const s = StyleSheet.create({
  sectionHeaderContainer: {
    paddingBottom: 10,
    paddingTop: 20,
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.grayLighter,
  },
  keyboardAvoidingView: {
    flex: 1,
    marginBottom: isIos ? dimensions.doubleMedium : 0,
  },
  buttonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.halfMedium,
    marginBottom: isIphoneX ? dimensions.doubleMedium : 0,
  },
  amountInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
  },
  amountInput: {
    fontSize: 24,
    fontFamily: 'Rubik-Medium',
    paddingVertical: dimensions.medium,
  },
  tokenIcon: {
    height: 20,
    width: 20,
    marginRight: dimensions.halfMedium,
  },

  noteContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    paddingHorizontal: dimensions.medium,
    justifyContent: 'space-between',
    width: '100%',
  },
  notesInput: {
    width: '80%',
    height: 100,
    paddingTop: 20,
    textAlignVertical: 'top',
  },
  charactersCountText: {
    paddingTop: 20,
  },
  walletsContainer: {
    paddingVertical: dimensions.doubleMedium,
    backgroundColor: colors.white,
    flexDirection: 'row',
    paddingHorizontal: dimensions.halfMedium,
  },
  walletBackgroundContainer: {
    alignItems: 'center',
    flex: 1,
    marginHorizontal: dimensions.halfMedium,
    borderRadius: 5,
    ...rs.shadow,
  },
  walletImage: {
    borderRadius: 5,
  },
  balanceBackground: {
    height: 64,
    width: '100%',
    justifyContent: 'center',
  },
  balanceContainer: {
    paddingHorizontal: dimensions.medium,
  },
  balanceText: {
    lineHeight: isIos ? 26 : undefined,
  },
});

export default s;
