import { createSelector } from 'reselect';
import * as R from 'ramda';
import appLanguages from '../../constants/appLanguages';

export const getAppLanguage = (state) => {
  return R.pathOr('he', ['app', 'language'], state);
};

export const getContactsSectionList = createSelector(
  [R.pathOr([], ['app', 'contacts']), (_, searchTerm) => searchTerm ?? ''],
  (contacts, searchTerm) => {
    if (!contacts.length) {
      return [];
    }

    const sections = {};

    contacts.forEach((contact) => {
      const { firstName, lastName, phoneNumbers } = contact;

      if (
        !firstName.startsWith(searchTerm) &&
        !lastName.startsWith(searchTerm) &&
        phoneNumbers[0].number.indexOf(searchTerm) === -1
      ) {
        return;
      }

      const englishCharsRegExp = new RegExp(/[A-Z]/g);
      const firstLetter = lastName ? lastName[0] : firstName[0];
      const sectionTitle = englishCharsRegExp.test(firstLetter) ? firstLetter : '#';

      sections[sectionTitle] = R.isNil(sections[sectionTitle])
        ? [contact]
        : [...sections[sectionTitle], contact];
    });

    const list = [];

    R.forEachObjIndexed((val, key) => {
      list.push({ title: key, data: val });
    }, sections);

    return list;
  },
);

export const getAppLocaleCode = createSelector([getAppLanguage], (language) => {
  switch (language) {
    case appLanguages.BR:
      return 'pt_BR';

    case appLanguages.HE:
      return 'iw_IL';

    case appLanguages.DE:
      return 'de_DE';

    default:
      return 'en_GB';
  }
});

export default {
  getAppLanguage,
  getContactsSectionList,
  getAppLocaleCode,
};
