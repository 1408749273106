import React from 'react';
import Animated, { EasingNode } from 'react-native-reanimated';

const { Clock, Value, set, cond, startClock, clockRunning, timing, stopClock, block } = Animated;

export function runTiming(
  clock,
  value,
  dest,
  duration = 250,
  easing = EasingNode.out(EasingNode.ease),
) {
  const state = {
    finished: new Value(0),
    position: value,
    time: new Value(0),
    frameTime: new Value(0),
  };

  const config = {
    duration,
    toValue: dest,
    easing,
  };

  return block([
    cond(clockRunning(clock), 0, [
      // If the clock isn't running we reset all the animation params and start the clock
      set(state.finished, 0),
      set(state.time, 0),
      set(state.position, value),
      set(state.frameTime, 0),
      set(config.toValue, dest),
      startClock(clock),
    ]),
    // we run the step here that is going to update position
    timing(clock, state, config),
    // if the animation is over we stop the clock
    cond(state.finished, stopClock(clock)),
    // return the updated position
    state.position,
  ]);
}

export function useCollapsible(config) {
  const [height, setHeight] = React.useState(0);
  const [state, setState] = React.useState('collapsed');

  const { current: clock } = React.useRef(new Clock());
  const { current: progress } = React.useRef(new Value(0));
  const { current: animation } = React.useRef(new Value(0));
  const { current: animatedHeight } = React.useRef(
    runTiming(clock, progress, animation, config?.duration, config?.easing),
  );

  React.useEffect(() => {
    if (state === 'collapsed') {
      animation.setValue(0);
    } else {
      animation.setValue(height);
    }
  }, [state, height, animation]);

  const onPress = React.useCallback(() => {
    setState((prev) => (prev === 'collapsed' ? 'expanded' : 'collapsed'));
  }, []);

  const onLayout = React.useCallback(
    (event) => {
      const measuredHeight = event.nativeEvent.layout.height;

      if (height !== measuredHeight) {
        setHeight(measuredHeight);
      }
    },
    [height],
  );

  return {
    onLayout,
    onPress,
    animatedHeight,
    height,
    state,
  };
}
