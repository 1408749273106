import React, { useCallback, useMemo, useState } from 'react';
import T from 'prop-types';
import { StyleSheet, View, I18nManager } from 'react-native';
import { Portal } from '@gorhom/portal';
import { colors, dimensions } from '../../../styles';
import TouchableItem from '../TouchableItem';

const SPACE = 10;

export const PopoverPosition = {
  top: 'top',
  bottom: 'bottom',
};

const Popover = ({ children, position, targetLayout, onPress }) => {
  const [layout, setLayout] = useState({
    width: 0,
    height: 0,
  });

  const popoverPosition = useMemo(() => {
    const left = targetLayout.x + targetLayout.width / 2 - layout.width / 2;

    return {
      opacity: layout.height === 0 || layout.width === 0 ? 0 : 1,
      top:
        position === PopoverPosition.bottom
          ? targetLayout.y + targetLayout.height + SPACE
          : targetLayout.y - layout.height - SPACE,
      // https://github.com/mxck/react-native-material-menu/commit/35ad4c034ea39ee06908e46b15cc55fcbb440f51#diff-809c40f33bfd9b29d0acb9ce89b12d0e14c7d1aec524c5317a170933d3bc5884R187
      ...(I18nManager.getConstants().isRTL ? { right: left } : { left }),
    };
  }, [position, layout, targetLayout]);

  const popoverContainerStyle = useMemo(() => [styles.popoverContainer, popoverPosition], [
    popoverPosition,
  ]);

  const handlePopoverLayout = useCallback(({ nativeEvent: { layout: { height, width } } }) => {
    setLayout((state) => {
      if (state.height === height && state.width === width) {
        return state;
      }

      return {
        height,
        width,
      };
    });
  }, []);

  return (
    <Portal name="modal">
      <TouchableItem onPress={onPress} style={styles.buttonContainer}>
        <View style={styles.backdropContainer}>
          <View onLayout={handlePopoverLayout} style={popoverContainerStyle}>
            {children}
          </View>
        </View>
      </TouchableItem>
    </Portal>
  );
};

const styles = StyleSheet.create({
  backdropContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  buttonContainer: {
    ...StyleSheet.absoluteFillObject,
  },
  popoverContainer: {
    position: 'absolute',
    alignSelf: 'center',
    backgroundColor: colors.white,
    borderRadius: dimensions.borderRadius,
  },
});

Popover.propTypes = {
  children: T.node.isRequired,
  position: T.oneOf(Object.values(PopoverPosition)),
  targetLayout: T.shape({
    x: T.number,
    y: T.number,
    width: T.number.isRequired,
    height: T.number.isRequired,
  }),
  onPress: T.func,
};

Popover.defaultProps = {
  position: PopoverPosition.bottom,
  targetLayout: { width: 0, height: 0 },
  onPress: () => {},
};

export default Popover;
