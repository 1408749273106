export const REWARDS_START = 'rewards/REWARDS_START';
export const REWARDS_SUCCESS = 'rewards/REWARDS_SUCCESS';
export const REWARDS_ERROR = 'rewards/REWARDS_ERROR';

export const UPDATE_REWARD = 'rewards/UPDATE_REWARD';


export const REWARDS_PROGRESS_START = 'rewards/REWARDS_PROGRESS_START';
export const REWARDS_PROGRESS_SUCCESS = 'rewards/REWARDS_PROGRESS_SUCCESS';
export const REWARDS_PROGRESS_ERROR = 'rewards/REWARDS_PROGRESS_ERROR';

export const USER_INFO_REWARDS_START = 'rewards/USER_INFO_REWARDS_START';
export const USER_INFO_REWARDS_SUCCESS = 'rewards/USER_INFO_REWARDS_SUCCESS';
export const USER_INFO_REWARDS_ERROR = 'rewards/USER_INFO_REWARDS_ERROR';
