import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';
import * as types from './types';

const initialState = {
  isLoading: false,
  isLoadMore: false,
  isExistMore: true,
  ids: [],
  entities: {},
};

const reducer = handleActions(
  {
    [types.CAMPUSES_START]: mergeDeep(({ payload }) => ({
      [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: true,
    })),

    [types.CAMPUSES_SUCCESS]: mergeDeep(({ payload }, state) => ({
      entities: payload.entities,
      ids: payload.isLoadMore ? [...state.ids, ...payload.ids] : payload.ids,
      isExistMore: payload.isExistMore,
      [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
    })),

    [types.CAMPUSES_ERROR]: mergeDeep(({ payload }) => ({
      [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
    })),
  },
  initialState,
);

export default reducer;
