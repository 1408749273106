import React, { Component, Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Error,
  RefundMissingFunds,
  RemoveItemError,
  EditItemError,
  SomethingWentWrong,
  UnblockUser,
  Report,
  Refund,
  Repost,
  Reward,
  Welcome,
  Rules,
  WebView,
  Discount,
  JoinCommunity,
  TokensExchangePrompt,
  NoInternetConnection,
  LeaveCommunity,
  UserLeftCommunity,
  Info,
  NoSharedCommunitiesWithUser,
  UserIsNotMember,
  JoinAnyCommunity,
  CreateLot,
  PromoCodeActivated,
  MissingTokensForGuest,
  ItemReview,
  AddLocation,
  AchievedPersonalRewardDetails,
  LegalInfo,
  InAppSharing,
  PaymentPlanComingSoon,
  DonationSuccess,
  ContactList,
  ContactDetails,
  EndorseUser,
  Promotion,
} from './modals';
import modalTypes from '../../../constants/modalTypes';
import {
  StyledModal,
  RootContainer,
  SwipeableModal,
  SwipeableModalContainer,
  FullScreenModal,
  FullScreenContainer,
} from './styles';
import { Portal } from '@gorhom/portal';

class RootModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleSimpleModal: false,
      isVisibleTransparentModal: false,
      isVisibleSwipeableModal: false,
      isVisibleMandatoryResponseModal: false,
      isVisibleFullScreenModal: false,
      modalName: '',
      modalParams: null,
    };
  }

  showSimpleModal = (modalName, params) => {
    this.setState({
      isVisibleSimpleModal: true,
      modalName,
      modalParams: params,
    });
  };

  showTransparentModal = (modalName, params) => {
    this.setState({
      isVisibleTransparentModal: true,
      modalName,
      modalParams: params,
    });
  };

  showFullScreenModal = (modalName, params) => {
    this.setState({
      isVisibleFullScreenModal: true,
      modalName,
      modalParams: params,
    });
  };

  showSwipeableModal = (modalName, params) => {
    this.setState({
      isVisibleSwipeableModal: true,
      modalName,
      modalParams: params,
    });
  };
  showMandatoryResponseModal = (modalName, params) => {
    this.setState({
      isVisibleMandatoryResponseModal: true,
      modalName,
      modalParams: params,
    });
  };

  onClose = () => {
    this.setState({
      isVisibleSimpleModal: false,
      isVisibleSwipeableModal: false,
      isVisibleMandatoryResponseModal: false,
      isVisibleFullScreenModal: false,
      isVisibleTransparentModal: false,
      modalName: '',
      modalParams: null,
    });
  };

  onBackdropPress = () => {
    this.onClose();
  };

  render() {
    const {
      modalName,
      modalParams,
      isVisibleSimpleModal,
      isVisibleSwipeableModal,
      isVisibleMandatoryResponseModal,
      isVisibleFullScreenModal,
      isVisibleTransparentModal,
    } = this.state;

    return (
      <>
        {isVisibleSimpleModal && (
          <Portal name="modal">
            <View style={{ ...StyleSheet.absoluteFillObject }}>
              <StyledModal>
                <RootContainer>
                  {
                    {
                      [modalTypes.ERROR]: <Error onPress={this.onClose} params={modalParams} />,
                      [modalTypes.TOKENS_EXCHANGE_PROMPT]: (
                        <TokensExchangePrompt onClose={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.REFUND]: <RefundMissingFunds onPress={this.onClose} />,

                      [modalTypes.REPOST]: <Repost onPress={this.onClose} params={modalParams} />,
                      [modalTypes.REMOVE_ITEM_ERROR]: <RemoveItemError onPress={this.onClose} />,
                      [modalTypes.EDIT_ITEM_ERROR]: <EditItemError onPress={this.onClose} />,
                      [modalTypes.SOMETHING_WENT_WRONG]: (
                        <SomethingWentWrong onPress={this.onClose} />
                      ),
                      [modalTypes.UNBLOCK_USER]: (
                        <UnblockUser onPress={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.REWARD]: <Reward onPress={this.onClose} params={modalParams} />,
                      [modalTypes.WELCOME]: <Welcome onPress={this.onClose} params={modalParams} />,
                      [modalTypes.DISCOUNT]: (
                        <Discount onPress={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.JOIN_COMMUNITY]: (
                        <JoinCommunity onPress={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.USER_IS_NOT_MEMBER]: (
                        <UserIsNotMember onPress={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.JOIN_ANY_COMMUNITY]: (
                        <JoinAnyCommunity onPress={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.LEAVE_COMMUNITY]: (
                        <LeaveCommunity onPress={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.USER_LEFT_COMMUNITY]: (
                        <UserLeftCommunity onPress={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.INFO]: <Info onPress={this.onClose} params={modalParams} />,
                      [modalTypes.PROMO_CODE_ACTIVATED]: (
                        <PromoCodeActivated onPress={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.MISSING_TOKENS]: (
                        <MissingTokensForGuest onPress={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.ITEM_REVIEW]: (
                        <ItemReview onPress={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.ADD_LOCATION]: (
                        <AddLocation onPress={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.ACHIEVED_PERSONAL_REWARD]: (
                        <AchievedPersonalRewardDetails
                          params={modalParams}
                          onPress={this.onClose}
                        />
                      ),
                      [modalTypes.DONATION_SUCCESS]: <DonationSuccess onPress={this.onClose} />,
                      [modalTypes.PAYMENT_PLAN_COMING_SOON]: (
                        <PaymentPlanComingSoon onPress={this.onClose} />
                      ),
                    }[modalName]
                  }
                </RootContainer>
              </StyledModal>
            </View>
          </Portal>
        )}

        {isVisibleTransparentModal && (
          <Portal name="modal">
            <View style={{ ...StyleSheet.absoluteFillObject }}>
              <StyledModal>
                {
                  {
                    [modalTypes.CREATE_LOT]: (
                      <CreateLot onPress={this.onClose} params={modalParams} />
                    ),
                  }[modalName]
                }
              </StyledModal>
            </View>
          </Portal>
        )}

        {isVisibleFullScreenModal && (
          <Portal name="modal">
            <View style={{ ...StyleSheet.absoluteFillObject }}>
              <FullScreenModal>
                <FullScreenContainer>
                  {
                    {
                      [modalTypes.RULES]: <Rules onClose={this.onClose} params={modalParams} />,
                      [modalTypes.WEB_VIEW]: (
                        <WebView onClose={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.NO_INTERNET_CONNECTION]: (
                        <NoInternetConnection onClose={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.NO_SHARED_COMMUNITIES_WITH_USER]: (
                        <NoSharedCommunitiesWithUser onPress={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.ENDORSE_USER]: (
                        <EndorseUser onClose={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.PROMOTION]: (
                        <Promotion params={modalParams} onClose={this.onClose} />
                      ),
                    }[modalName]
                  }
                </FullScreenContainer>
              </FullScreenModal>
            </View>
          </Portal>
        )}

        {isVisibleSwipeableModal && (
          <Portal name="modal">
            <View style={{ ...StyleSheet.absoluteFillObject }}>
              <SwipeableModal>
                <SwipeableModalContainer>
                  {
                    {
                      [modalTypes.REPORT]: <Report onClose={this.onClose} params={modalParams} />,
                      [modalTypes.REFUND]: <Refund onClose={this.onClose} params={modalParams} />,
                      [modalTypes.IN_APP_SHARING]: (
                        <InAppSharing onClose={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.CONTACT_LIST]: (
                        <ContactList onClose={this.onClose} params={modalParams} />
                      ),
                      [modalTypes.CONTACT_DETAILS]: (
                        <ContactDetails onClose={this.onClose} params={modalParams} />
                      ),
                    }[modalName]
                  }
                </SwipeableModalContainer>
              </SwipeableModal>
            </View>
          </Portal>
        )}

        {isVisibleMandatoryResponseModal && (
          <Portal name="modal">
            <View style={{ ...StyleSheet.absoluteFillObject }}>
              <SwipeableModal>
                <SwipeableModalContainer>
                  {
                    {
                      [modalTypes.LEGAL_INFO]: (
                        <LegalInfo onClose={this.onClose} params={modalParams} />
                      ),
                    }[modalName]
                  }
                </SwipeableModalContainer>
              </SwipeableModal>
            </View>
          </Portal>
        )}
      </>
    );
  }
}

export default RootModal;
