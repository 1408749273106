import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import {
  View,
  Rules,
  AnimatedOpacityHeader,
  Separator,
  Container,
  Text,
} from '../../../ReusableComponents';
import strings from '../../../../localization';
import { rs } from '../../../../styles';
import { Animated, Image } from 'react-native';
import s from './styles';

const CommunityRules = ({ communityInfo }) => {
  const scrollY = new Animated.Value(0);
  const { community_rules } = communityInfo;

  return (
    <Container>
      <AnimatedOpacityHeader scrollY={scrollY} title={strings.rules.conduct_rules} />

      <Animated.ScrollView
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
          useNativeDriver: true,
        })}
        scrollEventThrottle={8}
      >
        <View style={s.headerImageContainer}>
          <Image source={{ uri: 'community_rules' }} style={s.headerImage} />
        </View>

        <View style={s.sectionHeaderContainer}>
          <Text semiBold medium>
            {strings.rules.conduct_rules}
          </Text>
        </View>

        {!R.isEmpty(community_rules) && (
          <Fragment>
            <View style={[rs.paddingHorizontal, rs.paddingTop]}>
              <Rules rules={community_rules} />
            </View>
          </Fragment>
        )}

        <View style={rs.paddingHorizontal}>
          <Separator />
        </View>
      </Animated.ScrollView>
    </Container>
  );
};

export default connect((state) => ({
  communityInfo: state.communityInfo,
}))(CommunityRules);
