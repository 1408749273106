import { StyleSheet } from 'react-native';
import { dimensions, rs, colors } from '../../../../../../styles';

export default StyleSheet.create({
  contentContainer: {
    borderRadius: 4,
    backgroundColor: colors.white,
    ...rs.shadow,
    marginHorizontal: dimensions.medium,
    marginTop: 2, // to make shadow visible
    marginBottom: 2,
    paddingVertical: dimensions.halfMedium,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: dimensions.halfMedium,
  },
  iconContainer: {
    backgroundColor: colors.grayLighter,
    height: 34,
    width: 34,
    borderRadius: 17,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: dimensions.medium,
  },
});
