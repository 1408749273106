import React from 'react';
import { getTestimonialDate } from '../../../utils/dateHelper';
import { Container, Text, StarsRating, Separator, FontIcon } from '../../ReusableComponents';
import { colors, dimensions, rs } from '../../../styles';
import s from './styles';

const UserTestimonialScreen = ({ route }) => {
  const item = route.params.item;
  const date = getTestimonialDate(item.created_at);

  return (
    <Container style={s.container}>
      {/*<Image style={s.userImage} source={{ uri: item.profile_image }} />*/}
      <FontIcon name={'Profile-icon'} size={80} style={rs.marginBottom} color={colors.gray} />

      <Text style={rs.marginBottom} color={colors.gray} large semiBold>
        {item.user_name}
      </Text>

      <StarsRating rating={item.rating} size={dimensions.xLarge} style={rs.bigMarginBottom} />

      <Separator style={rs.bigMarginBottom} small />

      <Text style={s.description} color={colors.gray} medium alignCenter>
        {item.description}
      </Text>

      <Text color={colors.grayBorder} xsmall alignCenter>
        {date}
      </Text>
    </Container>
  );
};

export default UserTestimonialScreen;
