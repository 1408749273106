import * as R from 'ramda';
import transactionAPI from '../../api/transaction';
import { ModalsService, ToastsService } from '../../services';
import strings from '../../localization';
import NavigationService from '../../services/NavigationService';
import LoadingService from '../../services/LoadingService';
import modalTypes from '../../constants/modalTypes';
import * as actions from './actions';

import { normalize } from '../../utils/stateHelper';
import { walletsOperations } from '../wallets';
import { updateWalletBalance } from '../wallets/actions';
import { COMMON_LIMIT } from '../../constants/listLimits';

const getTransactions = (isRefresh) => async (dispatch) => {
  if (isRefresh) {
    dispatch(actions.transactionsRefreshStart());
  } else {
    dispatch(actions.transactionsStart());
  }

  try {
    const data = await transactionAPI.getTransferTransactions();

    const normalizedTransactions = normalize(data, 'transactionIds', 'transactionEntities');

    dispatch(
      actions.transactionsSuccess({
        normalizedTransactions,
        isExistMore: data.length === COMMON_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(actions.transactionsError(e));
  }
};

const getMoreTransactions = () => async (dispatch, getState) => {
  const { transactions } = getState();
  const { isLoading, isLoadingMore, isExistMore } = transactions;
  const skip = transactions.transactionIds.length;

  if (isLoadingMore || isLoading || !isExistMore) {
    return;
  }

  dispatch(actions.transactionsMoreStart());

  try {
    const data = await transactionAPI.getTransferTransactions(skip);

    const normalizedTransactions = normalize(data, 'transactionIds', 'transactionEntities');

    dispatch(
      actions.transactionsMoreSuccess({
        normalizedTransactions,
        isExistMore: data.length === COMMON_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(actions.transactionsError(e));
  }
};

const createIssueTransaction = ({ note, amount, receiverIds, walletType }) => async (
  dispatch,
  getState,
) => {
  const { communityInfo } = getState();
  try {
    await transactionAPI.createIssueTransaction({
      note,
      amount,
      receiverIds,
      communityId: communityInfo.id,
      walletType,
    });

    ToastsService.showSuccess(strings.success_messages.issue_token);
  } catch (e) {}
};

const sendTokensToBank = ({ walletType, amount, note }) => async (dispatch, getState) => {
  const { communityInfo } = getState();

  try {
    await transactionAPI.sendTokensToCommunityBank({
      communityId: communityInfo.id,
      walletType,
      note,
      amount,
    });
    ToastsService.showSuccess(strings.success_messages.transfer_tokens);
    NavigationService.goBack();
    dispatch(walletsOperations.getWallets());
  } catch (e) {
    if (R.path(['response', 'status'], e) === 403) {
      ModalsService.showModal(modalTypes.MISSING_FOUNDS);
    }
  }
};

const createDonationTransaction = ({ receiverId, amount, note }) => async (dispatch, getState) => {
  const { communityInfo, wallets } = getState();

  try {
    LoadingService.showLoader();

    await transactionAPI.createDonationTransaction({
      note,
      amount,
      receiverId,
      communityId: communityInfo.id,
    });

    const updatedBalanceAmount = wallets.wallet.balance_amount - amount;
    dispatch(updateWalletBalance({ balanceAmount: updatedBalanceAmount }));

    NavigationService.goBack();
    ModalsService.showModal(modalTypes.DONATION_SUCCESS);
  } catch (e) {
    ModalsService.showModal(modalTypes.MISSING_TOKENS);
  } finally {
    LoadingService.hideLoader();
  }
};

export default {
  getTransactions,
  getMoreTransactions,
  createIssueTransaction,
  sendTokensToBank,
  createDonationTransaction,
};
