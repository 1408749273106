import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../styles';

export default StyleSheet.create({
  itemContainer: {
    paddingTop: dimensions.medium,
    paddingBottom: dimensions.doubleMedium,
    paddingHorizontal: dimensions.doubleMedium,
    backgroundColor: colors.white,
  },
  activeItemContainer: {
    backgroundColor: colors.blueLightest,
  },
  descriptionText: {
    lineHeight: 20,
    flex: 1,
    flexWrap: 'wrap',
  },
  separator: {
    height: 1,
    marginHorizontal: dimensions.medium,
    alignSelf: 'stretch',
    backgroundColor: colors.grayLight,
  },
});
