import * as R from 'ramda';
import { createSelector } from 'reselect';

export const getCommunityCampuses = createSelector(
  [R.pathOr([], ['campuses', 'ids']), R.pathOr({}, ['campuses', 'entities'])],
  (ids, entities) => {
    return ids.map((id) => entities[id]);
  },
);

export default {
  getCommunityCampuses,
};
