import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';
import * as types from './types';
import { RESET_GROUP_STATE } from '../constants';

const initialState = {
  isLoading: false,
  isLoadingMore: false,
  isRefreshing: false,
  isExistMore: true,

  transactionIds: [],
  transactionEntities: {},
};

const shippingReducer = handleActions(
  {
    [types.TRANSACTIONS_START]: mergeDeep(() => ({
      isLoading: true,
    })),

    [types.TRANSACTIONS_REFRESH_START]: mergeDeep(() => ({
      isRefreshing: true,
    })),

    [types.TRANSACTIONS_ERROR]: mergeDeep(() => ({
      isLoading: false,
      isLoadingMore: false,
      isRefreshing: false,
    })),

    [types.TRANSACTIONS_SUCCESS]: mergeDeep(({ payload }) => ({
      transactionEntities: payload.normalizedTransactions.transactionEntities,
      transactionIds: payload.normalizedTransactions.transactionIds,
      isLoading: false,
      isRefreshing: false,
      isExistMore: payload.isExistMore,
    })),

    [types.TRANSACTIONS_MORE_START]: mergeDeep(() => ({
      isLoadingMore: true,
    })),

    [types.TRANSACTIONS_MORE_SUCCESS]: mergeDeep(({ payload }, state) => ({
      transactionEntities: payload.normalizedTransactions.transactionEntities,
      transactionIds: [...state.transactionIds, ...payload.normalizedTransactions.transactionIds],
      isLoadingMore: false,
      isExistMore: payload.isExistMore,
    })),

    [RESET_GROUP_STATE]: () => ({
      ...initialState,
    }),
  },
  initialState,
);

export default shippingReducer;
