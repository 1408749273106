import React, { Fragment } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { getGroupNameById } from '../../../../../store/groups/selectors';
import strings from '../../../../../localization';
import { numberWithCommas } from '../../../../../utils/stringHelper';
import { TouchableItem, Text, StaticHeightButton, FastImage } from '../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../styles';

const s = StyleSheet.create({
  contentContainer: {
    borderColor: colors.grayLight,
    borderWidth: dimensions.hairline,
    marginTop: dimensions.medium,
    marginHorizontal: dimensions.medium,
    borderRadius: 5,
    backgroundColor: colors.white,
    padding: dimensions.medium,
  },
  image: {
    width: 74,
    height: 74,
    borderRadius: 5,
  },
  infoContainer: {
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.halfMedium,
  },
  sizeContainer: {
    paddingHorizontal: 6,
    borderWidth: dimensions.hairline,
    borderColor: colors.gray,
    borderRadius: 5,
    marginHorizontal: dimensions.medium,
  },
  oldPriceText: {
    alignSelf: 'flex-end',
    paddingHorizontal: 10,
    textDecorationLine: 'line-through',
  },
  priceImage: {
    height: 12,
    width: 12,
    marginRight: 4,
  },
  userAvatar: {
    height: 22,
    width: 22,
    borderRadius: 11,
    marginRight: dimensions.halfMedium,
  },
  titleContainer: {
    maxWidth: '90%',
  },
});

const PendingLotItem = ({ lot, onPress, onApprovePendingItem, onDeclinePendingItem }) => {
  const groupName = useSelector((state) => getGroupNameById(state, lot.group_id));

  return (
    <TouchableItem style={s.contentContainer} withoutOpacity onPress={() => onPress(lot)}>
      <View style={rs.row}>
        <FastImage style={s.image} source={{ uri: lot.item_image }} resizeMode="cover" />

        <View style={rs.paddingHorizontal}>
          <View style={[rs.row, rs.alignCenter]}>
            <Image style={s.priceImage} source={{ uri: 'price' }} resizeMode="contain" />
            <Text color={colors.secondary} semiBold>
              {numberWithCommas(lot.discounted_price || lot.price)}
            </Text>
            {lot.discounted_price && (
              <Text color={colors.gray} style={s.oldPriceText}>
                {numberWithCommas(lot.price)}
              </Text>
            )}
            <Text>{lot.original_price}</Text>
          </View>

          <View style={s.titleContainer}>
            <Text semiBold numberOfLines={1} style={rs.verySmallPaddingTop}>
              {lot.title}
            </Text>
            <Text color={colors.gray} numberOfLines={2} style={rs.verySmallPaddingTop}>
              {lot.description}
            </Text>
          </View>
        </View>
      </View>

      <View style={rs.paddingTop}>
        <View style={[rs.row, rs.alignCenter, rs.smallPaddingBottom]}>
          <FastImage style={s.userAvatar} source={{ uri: lot.seller_image }} resizeMode="cover" />

          <Text semiBold color={colors.text} numberOfLines={1}>
            {lot.seller_name}
          </Text>
        </View>
        <Text style={rs.smallPaddingBottom}>{strings.lots.group}: {groupName}</Text>
        {!!lot.parent_category_name && (
          <Text style={rs.smallPaddingBottom}>
            {strings.lots.category}: {lot.parent_category_name} : {lot.category_name}
          </Text>
        )}
        {!!lot.size_title && <Text style={rs.smallPaddingBottom}>Size: {lot.size_title}</Text>}
        {!!lot.condition_title && (
          <Text style={rs.smallPaddingBottom}>Condition: {lot.condition_title}</Text>
        )}
      </View>

      <View style={[rs.flex, rs.row, rs.paddingTop]}>
        <Fragment>
          <StaticHeightButton
            small
            withMarginRight
            secondaryType
            title={'Decline Item'}
            onPress={() => onDeclinePendingItem(lot.id)}
          />
          <StaticHeightButton
            small
            title={'Approve Item'}
            onPress={() => onApprovePendingItem(lot.id)}
          />
        </Fragment>
      </View>
    </TouchableItem>
  );
};

export default PendingLotItem;
