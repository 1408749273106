import { StyleSheet } from 'react-native';
import { colors, dimensions, rs } from '../../../../styles';

export default StyleSheet.create({
  contentContainerStyle: {
    backgroundColor: colors.white,
    paddingVertical: dimensions.medium,
    ...rs.tabBarPadding,
  },
  grayBackground: {
    backgroundColor: '#f5f5f5',
  },
  columnWrapperStyle: {
    justifyContent: 'center',
  },
  emptyListStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  jcCenter: {
    justifyContent: 'center',
  },
  jcSpaceAround: {
    justifyContent: 'space-around',
  },
});
