import React, { useState, Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import strings from '../../../../localization';
import { TouchableItem, Text, CheckBox } from '../../';
import { colors, dimensions, rs } from '../../../../styles';

const s = StyleSheet.create({
  rootContainer: {
    flex: 1,
    paddingTop: 50,
  },
  swipeView: {
    width: 60,
    height: 8,
    alignSelf: 'center',
    marginBottom: dimensions.medium,
    borderRadius: 4,
    backgroundColor: colors.grayLightest,
    opacity: 0.8,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  headerContainer: {
    width: '100%',
    paddingTop: 16,
    paddingBottom: 20,
    paddingHorizontal: dimensions.medium,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayLight,
  },
  contactName: {
    paddingTop: dimensions.large,
    paddingHorizontal: dimensions.large,
  },
  optionsContainer: {
    marginTop: 20,
    paddingHorizontal: dimensions.medium,
    flexGrow: 1,
  },
  option: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: dimensions.halfMedium,
  },
  labelContainer: {
    flexGrow: 1,
    marginLeft: 15,
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayLight,
    paddingBottom: dimensions.halfMedium,
  },
});

const ContactDetails = ({ params, onClose }) => {
  const { onSubmit, contact } = params;

  const selectedNumbersInitialState = {};
  contact.phoneNumbers.forEach(({ id }) => {
    selectedNumbersInitialState[id] = true;
  });

  const [selectedNumbers, setSelectedNumbers] = useState(selectedNumbersInitialState);

  const isSendDisabled = Object.keys(selectedNumbers).every((id) => !selectedNumbers[id]);

  const onSubmitPress = () => {
    const contactToSend = {
      ...contact,
      phoneNumbers: contact.phoneNumbers.filter(({ id }) => !!selectedNumbers[id]),
    };

    onSubmit(contactToSend);
    onClose();
  };

  return (
    <View style={s.rootContainer}>
      <View style={s.swipeView} />

      <View style={s.contentContainer}>
        <View style={s.headerContainer}>
          <TouchableItem onPress={onClose}>
            <Text color={colors.blueLight}>{strings.common.cancel}</Text>
          </TouchableItem>

          <Text style={rs.alignCenter} color={colors.secondary} medium bold>
            {strings.modals.share_contact.share_contact}
          </Text>

          <TouchableItem onPress={onSubmitPress} disabled={isSendDisabled}>
            <Text color={isSendDisabled ? colors.grayLight : colors.blueLight} semiBold>
              {strings.common.send}
            </Text>
          </TouchableItem>
        </View>

        <View style={s.contactName}>
          <Text large semiBold>
            {`${contact.firstName} ${contact.lastName}`.trim()}
          </Text>
        </View>

        <View style={s.optionsContainer}>
          {contact.phoneNumbers.map(({ id, label, number }) => {
            const isSelected = selectedNumbers[id];
            const onPress = () => {
              setSelectedNumbers({ ...selectedNumbers, [id]: !isSelected });
            };

            return (
              <TouchableItem style={s.option} onPress={onPress} key={id}>
                <Fragment>
                  <CheckBox checked={isSelected} onToggle={onPress} />

                  <View style={s.labelContainer}>
                    <Text color={colors.blueLight} small>
                      {label}
                    </Text>
                    <Text style={rs.smallMarginTop} color={colors.text} medium semiBold>
                      {number}
                    </Text>
                  </View>
                </Fragment>
              </TouchableItem>
            );
          })}
        </View>
      </View>
    </View>
  );
};

export default ContactDetails;
