import React, { useState } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import strings from '../../../../../localization';
import {
  ASK_TO_BUY,
  CONFIRM_CANCEL_PURCHASE,
  ITEM_DELIVERED,
  OFFER_CONFIRMED,
} from '../../../../../store/offers/offerStatuses';
import * as offerActions from '../../../../../store/offers/offerActions';
import { offersOperations } from '../../../../../store/offers';
import { FormButtonText, RoundButton } from '../../../../ReusableComponents';
import { ModalsService, NavigationService } from '../../../../../services';
import modalTypes from '../../../../../constants/modalTypes';
import { lotsOperations } from '../../../../../store/lots';
import screens from '../../../../../navigation/screens';

const OfferButtonsContainer = ({
  offer,
  isCurrentUserBuyer,
  isItemActive,
  itemId,
  createOffer,
  performAction,
  getItemSnapshotByOfferId,
  isLoadingOffer,
}) => {
  const [isLoading, setLoading] = useState(false);

  const createOfferAndPerformAskToBuy = async () => {
    if (!isLoading) {
      setLoading(true);
    } else {
      return;
    }

    await createOffer(itemId);

    setLoading(false);
  };

  const onPerformAction = async (offerId, actionType) => {
    if (!isLoading) {
      setLoading(true);
    } else {
      return;
    }

    await performAction(offerId, actionType);

    if (actionType === offerActions.BUYER_CONFIRMED_DELIVERY) {
      const item = await getItemSnapshotByOfferId(offerId);

      NavigationService.replace(screens.SnapshotItemDetailsScreen, { item });
    }

    setLoading(false);
  };

  if (isLoadingOffer) {
    return <RoundButton isLoading withMarginRight />;
  }

  const currentStatusId = offer.status_id;

  if (
    isCurrentUserBuyer &&
    (R.isEmpty(offer) || Number(currentStatusId) === ITEM_DELIVERED) &&
    isItemActive
  ) {
    return (
      <RoundButton
        isLoading={isLoading}
        withMarginRight
        title={strings.buy_sell.ask_to_buy}
        onPress={createOfferAndPerformAskToBuy}
      />
    );
  }

  switch (parseInt(currentStatusId)) {
    case ASK_TO_BUY: {
      return (
        <RoundButton
          isLoading={isLoading}
          withMarginRight
          title={strings.buy_sell.ask_to_buy}
          onPress={() => {
            onPerformAction(offer.id, offerActions.BUYER_ASKED_TO_BUY);
          }}
        />
      );
    }
    case CONFIRM_CANCEL_PURCHASE: {
      return (
        <RoundButton
          isLoading={isLoading}
          withMarginRight
          title={strings.buy_sell.cancel_purchase}
          onPress={() => {
            onPerformAction(offer.id, offerActions.BUYER_CANCELLED_OFFER);
          }}
        />
      );
    }
    case OFFER_CONFIRMED: {
      return (
        <RoundButton
          isLoading={isLoading}
          withMarginRight
          title={strings.buy_sell.confirm_delivery}
          onPress={() => {
            onPerformAction(offer.id, offerActions.BUYER_CONFIRMED_DELIVERY);
          }}
        />
      );
    }
    case ITEM_DELIVERED: {
      return <FormButtonText withMarginRight title={strings.buy_sell.item_delivered} />;
    }
    default: {
      return null;
    }

    // case ITEM_NOT_AVAILABLE: {
    //   return <FormButtonText withMarginRight title={strings.buy_sell.ask_to_buy} />;
    // }
    // case ITEM_SOLD: {
    //   return <FormButtonText withMarginRight title={strings.buy_sell.ask_to_buy} />;
    // }
  }
};
export default connect(
  () => ({}),
  (dispatch) => ({
    createOffer: (itemId) => dispatch(offersOperations.createOffer(itemId)),
    performAction: (offerId, actionId) =>
      dispatch(offersOperations.performAction(offerId, actionId)),
    getItemSnapshotByOfferId: (offerId) =>
      dispatch(lotsOperations.getItemSnapshotByOfferId(offerId)),
  }),
)(OfferButtonsContainer);
