import * as React from 'react';
import { Image as RNImage } from 'react-native';
import T from 'prop-types';
import { isWeb } from '../../../utils/detectDevice';

const imageExtensions = {
  jpg: 'jpg',
  jpeg: 'jpeg',
  png: 'png',
};

const Image = ({ ext, source, ...props }) => {
  const urlRegExp = new RegExp(/https?:\/\/.*$/);
  const isUrl = urlRegExp.test(source.uri);

  return (
    <RNImage
      {...props}
      {...(!!isWeb && !isUrl && !!source?.uri
        ? { source: require(`../../../assets/web/images/${source.uri}.${ext}`) }
        : { source })}
    />
  );
};

Image.propTypes = {
  ext: T.oneOf(Object.values(imageExtensions)),
};

Image.defaultProps = {
  uri: null,
  ext: imageExtensions.png,
};

export default Image;
