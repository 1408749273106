import React from 'react';
import { View } from 'react-native';
import TouchableItem from '../TouchableItem';
import Text from '../Text';
import AngleIcon from '../AngleIcon';
import Image from '../Image';
import { dimensions, colors, rs } from '../../../styles';
import { StyleSheet } from 'react-native';
import strings from '../../../localization';
import { AnalyticsService, NavigationService } from '../../../services';
import screens from '../../../navigation/screens';
import Icon from '../Icon';
import T from 'prop-types';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    minHeight: 84,
    paddingVertical: dimensions.medium,
    paddingRight: dimensions.doubleMedium,
    borderRadius: 4,
    alignItems: 'center',
    marginHorizontal: dimensions.medium,
    marginTop: 30,
    backgroundColor: colors.grayLighter,
  },
  text: {
    lineHeight: 24,
    flex: 1,
    flexWrap: 'wrap',
  },
  imageContainer: {
    width: 116,
    alignItems: 'center',
  },
  image: {
    height: 76,
    width: 76,
  },
  closeIconButton: {
    position: 'absolute',
    top: dimensions.halfMedium,
    right: dimensions.halfMedium,
  },
});

const InviteFriendsBanner = ({ onClose, containerStyle, analyticsEventType }) => {
  const onPress = () => {
    if (analyticsEventType) {
      AnalyticsService.logEvent(analyticsEventType);
    }
    NavigationService.navigate(screens.InviteFriends);
  };

  return (
    <View style={rs.shadow}>
      <TouchableItem onPress={onPress}>
        <View style={[s.contentContainer, containerStyle]}>
          <View style={s.imageContainer}>
            <Image style={s.image} source={{ uri: 'invite_friends_banner' }} resizeMode="contain" />
          </View>
          <Text medium semiBold style={[s.text, rs.paddingHorizontal]}>
            {strings.invite_friends.invite_friends_banner}{' '}
            <Text color={colors.blueLight} medium semiBold style={s.text}>
              {strings.home.token_banner_blue_text}
            </Text>
          </Text>

          {!onClose && <AngleIcon color={colors.gray} size={26} />}
          {!!onClose && (
            <TouchableItem onPress={onClose} style={s.closeIconButton}>
              <Icon name={'x'} size={16} color={colors.grayBorder} />
            </TouchableItem>
          )}
        </View>
      </TouchableItem>
    </View>
  );
};

InviteFriendsBanner.propTypes = {
  onClose: T.func,
  containerStyle: T.any,
};

export default InviteFriendsBanner;
