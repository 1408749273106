/* eslint-disable react-native/no-inline-styles */
import React, { Component, Fragment } from 'react';
import { FlatList, View } from 'react-native';
import T from 'prop-types';
import s, { SizeItemContainer, SizeTitle } from './styles';
import { isRTL } from '../../../utils/rtlHelper';

class Sizes extends Component {
  render() {
    const { sizes, selectedSizes, onSelectSize } = this.props;
    return (
      <View style={s.contentContainer}>
        <FlatList
          horizontal
          inverted
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={s.listContentContainer}
          styles={s.list}
          data={sizes}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => {
            const isActive = selectedSizes.includes(item.id);
            return (
              <SizeItemContainer
                useOpacity={false}
                isActive={isActive}
                onPress={() => onSelectSize(item.id)}
              >
                <Fragment>
                  <SizeTitle isActive={isActive} semiBold>
                    {item.title}
                  </SizeTitle>
                </Fragment>
              </SizeItemContainer>
            );
          }}
        />
      </View>
    );
  }
}

Sizes.propTypes = {
  sizes: T.array,
  selectedSizes: T.array,
  onSelectSize: T.func,
};

export default Sizes;
