import React, { Component } from 'react';
import { connect } from 'react-redux';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { Alert, Animated } from 'react-native';
import * as R from 'ramda';
import { chatsOperations } from '../../../../store/chats';
import NavigationService from '../../../../services/NavigationService';
import { lotsOperations, lotsSelectors } from '../../../../store/lots';
import strings from '../../../../localization';
import ItemDetailsView from './components/ItemDetailsView';
import { shareDynamicLink } from '../../../../utils/shareHelper';
import { RoundButton, Container } from '../../../ReusableComponents';
import { LoadingService, ModalsService } from '../../../../services';
import modalTypes from '../../../../constants/modalTypes';
import { reportTypes } from '../../../../constants/reports';
import { OPEN_WISH_DETAILS } from '../../../../constants/dynamicalLinkActionTypes';
import BottomBar from './components/BottomBar';
import itemStatuses from '../../../../constants/itemStatuses';
import { communitySelectors } from '../../../../store/communityInfo';
import screens from '../../../../navigation/screens';

@connectActionSheet
class WishDetails extends Component {
  state = {
    scrollY: new Animated.Value(0),
    isLoading: true,
  };

  async componentDidMount() {
    const { route, wish, getWishDetails, getWishInfo } = this.props;

    const wishId = route.params.wishId;

    if (R.isEmpty(wish)) {
      LoadingService.showLoader();
      await getWishInfo(wishId);
      LoadingService.hideLoader();
    }

    await getWishDetails(wishId);
    this.setState({ isLoading: false });
  }

  onRemoveWish = () => {
    const { user, wish, removeWish } = this.props;

    Alert.alert(strings.wishes.delete_wish, strings.wishes.sure_delete, [
      {
        text: strings.common.delete,
        onPress: () => {
          removeWish({ wish_id: wish.id, wisher_id: user.id });
          NavigationService.goBack();
        },
      },
      {
        text: strings.common.cancel,
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
    ]);
  };

  onHide = () => {
    const { hideWish, wish } = this.props;

    Alert.alert(strings.items.hide_item, strings.items.sure_hide, [
      {
        text: 'Hide',
        onPress: () => {
          hideWish(wish.id);
          NavigationService.goBack();
        },
      },
      {
        text: strings.common.cancel,
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
    ]);
  };

  onUnhide = () => {
    const { unhideWish, wish } = this.props;

    Alert.alert(strings.items.unhide_item, strings.items.sure_unhide, [
      {
        text: 'Unhide',
        onPress: () => {
          unhideWish(wish.id);
          NavigationService.goBack();
        },
      },
      {
        text: strings.common.cancel,
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
    ]);
  };

  onOpenActionSheet = () => {
    const { wish, showActionSheetWithOptions } = this.props;

    const options = [
      strings.share.share_wish,
      strings.common.delete,
      strings.common.edit,
      strings.common.cancel,
    ];
    const destructiveButtonIndex = 1;
    const cancelButtonIndex = options.length - 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          shareDynamicLink({
            actionType: OPEN_WISH_DETAILS,
            id: wish.id,
            communityId: wish.group_id,
            socialImageUrl: wish.wish_image,
            socialTitle: wish.title,
            socialDescription: wish.description,
            linkDescription: strings.deep_linking.my_wish_link_info,
          });
        }
        if (buttonIndex === 1) {
          setTimeout(() => this.onRemoveWish(), 500);
        }
        if (buttonIndex === 2) {
          NavigationService.navigate(screens.EditWishStack, {
            screen: screens.EditWish,
            params: { wish, images: wish.images, locations: wish.locations },
          });
        }
      },
    );
  };

  openItemReportModal = () => {
    const { user, wish } = this.props;

    ModalsService.showSwipeableModal(modalTypes.REPORT, {
      reportType: reportTypes.item,
      reporterUserId: user.id,
      reportedUserId: wish.wisher_id,
      itemId: wish.id,
    });
  };

  openUserReportModal = () => {
    const { user, wish } = this.props;

    ModalsService.showSwipeableModal(modalTypes.REPORT, {
      reportType: reportTypes.profile,
      reporterUserId: user.id,
      reportedUserId: wish.wisher_id,
    });
  };

  onOpenBuyerActionSheet = () => {
    const { wish, isUserAdmin, showActionSheetWithOptions } = this.props;

    const isActive = wish.status === itemStatuses.ACTIVE;
    const isHidden = wish.status === itemStatuses.HIDDEN;

    const options = [
      strings.share.share_wish,
      strings.user.report_wish,
      strings.user.report_user,
      strings.common.cancel,
    ];

    if (isUserAdmin) {
      if (isActive) {
        options.splice(3, 0, strings.wishes.hide_wish);
      } else if (isHidden) {
        options.splice(3, 0, strings.wishes.unhide_wish);
      }
    }

    const cancelButtonIndex = options.length - 1;
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          shareDynamicLink({
            actionType: OPEN_WISH_DETAILS,
            id: wish.id,
            communityId: wish.group_id,
            socialImageUrl: wish.wish_image,
            socialTitle: wish.title,
            socialDescription: wish.description,
            linkDescription: strings.deep_linking.wish_link_info,
          });
        }
        if (buttonIndex === 1) {
          this.openItemReportModal();
        }
        if (buttonIndex === 2) {
          this.openUserReportModal();
        }
        if (isUserAdmin && buttonIndex === 3 && (isActive || isHidden)) {
          setTimeout(() => {
            if (isActive) {
              this.onHide();
            } else {
              this.onUnhide();
            }
          }, 500);
        }
      },
    );
  };

  onOpenChatRoom = async () => {
    const { openPersonalChat, wish } = this.props;

    openPersonalChat({
      userId: wish.wisher_id,
      profileImage: wish.wisher_image,
      selectedUserName: wish.wisher_name,
      wishId: wish.id,
    });
  };

  onFulfillWish = () => {
    const { wish } = this.props;

    NavigationService.navigate(screens.EditItemStack, {
      screen: screens.EditItem,
      params: {
        wish,
      },
    });
  };

  render() {
    const { scrollY } = this.state;
    const { user, wish, isUserAdmin } = this.props;

    const { wisher_image, wisher_name, wisher_id, status, isWisherBlocked } = wish;
    const isCurrentUser = Number(wish.wisher_id) === user.id;
    const isActive = status === itemStatuses.ACTIVE;
    const isHidden = status === itemStatuses.HIDDEN;

    let onOptionsButtonPress = null;

    if (isCurrentUser && (isActive || isHidden)) {
      onOptionsButtonPress = this.onOpenActionSheet;
    } else if (!isCurrentUser && (isUserAdmin || isActive)) {
      onOptionsButtonPress = this.onOpenBuyerActionSheet;
    }

    return (
      <Container>
        <ItemDetailsView
          isWish
          scrollY={scrollY}
          item={wish}
          onOptionsButtonPress={onOptionsButtonPress}
          isCurrentUser={isCurrentUser}
          isUnavailableDiscount
        />
        <BottomBar
          isWish
          userAvatar={wisher_image}
          userName={wisher_name}
          isActive={isActive}
          userId={wisher_id}
          isCurrentUser={isCurrentUser}
          isUserBlocked={isWisherBlocked}
          isLoading={this.state.isLoading}
          onOpenChatRoom={this.onOpenChatRoom}
        >
          <RoundButton
            withMarginRight
            title={strings.wishes.fullfill_wish}
            onPress={this.onFulfillWish}
          />
        </BottomBar>
      </Container>
    );
  }
}

export default connect(
  (state, { route }) => ({
    user: state.userInfo,
    wish: lotsSelectors.getWishInfoById(state, route.params.wishId),
    isUserAdmin: communitySelectors.getIsUserAdmin(state),
  }),
  (dispatch) => ({
    openPersonalChat: (user) => dispatch(chatsOperations.openPersonalChat(user)),
    removeWish: ({ wish_id, wisher_id }) =>
      dispatch(lotsOperations.removeWish({ wish_id, wisher_id })),
    getWishDetails: (id) => dispatch(lotsOperations.getWishDetails(id)),
    getWishInfo: (id) => dispatch(lotsOperations.getWishInfo(id)),
    hideWish: (wishId) => dispatch(lotsOperations.hideWish(wishId)),
    unhideWish: (wishId) => dispatch(lotsOperations.unhideWish(wishId)),
  }),
)(WishDetails);
