const ERROR = 'ERROR';
const SUCCESS = 'SUCCESS';
const INFO = 'INFO';
const WARNING = 'WARNING';
const NOTIFICATION = 'NOTIFICATION';

export default {
  ERROR,
  SUCCESS,
  INFO,
  WARNING,
  NOTIFICATION,
};
