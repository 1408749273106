import { CommonActions, StackActions } from '@react-navigation/native';
import { ENV } from '../../config/env';
import { NavigationActions } from '@react-navigation/compat';

class NavigationService {
  constructor() {
    this._navigation = null;
    this.routeName = null;
  }

  init(navigation) {
    if (this._navigation && ENV === 'production') {
      return;
    }
    this._navigation = navigation;
  }

  destroy() {
    this._navigation = null;
  }

  getState() {
    return this._navigation.state;
  }

  onReady() {
    this.routeName = this._navigation.getCurrentRoute().name;
  }

  navigate(name, params) {
    this._navigation.dispatch(
      CommonActions.navigate({
        name,
        params,
      }),
    );
  }

  push(name, params) {
    this._navigation.dispatch(StackActions.push(name, params));
  }

  replace(name, params) {
    this._navigation.dispatch(StackActions.replace(name, params));
  }

  pop(routeName, params) {
    this.goBack(params);
  }

  goBack(params) {
    this._navigation.dispatch(NavigationActions.back(params));
  }
}

const navigationService = new NavigationService();

export default navigationService;
