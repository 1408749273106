import React from 'react';
import T from 'prop-types';
import { StyleSheet, View } from 'react-native';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import { colors, dimensions } from '../../../styles';

const s = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const StarsRating = ({ rating, size, style }) => {
  return (
    <View style={[s.container, style]}>
      {Array(rating)
        .fill(null)
        .map((_, index) => {
          return (
            <MaterialIcon size={size} name="star" color={colors.violet} key={`star-${index}`} />
          );
        })}
    </View>
  );
};

StarsRating.propTypes = {
  rating: T.number.isRequired,
  style: T.shape({}),
  size: T.number,
};

StarsRating.defaultProps = {
  size: dimensions.medium,
  style: null,
};

export default StarsRating;
