import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Alert, Image, ImageBackground, KeyboardAvoidingView, ScrollView } from 'react-native';
import {
  View,
  Input,
  Text,
  Container,
  TouchableItem,
  Button,
} from '../../../../ReusableComponents';
import { colors, headerStyle, rs } from '../../../../../styles';
import { transactionOperations } from '../../../../../store/transactions';
import strings from '../../../../../localization';
import s from './styles';
import { numberWithCommas } from '../../../../../utils/stringHelper';
import { MASTER, REWARD } from '../../../../../constants/walletTypes';
import { isIos } from '../../../../../utils/detectDevice';

const SendTokensToBank = ({ sendTokensToBank, wallet, rewardWallet }) => {
  const [walletType, setWalletType] = useState(MASTER);
  const [amount, setAmount] = useState('');
  const [note, setNote] = useState('');

  const walletBalance =
    walletType === MASTER
      ? Number(wallet.balance_amount) - Number(wallet.hold_balance)
      : Number(rewardWallet.balance_amount) - Number(rewardWallet.hold_balance);

  const isValid =
    !!amount &&
    Number(amount) <= walletBalance &&
    !!note &&
    note.trim().length > 9 &&
    note.length <= 120;

  const onSubmit = () => {
    const bodyText = strings.bank.confirm_send_alert_body.replace('XX', amount);

    Alert.alert(strings.bank.confirm_send_alert_title, bodyText, [
      {
        text: strings.common.confirm,
        onPress: () => {
          sendTokensToBank({ walletType, amount, note: note.trim() });
        },
      },
      {
        text: strings.common.cancel,
        onPress: () => {},
        style: 'cancel',
      },
    ]);
  };

  const onSetAmount = (value) => {
    setAmount(value.replace(/[^0-9]/g, ''));
  };

  return (
    <Container>
      <KeyboardAvoidingView
        keyboardShouldPersistTabs="handled"
        behavior={isIos ? 'padding' : null}
        style={s.keyboardAvoidingView}
        keyboardVerticalOffset={isIos ? 80 : 0}
      >
        <ScrollView keyboardShouldPersistTabs="handled">
          <View style={s.sectionHeaderContainer}>
            <Text semiBold medium>
              {strings.bank.select_wallet_title}
            </Text>
          </View>

          <View style={s.walletsContainer}>
            <TouchableItem
              style={s.walletBackgroundContainer}
              onPress={() => setWalletType(MASTER)}
            >
              <ImageBackground
                style={s.balanceBackground}
                imageStyle={s.walletImage}
                source={{
                  uri: R.isEmpty(rewardWallet) ? 'wallet_background_big' : 'wallet_background',
                }}
                resizeMode="cover"
              >
                {walletType === MASTER && <View style={s.selectedWalletBorder} />}
                <View style={s.balanceContainer}>
                  <Text color={colors.white} semiBold>
                    {strings.bank.balance}
                  </Text>
                  <Text medium bold color={colors.white} style={s.balanceText}>
                    {numberWithCommas(Number(wallet.balance_amount) - Number(wallet.hold_balance))}
                  </Text>
                </View>
              </ImageBackground>
            </TouchableItem>

            {!R.isEmpty(rewardWallet) && (
              <TouchableItem
                style={s.walletBackgroundContainer}
                onPress={() => setWalletType(REWARD)}
              >
                <ImageBackground
                  style={s.balanceBackground}
                  imageStyle={s.walletImage}
                  source={{ uri: 'rewards_wallet_background' }}
                  resizeMode="cover"
                >
                  {walletType === REWARD && <View style={s.selectedWalletBorder} />}
                  <View style={s.balanceContainer}>
                    <Text color={colors.blueLight} semiBold>
                      {strings.bank.reward_allowance}
                    </Text>
                    <Text medium bold color={colors.blueLight}>
                      {numberWithCommas(
                        Number(rewardWallet.balance_amount) - Number(rewardWallet.hold_balance),
                      )}
                    </Text>
                  </View>
                </ImageBackground>
              </TouchableItem>
            )}
          </View>

          <View style={s.sectionHeaderContainer}>
            <Text semiBold medium>
              {strings.bank.send_tokens_amount_title}
            </Text>
          </View>

          <View style={s.amountInputContainer}>
            <Image style={s.tokenIcon} source={{ uri: 'price' }} resizeMode="contain" />
            <Input
              autoFocus
              style={s.amountInput}
              maxLength={5}
              placeholder="0"
              keyboardType="numeric"
              onChangeText={onSetAmount}
              value={amount}
            />
          </View>

          <View style={s.sectionHeaderContainer}>
            <Text semiBold medium>
              {strings.bank.send_tokens_note_title}
            </Text>
          </View>

          <View style={s.noteContainer}>
            <Input
              style={s.notesInput}
              multiline
              blurOnSubmit
              numberOfLines={3}
              maxLength={120}
              maxHeight={90}
              placeholder={strings.bank.at_least}
              onChangeText={setNote}
              value={note}
            />
            <Text xsmall style={s.charactersCountText} color={colors.gray}>
              {note.length}/120
            </Text>
          </View>
        </ScrollView>

        <View style={s.buttonContainer}>
          <Button title={strings.common.send} onPress={onSubmit} disabled={!isValid} />
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

export default connect(
  (state) => ({
    wallet: state.wallets.wallet,
    rewardWallet: state.wallets.rewardWallet,
  }),
  (dispatch) => ({
    sendTokensToBank: ({ walletType, amount, note }) =>
      dispatch(transactionOperations.sendTokensToBank({ walletType, amount, note })),
  }),
)(SendTokensToBank);
