import React from 'react';
import NetInfo from '@react-native-community/netinfo';
import { Image } from 'react-native';
import TextButton from '../../Buttons/TextButton';
import CenteredView from '../../Containers/CenteredView';
import Icon from '../../Icon';
import Text from '../../Text';
import { colors, dimensions, rs } from '../../../../styles';
import strings from '../../../../localization';

export default () => (
  <CenteredView>
    <Image
      source={{ uri: 'no_internet_connection' }}
      resizeMode="contain"
      style={{ width: 240, height: 240 }}
    />

    <Text large semiBold style={rs.marginVertical}>
      {strings.internet_connection.no_internet_connection_title}
    </Text>

    <Text alignCenter medium style={rs.marginBottom}>
      {strings.internet_connection.no_internet_connection_subtitle}
    </Text>

    <TextButton
      alignCenter
      title={strings.internet_connection.refresh}
      onPress={() => {
        NetInfo.fetch();
      }}
    />
  </CenteredView>
);
