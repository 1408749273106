import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Alert } from 'react-native';
import {
  View,
  Text,
  Container,
  BackButton,
  TouchableItem,
  NavigationIconButton,
  NavigationButton,
  AngleIcon,
  Input,
} from '../../../ReusableComponents';
import { headerStyle, rs, colors, dimensions } from '../../../../styles/index';
import strings from '../../../../localization';
import s from './style';
import { ScrollView } from 'react-native';
import { userLocationsOperations } from '../../../../store/userLocations';
import { hasPrimaryLocation } from '../../../../store/userLocations/selectores';
import { getAvailabilitiesForList } from '../../../../store/shipping/selectors';
import locationsTypes from '../../../../constants/locationsTypes';
import TypeItem from './components/TypeItem';
import SectionHeader from './components/SectionHeader';
import HorizontalSelectionList from './components/HorizontalSelectionList';
import NavigationService from '../../../../services/NavigationService';
import screens from '../../../../navigation/screens';
import { priceAmounts, shipperAmounts } from '../../../../constants/shipperDelivery';
import { getParamOr } from '../../../../utils/navHelper';
import { LoadingService } from '../../../../services';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const CreateLocation = ({
  navigation,
  route,
  availabilities,
  userHasPrimaryLocation,
  createLocation,
  editLocation,
}) => {
  const isEdit = route.params?.isEdit ?? false;
  const locationFromNavigation = route.params?.location ?? null;

  const [location, setLocation] = useState(locationFromNavigation);
  const [locationType, setLocationType] = useState(
    isEdit ? locationsTypes.SHIPPER : locationsTypes.USER,
  );
  const [shipperAmount, setShipperAmount] = useState(
    isEdit ? locationFromNavigation.shippers_count : shipperAmounts[0],
  );
  const [priceAmount, setPriceAmount] = useState(
    isEdit ? locationFromNavigation.price : priceAmounts[0],
  );
  const [availability, setAvailability] = useState(
    isEdit ? parseInt(locationFromNavigation.availability, 10) : availabilities[0].id,
  );
  const [internalNote, setInternalNote] = useState(
    isEdit ? locationFromNavigation.internal_note : '',
  );
  const [selectedShippers, setSelectedShippers] = useState(
    isEdit ? locationFromNavigation.shipper_profiles : [],
  );

  useEffect(() => {
    navigation.setParams({
      onPressDone: isEdit ? onEditLocation : onCreateLocation,
    });
  }, [
    location,
    locationType,
    shipperAmount,
    priceAmount,
    availability,
    internalNote,
    selectedShippers,
  ]);

  const onEditAddressCallback = (newLocation) => {
    if (!R.isEmpty(newLocation)) {
      const updatedLocation = isEdit ? { ...location, ...newLocation } : newLocation;
      setLocation(updatedLocation);
    }
  };

  const onCreateLocation = async () => {
    LoadingService.showLoader();
    const isShipperLocation = locationType === locationsTypes.SHIPPER;

    await createLocation({
      location,
      type: locationType,
      availability: isShipperLocation ? availability : null,
      shippersCount: isShipperLocation ? shipperAmount : null,
      price: isShipperLocation ? priceAmount : null,
      internalNote: isShipperLocation ? internalNote : null,
      shippersUserIds: isShipperLocation ? selectedShippers.map(({ id }) => id) : null,
    });
    LoadingService.hideLoader();
    NavigationService.goBack();
  };

  const onEditLocation = async () => {
    LoadingService.showLoader();

    await editLocation({
      ...location,
      availability,
      shippers_count: shipperAmount,
      price: priceAmount,
      internal_note: internalNote,
      shippers_user_ids: selectedShippers.map(({ id }) => id),
    });

    LoadingService.hideLoader();
    NavigationService.goBack();
  };

  const onEditAddress = () => {
    NavigationService.navigate(screens.Location, {
      location: locationFromNavigation,
      selectLocations: onEditAddressCallback,
    });
  };

  const onSelectMembers = (members) => {
    setSelectedShippers(members);
    NavigationService.goBack();
  };

  const onAddShippers = () => {
    NavigationService.navigate(screens.IssueMemberList, {
      onSubmit: onSelectMembers,
      selectedMembers: selectedShippers,
    });
  };

  const onSelectShipperLocationType = () => {
    if (!userHasPrimaryLocation) {
      Alert.alert(
        strings.locations.shipper_type_alert_title,
        strings.locations.shipper_type_alert_description,
      );
    } else {
      setLocationType(locationsTypes.SHIPPER);
    }
  };

  const selectedShippersValue = selectedShippers
    .map(({ name, nickname }) => `@${nickname || name}`)
    .join(', ');

  return (
    <Container>
      <KeyboardAwareScrollView
        extraScrollHeight={dimensions.medium}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={rs.bigPaddingVertical}
      >
        <View style={s.locationContainer}>
          <Text>{location.formatted_address}</Text>
          <TouchableItem onPress={onEditAddress}>
            <Text xsmall color={colors.blueLight} style={rs.paddingTop}>
              {strings.locations.edit_location}
            </Text>
          </TouchableItem>
        </View>

        {!isEdit && (
          <Fragment>
            <View style={s.typeTitleContainer}>
              <Text semiBold medium>
                {strings.locations.pickup_type}
              </Text>
            </View>

            <View style={[rs.row, rs.paddingHorizontal, rs.paddingTop]}>
              <TypeItem
                icon="Shipping_personal-pickup"
                withMarginRight
                isSelected={locationType === locationsTypes.USER}
                title={strings.shipping.meet_in_person}
                description={strings.locations.meet_in_person_description}
                onPress={() => setLocationType(locationsTypes.USER)}
              />
              <TypeItem
                icon="scooter"
                isSelected={locationType === locationsTypes.SHIPPER}
                title={strings.locations.shareitt_on_the_road}
                description={strings.locations.shipper_addresses_description}
                onPress={onSelectShipperLocationType}
              />
            </View>
          </Fragment>
        )}

        {locationType === locationsTypes.SHIPPER && (
          <Fragment>
            <HorizontalSelectionList
              icon="Pickup_Shipper-3"
              title={strings.locations.shippers_amount_title}
              description={strings.locations.shippers_amount_description}
              data={shipperAmounts}
              selectedItem={shipperAmount}
              onSelect={setShipperAmount}
            />
            <HorizontalSelectionList
              icon="Token"
              title={strings.locations.delivery_price_title}
              description={strings.locations.delivery_price_description}
              data={priceAmounts}
              selectedItem={priceAmount}
              onSelect={setPriceAmount}
            />
            <HorizontalSelectionList
              icon="Pickup_availability-2"
              title={strings.locations.availability}
              description={strings.locations.availability_description}
              data={availabilities}
              selectedItem={availability}
              onSelect={setAvailability}
            />

            <SectionHeader
              icon="Pickup_Shipper-2"
              title={strings.locations.shippers_info_title}
              description={strings.locations.shippers_info_description}
            />

            <TouchableItem style={s.addShippersButton} onPress={onAddShippers}>
              {R.isEmpty(selectedShippers) ? (
                <Text color={colors.gray}>{strings.locations.add_shippers}</Text>
              ) : (
                <Text color={colors.blueLight} style={s.selectedShippersText}>
                  {selectedShippersValue}
                </Text>
              )}
              <AngleIcon />
            </TouchableItem>

            <Input
              maxLength={80}
              onChangeText={setInternalNote}
              value={internalNote}
              placeholder={strings.locations.internal_note_placeholder}
              underlineColorAndroid="transparent"
              multiline
              numberOfLines={4}
              style={s.internalNoteInput}
            />
          </Fragment>
        )}
      </KeyboardAwareScrollView>
    </Container>
  );
};

export default connect(
  (state) => ({
    // user: state.userInfo,
    availabilities: getAvailabilitiesForList(),
    userHasPrimaryLocation: hasPrimaryLocation(state),
  }),
  (dispatch) => ({
    createLocation: (params) => dispatch(userLocationsOperations.createLocation(params)),
    editLocation: (location) => dispatch(userLocationsOperations.editLocation(location)),
  }),
)(CreateLocation);
