import { createSelector } from 'reselect';
import * as R from 'ramda';

export const getCommunityMembers = createSelector(
  [
    R.pathOr([], ['communityMembers', 'communityMemberIds']),
    R.pathOr({}, ['communityMembers', 'communityMemberEntities']),
  ],
  (ids, entities) => ids.map((id) => entities[id]),
);

const getCommunityMembersHistory = createSelector(
  [
    R.pathOr([], ['communityMembers', 'communityMemberSearchHistoryIds']),
    R.pathOr({}, ['communityMembers', 'communityMemberSearchHistoryEntities']),
  ],
  (ids, entities) => {
    return ids.map((id) => entities[id]);
  },
);

export default {
  getCommunityMembers,
  getCommunityMembersHistory,
};
