export const CHATS_START = 'chats/CHATS_START';
export const CHATS_SUCCESS = 'chats/CHATS_SUCCESS';
export const CHATS_ERROR = 'chats/CHATS_ERROR';
export const RESET_SEARCHED_CHATS = 'chats/RESET_SEARCHED_CHATS';

export const ADD_NEW_CHAT = 'chats/ADD_NEW_CHAT';
export const UPDATE_CHAT = 'chats/UPDATE_CHAT';

export const CHAT_INFO_START = 'chats/CHAT_INFO_START';
export const CHAT_INFO_SUCCESS = 'chats/CHAT_INFO_SUCCESS';

export const ADD_CHAT_ENTITY = 'chats/ADD_CHAT_ENTITY';
export const RESET_TEMPORARY_CHAT = 'chats/RESET_TEMPORARY_CHAT';

export const SET_MESSAGE = 'chats/SET_MESSAGE';
export const UPDATE_MESSAGE = 'chats/UPDATE_MESSAGE';
export const REMOVE_MESSAGE = 'chats/REMOVE_MESSAGE';

export const MESSAGES_START = 'chats/MESSAGES_START';
export const MESSAGES_SUCCESS = 'chats/MESSAGES_SUCCESS';

export const RESET_CHATS_STATE = 'chats/RESET_CHATS_STATE';

export const SET_INSIDE_ROOM_ID = 'chats/SET_INSIDE_ROOM_ID';

export const MARK_CHAT_AS_READ = 'chats/MARK_CHAT_AS_READ';
export const INCREASE_CHAT_UNREAD_COUNT = 'chats/INCREASE_CHAT_UNREAD_COUNT';
export const INCREASE_SUPPORT_CHAT_UNREAD_COUNT = 'chats/INCREASE_SUPPORT_CHAT_UNREAD_COUNT';
export const INCREASE_TOTAL_UNREAD_COUNT = 'chats/INCREASE_TOTAL_UNREAD_COUNT';
export const INCREASE_SUPPORT_CHATS_TOTAL_UNREAD_COUNT =
  'chats/INCREASE_SUPPORT_CHATS_TOTAL_UNREAD_COUNT';
export const SET_TOTAL_UNREAD_COUNT = 'chats/SET_TOTAL_UNREAD_COUNT';
export const SET_SUPPORT_CHATS_TOTAL_UNREAD_COUNT = 'chats/SET_SUPPORT_CHATS_TOTAL_UNREAD_COUNT';
export const ARCHIVE_CHATS = 'chats/ARCHIVE_CHATS';
export const UNARCHIVE_CHATS = 'chats/UNARCHIVE_CHATS';
export const MARK_CHAT_MESSAGES_AS_READ = 'chats/MARK_CHAT_MESSAGES_AS_READ';
export const SET_USER_TYPING_MESSAGE = 'chats/SET_USER_TYPING_MESSAGE';
