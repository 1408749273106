import React, { useEffect, useState } from 'react';
import { TabView } from 'react-native-tab-view';
import { connect } from 'react-redux';
import T from 'prop-types';
import { StyleSheet } from 'react-native';
import { offersOperations, offersSelectors } from '../../../store/offers';
import { headerStyleTransparentWithBackground } from '../../../styles/headerStyle';
import {
  TabBar,
  Container,
  IconButton,
  EmptyListWithIcon,
  Spinner,
  EmptyListWithImage,
} from '../../ReusableComponents';
import strings from '../../../localization';
import { SELLING } from '../../../constants/offerTypes';
import SellingOffer from './components/SellingOffer';
import OffersList from './components/OffersList';
import { dimensions, rs } from '../../../styles';
import { LoadingService, ModalsService } from '../../../services';
import NavigationService from '../../../services/NavigationService';
import screens from '../../../navigation/screens';
import modalTypes from '../../../constants/modalTypes';
import { SELLER_CONFIRMED_OFFER } from '../../../store/offers/offerActions';
import { lotsOperations } from '../../../store/lots';
import CenteredView from '../../ReusableComponents/Containers/CenteredView';

const s = StyleSheet.create({
  tabViewInitialLayout: {
    height: 0,
    width: dimensions.width,
  },
});

const SellingOffers = ({
  performAction,
  askedToBuyOffers,
  pendingDeliveryOffers,
  askedToBuyOfferLoadersInfo,
  pendingDeliveryOfferLoadersInfo,
  getSellingAskedToBuyOffers,
  getSellingPendingDeliveryOffers,
  getItemInfo,
}) => {
  const [index, setIndex] = useState(0);
  const [isRefreshing, setRefreshing] = useState(false);

  useEffect(() => {
    onFetchOffers();
  }, []);

  const onFetchOffers = async () => {
    await getSellingAskedToBuyOffers();
    await getSellingPendingDeliveryOffers();
  };

  const onRefresh = async () => {
    setRefreshing(true);

    if (index === 0) {
      await getSellingAskedToBuyOffers();
      await getSellingPendingDeliveryOffers();
    } else {
      await getSellingPendingDeliveryOffers();
      await getSellingAskedToBuyOffers();
    }

    setRefreshing(false);
  };

  const onLoadMoreAskedToBuyOffers = () => {
    getSellingAskedToBuyOffers(true);
  };

  const onLoadMorePendingDeliveryOffer = () => {
    getSellingPendingDeliveryOffers(true);
  };

  const onPerformAction = async (offerId, actionId) => {
    LoadingService.showSuccessLoader();
    await performAction(offerId, actionId);
    LoadingService.hideSuccessLoader();
  };

  const onPerformRefundBySeller = async (offerId) => {
    ModalsService.showSwipeableModal(modalTypes.REFUND, {
      offerId,
    });
  };

  const onPerformRepostBySeller = async (offerId, itemId) => {
    LoadingService.showLoader();
    const item = await getItemInfo(itemId);

    if (item.has_multiple_supply) {
      LoadingService.hideLoader();
      ModalsService.showModal(modalTypes.REPOST, { offerId });
    } else {
      await performAction(offerId, SELLER_CONFIRMED_OFFER);
      LoadingService.hideLoader();
    }
  };

  const onOpenChat = (chatId) => {
    NavigationService.push(screens.ChatRoom, { chatId });
  };

  const onOpenCreateNewItem = () => {
    NavigationService.navigate(screens.EditItemStack);
  };

  const routes = [
    { key: 'askedToBuy', title: strings.buy_sell.asked_to_buy },
    { key: 'pendingDelivery', title: strings.buy_sell.pending_delivery },
  ];

  const renderLazyPlaceholder = () => <Spinner />;

  return (
    <Container>
      <TabView
        navigationState={{ index, routes }}
        onIndexChange={setIndex}
        renderTabBar={(props) => <TabBar {...props} />}
        initialLayout={s.tabViewInitialLayout}
        lazy
        renderLazyPlaceholder={renderLazyPlaceholder}
        renderScene={({ route }) =>
          ({
            askedToBuy: (
              <OffersList
                data={askedToBuyOffers}
                isRefreshing={isRefreshing}
                isLoading={askedToBuyOfferLoadersInfo.isLoading}
                isLoadingMore={askedToBuyOfferLoadersInfo.isLoadingMore}
                onLoadMore={onLoadMoreAskedToBuyOffers}
                onRefresh={onRefresh}
                ListEmptyComponent={() => (
                  <EmptyListWithImage
                    image="empty_state_selling"
                    text={strings.emptyLists.empty_selling_offers_list}
                    buttonTitle={strings.emptyLists.upload_an_item}
                    onPress={onOpenCreateNewItem}
                  />
                )}
                renderItem={({ item }) => (
                  <SellingOffer
                    offerType={SELLING}
                    offer={item}
                    onOpenChat={onOpenChat}
                    onPerformAction={onPerformAction}
                    onPerformRepostBySeller={onPerformRepostBySeller}
                  />
                )}
              />
            ),
            pendingDelivery: (
              <OffersList
                data={pendingDeliveryOffers}
                isRefreshing={isRefreshing}
                isLoading={pendingDeliveryOfferLoadersInfo.isLoading}
                isLoadingMore={pendingDeliveryOfferLoadersInfo.isLoadingMore}
                onLoadMore={onLoadMorePendingDeliveryOffer}
                onRefresh={onRefresh}
                ListEmptyComponent={() => (
                  <EmptyListWithImage
                    image="empty_state_selling_delivery"
                    text={strings.emptyLists.empty_selling_pending_delivery_offers_list}
                    buttonTitle={strings.emptyLists.upload_an_item}
                    onPress={onOpenCreateNewItem}
                  />
                )}
                renderItem={({ item }) => (
                  <SellingOffer
                    offerType={SELLING}
                    offer={item}
                    onOpenChat={onOpenChat}
                    onPerformRefundBySeller={onPerformRefundBySeller}
                  />
                )}
              />
            ),
          }[route.key])
        }
      />
    </Container>
  );
};

SellingOffers.propTypes = {
  performAction: T.func,
  getSellingAskedToBuyOffers: T.func,
  getSellingPendingDeliveryOffers: T.func,
  askedToBuyOffers: T.array,
  pendingDeliveryOffers: T.array,
  askedToBuyOfferLoadersInfo: T.shape({
    isLoading: T.bool,
    isLoadingMore: T.bool,
  }),
  pendingDeliveryOfferLoadersInfo: T.shape({
    isLoading: T.bool,
    isLoadingMore: T.bool,
  }),
};

export default connect(
  (state, { searchValue }) => ({
    askedToBuyOffers: offersSelectors.getSellingAskedToBuyOffers(state, searchValue),
    pendingDeliveryOffers: offersSelectors.getSellingPendingDeliveryOffers(state, searchValue),
    askedToBuyOfferLoadersInfo: state.offers.sellingOffers.askedToBuy,
    pendingDeliveryOfferLoadersInfo: state.offers.sellingOffers.pendingDelivery,
  }),
  (dispatch) => ({
    performAction: (offerId, actionId) =>
      dispatch(offersOperations.performAction(offerId, actionId)),
    getSellingAskedToBuyOffers: (isLoadMore) =>
      dispatch(offersOperations.getSellingAskedToBuyOffers({ isLoadMore })),
    getSellingPendingDeliveryOffers: (isLoadMore) =>
      dispatch(offersOperations.getSellingPendingDeliveryOffers({ isLoadMore })),
    getItemInfo: (itemId) => dispatch(lotsOperations.getItemInfo(itemId)),
  }),
)(SellingOffers);
