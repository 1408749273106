import { createSelector } from 'reselect';
import * as R from 'ramda';
import chunk from '../../utils/chunk';

export const getMostFollowedUsersList = createSelector(
  [R.pathOr([], ['home', 'mostFollowedUsers'])],
  (mostFollowedUsers) => mostFollowedUsers,
);

export const getMyFollowersList = createSelector(
  [R.pathOr([], ['home', 'myFollowers'])],
  (myFollowers) => myFollowers,
);

export const getTopSellerUsersList = createSelector(
  [R.pathOr([], ['home', 'topSellers'])],
  (topSellers) => topSellers,
);

export const getMySellerUsersList = (state) => {
  return R.pathOr([], ['home', 'mySellers'], state);
};

export const getSellersNearmeList = createSelector(
  [R.pathOr([], ['home', 'sellersNearMe'])],
  (sellersNearMe) => sellersNearMe,
);

export const getTopCategoriesList = createSelector(
  [R.pathOr([], ['home', 'topUploadedCategories'])],
  (categories) => categories,
);

export const getRewardsList = createSelector([R.pathOr([], ['home', 'rewards'])], (data) =>
  R.sortWith([R.ascend(R.prop('order'))], data),
);

export const getCitiesList = createSelector([R.pathOr([], ['home', 'cities'])], (data) =>
  chunk(data, 2),
);

export const getCampusesList = createSelector(
  [R.pathOr([], ['home', 'campuses'])],
  (data) => {
    return data;
  },
);

export const getMyTopCategoriesList = createSelector(
  [R.pathOr([], ['home', 'myTopCategories'])],
  (categories) => categories,
);

export const getTopItems = createSelector(
  [R.pathOr([], ['home', 'itemIdsTop']), R.pathOr({}, ['home', 'itemEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getItemsFromMembersIFollowList = createSelector(
  [R.pathOr([], ['home', 'itemIdsFromMembersIFollow']), R.pathOr({}, ['home', 'itemEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getNearMeItemsList = createSelector(
  [R.pathOr([], ['home', 'itemIdsNearMe']), R.pathOr({}, ['home', 'itemNearEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getNearPrimaryLocationItemsList = createSelector(
  [R.pathOr([], ['home', 'itemIdsNearPrimaryLocation']), R.pathOr({}, ['home', 'itemEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getDiscountedItemsList = createSelector(
  [R.pathOr([], ['home', 'itemIdsDiscounted']), R.pathOr({}, ['home', 'itemEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getNewest = createSelector(
  [
    R.pathOr([], ['home', 'newestItemsInCommunityGroups']),
    R.pathOr({}, ['home', 'itemEntities']),
    R.pathOr([], ['groups', 'groupEntities']),
  ],
  (newest, itemEntities, groupEntities) => {
    const array = [];

    R.forEachObjIndexed((value, key) => {
      if (R.isEmpty(value)) return;
      array.push({
        groupName: R.path([key, 'group_name'], groupEntities),
        groupId: key,
        items: value.map((id) => itemEntities[id]),
      });
    }, newest);

    return array;
  },
);

export const getNewestItemsList = (state) => {
  return R.pathOr([], ['home', 'newestItems'], state);
};

export const getExcludedItemIds = createSelector(
  [R.pathOr([], ['home', 'excludedItemIdsCache'])],
  (ids) => ids,
);

export default {
  getMyFollowersList,
  getTopSellerUsersList,
  getMySellerUsersList,
  getSellersNearmeList,
  getMostFollowedUsersList,
  getTopCategoriesList,
  getMyTopCategoriesList,
  getTopItems,
  getItemsFromMembersIFollowList,
  getNearMeItemsList,
  getNearPrimaryLocationItemsList,
  getDiscountedItemsList,
  getNewest,
  getNewestItemsList,
  getRewardsList,
  getCitiesList,
  getCampusesList,
  getExcludedItemIds,
};
