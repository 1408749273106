import React, { useEffect } from 'react';
import T from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  StyleSheet,
  View,
  ImageBackground,
  TouchableOpacity,
  Linking,
  I18nManager,
} from 'react-native';
import dynamicLinks from '@react-native-firebase/dynamic-links';
import { SafeAreaView } from 'react-native-safe-area-context';
import Icon from 'react-native-vector-icons/Ionicons';
import { appOperations } from '../../../../store/app';
import { AnalyticsService } from '../../../../services';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';
import { TouchableItem } from '../../';
import { rs, dimensions, colors } from '../../../../styles';

const CLOSE_SCREEN_TIMEOUT = 8000;

const s = StyleSheet.create({
  background: {
    width: dimensions.width,
    height: dimensions.height,
  },
  closeContainer: {
    width: 28,
    height: 28,
    borderRadius: 14,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: colors.gray,
    top: dimensions.medium,
    left: dimensions.medium,
  },
  closeIcon: {
    color: colors.gray,
    transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
  },
  button: {
    flexGrow: 1,
    backgroundColor: colors.transparent,
    margin: dimensions.medium,
  },
});

const PromotionModal = ({ params, onClose }) => {
  const dispatch = useDispatch();
  const { link, image } = params.promotion;

  useEffect(() => {
    AnalyticsService.logEvent(analyticsEventTypes.promotion_was_shown);

    const closeTimeout = setTimeout(() => {
      onClose();
    }, CLOSE_SCREEN_TIMEOUT);

    return () => {
      clearTimeout(closeTimeout);
    };
  }, []);

  const onPress = async () => {
    AnalyticsService.logEvent(analyticsEventTypes.promotion_redirect_action_triggered);
    const deepLinkRegExp = new RegExp(/https:\/\/shareitt\.page\.link\/(\S+)/);

    if (deepLinkRegExp.test(link)) {
      const resolvedLink = await dynamicLinks().resolveLink(link);
      dispatch(appOperations.processDeepLink(resolvedLink));
      onClose();
      return;
    }

    const isValid = Linking.canOpenURL(link);

    if (isValid) {
      await Linking.openURL(link);
    } else {
      console.warn(`Cannot open ${link}: invalid url provided`);
      onClose();
    }
  };

  const onClosePress = () => {
    AnalyticsService.logEvent(analyticsEventTypes.promotion_closed_with_cross_button);
    onClose();
  };

  return (
    <View style={rs.flex}>
      <ImageBackground source={{ uri: image }} resizeMode="cover" style={s.background}>
        <SafeAreaView />

        <TouchableItem style={s.closeContainer} onPress={onClosePress}>
          <Icon size={26} name="ios-close" style={s.closeIcon} />
        </TouchableItem>

        <TouchableOpacity onPress={onPress} activeOpacity={1} style={s.button} />
      </ImageBackground>
    </View>
  );
};

PromotionModal.propTypes = {
  params: T.shape({
    promotion: T.shape({
      image: T.string.isRequired,
      link: T.string.isRequired,
    }).isRequired,
  }).isRequired,
  onClose: T.func.isRequired,
};

export default PromotionModal;
