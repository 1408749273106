import React  from 'react';
import { StyleSheet, View, Image } from 'react-native';
import strings from '../../../../localization';
import Text from '../../Text';
import Icon from '../../Icon';
import TouchableItem from '../../TouchableItem';
import { MainContainer } from './styles';
import { colors, rs } from '../../../../styles';

const s = StyleSheet.create({
  imageContainer: {
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -40,
    width: 120,
    height: 120,
    borderRadius: 60,
  },
  image: {
    width: 80,
    height: 80,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
  title: {
    paddingTop: 50,
  },
});

const DonationSuccessModal = ({ onPress: onClose }) => (
  <View style={rs.alignCenter}>
    <View style={s.imageContainer}>
      <Image source={{ uri: 'donation' }} style={s.image} />
    </View>

    <MainContainer>
      <TouchableItem onPress={onClose} style={s.closeButton}>
        <Icon
          name="x"
          size={16}
          color={colors.black}
          style={[rs.paddingVertical, rs.paddingHorizontal]}
        />
      </TouchableItem>

      <Text color={colors.text} style={s.title} headline extraBold alignCenter>
        {strings.donate_points.modal_title}
      </Text>

      <Text color={colors.blueLight} style={rs.smallPaddingTop} extraBold large alignCenter>
        {strings.donate_points.modal_description}
      </Text>
    </MainContainer>
  </View>
);

export default DonationSuccessModal;
