import * as React from 'react';
import Image from '../Image';

const FastImage = (props) => <Image {...props} progressiveRenderingEnabled />;

FastImage.priority = {
  high: 'high',
};

export default FastImage;
