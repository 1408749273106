export default {
  title: {
    en: 'Welcome to Shareitt!',
    he: 'ברוכים הבאים לאפליקציית Shareitt!',
    br: 'Bem-vinda (o) ao Shareitt',
    de: 'Willkommen bei Shareitt!',
  },
  body: {
    en:
      'Shareitt is a unique social e-commerce platform for buying and selling second hand used or new items without spending money! Shareitt lets you recycle items that you\u0027re no longer using and earn valuable Shareitt coins.\n\nSo, how does it work?\nAfter downloading the app and a short registration process, we\u0027ll award you with a welcome gift of Shareitt Coins which you may use to purchase items, even on your first visit. Visit our Marketplace where you\u0027ll find dozens of items in a wide variety of categories: women\u0027s and men\u0027s fashion, toys, books, furniture, cookware, computers and mobile phones, sporting and camping goods and more. All the items are priced with Shareitt coins and you can purchase them in a few easy steps.\n\nIn order to earn Shareitt coins, just upload and sell items to other users. The more you upload and sell, the more coins you can accumulate. And the more you interact on Shareitt – buy, upload and sell items, share the app with friends, grant other users\u0027 wishes - the more gifts you\u0027ll receive from us.\n\nAnd another really important point: When you use Shareitt, you help conserve our planet, reduce pollution and make the world a better place!\n',
    he:
      'אפליקציית Shareitt היא פלטפורמה ייחודית למסחר חברתי שמאפשרת קניה ומכירה של פריטי יד שניה חדשים או משומשים בין אנשים, על בסיס נקודות Shareitt וללא צורך בכסף אמיתי! Shareitt מאפשרת לכם לנצל בחוכמה את עודף הפריטים העצום שאינו בשימוש ולקבל תמורתו  ממנו ערך אמיתי. \nאז איך זה עובד?\nמורידים את אפליקציית Shareitt ואחרי תהליך הרשמה קצר מקבלים מתנת הצטרפות מפנקת בדמות נקודות Shareitt שיאפשרו לכם לקבל פריטים שאהבתם כבר בביקור הראשון שלכם באפליקציה. \nבמרקטפלייס תוכלו למצוא עשרות אלפי פריטים בקטגוריות שונות: אופנת נשים וגברים, צעצועים, ספרים, ריהוט, כלי בישול ואפייה, מחשבים וסלולר, ספורט, קמפינג ועוד. כל הפריטים מתומחרים בנקודות Shareitt וניתנים לקבלה בכמה צעדים פשוטים. \nכדי לקבל נקודות Shareitt אנו ממליצים להעלות כמה שיותר פריטים ולמסור למשתמשים אחרים שמעוניינים בפריטים שלכם. בנוסף, תקבלו מאיתנו לא מעט מתנות ככל שתבצעו יותר ויותר פעולות במערכת: תמסרו, תקבלו, תעלו פריטים, תשתפו עם חברים, תגשימו משאלות וכו׳. \nועוד פרט חשוב שכדאי שתכירו - כשאתם מוסרים ומקבלים פריטים ב-Shareitt, אתם עוזרים לנו לשמור על כדור הארץ, להפחית את זיהום האוויר ולהפוך את העולם למקום טוב יותר.\n',
    br:
      'Shareitt é a única plataforma de e-commerce social para comprar e vender itens novos ou de segunda mão sem gastar dinheiro! O Shareitt permite que você recicle itens que não está mais usando e ganhe moedas valiosas.\n Então como funciona?\n Após baixar o aplicativo e um breve processo de cadastro, nós o recompensaremos com um presente de boas-vindas em moedas Shareitt que você pode usar para comprar itens, mesmo em sua primeira visita. Visite nosso Marketplace onde você encontrará dezenas de itens em uma ampla variedade de categorias: moda feminina e masculina, brinquedos, livros, móveis, utensílios de cozinha, computadores e celulares, artigos esportivos e de camping e muito mais. Todos os itens são precificados com moedas Shareitt e você pode comprá-los em algumas fáceis etapas.\n\n Para ganhar moedas Shareitt, basta fazer upload e vender itens para outros usuários. Quanto mais você fizer upload e vender, mais moedas poderá acumular. E quanto mais você interagir no Shareitt - faça upload, venda itens, compartilhe o aplicativo com amigos, realize desejos de outros usuários - mais presentes você receberá de nós.’ \n\n E outro ponto muito importante: quando você usa o Shareitt, você ajuda a conservar nosso planeta, reduzir a poluição e tornar o mundo um lugar melhor!',
    de:
      'Shareitt ist ein einzigartiger sozialer Marktplatz für die Weitergabe von Gegenständen ohne dabei Geld auszugeben! Mit Shareitt kannst du Artikel, die du nicht mehr brauchst, weitergeben und wertvolle Shareitt-Punkte verdienen. Wie funktioniert das? Nach dem Herunterladen der App und einem kurzen Registrierungsprozess erhältst du ein Willkommensgeschenk in Form von Shareitt-Punkten, die du bereits bei deinem ersten Besuch zum Erwerb von Artikeln verwenden kannst. Besuche unseren Marktplatz, auf dem du Dutzende von Artikeln in einer Vielzahl von Kategorien findest: Damen- und Herrenmode, Kinderkleider, Spielzeug, Bücher, Möbel, Kochgeschirr, Computer und Mobiltelefone, Sport- und Campingartikel und vieles mehr. Alle Artikel können mit Shareitt-Punkten in wenigen Schritten erworben werden. Um Shareitt-Punkte zu erhalten, lädst du einfach Artikel hoch und bietest sie anderen Nutzer:innen an. Je mehr du hochlädst und anbietest, desto mehr Punkte kannst du sammeln. Je mehr du auf Shareitt interagierst, Artikel erwirbst und anbietest, die App mit Freunden teilst und Wünsche von anderen Nutzern erfüllst - umso mehr Punkte  erhältst du von uns.\n\nUnd noch etwas Wichtiges: Wenn du Shareitt nutzt, leistest du einen Beitrag, unseren Planeten zu schützen, die Umweltbelastung zu reduzieren und die Welt zu einem besseren Ort zu machen!\n',
  },
};
