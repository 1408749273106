import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SafeAreaView, StyleSheet, FlatList } from 'react-native';
import * as R from 'ramda';
import { Container, Text, TouchableItem, EmptyListWithImage } from '../../../ReusableComponents';
import { colors, rs, dimensions } from '../../../../styles';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import screens from '../../../../navigation/screens';
import { getUserPickupLocations } from '../../../../store/userLocations/selectores';
import { ButtonContainer } from '../../../ReusableComponents/Buttons/ListButton';
import StaticHeightButton from '../../../ReusableComponents/Buttons/StaticHeightButton';
import Icon from '../../../ReusableComponents/Icon';

const s = StyleSheet.create({
  locationContainer: {
    minHeight: 60,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: dimensions.medium,
    justifyContent: 'space-between',
  },
  addressText: {
    lineHeight: 22,
    maxWidth: '90%',
  },
});

class ItemPickupLocations extends Component {
  state = {
    locations: [],
    selectedLocations: [],
    isActiveMeet: false,
  };

  componentDidMount() {
    const { navigation, route, userPickupLocations } = this.props;

    const itemLocations = route.params.itemLocations;

    const filteredArray = userPickupLocations.filter(
      (userLocation) =>
        !itemLocations.some(
          (itemLocation) =>
            itemLocation.latitude === userLocation.latitude &&
            itemLocation.longitude === userLocation.longitude,
        ),
    );

    const locations = [...itemLocations.filter((el) => el), ...filteredArray];

    this.setState({
      selectedLocations: itemLocations.filter((el) => !!el),
      locations,
      isActiveMeet: !!itemLocations.length,
    });

    navigation.setParams({
      onAddLocation: this.onAddLocation,
    });
  }

  componentDidUpdate(oldProps, oldState) {
    const newState = this.state;
    if (oldState.selectedLocations.length !== newState.selectedLocations.length) {
      this.props.navigation.setParams({
        isValid: newState.selectedLocations.length,
      });
    }
  }

  onSubmit = () => {
    const { selectedLocations } = this.state;
    const { route } = this.props;

    const onSetPickupLocations = route.params.onSetPickupLocations;

    onSetPickupLocations(selectedLocations);

    NavigationService.goBack();
  };

  onCheckboxToggle = (checked, location) => {
    const { selectedLocations } = this.state;

    if (checked && selectedLocations.length > 2) return;

    const newSelectedLocations = checked
      ? [...selectedLocations, location]
      : selectedLocations.filter(
          (el) => el.latitude !== location.latitude && el.longitude !== location.longitude,
        );

    if (checked && !selectedLocations.length) {
      this.setState({
        isActiveMeet: true,
      });
    }

    if (!checked && !newSelectedLocations.length) {
      this.setState({
        isActiveMeet: false,
      });
    }

    this.setState({
      selectedLocations: newSelectedLocations,
    });
  };

  onAddLocationCallBack = (location) => {
    if (this.state.selectedLocations.length < 3) {
      this.setState({
        selectedLocations: [...this.state.selectedLocations, location],
        isActiveMeet: true,
      });
    }
    this.setState({
      locations: [...this.state.locations, location],
    });
  };

  onAddLocation = () => {
    NavigationService.navigate(screens.Location, {
      selectLocations: this.onAddLocationCallBack,
    });
  };

  render() {
    const { locations, selectedLocations, isActiveMeet } = this.state;
    const isApplyDisabled = !isActiveMeet;

    return (
      <Container>
        <FlatList
          data={locations}
          keyExtractor={() => Math.random().toString()}
          keyboardShouldPersistTaps="handled"
          renderItem={({ item }) => {
            const isSelected = R.includes(item, selectedLocations);
            return (
              <TouchableItem
                style={s.locationContainer}
                onPress={() => this.onCheckboxToggle(!isSelected, item)}
              >
                <Text color={colors.gray} style={s.addressText}>
                  {item.formatted_address}
                </Text>

                {isSelected && (
                  <Icon name="check" size={22} color={colors.blueLight} withoutRTLScale />
                )}
              </TouchableItem>
            );
          }}
          ListEmptyComponent={
            <EmptyListWithImage
              image="empty_state_search"
              text={strings.locations.addresses_empty_list}
              onPress={this.onAddLocation}
              buttonTitle={strings.locations.add_address}
            />
          }
        />

        <SafeAreaView style={rs.marginBottom}>
          <ButtonContainer>
            <StaticHeightButton
              title={strings.common.apply}
              onPress={this.onSubmit}
              disabled={isApplyDisabled}
            />
          </ButtonContainer>
        </SafeAreaView>
      </Container>
    );
  }
}

export default connect((state, { navigation }) => ({
  userPickupLocations: getUserPickupLocations(state),
}))(ItemPickupLocations);
