import { createAction } from 'redux-actions';
import * as types from './types';

export const setOffers = createAction(types.SET_OFFERS);
export const addNewSellingOffer = createAction(types.ADD_NEW_SELLING_OFFER);
export const addNewBuyingOffer = createAction(types.ADD_NEW_BUYING_OFFER);
export const addNewWishFulfillmentOffer = createAction(types.ADD_NEW_WISH_FULFILLMENT_OFFER);
export const updateOfferStatus = createAction(types.UPDATE_OFFER_STATUS);
export const updateOffer = createAction(types.UPDATE_OFFER);
export const updateOffers = createAction(types.UPDATE_OFFERS);

export const offerStart = createAction(types.OFFER_START);
export const offerSuccess = createAction(types.OFFER_SUCCESS);
export const offerError = createAction(types.OFFER_ERROR);

export const buyingOffersStart = createAction(types.BUYING_OFFERS_START);
export const buyingOffersSuccess = createAction(types.BUYING_OFFERS_SUCCESS);
export const buyingOffersError = createAction(types.BUYING_OFFERS_ERROR);

export const sellingOffersStart = createAction(types.SELLING_OFFERS_START);
export const sellingOffersSuccess = createAction(types.SELLING_OFFERS_SUCCESS);
export const sellingOffersError = createAction(types.SELLING_OFFERS_ERROR);

export const wishFulfillmentOffersStart = createAction(types.WISH_FULFILLMENT_OFFERS_START);
export const wishFulfillmentOffersSuccess = createAction(types.WISH_FULFILLMENT_OFFERS_SUCCESS);
export const wishFulfillmentOffersError = createAction(types.WISH_FULFILLMENT_OFFERS_ERROR);

export const setOfferCounters = createAction(types.SET_OFFER_COUNTERS);
