import { StyleSheet } from 'react-native';
import { dimensions, colors } from '../../../styles';

export default StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    height: 84,
    padding: dimensions.medium,
    paddingRight: dimensions.doubleMedium,
    alignItems: 'center',
    backgroundColor: colors.grayLighter,
  },
  textContainer: {
    maxWidth: '95%',
  },
  closeIconButton: {
    position: 'absolute',
    top: dimensions.halfMedium,
    right: dimensions.halfMedium,
  },
  text: {
    lineHeight: 24,
  },
});
