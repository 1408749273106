import React, { memo, Fragment } from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import { Placeholder, PlaceholderLine, Fade } from 'rn-placeholder';
import strings from '../../../../localization';
import { TouchableItem, Text } from '../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../styles';

const s = StyleSheet.create({
  list: {
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLight,
  },
  listContentContainer: {
    paddingHorizontal: dimensions.medium - 4,
    paddingTop: dimensions.halfMedium,
    paddingBottom: dimensions.medium,
    alignItems: 'center',
    minWidth: '100%',
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 50,
    marginHorizontal: 4,
    height: 30,
    paddingHorizontal: 10,

    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: colors.white,
    borderColor: colors.grayLight,
  },
  activeButtonContainer: {
    backgroundColor: colors.blueLight,
    borderColor: colors.blueLight,
  },
  amountContainer: {
    height: 16,
    minWidth: 18,
    marginRight: 4,
    borderRadius: 4,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  placeholderContainer: {
    width: 16,
    marginRight: 4,
  },
});

const TabBarOption = memo(({ title, count, isActive, onPress, isLoading }) => (
  <TouchableItem style={[s.buttonContainer, isActive && s.activeButtonContainer]} onPress={onPress}>
    <Fragment>
      {isLoading ? (
        <View style={s.placeholderContainer}>
          <Placeholder Animation={Fade}>
            <PlaceholderLine height={16} noMargin />
          </Placeholder>
        </View>
      ) : (
        <View style={s.amountContainer}>
          <Text xsmall semiBold color={isActive ? colors.white : colors.text}>
            {count}
          </Text>
        </View>
      )}

      <Text semiBold xsmall color={isActive ? colors.white : colors.gray}>
        {title}
      </Text>
    </Fragment>
  </TouchableItem>
));

const CurrentUserItemsTabBar = memo(
  ({
     activeTabIndex,
     onTabPress,
     showPurchasedItems,
     activeItemsTotalAmount,
     soldItemsTotalAmount,
     wishesTotalAmount,
     purchasedItemsTotalAmount,
     isActiveItemsLoading,
     isWishesLoading,
     isSoldItemsLoading,
     isPurchasedItemsLoading,
   }) => {
    const options = [
      {
        index: 0,
        count: activeItemsTotalAmount,
        title: strings.profile.for_sale,
        isLoading: isActiveItemsLoading,
      },
      {
        index: 1,
        count: soldItemsTotalAmount,
        title: strings.profile.sold,
        isLoading: isSoldItemsLoading,
      },
      {
        index: 2,
        count: wishesTotalAmount,
        title: strings.profile.wishes,
        isLoading: isWishesLoading,
      },
    ];

    if (showPurchasedItems) {
      options.push({
        index: 3,
        count: purchasedItemsTotalAmount,
        title: strings.profile.purchased,
        isLoading: isPurchasedItemsLoading,
      });
    }

    return (
      <View style={rs.row}>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={s.listContentContainer}
          style={s.list}
        >
          {options.map(({ index, title, count, isLoading }) => {
            const isActive = activeTabIndex === index;
            const onPress = () => {
              onTabPress(index);
            };

            return (
              <TabBarOption
                key={index}
                title={title}
                count={count}
                isActive={isActive}
                onPress={onPress}
                isLoading={isLoading}
              />
            );
          })}
        </ScrollView>
      </View>
    );
  },
);

CurrentUserItemsTabBar.propTypes = {};

export default CurrentUserItemsTabBar;
