import React, { Fragment, memo } from 'react';
import T from 'prop-types';
import styled from 'styled-components/native';
import memberStatuses from '../../../constants/memberStatuses';
import UserAvatar from '../UserAvatar';
import { colors, fontSizes, dimensions, reusableStyles } from '../../../styles';
import Text from '../Text';
import AutoWidthButton from '../Buttons/AutoWidthButton';
import strings from '../../../localization';
import IconButton from '../Buttons/IconButton';

const MainContainer = styled.View`
  flex-direction: row;
  align-items: center;
  height: 64px;
  padding-right: ${dimensions.medium};
`;

const InfoView = styled.View`
  justify-content: center;
`;

const DetailContainer = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MemberName = styled(Text)`
  font-size: ${fontSizes.medium};
`;

const AnswersIcon = styled(IconButton)`
  margin-right: ${dimensions.halfMedium};
`;

const MemberStatus = styled(Text)`
  font-size: ${fontSizes.xsmall};
  color: ${(props) => (props.color ? props.color : colors.GreenLight)};
  margin-top: 4px;
`;

const ButtonsContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

const waiting_approval = 'waiting_approval';

const MemberItem = memo(({ member, groupHasQuestions, onDecline, onApprove, onOpenAnswers }) => {
  const { status, user_name, profile_image, user_id } = member;

  return (
    <MainContainer>
      <Fragment>
        <UserAvatar uri={profile_image} />

        <DetailContainer>
          <InfoView>
            <MemberName>{user_name}</MemberName>
            <MemberStatus color={colors.gray}>{memberStatuses[status]}</MemberStatus>
          </InfoView>
        </DetailContainer>

        <ButtonsContainer>
          <Fragment>
            {groupHasQuestions && (
              <AnswersIcon
                name="rules"
                color={colors.gray}
                size={20}
                onPress={() => onOpenAnswers(member)}
              />
            )}
            {status === waiting_approval && (
              <AutoWidthButton
                color={colors.red}
                withMarginRight
                withSmallPadding
                title={strings.members.decline}
                onPress={() => onDecline(user_id)}
              />
            )}
            <AutoWidthButton
              color={colors.green}
              withSmallPadding
              title={strings.members.approve}
              onPress={() => onApprove(user_id)}
            />
          </Fragment>
        </ButtonsContainer>
      </Fragment>
    </MainContainer>
  );
});

MemberItem.propTypes = {
  member: T.object,
  groupHasQuestions: T.bool,
  onDecline: T.func,
  onApprove: T.func,
  onOpenAnswers: T.func,
};

export default MemberItem;
