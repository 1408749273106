import { colors, dimensions } from '../../../../../styles';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  topContainer: {
    alignItems: 'center',
    paddingVertical: dimensions.doubleMedium,
  },
  itemImage: {
    height: 120,
    width: 120,
    borderWidth: 1,
    borderColor: colors.gray,
    borderRadius: 5,
  },
  sectionTitleContainer: {
    width: '100%',
    paddingTop: 26,
    paddingBottom: 20,
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.grayLighter,
  },
  infoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: dimensions.medium,
  },
});
