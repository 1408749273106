import React, { Fragment, useState, useEffect } from 'react';
import { Animated, View, StatusBar, Image, ScrollView } from 'react-native';
import * as R from 'ramda';
import T from 'prop-types';
import { connect } from 'react-redux';
import { NavigationService } from '../../../../../../services';
import screens from '../../../../../../navigation/screens';
import { dayAndTime } from '../../../../../../utils/dateHelper';
import { numberWithCommas } from '../../../../../../utils/stringHelper';
import { colors, dimensions, rs } from '../../../../../../styles';
import { isRTL } from '../../../../../../utils/rtlHelper';
import { getNearestBuyerLocation } from '../../../../../../utils/distance';
import s, {
  Container,
  DateText,
  PriceContainer,
  PriceText,
  OldPriceText,
  InfoContainer,
  Subtitle,
  NameContainer,
  HeaderContainer,
  AnimatedItemImageContainer,
  LotName,
  InfoIcon,
  InfoSubtitle,
  AnimatedList,
  DiscountContainer,
  SaleContainer,
  DiscountIcon,
  HeartIcon,
  FavoriteText,
  FavoriteContainer,
  OpacityView,
  SoldContainer,
  WishFulfillmentContainer,
} from './styles';
import {
  Text,
  Separator,
  TokenIcon,
  OpacityHeader,
  LotImageSwiper,
  FontIcon,
  UserInfo,
  TouchableItem,
  FavoriteIcon,
  IconButton,
  HorizontalItemsList,
  SectionTitle,
  AngleIcon,
} from '../../../../../ReusableComponents';
import strings from '../../../../../../localization';
import ShippingOptions from './components/ShippingOptions';
import { groupsSelectors } from '../../../../../../store/groups';
import { isIos, isWeb } from '../../../../../../utils/detectDevice';
import zoneProperties from '../../../../../../constants/zoneProperties';
import LinearGradient from 'react-native-linear-gradient';
import itemStatuses from '../../../../../../constants/itemStatuses';
import { getCurrencyCode } from '../../../../../../store/communityInfo/selectors';
import currencyCodesMap from '../../../../../../constants/currencyCodes';

const IMAGE_HEIGHT = dimensions.width;

const getMapLocations = ({ itemLocations, nearestBuyerLocation, isCurrentUser }) => {
  const locationsWithBuyer =
    R.isEmpty(nearestBuyerLocation) || isCurrentUser
      ? itemLocations
      : itemLocations.map((location) => {
          if (location.id === nearestBuyerLocation.parentId) {
            return {
              ...location,
              nearestBuyerLocation: {
                ...nearestBuyerLocation.coords,
                distance: nearestBuyerLocation.distance,
              },
            };
          }

          return location;
        });

  return R.sortWith([R.descend(R.has('nearestBuyerLocation'))], locationsWithBuyer);
};

const ItemDetailsMainView = ({
  isWish,
  scrollY,
  item,
  groupInfo,
  itemCommunityInfo,
  onOptionsButtonPress,
  isCurrentUser,
  isAdmin,
  onAddItemToFavorites,
  onAddItemToTop,
  isUnavailableDiscount,
  onDiscountPress,
  hideBottomBar,
  hideFavorites,
  isSoldItem,
  wishTitle,
  isVisibleWishInfo,
  userLocations,
  isSearchByPrimaryLocation,
  marketplaceCoordinates,
  geolocationCoordinates,
  previousScreenName,
  onItemShare,
  currencyCode,
}) => {
  const [selectedLocation, setSelectedLocation] = useState({});
  const [isReadyMap, setReadyMap] = useState(false);

  useEffect(() => {
    setTimeout(() => setReadyMap(true), 700);
  }, []);

  useEffect(() => {
    if (item.locations && item.shipper_locations) {
      const locationsWithShippers = [...item.locations, ...item.shipper_locations];

      setSelectedLocation(locationsWithShippers[0]);
    }
  }, [item.locations, item.shipper_locations]);

  const {
    mark_as_favorite,
    is_top_item,
    discounted_price,
    discount_rate,
    title,
    parent_category_name,
    category_name,
    created_at,
    updated_at,
    sold_at,
    purchased_at,
    price,
    condition_title,
    refund_policy_title,
    brand_name,
    size_id,
    size_title,
    original_price,
    description,
    is_boxit_active,
    shipping_price,
    seller_image,
    seller_name,
    status,
    seller_id,
    category_id,
    has_multiple_supply,

    item_image, // primary image
    images, // array of images from getItemDetails request
    locations = [], // array of locations from getItemDetails request
    shipper_locations = [],

    wisher_id,
    wisher_image,
    wisher_name,
    pay_for_shipping,
    pay_for_boxit,

    wish_image,

    wish_id, // when user was fulfilled wish

    // only for purchased or sold items
    buyer_id,
    buyer_name,
    buyer_image,
    more_items_from_seller,
    similar_items,
    is_shippers_delivery_active,
  } = item;

  const isActive = status === itemStatuses.ACTIVE;

  const { group_name } = groupInfo;

  const category = parent_category_name
    ? `${parent_category_name}\n${category_name}`
    : category_id
    ? `${category_name}`
    : `No Category`;

  const isVisibleBuyerInfo = !!buyer_id && isCurrentUser;

  let date = '';

  // todo handle date!!!!!!!!!!!!!!
  if (sold_at) {
    date = `${strings.main.sold}: ${dayAndTime(sold_at)}`;
  }
  // else if (purchased_at) {
  //   date = `${strings.main.purchased}: ${calendar(purchased_at)}`;
  // }
  else if (!isActive && updated_at) {
    date = `${strings.lots.updated}: ${dayAndTime(updated_at)}`;
  } else {
    date = `${strings.lots.published}: ${dayAndTime(created_at)}`;
  }

  const imagePreview = isWish ? wish_image : item_image;

  const currencySymbol = R.pathOr(
    zoneProperties.israel.currencySymbol,
    ['itemCommunityInfo', 'zone_name', 'currencySymbol'],
    zoneProperties,
  );

  const itemLocations = is_shippers_delivery_active
    ? [...locations, ...shipper_locations]
    : locations;

  const availableShippingOptions = [
    { index: 'personal', icon: 'Shipping_personal-pickup', visible: !R.isEmpty(locations) },
    { index: 'shippers', icon: 'scooter', visible: is_shippers_delivery_active },
    { index: 'domestic', icon: 'Shipping_domestic-shipping', visible: !!shipping_price },
  ].filter(({ visible }) => visible);

  let nearestBuyerLocation = null;

  if (marketplaceCoordinates && previousScreenName === screens.Marketplace) {
    nearestBuyerLocation = getNearestBuyerLocation([marketplaceCoordinates], itemLocations);
  } else if (isSearchByPrimaryLocation && previousScreenName === screens.Marketplace) {
    nearestBuyerLocation = getNearestBuyerLocation(
      userLocations.filter((location) => location.is_primary),
      itemLocations,
    );
  } else if (geolocationCoordinates.longitude) {
    nearestBuyerLocation = getNearestBuyerLocation(
      [...userLocations, geolocationCoordinates],
      itemLocations,
    );
  } else {
    nearestBuyerLocation = getNearestBuyerLocation(userLocations, itemLocations);
  }

  const hasPickupDistance = R.is(Number, nearestBuyerLocation.distance);
  const hasShippingOptions = !R.isEmpty(availableShippingOptions);
  const isDistanceContainerDisabled = R.isEmpty(locations) && !is_shippers_delivery_active;

  const mapLocations = getMapLocations({
    itemLocations,
    isCurrentUser,
    nearestBuyerLocation: nearestBuyerLocation,
  });

  const onPressSeeAllItemsFromSeller = () => {
    NavigationService.navigate(screens.UserProfile, {
      name: seller_name,
      userId: seller_id,
      profile_image: seller_image,
    });
  };

  const onPressShippingInfo = () => {
    NavigationService.navigate(screens.MapWithUserLocations, {
      userLocations: mapLocations,
      buyerLocations: isCurrentUser ? [] : userLocations,
    });
  };
  const Wrapper = isWeb ? ScrollView : Fragment;

  return (
    <Wrapper contentContainerStyle={isWeb && rs.webContainer}>
      <StatusBar barStyle="light-content" />
      <OpacityHeader
        scrollY={scrollY}
        isShowOptionsButton={!!onOptionsButtonPress}
        onOptionsButtonPress={onOptionsButtonPress}
      />

      <AnimatedList
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
          useNativeDriver: true,
        })}
        scrollEventThrottle={8}
      >
        <AnimatedItemImageContainer
          style={{
            transform: [
              {
                translateY: scrollY.interpolate({
                  inputRange: [-IMAGE_HEIGHT, 0, IMAGE_HEIGHT],
                  outputRange: [-IMAGE_HEIGHT / 2, 0, IMAGE_HEIGHT / 2],
                  extrapolateRight: 'clamp',
                }),
              },
              {
                scale: scrollY.interpolate({
                  inputRange: [-IMAGE_HEIGHT, 0],
                  outputRange: [2, 1],
                  extrapolateRight: 'clamp',
                }),
              },
            ],
          }}
        >
          <LotImageSwiper images={images || []} imagePreview={imagePreview} />
        </AnimatedItemImageContainer>

        <View style={rs.backgroundWhite}>
          {!!discounted_price && !isSoldItem && (
            <SaleContainer
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              colors={['#A44AFE', '#39BDDC']}
            >
              <Text color={colors.white} semiBold large>
                -{discount_rate}%
              </Text>
            </SaleContainer>
          )}
          {isSoldItem && (
            <SoldContainer>
              <Text color={colors.white} semiBold large>
                {strings.items.sold_cc}
              </Text>
            </SoldContainer>
          )}

          {!isWish && (
            <Fragment>
              <View style={s.favoriteContainer}>
                <View style={s.priceContainer}>
                  {!!price && (
                    <Fragment>
                      <View style={[rs.row, rs.alignCenter]}>
                        <Image
                          style={s.priceImage}
                          source={{ uri: 'price' }}
                          resizeMode="contain"
                        />

                        <Text large bold color={colors.secondary} style={rs.smallPaddingHorizontal}>
                          {numberWithCommas(discounted_price || price)}
                        </Text>
                      </View>
                      {discounted_price && (
                        <Text medium style={s.oldPriceText} color={colors.gray}>
                          {numberWithCommas(price)}
                        </Text>
                      )}
                    </Fragment>
                  )}
                </View>

                <View style={[rs.row, rs.alignCenter]}>
                  {has_multiple_supply && (
                    <FontIcon
                      name="multiple-supply"
                      size={24}
                      color={colors.secondary}
                      containerStyle={rs.marginLeft}
                      withoutRTLScale
                    />
                  )}

                  {isActive && isAdmin && !!onAddItemToTop && (
                    <IconButton
                      isMaterialCommunityIcon
                      size={24}
                      color={colors.secondary}
                      name={is_top_item ? 'star-face' : 'star-outline'}
                      isFavorite={is_top_item}
                      onPress={onAddItemToTop}
                      containerStyle={rs.marginLeft}
                    />
                  )}

                  {isActive && !hideFavorites && !isCurrentUser && (
                    <TouchableItem onPress={onAddItemToFavorites} style={rs.marginLeft}>
                      <FavoriteIcon isFavorite={mark_as_favorite} size={24} />
                    </TouchableItem>
                  )}

                  <TouchableItem onPress={onItemShare} style={rs.marginLeft}>
                    <FontIcon name="share-2" size={20} color={colors.secondary} />
                  </TouchableItem>
                </View>
              </View>

              <View style={rs.paddingHorizontal}>
                <Separator />
              </View>
            </Fragment>
          )}

          {hasPickupDistance || hasShippingOptions ? (
            <View style={s.titleWithoutShadow}>
              <Text numberOfLines={2} medium semiBold>
                {title}
              </Text>
              <Text color={colors.grayBorder} style={rs.smallMarginTop}>
                {date}
              </Text>
            </View>
          ) : (
            <View style={s.shadowContainer}>
              <View style={s.titleContainer}>
                <Text numberOfLines={2} medium semiBold>
                  {title}
                </Text>
                <Text color={colors.grayBorder} style={rs.smallMarginTop}>
                  {date}
                </Text>
              </View>
            </View>
          )}

          {(hasPickupDistance || hasShippingOptions) && (
            <Fragment>
              <View style={rs.paddingHorizontal}>
                <Separator />
              </View>

              <View style={s.shadowContainer}>
                <TouchableItem
                  onPress={onPressShippingInfo}
                  disabled={isDistanceContainerDisabled}
                  style={s.shippingInfoContainer}
                >
                  {hasPickupDistance && !isCurrentUser && (
                    <View style={[rs.row, rs.marginBottom]}>
                      <Text color={colors.gray}>{strings.shipping.pickup_distance}</Text>
                      <Text color={colors.blueLight} style={s.shippingDistance} semiBold>
                        {nearestBuyerLocation.distance === 0
                          ? strings.shipping.right_next_to_you
                          : `${nearestBuyerLocation.distance}km`}
                      </Text>
                    </View>
                  )}
                  {hasShippingOptions && (
                    <View style={[rs.row, rs.alignCenter]}>
                      <Text color={colors.gray}>{strings.shipping.shipping_options}</Text>

                      <Fragment>
                        {availableShippingOptions.map(({ index, icon }) => {
                          return (
                            <FontIcon
                              withoutRTLScale
                              key={index}
                              name={icon}
                              size={20}
                              color={colors.secondary}
                              style={{ marginLeft: dimensions.medium }}
                            />
                          );
                        })}
                      </Fragment>
                    </View>
                  )}
                  {!isDistanceContainerDisabled && <AngleIcon />}
                </TouchableItem>
              </View>
            </Fragment>
          )}

          <View style={s.sectionTitleContainer}>
            <Text style={s.sectionTitleText} medium semiBold>
              {isWish ? strings.lots.wish_description : strings.lots.description}
            </Text>
          </View>

          <View style={[rs.paddingHorizontal, rs.paddingVertical]}>
            <Text>{description}</Text>
          </View>

          {isVisibleBuyerInfo && (
            <Fragment>
              <View style={s.sectionTitleContainer}>
                <Text style={s.sectionTitleText} medium semiBold>
                  {strings.lots.purchased_by}
                </Text>
              </View>
              <Container>
                <UserInfo
                  image={buyer_image}
                  name={buyer_name}
                  id={buyer_id}
                  // bottomInfo={`${strings.main.purchased}: ${calendar(sold_at)}`}
                />
              </Container>
            </Fragment>
          )}

          {isVisibleWishInfo && (
            <Fragment>
              <View style={s.sectionTitleContainer}>
                <Text style={s.sectionTitleText} medium semiBold>
                  {strings.lots.wish_fulfillment_for}
                </Text>
              </View>
              <WishFulfillmentContainer>
                <FontIcon name="magic-wand" size={20} color={colors.gray} />
                <Text medium style={rs.marginHorizontal}>
                  <Text medium semiBold>
                    {wishTitle}
                  </Text>
                </Text>
              </WishFulfillmentContainer>
            </Fragment>
          )}

          {!isWish && (
            <View style={s.sectionTitleContainer}>
              <Text style={s.sectionTitleText} medium semiBold>
                {strings.lots.item_details}
              </Text>
            </View>
          )}

          <Container>
            <InfoContainer>
              <InfoIcon name="New-item_category" />
              <InfoSubtitle>{strings.lots.group}</InfoSubtitle>
              <Text color={colors.gray}>{group_name}</Text>
            </InfoContainer>
            <InfoContainer>
              <InfoIcon name="Subcategory" />
              <InfoSubtitle>{strings.lots.category}</InfoSubtitle>
              <Text color={colors.gray}>{category}</Text>
            </InfoContainer>
            {!!condition_title && (
              <InfoContainer>
                <InfoIcon name="New-item_condition" />
                <InfoSubtitle>{strings.lots.condition}</InfoSubtitle>
                <Text color={colors.gray}>{condition_title}</Text>
              </InfoContainer>
            )}
            {brand_name && (
              <InfoContainer>
                <InfoIcon name="Brand" />
                <InfoSubtitle>{strings.lots.brand}</InfoSubtitle>
                <Text color={colors.gray}>{brand_name}</Text>
              </InfoContainer>
            )}
            {!!size_id && (
              <InfoContainer>
                <InfoIcon name="New-item_size" />
                <InfoSubtitle>{strings.lots.size}</InfoSubtitle>
                <Text color={colors.gray}>{size_title}</Text>
              </InfoContainer>
            )}
            {!!original_price && (
              <InfoContainer>
                <InfoIcon name="New-item_Original-Price-1" />

                <InfoSubtitle>
                  {strings.lots.original_price_without_code}
                </InfoSubtitle>
                {/* FUCK THIS BULLSHIT */}
                {itemCommunityInfo.zone_name === 'israel' ? (
                  <Fragment>
                    {!isRTL && (
                      <Text color={colors.gray}>
                        {currencyCodesMap[currencyCode]} {original_price}
                      </Text>
                    )}
                    {isRTL && (
                      <Text color={colors.gray}>
                        {original_price} {currencyCodesMap[currencyCode]}
                      </Text>
                    )}
                  </Fragment>
                ) : (
                  <Text color={colors.gray}>
                    {currencyCodesMap[currencyCode]} {original_price}
                  </Text>
                )}
              </InfoContainer>
            )}
            {!!refund_policy_title && (
              <InfoContainer>
                <InfoIcon name="refund_policy" />
                <InfoSubtitle>{strings.lots.refund_policy}</InfoSubtitle>
                <Text color={colors.gray}>{refund_policy_title}</Text>
              </InfoContainer>
            )}
          </Container>

          {!isWish && !isSoldItem && (
            <Fragment>
              <View style={s.sectionTitleContainer}>
                <Text style={s.sectionTitleText} medium semiBold>
                  {strings.lots.shipping}
                </Text>
              </View>
              <ShippingOptions
                isWish={isWish}
                zoneName={itemCommunityInfo.zone_name}
                isActiveShipping={!!shipping_price || pay_for_shipping}
                shippingPrice={shipping_price}
                isShippersDeliveryActive={is_shippers_delivery_active}
                isActiveBoxit={is_boxit_active || pay_for_boxit}
                locations={locations}
                shipperLocations={shipper_locations}
                mapLocations={mapLocations}
                isReadyMap={isReadyMap}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                onPressShippingInfo={onPressShippingInfo}
                currencyCode={currencyCode}
              />
            </Fragment>
          )}

          {!!more_items_from_seller && !R.isEmpty(more_items_from_seller) && (
            <Fragment>
              <SectionTitle
                title={strings.lots.more_items_from_seller}
                onSeeAllPress={onPressSeeAllItemsFromSeller}
                small
              />
              <HorizontalItemsList items={more_items_from_seller} showFavoriteIcon={false} />
            </Fragment>
          )}

          {!!similar_items && !R.isEmpty(similar_items) && (
            <Fragment>
              <SectionTitle title={strings.lots.similar_items} small />
              <HorizontalItemsList items={similar_items} showFavoriteIcon={false} />
            </Fragment>
          )}
        </View>

        <View style={{ height: 80 }} />
      </AnimatedList>
    </Wrapper>
  );
};

ItemDetailsMainView.propTypes = {
  images: T.array,
  locations: T.array,
  scrollY: T.any,
  item: T.shape({
    mark_as_favorite: T.bool,
    discounted_price: T.number,
    title: T.string,
    parent_category_name: T.string,
    category_name: T.string,
    created_at: T.string,
    price: T.number,
    item_condition: T.number,
    brand_name: T.string,
    size: T.string,
    size_title: T.string,
    original_price: T.number,
    description: T.string,
    is_shippers_delivery_active: T.bool,
    shipping_price: T.number,
    seller_image: T.string,
    seller_name: T.string,
    status: T.string,
    seller_id: T.string,
    has_multiple_supply: T.bool,
    more_items_from_seller: T.arrayOf(T.shape({})),
    similar_items: T.arrayOf(T.shape({})),
    shipper_locations: T.arrayOf(T.shape({})),
  }),
  userLocations: T.arrayOf(T.shape({})),
  onOptionsButtonPress: T.func,
  isCurrentUser: T.bool,
  onAddItemToFavorites: T.func,
  isUnavailableDiscount: T.bool,
  onDiscountPress: T.func,
  hideBottomBar: T.bool,
  hideFavorites: T.bool,
  isSoldItem: T.bool,
};

ItemDetailsMainView.defaultProps = {
  userLocations: [],
};

export default connect((state, { item }) => ({
  groupInfo: groupsSelectors.getGroupInfoById(state, R.prop('group_id', item)),
  itemCommunityInfo: groupsSelectors.getCommunityInfoByCommunityId(
    state,
    R.prop('group_id', item),
  ),
  geolocationCoordinates: state.app.geolocationCoordinates,
  isSearchByPrimaryLocation: state.lots.feedLotFilters.isSearchByPrimaryLocation,
  marketplaceCoordinates: state.lots.feedLotFilters.coordinates,
  currencyCode: getCurrencyCode(state),
}))(ItemDetailsMainView);
