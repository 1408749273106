import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import Animated, { Extrapolate, interpolateNode } from 'react-native-reanimated';

export function AnimatedSection({ children, onLayout, animatedHeight, style }) {
  const animatedOpacity = useMemo(
    () =>
      interpolateNode(animatedHeight, {
        inputRange: [0, 40],
        outputRange: [0, 1],
        extrapolate: Extrapolate.CLAMP,
      }),
    [animatedHeight],
  );
  return (
    <Animated.View
      style={[{ height: animatedHeight, opacity: animatedOpacity }, styles.overflowHidden]}
    >
      <Animated.View onLayout={onLayout} style={[styles.container, style]}>
        {children}
      </Animated.View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  overflowHidden: {
    overflow: 'hidden',
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});
