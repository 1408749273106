import React from 'react';
import { connect } from 'react-redux';
import { View, EmptyList, Text, TouchableItem } from '../../../ReusableComponents/index';
import { colors, rs } from '../../../../styles';
import strings from '../../../../localization/index';
import { groupsSelectors } from '../../../../store/groups';
import { NavigationService } from '../../../../services';
import { SectionList } from 'react-native';
import s from './styles';
import FontIcon from '../../../ReusableComponents/FontIcon';

const CategoryItem = ({ category, parentCategory, isSelected, onSelectCategory }) => (
  <TouchableItem
    style={[s.itemContainer, isSelected && s.activeItemContainer]}
    useOpacity={false}
    onPress={() => {
      onSelectCategory(category);
      onSelectCategory({
        childCategory: {
          ...category,
          id: category.id,
        },
        parentCategory,
      });
      NavigationService.pop();
    }}
  >
    <Text medium style={rs.verySmallMarginBottom} color={colors.gray}>
      {category.name}
    </Text>
  </TouchableItem>
);

const SectionHeader = ({ parentCategory }) => (
  <View style={s.sectionContainer}>
    <View style={s.iconContainer}>
      <FontIcon name={parentCategory.icon_name} size={25} color={colors.secondary} />
    </View>

    <Text medium semiBold style={rs.verySmallMarginBottom}>
      {parentCategory.name}
    </Text>
  </View>
);

const Categories = ({ categoriesSectionList, route }) => {
  const selectedCategoryId = route.params.selectedCategoryId;
  const onSelectCategory = route.params.onSelectCategory;

  return (
    <SectionList
      sections={categoriesSectionList}
      keyExtractor={(item) => item.id}
      initialNumToRender={20}
      style={rs.backgroundWhite}
      keyboardShouldPersistTaps="handled"
      stickySectionHeadersEnabled={false}
      renderSectionHeader={({ section: { parentCategory } }) => (
        <SectionHeader parentCategory={parentCategory} />
      )}
      ListEmptyComponent={<EmptyList text={strings.emptyLists.emptyContactsList} />}
      renderItem={({ item, section }) => (
        <CategoryItem
          category={item}
          parentCategory={section.parentCategory}
          isSelected={item.id === selectedCategoryId}
          onSelectCategory={onSelectCategory}
        />
      )}
    />
  );
};

export default connect((state, { route }) => ({
  categoriesSectionList: groupsSelectors.getSectionListByCategories(route.params.categories),
}))(Categories);
