const REPORT = 'REPORT';
const ERROR = 'ERROR';
const REMOVE_ITEM_ERROR = 'REMOVE_ITEM_ERROR';
const EDIT_ITEM_ERROR = 'EDIT_ITEM_ERROR';
const SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG';
const UNBLOCK_USER = 'UNBLOCK_USER';
const REWARD = 'REWARD';
const WELCOME = 'WELCOME';
const RULES = 'RULES';
const REFUND = 'REFUND';
const REPOST = 'REPOST';
const WEB_VIEW = 'WEB_VIEW';
const DISCOUNT = 'DISCOUNT';
const JOIN_COMMUNITY = 'JOIN_COMMUNITY';
const USER_IS_NOT_MEMBER = 'USER_IS_NOT_MEMBER';
const JOIN_ANY_COMMUNITY = 'JOIN_ANY_COMMUNITY';
const TOKENS_EXCHANGE_PROMPT = 'TOKENS_EXCHANGE_PROMPT';
const VERIFY_EXCHANGE = 'VERIFY_EXCHANGE';
const NO_INTERNET_CONNECTION = 'NO_INTERNET_CONNECTION';
const LEAVE_COMMUNITY = 'LEAVE_COMMUNITY';
const USER_LEFT_COMMUNITY = 'USER_LEFT_COMMUNITY';
const INFO = 'INFO';
const CREATE_LOT = 'CREATE_LOT';
const NO_SHARED_COMMUNITIES_WITH_USER = 'NO_SHARED_COMMUNITIES_WITH_USER';
const ITEM_WAS_UPLOADED = 'ITEM_WAS_UPLOADED';
const ASKED_TO_BUY = 'ASKED_TO_BUY';
const PROMO_CODE_ACTIVATED = 'PROMO_CODE_ACTIVATED';
const MISSING_TOKENS = 'MISSING_TOKENS';
const ITEM_REVIEW = 'ITEM_REVIEW';
const ADD_LOCATION = 'ADD_LOCATION';
const ACHIEVED_PERSONAL_REWARD = 'ACHIEVED_PERSONAL_REWARD';
const LEGAL_INFO = 'LEGAL_INFO';
const IN_APP_SHARING = 'IN_APP_SHARING';
const PAYMENT_PLAN_COMING_SOON = 'PAYMENT_PLAN_COMING_SOON';
const DONATION_SUCCESS = 'DONATION_SUCCESS';
const CONTACT_LIST = 'CONTACT_LIST';
const CONTACT_DETAILS = 'CONTACT_DETAILS';
const ENDORSE_USER = 'ENDORSE_USER';
const PROMOTION = 'PROMOTION';

export default {
  REPORT,
  ERROR,
  REMOVE_ITEM_ERROR,
  EDIT_ITEM_ERROR,
  SOMETHING_WENT_WRONG,
  UNBLOCK_USER,
  REWARD,
  WELCOME,
  RULES,
  REFUND,
  REPOST,
  WEB_VIEW,
  DISCOUNT,
  JOIN_COMMUNITY,
  USER_IS_NOT_MEMBER,
  JOIN_ANY_COMMUNITY,
  TOKENS_EXCHANGE_PROMPT,
  VERIFY_EXCHANGE,
  NO_INTERNET_CONNECTION,
  LEAVE_COMMUNITY,
  USER_LEFT_COMMUNITY,
  INFO,
  CREATE_LOT,
  NO_SHARED_COMMUNITIES_WITH_USER,
  ITEM_WAS_UPLOADED,
  ASKED_TO_BUY,
  PROMO_CODE_ACTIVATED,
  MISSING_TOKENS,
  ITEM_REVIEW,
  ADD_LOCATION,
  ACHIEVED_PERSONAL_REWARD,
  LEGAL_INFO,
  IN_APP_SHARING,
  PAYMENT_PLAN_COMING_SOON,
  DONATION_SUCCESS,
  CONTACT_LIST,
  CONTACT_DETAILS,
  ENDORSE_USER,
  PROMOTION,
};
