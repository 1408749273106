import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import Button from '../../Buttons/Button';
import Text from '../../Text';
import { dimensions, fontSizes, colors } from '../../../../styles';
import strings from '../../../../localization';

const AdminAvatar = styled.Image`
  height: 68px;
  width: 68px;
  margin-top: -34px;
`;

const ContentContainer = styled.View`
  align-items: center;
`;

const MainContainer = styled.View`
  padding-vertical: ${dimensions.doubleMedium};
  padding-horizontal: ${dimensions.doubleMedium};
  width: ${dimensions.width - 3 * dimensions.medium};
`;

const ButtonContainer = styled.View`
  width: 100%;
  height: 40px;
  margin-top: 40px;
`;

const Title = styled(Text)`
  font-size: 26px;
  margin-bottom: 30px;
  text-align: center;
  color: ${colors.blueLighter};
`;

const Description = styled(Text)`
  font-size: ${fontSizes.large};
  text-align: center;
`;

const Reward = ({ onPress, params }) => (
  <ContentContainer>
    <AdminAvatar source={{ uri: 'round_logo' }} />
    <MainContainer>
      <Title bold>{params.title}</Title>
      {/* <Description bold>{params.description}</Description> */}

      <Description>{strings.modals.reward_text.replace('XX', params.amount)}</Description>

      <ButtonContainer>
        <Button title={strings.common.ok} onPress={onPress} />
      </ButtonContainer>
    </MainContainer>
  </ContentContainer>
);

export default Reward;
