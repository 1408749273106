import React, { memo } from 'react';
import { View, ScrollView } from 'react-native';
import s from './styles';

const FilterHorizontalList = memo(({ style, contentContainerStyle, children, forwardRef }) => {
  return (
    <ScrollView
      ref={forwardRef}
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={[s.listContentContainer, contentContainerStyle]}
      style={[s.list, style]}
    >
      {children}
    </ScrollView>
  );
});

export default FilterHorizontalList;
