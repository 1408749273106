import { StyleSheet } from 'react-native';
import { dimensions } from '../../../styles';

export default StyleSheet.create({
  contentContainer: {
    height: 54,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: dimensions.medium,
  },
  resultContainer: {
    flex: 1,
    marginHorizontal: dimensions.medium,
  },
});
