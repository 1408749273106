import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer, createTransform, createMigrate } from 'redux-persist';
import AsyncStorage from '@react-native-community/async-storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import * as R from 'ramda';
import rootReducer from './rootReducer';
import { getSubPaths } from '../utils/persistHelper';
import guideTypes from '../constants/guidelTypes';

const blacklistPaths = [
  // 'communityCities.insideCommunityId'
];

const migrations = {
  3: (state) => ({
    ...state,
    guides: null,
  }),
  4: (state) => ({
    ...state,
    guides: {
      showAgain: {
        ...state.guides.showAgain,
        [guideTypes.MARKETPLACE_LOCATION_PERMISSION]: true,
      },
    },
  }),
};

const persistConfig = {
  key: 'root',
  version: 4,
  whitelist: [
    'auth',
    'app',
    'shipping',
    'userLocations',
    'userInfo',
    'groups',
    'wallets',
    // 'offers',
    'guides',
    'communityInfo',
    'home',
    'createLot',
  ],
  storage: AsyncStorage,
  stateReconciler: autoMergeLevel2,
  transforms: [
    // nested blacklist-paths require a custom transform to be applied
    createTransform(
      (inboundState, key) => R.omit(getSubPaths(blacklistPaths, key), inboundState),
      null,
    ),
  ],
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk];

if (__DEV__) {
  const createDebugger = require('redux-flipper').default;
  middlewares.push(createDebugger());

  const createFlipperMiddleware = require('rn-redux-middleware-flipper').default;
  middlewares.push(createFlipperMiddleware());
}

const configureStore = () => {
  const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(...middlewares)));

  const persistor = persistStore(store);
  // persistor.purge();
  return { persistor, store };
};

export const { persistor, store } = configureStore();
