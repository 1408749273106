import React from 'react';
import { View } from 'react-native';
import Text from '../Text';
import { colors, rs } from '../../../styles';
import strings from '../../../localization';
import TokenIcon from '../TokenIcon';
import Icon from '../Icon';
import TouchableItem from '../TouchableItem';
import s from './styles';

const TokenBanner = ({ onClose }) => (
  <View style={s.contentContainer}>
    <TokenIcon isBig />
    <View style={s.textContainer}>
      <Text medium semiBold style={[s.text, rs.paddingHorizontal]} color={colors.gray}>
        {strings.home.token_banner}{' '}
        <Text color={colors.violet} medium semiBold style={s.text}>
          {strings.home.token_banner_blue_text}
        </Text>
      </Text>
    </View>

    {!!onClose && (
      <TouchableItem onPress={onClose} style={s.closeIconButton}>
        <Icon name={'x'} size={16} color={colors.grayBorder} />
      </TouchableItem>
    )}
  </View>
);

export default TokenBanner;
