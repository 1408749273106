import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RefreshControl, FlatList } from 'react-native';
import { headerStyle, colors, rs } from '../../../../styles';
import { View, Container, WalletItem } from '../../../ReusableComponents';
import strings from '../../../../localization';
import { getWallets } from '../../../../store/wallets/selectors';
import s from './styles';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import { walletsOperations } from '../../../../store/wallets';

const Wallets = ({ wallets, getWalletsList }) => {
  const [isRefreshing, setRefreshing] = useState(false);

  const onOpenTransactions = communityId => {
    NavigationService.navigate(screens.Transactions, { communityId });
  };

  const onRefresh = async () => {
    setRefreshing(true);
    await getWalletsList();
    setRefreshing(false);
  };

  return (
    <Container style={{ backgroundColor: colors.grayLighter }}>
      <FlatList
        style={s.contentContainer}
        data={wallets}
        keyExtractor={item => item.id.toString()}
        renderItem={({ item }) => (
          <View style={[rs.smallPaddingHorizontal, rs.smallPaddingTop]}>
            <WalletItem wallet={item} onOpenTransactions={onOpenTransactions} />
          </View>
        )}
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
        refreshing={isRefreshing}
      />
    </Container>
  );
};

Wallets.navigationOptions = () => ({
  ...headerStyle,
  title: strings.buy_sell.wallets,
  headerRight: <View />,
});

export default connect(
  state => ({
    wallets: [],
  }),
  dispatch => ({
    getWalletsList: () => dispatch(walletsOperations.getWallets()),
  }),
)(Wallets);
