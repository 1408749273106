import React, { Fragment, memo } from 'react';
import styled from 'styled-components/native';
import T from 'prop-types';
import { Text } from '../../../../ReusableComponents';
import { colors, dimensions, fontSizes } from '../../../../../styles';

const TitleText = styled(Text)`
  padding-horizontal: ${({ withPadding }) => (withPadding ? dimensions.medium : 0)};
  padding-top: ${dimensions.doubleMedium};
  padding-bottom: ${dimensions.medium};
`;

const Mandatory = () => (
  <Text large semiBold color={colors.redDarker}>
    *
  </Text>
);

const Title = memo(({ children, mandatory, withPadding }) => (
  <TitleText withPadding={withPadding} semiBold medium>
    {children}{' '}
    {mandatory && (
      <Fragment>
        <Mandatory />{' '}
      </Fragment>
    )}
  </TitleText>
));

Title.propTypes = {
  children: T.node,
  mandatory: T.bool,
  withPadding: T.bool,
};

export default Title;
