import { createAction } from 'redux-actions';
import types from './types';

const setCommunityGroups = createAction(types.SET_COMMUNITY_GROUPS);

const setCommunities = createAction(types.SET_COMMUNITIES);
const setJoinedCommunities = createAction(types.SET_JOINED_COMMUNITIES);
const setInsideCommunityId = createAction(types.SET_INSIDE_COMMUNITY_ID);
const setCommunityInfo = createAction(types.SET_COMMUNITY_INFO);
const changeStatusToPendingApproval = createAction(types.CHANGE_STATUS_TO_PENDING_APPROVAL);
const userBecomeCommunityMember = createAction(types.USER_BECOME_COMMUNITY_MEMBER);
const userLeftCommunity = createAction(types.USER_LEFT_COMMUNITY);

const approveCommunityRules = createAction(types.APPROVE_COMMUNITY_RULES);
const approveItemRules = createAction(types.APPROVE_ITEM_RULES);
const approveWishRules = createAction(types.APPROVE_WISH_RULES);

const increaseMembersCount = createAction(types.INCREASE_MEMBERS_COUNT);
const markCommunityAsVisited = createAction(types.MARK_COMMUNITY_AS_VISITED);
const setAllCommunitiesOnFeed = createAction(types.SET_ALL_COMMUNITIES_ON_FEED);

export default {
  setCommunityGroups,

  setCommunities,
  setJoinedCommunities,
  setInsideCommunityId,
  setCommunityInfo,
  changeStatusToPendingApproval,
  approveCommunityRules,
  approveItemRules,
  approveWishRules,
  userBecomeCommunityMember,
  userLeftCommunity,
  increaseMembersCount,
  markCommunityAsVisited,
  setAllCommunitiesOnFeed,
};
