import api from './api';
import { LOTS_LIMIT } from '../constants/listLimits';

export default {
  getRecentSearches: (type, withShipping) =>
    api.get(`getRecentSearches/?type=${type}&with_shipping=${withShipping}`),

  removeSearchTerm: (searchTermId) =>
    api.post('removeSearchTerm', {
      search_id: searchTermId,
    }),

  getGroupItemsTitlesSuggestions: (groupIds, search_term, skip = 0) =>
    api.post('getGroupItemstitlesSuggestions', {
      group_ids: groupIds,
      search_term,
      skip,
      limit: LOTS_LIMIT,
    }),

  getWishesTitlesSuggestions: ({ searchTerm, skip = 0 }) =>
    api.post('getWishesTitlesSuggestions', {
      search_term: searchTerm,
      skip,
      limit: LOTS_LIMIT,
    }),
};
