import React, { memo } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import {
  CategoriesContainer,
  CategoriesList,
  CategoryButton,
  CategoryText,
  CheckIcon,
} from './styles';
import { colors } from '../../../../../../../styles';
import { FontIcon } from '../../../../../../ReusableComponents';
import { groupsSelectors } from '../../../../../../../store/groups';

const CategoryItem = memo(({ isActive, onSelect, category }) => {
  return (
    <CategoryButton onPress={() => onSelect(category.id)}>
      {isActive && <CheckIcon name="check-circle" size={14} color={colors.blueLight} />}
      <FontIcon
        name={category.icon_name}
        size={28}
        color={isActive ? colors.blueLight : colors.text}
      />
      <CategoryText xsmall isActive={isActive}>
        {category.name}
      </CategoryText>
    </CategoryButton>
  );
});

const Categories = memo(({ selectedCategoryIds, parentCategories, onSelectCategory }) => {
  return (
    <CategoriesContainer>
      <CategoriesList
        data={parentCategories}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <CategoryItem
            isActive={selectedCategoryIds.includes(item.id)}
            category={item}
            onSelect={onSelectCategory}
          />
        )}
      />
    </CategoriesContainer>
  );
});

Categories.propTypes = {
  parentCategories: T.array,
  selectedCategoryIds: T.array,
  onSelectCategory: T.func,
};

export default connect((state, props) => ({
  parentCategories: groupsSelectors.getSelectedCommunityParentCategoriesList(props.categories),
}))(Categories);
