import React, { memo } from 'react';
import T from 'prop-types';
import { StyleSheet, Image, FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import { View, Text, TouchableItem, FastImage } from '../../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../../styles';
import strings from '../../../../../../localization';
import { NavigationService } from '../../../../../../services';
import rewardAmauntTypes from '../../../../../../constants/rewardAmauntTypes';
import { getIsUnverifiedUserEmail } from '../../../../../../store/userInfo/selectors';
import { getPersonalRewardNavigationScreen } from '../../../../../../utils/personalRewards';
import RewardBackground from './RewardBackground';
import { isWeb } from '../../../../../../utils/detectDevice';

const s = StyleSheet.create({
  contentContainer: {},
  listContentContainer: {
    paddingHorizontal: dimensions.halfMedium,
  },
  itemContainer: {
    alignItems: 'center',
    marginHorizontal: dimensions.halfMedium / 2,
  },
  mainContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'row',
    zIndex: 1000,
    justifyContent: 'center',
  },
  imageContainer: {
    width: isWeb ? 220 : 110,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemImage: {
    height: isWeb ? 120 : 60,
    width: isWeb ? 120 : 60,
  },
  infoContainer: {
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    paddingVertical: dimensions.medium,
    paddingLeft: dimensions.medium,
  },
  shortTitleText: {
    width: isWeb ? 180 : 90,
    lineHeight: 18,
    paddingTop: dimensions.halfMedium,
  },
  text: {
    lineHeight: 18,
  },
});

const Item = memo(({ item, isUnverifiedEmail }) => {
  const onPress = () => {
    const screen = getPersonalRewardNavigationScreen(item.app_action, { isUnverifiedEmail });
    NavigationService.navigate(screen);
  };

  return (
    <TouchableItem onPress={onPress} style={s.itemContainer}>
      <RewardBackground />

      <View style={s.mainContainer}>
        <View style={s.imageContainer}>
          <FastImage source={{ uri: item.icon_url }} style={s.itemImage} />
          {item.label !== 'personal_info' && (
            <Text style={s.shortTitleText} alignCenter>
              {item.short_title}
            </Text>
          )}
        </View>
        <View style={s.infoContainer}>
          {item.label === 'personal_info' ? (
            <Text color={colors.gray} style={s.text}>
              {strings.rewards.add}
              {'\n'}
              {item.short_title}
            </Text>
          ) : (
            <Text color={colors.gray} style={s.text}>
              {item.count_to_next_goal}{' '}
              {strings.rewards[item.section === 'referral' ? 'referrals' : 'items']}
              {'\n'}
              {strings.rewards.to_next_badge}
            </Text>
          )}

          <Text style={[rs.marginTop, s.text]}>
            {item.type === rewardAmauntTypes.fixed
              ? strings.notifications.earn
              : strings.notifications.earn_up_to}
            {'\n'}
            <Text color={colors.blueLight} semiBold>
              {item.max_reward_amount} {strings.bank.tokens}
            </Text>
          </Text>
        </View>
      </View>
    </TouchableItem>
  );
});

const UserInfoRewardsList = memo(({ data }) => {
  const isUnverifiedEmail = useSelector(getIsUnverifiedUserEmail);

  return (
    <View style={s.contentContainer}>
      <FlatList
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={[s.listContentContainer, isWeb && rs.webContainer]}
        data={data}
        keyExtractor={({ id }) => id.toString()}
        renderItem={({ item }) => <Item item={item} isUnverifiedEmail={isUnverifiedEmail} />}
      />
    </View>
  );
});

UserInfoRewardsList.propTypes = {
  // profileImage: T.string,
};

export default UserInfoRewardsList;
