import React, { Fragment } from 'react';
import styled from 'styled-components/native';
import Text from '../Text';
import TouchableItem from '../TouchableItem';
import FontIcon from '../FontIcon';
import Icon from '../Icon';
import AngleIcon from '../AngleIcon';
import { colors, dimensions, fontSizes } from '../../../styles';

export const ButtonContainer = styled(TouchableItem)`
  padding-left: ${dimensions.medium};
  padding-right: ${dimensions.medium};
  height: ${dimensions.smallListItemHeight};
  flex-direction: row;
  border-bottom-width: ${({ withoutBorder }) => (withoutBorder ? 0 : dimensions.hairline)};
  border-bottom-color: ${colors.grayLight};
  align-items: center;
`;

export const IconContainer = styled.View`
  margin-right: ${dimensions.medium};
`;

export const TitleContainer = styled.View`
  justify-content: center;
`;

export const Title = styled(Text)`
  font-size: ${fontSizes.medium};
`;

const Button = ({ iconName, isFontIcon, onPress, title }) => (
  <ButtonContainer onPress={onPress} useOpacity={false}>
    <Fragment>
      <IconContainer>
        {isFontIcon ? (
          <FontIcon name={iconName} size={22} color={colors.gray} />
        ) : (
          <Icon name={iconName} size={18} color={colors.gray} />
        )}
      </IconContainer>

      <TitleContainer>
        <Text medium>{title}</Text>
      </TitleContainer>
      <AngleIcon color={colors.gray} />
    </Fragment>
  </ButtonContainer>
);

export default Button;
