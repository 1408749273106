import { dimensions } from '../../../../styles';
import { StyleSheet } from 'react-native';
import { isSmallAndroid } from '../../../../utils/detectDevice';

export default StyleSheet.create({
  image: {
    width: 200,
    height: dimensions.height * 0.14,
    marginTop: isSmallAndroid ? 0 : dimensions.medium,
  },
});
