import api from './api';

export default {
  getTokensForExchange: (group_id, amount) =>
    api.get(`/getTokensForExchange/${group_id}/${amount}`),

  getTokensWhereExchangeAvailable: () => api.get(`/getTokensWhereExchangeAvailable`),

  getTokensForExchangeFromTokenId: tokenId =>
    api.get(`/getTokensForExchangeFromTokenId/${tokenId}`),

  performExchange({ token_id, to_token_id, exchange_amount }) {
    return api.post(`/exchangeTokens`, {
      token_id,
      to_token_id,
      exchange_amount,
    });
  },
};
