import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FlatList } from 'react-native';
import debounce from 'debounce';
import * as R from 'ramda';
import {
  Container,
  Separator,
  View,
  HeaderContainer,
  SearchItem,
  SearchHistoryItem,
  Spinner,
  EmptyListWithImage,
} from '../../../ReusableComponents';
import MarketplaceSearchBar from '../../../ReusableComponents/MarketplaceSearchBar';
import { dimensions, headerStyle, rs } from '../../../../styles';
import { groupsSelectors } from '../../../../store/groups';
import { lotsOperations } from '../../../../store/lots';
import { feedSearchOperations, feedSearchSelector } from '../../../../store/feedSearch';

import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import strings from '../../../../localization';
import { SHIPPING_GROUP_ID } from '../../../../constants/excludedGroupIds';

class SearchResults extends Component {
  static navigationOptions = () => ({
    header: null,
    ...headerStyle,
  });

  state = {
    searchTerm: '',
    titlesSuggestions: [],
    isTitleSuggestionsLoading: false,
  };

  getSuggestionsDebounce = debounce(async (searchTerm) => {
    const { getGroupItemsTitlesSuggestions } = this.props;

    const titlesSuggestions = await getGroupItemsTitlesSuggestions(searchTerm);

    this.setState({ titlesSuggestions, isTitleSuggestionsLoading: false });
  }, 400);

  componentDidMount() {
    const { searchTerm, getItemsSearchHistory } = this.props;
    getItemsSearchHistory();
    if (searchTerm) {
      this.onSearchTermChanged(searchTerm);
    }
  }

  onSearchTermChanged = (searchTerm) => {
    this.setState({ searchTerm, isTitleSuggestionsLoading: true });
    this.getSuggestionsDebounce(searchTerm);
  };

  onSelectSearchResult = (title) => {
    const { setFeedLotFilters, filteredGroupIds, searchTerm: searchTermFromStore } = this.props;
    const { searchTerm } = this.state;

    // if search term doesn't change and still empty and no title suggestion selected, just go back
    if (!searchTerm && !searchTermFromStore && !R.is(String, title)) {
      NavigationService.goBack();
      NavigationService.navigate(screens.MarketplaceTab);

      return;
    }

    const groupIds = filteredGroupIds.includes(SHIPPING_GROUP_ID) ? filteredGroupIds : [];

    setFeedLotFilters({
      searchTerm: R.is(String, title) ? title : searchTerm.trim() || null,
      groupIds: groupIds,
      selectedCategoryIds: [],
      selectedSizes: [],
      selectedBrands: [],
      selectedConditions: [],
      isSearchByFollowings: false,
      isSearchByDiscount: false,
      isOnlyPublic: true,
    });
    NavigationService.goBack();
    NavigationService.navigate(screens.MarketplaceTab);
  };

  onRemoveSearchHistory = (searchTermId) => {
    const { removeItemSearchTerm } = this.props;
    removeItemSearchTerm(searchTermId);
  };

  renderEmptyListComponent = () => {
    const { searchTerm, isTitleSuggestionsLoading } = this.state;
    if (isTitleSuggestionsLoading) {
      return (
        <View style={[rs.paddingTop]}>
          <Spinner />
        </View>
      );
    }

    return (
      <EmptyListWithImage
        image="empty_state_search"
        text={
          searchTerm
            ? strings.emptyLists.empty_search_results
            : strings.emptyLists.empty_item_history
        }
      />
    );
  };

  render() {
    const { titlesSuggestions, searchTerm, isTitleSuggestionsLoading } = this.state;
    const { searchResults } = this.props;

    const showSearchHistory =
      R.isEmpty(titlesSuggestions) && R.isEmpty(searchTerm) && !isTitleSuggestionsLoading;

    return (
      <Container>
        <HeaderContainer>
          <MarketplaceSearchBar
            autoFocus
            showCancel
            defaultValue={searchTerm || ''}
            value={searchTerm}
            onCancel={() => NavigationService.goBack()}
            onChangeText={this.onSearchTermChanged}
            onSubmitEditing={this.onSelectSearchResult}
            placeholder={strings.other.marketplace_search_placeholder}
            returnKeyType="search"
            enablesReturnKeyAutomatically={false}
            inputContainerStyle={{ marginLeft: 0 }}
            containerStyle={{ width: dimensions.width - dimensions.doubleMedium }}
            // Android only
            withHorizontalPadding
          />
        </HeaderContainer>
        <FlatList
          keyboardShouldPersistTaps="handled"
          data={showSearchHistory ? searchResults : titlesSuggestions}
          keyExtractor={(item) => (R.prop('id', item) || item).toString()}
          ItemSeparatorComponent={() => <Separator />}
          ListEmptyComponent={this.renderEmptyListComponent}
          renderItem={({ item }) =>
            showSearchHistory ? (
              <SearchHistoryItem
                title={item.search_term}
                id={item.id}
                onSelectSearchResult={this.onSelectSearchResult}
                onRemoveSearchHistory={this.onRemoveSearchHistory}
              />
            ) : (
              <SearchItem title={item} onSelectSearchResult={this.onSelectSearchResult} />
            )
          }
        />
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    searchTerm: state.lots.feedLotFilters.searchTerm,
    searchResults: feedSearchSelector.getItemSearchHistory(state),
    filteredGroupIds: state.lots.feedLotFilters.groupIds,
  }),
  (dispatch) => ({
    getItemsSearchHistory: () => dispatch(feedSearchOperations.getItemsSearchHistory()),
    removeItemSearchTerm: (searchTermId) =>
      dispatch(feedSearchOperations.removeItemSearchTerm(searchTermId)),
    getGroupItemsTitlesSuggestions: (searchTerm) =>
      dispatch(feedSearchOperations.getGroupItemsTitlesSuggestions(searchTerm)),
    setFeedLotFilters: (feedLotFilters) =>
      dispatch(lotsOperations.setFeedLotFilters(feedLotFilters)),
  }),
)(SearchResults);
