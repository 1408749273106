import { StyleSheet } from 'react-native';

import colors from './colors';
import fontSizes from './fontSizes';
import { isIos } from '../utils/detectDevice';

const androidConfig = {
  height: 56,
  elevation: 0,
  borderBottomWidth: StyleSheet.hairlineWidth,
};

const headerStyle = {
  headerStyle: {
    ...(isIos ? {} : androidConfig),
    backgroundColor: colors.white,
  },
  headerTitleStyle: {
    textAlign: 'center',
    color: colors.text,
    alignSelf: 'center',
    fontWeight: undefined,
    fontSize: fontSizes.large,
    fontFamily: 'Rubik-Medium',
  },
  headerTintColor: colors.blueLight,
  headerBackTitleVisible: false,
};

export const grayHeaderStyle = {
  ...headerStyle,
  headerStyle: {
    ...(isIos ? {} : androidConfig),
    backgroundColor: colors.grayLighter,
  },
};

export const darkBlueHeaderStyle = {
  ...headerStyle,
  headerStyle: {
    ...(isIos ? {} : androidConfig),
    backgroundColor: colors.secondary,
  },
  headerTitleStyle: {
    ...headerStyle.headerTitleStyle,
    color: colors.white,
  },
};

export const headerStyleTransparent = {
  headerStyle: {
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0,
  },
  headerTitleStyle: {
    textAlign: 'center',
    color: colors.text,
    alignSelf: 'center',
    fontWeight: undefined,
    fontSize: fontSizes.large,
    fontFamily: 'Rubik-Medium',
  },
  headerTintColor: colors.blueLight,
  headerBackTitleVisible: false,
};

export const headerStyleTransparentWithBackground = {
  headerStyle: {
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0,
    backgroundColor: colors.white,
  },
  headerTitleStyle: {
    textAlign: 'center',
    color: colors.text,
    alignSelf: 'center',
    fontWeight: undefined,
    fontSize: fontSizes.large,
    fontFamily: 'Rubik-Medium',
  },
  headerTintColor: colors.blueLight,
  headerBackTitleVisible: false,
};

export default headerStyle;
