import * as React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { getIsUserAdmin } from '../../store/communityInfo/selectors';
import strings from '../../localization';
import screens from '../screens';
import {
  TouchableItem,
  Text,
  FontIcon,
  Icon,
  Image,
  Separator,
} from '../../components/ReusableComponents';
import Popover, { usePopover } from '../../components/ReusableComponents/PortalPopover';
import { NavigationService } from '../../services';
import { colors, dimensions, rs } from '../../styles';

const IMAGE_SIZE = 40;

const options = [
  {
    key: 'editProfile',
    title: strings.login.edit_profile,
    iconName: 'Profile-icon',
    screen: screens.EditUserInfo,
    withAdminAccess: false,
    hasSeparator: false,
  },
  {
    key: 'transactions',
    title: strings.settings.transactions,
    iconName: 'Wallet',
    screen: screens.Transactions,
    withAdminAccess: false,
    hasSeparator: false,
  },
  {
    key: 'inbox',
    title: strings.chats.inbox,
    iconName: 'NavBar_Inbox',
    screen: screens.Inbox,
    withAdminAccess: false,
    hasSeparator: false,
  },
  {
    key: 'settings',
    title: strings.settings.settings,
    iconName: 'Menu_settings',
    screen: screens.Settings,
    withAdminAccess: false,
    hasSeparator: true,
  },
  {
    key: 'savedItems',
    title: strings.profile.my_saved_items,
    iconName: 'ouline_heart',
    screen: screens.SavedItems,
    withAdminAccess: false,
    hasSeparator: false,
  },
  {
    key: 'sellingOffers',
    title: strings.profile.selling,
    iconName: 'Selling',
    screen: screens.SellingOffers,
    withAdminAccess: false,
    hasSeparator: false,
  },
  {
    key: 'buyingOffers',
    title: strings.profile.buying,
    iconName: 'Buying',
    screen: screens.BuyingOffers,
    withAdminAccess: false,
    hasSeparator: false,
  },
  {
    key: 'wishFulfillmentOffers',
    title: strings.profile.wish_fulfillment,
    iconName: 'Wish',
    screen: screens.WishFulfillmentOffers,
    withAdminAccess: false,
    hasSeparator: false,
  },
  {
    key: 'myItems',
    title: strings.buy_sell.my_items,
    iconName: 'Items',
    screen: screens.CurrentUserItems,
    withAdminAccess: false,
    hasSeparator: false,
  },
  {
    key: 'support',
    title: strings.support.support,
    iconName: 'Menu_Support',
    screen: screens.Support,
    withAdminAccess: false,
    hasSeparator: false,
  },
  {
    key: 'pendingItems',
    title: strings.pending_items.pending_items,
    iconName: 'pending_items',
    screen: screens.PendingItems,
    withAdminAccess: true,
    hasSeparator: false,
  },
];

const s = StyleSheet.create({
  popoverOuterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderWidth: 0.5,
    borderColor: colors.darkCyan,
    borderRadius: IMAGE_SIZE / 2,
  },
  popoverOuterImage: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    borderRadius: IMAGE_SIZE / 2,
    marginLeft: 10,
  },
  popoverOuterIcon: {
    marginLeft: IMAGE_SIZE / 2,
  },
  popoverOption: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    paddingHorizontal: dimensions.halfMedium,
    paddingVertical: 10,
  },
});

const HeaderMenu = React.memo(() => {
  const wrapperRef = React.useRef(null);
  const { isVisible, targetLayout, handleMountPress, hide } = usePopover(wrapperRef);
  const isCurrentUserAdmin = useSelector(getIsUserAdmin);
  const userProfileImage = useSelector((state) => state.userInfo.profile_image);

  return (
    <>
      <TouchableOpacity ref={wrapperRef} style={s.popoverOuterContainer} onPress={handleMountPress}>
        <>
          <Icon name="menu" size={20} color={colors.darkCyan} style={s.popoverOuterIcon} />
          <Image
            source={{ uri: userProfileImage }}
            style={s.popoverOuterImage}
            resizeMode="contain"
          />
        </>
      </TouchableOpacity>

      {isVisible && (
        <Popover targetLayout={targetLayout} onPress={hide}>
          <View>
            {options
              .filter(({ withAdminAccess }) => !withAdminAccess || isCurrentUserAdmin)
              .map((item) => {
                const onPress = () => {
                  NavigationService.navigate(item.screen);
                  hide();
                };

                return (
                  <>
                    <TouchableItem key={item.key} onPress={onPress} style={s.popoverOption}>
                      <FontIcon
                        withoutRTLScale
                        name={item.iconName}
                        color={colors.text}
                        size={20}
                      />
                      <Text style={rs.smallMarginLeft} semiBold>
                        {item.title}
                      </Text>
                    </TouchableItem>

                    {item.hasSeparator && <Separator />}
                  </>
                );
              })}
          </View>
        </Popover>
      )}
    </>
  );
});

export default HeaderMenu;
