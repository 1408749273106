import React, { memo } from 'react';
import {
  EmptyListWithImage,
  SearchHistoryItem,
  SearchItem,
  Separator,
  Spinner,
  View,
} from '../../../../ReusableComponents';
import { rs } from '../../../../../styles';
import strings from '../../../../../localization';
import { FlatList } from 'react-native';
import * as R from 'ramda';
import memoHelper from '../../../../../utils/memoHelper';

const ItemsScene = memo(
  ({
    showItemsSearchHistory,
    searchResults,
    titleSuggestions,
    isItemSuggestionsLoading,
    isEmptySearchTerm,
    onSelectSearchResult,
    onRemoveSearchHistory,
  }) => {
    return (
      <FlatList
        data={showItemsSearchHistory ? searchResults : titleSuggestions}
        keyExtractor={(item) => (R.prop('id', item) || item).toString()}
        ItemSeparatorComponent={() => <Separator />}
        ListEmptyComponent={() =>
          isItemSuggestionsLoading ? (
            <View style={[rs.paddingTop]}>
              <Spinner />
            </View>
          ) : (
            <EmptyListWithImage
              image="empty_state_search"
              text={
                isEmptySearchTerm
                  ? strings.emptyLists.empty_item_history
                  : strings.emptyLists.empty_search_results
              }
            />
          )
        }
        renderItem={({ item }) =>
          showItemsSearchHistory ? (
            <SearchHistoryItem
              title={item.search_term}
              id={item.id}
              onSelectSearchResult={onSelectSearchResult}
              onRemoveSearchHistory={onRemoveSearchHistory}
            />
          ) : (
            <SearchItem title={item} onSelectSearchResult={onSelectSearchResult} />
          )
        }
        keyboardShouldPersistTaps="handled"
      />
    );
  },
  (prev, next) =>
    memoHelper(prev, next, [
      'showItemsSearchHistory',
      'searchResults',
      'titleSuggestions',
      'isItemSuggestionsLoading',
      'isEmptySearchTerm',
      'onSelectSearchResult',
      'onRemoveSearchHistory',
    ]),
);

export default ItemsScene;
