import React, { memo } from 'react';
import { TouchableOpacity, View, Image } from 'react-native';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { numberWithCommas } from '../../../../../utils/stringHelper';
import {
  SELLER_LEFT_COMMUNITY,
  BUYER_LEFT_COMMUNITY,
  CONFIRM_CANCEL_PURCHASE,
  OFFER_CONFIRMED,
} from '../../../../../store/offers/offerStatuses';
import s from './styles';
import {
  Text,
  ItemAvatar,
  Button,
  RoundButton,
  AppImage,
  TouchableItem,
  IconButton,
} from '../../../../ReusableComponents';
import { colors, rs } from '../../../../../styles';
import strings from '../../../../../localization';
import { time } from '../../../../../utils/dateHelper';
import { BUYING } from '../../../../../constants/offerTypes';
import NavigationService from '../../../../../services/NavigationService';
import screens from '../../../../../navigation/screens';

const ItemView = memo(
  ({
    offer,
    offerType,
    isShowStatus,
    onOpenChat,
    hasDismissButton,
    dismissButtonText,
    onDismissPress,
    hasSuccessButton,
    onSuccessPress,
    successButtonText,
    hasWarningNotification,
    warningNotificationText,
    hasSuccessNotification,
    successNotificationText,
  }) => {
    const {
      status_id,
      item_id,
      item_name,
      item_image,
      seller_name,
      buyer_name,
      discounted_price,
      item_price,
      created_at,
      updated_at,
      seller_id,
      buyer_id,
      seller_image,
      buyer_image,
      chat_room_id,
    } = offer;

    const isBuyingOffer = offerType === BUYING;

    const isUserLeftCommunity =
      parseInt(status_id) === BUYER_LEFT_COMMUNITY || parseInt(status_id) === SELLER_LEFT_COMMUNITY;

    let statusText = '';

    if (Number(status_id) === OFFER_CONFIRMED) {
      statusText = strings.buy_sell.pending_delivery;
    } else if (Number(status_id) === CONFIRM_CANCEL_PURCHASE && isBuyingOffer) {
      statusText = strings.buy_sell.asked_to_buy;
    } else if (Number(status_id) === CONFIRM_CANCEL_PURCHASE && !isBuyingOffer) {
      statusText = strings.buy_sell.purchase_requests;
    }

    const onOpenUserProfile = () => {
      NavigationService.navigate(screens.UserProfile, {
        name: isBuyingOffer ? seller_name : buyer_name,
        userId: isBuyingOffer ? seller_id : buyer_id,
        profile_image: isBuyingOffer ? seller_image : buyer_image,
      });
    };

    const onOpenItemDetails = () => {
      NavigationService.navigate(screens.ItemDetails, { itemId: item_id });
    };

    return (
      <View style={rs.paddingHorizontal}>
        <View style={[s.contentContainer, rs.shadow]}>
          <TouchableItem style={s.topContainer} onPress={onOpenItemDetails}>
            <AppImage source={{ uri: item_image }} style={s.itemImage} />
            <View style={s.nameContainer}>
              <View style={s.priceContainer}>
                <Image style={s.priceImage} source={{ uri: 'price' }} resizeMode="contain" />
                <Text style={s.priceText} bold medium color={colors.secondary}>
                  {numberWithCommas(discounted_price || item_price)}{' '}
                </Text>
              </View>

              <Text medium semiBold numberOfLines={1}>
                {item_name}
              </Text>

              <Text xsmall color={colors.gray} style={rs.smallPaddingTop}>
                {time(created_at)}
              </Text>
            </View>

            {isUserLeftCommunity && (
              <Text color={colors.gray}>{strings.buy_sell.user_left_community}</Text>
            )}
          </TouchableItem>

          <View style={[s.infoContainer]}>
            <Text style={s.infoTitleText}>
              {isBuyingOffer ? `${strings.buy_sell.seller}:` : `${strings.buy_sell.buyer}:`}
            </Text>
            <TouchableOpacity
              style={[rs.row, rs.marginRight, rs.alignCenter]}
              onPress={onOpenUserProfile}
            >
              <AppImage
                style={s.userImage}
                source={{ uri: isBuyingOffer ? seller_image : buyer_image }}
              />
              <Text color={colors.blueLight}>
                {isBuyingOffer ? `${seller_name}` : `${buyer_name}`}
              </Text>
            </TouchableOpacity>
            <IconButton
              name="Message-user"
              size={26}
              color={colors.blueLight}
              onPress={() => onOpenChat(chat_room_id)}
            />
          </View>

          {isShowStatus && (
            <View style={[s.infoContainer, rs.smallMarginTop]}>
              <Text style={s.infoTitleText}>{strings.buy_sell.status}:</Text>
              <Text semiBold>{statusText}</Text>
            </View>
          )}

          <View style={s.buttonsContainer}>
            {hasDismissButton && (
              <RoundButton
                fullWidth
                withMarginRight={hasSuccessButton}
                secondaryType
                onPress={onDismissPress}
                title={dismissButtonText}
              />
            )}

            {hasSuccessButton && (
              <RoundButton fullWidth onPress={onSuccessPress} title={successButtonText} />
            )}

            {hasWarningNotification && (
              <RoundButton fullWidth disabled secondaryType title={warningNotificationText} />
            )}

            {hasSuccessNotification && (
              <RoundButton fullWidth disabled secondaryType title={successNotificationText} />
            )}
          </View>
        </View>
      </View>
    );
  },
);

export default ItemView;
