import React, { Fragment } from 'react';
import s from './styles';
import {
  BackButton,
  View,
  TouchableItem,
  Container,
  Text,
} from '../../../ReusableComponents/index';
import { colors, headerStyle, rs } from '../../../../styles';
import strings from '../../../../localization/index';
import { NavigationService } from '../../../../services';

const Item = ({ item, isSelected, onSelectCondition }) => (
  <TouchableItem
    style={[s.itemContainer, isSelected && s.activeItemContainer]}
    useOpacity={false}
    onPress={() => {
      onSelectCondition(item.id);
      NavigationService.pop();
    }}
  >
    <Fragment>
      <Text medium semiBold style={rs.verySmallMarginBottom}>
        {item.title}
      </Text>
      <Text color={colors.gray} style={s.descriptionText}>
        {item.subtitle}
      </Text>
    </Fragment>
  </TouchableItem>
);

const RefundPolicies = ({ route }) => {
  const refundPolicies = route.params.refundPolicies;
  const selectedRefundPolicyId = route.params.selectedConditionId;
  const onSelectRefundPolicy = route.params.onSelectRefundPolicy;

  return (
    <Container>
      {refundPolicies.map((item) => (
        <Item
          key={item.id}
          item={item}
          isSelected={item.id === selectedRefundPolicyId}
          onSelectCondition={onSelectRefundPolicy}
        />
      ))}
    </Container>
  );
};

RefundPolicies.navigationOptions = ({ navigation }) => ({
  ...headerStyle,
  title: strings.lots.refund_policy,
  headerLeft: <BackButton navigation={navigation} isCancel />,
  headerRight: <View />,
});

export default RefundPolicies;
