import React, { memo, Fragment } from 'react';
import T from 'prop-types';
import { View, FlatList } from 'react-native';
import s from './styles';
import { colors } from '../../../styles';
import TouchableItem from '../TouchableItem';
import TokenIcon from '../TokenIcon';
import Text from '../Text';
import strings from '../../../localization';

const HorizontalTokenSelector = memo(
  ({ isCommunitySelector, communities, selectedCommunityId, onSelect }) => {
    return (
      <View style={s.contentContainer}>
        <FlatList
          bounces={communities.length > 2}
          horizontal
          inverted
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={s.listContentContainerStyle}
          data={communities}
          extraData={selectedCommunityId}
          keyExtractor={item => item.id.toString()}
          renderItem={({ item }) => {
            const isActive = item.id === selectedCommunityId;
            return (
              <TouchableItem
                useOpacity={false}
                disabled={isActive}
                style={[
                  s.itemContainer,
                  isActive && {
                    backgroundColor: colors.blueLighter,
                    borderColor: colors.blueLighter,
                  },
                ]}
                onPress={() => onSelect(item.id)}
              >
                <Fragment>
                  <TokenIcon withSmallHorizontalMargin customUrl={item.token_icon} />
                  <View>
                    <Text color={isActive && colors.white}>
                      {isCommunitySelector ? item.group_name : item.token_name}
                    </Text>
                    {!isCommunitySelector && (
                      <Text color={isActive && colors.white} xsmall>
                        {strings.tokens_exchange.balance}: {item.available_balance}
                      </Text>
                    )}
                  </View>
                </Fragment>
              </TouchableItem>
            );
          }}
        />
      </View>
    );
  },
);

HorizontalTokenSelector.propTypes = {
  communities: T.array,
  isCommunitySelector: T.bool,
  selectedCommunityId: T.number,
  onSelect: T.func.isRequired,
};

export default HorizontalTokenSelector;
