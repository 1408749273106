/* eslint-disable */
import React, { Component } from 'react';
import { LayoutAnimation } from 'react-native';
import LottieView from 'lottie-react-native';
import styled from 'styled-components/native';
import { colors } from '../../../styles';

const animation = require('../../../assets/animations/loadingWithSuccess.json');

const ContentContainer = styled.View`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const CentredContainer = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const OpacityBackground = styled.View`
  background-color: ${colors.black};
  opacity: 0.1;
  position: absolute;
  width: 100%;
  height: 100%;
`;

class SuccessLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isLastLoop: false,
      isFinished: false,
    };
  }

  callBackOnFinish = null;

  componentDidMount() {
    LayoutAnimation.easeInEaseOut();
  }

  componentWillUnmount() {
    LayoutAnimation.easeInEaseOut();
  }

  showLoader = () => {
    this.setState({ show: true, isLastLoop: false, isFinished: false });
    setTimeout(() => {
      this.animation.play(19, 56);
    }, 0);
  };

  hideLoader = callBackOnFinish => {
    this.setState({ isLastLoop: true });
    this.callBackOnFinish = callBackOnFinish;
  };

  onAnimationFinish = () => {
    const { isLastLoop, isFinished } = this.state;
    if (isLastLoop) {
      if (isFinished) {
        this.animation.reset();
        this.setState({ show: false });
        if (this.callBackOnFinish) this.callBackOnFinish();
      }
      this.animation.play(56, 90);
      this.setState({ isFinished: true });
    } else {
      this.animation.play(19, 56);
    }
  };

  render() {
    const { show } = this.state;
    return show ? (
      <ContentContainer>
        <CentredContainer>
          <OpacityBackground />
          <LottieView
            ref={animation => {
              this.animation = animation;
            }}
            loop={false}
            speed={1.2}
            onAnimationFinish={this.onAnimationFinish}
            style={{
              width: 100,
              height: 100,
            }}
            source={animation}
          />
        </CentredContainer>
      </ContentContainer>
    ) : null;
  }
}

export default SuccessLoader;
