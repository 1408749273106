import toastTypes from '../constants/toastTypes';
import strings from '../localization';

class ModalsService {
  constructor() {
    this._rootModal = null;
  }

  init(ref) {
    if (this._rootModal) {
      return;
    }
    this._rootModal = ref;
  }

  destroy() {
    this._rootModal = null;
  }

  showModal(name, params) {
    this._rootModal.showSimpleModal(name, params);
  }

  showTransparentModal(name, params) {
    this._rootModal.showTransparentModal(name, params);
  }

  showFullScreenModal(name, params) {
    this._rootModal.showFullScreenModal(name, params);
  }

  showSwipeableModal(name, params) {
    this._rootModal.showSwipeableModal(name, params);
  }
  showMandatoryResponseModal(name, params) {
    this._rootModal.showMandatoryResponseModal(name, params);
  }

  hideModal() {
    this._rootModal.onClose();
  }

  // TODO: workaround, need to find a way how to replace modal without timeout
  async replaceModal(name, params) {
    await this._rootModal.onClose();
    setTimeout(() => {
      this._rootModal.showSimpleModal(name, params);
    }, 500);
  }
}

const modalsService = new ModalsService();

export default modalsService;
