import React, { Fragment } from 'react';
import T from 'prop-types';
import {
  MainContainer,
  DetailContainer,
  MemberName,
  MemberStatus,
  InfoView,
  AngleIcon,
} from './styles';
import strings from '../../../../../localization';
import { colors } from '../../../../../styles';
import UserAvatar from '../../../UserAvatar';
import { getTimeForNotification } from '../../../../../utils/dateHelper';
import Text from '../../../Text';
import CheckBox from '../../../CheckBox';

class MemberItem extends React.PureComponent {
  render() {
    const {
      lastSeenAt,
      profileImage,
      name,
      nickname,
      isMulti,
      isSelected,
      isDisabled,
      onSelect,
    } = this.props;

    return (
      <MainContainer disabled={isDisabled} useOpacity={false} onPress={onSelect}>
        <Fragment>
          <UserAvatar uri={profileImage} />

          <DetailContainer>
            <InfoView>
              <Text>
                {name}
                {nickname ? <Text color={colors.gray}> @{nickname}</Text> : ''}
              </Text>
              <MemberStatus color={colors.gray}>
                {strings.profile.last_seen.replace(/__TIME__/, getTimeForNotification(lastSeenAt))}
              </MemberStatus>
            </InfoView>
          </DetailContainer>

          {isMulti ? (
            <CheckBox checked={isSelected} disabled={isDisabled} onToggle={onSelect} />
          ) : (
            <AngleIcon />
          )}
        </Fragment>
      </MainContainer>
    );
  }
}

MemberItem.propTypes = {
  profileImage: T.string,
  lastSeenAt: T.string,
  name: T.string,
  isSelected: T.bool,
  isDisabled: T.bool,
  onSelect: T.func.isRequired,
};

MemberItem.defaultProps = {
  isSelected: false,
  isDisabled: false,
};

export default MemberItem;
