import React, { Component } from 'react';
import { connect } from 'react-redux';
import { View, Linking } from 'react-native';
import T from 'prop-types';
import { authOperations } from '../../../../store/auth';
import { Text, TextButton, TouchableItem, Container, Image } from '../../../ReusableComponents';
import s from './styles';
import { colors, rs } from '../../../../styles';
import strings from '../../../../localization';
import { CONTACT_US } from '../../../../api/urls';
import CodeConfirmationInput from './components/CodeConfirmationInput';
import { AnalyticsService } from '../../../../services';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';
import { isWeb } from '../../../../utils/detectDevice';

const INITIAL_COUNTER_VALUE = 29;

class CodeVerification extends Component {
  state = {
    code: '',
    phoneNumber: '',

    isAvailableSendAgain: false,

    timer: null,
    counter: INITIAL_COUNTER_VALUE,
  };

  componentDidMount() {
    const { navigation, route } = this.props;

    navigation.setParams({
      isValidCode: false,
      nextClicked: this.nextClicked,
    });

    const phoneNumber = route.params.phoneNumber;

    let timer = setInterval(this.tick, 1000);
    this.setState({ phoneNumber, timer });
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  tick = () => {
    if (this.state.counter > 1) {
      this.setState({
        counter: this.state.counter - 1,
      });
    } else {
      clearInterval(this.state.timer);
      this.setState({
        isAvailableSendAgain: true,
      });
    }
  };

  onChangeCode = (code) => {
    const { navigation } = this.props;

    this.setState({ code });
    if (code.length === 4) {
      navigation.setParams({
        isValidCode: true,
      });
      setTimeout(() => this.nextClicked(), 1);
      return;
    }
    navigation.setParams({
      isValidCode: false,
    });
  };

  onSendCodeAgain = () => {
    const { route } = this.props;
    const onSendCodeAgain = route.params.onSendCodeAgain;

    onSendCodeAgain();
    let timer = setInterval(this.tick, 1000);
    this.setState({ timer, isAvailableSendAgain: false, counter: INITIAL_COUNTER_VALUE });
    AnalyticsService.logEvent(analyticsEventTypes.onboarding_send_code_again);
  };

  nextClicked = async () => {
    const { code, phoneNumber } = this.state;
    const { codeVerification } = this.props;

    await codeVerification(phoneNumber, code);
    AnalyticsService.logEvent(analyticsEventTypes.onboarding_confirm_code);
  };

  render() {
    const { code, counter, isAvailableSendAgain } = this.state;
    const counterFormatted = `0:${counter.toLocaleString('en-US', { minimumIntegerDigits: 2 })}`;

    return (
      <Container style={[rs.alignCenter]}>
        <View style={[rs.webContainer, rs.alignCenter]}>
          <Image style={s.image} source={{ uri: 'verification_code' }} resizeMode="contain" />

          <Text alignCenter medium style={[rs.veryBigMarginBottom, rs.bigMarginTop]}>
            {strings.login.please_enter_code}
          </Text>

          <CodeConfirmationInput
            onChangeText={(verificationCode) => this.onChangeCode(verificationCode)}
            value={code}
          />

          <View style={rs.marginTop}>
            {isAvailableSendAgain ? (
              <TouchableItem onPress={this.onSendCodeAgain}>
                <Text color={colors.blue}>{strings.login.send_code_again}</Text>
              </TouchableItem>
            ) : (
              <Text>
                {strings.login.resend_code_in} {counterFormatted}
              </Text>
            )}
          </View>

          <View style={[rs.row, rs.bigMarginTop, rs.alignCenter]}>
            <Text> {strings.login.didnt_get_code} </Text>
            <TextButton
              small
              title={strings.login.contact_us}
              onPress={() => Linking.openURL(CONTACT_US)}
            />
          </View>
        </View>
      </Container>
    );
  }
}

CodeVerification.propTypes = {
  codeVerification: T.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    codeVerification: (phoneNumber, code) =>
      isWeb
        ? dispatch(authOperations.codeVerificationForWeb(phoneNumber, code))
        : dispatch(authOperations.codeVerification(phoneNumber, code)),
  }),
)(CodeVerification);
