import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { View, ScrollView, RefreshControl, Image } from 'react-native';
import * as R from 'ramda';
import T from 'prop-types';
import { colors, headerStyleTransparent } from '../../../../styles';
import {
  BackButton,
  NavigationIconButton,
  Container,
  UserAvatarWithViewer,
  Text,
  UserFollowersButtons,
} from '../../../ReusableComponents';
import strings from '../../../../localization';
import { getTransactionsSectionList } from '../../../../store/transactions/selectors';
import s from './styles';
import { NavigationService, LoadingService } from '../../../../services';
import screens from '../../../../navigation/screens';
import { getParamOr } from '../../../../utils/navHelper';
import { shareDynamicLink } from '../../../../utils/shareHelper';
import { OPEN_USER_PROFILE } from '../../../../constants/dynamicalLinkActionTypes';
import { rs } from '../../../../styles';
import { walletsOperations } from '../../../../store/wallets';
import { numberWithCommas } from '../../../../utils/stringHelper';
import { getIsUserDefaultProfileImage, getUserInfo } from '../../../../store/userInfo/selectors';
import { userLocationsSelectors } from '../../../../store/userLocations';
import CardItem from './components/CardItem';
import { userOperations } from '../../../../store/userInfo';

const Transactions = ({
  navigation,
  userInfo,
  getWallets,
  getCurrentUserInfo,
  followersCount,
  followingsCount,
  userLocations,
  wallet,
  rewardWallet,
}) => {
  const { user_credibility_details, nickname, profile_image, name, id, is_shipper } = userInfo;
  const { is_verified, endorsements_count, members_sold_to_count } = user_credibility_details;

  const [isRefreshing, setRefreshing] = useState(false);
  useEffect(() => {
    getCurrentUserInfo();
    navigation.setParams({
      nickname: nickname,
      onShareProfile,
      onEditProfile,
    });
  }, []);

  const onRefresh = async () => {
    setRefreshing(true);
    await getWallets();
    await getCurrentUserInfo();
    setRefreshing(false);
  };

  const onShareProfile = async () => {
    LoadingService.showLoader();
    await shareDynamicLink({
      actionType: OPEN_USER_PROFILE,
      id: id,
      socialImageUrl: profile_image,
      socialTitle: name,
      linkDescription: strings.deep_linking.my_profile_link_info,
    });
    LoadingService.hideLoader();
  };

  const onEditProfile = () => {
    NavigationService.navigate(screens.EditUserInfo);
  };

  const onOpenTransactions = ({}) => {
    NavigationService.navigate(screens.Transactions);
  };

  const onOpenEndorsements = () => {
    NavigationService.navigate(screens.Endorsements, {
      userId: id,
      endorsementsCount: endorsements_count,
    });
  };
  const onOpenAllBuyers = () => {
    NavigationService.navigate(screens.AllBuyers);
  };

  return (
    <Container style={rs.backgroundGray}>
      <View style={s.fakeBackground} />
      <ScrollView
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
        refreshing={isRefreshing}
      >
        <View style={s.contentContainer}>
          <View style={s.backgroundContainer}>
            <UserAvatarWithViewer profileImage={profile_image} isSameUser />

            <View style={[rs.row, rs.alignCenter, rs.paddingTop]}>
              <Text large bold color={colors.white}>
                {name}
              </Text>

              {is_verified && (
                <Image
                  style={s.credibilityImage}
                  source={{
                    uri: 'verified_account',
                  }}
                  resizeMode="contain"
                />
              )}
            </View>
            {/*{!!nickname && (*/}
            {/*  <Text color={colors.white} semiBold style={rs.verySmallPaddingTop}>*/}
            {/*    @{nickname}*/}
            {/*  </Text>*/}
            {/*)}*/}

            <View style={rs.smallMarginBottom} />

            <UserFollowersButtons
              isWhite
              isCurrentUser
              followersCount={followersCount}
              followingsCount={followingsCount}
              name={name}
              userId={id}
              isShipper={is_shipper}
              userLocations={userLocations}
            />
          </View>
        </View>

        <CardItem
          onPress={onOpenTransactions}
          zIndex={4}
          color={colors.blueLighter}
          title={strings.user.account_balance_and_transactions}
          iconName="User_profile_account_balance"
        >
          <Text color={colors.white} style={rs.verySmallMarginBottom}>
            {strings.bank.balance}{' '}
            <Text semiBold color={colors.white}>
              {numberWithCommas(Number(wallet.balance_amount) - Number(wallet.hold_balance))}
            </Text>
          </Text>

          {!R.isEmpty(rewardWallet) && (
            <Text color={colors.white}>
              {strings.bank.reward_allowance}{' '}
              {numberWithCommas(
                Number(rewardWallet.balance_amount) - Number(rewardWallet.hold_balance),
              )}
            </Text>
          )}
        </CardItem>
        <CardItem
          onPress={onOpenAllBuyers}
          zIndex={3}
          color={colors.blueLight}
          title={strings.buy_sell.sales}
          iconName="Selling"
        >
          <Text color={colors.white}>
            {members_sold_to_count} {strings.user.members_bought_from_you}
          </Text>
        </CardItem>
        <CardItem
          onPress={onOpenEndorsements}
          zIndex={2}
          color={colors.greenDark}
          title={strings.user.endorsements}
          iconName="User_profile_endorsements-1"
        >
          <Text color={colors.white}>
            {endorsements_count} {strings.user.members_endorsed_you}
          </Text>
        </CardItem>
      </ScrollView>
    </Container>
  );
};

// Transactions.navigationOptions = ({ navigation }) => {
//   const nickname = getParamOr('', 'nickname')(navigation);
//
//   return {
//     ...headerStyleTransparent,
//     headerStyle: {
//       borderBottomWidth: 0,
//       elevation: 0,
//       shadowOpacity: 0,
//       backgroundColor: colors.secondary,
//     },
//     headerTitleStyle: {
//       ...headerStyleTransparent.headerTitleStyle,
//       color: colors.white,
//     },
//     title: !!nickname ? `@${nickname}` : '',
//     headerLeft: <BackButton isWhite />,
//     headerRight: (
//       <View style={[rs.row, rs.alignCenter]}>
//         <NavigationIconButton
//           isFontIcon
//           name="Edit-17"
//           color={colors.white}
//           onPress={getParamOr(null, 'onEditProfile')(navigation)}
//         />
//         <NavigationIconButton
//           isFontIcon
//           name="Upload_grey"
//           color={colors.white}
//           onPress={getParamOr(null, 'onShareProfile')(navigation)}
//         />
//       </View>
//     ),
//   };
// };

Transactions.propTypes = {
  userInfo: T.object,
  isUserDefaultProfileImage: T.bool,
  followersCount: T.number,
  followingsCount: T.number,
  userLocations: T.array,
};

export default connect(
  (state) => ({
    transactions: getTransactionsSectionList(state),
    isLoading: state.transactions.isLoading,
    isLoadingMore: state.transactions.isLoadingMore,
    userInfo: getUserInfo(state),
    isUserDefaultProfileImage: getIsUserDefaultProfileImage(state),
    followersCount: state.followers.followersCount,
    followingsCount: state.followers.followingsCount,
    userLocations: userLocationsSelectors.getUserLocations(state),
    wallet: state.wallets.wallet,
    rewardWallet: state.wallets.rewardWallet,
  }),
  (dispatch) => ({
    getWallets: () => dispatch(walletsOperations.getWallets()),
    getCurrentUserInfo: () => dispatch(userOperations.getCurrentUserInfo()),
  }),
)(Transactions);
