import React from 'react';
import Icon from 'react-native-vector-icons/Feather';
import styled from 'styled-components/native';
import { colors, rs } from '../../styles';
import Text from './Text';
import strings from '../../localization';

export const SelectItemImagePlaceholder = styled.View`
  width: 128px;
  height: 92px;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${({ color }) => color || colors.white};
  align-items: center;
  justify-content: center;
`;

const EmptyList = ({ title, color }) => (
  <SelectItemImagePlaceholder color={color}>
    <Icon
      style={[{ color: color || colors.white }, rs.verySmallMarginBottom]}
      size={30}
      name="image"
    />
    <Text color={color || colors.white} medium>
      {title || strings.other.add_photo}
    </Text>
  </SelectItemImagePlaceholder>
);

export default EmptyList;
