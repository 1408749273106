import headerStyle, { headerStyleTransparent } from './headerStyle';
import fontSizes from './fontSizes';
import colors from './colors';
import rs from './rs';
import * as dimensions from './dimensions';
import * as scalingUtils from './scalingUtils';
import * as reusableStyles from './reusableStyles';

export {
  headerStyle,
  headerStyleTransparent,
  fontSizes,
  scalingUtils,
  colors,
  rs,
  dimensions,
  reusableStyles,
};
