import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import Button from '../../Buttons/Button';
import Text from '../../Text';
import { MainContainer } from './styles';
import strings from '../../../../localization';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import { colors, rs, dimensions } from '../../../../styles';

const s = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.white,
    height: 100,
    width: 100,
    borderRadius: 50,
    position: 'absolute',
    top: -40,
    alignItems: 'center',
    paddingTop: 16,
  },

  buttonContainer: {
    width: '100%',
    height: 40,
    marginTop: dimensions.doubleMedium,
    paddingHorizontal: dimensions.medium,
    flexDirection: 'row',
  },
});

const PromoCodeActivated = ({ onPress, params }) => (
  <View style={rs.alignCenter}>
    <View style={s.mainContainer}>
      <Image source={{ uri: 'promo_code_small' }} style={{ height: 38, width: 38 }} />
    </View>
    <MainContainer>
      <Text semiBold medium alignCenter style={rs.paddingTop}>
        {strings.promo_codes.modal_title}
      </Text>
      <Text alignCenter style={rs.paddingTop}>
        {strings.promo_codes.modal_subtitle}
      </Text>
      <Text alignCenter semiBold color={colors.blueLight} style={rs.smallPaddingTop}>
        {params.tokensAmount} {strings.home.token_banner_blue_text}
      </Text>
      <View style={s.buttonContainer}>
        <Button
          title={strings.emptyLists.go_to_marketplace}
          onPress={() => {
            onPress();
            NavigationService.navigate(screens.MarketplaceTab);
          }}
        />
      </View>

      <Text alignCenter xsmall color={colors.gray} style={rs.paddingTop}>
        {strings.promo_codes.modal_footer}
      </Text>
    </MainContainer>
  </View>
);

export default PromoCodeActivated;
