import { StyleSheet } from 'react-native';
import { dimensions, colors } from '../../../../styles';

export default StyleSheet.create({
  languageContainer: {
    height: 40,
    width: '100%',
    borderBottomWidth: dimensions.hairline,
    borderColor: colors.grayLight,
    paddingHorizontal: dimensions.medium,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
