import React from 'react';
import { View } from 'react-native';
import StaticHeightButton from './Buttons/StaticHeightButton';
import Text from './Text';
import CenteredView from './Containers/CenteredView';
import strings from '../../localization';
import { rs } from '../../styles';
import { NavigationService } from '../../services';
import screens from '../../navigation/screens';

const JoinAnyCommunityPlaceholder = () => {
  return (
    <CenteredView style={rs.paddingHorizontal}>
      <View>
        <Text large semiBold alignCenter>
          {strings.not_community_member.not_member_of_any_community_title}
        </Text>

        <Text medium alignCenter style={rs.paddingVertical}>
          {strings.not_community_member.not_member_of_any_community_description}
        </Text>

        <View>
          <StaticHeightButton
            title={strings.emptyLists.go_to_marketplace}
            onPress={() => {
              NavigationService.navigate(screens.FeedTab);
            }}
          />
        </View>
      </View>
    </CenteredView>
  );
};

export default JoinAnyCommunityPlaceholder;
