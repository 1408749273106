import styled from 'styled-components/native';
import { fontSizes, colors, dimensions } from '../../../../styles/index';
import Text from '../../Text';
import TouchableItem from '../../TouchableItem';

const BackButtonContainer = styled(TouchableItem)`
  padding-horizontal: 10px;
  padding-vertical: 2px;
`;

const CancelTitleText = styled(Text)`
  font-size: ${fontSizes.medium - 1};
  color: ${colors.blueLight};
`;

export { BackButtonContainer, CancelTitleText };
