export default {
  SET_LANGUAGE: 'app/SET_LANGUAGE',
  SET_CONNECTION: 'app/SET_CONNECTION',
  SET_MODAL_TYPE: 'app/SET_MODAL_TYPE',
  SET_NOTIFICATIONS_PERMISSION: 'app/SET_NOTIFICATIONS_PERMISSION',
  SET_LOCATION_PERMISSION: 'app/SET_LOCATION_PERMISSION',
  SET_FEED_COLUMN_STYLE: 'app/SET_FEED_COLUMN_STYLE',
  RESET_STATE: 'app/RESET_STATE',
  SET_LAST_ACCESS_DATE_IN_CURRENT_SESSION: 'app/SET_LAST_ACCESS_DATE_IN_CURRENT_SESSION',
  SET_GEOLOCATION_COORDINATES: 'app/SET_GEOLOCATION_COORDINATES',
  SET_CONTACTS_PERMISSION: 'app/SET_CONTACTS_PERMISSION',
  SET_CONTACTS: 'app/SET_CONTACTS',
};
