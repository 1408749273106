;
import api from './api';
import { getImageType } from '../utils/stringHelper';
import { LOTS_LIMIT } from '../constants/listLimits';

export default {
  getUserItems: ({ group_ids, sort_by, user_account_id, type, skip = 0, search_term }) =>
    api.post('getItems', {
      group_ids,
      user_account_id,
      sort_by,
      type,
      skip,
      limit: LOTS_LIMIT,
      search_term,
    }),

  getUserSavedItems: ({ group_ids = [], skip = 0 }) =>
    api.post('getUserSavedItems', {
      group_ids,
      skip,
      limit: LOTS_LIMIT,
    }),

  saveNewItem({
    sellerId,
    group_id,
    title,
    description,
    price,
    brandId,
    images,
    item_condition,
    shipping_price,
    locations,
    original_price,
    category_id,
    wishId,
    sizeId,
    refundPolicyId,
    isPublic,
    has_multiple_supply,
    is_shippers_delivery_active,
    tagged_user_ids,
  }) {
    const form = new FormData();
    form.append('seller_id', sellerId);
    form.append('group_id', group_id);
    form.append('title', title);
    form.append('description', description);
    form.append('price', price);
    form.append('brand_id', brandId);
    form.append('item_condition', item_condition);
    form.append('size_id', sizeId);
    form.append('refund_policy_id', refundPolicyId);
    form.append('shipping_price', shipping_price);
    form.append('locations', JSON.stringify(locations));
    form.append('original_price', original_price);
    form.append('category_id', category_id);
    form.append('wish_id', wishId);
    form.append('is_public', isPublic);
    form.append('has_multiple_supply', has_multiple_supply);
    form.append('is_shippers_delivery_active', is_shippers_delivery_active);
    form.append('tagged_user_ids', JSON.stringify(tagged_user_ids));

    form.append(
      'image_1',
      images[0]
        ? {
            uri: images[0],
            name: `image_1.${getImageType(images[0])}`,
            type: `image/${getImageType(images[0])}`,
          }
        : '',
    );
    form.append(
      'image_2',
      images[1]
        ? {
            uri: images[1],
            name: `image_2.${getImageType(images[1])}`,
            type: `image/${getImageType(images[1])}`,
          }
        : '',
    );
    form.append(
      'image_3',
      images[2]
        ? {
            uri: images[2],
            name: `image_3.${getImageType(images[2])}`,
            type: `image/${getImageType(images[2])}`,
          }
        : '',
    );
    form.append(
      'image_4',
      images[3]
        ? {
            uri: images[3],
            name: `image_4.${getImageType(images[3])}`,
            type: `image/${getImageType(images[3])}`,
          }
        : '',
    );
    // form.append(
    //   'image_5',
    //   images[4]
    //     ? {
    //         uri: images[4],
    //         name: `image_5.${getImageType(images[4])}`,
    //         type: `image/${getImageType(images[4])}`,
    //       }
    //     : '',
    // );
    return api.post('createNewItem', form);
  },

  getItemInfo: (id) => api.get(`getItemInfo/${id}`),

  getItemSnapshotByOfferId: (id) => api.get(`getItemSnapshotByOfferId/${id}`),

  activateSoldItem: (id) =>
    api.post('activateSoldItem', {
      item_id: id,
    }),

  getItemDetails: (itemId) => api.get(`getItemDetails/${itemId}`),
  getItemDetailsLists: (itemId) => api.get(`itemDetails/lists/${itemId}`),

  // getUserItems: ({ account_id, group_id }) =>
  //   api.post('getUserItems', {
  //     account_id,
  //     group_id,
  //   }),

  getUserSoldItems: ({ account_id, group_id }) =>
    api.post('getUserSoldItems', {
      account_id,
      group_id,
    }),

  getUserPurchasedItems: ({ account_id, group_id }) =>
    api.post('getUserPurchasedItems', {
      account_id,
      group_id,
    }),

  deleteItem: ({ item_id, seller_id }) => api.delete(`item/${item_id}/${seller_id}`),

  deactivateItem: ({ item_id, account_id }) =>
    api.post('deactivateItem', {
      account_id,
      item_id,
    }),

  activateItem: ({ item_id, account_id }) =>
    api.post('activateItem', {
      account_id,
      item_id,
    }),

  editItem({
    sellerId,
    group_id,
    title,
    description,
    price,
    brandId,
    images,
    item_condition,
    shipping_price,
    locations,
    sizeId,
    refundPolicyId,
    original_price,
    itemId,
    category_id,
    wishId,
    isPublic,
    has_multiple_supply,
    is_shippers_delivery_active,
  }) {
    const form = new FormData();

    form.append('seller_id', sellerId);
    form.append('group_id', group_id);
    form.append('title', title);
    form.append('description', description);
    form.append('price', price);
    form.append('brand_id', brandId);
    form.append('item_condition', item_condition);
    form.append('shipping_price', shipping_price);
    form.append('size_id', sizeId);
    form.append('refund_policy_id', refundPolicyId);
    form.append('locations', JSON.stringify(locations));
    form.append('original_price', original_price);
    form.append('category_id', category_id);
    form.append('wish_id', wishId);
    form.append('is_public', isPublic);
    form.append('has_multiple_supply', has_multiple_supply);
    form.append('is_shippers_delivery_active', is_shippers_delivery_active);

    form.append(
      'image_1',
      images[0].startsWith('http') || R.isEmpty(images[0])
        ? images[0]
        : {
            uri: images[0],
            name: `image_1.${getImageType(images[0])}`,
            type: `image/${getImageType(images[0])}`,
          },
    );

    form.append(
      'image_2',
      images[1].startsWith('http') || R.isEmpty(images[1])
        ? images[1]
        : {
            uri: images[1],
            name: `image_2.${getImageType(images[1])}`,
            type: `image/${getImageType(images[1])}`,
          },
    );
    form.append(
      'image_3',
      images[2].startsWith('http') || R.isEmpty(images[2])
        ? images[2]
        : {
            uri: images[2],
            name: `image_3.${getImageType(images[2])}`,
            type: `image/${getImageType(images[2])}`,
          },
    );
    form.append(
      'image_4',
      images[3].startsWith('http') || R.isEmpty(images[3])
        ? images[3]
        : {
            uri: images[3],
            name: `image_4.${getImageType(images[3])}`,
            type: `image/${getImageType(images[3])}`,
          },
    );
    // form.append(
    //   'image_5',
    //   images[4].startsWith('http') || R.isEmpty(images[4])
    //     ? images[4]
    //     : {
    //         uri: images[4],
    //         name: `image_5.${getImageType(images[4])}`,
    //         type: `image/${getImageType(images[4])}`,
    //       },
    // );

    return api.put(`item/${itemId}/${sellerId}`, form);
  },

  newFavoriteItem: (userId, itemId) =>
    api.post(`newFavoriteItem`, {
      account_id: userId,
      item_id: itemId,
    }),

  unFavoriteItem: (userId, itemId) =>
    api.post(`unFavoriteItem`, {
      account_id: userId,
      item_id: itemId,
    }),

  markItemAsTop: ({ itemId, communityId }) =>
    api.post(`markItemAsTop`, {
      community_id: communityId,
      item_id: itemId,
    }),

  unmarkItemAsTop: ({ itemId, communityId }) =>
    api.post(`unmarkItemAsTop`, {
      community_id: communityId,
      item_id: itemId,
    }),

  getUserFavoritesItems: (userId, groupId) => api.get(`getUserFavoritesItems/${userId}/${groupId}`),
};
