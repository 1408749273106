class LoadingService {
  constructor() {
    this._loader = null;
    this._successLoader = null;
  }

  init(ref) {
    if (this._loader) {
      return;
    }
    this._loader = ref;
  }

  initSuccessLoader(ref) {
    if (this._successLoader) {
      return;
    }
    this._successLoader = ref;
  }

  destroy() {
    this._loader = null;
    this._successLoader = null;
  }

  showLoader() {
    this._loader.showLoader();
  }

  hideLoader() {
    this._loader.hideLoader();
  }

  showSuccessLoader() {
    this._successLoader.showLoader();
  }

  hideSuccessLoader({ callback = null, isSuccess = true } = {}) {
    this._successLoader.hideLoader({ callback, isSuccess });
  }
}

const loadingService = new LoadingService();

export default loadingService;
