import React from 'react';
import { TouchableHighlight, TouchableOpacity } from 'react-native';
import { colors } from '../../../styles';

const noop = () => {};

const Button = ({
  onPress = noop,
  onLongPress = noop,
  onLayout = noop,
  children,
  style,
  useOpacity = true,
  withoutOpacity,
  ...props
}) => {
  if (useOpacity || withoutOpacity) {
    return (
      <TouchableOpacity
        hitSlop={{ top: 8, bottom: 8, left: 8, right: 8 }}
        onLongPress={onLongPress}
        onLayout={onLayout}
        onPress={onPress}
        style={style}
        activeOpacity={withoutOpacity ? 1 : 0.5}
        {...props}
      >
        {children}
      </TouchableOpacity>
    );
  }

  return (
    <TouchableHighlight
      hitSlop={{ top: 8, bottom: 8, left: 8, right: 8 }}
      onLongPress={onLongPress}
      onLayout={onLayout}
      onPress={onPress}
      underlayColor={colors.grayLight}
      style={style}
      {...props}
    >
      {children}
    </TouchableHighlight>
  );
};

Button.defaultProps = {
  onPress: noop,
  onLongPress: noop,
  onLayout: noop,
};

export default Button;
