import { StyleSheet } from 'react-native';
import colors from '../../../styles/colors';
import { dimensions } from '../../../styles';

export default StyleSheet.create({
  contentContainer: {
    transform: [{ scaleX: -1 }],
    alignItems: 'flex-end',
  },
  itemContainer: {
    transform: [{ scaleX: -1 }],
    alignItems: 'center',
    paddingVertical: dimensions.halfMedium,
    paddingRight: dimensions.halfMedium,
    borderWidth: 1,
    borderColor: colors.grayLight,
    marginHorizontal: dimensions.halfMedium,
    borderRadius: 5,
    flexDirection: 'row',
  },
  listContentContainerStyle: {
    paddingHorizontal: dimensions.halfMedium,
  },
});
