/* eslint-disable react/destructuring-assignment,react/no-access-state-in-setstate */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as R from 'ramda';
import { Alert, Keyboard, View, ScrollView } from 'react-native';
import { lotsOperations } from '../../../../store/lots';
import { filterBadWords } from '../../../../utils/stringHelper';
import { Button, Separator, ItemDetailsButton, TouchableItem } from '../../../ReusableComponents';
import { ImagesSelector, TitleInput, DescriptionInput } from './components';
import s, { PostButtonContainer } from './styles';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import { colors, dimensions, rs } from '../../../../styles';
import { groupsSelectors } from '../../../../store/groups';
import { getAppLanguage } from '../../../../store/app/selectors';
import screens from '../../../../navigation/screens';
import { LoadingService, ModalsService, ToastsService } from '../../../../services';
import modalTypes from '../../../../constants/modalTypes';
import ruleTypes from '../../../../constants/ruleTypes';
import Title from './components/Title';
import withSelectedGroupId from './SelectedCommunityContainer';
import Text from '../../../ReusableComponents/Text';
import { isWeb } from '../../../../utils/detectDevice';

class EditWish extends Component {
  state = {
    isEditFlow: false,

    id: '',
    title: '',
    description: '',
    images: ['', '', '', ''],

    size: '',
    selectedCategory: {
      parentCategory: null,
      childCategory: null,
    },
  };

  async componentDidMount() {
    const { navigation, route } = this.props;

    navigation.setParams({
      onSubmit: this.onSubmit,
    });

    const wish = route.params.wish;

    if (wish) {
      this.setPropsFromWish(wish);
    }
  }

  componentDidUpdate(oldProps, oldState) {
    const newState = this.state;
    const newProps = this.props;
    if (
      !R.equals(oldState.title, newState.title) ||
      !R.equals(oldState.description, newState.description) ||
      !R.equals(oldState.selectedCategory, newState.selectedCategory)
    ) {
      this.checkValidation();
    }

    // if (!R.equals(oldProps.groupInfo.group_id, newProps.groupInfo.group_id)) {
    //   if (!newProps.groupInfo.has_wishes) {
    //     newProps.setSelectedCommunityId(newProps.communitiesWithWishes[0].group_id);
    //   }
    //
    //   this.onItemRulesApproveCheck();
    // }
  }

  setPropsFromWish = async (wish) => {
    const { setSelectedGroupId, route } = this.props;

    const images = route.params.images;

    const imgs = this.state.images;
    images.forEach((el, id) => {
      imgs[id] = el;
    });
    imgs.length = 4;

    const wishLocations = route.params.locations || [];
    const locations = wishLocations.map((el) => ({ ...el, isItemLocation: true }));

    this.setState({
      isEditFlow: true,
      ...wish,
      wishLocations: locations,
      images: imgs,
      title: wish.title,
      description: wish.description,
      id: wish.id,
      isPayForShipping: wish.pay_for_shipping,
      isPayForBoxit: wish.pay_for_boxit,
      size: wish.size,
      selectedCategory: {
        parentCategory: {
          id: R.propOr(null, 'parent_category_id', wish),
          name: R.propOr(null, 'parent_category_name', wish),
        },
        childCategory: {
          id: R.propOr(null, 'category_id', wish),
          name: R.propOr(null, 'category_name', wish),
        },
      },
    });

    setTimeout(() => {
      setSelectedGroupId(wish.group_id);
    }, 100);
  };

  onOpenHomWishes = () => {
    NavigationService.navigate(screens.Wishes);
    ToastsService.showSuccess(strings.success_messages.wish_uploaded_successfully);
  };

  onSubmit = async () => {
    const { navigation, editWish, createNewWish, selectedGroupId, route } = this.props;

    const {
      title,
      description,
      images,
      size,
      selectedCategory,
      id,
    } = this.state;

    if (route.params?.isValidate ?? false) {
      navigation.setParams({ isValidate: false }); // disable create same items
    } else {
      return;
    }

    Keyboard.dismiss();

    const submitWish = id ? editWish : createNewWish;

    LoadingService.showSuccessLoader();

    await submitWish({
      wishId: id,
      title,
      description,
      images,
      category_id: R.pathOr(null, ['childCategory', 'id'], selectedCategory),
      size,
      group_id: selectedGroupId,
    });

    LoadingService.hideSuccessLoader({
      callback: () => (id ? NavigationService.goBack() : this.onOpenHomWishes()),
    });
  };

  onInputChangesHandler = (value, stateName) => {
    this.setState((state, props) => ({
      [stateName]: props.appLanguage === 'en' ? filterBadWords(value) : value,
    }));
  };

  setImage = (images) => {
    this.setState({ images });
    this.checkValidation();
  };

  selectSize = (selectedSize) => {
    const { size } = this.state;

    this.setState({ size: selectedSize === size ? null : selectedSize });
  };

  onSetShipping = (shipping) => {
    const { locations, isPayForShipping, isPayForBoxit } = shipping;
    this.setState({ wishLocations: locations, isPayForShipping, isPayForBoxit });
    setTimeout(() => this.checkValidation(), 0);
  };

  checkValidation = () => {
    const { navigation } = this.props;
    const {
      title,
      description,
      images,
      selectedCategory,
      // wishLocations,
      // isPayForBoxit,
      // isPayForShipping,
    } = this.state;

    navigation.setParams({
      isValidate:
        title.length > 0 &&
        description.length > 0 &&
        images.some((el) => !!el) &&
        !!R.path(['parentCategory', 'id'], selectedCategory),
      // (!!wishLocations.length || isPayForShipping || isPayForBoxit),
    });
  };

  onOpenRules = () => {
    ModalsService.showFullScreenModal(modalTypes.RULES, {
      type: ruleTypes.WISH_RULES,
    });
  };

  onItemRulesApproveCheck = () => {
    const { groupInfo } = this.props;
    if (!groupInfo.is_approved_wish_rules) {
      ModalsService.showFullScreenModal(modalTypes.RULES, {
        type: ruleTypes.WISH_RULES,
        communityId: groupInfo.group_id,
      });
    }
  };

  onSelectCategory = (selectedCategory) => {
    this.setState({
      selectedCategory,
    });
  };

  onOpenCategories = () => {
    const { groupInfo } = this.props;
    const { selectedCategory } = this.state;

    NavigationService.navigate(screens.Categories, {
      selectedCategoryId: R.path(['childCategory', 'id'], selectedCategory),
      categories: groupInfo.categories,
      onSelectCategory: this.onSelectCategory,
    });
  };

  onSelectGroup = (groupId) => {
    const { setSelectedGroupId } = this.props;
    const { selectedCategory } = this.state;

    if (selectedCategory.parentCategory) {
      Alert.alert(
        strings.discounts.change_community_description,
        strings.lots.change_community_description,
        [
          {
            text: strings.common.ok,
            onPress: () => {
              this.setState({
                selectedCategory: {
                  parentCategory: null,
                  childCategory: null,
                },
              });
              setSelectedGroupId(groupId);

              NavigationService.pop();
            },
          },
          {
            text: strings.common.cancel,
            onPress: () => console.log('Cancel Pressed'),
            style: 'cancel',
          },
        ],
      );
    } else {
      setSelectedGroupId(groupId);
      NavigationService.pop();
    }
  };

  onOpenGroups = () => {
    const { groupInfo, groupsWithWishes } = this.props;

    NavigationService.navigate(screens.Groups, {
      selectedGroupId: groupInfo.id,
      groups: groupsWithWishes,
      onSelectGroup: this.onSelectGroup,
    });
  };

  render() {
    const { groupInfo, route } = this.props;
    const {
      isEditFlow,

      images,
      title,
      description,
      selectedCategory,
    } = this.state;

    const Wrapper = isWeb ? ScrollView : KeyboardAwareScrollView;

    return (
      <Wrapper
        extraScrollHeight={dimensions.medium}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="handled"
        style={{ backgroundColor: colors.grayLighter }}
        contentContainerStyle={isWeb && rs.webContainer}
      >
        <TouchableItem onPress={this.onOpenRules} style={s.rulesContainer}>
          <Text color={colors.blueLight}>{strings.rules.wish_rules_title}</Text>
        </TouchableItem>

        <ImagesSelector images={images} setImage={this.setImage} />

        <Title withPadding>{strings.lots.wish_description}</Title>

        <TitleInput
          value={title}
          placeholder={strings.lots.what_is_your_wish}
          onChangeText={(name) => this.onInputChangesHandler(name, 'title')}
        />
        <View style={rs.paddingHorizontal}>
          <Separator />
        </View>

        <DescriptionInput
          value={description}
          placeholder={strings.lots.describe_your_wish}
          onChangeText={(name) => this.onInputChangesHandler(name, 'description')}
        />

        <Title withPadding>{strings.lots.details}</Title>

        {!isEditFlow && (
          <Fragment>
            <ItemDetailsButton
              title={strings.lots.group}
              placeholder={strings.filters.select_group}
              selectedValue={groupInfo.group_name}
              onPress={this.onOpenGroups}
            />
            <View style={rs.paddingHorizontal}>
              <Separator />
            </View>
          </Fragment>
        )}

        <ItemDetailsButton
          title={strings.lots.category}
          placeholder={strings.filters.select_category}
          selectedValue={
            selectedCategory.parentCategory
              ? `${selectedCategory.parentCategory.name} : ${selectedCategory.childCategory.name}`
              : ''
          }
          onPress={this.onOpenCategories}
        />

        <PostButtonContainer>
          <Button
            disabled={!route.params?.isValidate ?? false}
            onPress={this.onSubmit}
            title={isEditFlow ? strings.common.save : strings.lots.create_lot_button}
          />
        </PostButtonContainer>
      </Wrapper>
    );
  }
}

const EditWishWithSelectedCommunityId = withSelectedGroupId(
  connect(
    (state, { selectedGroupId }) => ({
      userInfo: state.userInfo,
      groupInfo: groupsSelectors.getGroupInfoById(state, selectedGroupId),

      groupsWithWishes: groupsSelectors.getCommunityGroupsWithWishes(state),

      savedLocations: state.shipping.locations,
      isLoadingManagementLot: state.lots.isLoadingManagementLot,
      appLanguage: getAppLanguage(state),
    }),
    (dispatch) => ({
      editWish: (args) => dispatch(lotsOperations.editWish(args)),
      createNewWish: (args) => dispatch(lotsOperations.createNewWish(args)),
    }),
  )(EditWish),
);

export default EditWishWithSelectedCommunityId;
