import React, { useState } from 'react';
import { Image, Keyboard, ScrollView } from 'react-native';
import T from 'prop-types';
import {
  InputWithTitleAndValidation,
  Container,
  View,
  Button,
  ScreenHeader,
  SquareCheckBox,
  Text,
  TouchableItem,
} from '../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../styles';
import { AnalyticsService, LoadingService } from '../../../../services';
import strings from '../../../../localization';
import { KeyboardAvoidingView, StyleSheet } from 'react-native';
import { isIos, isIphoneX } from '../../../../utils/detectDevice';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { userOperations } from '../../../../store/userInfo';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';

const s = StyleSheet.create({
  inputStyle: {
    color: colors.blueLight,
  },
  image: {
    width: 150,
    height: 115,
    alignSelf: 'center',
    marginTop: dimensions.doubleMedium,
    marginBottom: dimensions.doubleMedium,
  },
  inputContainer: {
    backgroundColor: colors.grayLighter,
    paddingHorizontal: dimensions.medium,
    paddingVertical: isIos ? dimensions.halfMedium : 0,
  },
  buttonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.halfMedium,
    marginBottom: isIphoneX ? dimensions.doubleMedium : dimensions.medium,
  },
  infoText: {
    lineHeight: 22,
  },
  keyboardAvoidingView: {
    flex: 1,
    marginBottom: isIos ? dimensions.doubleMedium : 0,
  },
  input: {
    textAlign: 'left',
  },
  checkBoxContainer: {
    width: '90%',
    paddingHorizontal: dimensions.halfMedium,
  },
});

const AddEmail = ({ userInfo, updateUserInfo }) => {
  const [email, setEmail] = useState(userInfo.email);
  const [isValidEmail, setValidEmail] = useState(false);
  const [isActiveEmailSubscription, setActiveEmailSubscription] = useState(
    !!userInfo.has_email_subscription,
  );

  const onSubmit = async () => {
    LoadingService.showLoader();
    await updateUserInfo({
      email,
      userName: userInfo.name,
      userAvatar: userInfo.profile_image,
      gender: userInfo.gender,
      hasEmailSubscription: isActiveEmailSubscription,
    });
    LoadingService.hideLoader();
    Keyboard.dismiss();
    AnalyticsService.logEvent(analyticsEventTypes.earn_points_add_email);
  };

  const onChangeEmail = async (value) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const isInvalid = !reg.test(value) && !R.isEmpty(value);

    setValidEmail(!isInvalid);
    setEmail(value);
  };
  const onChangeActiveEmailSubscription = async () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const isInvalid = !reg.test(email) && !R.isEmpty(email);

    setValidEmail(!isInvalid);
    setActiveEmailSubscription(!isActiveEmailSubscription);
  };

  return (
    <Container>
      <KeyboardAvoidingView
        keyboardShouldPersistTabs="handled"
        behavior={isIos ? 'padding' : null}
        style={s.keyboardAvoidingView}
      >
        <ScreenHeader withoutBorder headerTitle={strings.user_info.email_address} />
        <ScrollView keyboardShouldPersistTabs="handled">
          <Image style={s.image} source={{ uri: 'email' }} resizeMode="contain" />

          <InputWithTitleAndValidation
            inputStyle={s.input}
            inputContainerStyle={s.inputContainer}
            autoCapitalize="none"
            maxLength={30}
            minLength={5}
            placeholder={strings.user_info.email_placeholder}
            onChangeText={onChangeEmail}
            value={email}
            underlineColorAndroid="transparent"
          />
          <View style={s.checkBoxContainer}>
            <TouchableItem
              onPress={onChangeActiveEmailSubscription}
              style={[rs.row, rs.alignCenter]}
            >
              <SquareCheckBox selected={isActiveEmailSubscription} style={rs.marginRight} />

              <Text style={s.infoText}>{strings.user_info.email_placeholder_checkbox}</Text>
            </TouchableItem>
          </View>

          <Text style={[rs.paddingHorizontal, s.infoText, rs.paddingTop]}>
            {strings.user_info.email_description}
          </Text>
        </ScrollView>

        <View style={s.buttonContainer}>
          <Button title={strings.common.save} onPress={onSubmit} disabled={!isValidEmail} />
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

AddEmail.propTypes = {
  userInfo: T.object.isRequired,
  checkNicknameAvailability: T.func.isRequired,
  updateNickname: T.func.isRequired,
};

export default connect(
  (state) => ({
    userInfo: state.userInfo,
  }),
  (dispatch) => ({
    updateUserInfo: (params) => dispatch(userOperations.updateUserInfo(params)),
  }),
)(AddEmail);
