import React, { Fragment } from 'react';
import styled from 'styled-components/native';
import Text from './Text';
import { colors, dimensions, fontSizes } from '../../styles';

const ButtonContainer = styled.TouchableOpacity`
  padding-horizontal: ${dimensions.halfMedium};
  min-width: 56px;
  height: ${dimensions.commonHeight};
  align-items: center;
  justify-content: center;
  background-color: ${({ disabled }) => (disabled ? colors.grayLighter : colors.blueLighter)};
  border-radius: 4px;
  margin-right: ${({ withMarginRight }) => (withMarginRight ? dimensions.halfMedium : 0)};
`;

const ButtonText = styled(Text)`
  color: ${({ disabled }) => (disabled ? colors.gray : colors.white)};
  font-size: ${fontSizes.xsmall};
`;

const SecondaryButtonContainer = styled(ButtonContainer)`
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${({ disabled }) => (disabled ? colors.grayLighter : colors.blueLighter)};
  margin-right: ${({ withMarginRight }) => (withMarginRight ? dimensions.halfMedium : 0)};
`;

const SecondaryButtonText = styled(ButtonText)`
  color: ${({ disabled }) => (disabled ? colors.gray : colors.blueLighter)};
`;

const Button = ({ disabled, title, onPress, withMarginRight, secondaryType = false }) => (
  <Fragment>
    {secondaryType ? (
      <SecondaryButtonContainer
        disabled={disabled}
        withMarginRight={withMarginRight}
        onPress={onPress}
      >
        <SecondaryButtonText disabled={disabled}>{title}</SecondaryButtonText>
      </SecondaryButtonContainer>
    ) : (
      <ButtonContainer disabled={disabled} withMarginRight={withMarginRight} onPress={onPress}>
        <ButtonText disabled={disabled}>{title}</ButtonText>
      </ButtonContainer>
    )}
  </Fragment>
);

export default Button;
