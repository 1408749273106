import React, { memo, Fragment } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import { View, FlatList, StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../../styles';
import { Text, TouchableItem } from '../../../../ReusableComponents';
import SectionHeader from './SectionHeader';

const s = StyleSheet.create({
  contentContainer: {
    transform: [{ scaleX: -1 }],
    alignItems: 'flex-end',
  },
  listContentContainerStyle: {
    paddingHorizontal: dimensions.halfMedium,
  },
  itemButton: {
    minWidth: 65,
    paddingVertical: dimensions.halfMedium,
    paddingHorizontal: dimensions.medium,
    marginRight: dimensions.halfMedium,
    borderRadius: 3,
    backgroundColor: colors.grayLighter,
    transform: [{ scaleX: -1 }],
  },
  activeButton: {
    backgroundColor: colors.blueLight,
  },
});

const ListItem = memo(({ isSelected, item, onSelect }) => {
  const title = R.is(Object, item) ? item.label : item;

  const onPress = () => {
    const value = R.is(Object, item) ? item.id : item;

    onSelect(value);
  };

  return (
    <TouchableItem onPress={onPress} style={[s.itemButton, isSelected && s.activeButton]}>
      <Text alignCenter color={isSelected ? colors.white : colors.grayBorder}>
        {title}
      </Text>
    </TouchableItem>
  );
});

const HorizontalSelectionList = memo(
  ({ data, selectedItem, onSelect, icon, title, description }) => {
    return (
      <Fragment>
        <SectionHeader icon={icon} title={title} description={description} />
        <View style={s.contentContainer}>
          <FlatList
            horizontal
            inverted
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={s.listContentContainerStyle}
            data={data}
            keyExtractor={(item) => R.is(Object, item) ? item.id.toString() : item.toString()}
            renderItem={({ item }) => {
              const isSelected = R.is(Object, item)
                ? selectedItem === item.id
                : selectedItem === item;

              return <ListItem item={item} onSelect={onSelect} isSelected={isSelected} />;
            }}
          />
        </View>
      </Fragment>
    );
  },
);

HorizontalSelectionList.propTypes = {
  data: T.array,
  selectedItem: T.any,
  onSelect: T.func,
  icon: T.string,
  title: T.string,
  description: T.string,
};

export default HorizontalSelectionList;
