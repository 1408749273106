import { createAction } from 'redux-actions';
import types from './types';

const saveGroupForNextLot = createAction(types.SAVE_GROUP_FOR_NEXT_LOT);
const saveRefundPolicyForNextLot = createAction(types.SAVE_REFUND_POLICY_FOR_NEXT_LOT);

export default {
  saveGroupForNextLot,
  saveRefundPolicyForNextLot,
};
