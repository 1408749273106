import React, { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { FlatList, RefreshControl, StyleSheet } from 'react-native';
import { TabView } from 'react-native-tab-view';
import T from 'prop-types';
import { chatsOperations, chatsSelectors } from '../../../../store/chats';
import {
  Container,
  TabBar,
  Badge,
  Spinner,
  View,
  EmptyListWithImage,
  Text,
} from '../../../ReusableComponents';
import ChatsList from './components/ChatsList';
import NotificationItem from './components/NotificationItem';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import { dimensions, rs } from '../../../../styles';
import screens from '../../../../navigation/screens';
import notificationOperation from '../../../../store/notifications/operations';
import { getNotifications } from '../../../../store/notifications/selectors';
import { usePrevious } from '../../../../utils/hooks';
import { getParamOr } from '../../../../utils/navHelper';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useIsFocused } from '@react-navigation/native';
import { communitySelectors } from '../../../../store/communityInfo';
import { isWeb } from '../../../../utils/detectDevice';

const s = StyleSheet.create({
  tabViewInitialLayout: {
    height: 0,
    width: dimensions.width,
  },
});

const Chats = ({
  chats,
  supportChats,

  isAdmin,
  isLoadingChats,
  isLoadingMoreChats,
  isLoadingSupportChats,
  isLoadingMoreSupportChats,

  unreadCountChats,
  unreadCountSupportChats,
  unreadCountNotifications,

  getAllChats,
  getSupportChatsForAdmins,
  getUnreadCountForAllChats,
  getUnreadCountForAdminSupportChats,
  archiveChats,
  navigation,

  notifications,
  isLoadingNotifications,
  isLoadingMoreNotifications,
  isRefreshingNotifications,
  onLoadMore,
  onRefreshNotifications,
  onPressNotification,
  onMarkNotificationAsOpen,
  onMarkNotificationsAsRead,
  language,
  currentUserId,
  route,
}) => {
  const isFocused = useIsFocused();
  const { showActionSheetWithOptions } = useActionSheet();
  const isEditMode = route.params?.isEditMode ?? false;

  const [index, setIndex] = useState(0);
  const [isRefreshing, setRefreshing] = useState(false);
  const [selectedChatIds, setSelectedChatIds] = useState([]);

  useEffect(() => {
    navigation.setParams({
      onDonePress: () => {
        setSelectedChatIds([]);
        navigation.setParams({ isEditMode: false, selectedChatsLength: 0 });
      },
      onArchivePress: () => {
        setSelectedChatIds([]);
        navigation.setParams({ isEditMode: false, selectedChatsLength: 0 });
        archiveChats(selectedChatIds);
      },
    });
  }, [selectedChatIds]);

  const prevProps = usePrevious({ isFocused });

  useEffect(() => {
    if (isFocused && index === 0) onMarkNotificationsAsRead();

    if (R.prop('isFocused', prevProps) && !isFocused && index === 0) {
      onMarkNotificationsAsRead();
    }
  }, [isFocused]);

  useEffect(() => {
    navigation.setParams({
      onOpenActionSheet,
      tabIndex: index,
    });
  }, []);

  const onChangeTabIndex = (tabIndex) => {
    navigation.setParams({
      tabIndex,
    });

    setIndex(tabIndex);
  };

  const onOpenActionSheet = useCallback(() => {
    const options = [
      strings.chats.add_chats_to_archive,
      strings.chats.archived_chats,
      strings.common.cancel,
    ];

    const cancelButtonIndex = options.length - 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          navigation.setParams({ isEditMode: true });
        }
        if (buttonIndex === 1) {
          NavigationService.navigate(screens.ArchivedChats);
        }
      },
    );
  }, []);

  const onNotificationPress = (notification) => {
    const { action_id, action_description, id } = notification;

    onMarkNotificationAsOpen(id);
    onPressNotification({ action_id, action_description: JSON.stringify(action_description) });
  };

  const onSelectChat = (chatId) => {
    if (!isEditMode) {
      NavigationService.navigate(screens.ChatRoom, { chatId });
      return;
    }
    let newSelectedChatIds = null;

    if (R.includes(chatId, selectedChatIds)) {
      newSelectedChatIds = selectedChatIds.filter((element) => element !== chatId);
    } else {
      newSelectedChatIds = [...selectedChatIds, chatId];
    }

    setSelectedChatIds(newSelectedChatIds);
    navigation.setParams({ selectedChatsLength: newSelectedChatIds.length });
  };

  const onOpenUserProfile = (userId, name, avatar) => {
    NavigationService.navigate(screens.UserProfile, {
      name,
      userId,
      profile_image: avatar,
    });
  };

  const renderLazyPlaceholder = () => <Spinner />;

  const onRefresh = async () => {
    setRefreshing(true);
    await getAllChats();
    if (isAdmin) {
      await getSupportChatsForAdmins();
      getUnreadCountForAdminSupportChats();
    }
    getUnreadCountForAllChats();
    setRefreshing(false);
  };

  const routes = [
    { key: 'notifications', title: strings.notifications.notifications },
    { key: 'chats', title: strings.chats.chats },
  ];

  if (isAdmin) {
    routes.push({ key: 'admin_support', title: strings.chats.admin_support });
  }

  return (
    <Container>
      <TabView
        lazy
        renderLazyPlaceholder={renderLazyPlaceholder}
        initialLayout={s.tabViewInitialLayout}
        renderTabBar={(props) => (
          <TabBar
            {...props}
            renderLabel={({ route, focused, color }) => (
              <View style={[rs.row, rs.alignCenter]}>
                <Text style={{ color }}>{route.title}</Text>
                <Badge
                  count={
                    {
                      notifications: unreadCountNotifications,
                      chats: unreadCountChats,
                      admin_support: unreadCountSupportChats,
                    }[route.key]
                  }
                />
              </View>
            )}
          />
        )}
        navigationState={{ index, routes }}
        onIndexChange={onChangeTabIndex}
        renderScene={({ route }) => {
          switch (route.key) {
            case 'admin_support':
              return (
                <ChatsList
                  isEditMode={isEditMode}
                  selectedChatIds={selectedChatIds}
                  contentContainerStyle={isWeb && rs.webContainer}
                  isLoading={isLoadingSupportChats}
                  isLoadingMore={isLoadingMoreSupportChats}
                  chatList={supportChats}
                  onOpenChat={onSelectChat}
                  onOpenUserProfile={onOpenUserProfile}
                  onLoadMore={() => getSupportChatsForAdmins(true)}
                  isRefreshing={isRefreshing}
                  onRefresh={onRefresh}
                  ListEmptyComponent={() => (
                    <EmptyListWithImage
                      image="empty_state_chats"
                      text={strings.emptyLists.empty_buy_chat_list}
                    />
                  )}
                />
              );
            case 'chats':
              return (
                <ChatsList
                  isEditMode={isEditMode}
                  selectedChatIds={selectedChatIds}
                  isLoading={isLoadingChats}
                  isLoadingMore={isLoadingMoreChats}
                  chatList={chats}
                  onOpenChat={onSelectChat}
                  onOpenUserProfile={onOpenUserProfile}
                  onLoadMore={() => getAllChats(true)}
                  isRefreshing={isRefreshing}
                  onRefresh={onRefresh}
                  ListEmptyComponent={() => (
                    <EmptyListWithImage
                      image="empty_state_chats"
                      text={strings.emptyLists.empty_buy_chat_list}
                    />
                  )}
                />
              );
            case 'notifications':
              return (
                <FlatList
                  data={notifications}
                  keyExtractor={(item) => item.id.toString()}
                  refreshControl={
                    <RefreshControl
                      refreshing={isRefreshingNotifications}
                      onRefresh={onRefreshNotifications}
                    />
                  }
                  refreshing={isRefreshingNotifications}
                  onEndReachedThreshold={0.7}
                  onEndReached={onLoadMore}
                  contentContainerStyle={isWeb && rs.webContainer}
                  ListFooterComponent={isLoadingMoreNotifications && <Spinner />}
                  ListEmptyComponent={
                    isLoadingNotifications ? (
                      <Spinner />
                    ) : (
                      <EmptyListWithImage
                        image="empty_state_notifications"
                        text={strings.emptyLists.empty_notifications_list}
                      />
                    )
                  }
                  renderItem={({ item }) => (
                    <NotificationItem
                      notification={item}
                      language={language}
                      onPress={onNotificationPress}
                    />
                  )}
                />
              );
            default:
              return null;
          }
        }}
      />
    </Container>
  );
};

Chats.propTypes = {
  chats: T.array,

  isLoadingChats: T.bool,
  isLoadingMoreChats: T.bool,

  isUnreadChats: T.bool,
  isUnreadInSellTab: T.bool,
  isUnreadInPersonalTab: T.bool,

  getAllChats: T.func,
  getSupportChatsForAdmins: T.func,

  getUnreadCountForAllChats: T.func,
  getUnreadCountForAdminSupportChats: T.func,
  archiveChats: T.func,
};

export default connect(
  (state) => ({
    isLoadingChats: state.chats.allChats.isLoading,
    isAdmin: communitySelectors.getIsUserAdmin(state),
    isLoadingMoreChats: state.chats.allChats.isLoadingMore,
    unreadCountChats: state.chats.totalUnreadCount,
    unreadCountSupportChats: state.chats.adminSupportChatsTotalUnreadCount,
    chats: chatsSelectors.getChats(state),

    isLoadingSupportChats: state.chats.adminSupportChats.isLoading,
    isLoadingMoreSupportChats: state.chats.adminSupportChats.isLoadingMore,
    supportChats: chatsSelectors.getSupportChatsForAdmin(state),

    notifications: getNotifications(state),
    unreadCountNotifications: state.notifications.unreadCount,

    isLoadingNotifications: state.notifications.isLoading,
    isLoadingMoreNotifications: state.notifications.isLoadingMore,
    isRefreshingNotifications: state.notifications.isRefreshing,
    language: state.app.language,
    currentUserId: state.userInfo.id,
  }),
  (dispatch) => ({
    getAllChats: (isLoadMore) => dispatch(chatsOperations.getAllChats(isLoadMore)),
    getSupportChatsForAdmins: (isLoadMore) =>
      dispatch(chatsOperations.getSupportChatsForAdmins({ isLoadMore })),
    getUnreadCountForAllChats: () => dispatch(chatsOperations.getUnreadCountForAllChats()),
    getUnreadCountForAdminSupportChats: () =>
      dispatch(chatsOperations.getUnreadCountForAdminSupportChats()),
    archiveChats: (chatIds) => dispatch(chatsOperations.archiveChats(chatIds)),

    onLoadMore: () => dispatch(notificationOperation.getMoreNotifications()),
    onRefreshNotifications: () => dispatch(notificationOperation.getNotifications(true)),
    onPressNotification: (notificationData) =>
      dispatch(notificationOperation.onPressNotification(notificationData)),
    onMarkNotificationsAsRead: () => dispatch(notificationOperation.onMarkNotificationsAsRead()),
    onMarkNotificationAsOpen: (notificationId) =>
      dispatch(notificationOperation.onMarkNotificationAsOpen(notificationId)),
  }),
)(Chats);
