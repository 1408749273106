import React, { memo } from 'react';
import T from 'prop-types';
import { View } from 'react-native';
import DoubleColumnListItem from '../../../ReusableComponents/ListItems/Lots/DoubleColumnsLotListItem';
import TouchableItem from '../../../ReusableComponents/TouchableItem';
import itemTypes from '../../../../constants/itemTypes';
import { colors } from '../../../../styles';
import s from '../styles';
import { Text } from '../../../ReusableComponents';

const ListItem = memo(({ item, index, isSelected, userId, onSelect }) => {
  return (
    <TouchableItem onPress={() => onSelect(item)}>
      <DoubleColumnListItem
        lot={item}
        onPress={onSelect}
        type={itemTypes.ACTIVE}
        currentUserId={userId}
        isSoldItem={false}
        withoutOpacity={false}
      />

      <View style={[s.circle, isSelected && s.circleSelected]}>
        {isSelected && (
          <Text color={colors.white} semiBold xsmall>
            {index}
          </Text>
        )}
      </View>
    </TouchableItem>
  );
});

ListItem.propTypes = {
  item: T.shape({}).isRequired,
  isSelected: T.bool.isRequired,
  userId: T.number.isRequired,
  onSelect: T.func.isRequired,

  index: T.number,
};

ListItem.defaultProps = {
  index: null,
};

export default ListItem;
