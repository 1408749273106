import { ENV } from './env';

let baseUrl = '';
let chatServiceUrl = '';

if (ENV === 'local') {
  baseUrl = 'http://localhost:3000/api';
  chatServiceUrl = 'https://wsg-dev.shareittapp.com/';
}

if (ENV === 'development') {
  baseUrl = 'https://dev.shareittapp.com/api';
  chatServiceUrl = 'https://wsg-dev.shareittapp.com/';
}

if (ENV === 'staging') {
  baseUrl = 'https://staging.shareittapp.com/api';
  chatServiceUrl = 'https://wsg-staging.shareittapp.com/';
}

if (ENV === 'production') {
  baseUrl = 'https://prod.shareittapp.com/api';
  chatServiceUrl = 'https://wsg.shareittapp.com/';
}

export const BASE_URL = baseUrl;
export const CHAT_SERVICE_URL = chatServiceUrl;
