import dynamicLinks from '@react-native-firebase/dynamic-links';
import { Alert, Share } from 'react-native';
import {
  APP_STORE_ID,
  BUNDLE_ID,
  PACKAGE_NAME,
  FIREBASE_DYNAMICAL_LINK_PREFIX,
} from '../../config/identifiers';
import { leftToRightIsolate } from './stringHelper';
import { isAndroid } from './detectDevice';

export const createDynamicLink = async ({
  actionType,
  id,
  communityId,
  socialImageUrl = '',
  socialTitle = '',
  socialDescription = '',
}) => {
  const link = await dynamicLinks().buildShortLink({
    link: `https://shareitt.com/?actionType=${actionType}&id=${id}&communityId=${communityId}`,
    domainUriPrefix: FIREBASE_DYNAMICAL_LINK_PREFIX,
    analytics: {
      campaign: 'banner',
    },
    social: {
      descriptionText: socialDescription,
      imageUrl: socialImageUrl,
      title: socialTitle,
    },
    ios: {
      bundleId: BUNDLE_ID,
      appStoreId: APP_STORE_ID,
    },
    android: {
      packageName: PACKAGE_NAME,
    },
    navigation: {
      forcedRedirectEnabled: true,
    },
  });

  return link;
};

export const shareDynamicLink = async ({
  actionType,
  id,
  communityId,
  socialImageUrl = '',
  socialTitle = '',
  socialDescription = '',
  linkDescription = '',
}) => {
  const link = await createDynamicLink({
    actionType,
    id,
    communityId,
    socialImageUrl,
    socialTitle,
    socialDescription,
  });

  try {
    const result = await Share.share({
      message: `${linkDescription} ${leftToRightIsolate} ${isAndroid ? link : ''}`,
      url: link,
    });

    if (result.action === Share.sharedAction) {
      if (result.activityType) {
        // shared with activity type of result.activityType
      } else {
        // shared
      }
    } else if (result.action === Share.dismissedAction) {
      // dismissed
    }
  } catch (error) {
    Alert.alert('Opps', error.message);
  }
};

export const shareReferralDynamicLink = async ({
  actionType,
  referralCode,
  socialImageUrl = '',
  socialTitle = '',
  socialDescription = '',
  linkDescription = '',
}) => {
  const link = await dynamicLinks().buildShortLink({
    link: `https://shareitt.com/?actionType=${actionType}&referral_code=${referralCode}`,
    domainUriPrefix: FIREBASE_DYNAMICAL_LINK_PREFIX,
    analytics: {
      campaign: 'banner',
    },
    social: {
      descriptionText: socialDescription,
      imageUrl: socialImageUrl,
      title: socialTitle,
    },
    ios: {
      bundleId: BUNDLE_ID,
      appStoreId: APP_STORE_ID,
    },
    android: {
      packageName: PACKAGE_NAME,
    },
    navigation: {
      forcedRedirectEnabled: true,
    },
  });

  try {
    const result = await Share.share({
      message: `${linkDescription} ${leftToRightIsolate} ${isAndroid ? link : ''}`,
      url: link,
    });

    if (result.action === Share.sharedAction) {
      if (result.activityType) {
        // shared with activity type of result.activityType
      } else {
        // shared
      }
    } else if (result.action === Share.dismissedAction) {
      // dismissed
    }
  } catch (error) {
    Alert.alert('Opps', error.message);
  }
};

export const shareExistingLink = async (link, description) => {
  try {
    await Share.share({
      message: `${description} ${leftToRightIsolate} ${isAndroid ? link : ''}`,
      url: link,
    });
  } catch (error) {
    Alert.alert('Opps', error.message);
  }
};
