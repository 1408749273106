import { createSelector } from 'reselect';
import * as R from 'ramda';
import { getFirstLetterFrom } from '../../utils/stringHelper';
import { getBrandsListForFilteredGroup } from '../groups/selectors';

const getContactsList = state => R.pathOr([], ['AppData', 'contactList'], state);
const getSearchValue = (_, searchValue) => searchValue;

export const getContactsSectionList = createSelector(
  [getContactsList, getSearchValue],

  (contactsList, searchValue) => {
    const sections = {};

    contactsList.forEach(contact => {
      if (contact.full_name.indexOf(searchValue) === -1) return;
      const letter = getFirstLetterFrom(contact.full_name);
      sections[letter] = sections[letter] ? [...sections[letter], contact] : [contact];
    });

    const sectionList = [];

    R.forEachObjIndexed((val, key) => {
      sectionList.push({ title: key, data: val });
    }, sections);

    return sectionList;
  },
);

export const getBrandsSectionList = createSelector(
  [getBrandsListForFilteredGroup, getSearchValue],

  (brandsList, searchValue) => {
    const sections = {};

    const sortByName = R.sortBy(
      R.compose(
        R.toLower,
        R.prop('name'),
      ),
    );

    sortByName(brandsList).forEach(brand => {
      if (brand.name.toLowerCase().indexOf(searchValue.toLowerCase()) === -1) return;
      const letter = getFirstLetterFrom(brand.name);
      sections[letter] = sections[letter] ? [...sections[letter], brand] : [brand];
    });
    const sectionList = [];

    R.forEachObjIndexed((val, key) => {
      sectionList.push({ title: key, data: val });
    }, sections);

    return sectionList;
  },
);

export const getSelectedCommunityBrandsSectionList = createSelector(
  [({ brands }) => brands, ({ searchValue }) => searchValue],

  (brandsList, searchValue) => {
    const sections = {};

    const sortByName = R.sortBy(
      R.compose(
        R.toLower,
        R.prop('name'),
      ),
    );

    sortByName(brandsList).forEach(brand => {
      if (brand.name.toLowerCase().indexOf(searchValue.toLowerCase()) === -1) return;
      const letter = getFirstLetterFrom(brand.name);
      sections[letter] = sections[letter] ? [...sections[letter], brand] : [brand];
    });
    const sectionList = [];

    R.forEachObjIndexed((val, key) => {
      sectionList.push({ title: key, data: val });
    }, sections);

    return sectionList;
  },
);
