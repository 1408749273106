import searchesApi from '../../api/search';
import lotTypes from '../../constants/lotTypes';
import { SHIPPING_GROUP_ID } from '../../constants/excludedGroupIds';
import * as actions from './actions';

const getGroupItemsTitlesSuggestions = (searchTerm) => async (dispatch, getState) => {
  const { lots } = getState();
  const groupIds = lots.feedLotFilters.groupIds;
  try {
    return await searchesApi.getGroupItemsTitlesSuggestions(groupIds, searchTerm);
  } catch (err) {}
};

const getWishesTitlesSuggestions = (searchTerm) => async (dispatch, getState) => {
  try {
    return await searchesApi.getWishesTitlesSuggestions({ searchTerm });
  } catch (err) {}
};

const getItemsSearchHistory = () => async (dispatch, getState) => {
  try {
    const { groupIds } = getState().lots.feedLotFilters;
    const withShipping = groupIds.includes(SHIPPING_GROUP_ID);

    const searchResult = await searchesApi.getRecentSearches(lotTypes.ITEM, withShipping);
    return dispatch(actions.setItemsSearchHistory(searchResult));
  } catch (err) {}
};

const getWishesSearchHistory = () => async (dispatch, getState) => {
  try {
    const searchResult = await searchesApi.getRecentSearches(lotTypes.WISH);

    return dispatch(actions.setWishesSearchHistory(searchResult));
  } catch (err) {}
};

const removeItemSearchTerm = (searchTermId) => async (dispatch, getState) => {
  try {
    await searchesApi.removeSearchTerm(searchTermId);
    return dispatch(actions.removeItemSearchTerm(searchTermId));
  } catch (err) {}
};

const removeWishSearchTerm = (searchTermId) => async (dispatch, getState) => {
  try {
    await searchesApi.removeSearchTerm(searchTermId);
    return dispatch(actions.removeWishSearchTerm(searchTermId));
  } catch (err) {}
};

export default {
  getItemsSearchHistory,
  getWishesSearchHistory,
  removeItemSearchTerm,
  removeWishSearchTerm,
  getGroupItemsTitlesSuggestions,
  getWishesTitlesSuggestions,
};
