import * as actions from './actions';
import { normalize } from '../../utils/stateHelper';
import communitiesApi from '../../api/communities';
import { COMMON_LIMIT } from '../../constants/listLimits';

export const getCommunityCities = ({ searchTerm = '', isLoadMore }) => async (
  dispatch,
  getState,
) => {
  const { communityInfo, communityCities } = getState();
  const { cityIds, isLoading, isLoadingMore, isExistMore } = communityCities;
  if (isLoading || isLoadingMore || (isLoadMore && !isExistMore)) return;

  dispatch(actions.citiesStart({ isLoadMore }));

  const skip = isLoadMore ? cityIds.length : 0;

  try {
    const data = await communitiesApi.getCities({
      communityId: communityInfo.id,
      skip,
      searchTerm,
    });

    const normalizedData = normalize(data, 'cityIds', 'cityEntities');

    dispatch(
      actions.citiesSuccess({
        ...normalizedData,
        isLoadMore,
        isExistMore: data.length === COMMON_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(actions.citiesError({ isLoadMore }));
  }
};

export default {
  getCommunityCities,
};
