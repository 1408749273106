import React from 'react';
import T from 'prop-types';
import { StyleSheet, View, Image } from 'react-native';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import strings from '../../../../localization';
import Text from '../../Text';
import Button from '../../Buttons/Button';
import Icon from '../../Icon';
import TouchableItem from '../../TouchableItem';
import { MainContainer } from './styles';
import { colors, dimensions, rs } from '../../../../styles';

const s = StyleSheet.create({
  imageContainer: {
    backgroundColor: colors.white,
    alignItems: 'center',
    position: 'absolute',
    top: -50,
    width: 120,
    height: 120,
    borderRadius: 60,
  },
  image: {
    width: 120,
    height: 120,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
  title: {
    paddingTop: 50,
  },
  buttonContainer: {
    width: '100%',
    height: 40,
    marginTop: 20,
    paddingHorizontal: dimensions.doubleMedium,
  },
});

const AchievedPersonalRewardDetails = ({ params, onPress: onClose }) => {
  const onPressGoToMarketplace = () => {
    NavigationService.navigate(screens.MarketplaceTab, { screen: screens.Marketplace });
    onClose();
  };

  return (
    <View style={rs.alignCenter}>
      <View style={s.imageContainer}>
        <Image source={{ uri: params.achieved_icon_url }} style={s.image} />
      </View>

      <MainContainer>
        <TouchableItem onPress={onClose} style={s.closeButton}>
          <Icon
            name="x"
            size={16}
            color={colors.black}
            style={[rs.paddingVertical, rs.paddingHorizontal]}
          />
        </TouchableItem>

        <Text color={colors.text} style={s.title} headline extraBold alignCenter>
          {params.title.toUpperCase()}
        </Text>

        <Text color={colors.blueLight} style={rs.bigPaddingTop} extraBold large alignCenter>
          {strings.notifications.earned.replace('XX', params.given_reward_amount)}
        </Text>

        <View style={s.buttonContainer}>
          <Button title={strings.emptyLists.go_to_marketplace} onPress={onPressGoToMarketplace} />
        </View>
      </MainContainer>
    </View>
  );
};

AchievedPersonalRewardDetails.propTypes = {
  params: T.shape({
    title: T.string,
    achieved_icon_url: T.string,
    given_reward_amount: T.number,
  }).isRequired,
  onPress: T.func.isRequired,
};

export default AchievedPersonalRewardDetails;
