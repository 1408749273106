import deepMerge from 'deepmerge';

const processChange = (change, state, action) =>
  typeof change === 'object' ? change : change(action, state);

export const mergeIn = change => (state, action) => ({
  ...state,
  ...processChange(change, state, action),
});

export const mergeDeep = change => (state, action) => ({
  ...deepMerge(state, processChange(change, state, action), {
    arrayMerge: (destinationArray, sourceArray) => sourceArray,
  }),
});

export const normalize = (arr, idsArrName, entitiesName) => {
  let entities = {};

  const idsArr = arr.map(item => {
    entities = {
      ...entities,
      [item.id]: item,
    };
    return item.id;
  });
  return { [idsArrName]: idsArr, [entitiesName]: entities };
};

export const normalizeCommunities = (arr, idsArrName, entitiesName) => {
  let entities = {};

  const idsArr = arr.map(item => {
    entities = {
      ...entities,
      [item.group_id]: item,
    };
    return item.group_id;
  });
  return { [idsArrName]: idsArr, [entitiesName]: entities };
};
