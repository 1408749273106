import { StyleSheet } from 'react-native';
import { dimensions, fontSizes } from '../../../../styles';

export default StyleSheet.create({
  soldDescriptionTitleText: {
    alignSelf: 'center',
    textAlign: 'center',
    marginHorizontal: dimensions.doubleMedium,
    marginTop: dimensions.medium,
    marginBottom: 4,
    fontSize: fontSizes.large + 2,
  },
  soldDescriptionText: {
    alignSelf: 'center',
    textAlign: 'center',
    marginHorizontal: dimensions.doubleMedium,
    marginBottom: dimensions.doubleMedium,
  },
});
