export default {
  SET_COMMUNITY_MEMBERS: 'members/SET_COMMUNITY_MEMBERS',
  SET_MORE_COMMUNITY_MEMBERS: 'members/SET_MORE_COMMUNITY_MEMBERS',
  START_LOADING_COMMUNITY_MEMBERS: 'members/START_LOADING_COMMUNITY_MEMBERS',
  START_LOADING_MORE_COMMUNITY_MEMBERS: 'members/START_LOADING_MORE_COMMUNITY_MEMBERS',
  ERROR_LOADING_COMMUNITY_MEMBERS: 'members/ERROR_LOADING_COMMUNITY_MEMBERS',
  ERROR_LOADING_MORE_COMMUNITY_MEMBERS: 'members/ERROR_LOADING_MORE_COMMUNITY_MEMBERS',
  SET_COMMUNITY_MEMBERS_SEARCH_HISTORY: 'members/SET_COMMUNITY_MEMBERS_SEARCH_HISTORY',
  REMOVE_MEMBER_FROM_SEARCH_HISTORY: 'members/REMOVE_MEMBER_FROM_SEARCH_HISTORY',
};
