import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../styles';
import { isIos } from '../../../../utils/detectDevice';

const s = StyleSheet.create({
  buttonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.halfMedium,
    marginBottom: dimensions.medium,
  },

  image: {
    width: 240,
    height: 130,
    alignSelf: 'center',
    marginTop: dimensions.doubleMedium,
    marginBottom: dimensions.doubleMedium,
  },

  inputContainer: {
    backgroundColor: colors.grayLighter,
    paddingHorizontal: dimensions.medium,
    paddingVertical: isIos ? dimensions.halfMedium : 0,
  },

  input: {
    textAlign: 'left',
  },

  keyboardAvoidingView: {
    flex: 1,
    marginBottom: isIos ? dimensions.doubleMedium : 0,
  },

  infoText: {
    lineHeight: 22,
    marginBottom: 40,
    paddingHorizontal: dimensions.medium,
  },
});

export default s;
