import Filter from 'bad-words';
import * as R from 'ramda';

const customFilter = new Filter({ placeHolder: 'x' });
export const filterBadWords = (str) => customFilter.clean(str);

export const numberWithCommas = (str) =>
  str || str === 0 ? str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;

export const kFormatter = (str) =>
  Math.abs(str) > 999
    ? Math.sign(str) * (Math.abs(str) / 1000).toFixed(1) + 'K'
    : Math.sign(str) * Math.abs(str);

export const capitalize = (s) => (s ? s[0].toUpperCase() + s.slice(1) : '');

export const getFirstLetterFrom = (value) => (value ? value.slice(0, 1).toUpperCase() : '#');

export const convertFloatDecimal = (value, decimal) => parseFloat(value).toFixed(decimal || 2);

export const getImageType = (uri) => uri.split('.')[uri.split('.').length - 1];

export const renderNamesFromArrThroughCommas = (arr) => {
  if (Array.isArray(arr)) {
    return arr.map((el, index) => `${el.name}${index === arr.length - 1 ? '' : ', '}`);
  }
  return '';
};

export const renderArrOfPropsThroughCommas = (arr, propName) => {
  if (Array.isArray(arr)) {
    return arr
      .map((el, index) => `${R.prop(propName, el)}${index === arr.length - 1 ? '' : ', '}`)
      .join('');
  }
  return '';
};

export const renderStringArrThroughCommas = (arr) => {
  if (Array.isArray(arr)) {
    return arr.map((el, index) => `${el}${index === arr.length - 1 ? '' : ', '}`);
  }
  return '';
};

export const leftToRightIsolate = '\u2066';

export const isURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return pattern.test(str);
};

export const getIsImageLink = (text) => {
  const imageLinkRegExp = new RegExp(/^(https?:\/\/.*\.(?:png|jpg))$/);

  return imageLinkRegExp.test(text);
};
