import styled from 'styled-components/native';
import colors from '../../../../../../../styles/colors';
import { medium } from '../../../../../../../styles/dimensions';
import { Text, Icon } from '../../../../../../ReusableComponents';

export const CategoriesContainer = styled.View`
  transform: scaleX(-1);
  align-items: flex-end;
`;

export const CategoriesList = styled.FlatList.attrs({
  horizontal: true,
  inverted: true,
  showsHorizontalScrollIndicator: false,
  contentContainerStyle: {
    paddingHorizontal: 1,
  },
})`
  min-height: 30px;
  background-color: ${colors.white};
  margin-bottom: ${medium};
`;

export const CategoryButton = styled.TouchableOpacity`
  transform: scaleX(-1);
  align-items: center;
  width: 74px;
  padding-top: ${medium};
  padding-horizontal: 1px;
`;

export const CategoryText = styled(Text)`
  margin-top: 12px;
  text-align: center;
  color: ${({ isActive }) => (isActive ? colors.blueLighter : colors.text)};
`;
export const CheckIcon = styled(Icon)`
  position: absolute;
  top: 6;
  right: 12;
`;
