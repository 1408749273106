import { StyleSheet } from 'react-native';
import { dimensions, colors } from '../../../../styles';
import { isIphoneX } from '../../../../utils/detectDevice';

export default StyleSheet.create({
  sectionHeaderContainer: {
    backgroundColor: colors.grayLighter,
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: dimensions.medium,
  },
  itemHeaderContainer: {
    paddingVertical: 20,
  },
  descriptionText: {
    lineHeight: 20,
  },
  listStyle: {
    backgroundColor: colors.grayLighter,
  },
  headerImage: {
    height: 150,
    width: 150,
    marginVertical: dimensions.doubleMedium,
  },
  headerImageContainer: {
    width: '100%',
    alignItems: 'center',
    paddingTop: isIphoneX ? 48 : 12,
  },
});
