import { scaleFont } from './scalingUtils';
import { isWeb } from '../utils/detectDevice';

const fontSizes = {
  title: isWeb ? 26 : 22,
  headline: isWeb ? 24 : 20,
  large: isWeb ? 22 : 18,
  medium: isWeb ? 20 : 16,
  small: isWeb ? 18 : 14,
  xsmall: isWeb ? 15 : 12,
  xxsmall: isWeb ? 14 : 10,

  // deprecated don't use it, remove in the future
  xxbig: scaleFont(52),
  xbig: scaleFont(38),
  big: scaleFont(24),
  xxmedium: scaleFont(20),
  xmedium: scaleFont(18),
};

export default fontSizes;
