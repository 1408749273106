import React, { Fragment } from 'react';
import styled from 'styled-components/native';
import Text from '../Text';
import TouchableItem from '../TouchableItem';
import { colors, dimensions, fontSizes } from '../../../styles';
import { isWeb } from '../../../utils/detectDevice';

const ButtonContainer = styled(TouchableItem)`
  height: ${({ small }) => (small ? 30 : 40)};
  ${({ small }) => (small ? 'flex: 1' : 'width: 100%')};
  align-items: center;
  justify-content: center;
  background-color: ${({ disabled }) => (disabled ? colors.grayLighter : colors.blueLight)};
  border-radius: 4px;
  margin-right: ${({ withMarginRight }) => (withMarginRight ? dimensions.halfXLarge : 0)};
`;

const ButtonText = styled(Text)`
  color: ${({ disabled }) => (disabled ? colors.gray : colors.white)};
  ${({ small }) => (small ? `font-size: ${fontSizes.small}` : '')};
  ${({ small }) => (small ? `font-family: Assistant-SemiBold` : '')};
`;

const SecondaryButtonContainer = styled(ButtonContainer)`
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${({ disabled }) => (disabled ? colors.grayLighter : colors.blueLight)};
  ${({ small }) => (small ? `border-color: ${colors.grayBorder}` : '')};
  margin-right: ${({ withMarginRight }) => (withMarginRight ? dimensions.halfXLarge : 0)};
`;

const SecondaryButtonText = styled(ButtonText)`
  color: ${({ disabled }) => (disabled ? colors.gray : colors.blueLight)};
  ${({ small }) => (small ? `color: ${colors.blueLight}` : '')};
`;

const Button = ({ small, disabled, title, onPress, withMarginRight, secondaryType = false }) => (
  <Fragment>
    {secondaryType ? (
      <SecondaryButtonContainer
        useOpacity={false}
        disabled={disabled}
        withMarginRight={withMarginRight}
        small={small}
        onPress={onPress}
      >
        <SecondaryButtonText medium disabled={disabled} small={small}>
          {title}
        </SecondaryButtonText>
      </SecondaryButtonContainer>
    ) : (
      <ButtonContainer
        useOpacity={false}
        disabled={disabled}
        withMarginRight={withMarginRight}
        small={small}
        onPress={onPress}
      >
        <ButtonText medium disabled={disabled} small={small}>
          {title}
        </ButtonText>
      </ButtonContainer>
    )}
  </Fragment>
);

export default Button;
