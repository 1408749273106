import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { MainContainer, DetailContainer, MemberStatus, InfoView, ButtonsContainer } from './styles';
import { colors, rs } from '../../../../../styles';
import UserAvatar from '../../../UserAvatar';
import Text from '../../../Text';
import NavigationService from '../../../../../services/NavigationService';
import { chatsOperations } from '../../../../../store/chats';
import screens from '../../../../../navigation/screens';

const s = StyleSheet.create({
  textContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  nickname: {
    writingDirection: 'ltr',
  },
});

class MemberItem extends React.PureComponent {
  openChatRoom = () => {
    const { openPersonalChat, profileImage, name, userId } = this.props;
    openPersonalChat({
      userId,
      profileImage,
      selectedUserName: name,
    });
  };

  render() {
    const {
      profileImage,
      name,
      nickname,
      userId,
      currentUserId,
      additionalInfo,
      BottomInfoComponent,
      RightComponent,
      onPress,
      disabledTouchable = false,
      forceNavigateToProfile = true,
    } = this.props;

    return (
      <MainContainer
        useOpacity={false}
        disabled={disabledTouchable}
        onPress={() => {
          if (onPress) onPress();

          if (forceNavigateToProfile) {
            NavigationService.push(screens.UserProfile, {
              name,
              userId,
              profile_image: profileImage,
            });
          }
        }}
      >
        <Fragment>
          <UserAvatar uri={profileImage} />

          <DetailContainer>
            <InfoView>
              <View style={s.textContainer}>
                <Text medium semiBold style={rs.smallMarginRight}>
                  {name}
                </Text>
                {!!nickname && (
                  <Text color={colors.gray} style={s.nickname}>
                    @{nickname}
                  </Text>
                )}
              </View>
              {BottomInfoComponent ? (
                <BottomInfoComponent />
              ) : (
                <MemberStatus color={colors.gray}>{additionalInfo}</MemberStatus>
              )}
            </InfoView>

            <ButtonsContainer>{RightComponent && <RightComponent />}</ButtonsContainer>
          </DetailContainer>
        </Fragment>
      </MainContainer>
    );
  }
}

MemberItem.propTypes = {
  profileImage: T.string,
  additionalInfo: T.string,
  name: T.string,
  userId: T.number,
  currentUserId: T.number,
  openPersonalChat: T.func,
  disabledTouchable: T.bool,
  forceNavigateToProfile: T.bool,
};

export default connect(
  (state) => ({
    currentUserId: state.userInfo.id,
  }),
  (dispatch) => ({
    openPersonalChat: (user) => dispatch(chatsOperations.openPersonalChat(user)),
  }),
)(MemberItem);
