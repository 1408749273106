import React from 'react';
import { TouchableOpacity, View, TouchableWithoutFeedback } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import styled from 'styled-components/native';
import { isRTL } from '../../utils/rtlHelper';
import { isIos } from '../../utils/detectDevice';
import { colors, dimensions } from '../../styles';

const ContentContainer = styled.View`
  min-width: 200px;
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray};
  align-self: flex-start;
  margin-vertical: ${dimensions.halfMedium};
`;

const Input = styled.TextInput`
  padding-vertical: 4px;
  padding-horizontal: ${dimensions.halfMedium};
  ${isIos && isRTL ? 'text-align: right' : ''}
`;

const TextInput = ({
  onClear = () => null,
  value,
  onChangeText,
  onPress,
  placeholder,
  iconName,
  ...inputProps
}) => (
  <ContentContainer>
    {!!iconName && <Icon color={colors.gray} name={iconName} size={18} />}
    <Input
      value={value}
      onChangeText={onChangeText}
      placeholder={placeholder}
      editable={!onPress}
      {...inputProps}
    />
  </ContentContainer>
);

export default TextInput;
