import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../../styles';

export default StyleSheet.create({
  itemContainer: {
    height: 90,
    justifyContent: 'center',
    paddingHorizontal: dimensions.medium,
  },
  activeItemContainer: {
    backgroundColor: colors.grayLighter,
  },
});
