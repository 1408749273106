import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { useActionSheet } from '@expo/react-native-action-sheet';
import T from 'prop-types';
import { getParamOr } from '../../../../utils/navHelper';
import { shareDynamicLink } from '../../../../utils/shareHelper';
import { Container, NavigationIconButton } from '../../../ReusableComponents';
import { headerStyle, colors } from '../../../../styles';
import UserInfo from './components/UserInfo';
import { chatsOperations } from '../../../../store/chats';
import { communityMembersOperations } from '../../../../store/communityMembers';
import { followersOperations } from '../../../../store/followers';
import { userOperations } from '../../../../store/user';
import { ModalsService, ToastsService } from '../../../../services';
import strings from '../../../../localization';
import { reportTypes } from '../../../../constants/reports';
import modalTypes from '../../../../constants/modalTypes';
import { OPEN_USER_PROFILE } from '../../../../constants/dynamicalLinkActionTypes';
import UserItems from '../components/UserItems';
import NavigationService from '../../../../services/NavigationService';
import screens from '../../../../navigation/screens';
import locationsApi from '../../../../api/locations';
import { communitySelectors } from '../../../../store/communityInfo';
import { lotsOperations } from '../../../../store/lots';

const SHARED_COMMUNITY_IDS_PLACEHOLDER = [];

const UserProfile = ({
  user,
  isUserAdmin,
  navigation,
  route,
  getUserInfo,
  openPersonalChat,
  approveUserToUploadItems,
  suspendUserToUploadItems,
  blockUser,
  unblockUser,
  followMember,
  unfollowMember,
  getUserCredibilityDetails,
}) => {
  const { showActionSheetWithOptions } = useActionSheet();
  const userId = route.params.userId;
  const [isApprovedToUploadItems, setApprovedToUploadItems] = useState(false);
  const [isLoadingUserInfo, setLoadingUserInfo] = useState(false);
  const [isLoadingUserLocations, setLoadingUserLocations] = useState(false);

  const [state, setState] = useState({
    profileImage: null,
    selectedUserName: null,
    nickname: '',
    isShipper: false,

    memberSince: '',
    lastSeenAt: '',

    isBlocked: false,
    isFollower: null,
    isFollowing: null,
    followersCount: 0,
    followingsCount: 0,
    userLocations: [],
    userCredibilityDetails: {
      is_verified: false,
      endorsements_count: 0,
      members_sold_to_count: 0,
    },
    schoolInfo: {},
  });

  const [userLocations, setUserLocations] = useState([]);

  const {
    profileImage,
    selectedUserName,
    nickname,
    isShipper,

    memberSince,
    lastSeenAt,
    isBlocked,
    isFollower,
    isFollowing,
    followersCount,
    followingsCount,
    userCredibilityDetails,
    schoolInfo,
  } = state;

  useEffect(() => {
    const profile_image = route.params.profile_image;
    const name = route.params.name;

    setState({
      ...state,
      selectedUserName: name,
      profileImage: profile_image,
    });

    onLoadUserInfo(true);
    onLoadUserLocations(true);
  }, []);

  useEffect(() => {
    navigation.setParams({
      onOpenActionSheet,
      isActionSheetDisabled: isLoadingUserInfo,
      nickname,
    });
  }, [state, isApprovedToUploadItems, isLoadingUserInfo]);

  const onUpdateUserCredibilityDetails = (updatedUserCredibilityDetails) => {
    setState({
      ...state,
      userCredibilityDetails: updatedUserCredibilityDetails,
    });
  };

  const onLoadUserInfo = async (isInitialLoading) => {
    if (isInitialLoading) {
      setLoadingUserInfo(true);
    }
    const response = await getUserInfo(userId);

    const {
      profile_image,
      name,
      is_follower,
      is_following,
      followers_count,
      followings_count,
      member_since,
      last_seen_at,
      nickname,
      is_approved_to_upload_items,
      is_shipper,
      user_credibility_details,
      school_info,
      is_blocked,
    } = response;

    setState({
      ...state,
      isFollower: is_follower,
      isFollowing: is_following,
      isBlocked: is_blocked,
      followersCount: followers_count,
      followingsCount: followings_count,
      memberSince: member_since,
      lastSeenAt: last_seen_at,
      profileImage: profile_image,
      selectedUserName: name,
      isShipper: is_shipper,
      nickname,
      userCredibilityDetails: user_credibility_details,
      schoolInfo: !R.isNil(school_info) ? school_info : {},
    });

    setApprovedToUploadItems(is_approved_to_upload_items);
    if (isInitialLoading) {
      setLoadingUserInfo(false);
    }
  };

  const onLoadUserLocations = async (isInitialLoading) => {
    try {
      if (isInitialLoading) {
        setLoadingUserLocations(true);
      }
      const locations = await locationsApi.getUserLocations(userId);
      // [SHA-1484] need to show only active locations in case it's the same user
      setUserLocations(locations.filter(({ is_active }) => is_active));
    } catch (e) {
      ToastsService.showError('Ooops, some problems with user locations');
    } finally {
      if (isInitialLoading) {
        setLoadingUserLocations(false);
      }
    }
  };
  const onRefresh = async () => {
    await Promise.all([onLoadUserLocations(), onLoadUserInfo()]);
  };

  const openChatRoom = async () => {
    openPersonalChat({
      userId,
      profileImage,
      selectedUserName,
    });
  };

  const handleUserBlock = async () => {
    if (isBlocked) {
      await unblockUser(userId);
      setState({ ...state, isBlocked: false });

      return;
    }
    await blockUser(userId);

    setState({ ...state, isBlocked: true });
    setState((prevState) => ({
      ...prevState,
      isBlocked: true,
      isFollower: false,
      isFollowing: false,
      followersCount: prevState.isFollowing
        ? prevState.followersCount - 1
        : prevState.followersCount,
      followingsCount: prevState.isFollower
        ? prevState.followingsCount - 1
        : prevState.followingsCount,
    }));
  };

  const onOpenActionSheet = () => {
    const isCurrentUser = Number(userId) === Number(user.id);

    let options = null;

    if (isCurrentUser) {
      options = [strings.share.share_this_profile, strings.common.cancel];
    } else if (isUserAdmin) {
      options = [
        strings.share.share_this_profile,
        isBlocked ? strings.user.unblock : strings.user.block,
        strings.user.report_user,
        isApprovedToUploadItems
          ? strings.user.suspend_user_to_upload_items
          : strings.user.approve_user_to_upload_items,
        strings.common.cancel,
      ];
    } else {
      options = [
        strings.share.share_this_profile,
        isBlocked ? strings.user.unblock : strings.user.block,
        strings.user.report_user,
        strings.common.cancel,
      ];
    }

    const cancelButtonIndex = options.length - 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex: isCurrentUser ? 2 : 1, // hide destructiveButton if isCurrentUser
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          shareDynamicLink({
            actionType: OPEN_USER_PROFILE,
            id: userId,
            socialImageUrl: profileImage,
            socialTitle: selectedUserName,
            linkDescription: strings.deep_linking.user_profile_link_info,
          });
        }
        if (!isCurrentUser && buttonIndex === 1) {
          handleUserBlock();
        }
        if (!isCurrentUser && buttonIndex === 2) {
          ModalsService.showSwipeableModal(modalTypes.REPORT, {
            reportType: reportTypes.profile,
            reporterUserId: user.id,
            reportedUserId: userId,
          });
        }
        if (!isCurrentUser && buttonIndex === 3 && isUserAdmin) {
          if (isApprovedToUploadItems) {
            setApprovedToUploadItems(false);
            suspendUserToUploadItems(userId);
          } else {
            setApprovedToUploadItems(true);
            approveUserToUploadItems(userId);
          }
        }
      },
    );
  };

  const onFollow = () => {
    followMember(userId);

    setState({
      ...state,
      isFollowing: true,
      followersCount: followersCount + 1,
    });
  };

  const onUnfollow = () => {
    unfollowMember(userId);

    setState({
      ...state,
      isFollowing: false,
      followersCount: followersCount - 1,
    });
  };

  const onOpenSearchItems = () => {
    NavigationService.navigate(screens.UserItems, {
      userId,
      sharedCommunityIds: [],
      userName: selectedUserName,
    });
  };

  const onOpenEndorsements = () => {
    NavigationService.push(screens.Endorsements, {
      userId,
      endorsementsCount: userCredibilityDetails?.endorsements_count ?? 0,
      onUpdateUserCredibilityDetails,
    });
  };

  const renderHeader = () => (
    <UserInfo
      profileImage={profileImage}
      name={selectedUserName}
      nickname={nickname}
      isShipper={isShipper}
      memberSince={memberSince}
      lastSeenAt={lastSeenAt}
      selectedUserId={userId}
      isCurrentUser={Number(userId) === Number(user.id)}
      onSendMessage={openChatRoom}
      onFollow={onFollow}
      onUnfollow={onUnfollow}
      isMemberFollower={isFollower}
      isContextUserFollower={isFollowing}
      followersCount={followersCount}
      followingsCount={followingsCount}
      userLocations={userLocations}
      onOpenSearchItems={onOpenSearchItems}
      onOpenEndorsements={onOpenEndorsements}
      userCredibilityDetails={userCredibilityDetails}
      isLoadingUserInfo={isLoadingUserInfo}
      isLoadingUserLocations={isLoadingUserLocations}
      schoolInfo={schoolInfo}
      isBlocked={isBlocked}
    />
  );

  return (
    <Container>
      <UserItems
        renderHeader={renderHeader}
        userId={userId}
        sharedCommunityIds={SHARED_COMMUNITY_IDS_PLACEHOLDER}
        onRefresh={onRefresh}
      />
    </Container>
  );
};

UserProfile.propTypes = {
  user: T.object.isRequired,
  getUserInfo: T.func,
  blockUser: T.func,
  unblockUser: T.func,
  followMember: T.func,
  unfollowMember: T.func,
};

export default connect(
  (state) => ({
    user: state.userInfo,
    isUserAdmin: communitySelectors.getIsUserAdmin(state),
  }),
  (dispatch) => ({
    getPersonalChatByUserId: (userId) => dispatch(chatsOperations.getPersonalChatByUserId(userId)),
    openPersonalChat: (user) => dispatch(chatsOperations.openPersonalChat(user)),
    blockUser: (userId) => dispatch(userOperations.blockUser(userId)),
    unblockUser: (userId) => dispatch(userOperations.unblockUser(userId)),
    isUserBlocked: (userId) => dispatch(userOperations.isUserBlocked(userId)),
    sendReport: ({ reporter_id, profile_id, report_reason }) => {
      dispatch(userOperations.sendReport({ reporter_id, profile_id, report_reason }));
    },
    getUserCredibilityDetails: (userId) =>
      dispatch(userOperations.getUserCredibilityDetails(userId)),
    getUserInfo: (userId) => dispatch(communityMembersOperations.getUserInfo(userId)),
    followMember: (memberId) => dispatch(followersOperations.followMember(memberId)),
    unfollowMember: (memberId) => dispatch(followersOperations.unfollowMember(memberId)),
    approveUserToUploadItems: (userId) => dispatch(lotsOperations.approveUserToUploadItems(userId)),
    suspendUserToUploadItems: (userId) => dispatch(lotsOperations.suspendUserToUploadItems(userId)),
  }),
)(UserProfile);
