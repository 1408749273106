import { StyleSheet } from 'react-native';
import { dimensions, rs } from '../../../styles';
import colors from '../../../styles/colors';

export default StyleSheet.create({
  contentContainer: {
    transform: [{ scaleX: -1 }],
    alignItems: 'flex-end',
  },
  itemContainer: {
    minWidth: 140,
    borderRadius: 4,
    backgroundColor: colors.white,
    ...rs.shadow,

    transform: [{ scaleX: -1 }],
    marginHorizontal: dimensions.halfMedium,
    marginTop: 2, // to make shadow visible
    marginBottom: 2,
    alignItems: 'center',
    padding: dimensions.medium,
  },
  image: {
    height: 80,
    width: 80,
    borderRadius: 40,
    marginBottom: dimensions.medium,
  },
  listContentContainerStyle: {
    paddingHorizontal: dimensions.halfMedium,
  },
  separator: {
    marginTop: 10,
    marginBottom: 10,
  },
});
