export const BUYER_SELLER = 'BUYER_SELLER';
export const PERSONAL = 'PERSONAL';
export const WISH = 'WISH';

export default {
  BUY_CHAT: 'buy_chat',
  SELL_CHAT: 'sell_chat',
  PERSONAL_CHAT: 'personal_chat',
  SUPPORT: 'support_chat',
};
