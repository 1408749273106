import React from 'react';
import { TextInput, StyleSheet } from 'react-native';
import { colors, fontSizes } from '../../styles';
import { isWeb } from '../../utils/detectDevice';
import { isRTL, getIsRTLString } from '../../utils/rtlHelper';

const getInputAlignment = (value) => {
  if (isRTL) {
    return 'right';
  }

  const isRTLValue = getIsRTLString(value);

  return isRTLValue ? 'right' : 'left';
};

const s = StyleSheet.create({
  input: {
    fontSize: fontSizes.small,
    fontFamily: isWeb ? 'Rubik' : 'Rubik-Regular',
    color: colors.text,
    paddingVertical: 0,
  },
});

const Input = ({ style, ...props }) => (
  <TextInput
    style={[s.input, style]}
    placeholderTextColor={colors.grayBorder}
    underlineColorAndroid={'transparent'}
    textAlign={getInputAlignment(props.value)}
    {...props}
  />
);

export default Input;
