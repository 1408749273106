import locationsApi from '../../api/locations';
import {
  getUserLocations as getLocations,
  removeUserLocation,
  activateUserLocation,
  setNewPrimaryLocation,
  addLocation,
  updateLocation,
} from './actions';
import { normalize } from '../../utils/stateHelper';
import { LoadingService } from '../../services';

const getUserLocations = () => (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  return locationsApi
    .getUserLocations(userId)
    .then((response) => {
      const normalizedLocations = normalize(response, 'userLocationIds', 'userLocationEntities');
      return dispatch(getLocations(normalizedLocations));
    })
    .catch((err) => {
      console.log('err getUserLocations', err);
    });
};

const removeLocation = (locationId) => (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;
  return locationsApi
    .removeLocation(userId, locationId)
    .then((response) => dispatch(removeUserLocation(locationId)))
    .catch((err) => {
      console.log('err', err);
    });
};

const activateLocation = (locationId) => {
  return async (dispatch, getState) => {
    try {
      const { userInfo } = getState();
      const userId = userInfo.id;

      await locationsApi.activateLocation(userId, locationId);
      dispatch(activateUserLocation(locationId));
    } catch (error) {}
  };
};

const createLocation = ({
  location,
  type,
  availability = null,
  shippersCount = null,
  price = null,
  internalNote = null,
  shippersUserIds = null,
}) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    const response = await locationsApi.createLocation({
      userId,
      location: {
        ...location,
        type,
        availability,
        shippers_count: shippersCount,
        price,
        internal_note: internalNote,
        shippers_user_ids: shippersUserIds,
      },
    });

    const normalizedLocations = normalize(response, 'userLocationIds', 'userLocationEntities');
    dispatch(addLocation(normalizedLocations));
  } catch (e) {}
};

const editLocation = (location) => {
  return async (dispatch) => {
    try {
      const updatedLocation = await locationsApi.editLocation(location);
      dispatch(updateLocation(updatedLocation));
    } catch (error) {}
  };
};

const setPrimaryLocation = (locationId) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    LoadingService.showLoader();
    const response = await locationsApi.setPrimaryLocation(userId, locationId);
    const { previousPrimaryLocation, currentPrimaryLocation } = response;
    dispatch(setNewPrimaryLocation({ previousPrimaryLocation, currentPrimaryLocation }));
    LoadingService.hideLoader();
  } catch (e) {
    LoadingService.hideLoader();
  }
};

export default {
  getUserLocations,
  removeLocation,
  activateLocation,
  setPrimaryLocation,
  createLocation,
  editLocation,
};
