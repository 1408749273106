import React, { Fragment } from 'react';
import MaterialIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { StyleSheet } from 'react-native';
import {
  Text,
  Separator,
  View,
  AngleIcon,
  TouchableItem,
  FontIcon,
} from '../../../../../ReusableComponents';
import { colors, dimensions, reusableStyles } from '../../../../../../styles';

const s = StyleSheet.create({
  mainContainer: {
    flex: 1,
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: dimensions.medium,
    paddingRight: dimensions.halfMedium,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: dimensions.medium,
    paddingLeft: dimensions.medium,
    minHeight: 64,
  },
  iconContainer: {
    width: 30,
  },
});

const BankItem = ({ iconName, onPress, title, description, isFontIcon }) => (
  <Fragment>
    <TouchableItem style={s.buttonContainer} useOpacity={false} onPress={onPress}>
      <Fragment>
        <View style={s.iconContainer}>
          {isFontIcon ? (
            <FontIcon name={iconName} size={22} />
          ) : (
            <MaterialIcon name={iconName} size={30} style={reusableStyles.rtlScaleStyle} />
          )}
        </View>

        <View style={s.mainContainer}>
          <View>
            <Text medium semiBold>
              {title}
            </Text>
            {description && <Text color={colors.gray}>{description}</Text>}
          </View>

          <AngleIcon />
        </View>
      </Fragment>
    </TouchableItem>
    <Separator color={colors.gray} />
  </Fragment>
);

export default BankItem;
