import { handleActions } from 'redux-actions';
import { mergeDeep, mergeIn } from '../../utils/stateHelper';

import types from './types';
import { RESET_GROUP_STATE } from '../constants';

const initialState = {
  groupIds: [],
  groupEntities: {},






  insideCommunityId: null,
  isAllCommunitiesOnFeed: false,

  communityIds: [],
  joinedCommunityIds: [],

  communityEntities: {
    // 2: {
    //   group_id: 2,
    //   group_name: 'Shareitt Home',
    //   group_member_count: '73',
    //   background_image:
    //     'https://storage.googleapis.com/group-background-images-prod/2_1554473164791.png',
    //   balance_amount: '67',
    //   icon: 'https://storage.googleapis.com/user-avatars-prod/SHAREITT_1554473198575.png',
    //   token_name: 'SHAREITT',
    //   is_group_member: false,
    //   is_pending_approval: false,
    // },
  },
};

const communitiesReducer = handleActions(
  {
    [types.SET_COMMUNITY_GROUPS]: mergeDeep(({ payload }, state) => ({
      groupEntities: payload.groupEntities,
      groupIds: payload.groupIds,
    })),







    [types.SET_COMMUNITIES]: mergeDeep(({ payload }, state) => ({
      communityEntities: payload.communityEntities,
      communityIds: payload.communityIds,
    })),

    [types.SET_JOINED_COMMUNITIES]: mergeDeep(({ payload }, state) => ({
      communityEntities: payload.communityEntities,
      joinedCommunityIds: payload.communityIds,
    })),

    [types.SET_INSIDE_COMMUNITY_ID]: mergeDeep(({ payload }, state) => ({
      insideCommunityId: payload,
      isAllCommunitiesOnFeed: false,
    })),

    [types.SET_ALL_COMMUNITIES_ON_FEED]: mergeDeep(({ payload }, state) => ({
      isAllCommunitiesOnFeed: payload,
      insideCommunityId: null,
    })),

    [types.SET_COMMUNITY_INFO]: mergeDeep(({ payload }, state) => ({
      communityEntities: {
        [payload.group_id]: payload,
      },
    })),

    [types.CHANGE_STATUS_TO_PENDING_APPROVAL]: mergeDeep(({ payload }, state) => ({
      communityEntities: {
        [payload.groupId]: {
          is_pending_approval: true,
        },
      },
    })),

    [types.USER_BECOME_COMMUNITY_MEMBER]: mergeDeep(({ payload }, state) => ({
      communityEntities: {
        [payload.groupId]: {
          is_group_member: true,
          is_pending_approval: false,
        },
      },
    })),

    [types.MARK_COMMUNITY_AS_VISITED]: mergeDeep(({ payload }, state) => ({
      communityEntities: {
        [payload.communityId]: {
          is_visited: true,
        },
      },
    })),

    [types.USER_LEFT_COMMUNITY]: mergeDeep(({ payload }, state) => ({
      communityEntities: {
        [payload.communityId]: {
          is_group_member: false,
          is_rejoin_member: true,
          group_member_count: (
            Number(state.communityEntities[payload.communityId].group_member_count) - 1
          ).toString(),
        },
      },
      insideCommunityId:
        payload.communityId === state.insideCommunityId
          ? state.joinedCommunityIds.find(el => el !== payload.communityId)
          : state.insideCommunityId,
    })),

    [types.APPROVE_COMMUNITY_RULES]: mergeDeep(({ payload }, state) => ({
      communityEntities: {
        [payload]: {
          is_approved_community_rules: true,
        },
      },
    })),

    [types.APPROVE_ITEM_RULES]: mergeDeep(({ payload }, state) => ({
      communityEntities: {
        [payload]: {
          is_approved_item_rules: true,
        },
      },
    })),

    [types.APPROVE_WISH_RULES]: mergeDeep(({ payload }, state) => ({
      communityEntities: {
        [payload]: {
          is_approved_wish_rules: true,
        },
      },
    })),

    [types.INCREASE_MEMBERS_COUNT]: mergeDeep(({ payload }, state) => ({
      communityEntities: {
        [payload.groupId]: {
          group_member_count: (
            Number(state.communityEntities[payload.groupId].group_member_count) + 1
          ).toString(),
        },
      },
    })),
  },
  initialState,
);

export default communitiesReducer;
