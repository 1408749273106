import accountApi from '../../api/account';
import { setWallets } from './actions';

const getWallets = () => async (dispatch) => {
  try {
    const assembledWallet = await accountApi.getAssembledWallet();
    const rewardWallet = await accountApi.getRewardWallet();

    dispatch(setWallets({ assembledWallet, rewardWallet }));
  } catch (err) {}
};

export default {
  getWallets,
};
