/* eslint-disable */
import React, { Fragment } from 'react';
import styled from 'styled-components/native';
import T from 'prop-types';
import { dimensions, colors } from '../../styles';
import { getIsDefaultProfileImage } from '../../utils/userInfo';
import Text from './Text';
import AppImage from './AppImage';
import strings from '../../localization';

const ProfileBackgroundImage = styled.ImageBackground`
  width: ${({ big }) => (big ? 68 : 44)};
  height: ${({ big }) => (big ? 68 : 44)};
  border-radius: ${({ big }) => (big ? 34 : 22)};
  margin-horizontal: ${dimensions.medium};
  border-width: 1px;
  border-color: ${colors.grayLighter};
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ProfileImage = styled(AppImage)`
  width: ${({ big }) => (big ? 66 : 42)};
  height: ${({ big }) => (big ? 66 : 42)};
  border-radius: ${({ big }) => (big ? 33 : 21)};
`;

const SmallProfileBackgroundImage = styled.ImageBackground`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-horizontal: ${({ withoutMargin }) => (withoutMargin ? 0 : dimensions.medium)};
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const SmallProfileImage = styled(AppImage)`
  width: 40;
  height: 40;
  border-radius: 20;
`;

const EditLabelContainer = styled.View`
  z-index: 10;
  position: absolute;
  height: 20px;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  background-color: ${colors.grayDarker};
  opacity: 0.7;
`;

const UserAvatar = ({ uri, small, withoutMargin, big, withEditLabel, withLogoPlaceholder }) => {
  const isDefaultProfileImage = getIsDefaultProfileImage(uri);

  const getBackgroundImage = () => {
    if (isDefaultProfileImage) {
      return uri;
    }

    return withLogoPlaceholder ? 'logo' : 'user_avatar_placeholder';
  };

  return (
    <Fragment>
      {small ? (
        <SmallProfileBackgroundImage
          withoutMargin={withoutMargin}
          source={{ uri: getBackgroundImage() }}
          resizeMode="cover"
          imageStyle={{ borderRadius: 20 }}
        >
          {!!uri && !isDefaultProfileImage && (
            <SmallProfileImage source={{ uri }} resizeMode="cover" />
          )}
        </SmallProfileBackgroundImage>
      ) : (
        <ProfileBackgroundImage
          big={big}
          source={{ uri: getBackgroundImage() }}
          resizeMode="cover"
          imageStyle={{ borderRadius: big ? 34 : 22 }}
        >
          {!!uri && !isDefaultProfileImage && (
            <ProfileImage big={big} source={{ uri }} resizeMode="cover" />
          )}
          {withEditLabel && (
            <EditLabelContainer>
              <Text xsmall bold color={colors.white}>
                {strings.common.edit}
              </Text>
            </EditLabelContainer>
          )}
        </ProfileBackgroundImage>
      )}
    </Fragment>
  );
};

UserAvatar.propTypes = {
  uri: T.string,
  big: T.bool,
  withEditLabel: T.bool,
};

export default UserAvatar;
