import React, { memo, Fragment } from 'react';
import T from 'prop-types';
import { View, ScrollView, TouchableOpacity, Image } from 'react-native';
import s from './styles';
import Text from '../Text';
import Spinner from '../Spinner';

import { colors } from '../../../styles';

const Tokens = memo(({ tokens, selectedToken, onSelectToken, isLoading }) => {
  return (
    <ScrollView horizontal style={s.list} bounces={false} showsHorizontalScrollIndicator={false}>
      {isLoading ? (
        <View style={s.spinnerContainer}>
          <Spinner />
        </View>
      ) : (
        <Fragment>
          {tokens.map(item => {
            const isActive = selectedToken && item.id === selectedToken.id;
            return (
              <TouchableOpacity
                key={item.id.toString()}
                style={s.tokenButton}
                onPress={() => onSelectToken(item)}
              >
                <View
                  style={[s.tokenImageContainer, isActive && { borderColor: colors.blueLighter }]}
                >
                  <Image style={s.tokenImage} source={{ uri: item.token_icon }} />
                </View>
                <Text
                  medium
                  semiBold
                  bold={isActive}
                  alignCenter
                  color={isActive ? colors.blueLighter : colors.text}
                >
                  {item.balance}
                </Text>
                <Text
                  medium
                  semiBold={isActive}
                  color={isActive ? colors.blueLighter : colors.text}
                >
                  {item.token_name}
                </Text>
              </TouchableOpacity>
            );
          })}
        </Fragment>
      )}
    </ScrollView>
  );
});

Tokens.propTypes = {
  tokens: T.array,
  selectedToken: T.object,
  onSelectToken: T.func,
  isLoading: T.bool,
};

export default Tokens;
