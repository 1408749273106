import styled from 'styled-components/native';
import { dimensions, colors } from '../../../../styles';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  separator: {
    alignSelf: 'center',
    width: dimensions.width - dimensions.doubleMedium,
  },
  rulesContainer: {
    paddingHorizontal: dimensions.medium,
    paddingVertical: 20,
    backgroundColor: colors.white,
  },
});

export const PostButtonContainer = styled.View`
  flex: 1;
  padding-vertical: ${dimensions.commonHeight};
  padding-horizontal: ${dimensions.medium};
`;
