import React from 'react';
import s from './styles';
import { TouchableItem, Container, Text } from '../../../ReusableComponents/index';
import { rs } from '../../../../styles';
import { FlatList } from 'react-native';

const ConditionItem = ({ group, isSelected, onSelectGroup }) => (
  <TouchableItem
    style={[s.itemContainer, isSelected && s.activeItemContainer]}
    useOpacity={false}
    onPress={() => {
      onSelectGroup(group.id);
    }}
  >
    <Text medium semiBold style={rs.verySmallMarginBottom}>
      {group.group_name}
    </Text>
  </TouchableItem>
);

const Groups = ({ route }) => {
  const groups = route.params.groups;
  const selectedGroupId = route.params.selectedGroupId;
  const onSelectGroup = route.params.onSelectGroup;

  return (
    <Container>
      <FlatList
        data={groups}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <ConditionItem
            group={item}
            isSelected={item.id === selectedGroupId}
            onSelectGroup={onSelectGroup}
          />
        )}
      />
    </Container>
  );
};

export default Groups;
