import React, { Fragment, memo } from 'react';
import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';
import Text from '../Text';
import TouchableItem from '../TouchableItem';
import FontIcon from '../FontIcon';
import Icon from '../Icon';
import { colors, dimensions } from '../../../styles';
import NavigationService from '../../../services/NavigationService';

const RootContainer = styled.View`
  height: 60;
  width: 100%;
`;

const ContentContainer = styled(TouchableItem)`
  height: 60;
  position: absolute;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-left: ${dimensions.medium};
  background-color: ${colors.white};
`;

const MainContainer = styled.View`
  flex: 1;
  height: 100%;
  flex-direction: row;
  align-items: center;
  margin-left: ${dimensions.medium};
  padding-right: ${dimensions.halfMedium};
  border-bottom-color: ${colors.grayLight};
  border-bottom-width: ${StyleSheet.hairlineWidth};
  border-bottom-width: ${({ withoutBottomSeparator }) =>
    withoutBottomSeparator ? 0 : StyleSheet.hairlineWidth};
`;

const LogoContainer = styled.View`
  align-items: center;
  justify-content: center;
  width: 34px;
`;

const BubbleAndArrowContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

const RightText = styled(Text)`
  margin-right: 4px;
  color: ${colors.gray};
`;

const BubbleText = styled(Text)`
  font-size: 13px;
`;

const BubbleNotification = styled.View`
  height: 18px;
  min-width: 18px;
  padding-horizontal: 4px;
  margin-horizontal: ${dimensions.doubleMedium};
  background-color: ${colors.blueLight};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

const OptionItem = memo(
  ({
    title,
    count,
    rightText, // text in the end, before chevron
    rightTextStyle, // text in the end, before chevron
    pageName, // if need navigate to screen
    onPress,
    iconName, // when need use 'Feather' icon
    Icon, // for custom icons or images
    navigation,
    showChevron = false,
    textColor,
    withoutBottomSeparator = true,
    withoutIcon,
    useOpacity = false,
  }) => (
    <RootContainer>
      <ContentContainer
        useOpacity={useOpacity}
        onPress={onPress || (() => NavigationService.navigate(pageName))}
      >
        <Fragment>
          {!withoutIcon && (
            <LogoContainer>
              {Icon ? (
                <Icon />
              ) : (
                <FontIcon withoutRTLScale name={iconName} color={colors.text} size={24} />
              )}
            </LogoContainer>
          )}
          <MainContainer withoutBottomSeparator={withoutBottomSeparator}>
            <Text medium color={textColor}>
              {title}
            </Text>

            {!!count && (
              <BubbleNotification>
                <BubbleText color={colors.white}>{count}</BubbleText>
              </BubbleNotification>
            )}

            <BubbleAndArrowContainer>
              <Fragment>
                {!!rightText && (
                  <RightText style={rightTextStyle} medium>
                    {rightText}
                  </RightText>
                )}
              </Fragment>
              {showChevron && (
                <Icon
                  name="chevron-right"
                  color="#BFBFC1"
                  size={dimensions.iconSize}
                  withoutRTLScale={false}
                />
              )}
            </BubbleAndArrowContainer>
          </MainContainer>
        </Fragment>
      </ContentContainer>
    </RootContainer>
  ),
);

export default OptionItem;
