import { NavigationService } from '../../services';
import screens from '../../navigation/screens';
import transactionAPI from '../../api/transaction';

export const onOpenChat = async (chatId, groupId, userId) => {
  NavigationService.push(screens.ChatRoom, { chatId });
};

export const onOpenItemDetails = (itemId) => {
  NavigationService.push(screens.ItemDetails, { itemId });
};

export const onOpenWishDetails = async (wishId) => {
  NavigationService.push(screens.WishDetails, { wishId });
};

export const onOpenTransactionDetails = async (transactionId, transactionType) => {
  try {
    const data = await transactionAPI.getTransactionDetails(transactionId, transactionType);

    NavigationService.push(screens.TransactionDetails, { transaction: data });
  } catch (e) {
  }
};

export const onOpenUserProfile = async (userId, profile_image, name) => {
  NavigationService.push(
    screens.UserProfile,
    {
      userId,
      profile_image,
      name,
    },
    userId.toString(),
  );
};

export const onOpenBirthdayNotification = async (bonus) => {
  NavigationService.push(screens.BirthdayRewardDetails, { bonus });
};
