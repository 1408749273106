import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { ContentContainer, SearchBarContainer } from './styles';
import { SearchInput } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import SectionList from './SectionList';

class Brands extends React.Component {
  state = {
    searchValue: '',
    selectedBrands: [],
  };

  componentDidMount() {
    const { navigation, route } = this.props;

    const selectedBrands = route.params.selectedBrands;
    this.setState({ selectedBrands });

    navigation.setParams({ onSubmit: this.onSubmit });
  }

  onSubmit = () => {
    const { selectedBrands } = this.state;
    const { navigation, route } = this.props;

    const onSelectBrands = route.params.onSelectBrands;
    onSelectBrands(selectedBrands);
    navigation.goBack();
  };

  selectBrand = (brand) => {
    const { selectedBrands } = this.state;
    this.setState({
      selectedBrands: R.includes(brand, selectedBrands)
        ? selectedBrands.filter((item) => !R.equals(item, brand))
        : [...selectedBrands, brand],
    });
  };

  onChangeText = (value) => {
    this.setState({ searchValue: value });
  };

  onResetSelectedBrands = () => {
    this.setState({ selectedBrands: [] });
  };

  render() {
    const { searchValue, selectedBrands } = this.state;
    const { selectedBrand } = this.props;
    return (
      <ContentContainer>
        <SearchBarContainer>
          <SearchInput
            placeholder={strings.other.search_brand}
            value={searchValue}
            onChangeText={this.onChangeText}
          />
        </SearchBarContainer>
        <SectionList
          searchValue={searchValue}
          selectBrand={this.selectBrand}
          selectedBrands={selectedBrands}
          onResetSelectedBrands={this.onResetSelectedBrands}
        />
      </ContentContainer>
    );
  }
}

export default connect((state) => ({}))(Brands);
