import { createSelector } from 'reselect';
import * as R from 'ramda';

// export const getWallets = createSelector(
//   [
//     R.pathOr([], ['wallets', 'communityIds']),
//     R.pathOr({}, ['wallets', 'walletEntities']),
//     R.pathOr({}, ['groups', 'communityEntities']),
//   ],
//   (ids, entities, communitiesEntities) =>
//     ids.map(id => {
//       return {
//         ...entities[id],
//         group_name: R.pathOr('', [id, 'group_name'], communitiesEntities),
//         available_balance: Number(entities[id].balance_amount) - Number(entities[id].hold_amount),
//       };
//     }),
// );

export default {
  // getWallets,
};
