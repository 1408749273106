import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import T from 'prop-types';
import { colors, dimensions, rs } from '../../../../../styles';
import { Text, TouchableItem, Icon, AngleIcon } from '../../../../ReusableComponents';

const CHECK_BOX_SIZE = 20;

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    paddingVertical: dimensions.doubleMedium,
    paddingHorizontal: dimensions.medium,
    alignItems: 'center',
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  verticalSeparator: {
    width: 1,
    height: '80%',
    backgroundColor: colors.text,
    marginHorizontal: dimensions.halfMedium,
  },
  bodyText: {
    maxWidth: '85%',
  },
  checkboxMarin: {
    marginLeft: CHECK_BOX_SIZE + dimensions.medium,
  },
  checkboxContainer: {
    width: CHECK_BOX_SIZE,
    height: CHECK_BOX_SIZE,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.grayBorder,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: dimensions.medium,
  },
  checkboxContainerSelected: {
    borderColor: colors.greenDark,
    backgroundColor: colors.greenDark,
  },
});

const CheckBoxIcon = ({ selected, style }) => {
  return (
    <View style={[s.checkboxContainer, selected && s.checkboxContainerSelected, style]}>
      <Icon
        name={selected ? 'check' : 'plus'}
        size={16}
        color={selected ? colors.white : colors.grayBorder}
        withoutRTLScale
      />
    </View>
  );
};

const EndorsementItem = memo(
  ({
    isCurrentUserVerified,
    isCurrentUser,
    endorsement,
    onUnselectUserEndorsement,
    onEndorseUser,
    onOpenMembersWhoEndorsed,
  }) => {
    const { id, is_selected, title, body, count } = endorsement;
    return (
      <TouchableItem
        // disabled={count === 0}
        disabled
        style={s.contentContainer}
        // onPress={() => {
        //   onOpenMembersWhoEndorsed(endorsement);
        // }}
      >
        <View style={rs.flex}>
          <View style={s.titleContainer}>
            {isCurrentUserVerified && !isCurrentUser && (
              <TouchableItem
                onPress={() => {
                  is_selected ? onUnselectUserEndorsement(id) : onEndorseUser(id);
                }}
              >
                <CheckBoxIcon selected={endorsement.is_selected} />
              </TouchableItem>
            )}
            <Text medium semiBold>
              {title}
            </Text>
            <View style={s.verticalSeparator} />

            <Text>{count}</Text>
          </View>
          <Text
            color={colors.gray}
            style={[s.bodyText, isCurrentUserVerified && !isCurrentUser && s.checkboxMarin]}
          >
            {body}
          </Text>
        </View>
        {/*{count !== 0 && <AngleIcon />}*/}
      </TouchableItem>
    );
  },
);

EndorsementItem.propTypes = {
  endorsement: T.object.isRequired,
  onUnselectUserEndorsement: T.func.isRequired,
  onEndorseUser: T.func.isRequired,
  onOpenMembersWhoEndorsed: T.func.isRequired,
  isCurrentUserVerified: T.bool.isRequired,
  isCurrentUser: T.bool.isRequired,
};

export default EndorsementItem;
