import React, { Fragment, memo } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { colors, dimensions, rs } from '../../styles';
import CenteredView from './Containers/CenteredView';
import Text from './Text';
import FontIcon from './FontIcon';
import Button from './Buttons/Button';

const IMAGE_WIDTH = dimensions.width * 0.65;
const DEFAULT_IMAGE_HEIGHT = IMAGE_WIDTH / 2;

const getImageHeight = (image) =>
  ({
    empty_state_followers: IMAGE_WIDTH / 2.5,
    empty_state_your_items_for_sale: IMAGE_WIDTH / 2.5,
    empty_state_your_items_purchased: IMAGE_WIDTH / 1.5,
    empty_state_wish_fulfillment: IMAGE_WIDTH / 1.67,
    empty_state_your_items_wishes: IMAGE_WIDTH / 1.67,
    empty_state_your_items_sold: IMAGE_WIDTH / 2,
    empty_state_search: IMAGE_WIDTH / 1.5,
  }[image] || DEFAULT_IMAGE_HEIGHT);

const s = StyleSheet.create({
  imageContainer: {
    height: DEFAULT_IMAGE_HEIGHT + 60,
    justifyContent: 'flex-end',
  },
  image: {
    width: IMAGE_WIDTH,
    height: 200,
    alignSelf: 'center',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    height: 40,
    width: dimensions.width,
    paddingHorizontal: dimensions.doubleMedium,
    marginTop: dimensions.medium,
  },
});

const EmptyList = memo(({ text }) => (
  <CenteredView style={{ padding: dimensions.xLarge }}>
    <Text medium color={colors.gray} alignCenter>
      {text}
    </Text>
  </CenteredView>
));

export const EmptyListWithIcon = memo(({ name, size, text, buttonTitle, onPress }) => (
  <Fragment>
    <FontIcon
      style={rs.bigMarginBottom}
      name={name}
      size={size || dimensions.height * 0.1}
      color={colors.gray}
      withoutRTLScale
    />
    <Text medium color={colors.gray} alignCenter>
      {text}
    </Text>
    {!!buttonTitle && (
      <View style={s.buttonContainer}>
        <Button title={buttonTitle} onPress={onPress} />
      </View>
    )}
  </Fragment>
));

export const EmptyListWithImage = memo(({ image, size, text, buttonTitle, onPress }) => (
  <Fragment>
    <View style={s.imageContainer}>
      <Image style={[s.image]} source={{ uri: image }} resizeMode="contain" />
    </View>

    {!!text && (
      <Text medium alignCenter style={rs.bigMarginTop}>
        {text}
      </Text>
    )}
    {!!buttonTitle && (
      <View style={s.buttonContainer}>
        <Button title={buttonTitle} onPress={onPress} />
      </View>
    )}
  </Fragment>
));

export default EmptyList;
