import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { View, StyleSheet } from 'react-native';
import T from 'prop-types';
import { chatsOperations, chatsSelectors } from '../../../../store/chats';
import {
  Container,
  FilterHorizontalList,
  FilterOptions,
  FilterButton,
  Separator,
  EmptyListWithIcon,
  EmptyListWithImage,
  HeaderContainer,
} from '../../../ReusableComponents';
import { colors, rs } from '../../../../styles';
import { headerStyleTransparentWithBackground } from '../../../../styles/headerStyle';
import ChatsList from './components/ChatsList';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import screens from '../../../../navigation/screens';
import { groupsSelectors } from '../../../../store/groups';
import chatTypes from '../../../../constants/chatTypes';
import SearchBar from '../../../ReusableComponents/SearchBar/index';
import { useDebouncedCallback } from 'use-debounce';

const s = StyleSheet.create({
  filterList: {
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayBorder,
  },
});

const Chats = ({
  groups,
  searchedChats,

  isLoadingSearchedChats,
  isLoadingMoreSearchedChats,

  getSearchedChats,
  resetSearchedChats,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isRefreshing, setRefreshing] = useState(false);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  useEffect(() => {
    getSearchedChats({
      groupIds: selectedGroupIds,
      types: selectedTypes,
      isLoadMore: false,
    });
    return () => {
      resetSearchedChats();
    };
  }, [selectedGroupIds, selectedTypes]);

  const [getChatsDebounce] = useDebouncedCallback((newSearchTerm) => {
    getSearchedChats({
      groupIds: selectedGroupIds,
      types: selectedTypes,
      searchTerm: newSearchTerm,
      isLoadMore: false,
    });
  }, 400);

  const onOpenChat = (chatId) => {
    NavigationService.navigate(screens.ChatRoom, { chatId });
  };

  const onOpenUserProfile = (userId, name, avatar) => {
    NavigationService.navigate(screens.UserProfile, {
      name,
      userId,
      profile_image: avatar,
    });
  };

  const onRefresh = async () => {
    setRefreshing(true);
    await getSearchedChats({
      groupIds: selectedGroupIds,
      types: selectedTypes,
      searchTerm,
      isLoadMore: false,
    });
    setRefreshing(false);
  };

  const onLoadMore = async () => {
    await getSearchedChats({
      groupIds: selectedGroupIds,
      types: selectedTypes,
      searchTerm,
      isLoadMore: true,
    });
  };

  const onChangeSearch = (value) => {
    setSearchTerm(value);
    getChatsDebounce(value);
  };

  const chatTypeOptions = [
    {
      title: strings.chats.sell,
      key: chatTypes.SELL_CHAT,
    },
    {
      title: strings.chats.buy,
      key: chatTypes.BUY_CHAT,
    },
    {
      title: strings.chats.personal,
      key: chatTypes.PERSONAL_CHAT,
    },
  ];

  return (
    <Container>
      <HeaderContainer>
        <SearchBar
          showCancel
          alwaysShowCancel
          value={searchTerm}
          placeholder={strings.other.search}
          onCancel={() => NavigationService.goBack()}
          onChangeText={onChangeSearch}
          enablesReturnKeyAutomatically
          returnKeyType="search"
        />
      </HeaderContainer>
      <View style={rs.row}>
        <FilterHorizontalList style={s.filterList} contentContainerStyle={rs.fullWidth}>
          <Fragment>
            <FilterOptions
              options={groups}
              optionKeyName="id"
              optionTitleName="group_name"
              selectedOptionIds={selectedGroupIds}
              onChange={setSelectedGroupIds}
            />
            <FilterOptions
              options={chatTypeOptions}
              optionKeyName="key"
              optionTitleName="title"
              selectedOptionIds={selectedTypes}
              onChange={setSelectedTypes}
            />
            <FilterButton
              primaryButtonTitle={strings.filters.group}
              options={groups}
              optionKeyName="id"
              optionTitleName="group_name"
              selectedOptionIds={selectedGroupIds}
              onChange={setSelectedGroupIds}
            />
            <FilterButton
              primaryButtonTitle={strings.chats.type}
              options={chatTypeOptions}
              optionKeyName="key"
              optionTitleName="title"
              selectedOptionIds={selectedTypes}
              onChange={setSelectedTypes}
              filterTitle={strings.list_filters.filter_by_chat_type}
            />
          </Fragment>
        </FilterHorizontalList>
      </View>

      <ChatsList
        isHideArchiveButton
        isLoading={isLoadingSearchedChats}
        isLoadingMore={isLoadingMoreSearchedChats}
        chatList={isLoadingSearchedChats && !isRefreshing ? [] : searchedChats}
        onOpenChat={onOpenChat}
        onOpenUserProfile={onOpenUserProfile}
        onLoadMore={onLoadMore}
        isRefreshing={isRefreshing}
        onRefresh={onRefresh}
        ListEmptyComponent={() => (
          <EmptyListWithImage
            image="empty_state_search"
            text={strings.emptyLists.empty_chat_search}
          />
        )}
      />
    </Container>
  );
};

Chats.propTypes = {
  groups: T.array,
  searchedChats: T.array,
  isLoadingSearchedChats: T.bool,
  isLoadingMoreSearchedChats: T.bool,
  getSearchedChats: T.func,
  resetSearchedChats: T.func,
};

export default connect(
  (state) => ({
    isLoadingSearchedChats: state.chats.searchedChats.isLoading,
    isLoadingMoreSearchedChats: state.chats.searchedChats.isLoadingMore,
    searchedChats: chatsSelectors.getSearchedChats(state),
    groups: groupsSelectors.getCommunityGroups(state),
  }),
  (dispatch) => ({
    getSearchedChats: ({ groupIds, types, isLoadMore, searchTerm = '' }) =>
      dispatch(chatsOperations.getSearchedChats({ groupIds, types, isLoadMore, searchTerm })),
    resetSearchedChats: () => dispatch(chatsOperations.resetSearchedChats()),
  }),
)(Chats);
