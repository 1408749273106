import React, { Fragment, memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { FontIcon, GoalIcon, Text, TouchableItem, FastImage } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import rewardAmauntTypes from '../../../../../constants/rewardAmauntTypes';
import { colors, dimensions, rs } from '../../../../../styles';
import { NavigationService } from '../../../../../services';
import screens from '../../../../../navigation/screens';

const itemSize = Math.round(dimensions.width / 3) - 1.5 * dimensions.medium;

const s = StyleSheet.create({
  rootContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    // backgroundColor: colors.grayLighter,
  },
  contentContainer: {
    width: itemSize,
    alignItems: 'center',
    marginHorizontal: dimensions.halfMedium,
    paddingBottom: dimensions.doubleMedium,
  },
  mainContainer: {
    width: '80%',
    flex: 1,
    paddingTop: dimensions.halfMedium,
    paddingBottom: 6,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLight,
  },
  infoText: {
    marginVertical: 4,
  },
  image: {
    height: 100,
    width: 100,
  },
});

const RewardItem = memo(({ item, onPress }) => {
  const {
    icon_url,
    achieved_icon_url,
    is_reached,

    amountType,
    title,
    text,
    achieved,
    number,
    max_reward_amount,
    given_reward_amount,
    tokenName,
  } = item;
  return (
    <TouchableItem style={s.contentContainer} onPress={() => onPress(item)} disabled={!is_reached}>
      <Fragment>
        <FastImage
          style={s.image}
          resizeMode={'contain'}
          priority={FastImage.priority.high}
          source={{ uri: is_reached ? achieved_icon_url : icon_url }}
        />

        {/*<View style={s.mainContainer}>*/}
        {/*  <Text xsmall alignCenter semiBold color={colors.gray}>*/}
        {/*    {title}*/}
        {/*  </Text>*/}
        {/*</View>*/}
      </Fragment>
    </TouchableItem>
  );
});

const RewardRow = memo(({ section, index }) => {
  const onRewardPress = (reward) => {
    NavigationService.navigate(
      reward.is_reached ? screens.AchievedRewardDetails : screens.NotAchievedRewardDetails,
      { reward },
    );
  };

  if (index % 3 !== 0) return null;

  const items = [];

  for (let i = index; i < index + 3; i++) {
    if (i >= section.data.length) {
      break;
    }

    items.push(
      <RewardItem key={section.data[i].id} item={section.data[i]} onPress={onRewardPress} />,
    );
  }

  return <View style={s.rootContainer}>{items}</View>;
});

export default RewardRow;
