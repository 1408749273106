import { Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');

const guidelineBaseWidth = 375; // iPhone 6 width
const guidelineBaseHeight = 667;

const realWidth = height > width ? width : height;

const scale = size => Math.round((width / guidelineBaseWidth) * size);
const verticalScale = size => Math.round((height / guidelineBaseHeight) * size);
const moderateScale = (size, factor = 0.5) => Math.round(size + (scale(size) - size) * factor);


const scaleFont = (fontSize) => (
    Math.round((fontSize * realWidth) / guidelineBaseWidth)
);

export { scale, verticalScale, moderateScale, scaleFont };
