import api from './api';
import { CHATS_LIMIT, MESSAGES_LIMIT } from '../constants/listLimits';
import { getImageType } from '../utils/stringHelper';

export default {
  getChatsByFilters: ({
    groupIds = [],
    types = [],
    isOnlyArchivedChats = false,
    searchTerm = '',
    skip = 0,
  }) =>
    api.post('chats/getChatsByFilters', {
      group_ids: groupIds,
      types,
      is_only_archived_chats: isOnlyArchivedChats,
      search_term: searchTerm,
      skip,
      limit: CHATS_LIMIT,
    }),

  getSupportChatsForAdmins: ({ searchTerm = '', skip = 0 }) =>
    api.post('admin/getSupportChatsForAdmins', {
      search_term: searchTerm,
      skip,
      limit: CHATS_LIMIT,
    }),

  getMessages: ({ chatId, skip = 0 }) =>
    api.get(`chats/getMessages/${chatId}?limit=${MESSAGES_LIMIT}&skip=${skip}`),

  getChatByItemId: (itemId) => api.get(`chats/getChatByItemId/${itemId}`),

  getPersonalChatByUserId: (userId) => api.get(`chats/getPersonalChatByUserId/${userId}`),

  getSupportChat: () => api.get(`chats/getSupportChat`),

  getChatById: (chatId) => api.get(`chats/getChatById/${chatId}`),

  getUnreadCountForAllChats: () => api.get(`chats/getUnreadCountForAllChats`),

  getUnreadCountForAdminSupportChats: () => api.get(`admin/getUnreadCountForAdminSupportChats`),

  markAsRead: (chatId) =>
    api.post(`chats/markAsRead`, {
      chat_id: chatId,
    }),

  markAsReadForSupportAdmins: (chatId) =>
    api.post(`admin/markAsReadForSupportAdmins`, {
      chat_id: chatId,
    }),

  createPersonalChat: ({ userId, payload, payloadType, wishId }) => {
    return api.post(`chats/createPersonalChat`, {
      interlocutor_id: userId,
      payload,
      payload_type: payloadType,
      wish_id: wishId,
    });
  },

  createItemChat: ({ itemId, payload, payloadType }) =>
    api.post(`chats/createItemChat`, {
      item_id: itemId,
      payload,
      payload_type: payloadType,
    }),

  sendMessage: ({ messageId, chatId, payload, payloadType, wishId, extra }) => {
    return api.post(`chats/sendMessage`, {
      message_id: messageId,
      chat_id: chatId,
      payload,
      payload_type: payloadType,
      wish_id: wishId,
      extra,
    });
  },

  uploadChatImage: (image) => {
    const form = new FormData();

    form.append('image', {
      uri: image,
      name: `image.${getImageType(image)}`,
      type: `image/${getImageType(image)}`,
    });

    return api.post(`uploadChatImage`, form);
  },

  archiveChats: ({ chatIds }) =>
    api.post(`chats/archiveChats`, {
      chat_ids: chatIds,
    }),

  unarchiveChats: ({ chatIds }) =>
    api.post(`chats/unarchiveChats`, {
      chat_ids: chatIds,
    }),

  sendMessageIsRead: (chatId) => api.post('chats/sendMessageIsRead', { chat_id: chatId }),
  sendUserTypingMessage: (chatId) => api.post('chats/sendUserTypingMessage', { chat_id: chatId }),
};
