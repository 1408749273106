import strings from '../../localization';

export const getAvailabilitiesForList = () => {
  // TODO: strings need access to the components' flow to show correct localization,
  // otherwise it always be english
  return [
    { id: 0, label: strings.shipping.daily },
    { id: 1, label: strings.shipping.weekly },
    { id: 2, label: strings.shipping.bi_weekly },
    { id: 3, label: strings.shipping.monthly },
    { id: 4, label: strings.shipping.randomly },
  ];
};
