/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { I18nManager } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import * as styled from './styles';
import { colors, dimensions } from '../../../../styles/index';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import { isAndroid, isIos, isWeb } from '../../../../utils/detectDevice';

const { BackButtonContainer, CancelTitleText } = styled;

const BackButton = ({ transitioning, isCancel, isClose, goTo, onPress, isWhite }) => {
  const onButtonPress = () =>
    goTo ? NavigationService.navigate(goTo) : NavigationService.goBack();

  return (
    <BackButtonContainer onPress={onPress || onButtonPress} disabled={transitioning}>
      {isCancel && <CancelTitleText>{strings.common.cancel}</CancelTitleText>}

      {isClose && (
        <Icon
          size={31}
          name="ios-close"
          style={{
            color: isWhite ? colors.white : colors.gray,
            transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
          }}
        />
      )}

      {isAndroid && !isClose && !isCancel && (
        <MaterialIcons
          size={24}
          name="arrow-back"
          style={{
            paddingLeft: 6,
            color: isWhite ? colors.white : colors.blueLight,
            transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
          }}
        />
      )}

      {(isIos || isWeb) && !isClose && !isCancel && (
        <Icon
          size={31}
          name="ios-arrow-back"
          style={{
            color: isWhite ? colors.white : colors.blueLight,
            transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
          }}
        />
      )}
    </BackButtonContainer>
  );
};

export default BackButton;
