import styled from 'styled-components/native';
import { Text, TouchableItem } from '../../../../ReusableComponents';
import { colors, dimensions, fontSizes } from '../../../../../styles';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  buttonContainer: {
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.white,
  },
  buttonTitle: {
    minWidth: 100,
  },
  multipleSupplyDescriptionText: {
    lineHeight: 20,
  },
});

export const Box = styled.View`
  width: 100%;
  padding-top: ${({ withoutTopPadding }) => (withoutTopPadding ? 0 : dimensions.medium)};
  padding-bottom: ${dimensions.medium};
  padding-horizontal: ${({ withoutPadding }) => (withoutPadding ? 0 : dimensions.medium)};
  background-color: ${({ gray }) => (gray ? colors.grayLighter : colors.white)};
`;

export const BoxButton = styled(TouchableItem)`
  width: 100%;
  padding-vertical: ${dimensions.medium};
  padding-horizontal: ${dimensions.medium};
  justify-content: center;
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ withoutMargin }) => withoutMargin ? 0 : dimensions.small};
`;

export const Title = styled(Text)`
  color: ${colors.text};
  font-size: ${fontSizes.medium};
  padding-horizontal: ${({ withPadding }) => (withPadding ? dimensions.halfMedium : 0)};
`;

export const TypeContainer = styled.View`
  height: ${dimensions.commonHeight};
  width: 150px;
  flex-direction: row;
  border-radius: ${dimensions.commonHeight / 2};
  overflow: hidden;
`;

export const TypeText = styled(Text)`
  color: ${({ isSelected }) => (isSelected ? colors.white : colors.text)};
`;

export const ItemRulesButtonContainer = styled.View`
  width: 100%;
  padding-horizontal: ${dimensions.medium};
  padding-vertical: ${dimensions.halfMedium};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const RequiredText = styled(Text)``;
