import api from './api';
import { COMMUNITY_MEMBERS_LIMIT } from '../constants/listLimits';

export default {
  followUser: userId =>
    api.post(`followers/followUser`, {
      user_id_to_follow: userId,
    }),

  unfollowUser: userId =>
    api.post(`followers/unfollowUser`, {
      user_id_to_unfollow: userId,
    }),

  getUserFollowers: ({ userId, searchTerm, skip = 0, limit = COMMUNITY_MEMBERS_LIMIT }) =>
    api.get(
      `followers/getUserFollowers/${userId}?limit=${limit}&skip=${skip}&search_term=${searchTerm}`,
    ),

  getUserFollowings: ({ userId, searchTerm, skip = 0, limit = COMMUNITY_MEMBERS_LIMIT }) =>
    api.get(
      `followers/getUserFollowings/${userId}?limit=${limit}&skip=${skip}&search_term=${searchTerm}`,
    ),

  getFollowersCounts: (userId) => api.get(`followers/getFollowersCounts/${userId}`),
};
