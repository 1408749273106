import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, LotsList, Text, View } from '../../../ReusableComponents';
import s from './styles';
import headerStyle from '../../../../styles/headerStyle';
import groupsApi from '../../../../api/groups';
import { LOTS_LIMIT } from '../../../../constants/listLimits';
import strings from '../../../../localization';

const FeedSoldItems = ({ doubleColumnLists }) => {
  const [lots, setLots] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [isExistMore, setExistMore] = useState(true);

  useEffect(() => {
    onFetch();
  }, []);

  const onFetch = async () => {
    setLoading(true);

    const { items, total_items_count } = await groupsApi.getCommunitySoldItemsByFilters({});
    setLots(items);
    setLoading(false);
    setExistMore(items.length === LOTS_LIMIT);
  };

  const onLoadMore = async () => {
    if (!isExistMore || isLoadingMore) return;
    setLoadingMore(true);
    const { items } = await groupsApi.getCommunitySoldItemsByFilters({
      skip: lots.length,
    });
    setLots([...lots, ...items]);
    setExistMore(items.length === LOTS_LIMIT);
    setLoadingMore(false);
  };

  const ListHeaderComponent = () => (
    <Fragment>
      <Text semiBold style={s.soldDescriptionTitleText}>
        {strings.communities.sold_items_description_title
          .replace('XX', 'totalItemsCount')
          .replace('YY', 'groupName')}
      </Text>
      <Text large style={s.soldDescriptionText}>
        {strings.communities.sold_items_description}
      </Text>
    </Fragment>
  );

  return (
    <Container>
      <LotsList
        keyExtractor={(item) => item.offer_id}
        doubleColumnLists={doubleColumnLists}
        data={lots}
        onLoadMore={onLoadMore}
        isLoadingMore={isLoadingMore}
        isLoading={isLoading}
        // ListHeaderComponent={ListHeaderComponent}
        isSoldList
      />
    </Container>
  );
};

export default connect((state) => ({
  doubleColumnLists: state.app.doubleColumnLists,
}))(FeedSoldItems);
