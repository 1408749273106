import React, { Fragment } from 'react';
import styled from 'styled-components/native';
import { Text, Spinner } from '../../../../ReusableComponents';
import { colors, dimensions, fontSizes } from '../../../../../styles';

const ButtonContainer = styled.TouchableOpacity`
  padding-horizontal: ${dimensions.medium};
  min-width: 100px;
  height: 28;
  align-items: center;
  justify-content: center;
  background-color: ${({ disabled }) => (disabled ? colors.grayLighter : colors.blueLight)};
  border-radius: 14px;
  margin-right: ${({ withMarginRight }) => (withMarginRight ? dimensions.medium : 0)};
`;

const ButtonText = styled(Text)`
  color: ${({ disabled }) => (disabled ? colors.gray : colors.white)};
  font-size: ${fontSizes.small};
`;

const SecondaryButtonContainer = styled(ButtonContainer)`
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${({ disabled }) => (disabled ? colors.grayBorder : colors.blueLighter)};
  margin-right: ${({ withMarginRight }) => (withMarginRight ? dimensions.halfMedium : 0)};
`;

const SecondaryButtonText = styled(ButtonText)`
  color: ${({ disabled }) => (disabled ? colors.gray : colors.blueLighter)};
`;

const Button = ({
  disabled,
  title,
  onPress,
  withMarginRight,
  secondaryType = false,
  isLoading,
}) => (
  <Fragment>
    {secondaryType ? (
      <SecondaryButtonContainer
        disabled={disabled}
        withMarginRight={withMarginRight}
        onPress={onPress}
      >
        {isLoading ? (
          <Spinner color={colors.white} />
        ) : (
          <SecondaryButtonText disabled={disabled}>{title}</SecondaryButtonText>
        )}
      </SecondaryButtonContainer>
    ) : (
      <ButtonContainer disabled={disabled} withMarginRight={withMarginRight} onPress={onPress}>
        {isLoading ? (
          <Spinner color={colors.white} />
        ) : (
          <ButtonText disabled={disabled}>{title}</ButtonText>
        )}
      </ButtonContainer>
    )}
  </Fragment>
);

export default Button;
