import React, { memo, Fragment } from 'react';
import T from 'prop-types';
import { FontIcon, Input, Separator, Text } from '../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../styles';
import Title from './Title';
import { Box, TitleContainer } from './styles';
import strings from '../../../../../localization';
import { StyleSheet, View } from 'react-native';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    paddingHorizontal: dimensions.medium,
    justifyContent: 'space-between',
    width: '100%',
  },
  input: {
    width: '84%',
    height: 100,
    paddingTop: 20,
    textAlignVertical: 'top',
  },
  charactersCountText: {
    paddingTop: 20,
  },
});

const DescriptionInput = memo(({ value, placeholder, onChangeText }) => (
  <View style={s.contentContainer}>
    <Input
      style={s.input}
      placeholder={placeholder}
      multiline
      numberOfLines={3}
      maxLength={250}
      maxHeight={100}
      onChangeText={onChangeText}
      value={value}
      underlineColorAndroid="transparent"
    />
    <Text xsmall style={s.charactersCountText} color={colors.gray}>
      {value.length}/250
    </Text>
  </View>
));

DescriptionInput.propTypes = {
  value: T.string,
  onChangeText: T.func,
};

export default DescriptionInput;
