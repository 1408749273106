import React, { Fragment, memo } from 'react';
import T from 'prop-types';
import { Image, StyleSheet } from 'react-native';
import { Text, TouchableItem, View } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import { colors, dimensions, rs } from '../../../../../styles';
import { numberWithCommas } from '../../../../../utils/stringHelper';

const s = StyleSheet.create({
  contentContainer: {},
  itemImage: {
    height: 44,
    width: 44,
    backgroundColor: colors.grayLighter,
    borderRadius: 3,
    marginRight: dimensions.medium,
  },
  button: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: dimensions.halfMedium,
    backgroundColor: colors.white,
    paddingHorizontal: dimensions.medium,
  },
});

const CampusItem = memo(({ item, onPress }) => {
  const { title, school_image, items_count } = item;
  return (
    <TouchableItem style={s.button} onPress={() => onPress(item)}>
      <Fragment>
        <Image
          source={{ uri: school_image || 'city_placeholder' }}
          style={s.itemImage}
          resizeMode="cover"
        />
        <View>
          <Text medium numberOfLines={1} ellipsizeMode="tail">
            {title}
          </Text>
          {!!items_count && (
            <Text color={colors.gray} style={rs.verySmallPaddingTop}>
              {`${numberWithCommas(items_count)} ${strings.groups.active_items}`}
            </Text>
          )}
        </View>
      </Fragment>
    </TouchableItem>
  );
});

CampusItem.propTypes = {
  onPress: T.func,
  id: T.number,
  activeItemsCount: T.number,
  name: T.string,
  image: T.string,
};

export default CampusItem;
