import * as R from 'ramda';
import groupsApi from '../../api/groups';
import membersApi from '../../api/members';
import {
  setCommunityMembers,
  setMoreCommunityMembers,
  startLoadingCommunityMembers,
  startLoadingMoreCommunityMembers,
  errorLoadingCommunityMembers,
  errorLoadingMoreCommunityMembers,
  setCommunityMembersSearchHistory,
  removeMemberFromSearchHistory,
} from './actions';
import { getCommunityId } from '../communityInfo/selectors';
import { normalize } from '../../utils/stateHelper';
import { SOMETHING_WENT_WRONG } from '../../../config/appConstants/httpCodes';
import { ModalsService, ToastsService } from '../../services';
import { groupsOperations } from '../groups';
import strings from '../../localization';
import modalTypes from '../../constants/modalTypes';

const getCommunityMembers = ({
  searchTerm = '',
  filterBy = '',
  excludeCurrentUser = false,
}) => async (dispatch, getState) => {
  dispatch(startLoadingCommunityMembers());

  try {
    const communityId = getCommunityId(getState());

    const data = await groupsApi.getCommunityMembers({
      communityId,
      searchTerm,
      filterBy,
      excludeCurrentUser,
    });

    const normalizedCommunityMembers = normalize(
      data,
      'communityMemberIds',
      'communityMemberEntities',
    );

    dispatch(setCommunityMembers(normalizedCommunityMembers));
  } catch (err) {
    dispatch(errorLoadingCommunityMembers(err));
  }
};

const getRecentMembersSearches = () => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  try {
    const data = await membersApi.getRecentMembersSearches(communityId);

    const normalizedCommunityMembers = normalize(
      data,
      'communityMemberSearchHistoryIds',
      'communityMemberSearchHistoryEntities',
    );

    dispatch(setCommunityMembersSearchHistory(normalizedCommunityMembers));
  } catch (err) {}
};

const saveMemberSearch = ({ userId }) => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  try {
    await membersApi.saveMemberSearch({ userId, communityId });
  } catch (err) {}
};

const removeMemberSearch = ({ userId, memberId }) => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  try {
    dispatch(removeMemberFromSearchHistory({ memberId }));

    await membersApi.removeMemberSearch({ userId, communityId });
  } catch (err) {}
};

const getMoreCommunityMembers = (searchTerm = '') => async (dispatch, getState) => {
  const { communityMembers } = getState();
  const { isLoading, isLoadingMore, existMore } = communityMembers;
  const skip = communityMembers.communityMemberIds.length;

  if (isLoadingMore || isLoading || !existMore) return;
  dispatch(startLoadingMoreCommunityMembers());

  try {
    const communityId = getCommunityId(getState());

    const data = await groupsApi.getCommunityMembers({ communityId, searchTerm, skip });

    const normalizedCommunityMembers = normalize(
      data,
      'communityMemberIds',
      'communityMemberEntities',
    );

    dispatch(setMoreCommunityMembers(normalizedCommunityMembers));
  } catch (err) {
    dispatch(errorLoadingMoreCommunityMembers(err));
  }
};

const getUserInfo = (userId) => async (dispatch, getState) => {
  try {
    return await membersApi.getUserInfo(userId);
  } catch (err) {
    if (R.path(['response', 'status'], err) === 406) {
      ModalsService.showModal(modalTypes.USER_LEFT_COMMUNITY);
    }
    return null;
  }
};

const getPendingApprovalGroupMembers = (communityId, searchTerm = '', skip) => async (
  dispatch,
  getState,
) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    return await membersApi.getPendingApprovalGroupMembers(communityId, userId, searchTerm, skip);
  } catch (err) {
    return [];
  }
};
const getDeclinedGroupMembers = (communityId, searchTerm = '', skip) => async (
  dispatch,
  getState,
) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    return await membersApi.getDeclinedGroupMembers(communityId, userId, searchTerm, skip);
  } catch (err) {
    return [];
  }
};

const declineGroupMember = (userId, communityId) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const adminId = userInfo.id;

  try {
    return await membersApi.declineGroupMember({ adminId, userId, groupId: communityId });
  } catch (e) {
    if (R.path(['response', 'status'], e) === SOMETHING_WENT_WRONG) {
      ToastsService.showError(strings.error_messages.another_admin_reviewed_this_request);
    }
    return null;
  }
};

const approveGroupMember = (userId, communityId) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const adminId = userInfo.id;

  try {
    const data = await membersApi.approveGroupMember({ adminId, userId, groupId: communityId });
    dispatch(groupsOperations.increaseMembersCount({ groupId: communityId }));
    return data;
  } catch (e) {
    if (R.path(['response', 'status'], e) === SOMETHING_WENT_WRONG) {
      ToastsService.showError(strings.error_messages.another_admin_reviewed_this_request);
    }
    return null;
  }
};

export default {
  getCommunityMembers,
  getMoreCommunityMembers,
  getRecentMembersSearches,
  saveMemberSearch,
  removeMemberSearch,
  getUserInfo,
  getPendingApprovalGroupMembers,
  getDeclinedGroupMembers,
  declineGroupMember,
  approveGroupMember,
};
