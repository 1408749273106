import { createSelector } from 'reselect';
import * as R from 'ramda';
import { getAppLanguage } from '../app/selectors';
import { BRAZIL_COMMUNITY } from '../../constants/communities';

export const getCommunityId = (state) => {
  return R.pathOr(null, ['communityInfo', 'id'], state);
};

export const getIsBrazilCommunity = createSelector(
  [getCommunityId],
  (communityId) => communityId === BRAZIL_COMMUNITY,
);

export const getHomeActionSliderSettings = createSelector(
  [R.pathOr({}, ['communityInfo', 'home_settings', 'home_action_slider_settings'])],
  (settings) => settings,
);

export const getIsUserAdmin = createSelector(
  [R.pathOr([], ['communityInfo', 'community_admins']), R.pathOr({}, ['userInfo', 'id'])],
  (communityAdmins, userid) => {
    const index = R.findIndex((item) => parseInt(item.id) === userid)(communityAdmins);
    return index !== -1;
  },
);

// TODO: need to revert changes from this commit right after "Shipping" section localization
const faqIdsBlackList = {
  en: ['22', '23', '24', '25', '26', '27'],
  he: ['18', '19', '20', '21'],
  br: ['22', '23', '24', '25', '26', '27'],
  ge: ['22', '23', '24', '25', '26', '27'],
};

export const getFAQSectionList = createSelector(
  [(_, data) => data, getAppLanguage],
  (data, language) => {
    const sections = {
      // [3]: { parentCategory: { id, title, name}, childCategories: []}
    };

    data.forEach((item) => {
      const { id, topicId, topicTitle, title, description } = item;
      const isVisible = !faqIdsBlackList[language].includes(id);

      if (isVisible) {
        sections[topicId] = {
          topicTitle: topicTitle[language],
          data: R.isNil(R.path([topicId, 'data'], sections))
            ? [{ id, title: title[language], description: description[language] }]
            : [
                ...sections[topicId].data,
                { id, title: title[language], description: description[language] },
              ],
        };
      }
    });

    const sectionList = [];

    R.forEachObjIndexed((val, key) => {
      sectionList.push({
        title: val.topicTitle,
        data: val.data,
      });
    }, sections);

    return sectionList;
  },
);

export const getCurrencyCode = createSelector(
  [R.pathOr(null, ['communityInfo', 'zone_currency_code'])],
  (code) => code,
);

export default {
  getCommunityId,
  getHomeActionSliderSettings,
  getIsUserAdmin,
  getFAQSectionList,
  getCurrencyCode,
};
