import React, { memo } from 'react';
import T from 'prop-types';
import { View } from 'react-native';
import s from './styles';
import { colors, rs } from '../../../../../../styles';
import { TouchableItem, Icon, Text, FontIcon } from '../../../../../ReusableComponents';
import NavigationService from '../../../../../../services/NavigationService';
import screens from '../../../../../../navigation/screens';
import strings from '../../../../../../localization';
import { connect } from 'react-redux';

const FilterButton = memo(({ feedLotFilters, isSquare }) => {
  let selectedFiltersAmount = 0;

  const {
    selectedCities,
    selectedCategoryIds,
    selectedSizes,
    selectedBrands,
    selectedConditions,
    distance,
    isSearchByDiscount,
    isSearchByFollowings,
    isOnlyFeaturedItems,
    // isSearchByFavorites,
    priceRange,
  } = feedLotFilters;

  // if (selectedCities.length) selectedFiltersAmount += 1;
  // if (selectedCategoryIds.length) selectedFiltersAmount += 1;
  if (selectedSizes.length) selectedFiltersAmount += 1;
  if (selectedBrands.length) selectedFiltersAmount += 1;
  if (selectedConditions.length) selectedFiltersAmount += 1;
  if (distance) selectedFiltersAmount += 1;
  if (isSearchByDiscount) selectedFiltersAmount += 1;
  if (isSearchByFollowings) selectedFiltersAmount += 1;
  if (isOnlyFeaturedItems) selectedFiltersAmount += 1;
  // if (isSearchByFavorites) selectedFiltersAmount += 1;
  if (priceRange[0] !== 0 || priceRange[1] !== 200) selectedFiltersAmount += 1;

  const onSelectFilters = () => {
    NavigationService.navigate(screens.FilterStack);
  };
  return (
    <TouchableItem key="SELECTOR" style={[s.buttonContainer, isSquare && s.squareButtonContainer]} onPress={onSelectFilters}>
      {!!selectedFiltersAmount ? (
        <View style={[s.selectedFiltersAmountContainer, isSquare && s.squareButtonFilterIcon]}>
          <Text semiBold color={colors.white}>
            {selectedFiltersAmount}
          </Text>
        </View>
      ) : (
        <FontIcon name="filters" size={12} color={colors.darkCyan} style={[s.filterIcon, isSquare && s.squareButtonFilterIcon]} />
      )}
      <Text semiBold color={colors.gray}>
        {strings.filters.filters}
      </Text>
      {!isSquare && (
        <Icon name={'chevron-down'} size={18} color={colors.text} style={s.icon} />
      )}
    </TouchableItem>
  );
});

FilterButton.propTypes = {
  feedLotFilters: T.object,
  isSquare: T.bool,
};

FilterButton.defaultProps = {
  isSquare: false,
};

export default connect((state) => ({
  feedLotFilters: state.lots.feedLotFilters,
}))(FilterButton);
