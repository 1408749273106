import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../styles';

export default StyleSheet.create({
  itemContainer: {
    height: 40,
    justifyContent: 'center',
    paddingHorizontal: dimensions.doubleMedium + 40,
  },
  sectionContainer: {
    flexDirection: 'row',
    height: 60,
    alignItems: 'center',
  },
  iconContainer: {
    height: 40,
    width: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.grayLighter,
    marginHorizontal: dimensions.medium,
  },
  activeItemContainer: {
    backgroundColor: colors.grayLighter,
  },
});
