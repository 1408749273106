import React, { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { PlaceholderLine, Fade } from 'rn-placeholder';
import { colors, dimensions, rs } from '../../../../../styles';
import { isIphoneX, isAndroid } from '../../../../../utils/detectDevice';
import { UserInfo, TouchableItem, FontIcon, Text } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import { NavigationService } from '../../../../../services';
import screens from '../../../../../navigation/screens';

const s = StyleSheet.create({
  rootContainer: { position: 'absolute', bottom: 0, left: 0, right: 0 },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: dimensions.medium,
    paddingBottom: isIphoneX ? dimensions.medium : 0,
    width: '100%',
    backgroundColor: colors.white,
    height: isIphoneX ? 82 : 70,
    borderTopLeftRadius: 22,
    borderTopRightRadius: 22,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 18,
    },
    shadowOpacity: 0.42,
    shadowRadius: 20,
    elevation: 4,
    borderWidth: isAndroid ? dimensions.hairline : 0,
    borderColor: colors.grayLight,
  },
  discount: {
    flexDirection: 'row',
    height: 28,
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: dimensions.medium,
    borderRadius: 14,
  },
  placeholderContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: dimensions.medium,
  },
  placeholderInner: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

const BottomBar = ({
  isWish,
  userAvatar,
  userName,
  userId,
  isCurrentUser,
  isCurrentUserBuyer,
  getStatusButton,
  onOpenChatRoom,
  onDiscountPress,
  isUnavailableDiscount,
  isActive,
  isUserBlocked = false,
  isLoading,
  children,
}) => {
  const onUserInfoPress = () => {
    // https://devunet.atlassian.net/browse/SHAREITT-1916
    // it's necessary to use [push] instead of [navigate] to prevent broken stack issues with flow:
    // User Profile -> Item details -> Similar items -> Item Details -> User Profile
    NavigationService.push(screens.UserProfile, {
      name: userName,
      userId,
      profile_image: userAvatar,
    });
  };

  const showButtons =
    !isUserBlocked && ((isActive && !isCurrentUser) || (isCurrentUserBuyer && !isActive));

  return (
    <View style={s.rootContainer}>
      <View style={s.contentContainer}>
        <UserInfo image={userAvatar} name={userName} id={userId} onPress={onUserInfoPress} />
        {isLoading ? (
          <View style={s.placeholderContainer}>
            <Fade>
              <View style={s.placeholderInner}>
                <PlaceholderLine height={22} width={50} style={[rs.marginRight, rs.bigMarginTop]} />
                <PlaceholderLine height={22} width={16} style={rs.bigMarginTop} />
              </View>
            </Fade>
          </View>
        ) : showButtons ? (
          <View style={[rs.row, rs.alignCenter]}>
            {children}
            <TouchableItem onPress={onOpenChatRoom}>
              <FontIcon name="Message-user" color={colors.text} size={30} />
            </TouchableItem>
          </View>
        ) : null}

        {isCurrentUser && isActive && !isWish && (
          <View style={[rs.row, rs.alignCenter]}>
            <TouchableItem onPress={onDiscountPress}>
              <LinearGradient
                style={s.discount}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
                colors={isUnavailableDiscount ? [colors.gray, colors.gray] : ['#A44AFE', '#39BDDC']}
              >
                <Fragment>
                  <Text color={colors.white}>{strings.discounts.discount_item_for_he_change}</Text>
                </Fragment>
              </LinearGradient>
            </TouchableItem>
          </View>
        )}
      </View>
    </View>
  );
};

export default BottomBar;
