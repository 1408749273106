import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';
import * as types from './types';
import { RESET_GROUP_STATE } from '../constants';

const initialState = {
  isLoading: false,
  isLoadingMore: false,
  isRefreshing: false,
  isExistMore: true,

  ids: [],
  entities: {},
};

const reducer = handleActions(
  {
    [types.FOUNDATIONS_START]: mergeDeep(() => ({
      isLoading: true,
    })),

    [types.FOUNDATIONS_REFRESH_START]: mergeDeep(() => ({
      isRefreshing: true,
    })),

    [types.FOUNDATIONS_ERROR]: mergeDeep(() => ({
      isLoading: false,
      isLoadingMore: false,
      isRefreshing: false,
    })),

    [types.FOUNDATIONS_SUCCESS]: mergeDeep(({ payload }) => ({
      entities: payload.normalizedFoundations.entities,
      ids: payload.normalizedFoundations.ids,
      isLoading: false,
      isRefreshing: false,
      isExistMore: payload.isExistMore,
    })),

    [types.FOUNDATIONS_MORE_START]: mergeDeep(() => ({
      isLoadingMore: true,
    })),

    [types.FOUNDATIONS_MORE_SUCCESS]: mergeDeep(({ payload }, state) => ({
      entities: payload.normalizedFoundations.entities,
      ids: [...state.ids, ...payload.normalizedFoundations.ids],
      isLoadingMore: false,
      isExistMore: payload.isExistMore,
    })),

    [RESET_GROUP_STATE]: () => ({
      ...initialState,
    }),
  },
  initialState,
);

export default reducer;
