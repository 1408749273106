import styled from 'styled-components/native';
import { Animated, StyleSheet } from 'react-native';
import React from 'react';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { Text, FontIcon, TouchableItem } from '../../../../../ReusableComponents';
import { colors, rs, dimensions, fontSizes } from '../../../../../../styles';
import { isRTL } from '../../../../../../utils/rtlHelper';
import { isAndroid } from '../../../../../../utils/detectDevice';
import LinearGradient from 'react-native-linear-gradient';

const { width, medium } = dimensions;

const ScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    paddingBottom: dimensions.medium,
    paddingTop: dimensions.width,
    backgroundColor: 'white',
  },
})``;

export default StyleSheet.create({
  priceContainer: {
    flexDirection: 'row',
    paddingVertical: dimensions.medium,
    backgroundColor: colors.white,
    alignItems: 'flex-end',
  },
  oldPriceText: {
    textDecorationLine: 'line-through',
    marginBottom: 1,
  },
  favoriteContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: dimensions.medium,
    height: 60,
    backgroundColor: colors.white,
  },
  titleContainer: {
    paddingHorizontal: dimensions.medium,
    justifyContent: 'space-between',
    paddingVertical: dimensions.medium,
    minHeight: 60,
    alignItems: 'flex-start',
    backgroundColor: colors.white,
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayLight,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 1,
  },
  titleWithoutShadow: {
    minHeight: 60,
    padding: dimensions.medium,
    justifyContent: 'space-between',
  },
  shippingInfoContainer: {
    paddingHorizontal: dimensions.medium,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingVertical: dimensions.medium,
    minHeight: 60,
    alignItems: 'flex-start',
    backgroundColor: colors.white,
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayLight,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 1,
  },

  shadowContainer: {
    overflow: 'hidden',
    paddingBottom: 4,
  },

  titleText: {
    maxWidth: '50%',
  },

  dateText: {
    paddingTop: 4,
  },

  shippingDistance: {
    marginLeft: 4,
  },

  sectionTitleContainer: {
    paddingHorizontal: dimensions.medium,
    height: 50,
    justifyContent: 'flex-end',
    paddingBottom: 8,
    backgroundColor: colors.grayLighter,
  },
  horizontalListTitleContainer: {
    backgroundColor: colors.grayLighter,
    paddingTop: dimensions.medium,
  },
  horizontalListContentContainer: {
    backgroundColor: colors.grayLighter,
  },

  priceImage: {
    height: 18,
    width: 18,
  },
});

export const AnimatedList = Animated.createAnimatedComponent(ScrollView);

export const ItemImageContainer = styled.View`
  width: ${width};
  height: ${width};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

export const AnimatedItemImageContainer = Animated.createAnimatedComponent(ItemImageContainer);

export const Container = styled.View`
  padding-vertical: ${medium};
  padding-horizontal: ${medium};
`;

export const SaleContainer = styled(LinearGradient)`
  opacity: 0.9;
  position: absolute;
  left: 0;
  top: -${36 + dimensions.halfMedium};
  height: ${36 + dimensions.halfMedium};
  padding-left: ${medium};
  padding-right: ${medium};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-top-right-radius: 10;
  width: 120;
`;

export const SoldContainer = styled.View`
  opacity: 0.9;
  position: absolute;
  left: 0;
  top: -${36 + dimensions.halfMedium};
  height: ${36 + dimensions.halfMedium};
  padding-left: ${medium};
  padding-right: ${medium};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-top-right-radius: 10;
  width: 120;
  background-color: ${colors.blueLight};
`;

export const FavoriteContainer = styled(TouchableItem)`
  position: absolute;
  right: ${dimensions.medium - 4};
  top: ${width - dimensions.halfMedium - 36};
  height: 36;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 18;
`;

export const OpacityView = styled.View`
  background-color: ${colors.grayDarker};
  opacity: 0.5;
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const LotName = styled(Text)`
  font-size: 22px;
  color: ${colors.black};
`;

export const DateText = styled(Text)`
  color: ${colors.gray};
`;

export const PriceContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PriceText = styled(Text)`
  margin-left: ${dimensions.halfMedium / 2};
  font-size: 32px;
  color: ${({ isDiscounted }) => (isDiscounted ? colors.orange : colors.blueLighter)};
`;

export const OldPriceText = styled(Text)`
  color: ${colors.gray};
  text-decoration-line: line-through;
`;

export const DiscountIcon = styled(MaterialCommunityIcons)`
  padding-right: ${dimensions.halfMedium};
  padding-top: 3;
`;

export const HeartIcon = styled(Ionicons)`
  padding-top: 4;
  padding-horizontal: 10;
`;

export const FavoriteText = styled(Text)`
  padding-left: ${isRTL && isAndroid ? 0 : 10};
  padding-right: ${isRTL && isAndroid ? 10 : 0};
`;

export const InfoContainer = styled.View`
  flex-direction: row;
  align-items: center;
  padding-bottom: ${dimensions.halfMedium};
`;

export const Subtitle = styled(Text)`
  font-size: ${fontSizes.large};
  padding-bottom: ${({ withPaddingBot }) => (withPaddingBot ? dimensions.medium : 0)};
`;

export const InfoIcon = styled(FontIcon).attrs({
  size: 22,
  color: colors.text,
  containerStyle: {
    minWidth: 32,
    alignItems: 'flex-start',
  },
  // withoutRTLScale: !(isRTL && isAndroid),
})``;

export const InfoSubtitle = styled(Text)`
  min-width: ${({ isSmall }) => (isSmall ? 80 : 120)};
`;

export const NameContainer = styled.View`
  justify-content: space-between;
  width: 65%;
`;

export const WishFulfillmentContainer = styled.View`
  padding-vertical: ${medium};
  padding-horizontal: ${medium};
  flex-direction: row;
  align-items: center;
`;

export const DiscountContainer = styled.View`
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;
