import React, { Fragment, memo } from 'react';
import { View } from 'react-native';
import T from 'prop-types';
import * as R from 'ramda';
import { Separator, Text } from '../../../../ReusableComponents';
import { rs, colors } from '../../../../../styles';
import { ItemRulesButtonContainer } from './styles';
import strings from '../../../../../localization';
import { isRTL } from '../../../../../utils/rtlHelper';

const WishInfo = memo(
  ({ wish }) =>
    !!wish && (
      <Fragment>
        <View style={[rs.paddingHorizontal, rs.bigPaddingVertical, rs.backgroundWhite]}>
          <Text medium semiBold alignCenter color={colors.gray}>
            {strings.lots.wish_fulfillment_for} {R.prop('wisher_name', wish)} {isRTL ? ': ' : '- '}
            {R.prop('title', wish)}
          </Text>
        </View>
      </Fragment>
    ),
);

WishInfo.propTypes = {
  wish: T.object,
};

export default WishInfo;
