import styled from 'styled-components/native';
import { colors } from '../../../styles';
import { isWeb } from '../../../utils/detectDevice';

const Container = styled.View`
  flex: 1;
  background-color: ${colors.white};
`;

export default Container;
