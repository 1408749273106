import { createSelector } from 'reselect';
import * as R from 'ramda';
import strings from '../../localization';
import locationsTypes from '../../constants/locationsTypes';

export const getUserLocations = createSelector(
  [
    R.pathOr([], ['userLocations', 'userLocationIds']),
    R.pathOr([], ['userLocations', 'userLocationEntities']),
  ],
  (ids, entities) => {
    const userLocation = ids.map((id) => entities[id]).filter(({ is_active }) => is_active);
    return R.sortWith([R.descend(R.prop('is_primary'))], userLocation);
  },
);

export const getUserPickupLocations = createSelector([getUserLocations], (locations) => {
  return locations.filter(({ type }) => type === locationsTypes.USER);
});

export const getUserShipperLocations = createSelector([getUserLocations], (locations) => {
  return locations.filter(({ type }) => type === locationsTypes.SHIPPER);
});

export const getUserLocationsSectionList = createSelector(
  [
    R.pathOr([], ['userLocations', 'userLocationIds']),
    R.pathOr([], ['userLocations', 'userLocationEntities']),
  ],
  (ids, entities) => {
    const sections = {
      // [user]: { sectionTitle: '', data: []}
    };

    ids.forEach((id) => {
      const location = entities[id];

      const { type } = location;

      sections[location.type] = {
        sectionTitle:
          type === locationsTypes.USER
            ? strings.lots.pickup_locations
            : strings.locations.shipper_addresses,
        data: R.isNil(R.path([type, 'data'], sections))
          ? [location]
          : [...sections[type].data, location],
      };
    });

    const userLocations = sections[locationsTypes.USER];
    const shipperLocations = sections[locationsTypes.SHIPPER];

    if (!R.isNil(userLocations)) {
      sections[locationsTypes.USER].data = R.sortWith(
        [R.descend(R.prop('is_primary')), R.descend(R.prop('is_active'))],
        userLocations.data,
      );
    }

    if (!R.isNil(shipperLocations)) {
      sections[locationsTypes.SHIPPER].data = R.sortWith(
        [R.descend(R.prop('is_active'))],
        shipperLocations.data,
      );
    }

    const sectionList = [];

    R.forEachObjIndexed((val, key) => {
      sectionList.push({
        title: val.sectionTitle,
        data: val.data,
      });
    }, sections);

    return R.sortWith([R.ascend(R.prop('title'))], sectionList);
  },
);

export const hasPrimaryLocation = createSelector([getUserLocations], (userLocations) => {
  const locations = userLocations.filter((userLocation) => userLocation.is_primary);
  return locations.length > 0;
});

export default {
  getUserLocations,
  getUserLocationsSectionList,
  hasPrimaryLocation,
};
