import React, { Fragment } from 'react';
import T from 'prop-types';
import { StyleSheet, View } from 'react-native';
import FAIcon from 'react-native-vector-icons/FontAwesome';
import { colors, dimensions, rs, reusableStyles } from '../../../../../styles';
import { TouchableItem, Text, FontIcon, Icon } from '../../../../ReusableComponents';
// import Tooltip from '../../../../ReusableComponents/Tooltip';
import CheckBoxIcon from './CheckBoxIcon';
import { AnimatedSection } from '../../../../ReusableComponents/CollapsibleLib/AnimatedSection';
import { useCollapsible } from '../../../../ReusableComponents/CollapsibleLib/useCollapsible';

const s = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
    paddingVertical: dimensions.xLarge,
    paddingHorizontal: dimensions.medium,
  },
  contentContainer: {
    flexGrow: 1,
    marginLeft: dimensions.xLarge,
    paddingRight: dimensions.medium,
  },
  subtitle: {
    marginTop: dimensions.halfMedium,
    lineHeight: 18,
  },
  infoSubTitle: {
    marginTop: 2,
  },
  infoBoxContainer: {
    backgroundColor: colors.white,
    marginLeft: dimensions.halfMedium,
    marginRight: dimensions.halfMedium,
    marginBottom: dimensions.medium,
    marginTop: 7,
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4.0,
    elevation: 4,
  },
  infoBoxMainContainer: {
    flex: 1,
    borderRadius: 5,
    backgroundColor: colors.white,
    paddingLeft: dimensions.medium,
    paddingRight: dimensions.doubleMedium,
    paddingVertical: dimensions.medium,
  },
  infoBoxTriangle: {
    zIndex: -100,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 8,
    borderRightWidth: 8,
    borderBottomWidth: 7,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: colors.white,
    position: 'absolute',
    top: -7,
    left: dimensions.medium,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4.0,
  },
  infoBoxCloseIcon: {
    position: 'absolute',
    top: 10,
    right: 8,
  },
  infoBoxLinkText: {
    textDecorationLine: 'underline',
    color: colors.blueLight,
  },
});

const ShippingOption = ({
  title,
  subtitle,
  onPress,
  iconName,
  hasArrow,
  selected,
  infoIconText,
  children,
  isAlwaysShowChildren,
}) => {
  const { animatedHeight, onPress: onToggleInfoBox, onLayout } = useCollapsible();

  return (
    <View style={s.container}>
      <TouchableItem style={[rs.row, rs.fullWidth]} onPress={onPress}>
        <CheckBoxIcon selected={selected} />
        <View style={s.contentContainer}>
          <View style={[rs.row, rs.alignCenter]}>
            <FontIcon withoutRTLScale name={iconName} color={colors.text} size={24} />
            <Text style={rs.smallMarginLeft} color={colors.gray} semiBold medium>
              {title}
            </Text>
            {!!infoIconText && (
              <Fragment>
                {/*<Tooltip*/}
                {/*  height={40}*/}
                {/*  width={200}*/}
                {/*  backgroundColor={colors.secondary}*/}
                {/*  popover={*/}
                {/*    <View>*/}
                {/*      <Text color={colors.white} style={s.infoSubTitle} xsmall>*/}
                {/*        {infoIconText}*/}
                {/*      </Text>*/}
                {/*    </View>*/}
                {/*  }*/}
                {/*  skipAndroidStatusBar*/}
                {/*>*/}
                {/*  <FontIcon name="info" size={14} color={colors.gray} style={rs.smallMarginLeft} />*/}
                {/*</Tooltip>*/}

                <TouchableItem onPress={onToggleInfoBox} style={rs.smallPaddingHorizontal}>
                  <FontIcon name="info" size={14} color={colors.gray} />
                </TouchableItem>
              </Fragment>
            )}
          </View>
          {!!infoIconText && (
            <AnimatedSection animatedHeight={animatedHeight} onLayout={onLayout}>
              <View style={s.infoBoxContainer}>
                <View style={s.infoBoxTriangle} />
                <View style={s.infoBoxMainContainer}>
                  <Text>{infoIconText}</Text>
                  <TouchableItem onPress={onToggleInfoBox} style={s.infoBoxCloseIcon}>
                    <Icon name="x" size={16} color={colors.grayLight} />
                  </TouchableItem>
                </View>
              </View>
            </AnimatedSection>
          )}
          <Text style={s.subtitle} color={colors.grayBorder}>
            {subtitle}
          </Text>
        </View>
        {hasArrow && (
          <FAIcon
            name="angle-right"
            size={18}
            color={colors.secondary}
            style={reusableStyles.rtlScaleStyle}
          />
        )}
      </TouchableItem>

      {(selected || isAlwaysShowChildren) && children}
    </View>
  );
};

ShippingOption.propTypes = {
  title: T.string.isRequired,
  subtitle: T.string.isRequired,
  iconName: T.string.isRequired,
  hasArrow: T.bool,
  selected: T.bool,
  onPress: T.func,
  infoIconText: T.string,
  children: T.oneOfType([T.element, T.arrayOf(T.element)]),
};

ShippingOption.defaultProps = {
  children: null,
  hasArrow: false,
  selected: false,
  infoIconText: null,
  onPress: () => {},
};

export default ShippingOption;
