import React, { useRef, useMemo, useLayoutEffect, useEffect, useState } from 'react';
import { InteractionManager } from 'react-native';

function useEventCallback(fn) {
  const ref = useRef();
  useLayoutEffect(() => {
    ref.current = fn;
  });
  return useMemo(() => (...args) => (0, ref.current)(...args), []);
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function useInteractionManager() {
  const [interactionsComplete, updateInteractions] = useState(false);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      updateInteractions(true);
    });
  }, []);

  return interactionsComplete;
}

export default useInteractionManager;

export { useEventCallback, usePrevious, useInteractionManager };
