import React from 'react';
import { connect } from 'react-redux';
import DeviceInfo from 'react-native-device-info';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { Alert, FlatList } from 'react-native';
import { MainContainer } from './styles';
import { Text, OptionItem } from '../../../ReusableComponents';
import { colors, rs } from '../../../../styles';
import { isIos, isWeb } from '../../../../utils/detectDevice';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import screens from '../../../../navigation/screens';
import { ENV } from '../../../../../config/env';
import { authOperations } from '../../../../store/auth';
import RNRestart from 'react-native-restart';
import { communitySelectors } from '../../../../store/communityInfo';

const BUILD_NUMBER = DeviceInfo.getBuildNumber();

@connectActionSheet
class Settings extends React.Component {
  onPressListItem = (key) => {
    const { logOut } = this.props;

    switch (key) {
      case 'members':
        NavigationService.navigate(screens.Members);
        break;
      case 'member_requests':
        NavigationService.navigate(screens.MemberRequests);
        break;
      case 'community_notifications':
        NavigationService.navigate(screens.SendNotifications);
        break;
      case 'item_rules':
        NavigationService.navigate(screens.ItemRules);
        break;
      case 'language':
        NavigationService.navigate(screens.Language);
        break;
      case 'my_address':
        NavigationService.navigate(screens.UserLocations);
        break;
      case 'leave':
        NavigationService.navigate(screens.LeaveCommunity);
        break;
      case 'bank':
        NavigationService.navigate(screens.Bank);
        break;
      case 'issue_tokens':
        NavigationService.navigate(screens.IssueToken);
        break;
      case 'send_tokens':
        NavigationService.navigate(screens.SendTokensToBank);
        break;
      case 'edit':
        NavigationService.navigate(screens.EditGroup);
        break;
      case 'notification':
        break;
      case 'token_exchange':
        NavigationService.navigate(screens.TokensExchange);
        break;
      case 'promoCode':
        NavigationService.navigate(screens.PromoCode);
        break;
      case 'logout':
        logOut();
        RNRestart.Restart();
        break;
      default:
        return null;
    }
  };

  render() {
    const { isCommunityAdmin } = this.props;
    const list = [
      {
        key: 'issue_tokens',
        name: strings.bank.issue_token,
        iconName: 'Settings_tokens-bank',
        access: 'Admin',
      },
      {
        key: 'send_tokens',
        name: strings.bank.transfer_tokens,
        iconName: 'Settings_tokens-bank',
        access: 'Admin',
      },
      {
        key: 'my_address',
        name: strings.settings.your_addresses,
        iconName: 'Settings_Location',
        access: 'All',
      },
      {
        key: 'community_notifications',
        name: strings.settings.community_notifications,
        iconName: 'settings_community-notifications',
        isRightIcon: true,
        access: 'Admin',
      },
      {
        key: 'promoCode',
        name: strings.promo_codes.redeem_code,
        iconName: 'redeem_code',
        isRightIcon: true,
        access: 'All',
      },
    ];

    if (ENV !== 'production') {
      list.push({
        key: 'logout',
        name: 'Log out',
        iconName: 'leave-community',
        isRightIcon: true,
        access: 'All',
      });
    }

    return (
      <MainContainer>
        <FlatList
          bounces={false}
          data={list}
          renderItem={({ item }) => {
            if (isCommunityAdmin || item.access === 'All') {
              return (
                <OptionItem
                  title={item.name}
                  Icon={item.icon}
                  iconName={item.iconName}
                  onPress={() => this.onPressListItem(item.key)}
                  rightText={item.value}
                  textColor={item.textColor}
                />
              );
            }
            return null;
          }}
          contentContainerStyle={isWeb && rs.webContainer}
        />
      </MainContainer>
    );
  }
}

export default connect(
  (state) => ({
    isCommunityAdmin: communitySelectors.getIsUserAdmin(state),
    userInfo: state.userInfo,
  }),
  (dispatch) => ({
    logOut: () => dispatch(authOperations.logOut()),
  }),
)(Settings);
