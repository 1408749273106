import api from './api';
import { COMMON_LIMIT } from '../constants/listLimits';

export default {
  getTransferTransactions: (skip = 0) =>
    api.get(`getTransferTransactions/?limit=${COMMON_LIMIT}&skip=${skip}`),

  getTransactionDetails: (transactionId, transactionType) =>
    api.post('getTransactionDetails', {
      id: transactionId,
      transaction_type: transactionType,
    }),

  createIssueTransaction: ({ note, amount, receiverIds, communityId, walletType }) =>
    api.post('createIssueTransaction', {
      note,
      amount,
      receivers_ids: receiverIds,
      community_id: communityId,
      wallet_type: walletType,
    }),

  createDonationTransaction: ({ note, amount, receiverId, communityId }) =>
    api.post('createDonationTransaction', {
      note,
      amount,
      receiver_id: receiverId,
      community_id: communityId,
    }),

  sendTokensToCommunityBank: ({ communityId, walletType, amount, note }) =>
    api.post('sendTokensToCommunityBank', {
      community_id: communityId,
      wallet_type: walletType,
      amount,
      note,
    }),
};
