import { handleActions } from 'redux-actions';
import { mergeDeep, mergeIn } from '../../utils/stateHelper';

import * as types from './types';
import * as lotTypes from '../lots/types';

const initialState = {
  isLoading: false,
  mostFollowedUsers: [],
  myFollowers: [],
  topSellers: [],
  mySellers: [],
  itemIdsFromMembersIFollow: [],
  sellersNearMe: [],
  itemIdsNearMe: [],
  itemNearEntities: {},
  itemIdsNearPrimaryLocation: [],
  itemIdsTop: [],
  itemIdsDiscounted: [],
  topUploadedCategories: [],
  rewards: [],
  cities: [],
  campuses: [],
  myTopCategories: [],
  newestItemsInCommunityGroups: {
    // 3: [1, 2, 3]
  },
  newestItems: [],
  newestItemsInCategories: {
    // 3: [1, 2, 3]
  },
  itemEntities: {},
  excludedItemIdsCache: [],
};

const reducer = handleActions(
  {
    [types.SET_HOME_DATA]: mergeDeep(({ payload }, state) => payload),

    [types.LOADING_START]: mergeIn(({ payload }, state) => ({
      isLoading: true,
      excludedItemIdsCache: [],
    })),

    [types.LOADING_SUCCESS]: mergeDeep(({ payload }, state) => ({
      isLoading: false,
    })),

    [types.SET_MOST_FOLLOWED_USERS]: mergeDeep(({ payload }, state) => ({
      mostFollowedUsers: payload,
    })),

    [types.SET_MY_FOLLOWERS]: mergeDeep(({ payload }, state) => ({
      myFollowers: payload,
    })),

    [types.SET_TOP_SELLERS]: mergeDeep(({ payload }, state) => ({
      topSellers: payload,
    })),

    [types.SET_MY_SELLERS]: mergeDeep(({ payload }) => ({
      mySellers: payload,
    })),

    [types.SET_TOP_ITEMS]: mergeDeep(({ payload }, state) => ({
      itemIdsTop: payload.itemIds,
      itemEntities: payload.itemEntities,
    })),

    [types.SET_ITEMS_FROM_MEMBERS_I_FOLLOW]: mergeDeep(({ payload }, state) => ({
      itemIdsFromMembersIFollow: payload.itemIds,
      itemEntities: payload.itemEntities,
    })),

    [types.SET_SELLERS_NEAR_ME]: mergeDeep(({ payload }, state) => ({
      sellersNearMe: payload,
    })),

    [types.SET_ITEMS_NEAR_ME]: mergeIn(({ payload }, state) => ({
      itemIdsNearMe: payload.itemIds,
      itemNearEntities: payload.itemEntities,
    })),

    [types.SET_DISCOUNTED_ITEMS]: mergeDeep(({ payload }, state) => ({
      itemIdsDiscounted: payload.itemIds,
      itemEntities: payload.itemEntities,
    })),

    [types.SET_MY_TOP_CATEGORIES]: mergeDeep(({ payload }, state) => ({
      myTopCategories: payload,
    })),

    [types.SET_TOP_UPLOADED_CATEGORIES]: mergeDeep(({ payload }, state) => ({
      topUploadedCategories: payload,
    })),

    [types.SET_REWARDS]: mergeDeep(({ payload }, state) => ({
      rewards: payload,
    })),

    [types.SET_CITIES]: mergeDeep(({ payload }, state) => ({
      cities: payload,
    })),
    [types.SET_CAMPUSES]: mergeDeep(({ payload }) => ({
      campuses: payload,
    })),

    [types.SET_NEWEST_ITEMS_IN_COMMUNITY_GROUPS]: mergeDeep(({ payload }, state) => ({
      newestItemsInCommunityGroups: payload.newestItemsInCommunityGroups,
      itemEntities: payload.itemEntities,
    })),

    [types.SET_HOME_ITEMS]: mergeIn(({ payload }, state) => ({
      itemIdsTop: payload.itemIdsTop,
      itemIdsFromMembersIFollow: payload.itemIdsFromMembersIFollow,
      itemIdsNearPrimaryLocation: payload.itemIdsNearPrimaryLocation,
      itemIdsDiscounted: payload.itemIdsDiscounted,
      newestItemsInCommunityGroups: payload.newestItemsInCommunityGroups,
      itemEntities: payload.itemEntities,
    })),
    [types.SET_NEWEST_ITEMS]: mergeDeep(({ payload }) => ({
      newestItems: payload,
    })),
    [types.ADD_EXCLUDED_ITEM_IDS_CACHE]: mergeDeep(({ payload }, state) => ({
      excludedItemIdsCache: [...state.excludedItemIdsCache, ...payload],
    })),

    [lotTypes.TOGGLE_ITEM_FAVORITES]: mergeDeep(({ payload }, state) => ({
      itemEntities: {
        [payload.itemId]: {
          mark_as_favorite: !payload.isFavorite,
        },
      },
    })),
  },
  initialState,
);

export default reducer;
