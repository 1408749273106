/* eslint-disable react-native/no-inline-styles */
import React, { Fragment, memo } from 'react';
import * as R from 'ramda';
import T from 'prop-types';
import { AngleIcon, FontIcon, RowContainer, Separator, Text } from '../../../../ReusableComponents';
import { colors } from '../../../../../styles';
import Title from './Title';
import { BoxButton, TitleContainer } from './styles';
import strings from '../../../../../localization';
import { renderArrOfPropsThroughCommas } from '../../../../../utils/stringHelper';

const ShippingSelector = memo(({ onPress, locations, isActiveShipping, isActiveBoxit }) => (
  <Fragment>
    <BoxButton useOpacity={false} onPress={onPress}>
      <Fragment>
        <TitleContainer>
          <FontIcon size={20} name="shipping" color={colors.text} />
          <Title withPadding mandatory>
            {strings.lots.shipping}
          </Title>
        </TitleContainer>
        <RowContainer style={{ width: '90%' }}>
          <Text medium>
            {!R.isEmpty(locations) && (
              <Text medium>
                {`${strings.shipping.meet_in_person}: ${renderArrOfPropsThroughCommas(
                  locations,
                  'city',
                )}`}
              </Text>
            )}
            {!!isActiveShipping && !R.isEmpty(locations) && <Text medium> & </Text>}
            {!!isActiveShipping && <Text medium>{strings.shipping.domestic_shipping}</Text>}
            {!!isActiveBoxit && (!R.isEmpty(locations) || !!isActiveShipping) && (
              <Text medium> & </Text>
            )}
            {!!isActiveBoxit && <Text medium>Boxit</Text>}
          </Text>
        </RowContainer>
        <AngleIcon />
      </Fragment>
    </BoxButton>
    <Separator medium />
  </Fragment>
));

ShippingSelector.propTypes = {
  onPress: T.func,
  locations: T.array,
  isActiveShipping: T.bool,
  isActiveBoxit: T.bool,
};

export default ShippingSelector;
