import React from 'react';
import styled from 'styled-components/native';
import Text from './Text';
import { colors, dimensions } from '../../styles';

const Container = styled.View`
  height: 29px;
  border-radius: 16px;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  padding-horizontal: ${dimensions.doubleMedium};
  border-width: 1px;
  border-color: ${colors.gray};
  margin-right: ${({ withMarginRight }) => (withMarginRight ? dimensions.medium : 0)};
`;

const LabelText = styled(Text)`
  color: ${colors.gray};
`;

const FormButtonText = ({ title, withMarginRight }) => (
  <Container withMarginRight={withMarginRight}>
    <LabelText>{title}</LabelText>
  </Container>
);

export default FormButtonText;
