import { StyleSheet } from 'react-native';
import { dimensions } from '../../../../styles';
import { isIphoneX } from '../../../../utils/detectDevice';

export default StyleSheet.create({
  infoIconContainer: {
    position: 'absolute',
    right: 0,
  },
  titleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingHorizontal: dimensions.doubleMedium + 6,
  },
  transactionDetailsContainer: {
    marginTop: dimensions.medium,
    alignItems: 'center',
    height: '40%',
  },
  buttonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginBottom: isIphoneX ? dimensions.doubleMedium : dimensions.medium,
  },
});
