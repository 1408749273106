import React, { memo } from 'react';
import T from 'prop-types';
import { View, FlatList } from 'react-native';
import s from './styles';
import TouchableItem from '../TouchableItem';
import Text from '../Text';
import { colors, rs } from '../../../styles';
import FontIcon from '../FontIcon';
import Separator from '../Separator';
import strings from '../../../localization';
import { numberWithCommas } from '../../../utils/stringHelper';
import { AnalyticsService } from '../../../services';
import * as analyticsEventTypes from '../../../constants/analyticsEventTypes';

const Item = memo(({ item, onPress }) => (
  <TouchableItem style={s.itemContainer} onPress={() => onPress(item)}>
    <FontIcon name={item.category_icon} size={38} color={colors.secondary} />

    <Text semiBold style={rs.paddingTop} alignCenter>
      {item.category_name}
    </Text>

    <Separator style={s.separator} />

    <Text xsmall color={colors.gray}>
      <Text xsmall semiBold color={colors.blueLight}>
        {numberWithCommas(item.uploaded_items_count)}
      </Text>{' '}
      {strings.home.items}
    </Text>
    {/*<Separator style={s.separator} />*/}

    {/*<Text xsmall color={colors.gray}>*/}
    {/*  {strings.home.sold}:{' '}*/}
    {/*  <Text xsmall semiBold>*/}
    {/*    {item.sold_items_count}*/}
    {/*  </Text>*/}
    {/*</Text>*/}
  </TouchableItem>
));

const HorizontalCategoriesList = memo(({ categories, onSelect, analyticsEventType }) => {
  const onPress = (item) => {
    if (analyticsEventType) {
      AnalyticsService.logEvent(analyticsEventType);
    }
    onSelect(item);
  };
  return (
    <View style={s.contentContainer}>
      <FlatList
        horizontal
        inverted
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={s.listContentContainerStyle}
        data={categories}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => <Item item={item} onPress={onPress} />}
      />
    </View>
  );
});

HorizontalCategoriesList.propTypes = {
  categories: T.array,
};

export default HorizontalCategoriesList;
