import NetInfo, { NetInfoStateType } from '@react-native-community/netinfo';
import modalTypes from '../constants/modalTypes';
import ModalsService from './ModalsService';
import AnalyticsService from './AnalyticsService';
import * as analyticsEventTypes from '../constants/analyticsEventTypes';
import { store } from '../store';
import { offersOperations } from '../store/offers';
import { appOperations } from '../store/app';

class NetworkConnectionService {
  constructor() {
    this.isOnline = true;
    this.timer = null;
    this.unsubscribe = null;
    this.connectionType = null;
  }

  async addListener() {
    await NetInfo.fetch().then(({ isConnected, type }) => {
      if (!isConnected) {
        this.isOnline = false;
        this.timer = setTimeout(() => {
          this.sendDisconnectEvent();
          ModalsService.showFullScreenModal(modalTypes.NO_INTERNET_CONNECTION);
        }, 5000);
      } else {
        this.connectionType = type;
      }
    });
    this.unsubscribe = NetInfo.addEventListener(this.handleFirstConnectivityChange);
  }

  removeListener() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  connectionEstablished() {
    if (this.isOnline) {
      return;
    }
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      store.dispatch(offersOperations.getRecentOffers());
      ModalsService.hideModal();
    }, 0);
    this.isOnline = true;
  }

  connectionDestroyed() {
    if (!this.isOnline) {
      return;
    }
    this.isOnline = false;

    this.timer = setTimeout(() => {
      this.sendDisconnectEvent();
      store.dispatch(appOperations.setLastAccessDateInCurrentSession());
      ModalsService.showFullScreenModal(modalTypes.NO_INTERNET_CONNECTION);
    }, 5000);
  }

  handleFirstConnectivityChange = ({ isConnected }) => {
    if (!isConnected) {
      console.warn('No Internet Connection');
      this.connectionDestroyed();
    }
    if (isConnected && !this.isOnline) {
      this.connectionEstablished();
    }
  };

  sendDisconnectEvent = () => {
    switch (this.connectionType) {
      case NetInfoStateType.wifi:
        AnalyticsService.logEvent(analyticsEventTypes.network_connection_lost_wifi);
        break;

      case NetInfoStateType.cellular:
        AnalyticsService.logEvent(analyticsEventTypes.network_connection_lost_cellular);
        break;

      default:
        return;
    }
  };
}

const networkConnectionService = new NetworkConnectionService();

export default networkConnectionService;
