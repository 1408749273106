import styled from 'styled-components/native';
import { isRTL } from '../../../utils/rtlHelper';
import { isIos } from '../../../utils/detectDevice';
import { colors, dimensions } from '../../../styles';

const ContentContainer = styled.View`
  flex-direction: row;
  padding-right: ${({ withoutRightPadding }) => (withoutRightPadding ? 0 : dimensions.medium)};
  padding-left: ${dimensions.medium};
  align-items: center;
  justify-content: center;
`;
const ClearButton = styled.TouchableOpacity`
  position: absolute;
  right: ${({ withoutRightPadding }) =>
    withoutRightPadding ? dimensions.medium : dimensions.doubleMedium};
`;

const SearchBar = styled.TextInput`
  background-color: ${colors.grayLighter};
  height: ${dimensions.commonHeight};
  width: 100%;
  border-radius: ${dimensions.commonHeight / 2};
  padding-vertical: 0px;
  padding-left: ${dimensions.medium};
  padding-right: ${dimensions.medium + 18};
  ${isIos && isRTL ? 'text-align: right' : ''}
`;

const SearchBarButton = styled.TouchableOpacity`
  position: absolute;
  height: ${dimensions.commonHeight};
  width: 100%;
  z-index: 1;
  margin-left: ${dimensions.medium};
`;

export { ContentContainer, SearchBar, ClearButton, SearchBarButton };
