import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';
import guideTypes from '../../constants/guidelTypes';
import * as types from './types';

const initialState = {
  showAgain: {
    [guideTypes.HOME_TOKEN_INFO_BANNER]: true,
    [guideTypes.TOKEN_INFO_BANNER]: true,
    [guideTypes.MARKETPLACE_INVITE_FRIENDS]: true,
    [guideTypes.MARKETPLACE_LOCATION_PERMISSION]: true,
    [guideTypes.MENU_INVITE_FRIENDS]: true,
    [guideTypes.WISHES_BANNER]: true,
    [guideTypes.ITEM_REVIEW]: true,
  },
};

const reducer = handleActions(
  {
    [types.NEVER_SHOW_GUIDE_AGAIN]: mergeDeep(({ payload }, state) => ({
      showAgain: {
        [payload.guideType]: false,
      },
    })),
  },
  initialState,
);

export default reducer;
