import React from 'react';
import Button from '../../Buttons/Button';
import strings from '../../../../localization';
import {
  ContentContainer,
  AdminAvatar,
  MainContainer,
  Title,
  Description,
  ButtonContainer,
} from './styles';

const Welcome = ({ onPress, params }) => (
  <ContentContainer>
    <AdminAvatar source={{ uri: 'round_logo' }} />
    {/* <LottieView */}
    {/*	speed={0.8} */}
    {/*	autoSize */}
    {/*	style={{ */}
    {/*		position: 'absolute', */}
    {/*		top: -90, */}
    {/*		width: 500, */}
    {/*		height: 600, */}
    {/*	}} */}
    {/*	source={require('../../../../assets/animations/confetti.json')} */}
    {/*	autoPlay */}
    {/*	loop={false} */}
    {/* /> */}
    <MainContainer>
      <Title semiBold>{params.title}</Title>
      <Description>{params.description}</Description>

      <ButtonContainer>
        <Button title={strings.common.ok} onPress={onPress} />
      </ButtonContainer>
    </MainContainer>
  </ContentContainer>
);

export default Welcome;
