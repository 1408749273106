import React, { Fragment } from 'react';
import { FlatList } from 'react-native';
import { connect } from 'react-redux';
import * as R from 'ramda';
import T from 'prop-types';
import Separator from '../../Separator';
import TextButton from '../../Buttons/TextButton';
import { EmptyListWithImage } from '../../EmptyList';
import Spinner from '../../Spinner';
import ListItem from './ListItem/ListItem';
import SelectableListItem from './ListItem/SelectableListItem';
import { getCommunityMembers } from '../../../../store/communityMembers/selectors';
import { communityMembersOperations } from '../../../../store/communityMembers';
import communityMemberFilters from '../../../../constants/communityMemberFilters';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import { getTimeForNotification } from '../../../../utils/dateHelper';
import strings from '../../../../localization';

const MAX_SELECTED_MEMBERS_COUNT = 15;

const getListItemMultiSelectProps = ({ currentMember, selectedMemberIds, isMulti }) => {
  if (!isMulti || !selectedMemberIds) {
    return {};
  }
  const isSelected = selectedMemberIds.includes(currentMember.id);
  const isDisabled = !isSelected && selectedMemberIds.length >= MAX_SELECTED_MEMBERS_COUNT;

  return {
    isMulti,
    isSelected,
    isDisabled,
  };
};

class List extends React.PureComponent {
  getListEmptyComponent = () => {
    const { isLoading, filter } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    return filter === communityMemberFilters.NEAR_ME ? (
      <EmptyListWithImage
        image="empty_state_buying"
        text={strings.emptyLists.empty_buying_offers_list}
        buttonTitle={strings.emptyLists.go_to_marketplace}
        onPress={() => {
          NavigationService.navigate(screens.Marketplace);
        }}
      />
    ) : (
      <EmptyListWithImage
        image="empty_state_search"
        text={strings.emptyLists.empty_members_search}
      />
    );
  };

  render() {
    const {
      searchValue,
      groupMembers,
      selectedMemberIds,
      onLoadMore,
      filter,
      isLoading,
      isLoadingMore,
      isMulti,
      onSelectUser,
      onPress,
      ...listProps
    } = this.props;

    return (
      <FlatList
        data={groupMembers}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.id.toString()}
        ItemSeparatorComponent={() => <Separator />}
        onEndReachedThreshold={0.9}
        onEndReached={() => onLoadMore(searchValue)}
        ListFooterComponent={isLoadingMore && <Spinner />}
        ListEmptyComponent={this.getListEmptyComponent()}
        renderItem={({ item }) => {
          const multiSelectProps = getListItemMultiSelectProps({
            currentMember: item,
            selectedMemberIds,
            isMulti,
          });

          return (
            <Fragment>
              {!R.isNil(onSelectUser) ? (
                <SelectableListItem
                  lastSeenAt={item.last_access_date}
                  name={item.name}
                  profileImage={item.profile_image}
                  nickname={item.nickname}
                  onSelect={() => onSelectUser(item)}
                  {...multiSelectProps}
                />
              ) : (
                <ListItem
                  userId={item.id}
                  name={item.name}
                  nickname={item.nickname}
                  profileImage={item.profile_image}
                  additionalInfo={`${strings.profile.last_seen.replace(
                    /__TIME__/,
                    getTimeForNotification(item.last_access_date),
                  )}`}
                  onPress={() => onPress(item.id)}
                />
              )}
            </Fragment>
          );
        }}
        {...listProps}
      />
    );
  }
}

List.propTypes = {
  groupMembers: T.arrayOf(
    T.shape({
      id: T.number,
      name: T.string,
      nickname: T.string,
      profile_image: T.string,
      last_access_date: T.string,
    }),
  ).isRequired,
  selectedMemberIds: T.arrayOf(T.number),
  searchValue: T.string,
  isLoading: T.bool.isRequired,
  isLoadingMore: T.bool.isRequired,
  isMulti: T.bool,
  filter: T.string,
  onSelectUser: T.func,
  onPress: T.func,
  onLoadMore: T.func.isRequired,
};

List.defaultProps = {
  selectedMemberIds: [],
  searchValue: null,
  isMulti: false,
  filter: null,
  onSelectUser: null,
  onPress: null,
};

export default connect(
  (state) => ({
    groupMembers: getCommunityMembers(state),
    isLoadingMore: state.communityMembers.isLoadingMore,
    isLoading: state.communityMembers.isLoading,
  }),
  (dispatch) => ({
    onLoadMore: (searchValue) =>
      dispatch(communityMembersOperations.getMoreCommunityMembers(searchValue)),
  }),
)(List);
