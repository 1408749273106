import React, { useState, Fragment } from 'react';
import styled from 'styled-components/native';
import * as Sentry from '@sentry/react-native';
import { Modal, StyleSheet, View, ImageBackground } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import T from 'prop-types';
import { dimensions, colors } from '../../../styles';
import Spinner from '../Spinner';
import AppImage from '../AppImage';
import FastImage from '../FastImage';
import { isIphoneX, isIos } from '../../../utils/detectDevice';

const s = StyleSheet.create({
  paginationContainer: {
    paddingHorizontal: 0,
    paddingVertical: dimensions.indent,
    zIndex: 5,
  },
  paginationDot: {
    width: 9,
    height: 9,
    borderRadius: 5,
    backgroundColor: colors.white,
  },
  inactiveDot: {
    borderWidth: 1,
    borderColor: colors.white,
    backgroundColor: 'transparent',
  },
  image: {
    height: '100%',
    width: '100%',
  },
  imageBackground: {
    height: dimensions.width,
    width: dimensions.width,
  },
});

const Slide = styled.TouchableWithoutFeedback`
  z-index: 4;
  height: ${dimensions.width};
  width: ${dimensions.width};
`;

const PaginationContainer = styled.View`
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  bottom: ${dimensions.halfMedium};
  position: absolute;
  align-self: center;
  z-index: 6;
`;

const CloseButton = styled.TouchableOpacity`
  padding: ${dimensions.halfMedium}px;
`;

const ModalHeaderContainer = styled.View`
  z-index: 10;
  position: absolute;
  top: ${isIos ? (isIphoneX ? 44 : 20) : 8};
  padding-right: ${dimensions.halfMedium}px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const SlideItem = React.memo(({ item, onPress }) => {
  return (
    <Slide onPress={onPress}>
      <FastImage
        style={s.image}
        resizeMode="cover"
        source={{ uri: item, priority: FastImage.priority.high }}
        progressiveRenderingEnabled
      />
    </Slide>
  );
});

const SwipeGallery = React.memo(({ images, imagePreview }) => {
  const [isVisibleViewer, setVisibleViewer] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const imagesArr = images.map((img) => ({ url: img }));

  const onLoadImageError = (error) => {
    Sentry.captureException(error);
  };

  return (
    <Fragment>
      <Modal visible={isVisibleViewer} onRequestClose={() => setVisibleViewer(false)} transparent>
        <ImageViewer
          loadingRender={() => <Spinner />}
          renderIndicator={() => {}}
          renderImage={(props) => <AppImage {...props} onError={onLoadImageError} />}
          renderHeader={() => (
            <ModalHeaderContainer>
              <CloseButton onPress={() => setVisibleViewer(false)}>
                <Icon size={dimensions.iconSize} name="close" color={colors.white} />
              </CloseButton>
              <Pagination
                dotsLength={images.length}
                activeDotIndex={selectedId}
                containerStyle={s.paginationContainer}
                dotStyle={s.paginationDot}
                inactiveDotOpacity={0.4}
              />
              <View />
            </ModalHeaderContainer>
          )}
          index={selectedId}
          enableSwipeDown
          saveToLocalByLongPress={false}
          onCancel={() => setVisibleViewer(false)}
          onChange={(id) => setSelectedId(id)}
          imageUrls={imagesArr}
        />
      </Modal>

      <ImageBackground style={s.image} source={{ uri: imagePreview }}>
        <Fragment>
          <Carousel
            bounces={false}
            data={images}
            renderItem={({ item, index }) => (
              <SlideItem
                item={item}
                imagePreview={imagePreview}
                onPress={() => {
                  setSelectedId(index);
                  setVisibleViewer(true);
                }}
              />
            )}
            onSnapToItem={(id) => setSelectedId(id)}
            sliderWidth={dimensions.width}
            itemHeight={dimensions.width}
            itemWidth={dimensions.width}
            inactiveSlideOpacity={1}
            inactiveSlideScale={1}
          />

          <PaginationContainer>
            <Pagination
              dotsLength={images.length}
              activeDotIndex={selectedId}
              containerStyle={s.paginationContainer}
              dotStyle={s.paginationDot}
              inactiveDotStyle={s.inactiveDot}
              inactiveDotOpacity={1}
              inactiveDotScale={1}
            />
          </PaginationContainer>
        </Fragment>
      </ImageBackground>
    </Fragment>
  );
});

SwipeGallery.propTypes = {
  images: T.array.isRequired,
};

export default SwipeGallery;
