import { StyleSheet } from 'react-native';
import { isAndroid } from '../../../../utils/detectDevice';
import { colors, dimensions } from '../../../../styles';

export default StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.grayBackground,
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayBorder,
    // paddingHorizontal: dimensions.medium,
  },
  tokenIconContainer: {
    flexDirection: 'row',
    backgroundColor: colors.grayLighter,
    alignItems: 'center',
    paddingVertical: 6,
  },
  verticalSeparator: {
    height: 32,
    width: 1,
    backgroundColor: colors.grayBorder,
  },
  mapButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 4,
    height: 32,
    width: 32,
    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: colors.white,
    borderColor: colors.grayLight,
    marginLeft: dimensions.medium,
  },
  mapButtonImage: {
    width: 22,
    height: 22,
  },
  listHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    paddingVertical: dimensions.small,
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.white,
  },
  listHeaderText: {
    flexGrow: 1,
    ...(isAndroid
      ? {
          // https://github.com/facebook/react-native/issues/7687
          // this is done to remove extra padding on the bottom of the text node for Android
          // because { alignItems: 'center' } doesn't work properly specifically on Android devices
          // (Also, { includeFontPadding: false, textAlignVertical: 'bottom' } doesn't work for this specific case)
          lineHeight: 1,
          paddingTop: 18,
        }
      : {}),
  },
});
