import React, { memo, Fragment } from 'react';
import { View } from 'react-native';
import * as R from 'ramda';
import T from 'prop-types';
import { ConditionsList, FontIcon, Separator } from '../../../../ReusableComponents';
import { colors } from '../../../../../styles';
import Title from './Title';
import { Box, TitleContainer } from './styles';
import strings from '../../../../../localization';

const ConditionsSelector = memo(({ conditionsList, activeConditionId, onSelectCondition }) => (
  <View>
    {!R.isEmpty(conditionsList) && !!conditionsList && (
      <Fragment>
        <Box withoutPadding>
          <TitleContainer>
            <FontIcon
              size={20}
              withLeftPadding
              withRightSmallPadding
              name="condition"
              color={colors.text}
            />
            <Title mandatory>{strings.lots.condition}</Title>
          </TitleContainer>
          <ConditionsList
            conditionsList={conditionsList}
            onSelectCondition={onSelectCondition}
            activeConditionId={activeConditionId}
          />
        </Box>
        <Separator medium />
      </Fragment>
    )}
  </View>
));

ConditionsSelector.propTypes = {
  activeConditionId: T.number,
  onSelectCondition: T.func,
};

export default ConditionsSelector;
