import { createSelector } from 'reselect';
import * as R from 'ramda';


export const getItemSearchHistory = createSelector(
  [R.pathOr([], ['feedSearch', 'itemsSearchHistory'])],
  (searchHistory) => {
    return searchHistory;
  },
);
export const getWishSearchHistory = createSelector(
  [R.pathOr([], ['feedSearch', 'wishesSearchHistory'])],
  (searchHistory) => {
    return searchHistory;
  },
);

export default {
  getItemSearchHistory,
  getWishSearchHistory,
};
