import React, { Fragment } from 'react';
import T from 'prop-types';
import { StyleSheet, Modal, View } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import { TouchableItem, Spinner, Text } from '../../../../ReusableComponents';
import { dimensions, colors } from '../../../../../styles';
import strings from '../../../../../localization';

const s = StyleSheet.create({
  footerContainer: {
    width: dimensions.width,
    paddingHorizontal: dimensions.large,
    paddingVertical: dimensions.xLarge,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    padding: dimensions.small,
  },
});

const UploadImagePreview = ({ imageSource, isVisible, onSave, onCancel }) => {
  const imageUrls = imageSource ? [{ url: imageSource.path }] : [];

  return (
    <Fragment>
      <Modal visible={isVisible} transparent>
        <ImageViewer
          imageUrls={imageUrls}
          onSave={onSave}
          onCancel={onCancel}
          onSwipeDown={onCancel}
          loadingRender={() => <Spinner />}
          renderIndicator={() => null}
          renderFooter={() => {
            return (
              <View style={s.footerContainer}>
                <TouchableItem onPress={onCancel} style={s.button}>
                  <Text color={colors.white}>{strings.common.cancel}</Text>
                </TouchableItem>

                <TouchableItem onPress={onSave} style={s.button}>
                  <Text color={colors.blueLight}>{strings.common.send}</Text>
                </TouchableItem>
              </View>
            );
          }}
          backgroundColor={colors.grayDarker}
          saveToLocalByLongPress={false}
          enableSwipeDown
        />
      </Modal>
    </Fragment>
  );
};

UploadImagePreview.propTypes = {
  imageSource: T.shape({ path: T.string }),
  isVisible: T.bool.isRequired,
  onSave: T.func.isRequired,
  onCancel: T.func.isRequired,
};

UploadImagePreview.defaultProps = {
  imageSource: null,
};

export default UploadImagePreview;
