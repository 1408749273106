export const NOTIFICATIONS_START = 'notifications/NOTIFICATIONS_START';
export const NOTIFICATIONS_SUCCESS = 'notifications/NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_ERROR = 'notifications/NOTIFICATIONS_ERROR';

export const NOTIFICATIONS_MORE_START = 'notifications/NOTIFICATIONS_MORE_START';
export const NOTIFICATIONS_MORE_SUCCESS = 'notifications/NOTIFICATIONS_MORE_SUCCESS';

export const NOTIFICATIONS_REFRESH_START = 'notifications/NOTIFICATIONS_REFRESH_START';
export const NOTIFICATIONS_REFRESH_SUCCESS = 'notifications/NOTIFICATIONS_REFRESH_SUCCESS';

export const MARK_NOTIFICATIONS_AS_READ = 'notifications/MARK_NOTIFICATIONS_AS_READ';
export const MARK_NOTIFICATION_AS_OPEN = 'notifications/MARK_NOTIFICATION_AS_OPEN';

export const ADD_NEW_NOTIFICATION = 'notifications/ADD_NEW_NOTIFICATION';
