import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { FlatList, RefreshControl } from 'react-native';
import T from 'prop-types';
import { View, Separator, Container, EmptyListWithImage } from '../../../ReusableComponents';
import MemberItem from '../../../ReusableComponents/Lists/CommunityMembersList/ListItem/ListItem';
import { headerStyle, rs } from '../../../../styles';
import { userOperations } from '../../../../store/user';
import strings from '../../../../localization';
import { COMMUNITY_MEMBERS_LIMIT } from '../../../../constants/listLimits';
import Spinner from '../../../ReusableComponents/Spinner';
import EmptyList from '../../../ReusableComponents/EmptyList';

const AllBuyers = ({ getAllBuyersList }) => {
  const [members, setMembers] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [isExistMore, setExistMore] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    setLoading(true);
    const data = await getAllBuyersList();
    setMembers(data);
    setLoading(false);
  };

  const onRefresh = async () => {
    setRefreshing(true);
    const data = await getAllBuyersList();
    setMembers(data);
    setRefreshing(false);
  };

  const onLoadMore = async () => {
    if (isLoading || isLoadingMore || isRefreshing || !isExistMore) return;

    setLoadingMore(true);

    const data = await getAllBuyersList(members.length);

    setMembers(R.uniq([...members, ...data]));
    setExistMore(data.length === COMMUNITY_MEMBERS_LIMIT);
    setLoadingMore(false);
  };

  return (
    <Container>
      <FlatList
        data={members}
        keyExtractor={({ id }) => id.toString()}
        style={rs.paddingBottom}
        ItemSeparatorComponent={() => <Separator />}
        renderItem={({ item }) => {
          return (
            <MemberItem
              userId={item.id}
              name={item.name}
              nickname={item.nickname}
              profileImage={item.profile_image}
            />
          );
        }}
        onEndReached={onLoadMore}
        ListEmptyComponent={
          isLoading ? (
            <Spinner />
          ) : (
            <EmptyListWithImage
              image="empty_state_members_sold_to"
              text={strings.emptyLists.empty_members_bought}
            />
          )
        }
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
        refreshing={isRefreshing}
      />
    </Container>
  );
};

AllBuyers.propTypes = {
  getAllBuyersList: T.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    getAllBuyersList: (skip) => dispatch(userOperations.getAllBuyersList(skip)),
  }),
)(AllBuyers);
