import * as React from 'react';
import LottieView from 'lottie-react-native';
import { View, StyleSheet, LayoutAnimation } from 'react-native';
import { colors } from '../../../styles';

import animation from '../../../assets/animations/successLoaderNew.json';

const s = StyleSheet.create({
  centeredContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  background: {
    backgroundColor: colors.black,
    opacity: 0.1,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  animation: {
    width: 100,
    height: 100,
  },
});

const SuccessLoaderNew = React.forwardRef((_, ref) => {
  const animationRef = React.useRef(null);
  const callbackFn = React.useRef(null);
  const [state, setState] = React.useState({
    isVisible: false,
    isFinished: false,
    isLastLoop: false,
    isSuccess: true,
  });

  React.useEffect(() => {
    LayoutAnimation.easeInEaseOut();

    return () => {
      LayoutAnimation.easeInEaseOut();
    };
  });

  const showLoader = async () => {
    await setState((prevState) => ({
      ...prevState,
      isVisible: true,
      isFinished: false,
      isLastLoop: false,
    }));

    animationRef?.current?.play(0, 119);
  };

  const hideLoader = ({ callback = null, isSuccess = true }) => {
    setState((prevState) => ({
      ...prevState,
      isSuccess,
      isLastLoop: true,
    }));

    callbackFn.current = callback;
  };

  React.useImperativeHandle(ref, () => ({ showLoader, hideLoader }));

  const onAnimationFinish = () => {
    if (!state.isLastLoop) {
      animationRef?.current?.play(0, 120);
      return;
    }

    if (state.isFinished) {
      animationRef?.current?.reset();
      setState((prevState) => ({ ...prevState, isVisible: false }));
      if (callbackFn.current && state.isSuccess) {
        callbackFn.current();
        callbackFn.current = null;
      }
    }

    if (state.isSuccess) {
      animationRef?.current?.play(121, 240);
    } else {
      animationRef?.current?.play(241, 360);
    }

    setState((prevState) => ({ ...prevState, isFinished: true }));
  };

  if (!state.isVisible) {
    return null;
  }

  return (
    <View style={s.contentContainer}>
      <View style={s.centeredContainer}>
        <View style={s.background} />

        <LottieView
          ref={animationRef}
          source={animation}
          onAnimationFinish={onAnimationFinish}
          speed={1.2}
          style={s.animation}
          loop={false}
        />
      </View>
    </View>
  );
});

export default SuccessLoaderNew;
