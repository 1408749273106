import React, { Fragment } from 'react';
import { I18nManager, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';

import s from './styles';
import { Text, TouchableItem } from '../';
import { colors } from '../../../styles';

class SearchItem extends React.PureComponent {
  render() {
    const { title, onSelectSearchResult } = this.props;

    return (
      <TouchableItem
        style={s.contentContainer}
        useOpacity={false}
        onPress={() => onSelectSearchResult(title)}
      >
        <Fragment>
          <Icon
            size={22}
            name="search"
            style={{
              color: colors.gray,
              transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
            }}
          />

          <View style={s.resultContainer}>
            <Text medium>{title}</Text>
          </View>

          <Icon
            size={22}
            name="arrow-up-left"
            style={{
              color: colors.gray,
              transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
            }}
          />
        </Fragment>
      </TouchableItem>
    );
  }
}

export default SearchItem;
