import React from 'react';
import OfferItem from './OfferItem';
import { CONFIRM_CANCEL_PURCHASE, OFFER_CONFIRMED } from '../../../../store/offers/offerStatuses';
import {
  BUYER_CANCELLED_OFFER,
  BUYER_CONFIRMED_DELIVERY,
} from '../../../../store/offers/offerActions';
import strings from '../../../../localization';

const InProgressItems = props => {
  const { isShowStatus, offer, onPerformAction } = props;
  const { id, status_id } = offer;

  if (parseInt(status_id) === CONFIRM_CANCEL_PURCHASE) {
    return (
      <OfferItem
        {...props}
        isShowStatus={isShowStatus}
        hasDismissButton
        dismissButtonText={strings.common.cancel}
        onDismissPress={() => onPerformAction(id, BUYER_CANCELLED_OFFER)}
      />
    );
  }
  if (parseInt(status_id) === OFFER_CONFIRMED) {
    return (
      <OfferItem
        {...props}
        isShowStatus={isShowStatus}
        hasSuccessButton
        successButtonText={strings.buy_sell.confirm_delivery}
        onSuccessPress={() => onPerformAction(id, BUYER_CONFIRMED_DELIVERY)}
      />
    );
  }
};

export default InProgressItems;
