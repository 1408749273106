export const SET_HOME_ITEMS = 'home/SET_HOME_ITEMS';

export const SET_HOME_DATA = 'home/SET_HOME_DATA';
export const LOADING_START = 'home/LOADING_START';
export const LOADING_SUCCESS = 'home/LOADING_SUCCESS';

export const SET_MOST_FOLLOWED_USERS = 'home/SET_MOST_FOLLOWED_USERS';
export const SET_MY_FOLLOWERS = 'home/SET_MY_FOLLOWERS';
export const SET_TOP_SELLERS = 'home/SET_TOP_SELLERS';
export const SET_MY_SELLERS = 'home/SET_MY_SELLERS';
export const SET_ITEMS_FROM_MEMBERS_I_FOLLOW = 'home/SET_ITEMS_FROM_MEMBERS_I_FOLLOW';
export const SET_SELLERS_NEAR_ME = 'home/v';
export const SET_TOP_ITEMS = 'home/SET_TOP_ITEMS';
export const SET_ITEMS_NEAR_ME = 'home/SET_ITEMS_NEAR_ME';
export const SET_DISCOUNTED_ITEMS = 'home/SET_DISCOUNTED_ITEMS';
export const SET_MY_TOP_CATEGORIES = 'home/SET_MY_TOP_CATEGORIES';
export const SET_TOP_UPLOADED_CATEGORIES = 'home/SET_TOP_UPLOADED_CATEGORIES';
export const SET_REWARDS = 'home/SET_REWARDS';
export const SET_CITIES = 'home/SET_CITIES';
export const SET_CAMPUSES = 'home/SET_CAMPUSES';
export const SET_NEWEST_ITEMS_IN_COMMUNITY_GROUPS = 'home/SET_NEWEST_ITEMS_IN_COMMUNITY_GROUPS';
export const SET_NEWEST_ITEMS = 'home/SET_NEWEST_ITEMS';

export const ADD_EXCLUDED_ITEM_IDS_CACHE = 'home/ADD_EXCLUDED_ITEM_IDS_CACHE';
