export const reportTypes = {
  item: 'item',
  profile: 'profile',
};

export default [
  {
    id: 1,
    title: 'offensive_content',
  },
  {
    id: 2,
    title: 'profanity',
  },
  {
    id: 3,
    title: 'inappropriate_item',
  },
  {
    id: 5,
    title: 'price',
  },
  {
    id: 6,
    title: 'fake_profile',
    type: reportTypes.profile,
  },
  {
    id: 7,
    title: 'fake_images',
    type: reportTypes.item,
  },
  {
    id: 8,
    title: 'appears_multiple_times',
    type: reportTypes.item,
  },
  {
    id: 4,
    title: 'else',
  },
];
