import React, { Fragment, useState, useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { View } from 'react-native';
import * as R from 'ramda';
import debounce from 'debounce';
import { SearchBarContainer } from './styles';
import { rs } from '../../../styles';
import {
  Container,
  SearchInput,
  CommunityMembersList,
  FilterHorizontalList,
  FilterButton,
} from '../../ReusableComponents';
import strings from '../../../localization';
import {
  communityMembersOperations,
  communityMembersSelector,
} from '../../../store/communityMembers';
import { getUserLocations } from '../../../store/userLocations/selectores';
import MembersHistory from './components/MembersHistory';
import MembersListSectionTitle from './components/MembersListSectionTitle';
import communityMemberFilters from '../../../constants/communityMemberFilters';

const Members = ({
  communityMembersSearchHistory,
  userLocations,
  getCommunityMembers,
  getRecentMembersSearches,
  saveMemberSearch,
  removeMemberSearch,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState(communityMemberFilters.ALL);

  useEffect(() => {
    const getMembersFunc = async () => {
      await Promise.all([getRecentMembersSearches(), getCommunityMembers()]);
    };

    getMembersFunc();

    return () => {
      getCommunityMembers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCommunityMembers(searchValue, filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const getMembersDebounce = debounce((value) => {
    getCommunityMembers(value, filter);
  }, 400);

  const onChangeText = (value) => {
    setSearchValue(value);
    getMembersDebounce(value);
  };

  const onSaveMemberSearch = (userId) => {
    saveMemberSearch({ userId });
  };

  const onRemoveMemberSearch = ({ memberId, userId }) => {
    removeMemberSearch({ memberId, userId });
  };

  const sortingTypesList = [
    {
      type: communityMemberFilters.ALL,
      title: strings.members.show_all,
    },
    {
      type: communityMemberFilters.TOP_SELLERS,
      title: strings.members.top_sellers,
    },
    {
      type: communityMemberFilters.NEAR_ME,
      title: strings.members.near_me,
    },
    {
      type: communityMemberFilters.MOST_FOLLOWED,
      title: strings.members.most_followed,
    },
    {
      type: communityMemberFilters.PURCHASED_FROM,
      title: strings.members.purchased_from,
    },
  ];

  const sortOptions = R.isEmpty(userLocations)
    ? sortingTypesList.filter(({ type }) => type !== communityMemberFilters.NEAR_ME)
    : sortingTypesList;

  const filterTitleMap = {
    [communityMemberFilters.ALL]: strings.members.filter_by,
    [communityMemberFilters.TOP_SELLERS]: strings.members.top_sellers,
    [communityMemberFilters.NEAR_ME]: strings.members.near_me,
    [communityMemberFilters.MOST_FOLLOWED]: strings.members.most_followed,
    [communityMemberFilters.PURCHASED_FROM]: strings.members.purchased_from,
  };

  const onSetFilter = (keys) => {
    if (keys[0] !== filter) {
      setFilter(keys[0]);
    }
  };

  const onPressResetFilter = () => {
    setFilter(communityMemberFilters.ALL);
  };

  const showSearchHistory =
    !R.isEmpty(communityMembersSearchHistory) && filter === communityMemberFilters.ALL;

  return (
    <Container>
      <SearchBarContainer>
        <SearchInput
          placeholder={strings.members.search_members}
          value={searchValue}
          onChangeText={onChangeText}
        />
      </SearchBarContainer>

      <View>
        <FilterHorizontalList style={[rs.backgroundWhite]}>
          <FilterButton
            options={sortOptions}
            selectedOptionIds={[filter]}
            onChange={onSetFilter}
            onPressResetFilter={onPressResetFilter}
            optionKeyName="type"
            optionTitleName="title"
            primaryButtonTitle={filterTitleMap[filter]}
            filterTitle={strings.members.filter_members_by}
            isActive={filter !== communityMemberFilters.ALL}
            isMultipleSelection={false}
            resetOnActivePress
          />
        </FilterHorizontalList>
      </View>

      <CommunityMembersList
        searchValue={searchValue}
        onPress={onSaveMemberSearch}
        filter={filter}
        extraData={communityMembersSearchHistory}
        ListHeaderComponent={
          <Fragment>
            {showSearchHistory && (
              <Fragment>
                <MembersListSectionTitle title={strings.members.recent} />

                <MembersHistory
                  communityMembersSearchHistory={communityMembersSearchHistory}
                  onSaveMemberSearch={onSaveMemberSearch}
                  onRemoveMemberSearch={onRemoveMemberSearch}
                />

                <MembersListSectionTitle title={strings.members.members} />
              </Fragment>
            )}
          </Fragment>
        }
      />
    </Container>
  );
};

Members.propTypes = {
  communityMembersSearchHistory: T.arrayOf(T.shape({})).isRequired,
  userLocations: T.arrayOf(T.shape({})).isRequired,
  getCommunityMembers: T.func.isRequired,
  getRecentMembersSearches: T.func.isRequired,
  saveMemberSearch: T.func.isRequired,
  removeMemberSearch: T.func.isRequired,
};

export default connect(
  (state) => ({
    userId: state.userInfo.id,
    communityMembersSearchHistory: communityMembersSelector.getCommunityMembersHistory(state),
    userLocations: getUserLocations(state),
  }),
  (dispatch) => ({
    getCommunityMembers: (searchTerm, filterBy) =>
      dispatch(communityMembersOperations.getCommunityMembers({ searchTerm, filterBy })),
    getRecentMembersSearches: () => dispatch(communityMembersOperations.getRecentMembersSearches()),
    saveMemberSearch: ({ userId }) =>
      dispatch(communityMembersOperations.saveMemberSearch({ userId })),
    removeMemberSearch: (params) => dispatch(communityMembersOperations.removeMemberSearch(params)),
  }),
)(Members);
