import React, { Fragment, memo } from 'react';
import { StyleSheet, View } from 'react-native';
import T from 'prop-types';
import UserAvatar from '../UserAvatar';
import TouchableItem from '../TouchableItem';
import { colors, dimensions } from '../../../styles';
import Text from '../Text';
import strings from '../../../localization';
import { calendar } from '../../../utils/dateHelper';

const s = StyleSheet.create({
  contentTouchableContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 64,
    paddingRight: dimensions.medium,
  },
  mainContainer: {
    flex: 1,
    justifyContent: 'center',
  },
});

const MemberItem = memo(
  ({ profileImage, name, onPress, createdAt, hasSoldFirstItem, maxRewardAmount }) => {
    return (
      <TouchableItem useOpacity={false} onPress={onPress} style={s.contentTouchableContainer}>
        <Fragment>
          <UserAvatar uri={profileImage} />

          <View style={s.mainContainer}>
            <Text medium>{name}</Text>
            {/*<Text xsmall color={hasSoldFirstItem ? colors.text : colors.blueLighter}>*/}
            {/*  {hasSoldFirstItem*/}
            {/*    ? strings.referrals.reward_received*/}
            {/*    : strings.referrals.more_tokens.replace('XX', maxRewardAmount)}*/}
            {/*</Text>*/}
            <Text xsmall color={colors.gray}>
              {calendar(createdAt)}
            </Text>
          </View>
        </Fragment>
      </TouchableItem>
    );
  },
);

MemberItem.propTypes = {
  profileImage: T.string,
  name: T.string,
  onPress: T.func,
  createdAt: T.string,
  maxRewardAmount: T.number,
};

export default MemberItem;
