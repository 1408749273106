import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { RefreshControl, ScrollView, View } from 'react-native';
import {
  Container,
  HorizontalGroupsList,
  SectionTitle,
  HorizontalUsersList,
  HorizontalCategoriesList,
  HorizontalItemsList,
  ActionBanner,
  WishesBanner,
  MarketplaceBanner,
} from '../../../ReusableComponents';
import { rs } from '../../../../styles';
import strings from '../../../../localization';
// import userTestimonials from '../../../../constants/userTestimonials';
import { groupsSelectors } from '../../../../store/groups';
import { homeOperations, homeSelectors } from '../../../../store/home';
import { lotsOperations } from '../../../../store/lots';
import {
  AnalyticsService,
  LoadingService,
  NavigationService,
  ToastsService,
} from '../../../../services';
import screens from '../../../../navigation/screens';
import { sortingTypes } from '../../../../constants/sortingTypes';
// import ActionSlider from './components/BannerCarousel';
// import HorizontalFAQList from './components/HorizontalFAQList';
// import HorizontalInfoList from './components/HorizontalInfoList';
// import UserTestimonialsList from './components/UserTestimonialsList';
import HorizontalRewardsList from './components/HorizontalRewardsList';
import HorizontalCitiesList from './components/HorizontalCitiesList';
import HorizontalCampusesList from './components/HorizontalCampusesList';
import InfoList from './components/InfoList';
import guideTypes from '../../../../constants/guidelTypes';
import { guideOperations } from '../../../../store/guides';
import { isRTL } from '../../../../utils/rtlHelper';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';
import { appOperations } from '../../../../store/app';
import { getIsUSDiscoveryZone, getSchoolExtension } from '../../../../store/userInfo/selectors';
import { getIsUSCommunity } from '../../../../utils/usCommunity';
import { isWeb } from '../../../../utils/detectDevice';

const MainTab = ({
  groups,
  cities,
  campuses,
  rewards,
  myFollowersList,
  topSellerUsersList,
  mySellerUsersList,
  sellersNearMeList,
  mostFollowedUsersList,
  topCategoriesList,
  myTopCategoriesList,
  newestItems,
  itemsFromMembersIFollowList,
  topItemsList,
  nearMeItemsList,
  nearPrimaryLocationItemsList,
  discountedItemsList,
  newestItemsList,
  replaceMarketplaceFiltersWithNew,
  communityInfo,
  isShowTokenBanner,
  neverShowGuideAgain,
  getCommunityHomePage,
  userId,
  handleGeolocation,
  isLocationPermissionGranted,
  isUSDiscoveryZone,
  schoolExtension,
}) => {
  const [isRefreshing, setRefreshing] = useState();
  const IS_SOCIAL_DELIVERY = communityInfo.id === 3;
  const isUSCommunity = getIsUSCommunity(communityInfo.id);

  const {
    has_most_followed_users,
    has_my_followers,
    has_top_sellers,
    has_my_last_sellers,
    has_newest_in_group,
    has_items_from_members_i_follow,
    has_items_near_me,
    has_items_near_primary_location,
    has_discounted_items,
    has_my_top_categories,
    has_top_categories,
    has_sellers_near_you,
    has_top_items,
    has_newest_items,
  } = R.propOr({}, 'home_settings', communityInfo);

  const onRefresh = async () => {
    if (isRefreshing) return;
    setRefreshing(true);
    await getCommunityHomePage();
    if (isLocationPermissionGranted) {
      await handleGeolocation();
    }
    setRefreshing(false);
  };

  const onSelectGroup = ({ id }) => {
    replaceMarketplaceFiltersWithNew({ groupIds: [id] });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };
  const onOpenMarketplaceSortedByDistance = () => {
    AnalyticsService.logEvent(analyticsEventTypes.home_see_all_items_near_me, {
      user_id: userId,
    });
    replaceMarketplaceFiltersWithNew({ sortBy: sortingTypes.by_distance });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
        params: 0,
      });
    }, 0);
  };

  const onSelectCategory = (item) => {
    replaceMarketplaceFiltersWithNew({ groupIds: [item.group_id], selectedCategoryIds: [item.id] });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSelectDiscountedItems = () => {
    AnalyticsService.logEvent(analyticsEventTypes.home_see_all_discounted_items, {
      user_id: userId,
    });

    replaceMarketplaceFiltersWithNew({ isSearchByDiscount: true });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSelectItemsFromFollowings = () => {
    AnalyticsService.logEvent(analyticsEventTypes.home_see_all_items_from_followings, {
      user_id: userId,
    });
    replaceMarketplaceFiltersWithNew({ isSearchByFollowings: true });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onNeverShowTokenBannerAgain = () => {
    neverShowGuideAgain({ guideType: guideTypes.HOME_TOKEN_INFO_BANNER });
  };

  const onOpenMarketplace = () => {
    AnalyticsService.logEvent(analyticsEventTypes.home_see_all_categories, {
      user_id: userId,
    });
    NavigationService.navigate(screens.MarketplaceTab, { screen: screens.Marketplace });
    NavigationService.navigate(screens.GroupFilterStack);
  };

  const onSeeAllNewestItems = () => {
    replaceMarketplaceFiltersWithNew({});
    NavigationService.navigate(screens.Marketplace);
    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onOpenAllRewards = () => {
    NavigationService.navigate(screens.EarnTokens);
  };

  const onSelectCity = (city) => {
    replaceMarketplaceFiltersWithNew({ selectedCities: [city] });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSelectCampus = (campus) => {
    replaceMarketplaceFiltersWithNew({ selectedCampuses: [campus] });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSeeAllItemsNearMe = async () => {
    LoadingService.showLoader();

    const coordinates = await handleGeolocation();

    if (!coordinates) {
      ToastsService.showError();
      return;
    }
    LoadingService.hideLoader();

    replaceMarketplaceFiltersWithNew({ coordinates, sortBy: sortingTypes.by_distance });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSeeAllItemsNearPrimaryAddress = () => {
    const updatedFilters = isUSDiscoveryZone
      ? { schoolExtension }
      : { isSearchByPrimaryLocation: true, sortBy: sortingTypes.by_distance };

    replaceMarketplaceFiltersWithNew(updatedFilters);

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSeeAllTopItems = () => {
    replaceMarketplaceFiltersWithNew({ isOnlyFeaturedItems: true }, true);
    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSeeAllCities = () => {
    NavigationService.navigate(screens.CitiesFilterStack);
  };

  const onSeeAllCampuses = () => {
    NavigationService.navigate(screens.CampusesFilter);
  };

  const isVisibleItemsNearMe =
    !!has_items_near_me && isLocationPermissionGranted && !!nearMeItemsList.length;

  const isVisibleItemsNearPrimaryLocation =
    !!has_items_near_primary_location && !!nearPrimaryLocationItemsList.length;

  return (
    <Container>
      <ScrollView
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
        refreshing={isRefreshing}
        contentContainerStyle={isWeb && rs.webContainer}
      >
        <MarketplaceBanner />

        {groups.length > 1 && (
          <Fragment>
            <SectionTitle title={strings.groups.groups} onSeeAllPress={onOpenMarketplace} />
            <HorizontalGroupsList groups={groups} onSelect={onSelectGroup} />
          </Fragment>
        )}

        {!!has_items_from_members_i_follow && !!itemsFromMembersIFollowList.length && (
          <Fragment>
            <SectionTitle
              title={strings.home.items_from_members_i_follow}
              onSeeAllPress={onSelectItemsFromFollowings}
            />
            <HorizontalItemsList
              items={itemsFromMembersIFollowList}
              analyticsEventType={analyticsEventTypes.home_select_item_from_following}
            />
          </Fragment>
        )}

        {isVisibleItemsNearMe && (
          <Fragment>
            <SectionTitle title={strings.home.items_near_me} onSeeAllPress={onSeeAllItemsNearMe} />
            <HorizontalItemsList
              items={nearMeItemsList}
              analyticsEventType={analyticsEventTypes.home_select_item_near_me}
            />
          </Fragment>
        )}

        {!isVisibleItemsNearMe && isVisibleItemsNearPrimaryLocation && (
          <Fragment>
            <SectionTitle
              title={
                isUSDiscoveryZone
                  ? strings.home.items_near_campus
                  : strings.home.items_near_primary_location
              }
              onSeeAllPress={onSeeAllItemsNearPrimaryAddress}
            />
            <HorizontalItemsList
              items={nearPrimaryLocationItemsList}
              analyticsEventType={analyticsEventTypes.home_select_items_near_primary_location}
            />
          </Fragment>
        )}

        {!!has_newest_items && !!newestItemsList.length && (
          <Fragment>
            <SectionTitle title={strings.home.newest_items} onSeeAllPress={onSeeAllNewestItems} />
            <HorizontalItemsList items={newestItemsList} />
          </Fragment>
        )}

        {!!has_top_items && !!topItemsList.length && (
          <Fragment>
            <SectionTitle title={strings.home.top_items} onSeeAllPress={onSeeAllTopItems} />
            <HorizontalItemsList
              items={topItemsList}
              analyticsEventType={analyticsEventTypes.home_select_featured_item}
            />
          </Fragment>
        )}

        {!isUSCommunity && !R.isEmpty(cities) && (
          <Fragment>
            <SectionTitle title={strings.home.popular_cities} onSeeAllPress={onSeeAllCities} />
            <HorizontalCitiesList data={cities} onSelect={onSelectCity} />
          </Fragment>
        )}

        {isUSCommunity && !R.isEmpty(campuses) && (
          <Fragment>
            <SectionTitle title={strings.home.popular_campuses} onSeeAllPress={onSeeAllCampuses} />
            <HorizontalCampusesList data={campuses} onSelect={onSelectCampus} />
          </Fragment>
        )}

        {!R.isEmpty(rewards) && (
          <Fragment>
            <SectionTitle title={strings.home.get_points} onSeeAllPress={onOpenAllRewards} />
            <HorizontalRewardsList data={rewards} />
          </Fragment>
        )}

        {isVisibleItemsNearMe && isVisibleItemsNearPrimaryLocation && (
          <Fragment>
            <SectionTitle
              title={
                isUSDiscoveryZone
                  ? strings.home.items_near_campus
                  : strings.home.items_near_primary_location
              }
              onSeeAllPress={onSeeAllItemsNearPrimaryAddress}
            />
            <HorizontalItemsList
              items={nearPrimaryLocationItemsList}
              analyticsEventType={analyticsEventTypes.home_select_items_near_primary_location}
            />
          </Fragment>
        )}

        {!!has_sellers_near_you && !!sellersNearMeList.length && (
          <Fragment>
            <SectionTitle title={strings.home.sellers_near_me} />
            <HorizontalUsersList
              users={sellersNearMeList}
              analyticsEventType={analyticsEventTypes.home_select_seller_near_me}
            />
          </Fragment>
        )}

        {has_my_last_sellers && !R.isEmpty(mySellerUsersList) && (
          <Fragment>
            <SectionTitle title={strings.home.my_sellers} />
            <HorizontalUsersList
              users={mySellerUsersList}
              analyticsEventType={analyticsEventTypes.home_select_my_seller}
            />
          </Fragment>
        )}

        <WishesBanner analyticsEventType={analyticsEventTypes.home_select_wish_banner} />

        {!!has_my_followers && !!myFollowersList.length && (
          <Fragment>
            <SectionTitle title={strings.home.my_followers} />
            <HorizontalUsersList users={myFollowersList} />
          </Fragment>
        )}
        {/*{isShowTokenBanner && (*/}
        {/*  <View style={[rs.bigMarginTop, rs.smallPaddingTop]}>*/}
        {/*    <TokenBanner onClose={onNeverShowTokenBannerAgain} />*/}
        {/*  </View>*/}
        {/*)}*/}

        {/*<ActionSlider />*/}

        {/*<SectionTitle title={strings.home.all_you_need_to_know} />*/}
        {/*<HorizontalFAQList />*/}

        {/*{!IS_SOCIAL_DELIVERY && (*/}
        {/*  <ActionBanner*/}
        {/*    analyticsEventType={analyticsEventTypes.home_select_invite_friends_banner}*/}
        {/*  />*/}
        {/*)}*/}

        {!!has_top_sellers && !!topSellerUsersList.length && (
          <Fragment>
            <SectionTitle title={strings.home.top_sellers} />
            <HorizontalUsersList
              users={topSellerUsersList}
              analyticsEventType={analyticsEventTypes.home_select_top_seller}
            />
          </Fragment>
        )}

        {has_newest_in_group && (
          <Fragment>
            {newestItems.map((element) => (
              <Fragment key={element.groupId}>
                <SectionTitle
                  title={
                    isRTL
                      ? `${strings.home.newest_in}${element.groupName}`
                      : `${strings.home.newest_in} ${element.groupName}`
                  }
                  onSeeAllPress={() => {
                    AnalyticsService.logEvent(analyticsEventTypes.home_see_all_items_in_category, {
                      user_id: userId,
                    });
                    onSelectGroup({ id: element.groupId });
                  }}
                />
                <HorizontalItemsList
                  items={element.items}
                  analyticsEventType={analyticsEventTypes.home_select_item_in_category}
                />
              </Fragment>
            ))}
          </Fragment>
        )}

        {!!has_discounted_items && !!discountedItemsList.length && (
          <Fragment>
            <SectionTitle
              title={strings.home.discounted_items}
              onSeeAllPress={onSelectDiscountedItems}
            />
            <HorizontalItemsList
              items={discountedItemsList}
              analyticsEventType={analyticsEventTypes.home_select_discounted_item}
            />
          </Fragment>
        )}

        {!!has_most_followed_users && !!mostFollowedUsersList.length && (
          <Fragment>
            <SectionTitle title={strings.home.most_followed_users} />
            <HorizontalUsersList
              users={mostFollowedUsersList}
              analyticsEventType={analyticsEventTypes.home_select_most_followed}
            />
          </Fragment>
        )}

        {!!has_top_categories && !!topCategoriesList.length && (
          <Fragment>
            <SectionTitle title={strings.home.popular_categories} />
            <HorizontalCategoriesList
              categories={topCategoriesList}
              onSelect={onSelectCategory}
              analyticsEventType={analyticsEventTypes.home_select_popular_category}
            />
          </Fragment>
        )}

        {!!has_my_top_categories && !!myTopCategoriesList.length && (
          <Fragment>
            <SectionTitle title={strings.home.my_top_categories} />
            <HorizontalCategoriesList
              categories={myTopCategoriesList}
              onSelect={onSelectCategory}
              analyticsEventType={analyticsEventTypes.home_select_my_top_category}
            />
          </Fragment>
        )}

        {/*<Fragment>*/}
        {/*  <SectionTitle title={strings.community_info.community_info} />*/}
        {/*  <InfoList />*/}
        {/*</Fragment>*/}

        {/*<Fragment>*/}
        {/*  <SectionTitle title={strings.home.user_testimonials} />*/}
        {/*  <UserTestimonialsList testimonials={userTestimonials} />*/}
        {/*</Fragment>*/}

        <View style={rs.marginVertical} />
      </ScrollView>
    </Container>
  );
};

export default connect(
  (state) => ({
    userId: state.userInfo.id,
    communityInfo: state.communityInfo,
    groups: groupsSelectors.getCommunityGroups(state),
    topItemsList: homeSelectors.getTopItems(state),
    myFollowersList: homeSelectors.getMyFollowersList(state),
    topSellerUsersList: homeSelectors.getTopSellerUsersList(state),
    mySellerUsersList: homeSelectors.getMySellerUsersList(state),
    sellersNearMeList: homeSelectors.getSellersNearmeList(state),
    mostFollowedUsersList: homeSelectors.getMostFollowedUsersList(state),
    topCategoriesList: homeSelectors.getTopCategoriesList(state),
    myTopCategoriesList: homeSelectors.getMyTopCategoriesList(state),
    itemsFromMembersIFollowList: homeSelectors.getItemsFromMembersIFollowList(state),
    nearMeItemsList: homeSelectors.getNearMeItemsList(state),
    nearPrimaryLocationItemsList: homeSelectors.getNearPrimaryLocationItemsList(state),
    discountedItemsList: homeSelectors.getDiscountedItemsList(state),
    // newestItems: state.home.newest_in,
    newestItems: homeSelectors.getNewest(state),
    rewards: homeSelectors.getRewardsList(state),
    cities: homeSelectors.getCitiesList(state),
    campuses: homeSelectors.getCampusesList(state),
    isShowTokenBanner: state.guides.showAgain[guideTypes.HOME_TOKEN_INFO_BANNER],
    newestItemsList: homeSelectors.getNewestItemsList(state),
    isLocationPermissionGranted: state.app.isLocationPermissionGranted,
    isUSDiscoveryZone: getIsUSDiscoveryZone(state),
    schoolExtension: getSchoolExtension(state),
  }),
  (dispatch) => ({
    getCommunityHomePage: () => dispatch(homeOperations.getCommunityHomePage()),
    replaceMarketplaceFiltersWithNew: (feedLotFilters, isNeedToResetItems) =>
      dispatch(lotsOperations.replaceMarketplaceFiltersWithNew(feedLotFilters, isNeedToResetItems)),
    neverShowGuideAgain: (prop) => dispatch(guideOperations.neverShowGuideAgain(prop)),
    handleGeolocation: () => dispatch(appOperations.handleGeolocation()),
  }),
)(MainTab);
