/* eslint-disable react/destructuring-assignment,react/no-access-state-in-setstate */
import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { SafeAreaView, View, TextInput } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Separator, Container, Text, TouchableItem } from '../../../ReusableComponents';
import s from './styles';
import { colors, rs } from '../../../../styles';
import {
  CALCULATE_SHIPPING_PRICE,
  CALCULATE_SHIPPING_PRICE_NETHERLANDS,
} from '../../../../api/urls';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import screens from '../../../../navigation/screens';
import { ModalsService } from '../../../../services';
import modalTypes from '../../../../constants/modalTypes';
import { BRAZIL_COMMUNITY } from '../../../../constants/communities';
import {
  getUserLocations,
  getUserShipperLocations,
} from '../../../../store/userLocations/selectores';
import SectionWithTitle from './components/SectionWithTitle';
import ShippingOption from './components/ShippingOption';
import { ButtonContainer } from '../../../ReusableComponents/Buttons/ListButton';
import StaticHeightButton from '../../../ReusableComponents/Buttons/StaticHeightButton';

class Shipping extends Component {
  state = {
    shipping_price: '',
    locations: [],
    selectedLocations: [],
    isActiveMeet: false,
    isActiveShipping: false,
    isShippersDeliveryActive: false,
  };

  componentDidMount() {
    const { route, userLocations } = this.props;

    const shipping_price = route.params.shipping_price || '';
    const itemLocations = route.params.itemLocations;
    const isShippersDeliveryActive = route.params.isShippersDeliveryActive;

    const filteredArray = userLocations.filter(
      (userLocation) =>
        !itemLocations.some(
          (itemLocation) =>
            itemLocation.latitude === userLocation.latitude &&
            itemLocation.longitude === userLocation.longitude,
        ),
    );

    const locations = [...itemLocations.filter((el) => el), ...filteredArray];

    this.setState({
      selectedLocations: itemLocations.filter((el) => !!el),
      locations,
      shipping_price: shipping_price.toString(),
      isActiveMeet: !!itemLocations.length,
      isActiveShipping: !!shipping_price,
      isShippersDeliveryActive,
    });
  }

  componentDidUpdate(oldProps, oldState) {
    const newState = this.state;
    if (
      oldState.shipping_price !== newState.shipping_price ||
      oldState.isShippersDeliveryActive !== newState.isShippersDeliveryActive ||
      oldState.selectedLocations.length !== newState.selectedLocations.length
    ) {
      this.props.navigation.setParams({
        isValid:
          !!newState.shipping_price ||
          newState.selectedLocations.length ||
          newState.isShippersDeliveryActive,
      });
    }
  }

  onSubmit = () => {
    const {
      selectedLocations,
      shipping_price,
      isShippersDeliveryActive,
      isActiveMeet,
    } = this.state;
    const { navigation, route } = this.props;

    const onSetShipping = route.params.onSetShipping;

    onSetShipping({
      locations: isActiveMeet ? selectedLocations : [],
      shipping_price: parseInt(shipping_price) || null,
      isShippersDeliveryActive,
    });

    navigation.goBack();
  };

  onShippingCostChange = (val) => {
    const res = val ? parseInt(val).toString() : '';

    if (!!val && !this.state.shipping_price) {
      this.setState({
        isActiveShipping: true,
      });
    }

    this.setState({
      shipping_price: res,
    });
  };

  onActivatePersonalPickup = () => {
    const { route } = this.props;
    const { isActiveMeet, selectedLocations } = this.state;
    const itemLocations = route.params.itemLocations;

    if (isActiveMeet) {
      this.setState({
        isActiveMeet: false,
      });
      return;
    }
    // else if (!R.isEmpty(selectedLocations)) {
    //   this.setState({
    //     isActiveMeet: true,
    //   });
    //   return;
    // }

    NavigationService.navigate(screens.ItemPickupLocations, {
      itemLocations,
      onSetPickupLocations: this.onSetPickupLocations,
    });
  };

  onSetPickupLocations = (locations) => {
    this.setState({
      isActiveMeet: true,
      selectedLocations: locations,
    });
  };

  onActivateShipping = () => {
    this.setState((prevState) => {
      return {
        isActiveShipping: !prevState.isActiveShipping,
        shipping_price: '',
      };
    });
  };

  onActivateShippersDelivery = () => {
    this.setState((prevState) => {
      return {
        isShippersDeliveryActive: !prevState.isShippersDeliveryActive,
      };
    });
  };

  onCalculateShippingCostPress = () => {
    const { communityInfo } = this.props;
    const isIsraelCommunity = communityInfo.zone_name === 'israel';

    ModalsService.showFullScreenModal(modalTypes.WEB_VIEW, {
      headerTitle: strings.lots.shipping_description,
      url: isIsraelCommunity ? CALCULATE_SHIPPING_PRICE : CALCULATE_SHIPPING_PRICE_NETHERLANDS,
    });
  };

  render() {
    const {
      shipping_price,
      locations,
      selectedLocations,
      isActiveMeet,
      isActiveShipping,
      isShippersDeliveryActive,
    } = this.state;
    const { communityInfo, isShipper, shipperLocations } = this.props;
    const isApplyDisabled =
      !isActiveMeet &&
      !isShippersDeliveryActive &&
      (!isActiveShipping || (isActiveShipping && !shipping_price));

    return (
      <Container>
        <KeyboardAwareScrollView keyboardShouldPersistTaps="handled" enableResetScrollToCoords>
          <SectionWithTitle
            title={strings.shipping.pickup_section_title}
            label={strings.shipping.recommended_label}
          >
            <ShippingOption
              title={strings.shipping.meet_in_person}
              subtitle={strings.shipping.arrange_to_meet}
              iconName="Shipping_personal-pickup"
              onPress={this.onActivatePersonalPickup}
              selected={isActiveMeet}
              infoIconText={strings.shipping.tooltip_info_personal_pickup}
              hasArrow
              isAlwaysShowChildren
            >
              {!R.isEmpty(selectedLocations) && (
                <Fragment>
                  <Separator style={rs.marginVertical} />

                  <View style={s.shippingOptionInner}>
                    {selectedLocations.map(({ formatted_address }, index) => {
                      return (
                        <View
                          key={index.toString()}
                          style={[rs.row, rs.alignCenter, s.locationsListItem]}
                        >
                          <Text
                            key={index.toString()}
                            color={colors.gray}
                            xsmall
                            style={s.addressText}
                          >
                            {formatted_address}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                </Fragment>
              )}
            </ShippingOption>
          </SectionWithTitle>

          {!!isShipper && !R.isEmpty(shipperLocations) && (
            <SectionWithTitle title={strings.shipping.shippers_delivery_section_title}>
              <ShippingOption
                title={strings.shipping.shippers_delivery}
                subtitle={strings.shipping.shippers_delivery_description}
                iconName="scooter"
                infoIconText={strings.shipping.tooltip_info_shippers_delivery}
                onPress={this.onActivateShippersDelivery}
                selected={isShippersDeliveryActive}
              />
            </SectionWithTitle>
          )}

          {communityInfo.id !== BRAZIL_COMMUNITY && (
            <SectionWithTitle title={strings.shipping.domestic_shipping_section_title}>
              <ShippingOption
                title={strings.shipping.domestic_shipping}
                subtitle={strings.shipping.ship_via_local_carrier}
                iconName="Shipping_domestic-shipping"
                onPress={this.onActivateShipping}
                selected={isActiveShipping}
              >
                <View style={s.shippingOptionInner}>
                  <TextInput
                    value={shipping_price}
                    onChangeText={this.onShippingCostChange}
                    placeholder={strings.shipping.shipping_placeholder.replace(
                      'CURRENCY',
                      communityInfo.zone_currency_code,
                    )}
                    keyboardType="numeric"
                    style={s.shippingPriceInput}
                    autoFocus
                  />
                  <TouchableItem onPress={this.onCalculateShippingCostPress} style={rs.marginTop}>
                    <Text color={colors.blueLight} xsmall>
                      {communityInfo.zone_name === 'israel'
                        ? strings.shipping.calculate_shipping_cost
                        : strings.shipping.calculate_shipping_cost_netherlands}
                    </Text>
                  </TouchableItem>
                </View>
              </ShippingOption>
            </SectionWithTitle>
          )}
        </KeyboardAwareScrollView>

        <SafeAreaView style={rs.marginBottom}>
          <ButtonContainer withoutBorder>
            <StaticHeightButton
              title={strings.common.apply}
              onPress={this.onSubmit}
              disabled={isApplyDisabled}
            />
          </ButtonContainer>
        </SafeAreaView>
      </Container>
    );
  }
}

export default connect((state, { navigation }) => ({
  communityInfo: state.communityInfo,
  isShipper: state.userInfo.is_shipper,
  userLocations: getUserLocations(state),
  shipperLocations: getUserShipperLocations(state),
}))(Shipping);
