import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import { Container, EmptyListWithImage, View } from '../../../ReusableComponents';
import { headerStyleTransparentWithBackground } from '../../../../styles/headerStyle';
import strings from '../../../../localization';
import { FlatList, RefreshControl } from 'react-native';
import { lotsOperations, lotsSelectors } from '../../../../store/lots';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import CenteredView from '../../../ReusableComponents/Containers/CenteredView';
import Spinner from '../../../ReusableComponents/Spinner';
import PendingLotItem from './components/PendingLotItem';
import s from './styles';
import { isWeb } from '../../../../utils/detectDevice';
import { rs } from '../../../../styles';

const PendingItems = ({
  data,
  isLoading,
  isLoadingMore,
  getPendingItems,
  approvePendingItem,
  declinePendingItem,
}) => {
  const [isRefreshing, setRefreshing] = useState(false);

  useEffect(() => {
    getPendingItems();
  }, []);

  const onLoadMore = () => {
    getPendingItems(true);
  };

  const onRefresh = async () => {
    setRefreshing(true);

    await getPendingItems();
    setRefreshing(false);
  };

  const onPress = (lot) => {
    NavigationService.push(screens.ItemDetails, { itemId: lot.id, itemType: null });
  };

  return (
    <Container>
      <FlatList
        contentContainerStyle={[s.contentContainerStyle, isWeb && rs.webContainer]}
        emptyListStyle={s.emptyListStyle}
        scrollEventThrottle={100}
        data={isLoading && !isRefreshing ? [] : data}
        showsVerticalScrollIndicator={false}
        keyExtractor={(lot) => lot.id}
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
        refreshing={isRefreshing}
        onEndReachedThreshold={0.5}
        onEndReached={onLoadMore}
        renderItem={({ item }) => (
          <PendingLotItem
            onPress={onPress}
            lot={item}
            onApprovePendingItem={approvePendingItem}
            onDeclinePendingItem={declinePendingItem}
          />
        )}
        ListEmptyComponent={
          <CenteredView>
            {isLoading && !isRefreshing ? (
              <Spinner />
            ) : (
              <EmptyListWithImage
                image="empty_state_my_saved_items"
                text={strings.emptyLists.pending_items}
              />
            )}
          </CenteredView>
        }
        ListFooterComponent={isLoadingMore && <Spinner />}
      />
    </Container>
  );
};

PendingItems.propTypes = {
  data: T.array.isRequired,
  isLoading: T.bool.isRequired,
  isLoadingMore: T.bool.isRequired,
};

export default connect(
  (state) => ({
    data: lotsSelectors.getPendingItems(state),
    isLoading: state.lots.pendingItems.isLoading,
    isLoadingMore: state.lots.pendingItems.isLoadingMore,
  }),
  (dispatch) => ({
    getPendingItems: (isLoadMore = false) =>
      dispatch(
        lotsOperations.getPendingItems({
          isLoadMore,
        }),
      ),
    approvePendingItem: (itemId) => dispatch(lotsOperations.approvePendingItem(itemId)),
    declinePendingItem: (itemId) => dispatch(lotsOperations.declinePendingItem(itemId)),
  }),
)(PendingItems);
