import { StyleSheet } from 'react-native';
import { dimensions, colors } from '../../../styles';
import { isSmallDevice } from '../../../utils/detectDevice';

const IMAGE_WIDTH = dimensions.width;
const IMAGE_HEIGHT = IMAGE_WIDTH * (isSmallDevice ? 0.51 : 0.62);

export default StyleSheet.create({
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  titleContainer: {
    paddingTop: 60,
    paddingBottom: dimensions.medium,
  },
  titleText: {
    fontSize: 26,
  },
  tokenImageContainer: {
    width: dimensions.width,
    alignItems: 'center',
    borderBottomWidth: 1,
  },
  image: {
    height: IMAGE_HEIGHT,
    width: IMAGE_WIDTH,
    marginTop: 48,
    marginBottom: 60,
  },
  signUpButtonContainer: {
    width: dimensions.width,
    paddingHorizontal: dimensions.doubleMedium,
  },
  guestButton: {
    padding: dimensions.medium,
    marginTop: 40,
  },
  guestButtonText: {
    textDecorationLine: 'underline',
  },
});
