import { StyleSheet } from 'react-native';
import { dimensions } from '../../../styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: dimensions.medium,
    flexDirection: 'column',
    alignItems: 'center',
  },
  userImage: {
    width: 80,
    height: 80,
    borderRadius: 80,
    marginBottom: dimensions.xLarge,
  },
  description: {
    marginBottom: 62,
    paddingHorizontal: dimensions.medium,
    lineHeight: 22,
  },
});

export default styles;
