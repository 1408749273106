import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../styles';

export default StyleSheet.create({
  contentContainer: { backgroundColor: colors.grayLightest },
  sectionHeaderContainer: {
    paddingBottom: 10,
    paddingTop: 20,
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.grayLighter,
  },
});
