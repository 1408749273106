import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../../styles';

export default StyleSheet.create({
  itemContainer: {
    justifyContent: 'center',
    paddingHorizontal: dimensions.medium,
    paddingTop: 16,
  },
  separator: {
    marginTop: 16,
  },
  sectionContainer: {
    flexDirection: 'row',
    height: 60,
    alignItems: 'center',
  },
  iconContainer: {
    height: 40,
    width: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.grayLighter,
    marginHorizontal: dimensions.medium,
  },
  activeIconContainer: {
    backgroundColor: colors.grayLight,
  },
  activeItemContainer: {
    backgroundColor: colors.grayLighter,
  },
  groupInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: dimensions.doubleMedium,
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.white,
  },
  groupImage: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: dimensions.medium,
  },
});
