import React, { Fragment, memo } from 'react';
import T from 'prop-types';
import s from './styles';
import { Text, TouchableItem, View } from '../../../../../ReusableComponents';
import { Image } from 'react-native';

const AdditionalItem = memo(({ image, title, onPress }) => {
  return (
    <TouchableItem style={s.button} onPress={onPress}>
      <Fragment>
        <Image
          source={{ uri: image || 'city_placeholder' }}
          style={s.itemImage}
          resizeMode="cover"
        />
        <View>
          <Text medium numberOfLines={1} ellipsizeMode="tail">
            {title}
          </Text>
        </View>
      </Fragment>
    </TouchableItem>
  );
});

AdditionalItem.propTypes = {
  onPress: T.func,
  title: T.string,
  image: T.string,
};

export default AdditionalItem;
