import React from 'react';
import { StyleSheet, View } from 'react-native';
import TouchableItem from '../../TouchableItem';
import Text from '../../Text';
import strings from '../../../../localization';
import { colors, rs, dimensions } from '../../../../styles';
import { ModalsService, NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import { FontIcon } from '../../index';
import modalTypes from '../../../../constants/modalTypes';
import { connect } from 'react-redux';
import guideTypes from '../../../../constants/guidelTypes';
import { guideOperations } from '../../../../store/guides';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 80,
  },
  iconContainer: {
    height: 60,
    width: 60,
    backgroundColor: colors.blueLighter,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: dimensions.halfMedium,
    ...rs.shadow,
  },
  wishIconContainer: {
    backgroundColor: colors.violet,
  },
});

const CreateLot = ({ isShowReviewModal, onPress, params }) => (
  <View style={s.contentContainer}>
    <TouchableItem
      style={[rs.alignCenter, rs.bigMarinRight]}
      onPress={() => {
        onPress();
        if (!!isShowReviewModal) {
          setTimeout(() => ModalsService.showModal(modalTypes.ITEM_REVIEW), 600);
        } else {
          NavigationService.navigate(screens.EditItemStack, {
            screen: screens.EditItem,
          });
        }
      }}
    >
      <View style={s.iconContainer}>
        <FontIcon name="Item" size={36} color={colors.white} />
      </View>
      <Text bold color={colors.white}>
        {strings.items.item}
      </Text>
    </TouchableItem>

    <TouchableItem
      style={[rs.alignCenter, rs.bigMarinLeft]}
      onPress={() => {
        onPress();
        if (!!isShowReviewModal) {
          setTimeout(() => ModalsService.showModal(modalTypes.ITEM_REVIEW, { isWish: true }), 600);
        } else {
          NavigationService.navigate(screens.EditWishStack, { screen: screens.EditWish });
        }
      }}
    >
      <View style={[s.iconContainer, s.wishIconContainer]}>
        <FontIcon name="Wish1" size={36} color={colors.white} />
      </View>
      <Text bold color={colors.white}>
        {strings.wishes.wish}
      </Text>
    </TouchableItem>
  </View>
);

export default connect(
  (state) => ({
    isShowReviewModal: state.guides.showAgain[guideTypes.ITEM_REVIEW],
  }),
  (dispatch) => ({
    neverShowGuideAgain: (prop) => dispatch(guideOperations.neverShowGuideAgain(prop)),
  }),
)(CreateLot);
