import React from 'react';
import Button from '../../Buttons/Button';
import {
  ContentContainer,
  AdminAvatar,
  MainContainer,
  Title,
  Description,
  ButtonContainer,
} from './styles';
import strings from '../../../../localization';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';

const JoinAnyCommunity = ({ onPress }) => {
  return (
    <ContentContainer>
      <AdminAvatar source={{ uri: 'round_logo' }} />
      <MainContainer>
        <Title semiBold>{strings.not_community_member.not_member_of_any_community_title}</Title>
        <Description>
          {strings.not_community_member.not_member_of_any_community_description}
        </Description>

        <ButtonContainer>
          <Button
            secondaryType
            withMarginRight
            title={strings.common.no_thanks}
            onPress={onPress}
          />

          <Button
            title={strings.not_community_member.see_communities}
            onPress={() => {
              onPress();
              NavigationService.navigate(screens.CommunitiesList);
            }}
          />
        </ButtonContainer>
      </MainContainer>
    </ContentContainer>
  );
};

export default JoinAnyCommunity;
