import accountApi from '../../api/account';
import {
  setUserInfo,
  setReferralCode,
  verifyEmailSuccess,
  verifyCampusEmailSuccess,
  setReferralRequired,
} from './actions';
import * as R from 'ramda';
import { ModalsService, ToastsService, NavigationService, LoadingService } from '../../services';
import screens from '../../navigation/screens';
import strings from '../../localization';
import modalTypes from '../../constants/modalTypes';
import { PROMO, REFERRAL } from '../../constants/appCodeTypes';
import { rewardsOperations } from '../rewards';

const getCurrentUserInfo = () => async (dispatch) => {
  try {
    const response = await accountApi.getCurrentUserInfo();
    dispatch(setUserInfo(response));
  } catch (e) {}
};

const updateUserInfo = (params) => async (dispatch, getState) => {
  try {
    const { userInfo } = getState();
    const isEmailUpdate = !!params.email && userInfo.email !== params.email;

    const response = await accountApi.updateInfo(params);
    dispatch(setUserInfo(response));

    if (isEmailUpdate) {
      NavigationService.replace(screens.VerifyEmail);
    }
  } catch (e) {
    ToastsService.showError(strings.error_messages.error);
  }
};

const updateCampusEmail = ({ email }) => async (dispatch) => {
  try {
    await accountApi.updateCampusEmail(email);
    dispatch(setUserInfo({ email }));
    NavigationService.navigate(screens.VerifyCampusEmail);
  } catch (e) {
    if (R.path(['response', 'status'], e) === 406) {
      ToastsService.showError(strings.login.error_campus_email_for_another_number);
    } else if (R.path(['response', 'status'], e) === 403) {
      ToastsService.showError(strings.login.error_not_approved_campus);
    } else {
      ToastsService.showError(strings.error_messages.error);
    }
  }
};

const checkNicknameAvailability = (nickname) => async (dispatch) => {
  try {
    const { is_available } = await accountApi.checkNicknameAvailability(nickname);
    return is_available;
  } catch (e) {}
};

const updateNickname = (nickname) => async (dispatch) => {
  try {
    const response = await accountApi.updateNickname(nickname);
    dispatch(setUserInfo(response));
  } catch (e) {}
};

const applyReferralCode = (referralCode, hasWelcomeReward) => async (dispatch, getState) => {
  try {
    LoadingService.showLoader();

    await accountApi.applyReferralCode(referralCode, hasWelcomeReward);

    if (getState().userInfo?.isReferralRequired) {
      dispatch(setReferralRequired(false));
    }
  } catch (error) {
    const isInvalidCodeError = R.path(['response', 'status'], error) === 404;

    if (isInvalidCodeError) {
      ToastsService.showError(strings.error_messages.referral_code);
      return;
    }

    ToastsService.showError(
      R.pathOr(strings.promo_codes.error_message_not_valid, ['response', 'data'], error),
    );
  } finally {
    LoadingService.hideLoader();
  }
};
const applyCode = (code) => async () => {
  try {
    const { received_tokens_amount, type } = await accountApi.applyCode(code);

    if (type === PROMO) {
      ModalsService.showModal(modalTypes.PROMO_CODE_ACTIVATED, {
        tokensAmount: received_tokens_amount,
      });
    } else if (type === REFERRAL) {
      ModalsService.showModal(modalTypes.PROMO_CODE_ACTIVATED, {
        tokensAmount: received_tokens_amount,
      });
    }
  } catch (err) {
    if (R.path(['response', 'status'], err) === 406) {
      ToastsService.showError(
        R.pathOr(strings.promo_codes.error_message_not_valid, ['response', 'data'], err),
      );
    }
  }
};

const sendEmailVerification = (email, useRedirect = true) => {
  return async () => {
    try {
      LoadingService.showLoader();

      await accountApi.sendEmailVerification(email);

      if (useRedirect) {
        NavigationService.navigate(screens.VerifyEmail);
      }

      LoadingService.hideLoader();
    } catch (error) {
      ToastsService.showError(strings.error_messages.error);
    }
  };
};

const verifyEmail = (code) => {
  return async (dispatch) => {
    try {
      LoadingService.showLoader();

      await accountApi.verifyEmail(code);
      dispatch(verifyEmailSuccess());

      ToastsService.showSuccess(strings.user_info.email_verification_success);
      NavigationService.goBack();
    } catch (error) {
      ToastsService.showError(strings.error_messages.code_verification);
    } finally {
      LoadingService.hideLoader();
    }
  };
};
const verifyCampusEmail = (code) => async (dispatch, getState) => {
  const { userInfo } = getState();
  try {
    LoadingService.showLoader();
    console.log('code ', code);

    const verify = await accountApi.verifyCampusEmail(code);
    dispatch(verifyCampusEmailSuccess());

    console.log('VERIFYYYYY ', verify);
    ToastsService.showSuccess(strings.user_info.email_verification_success);
    NavigationService.reset(screens.AuthorizedApplication, {
      screen: screens.WelcomeScreen,
      params: {
        rewardAmount: userInfo.welcome_reward_amount,
      },
    });
  } catch (error) {
    ToastsService.showError(strings.error_messages.code_verification);
  } finally {
    LoadingService.hideLoader();
  }
};

export default {
  getCurrentUserInfo,
  updateUserInfo,
  updateCampusEmail,
  setUserInfo,
  setReferralCode,
  checkNicknameAvailability,
  updateNickname,
  applyReferralCode,
  applyCode,
  sendEmailVerification,
  verifyEmail,
  verifyCampusEmail,
};
