import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, UsersList, ReferralUserItem } from '../../../ReusableComponents';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import screens from '../../../../navigation/screens';
import { rewardsOperations } from '../../../../store/rewards';
import { COMMUNITY_MEMBERS_LIMIT } from '../../../../constants/listLimits';

const Referrals = ({ getUserReferrals, getUserReferralsWhoSoldItem, route }) => {
  const [state, setState] = useState({
    referrals: [],
    isLoading: false,
    isLoadingMore: false,
    isExistMore: true,
  });

  const isSoldItemReferrals = route.params?.isSoldItemReferrals ?? false;
  const getReferrals = isSoldItemReferrals ? getUserReferralsWhoSoldItem : getUserReferrals;

  useEffect(() => {
    fetchReferrals();
  }, []);

  const { referrals, isLoading, isLoadingMore, isExistMore } = state;

  const fetchReferrals = async () => {
    setState({ ...state, isLoading: true });

    const data = await getReferrals();

    setState({
      ...state,
      isLoading: false,
      referrals: data,
      isExistMore: referrals.length < COMMUNITY_MEMBERS_LIMIT,
    });
  };

  const onLoadMore = async () => {
    if (!isExistMore || isLoading || isLoadingMore) return;
    setState({ ...state, isLoadingMore: true });

    const data = await getReferrals(referrals.length);

    setState({
      ...state,
      referrals: [...referrals, ...data],
      isExistMore: data.length === COMMUNITY_MEMBERS_LIMIT,
      isLoadingMore: false,
    });
  };

  const onSelectUser = ({ name, profile_image, id }) => {
    NavigationService.push(screens.UserProfile, {
      name,
      userId: id.toString(),
      profile_image,
    });
  };

  return (
    <Container>
      <UsersList
        ListItem={({ item }) => (
          <ReferralUserItem
            name={item.name}
            profileImage={item.profile_image}
            hasSoldFirstItem={item.has_sold_first_item}
            maxRewardAmount={item.sold_first_item_max_reward_amount}
            createdAt={item.created_at}
            onPress={() => onSelectUser(item)}
          />
        )}
        data={referrals}
        isLoading={isLoading}
        isLoadingMore={isLoadingMore}
        onLoadMore={onLoadMore}
        emptyComponentText={strings.emptyLists.empty_referral_list}
      />
    </Container>
  );
};

export default connect(null, (dispatch) => ({
  getUserReferrals: (skip) => dispatch(rewardsOperations.getUserReferrals(skip)),
  getUserReferralsWhoSoldItem: (skip) =>
    dispatch(rewardsOperations.getUserReferralsWhoSoldItem(skip)),
}))(Referrals);
