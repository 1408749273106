import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FlatList, RefreshControl } from 'react-native';
import T from 'prop-types';
import { View, Separator, Container, Text } from '../../../ReusableComponents';
import EndorsementItem from './components/EndorsementItem';
import { colors, headerStyle, rs } from '../../../../styles';
import NavigationService from '../../../../services/NavigationService';
import screens from '../../../../navigation/screens';
import strings from '../../../../localization';
import { userOperations } from '../../../../store/user';
import { LoadingService } from '../../../../services';

const Endorsements = ({
  route,
  getUserEndorsements,
  endorseUser,
  unselectUserEndorsement,
  isCurrentUserVerified,
  currentUserId,
  getUserCredibilityDetails,
}) => {
  const userId = route.params.userId;
  const defaultEndorsementsCount = route.params.endorsementsCount;
  const [endorsementsCount, setEndorsementsCount] = useState(defaultEndorsementsCount);
  const onUpdateUserCredibilityDetails = route.params.onUpdateUserCredibilityDetails;
  const isCurrentUser = currentUserId === Number(userId);

  const [isRefreshing, setRefreshing] = useState(false);
  const [endorsements, setEndorsements] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await getUserEndorsements(userId);
    setEndorsements(data);
  };

  const onRefresh = async () => {
    setRefreshing(true);
    await fetchData();
    setRefreshing(false);
  };

  const onCheckUserCredibilityDetails = async () => {
    const userCredibilityDetails = await getUserCredibilityDetails(userId);

    if (endorsementsCount !== userCredibilityDetails.endorsements_count) {
      setEndorsementsCount(userCredibilityDetails.endorsements_count);
      !!onUpdateUserCredibilityDetails && onUpdateUserCredibilityDetails(userCredibilityDetails);
    }
  };

  const onEndorseUser = async (endorsementId) => {
    LoadingService.showLoader();
    const response = await endorseUser({ userId, endorsementId });

    const updatedEndorsements = endorsements.map((element) =>
      element.id !== endorsementId ? element : response,
    );

    setEndorsements(updatedEndorsements);

    await onCheckUserCredibilityDetails();

    LoadingService.hideLoader();
  };

  const onUnselectUserEndorsement = async (endorsementId) => {
    LoadingService.showLoader();
    const response = await unselectUserEndorsement({ userId, endorsementId });

    const updatedEndorsements = endorsements.map((element) =>
      element.id !== endorsementId ? element : response,
    );

    setEndorsements(updatedEndorsements);

    await onCheckUserCredibilityDetails();
    LoadingService.hideLoader();
  };

  // const onOpenMembersWhoEndorsed = async (endorsement) => {
  //   NavigationService.push(screens.MembersWhoEndorsed, {
  //     endorsementId: endorsement.id,
  //     userId,
  //     title: endorsement.title,
  //   });
  // };

  return (
    <Container>
      <FlatList
        data={endorsements}
        keyExtractor={({ id }) => id.toString()}
        ItemSeparatorComponent={() => <Separator />}
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
        refreshing={isRefreshing}
        renderItem={({ item }) => {
          return (
            <EndorsementItem
              isCurrentUser={isCurrentUser}
              isCurrentUserVerified={isCurrentUserVerified}
              endorsement={item}
              onEndorseUser={onEndorseUser}
              onUnselectUserEndorsement={onUnselectUserEndorsement}
              // onOpenMembersWhoEndorsed={onOpenMembersWhoEndorsed}
            />
          );
        }}
        style={rs.paddingBottom}
        ListHeaderComponent={
          <View
            style={[rs.paddingHorizontal, rs.paddingBottom, rs.bigPaddingTop, rs.backgroundGray]}
          >
            <Text medium semiBold color={colors.greenDark}>
              {endorsementsCount}
              {'  '}
              <Text medium semiBold>
                {strings.user.members_endorsements}
              </Text>
            </Text>
          </View>
        }
      />
    </Container>
  );
};

Endorsements.propTypes = {
  getUserEndorsements: T.func.isRequired,
  endorseUser: T.func.isRequired,
  unselectUserEndorsement: T.func.isRequired,
  isCurrentUserVerified: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isCurrentUserVerified: state.userInfo.user_credibility_details.is_verified,
    currentUserId: state.userInfo.id,
  }),
  (dispatch) => ({
    getUserEndorsements: (userId) => dispatch(userOperations.getUserEndorsements(userId)),
    endorseUser: ({ userId, endorsementId }) =>
      dispatch(userOperations.endorseUser({ userId, endorsementId })),
    unselectUserEndorsement: ({ userId, endorsementId }) =>
      dispatch(userOperations.unselectUserEndorsement({ userId, endorsementId })),
    getUserCredibilityDetails: (userId) =>
      dispatch(userOperations.getUserCredibilityDetails(userId)),
  }),
)(Endorsements);
