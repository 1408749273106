import React from 'react';
import { SectionList, View } from 'react-native';
import { connect } from 'react-redux';
import T from 'prop-types';
import { KeyName } from './styles';
import BrandItem from './BrandItem';
import { EmptyList } from '../../../ReusableComponents';
import strings from '../../../../localization';
import { getSelectedCommunityBrandsSectionList } from '../../../../store/group/selectors';
import { rs } from '../../../../styles';

const List = ({ selectedBrand, filteredBrands, selectBrand }) => (
  <SectionList
    sections={filteredBrands}
    keyExtractor={(item) => item.id}
    initialNumToRender={20}
    style={rs.backgroundGray}
    keyboardShouldPersistTaps="handled"
    stickySectionHeadersEnabled={false}
    renderSectionHeader={({ section: { title } }) => (
      <View style={[rs.backgroundGray, rs.smallPaddingTop]}>
        <KeyName semiBold medium>
          {title}
        </KeyName>
      </View>
    )}
    ListEmptyComponent={<EmptyList text={strings.emptyLists.emptyContactsList} />}
    ListHeaderComponent={
      <BrandItem
        brand={{ id: 'no_brand', name: strings.lots.no_brands }}
        onSelect={() => selectBrand({})}
        selectedBrand={selectedBrand}
      />
    }
    renderItem={({ item }) => (
      <BrandItem brand={item} onSelect={() => selectBrand(item)} selectedBrand={selectedBrand} />
    )}
  />
);

List.propTypes = {
  selectedBrand: T.object,
  filteredBrands: T.array,
  selectBrand: T.func,
};

export default connect((state, { brands, searchValue }) => ({
  filteredBrands: getSelectedCommunityBrandsSectionList({ brands, searchValue }),
}))(List);
