import { createSelector } from 'reselect';
import * as R from 'ramda';

export const getNotifications = createSelector(
  [
    R.pathOr([], ['notifications', 'notificationIds']),
    R.pathOr({}, ['notifications', 'notificationEntities']),
  ],
  (ids, entities) => ids.map(id => entities[id]),
);

export default {
  getNotifications,
};
