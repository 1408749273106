import React, { memo, Fragment } from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { numberWithCommas } from '../../../../../utils/stringHelper';
import {
  ASK_TO_BUY,
  SELLER_LEFT_COMMUNITY,
  BUYER_LEFT_COMMUNITY,
  CONFIRM_CANCEL_PURCHASE,
  OFFER_CONFIRMED,
} from '../../../../../store/offers/offerStatuses';
import s from './styles';
import {
  Text,
  RoundButton,
  TokenIcon,
  TouchableItem,
  IconButton,
  AppImage,
} from '../../../../ReusableComponents';
import { colors, rs } from '../../../../../styles';
import strings from '../../../../../localization';
import { time } from '../../../../../utils/dateHelper';
import NavigationService from '../../../../../services/NavigationService';
import screens from '../../../../../navigation/screens';
import {
  BUYER_ASKED_TO_BUY,
  BUYER_CONFIRMED_DELIVERY,
  REQUESTER_REJECTED_OFFER,
} from '../../../../../store/offers/offerActions';

const ItemView = memo(({ offer, onPerformAction, onOpenChat }) => {
  const {
    id,
    status_id,
    item_id,
    item_name,
    item_image,
    seller_name,
    buyer_name,
    discounted_price,
    item_price,
    created_at,
    updated_at,
    seller_id,
    buyer_id,
    seller_image,
    buyer_image,
    chat_room_id,

    wish_id,
    wish_title,
  } = offer;

  const isUserLeftCommunity =
    parseInt(status_id) === BUYER_LEFT_COMMUNITY || parseInt(status_id) === SELLER_LEFT_COMMUNITY;

  let statusText = '';

  if (Number(status_id) === ASK_TO_BUY) {
    statusText = strings.buy_sell.pending_your_decision;
  } else if (Number(status_id) === OFFER_CONFIRMED) {
    statusText = strings.buy_sell.pending_delivery;
  } else if (Number(status_id) === CONFIRM_CANCEL_PURCHASE) {
    statusText = strings.buy_sell.purchase_request_sent;
  }

  const onOpenUserProfile = () => {
    NavigationService.navigate(screens.UserProfile, {
      name: seller_name,
      userId: seller_id,
      profile_image: seller_image,
    });
  };

  const onOpenItemDetails = () => {
    NavigationService.navigate(screens.ItemDetails, { itemId: item_id });
  };

  const onOpenWishDetails = () => {
    NavigationService.navigate(screens.WishDetails, { wishId: wish_id });
  };

  return (
    <View style={rs.paddingHorizontal}>
      <View style={[s.contentContainer, rs.shadow]}>
        <TouchableItem style={s.topContainer} onPress={onOpenItemDetails}>
          <AppImage source={{ uri: item_image }} style={s.itemImage} />
          <View style={s.nameContainer}>
            <View style={s.priceContainer}>
              <TokenIcon isSmall />
              <Text style={s.priceText} bold medium color={colors.secondary}>
                {numberWithCommas(discounted_price || item_price)}{' '}
              </Text>
            </View>

            <Text medium semiBold numberOfLines={1}>
              {item_name}
            </Text>

            <Text xsmall color={colors.gray} style={rs.smallPaddingTop}>
              {time(created_at)}
            </Text>
          </View>

          {isUserLeftCommunity && (
            <Text color={colors.gray}>{strings.buy_sell.user_left_community}</Text>
          )}
        </TouchableItem>

        <View style={[s.infoContainer, rs.smallMarginBottom]}>
          <Text style={s.infoTitleText}>{strings.buy_sell.wish_fulfiller}:</Text>
          <TouchableOpacity
            style={[rs.row, rs.marginRight, rs.alignCenter]}
            onPress={onOpenUserProfile}
          >
            <AppImage style={s.userImage} source={{ uri: seller_image }} />
            <Text color={colors.blueLight}>{seller_name}</Text>
          </TouchableOpacity>
          <IconButton
            name="Message-user"
            size={26}
            color={colors.blueLight}
            onPress={() => onOpenChat(chat_room_id)}
          />
        </View>

        <View style={[s.infoContainer, rs.smallMarginBottom]}>
          <Text style={s.infoTitleText}>{strings.buy_sell.for_wish}:</Text>

          <TouchableOpacity onPress={onOpenWishDetails}>
            <Text color={colors.blueLight}>{wish_title}</Text>
          </TouchableOpacity>
        </View>

        <View style={[s.infoContainer, rs.smallMarginTop]}>
          <Text style={s.infoTitleText}>{strings.buy_sell.status}:</Text>
          <Text semiBold>{statusText}</Text>
        </View>

        <View style={s.buttonsContainer}>
          {parseInt(status_id) === ASK_TO_BUY && (
            <Fragment>
              <RoundButton
                fullWidth
                secondaryType
                withMarginRight
                onPress={() => onPerformAction(id, REQUESTER_REJECTED_OFFER)}
                title={strings.common.no_thanks}
              />
              <RoundButton
                fullWidth
                onPress={() => onPerformAction(id, BUYER_ASKED_TO_BUY)}
                title={strings.buy_sell.ask_to_buy}
              />
            </Fragment>
          )}
          {parseInt(status_id) === CONFIRM_CANCEL_PURCHASE && (
            <RoundButton
              fullWidth
              secondaryType
              onPress={() => onPerformAction(id, REQUESTER_REJECTED_OFFER)}
              title={strings.common.cancel}
            />
          )}

          {parseInt(status_id) === OFFER_CONFIRMED && (
            <RoundButton
              fullWidth
              onPress={() => onPerformAction(id, BUYER_CONFIRMED_DELIVERY)}
              title={strings.buy_sell.confirm_delivery}
            />
          )}
        </View>
      </View>
    </View>
  );
});

export default ItemView;
