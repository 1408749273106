import followersApi from '../../api/followers';
import {
  setFollowerCounts,
  increaseFollowingsCounter,
  increaseFollowersCounter,
  reduceFollowingsCounter,
} from './actions';

const followMember = (userId) => async (dispatch, getState) => {
  try {
    dispatch(increaseFollowingsCounter());
    return await followersApi.followUser(userId);
  } catch (err) {
    dispatch(reduceFollowingsCounter());
    return null;
  }
};

const unfollowMember = (userId) => async (dispatch, getState) => {
  try {
    dispatch(reduceFollowingsCounter());
    return await followersApi.unfollowUser(userId);
  } catch (err) {
    dispatch(increaseFollowingsCounter());
    return null;
  }
};

const getCurrentUserFollowerCounts = () => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    const { user_followers_count, user_followings_count } = await followersApi.getFollowersCounts(
      userId,
    );

    dispatch(
      setFollowerCounts({
        followersCount: user_followers_count,
        followingsCount: user_followings_count,
      }),
    );
  } catch (err) {
  }
};

const getFollowers = (userId, searchTerm = '', skip) => async (dispatch, getState) => {
  try {
    return await followersApi.getUserFollowers({ userId, searchTerm, skip });
  } catch (err) {
    return null;
  }
};

const getFollowings = (userId, searchTerm = '', skip) => async (dispatch, getState) => {
  try {
    return await followersApi.getUserFollowings({ userId, searchTerm, skip });
  } catch (err) {
    return null;
  }
};

export default {
  followMember,
  unfollowMember,
  increaseFollowingsCounter,
  increaseFollowersCounter,

  getFollowers,
  getFollowings,
  getCurrentUserFollowerCounts,
};
