import React, { memo, Fragment } from 'react';
import T from 'prop-types';
import { FontIcon, SelectImagesGalery, Separator, Text, TouchableItem } from '../../../../ReusableComponents';
import { colors, rs } from '../../../../../styles';
import Title from './Title';
import { Box, TitleContainer } from './styles';
import { NavigationService } from '../../../../../services';
import screens from '../../../../../navigation/screens';
import strings from '../../../../../localization';

const ImagesSelector = ({ images, setImage }) => {
  const onPressPhotoTips = () => {
    NavigationService.navigate(screens.PhotoTips);
  };

  return (
    <Fragment>
      <Box withoutPadding withoutTopPadding gray>
        <TitleContainer withoutMargin>
          <Title withPadding>{strings.common.select_images}</Title>

          <TouchableItem onPress={onPressPhotoTips} style={[rs.bigPaddingTop, rs.paddingBottom]}>
            <Text color={colors.blueLight} xsmall>
              {strings.items.photo_tips}
            </Text>
          </TouchableItem>
        </TitleContainer>

        <SelectImagesGalery images={images} setImage={setImage}/>
      </Box>
      <Separator/>
    </Fragment>
  );
};

ImagesSelector.propTypes = {
  images: T.array,
  setImage: T.func,
};

export default ImagesSelector;
