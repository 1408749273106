import { StyleSheet } from 'react-native';
import colors from '../../../styles/colors';
import { dimensions, rs } from '../../../styles';

export default StyleSheet.create({
  contentContainer: {
    transform: [{ scaleX: -1 }],
    alignItems: 'flex-end',
  },
  itemContainer: {
    height: 190,
    width: 140,
    borderRadius: 4,
    backgroundColor: colors.white,
    ...rs.shadow,

    transform: [{ scaleX: -1 }],
    marginHorizontal: dimensions.halfMedium,
    marginTop: 2, //to make shadow visible
    marginBottom: 2,
  },
  image: {
    height: 140,
    width: 140,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  listContentContainerStyle: {
    paddingHorizontal: dimensions.halfMedium,
  },
});
