import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FlatList } from 'react-native';
import T from 'prop-types';
import { View, Separator, Container } from '../../../ReusableComponents';
import MemberItem from '../../../ReusableComponents/Lists/CommunityMembersList/ListItem/ListItem';
import { headerStyle, rs } from '../../../../styles';
import { userOperations } from '../../../../store/user';
import { getParamOr } from '../../../../utils/navHelper';

const MembersWhoEndorsed = ({ getMembersWhoEndorsedUser, route }) => {
  const endorsementId = route.params.endorsementId;
  const userId = route.params.userId;

  const [members, setMembers] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await getMembersWhoEndorsedUser({ endorsementId, userId });
    setMembers(data);
  };

  return (
    <Container>
      <FlatList
        data={members}
        keyExtractor={({ id }) => id.toString()}
        ItemSeparatorComponent={() => <Separator />}
        renderItem={({ item }) => {
          return (
            <MemberItem
              userId={item.id}
              name={item.name}
              nickname={item.nickname}
              profileImage={item.profile_image}
            />
          );
        }}
        style={rs.paddingBottom}
      />
    </Container>
  );
};

MembersWhoEndorsed.propTypes = {
  user: T.object.isRequired,
  followersTotalCount: T.number.isRequired,
  followingsTotalCount: T.number.isRequired,
};

export default connect(
  (state) => ({
    // isCurrentUserVerified: state.userInfo.is_verified,
  }),
  (dispatch) => ({
    getMembersWhoEndorsedUser: ({ userId, endorsementId }) =>
      dispatch(userOperations.getMembersWhoEndorsedUser({ userId, endorsementId })),
  }),
)(MembersWhoEndorsed);
