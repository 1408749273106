import React, { Fragment } from 'react';
import FontIcon from './FontIcon';
import { colors } from '../../styles';

const FavoriteIcon = ({ isFavorite, size = 20 }) => (
  <Fragment>
    {isFavorite ? (
      <FontIcon name="full_heart" size={size} color={colors.secondary} />
    ) : (
      <FontIcon name="ouline_heart" size={size} color={colors.secondary} />
    )}
  </Fragment>
);


export default FavoriteIcon;
