import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { ScrollView, KeyboardAvoidingView, Keyboard } from 'react-native';
import T from 'prop-types';
import {
  Separator,
  BackButton,
  View,
  Button,
  Text,
  Container,
  Input,
  TokensHorizontalList,
  EmptyListWithIcon,
  CenteredView,
} from '../../../ReusableComponents';
import s from './styles';
import { headerStyle, colors, rs } from '../../../../styles';
import strings from '../../../../localization';
import { LoadingService, NavigationService } from '../../../../services';
import tokensExchangeOperations from '../../../../store/tokensExchange/operations';
import screens from '../../../../navigation/screens';
import { isIos } from '../../../../utils/detectDevice';

const TokensExchange = ({
  performExchange,
  getTokensWhereExchangeAvailable,
  getTokensForExchangeFromTokenId,
}) => {
  const [selectedTokenFromExchange, setSelectedTokenFromExchange] = useState({});
  const [selectedTokenToExchange, setSelectedTokenToExchange] = useState({});
  const [exchangeFromAmount, setExchangeFromAmount] = useState('0');
  const [exchangeToAmount, setExchangeToAmount] = useState('0');
  const [tokensFromExchange, setTokensFromExchange] = useState([]);
  const [tokensToExchange, setTokensToExchange] = useState([]);

  const [isLoadingTokensFromExchange, setLoadingTokensFromExchange] = useState(false);
  const [isLoadingTokensToExchange, setLoadingTokensToExchange] = useState(true);

  useEffect(() => {
    onFetchData();
  }, []);

  const onFetchData = async () => {
    setLoadingTokensFromExchange(true);

    const tokensFrom = await getTokensWhereExchangeAvailable();

    setLoadingTokensFromExchange(false);
    setTokensFromExchange(tokensFrom);

    const selectedTokenFrom = tokensFrom[0];
    setSelectedTokenFromExchange(selectedTokenFrom);

    setLoadingTokensToExchange(true);

    const tokensTo = await getTokensForExchangeFromTokenId(selectedTokenFrom.token_id);

    setTokensToExchange(tokensTo);
    setLoadingTokensToExchange(false);
    setSelectedTokenToExchange(tokensTo[0]);
  };

  const onSubmit = async () => {
    Keyboard.dismiss();
    LoadingService.showSuccessLoader();

    try {
      await performExchange({
        token_id: selectedTokenFromExchange.token_id,
        to_token_id: selectedTokenToExchange.token_id,
        exchange_amount: exchangeFromAmount,
      });

      LoadingService.hideSuccessLoader({
        callback: () => {
          NavigationService.navigate(screens.Transactions);
        },
      });
    } catch (error) {
      LoadingService.hideSuccessLoader({ isSuccess: false });
    }
  };

  const onRecalculateAmount = () => {
    const toAmount = Math.round(selectedTokenToExchange.exchange_rate * exchangeFromAmount);

    setExchangeToAmount(toAmount);
  };

  const onSetAmount = amount => {
    const value = amount.replace(/[^0-9]/g, '');

    const toAmount = Math.round(selectedTokenToExchange.exchange_rate * value);

    setExchangeFromAmount(value);
    setExchangeToAmount(toAmount);
  };

  const onSelectTokenFromExchange = async token => {
    if (selectedTokenFromExchange.token_id === token.token_id) return;

    setSelectedTokenFromExchange(token);
    setLoadingTokensToExchange(true);
    const tokens = await getTokensForExchangeFromTokenId(token.token_id);

    setTokensToExchange(tokens);
    setSelectedTokenToExchange(tokens[0]);
    setLoadingTokensToExchange(false);

    setTimeout(() => {
      onRecalculateAmount();
    });
  };

  const onSelectTokenToExchange = token => {
    if (selectedTokenToExchange.token_id === token.token_id) return;

    setSelectedTokenToExchange(token);

    setTimeout(() => {
      onRecalculateAmount();
    });
  };

  const isNotEnoughTokens =
    Number(exchangeFromAmount) > Number(R.propOr(0, 'balance', selectedTokenFromExchange));

  if (R.isNil(selectedTokenFromExchange)) {
    return (
      <CenteredView>
        <EmptyListWithIcon
          name="transactions"
          text={strings.tokens_exchange.no_available_exchanges}
        />
      </CenteredView>
    );
  }

  return (
    <Container>
      <KeyboardAvoidingView
        behavior={isIos ? 'padding' : null}
        style={rs.flex}
        keyboardVerticalOffset={isIos ? 65 : 0}
      >
        <ScrollView>
          <View style={s.boxContainer}>
            <Text large semiBold style={s.subtitle}>
              {strings.tokens_exchange.from}
            </Text>
            <TokensHorizontalList
              isLoading={isLoadingTokensFromExchange}
              tokens={tokensFromExchange}
              selectedToken={selectedTokenFromExchange}
              onSelectToken={onSelectTokenFromExchange}
            />
          </View>

          <Separator medium />

          <View style={s.boxContainer}>
            <Text large semiBold style={s.subtitle}>
              {strings.tokens_exchange.amount}
            </Text>

            <View style={s.amountInputContainer}>
              <Input
                style={[s.amountInput, isNotEnoughTokens && { color: colors.red }]}
                maxLength={5}
                placeholder="0"
                keyboardType="numeric"
                onChangeText={onSetAmount}
                value={exchangeFromAmount === '0' ? '' : exchangeFromAmount}
              />
            </View>

            <View style={s.errorMessageContainer}>
              {isNotEnoughTokens && (
                <Text color={colors.red}>
                  {strings.tokens_exchange.you_dont_have_enough_tokens}
                </Text>
              )}
            </View>
          </View>

          <Separator medium />
          <View style={s.boxContainer}>
            <Text large semiBold style={s.subtitle}>
              {strings.tokens_exchange.to}
            </Text>

            <TokensHorizontalList
              isLoading={isLoadingTokensToExchange}
              tokens={tokensToExchange}
              selectedToken={selectedTokenToExchange}
              onSelectToken={onSelectTokenToExchange}
            />
          </View>

          <Separator medium />

          {selectedTokenToExchange.token_name && selectedTokenFromExchange.token_name && (
            <Fragment>
              <View style={s.boxContainer}>
                <Text large semiBold style={s.subtitle}>
                  {strings.tokens_exchange.summary}
                </Text>

                <Text large style={s.summaryText}>
                  {strings.tokens_exchange.exchange} {exchangeFromAmount || '0'}{' '}
                  {selectedTokenFromExchange.token_name}
                  {'\n'}
                  {strings.tokens_exchange.for} {exchangeToAmount}{' '}
                  {selectedTokenToExchange.token_name}
                </Text>
              </View>
            </Fragment>
          )}
        </ScrollView>

        <Separator />
        <View style={s.buttonContainer}>
          <Button
            title={strings.tokens_exchange.exchange}
            onPress={onSubmit}
            disabled={isNotEnoughTokens || !Number(exchangeFromAmount)}
          />
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

TokensExchange.navigationOptions = () => ({
  ...headerStyle,
  headerTitle: strings.tokens_exchange.title,
  headerLeft: <BackButton isCancel />,
  headerRight: <View />,
});

TokensExchange.propTypes = {
  performExchange: T.func,
  getTokensWhereExchangeAvailable: T.func,
  getTokensForExchangeFromTokenId: T.func,
};

export default connect(
  null,
  (dispatch, { navigation }) => ({
    getTokensWhereExchangeAvailable: () =>
      dispatch(tokensExchangeOperations.getTokensWhereExchangeAvailable()),
    getTokensForExchangeFromTokenId: tokenId =>
      dispatch(tokensExchangeOperations.getTokensForExchangeFromTokenId(tokenId)),
    performExchange: ({ token_id, to_token_id, exchange_amount }) =>
      dispatch(
        tokensExchangeOperations.performExchange({
          token_id,
          to_token_id,
          exchange_amount,
        }),
      ),
  }),
)(TokensExchange);
