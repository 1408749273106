import React, { memo } from 'react';
import { View, StyleSheet } from 'react-native';
import T from 'prop-types';
import { connect } from 'react-redux';
import { Text, TouchableItem } from '../../../../ReusableComponents';
import { rs } from '../../../../../styles';
import { chatsSelectors } from '../../../../../store/chats';
import NavigationService from '../../../../../services/NavigationService';
import screens from '../../../../../navigation/screens';

const s = StyleSheet.create({
  container: {
    width: '100%',
  },
});

const ChatNameHeaderTitle = memo(({ chat }) => {
  const { interlocutor_name, interlocutor_id, interlocutor_image } = chat;

  const onOpenUserProfile = () => {
    NavigationService.navigate(screens.UserProfile, {
      name: interlocutor_name,
      userId: interlocutor_id,
      profile_image: interlocutor_image,
    });
  };

  return (
    <TouchableItem onPress={onOpenUserProfile} style={s.container}>
      <View style={[rs.row, rs.alignItems, rs.justifyCenter]}>
        <Text large>{interlocutor_name}</Text>
      </View>
    </TouchableItem>
  );
});

ChatNameHeaderTitle.propTypes = {
  chat: T.object,
};

export default connect((state, { chatId }) => ({
  chat: chatsSelectors.getChatInfoById(state, chatId),
}))(ChatNameHeaderTitle);
