import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image, KeyboardAvoidingView, ScrollView } from 'react-native';
import strings from '../../../../localization';
import { isIos } from '../../../../utils/detectDevice';
import { userOperations } from '../../../../store/userInfo';
import {
  View,
  Text,
  Container,
  Button,
  InputWithTitleAndValidation,
  ScreenHeader,
} from '../../../ReusableComponents';
import { colors } from '../../../../styles';
import s from './style';

const TIMER_INITIAL_VALUE = 30;

const VerifyEmailScreen = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.userInfo?.email);
  const [verificationCode, setVerificationCode] = useState('');
  const [count, setCount] = useState(TIMER_INITIAL_VALUE);
  const [isAvailableToSendCode, setAvailableToSendCode] = useState(false);

  useEffect(() => {
    let interval = null;

    if (!isAvailableToSendCode) {
      interval = setInterval(() => {
        if (count > 1) {
          setCount((prevState) => prevState - 1);
        } else {
          setAvailableToSendCode(true);
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isAvailableToSendCode, count]);

  const onResendCode = async () => {
    await dispatch(userOperations.sendEmailVerification(email, false));
    setAvailableToSendCode(false);
    setCount(TIMER_INITIAL_VALUE);
  };

  const onSubmitCode = async () => {
    await dispatch(userOperations.verifyEmail(verificationCode));
    setVerificationCode('');
  };

  const isSubmitButtonDisabled = !verificationCode;
  const countdown = `0:${count.toLocaleString('en-US', { minimumIntegerDigits: 2 })}`;

  return (
    <Container>
      <KeyboardAvoidingView
        keyboardShouldPersistTabs="handled"
        behavior={isIos ? 'padding' : null}
        style={s.keyboardAvoidingView}
      >
        <ScreenHeader withoutBorder headerTitle={strings.user_info.email_verification} />
        <ScrollView keyboardShouldPersistTabs="handled">
          <Image style={s.image} source={{ uri: 'email_verification' }} resizeMode="contain" />

          <InputWithTitleAndValidation
            onChangeText={setVerificationCode}
            value={verificationCode}
            maxLength={6}
            placeholder={strings.promo_codes.enter_code_here}
            inputStyle={s.input}
            inputContainerStyle={s.inputContainer}
            keyboardType="numeric"
            underlineColorAndroid="transparent"
            autoFocus
          />

          <Text style={s.infoText} color={colors.gray}>
            {strings.user_info.email_verification_description}
          </Text>

          <View style={s.resendCodeWrapper}>
            <Fragment>
              {isAvailableToSendCode ? (
                <Text onPress={onResendCode} style={s.resendCodeText}>
                  {strings.login.send_code_again}
                </Text>
              ) : (
                <Text color={colors.gray}>
                  {strings.login.resend_code_in} {countdown}
                </Text>
              )}
            </Fragment>
          </View>
        </ScrollView>

        <View style={s.buttonContainer}>
          <Button
            title={strings.user_info.email_verification_button}
            onPress={onSubmitCode}
            disabled={isSubmitButtonDisabled}
          />
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

export default VerifyEmailScreen;
