import strings from '../../../../localization';

export default (transaction) =>
  ({
    ISSUE_REWARD: {
      title: strings.transactions.reward_title,
      description: transaction.reward_title,
      iconName: 'Transactions_Rewards-copy',
      isIncome: true,
      type: 'REWARD',
    },
    ISSUE_BONUS: {
      title: strings.transactions.reward_title,
      description: transaction.note,
      iconName: 'Transactions_Rewards-copy',
      isIncome: true,
      type: 'REWARD',
    },
    ISSUE: {
      title: strings.transactions.issue_title,
      description: transaction.note,
      iconName: 'Transactions_Issue-copy',
      isIncome: true,
      type: 'ISSUE',
    },
    WITHDRAW: {
      title: strings.transactions.withdraw_title,
      description: transaction.note,
      iconName: 'Transactions_Issue-copy',
      isIncome: false,
      type: 'WITHDRAW',
    },
    TRANSFER_EXPENSE: {
      title: transaction.refund_type
        ? strings.buy_sell.refund
        : strings.transactions.transfer_expense_title,
      description: transaction.refund_type
        ? strings.transactions.transfer_expense_refund_description
            .replace('XX', transaction.item_title)
            .replace('YY', transaction.user_name)
        : strings.transactions.transfer_expense_description
            .replace('XX', transaction.item_title)
            .replace('YY', transaction.user_name),
      iconName: transaction.refund_type
        ? 'Transactions_Expense_Refund-copy'
        : 'Transactions_Expense_Purchase-copy-1',
      isIncome: false,
      type: 'TRANSFER',
    },
    TRANSFER_INCOME: {
      title: transaction.refund_type
        ? strings.buy_sell.refund
        : strings.transactions.transfer_income_title,
      description: transaction.refund_type
        ? strings.transactions.transfer_income_refund_description
            .replace('XX', transaction.user_name)
            .replace('YY', transaction.item_title)
        : strings.transactions.transfer_income_description
            .replace('XX', transaction.item_title)
            .replace('YY', transaction.user_name),
      isIncome: true,
      iconName: transaction.refund_type
        ? 'Transactions_Expense_Refund-copy'
        : 'Transactions_Income_Refund-copy',
      type: 'TRANSFER',
    },
    DONATION_EXPENSE: {
      title: strings.transactions.donation_title,
      description: strings.transactions.donation_expense_description,
      iconName: 'donation',
      isIncome: false,
      type: 'DONATION',
    },
    DONATION_INCOME: {
      title: strings.transactions.donation_title,
      description: strings.transactions.donation_income_description,
      isIncome: true,
      iconName: 'donation',
      type: 'DONATION',
    },
    EXCHANGE_TO: {
      title: strings.transactions.exchange_to_title,
      description: strings.transactions.exchange_to_description,
      isIncome: true,
      amount: transaction.exchange_amount,
      type: 'EXCHANGE_TO',
      iconName: 'Transactions_Expense_Refund-copy',
    },
    EXCHANGE_FROM: {
      title: strings.transactions.exchange_from_title,
      description: strings.transactions.exchange_to_description,
      isIncome: false,
      amount: transaction.initial_amount,
      type: 'EXCHANGE_FROM',
      iconName: 'Transactions_Expense_Refund-copy',
    },
  }[transaction.type]);
