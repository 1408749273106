import React, { Fragment, memo } from 'react';
import T from 'prop-types';
import { FontIcon, Input, Separator } from '../../../../ReusableComponents';
import { colors, rs } from '../../../../../styles';
import Title from './Title';
import { Box, TitleContainer } from './styles';
import strings from '../../../../../localization';

const OriginalPriceInput = memo(({ iconName, value, onFocus, onChangeText }) => (
  <Fragment>
    <Box>
      {/*<Title mandatory>{strings.items.original_price}</Title>*/}
      <Input
        style={rs.paddingVertical}
        keyboardType="numeric"
        onChangeText={onChangeText}
        value={value}
        onFocus={onFocus}
        placeholder={strings.items.original_price_placeholder}
        underlineColorAndroid="transparent"
      />
    </Box>
  </Fragment>
));

OriginalPriceInput.propTypes = {
  iconName: T.string,
  value: T.string,
  onFocus: T.func,
  onChangeText: T.func,
};

export default OriginalPriceInput;
