import React from 'react';
import T from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { colors, dimensions } from '../../../../styles';
import Text from '../../../ReusableComponents/Text';

const s = StyleSheet.create({
  titleContainer: {
    backgroundColor: colors.grayLighter,
    paddingHorizontal: dimensions.medium,
    paddingTop: dimensions.doubleMedium,
    paddingBottom: dimensions.medium,
  },
});

const MembersListSectionTitle = ({ title }) => {
  return (
    <View style={s.titleContainer}>
      <Text semiBold medium>
        {title}
      </Text>
    </View>
  );
};

MembersListSectionTitle.propTypes = {
  title: T.string.isRequired,
};

export default MembersListSectionTitle;
