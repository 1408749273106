import * as R from 'ramda';

export const toFormData = data => {
  const form = new FormData();

  R.forEachObjIndexed((value, key) => {
    form.append(key, value);
  }, data);

  return form;
};
