import React from 'react';
import { StyleSheet, View } from 'react-native';
import { TabBar } from 'react-native-tab-view';
import { colors, fontSizes, dimensions } from '../../../styles';
import Text from '../Text';
import { isWeb } from '../../../utils/detectDevice';

const s = StyleSheet.create({
  tabBar: {
    backgroundColor: colors.white,
    height: 48,
  },
  tab: {
    justifyContent: 'flex-end',
  },
  indicator: {
    backgroundColor: colors.blueLight,
    borderBottomWidth: 2,
    borderBottomColor: colors.blueLight,
  },
  indicatorContainer: {
    borderBottomWidth: 1,
    borderColor: colors.grayBorder,
  },
  label: {
    padding: 0,
    margin: 0,
    fontFamily: isWeb ? 'Rubik' : 'Rubik-Regular',
    fontSize: fontSizes.small,
  },

  badgeContainer: {
    height: 18,
    minWidth: 18,
    paddingHorizontal: 3,
    borderRadius: 4,
    backgroundColor: colors.blueLight,
    marginHorizontal: dimensions.halfMedium,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default ({ ...props }) => (
  <TabBar
    getLabelText={({ route }) => route.title}
    style={s.tabBar}
    tabStyle={s.tab}
    indicatorStyle={s.indicator}
    indicatorContainerStyle={s.indicatorContainer}
    labelStyle={s.label}
    inactiveColor={colors.gray}
    activeColor={colors.blueLight}
    {...props}
  />
);

export const Badge = ({ count }) => {
  if (!count) {
    return null;
  }
  return (
    <View style={s.badgeContainer}>
      <Text color={colors.white} xsmall>
        {count}
      </Text>
    </View>
  );
};

export const BadgeOld = ({ isPersonal }) => (
  <View
    style={{
      height: 10,
      marginTop: 8,
      marginRight: isPersonal ? 6 : 20,
      width: 10,
      borderRadius: 5,
      backgroundColor: colors.red,
    }}
  />
);
