import * as RNLocalize from 'react-native-localize';
import * as R from 'ramda';
import countries from '../constants/countries.json';

const getLanguage = () => {
  const languageCode = RNLocalize.getLocales()[0].languageCode;

  switch (languageCode) {
    case 'pt':
      return 'br';
    case 'he':
    case 'de':
      return languageCode;
    default:
      return 'en';
  }
};

const getPhoneNumberCountry = () => {
  const countryCode = RNLocalize.getCountry();
  const country = R.find(R.propEq('iso2', countryCode?.toLowerCase()))(countries);
  const defaultCountry = R.find(R.propEq('iso2', 'il'))(countries);

  return country ?? defaultCountry;
};

export { getLanguage, getPhoneNumberCountry };
