import React, { useEffect, useState, useRef } from 'react';
import { Keyboard, KeyboardAvoidingView, ScrollView, View } from 'react-native';
import { connect } from 'react-redux';
import T from 'prop-types';
import {
  InputWithTitleAndValidation,
  Button,
  Container,
  ScreenHeader,
} from '../../../ReusableComponents';
import { userOperations } from '../../../../store/userInfo';
import { colors, dimensions } from '../../../../styles';
import { headerStyleTransparentWithBackground } from '../../../../styles/headerStyle';
import { getParamOr } from '../../../../utils/navHelper';
import { isRTL } from '../../../../utils/rtlHelper';
import { isIos, isIphoneX, getIsDeviceWithNotch } from '../../../../utils/detectDevice';
import { AnalyticsService, NavigationService } from '../../../../services';
import strings from '../../../../localization';
import { Image, StyleSheet } from 'react-native';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';

const s = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
    marginBottom: isIos ? dimensions.doubleMedium : 0,
  },
  inputStyle: {
    color: colors.blueLight,
  },
  inputContainer: {
    backgroundColor: colors.grayLighter,
    paddingHorizontal: dimensions.medium,
    paddingVertical: isIos ? dimensions.halfMedium : 0,
  },
  infoTextContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: dimensions.medium,
    paddingTop: dimensions.medium,
  },
  image: {
    width: 150,
    height: 115,
    alignSelf: 'center',
    marginTop: dimensions.doubleMedium,
    marginBottom: dimensions.doubleMedium,
  },
  input: {
    textAlign: 'left',
  },
  saveButtonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.halfMedium,
    marginBottom: getIsDeviceWithNotch() ? dimensions.doubleMedium : dimensions.medium,
  },
});

const EditNickname = ({ userInfo, updateNickname, navigation, checkNicknameAvailability }) => {
  const scrollRef = useRef(null);
  const [nickname, setNickname] = useState(userInfo.nickname);
  const [isLoadingNicknameValidation, setLoadingNicknameValidation] = useState(false);
  const [isInvalidSymbolsInNickname, setInvalidSymbolsInNickname] = useState(false);
  const [isNicknameAvailable, setNicknameAvailable] = useState(false);
  const [isValid, setValid] = useState(false);
  const isCurrentNickname = nickname === userInfo.nickname;

  useEffect(() => {
    const keyboardDidShow = Keyboard.addListener('keyboardDidShow', () => {
      if (scrollRef.current) {
        scrollRef.current.scrollToEnd();
      }
    });

    return () => {
      keyboardDidShow.remove();
    };
  }, []);

  useEffect(() => {
    navigation.setParams({
      updateNickname: updateUserNickname,
    });
  }, [nickname]);

  const onChangeNickname = async (value) => {
    setLoadingNicknameValidation(true);

    const nicknameRegExp = new RegExp(/^[a-zA-Z0-9_]+$/);
    const isValidNickname = nicknameRegExp.test(value) || value === '';

    setInvalidSymbolsInNickname(!isValidNickname);

    if (isValidNickname) {
      setNickname(value);
    }

    const isValidLength = value.length >= 5 || value === '';
    if (!isValidLength) {
      setValid(false);
      return;
    }

    // if value is empty string - send null
    const isAvailable = await checkNicknameAvailability(value || null);

    setNicknameAvailable(isAvailable);

    setValid(isValidLength && isAvailable && isValidNickname);

    setLoadingNicknameValidation(false);
  };

  const updateUserNickname = async () => {
    updateNickname(nickname);
    AnalyticsService.logEvent(analyticsEventTypes.earn_points_add_nickname);
    NavigationService.goBack();
  };

  return (
    <Container>
      <KeyboardAvoidingView
        keyboardShouldPersistTabs="handled"
        behavior={isIos ? 'padding' : null}
        style={s.keyboardAvoidingView}
      >
        <ScreenHeader withoutBorder headerTitle={strings.user_info.username} />
        <ScrollView keyboardShouldPersistTabs="handled" ref={scrollRef}>
          <Image style={s.image} source={{ uri: 'add_nickname' }} resizeMode="contain" />

          <InputWithTitleAndValidation
            autoCapitalize="none"
            inputStyle={s.input}
            inputContainerStyle={s.inputContainer}
            infoTextContainerStyle={s.infoTextContainer}
            withValidationAtRight={!isCurrentNickname && !isInvalidSymbolsInNickname && isRTL}
            withValidation={!isCurrentNickname && !isInvalidSymbolsInNickname && !isRTL}
            isAvailable={isNicknameAvailable}
            isLoading={isLoadingNicknameValidation}
            placeholder={strings.user_info.nickname_placeholder}
            maxLength={30}
            minLength={5}
            onChangeText={onChangeNickname}
            value={nickname}
            underlineColorAndroid="transparent"
            helperText={strings.user_info.username_description}
            errorText={
              isInvalidSymbolsInNickname ? strings.user_info.username_validation_error : null
            }
          />
        </ScrollView>

        <View style={s.saveButtonContainer}>
          <Button title={strings.common.save} onPress={updateUserNickname} disabled={!isValid} />
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

EditNickname.propTypes = {
  userInfo: T.object.isRequired,
  checkNicknameAvailability: T.func.isRequired,
  updateNickname: T.func.isRequired,
};

export default connect(
  (state) => ({
    userInfo: state.userInfo,
  }),
  (dispatch) => ({
    checkNicknameAvailability: (nickname) =>
      dispatch(userOperations.checkNicknameAvailability(nickname)),
    updateNickname: (nickname) => dispatch(userOperations.updateNickname(nickname)),
  }),
)(EditNickname);
