import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../styles';

const styles = StyleSheet.create({
  locationContainer: {
    flex: 1,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.grayBorder,
    paddingVertical: dimensions.medium,
    paddingHorizontal: dimensions.medium,
    marginHorizontal: dimensions.medium,
    marginBottom: dimensions.doubleMedium,
  },
  typeTitleContainer: {
    backgroundColor: colors.grayLighter,
    paddingHorizontal: dimensions.medium,
    paddingTop: 20,
    paddingBottom: 10,
  },
  addShippersButton: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: dimensions.medium,
    paddingRight: 30,
    paddingVertical: dimensions.medium,
    borderRadius: 3,

    marginHorizontal: dimensions.medium,
    backgroundColor: colors.grayLighter,
  },
  selectedShippersText: {
    lineHeight: 20,
  },
  internalNoteInput: {
    backgroundColor: colors.grayLighter,
    borderRadius: 3,
    marginHorizontal: dimensions.medium,
    marginVertical: dimensions.medium,
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.medium,
    height: 80,
  },
});

export default styles;
