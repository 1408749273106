import React from 'react';
import { ContentContainer, SearchBarContainer } from './styles';
import { SearchInput } from '../../../ReusableComponents';
import strings from '../../../../localization';
import SectionList from './SectionList';

class Brands extends React.Component {
  state = {
    searchValue: '',
  };

  selectBrand = (brand) => {
    const { navigation, route } = this.props;

    const onSelectBrand = route.params.onSelectBrand;

    onSelectBrand(brand);
    navigation.pop();
  };

  onChangeText = (value) => {
    this.setState({ searchValue: value });
  };

  render() {
    const { searchValue } = this.state;
    const { route } = this.props;

    const selectedBrand = route.params.selectedBrand;
    const brands = route.params.brands;

    return (
      <ContentContainer>
        <SearchBarContainer>
          <SearchInput
            placeholder={strings.other.search_brand}
            value={searchValue}
            onChangeText={this.onChangeText}
          />
        </SearchBarContainer>
        <SectionList
          searchValue={searchValue}
          brands={brands}
          selectBrand={this.selectBrand}
          selectedBrand={selectedBrand}
        />
      </ContentContainer>
    );
  }
}

export default Brands;
