import React, { Fragment, memo } from 'react';
import T from 'prop-types';
import { CheckBox, Text, Separator } from '../../../../ReusableComponents';
import { colors, rs, fontSizes } from '../../../../../styles';
import s, { Box } from './styles';
import strings from '../../../../../localization';

const MultipleSupplyToggle = memo(({ hasMultipleSupply, onMultipleSupplyToggle }) => (
  <Fragment>
    <Box>
      <CheckBox
        checked={hasMultipleSupply}
        onToggle={() => onMultipleSupplyToggle(!hasMultipleSupply)}
        label={strings.items.selling_more}
        styleLabel={{ fontSize: fontSizes.small }}
      />
      <Text style={[s.multipleSupplyDescriptionText, rs.verySmallPaddingTop]} color={colors.grayBorder}>
        {strings.items.selling_more_body}
      </Text>
    </Box>
  </Fragment>
));

MultipleSupplyToggle.propTypes = {
  hasMultipleSupply: T.bool,
  onMultipleSupplyToggle: T.func,
};

export default MultipleSupplyToggle;
