export default url => {
  const queryString = url.split('?')[1];
  const keyValuePairs = queryString.split('&');
  let keyValue = [];
  const queryParams = {};

  keyValuePairs.forEach(pair => {
    keyValue = pair.split('=');
    queryParams[keyValue[0]] = decodeURIComponent(keyValue[1]).replace(/\+/g, ' ');
  });

  return queryParams;
};
