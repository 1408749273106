import { StyleSheet } from 'react-native';
import { colors, dimensions, fontSizes } from '../../../../styles';
import { isIphoneX } from '../../../../utils/detectDevice';

export default StyleSheet.create({
  communityInfoTitleText: {
    alignSelf: 'center',
    textAlign: 'center',
    marginHorizontal: dimensions.doubleMedium,
    marginTop: dimensions.halfMedium,
    marginBottom: 4,
    fontSize: fontSizes.large + 2,
  },
  headerImage: {
    height: 150,
    width: 150,
    marginVertical: dimensions.doubleMedium,
  },
  headerImageContainer: {
    width: '100%',
    alignItems: 'center',
    paddingTop: isIphoneX ? 48 : 12,
  },
  sectionHeaderContainer: {
    backgroundColor: colors.grayLighter,
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: dimensions.medium,
  },
});
