import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import { Container, EmptyListWithImage, LotsList, View } from '../../../ReusableComponents';
import { headerStyleTransparentWithBackground } from '../../../../styles/headerStyle';
import strings from '../../../../localization';
import { groupsSelectors } from '../../../../store/groups';
import { RefreshControl, StyleSheet } from 'react-native';
import { lotsOperations, lotsSelectors } from '../../../../store/lots';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';

const s = StyleSheet.create({
  emptyComponentContainer: {
    paddingTop: 60,
  },
});

const CurrentUserItems = ({
  doubleColumnLists,
  data,
  isLoading,
  isLoadingMore,
  getUserSavedItems,
}) => {
  const [isRefreshing, setRefreshing] = useState(false);

  useEffect(() => {
    getUserSavedItems();
  }, []);

  const onLoadMore = () => {
    getUserSavedItems(true);
  };

  const onRefresh = async () => {
    setRefreshing(true);

    await getUserSavedItems();
    setRefreshing(false);
  };

  const onOpenMarketplace = async () => {
    NavigationService.navigate(screens.MarketplaceTab);
  };

  return (
    <Container>
      <LotsList
        onEndReachedThreshold={0.5}
        // ListEmptyComponent={ListEmptyComponent}
        // forwardedRef={flatListRef}
        // extraData={index}
        doubleColumnLists={doubleColumnLists}
        keyExtractor={(item) => item.id}
        data={isLoading && !isRefreshing ? [] : data}
        isLoading={isLoading && !isRefreshing}
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
        refreshing={isRefreshing}
        isLoadingMore={isLoadingMore}
        onLoadMore={() => onLoadMore()}
        ListEmptyComponent={() => (
          <View>
            <EmptyListWithImage
              image="empty_state_my_saved_items"
              text={strings.emptyLists.empty_saved_item}
              buttonTitle={strings.emptyLists.find_an_item}
              onPress={onOpenMarketplace}
            />
          </View>
        )}
      />
    </Container>
  );
};

CurrentUserItems.propTypes = {
  userId: T.number.isRequired,
  groups: T.array,
};

export default connect(
  (state) => ({
    doubleColumnLists: state.app.doubleColumnLists,
    data: lotsSelectors.getSavedItems(state),
    isLoading: state.lots.savedItems.isLoading,
    isLoadingMore: state.lots.savedItems.isLoadingMore,
    groups: groupsSelectors.getCommunityGroups(state),
    userId: state.userInfo.id,
  }),
  (dispatch) => ({
    getUserSavedItems: (isLoadMore = false) =>
      dispatch(
        lotsOperations.getUserSavedItems({
          isLoadMore,
        }),
      ),
  }),
)(CurrentUserItems);
