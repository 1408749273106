import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import screens from '../screens';
import SuspendedUserScreen from '../../components/Screens/AuthorizationGroup/SuspendedUserScreen';

const Stack = createStackNavigator();

const SuspendedUserStack = () => (
  <Stack.Navigator initialRouteName={screens.SuspendedUser}>
    <Stack.Screen
      name={screens.SuspendedUser}
      component={SuspendedUserScreen}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default SuspendedUserStack;
