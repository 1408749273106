import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';
import CollapseHeader from './CollapseHeader';
import CollapseBody from './CollapseBody';

const Collapse = React.forwardRef(
  (
    {
      isCollapsed = false,
      disabled = false,
      onToggle = () => undefined,
      handleLongPress = () => undefined,
      children,
      ...restProps
    },
    ref,
  ) => {
    const [show, setShow] = useState(isCollapsed);
    useEffect(() => {
      setShow(isCollapsed);
    }, [isCollapsed]);
    let header = null;
    let body = null;
    React.Children.forEach(children, (child) => {
      if (child.type === CollapseHeader) {
        header = child;
      } else if (child.type === CollapseBody) {
        body = child;
      }
    });
    if (header === null) {
      return null;
    } else {
      return (
        <View ref={ref} {...restProps}>
          <TouchableOpacity
            disabled={disabled}
            onPress={() => {
              onToggle(!show);
              setShow(!show);
            }}
            onLongPress={handleLongPress}
          >
            {header}
          </TouchableOpacity>
          {show && (
            <TouchableOpacity
              onPress={() => {
                onToggle(!show);
                setShow(!show);
              }}
              onLongPress={handleLongPress}
            >
              {body}
            </TouchableOpacity>
          )}
        </View>
      );
    }
  },
);

export default Collapse;
