import React from 'react';
import { useSelector } from 'react-redux';
import {
  View,
  AnimatedOpacityHeader,
  Separator,
  Container,
  Text,
  TouchableItem,
} from '../../../ReusableComponents';
import { colors, rs } from '../../../../styles';
import { Animated, Image } from 'react-native';
import s from './styles';
import strings from '../../../../localization';
import { isRTL } from '../../../../utils/rtlHelper';
import { getAppLanguage } from '../../../../store/app/selectors';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import tipsInfo from '../../../../constants/pictureTips';

const PhotoTipsScreen = () => {
  const language = useSelector(getAppLanguage);

  const scrollY = new Animated.Value(0);

  const onOpenFAQ = () => {
    NavigationService.navigate(screens.FAQ);
  };
  return (
    <Container>
      <AnimatedOpacityHeader scrollY={scrollY} title={tipsInfo.title[language]} />

      <Animated.ScrollView
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
          useNativeDriver: true,
        })}
        scrollEventThrottle={8}
      >
        <View style={s.headerImageContainer}>
          <Image source={{ uri: 'rules' }} style={s.headerImage} />
        </View>

        <View style={s.sectionHeaderContainer}>
          <Text semiBold medium>
            {tipsInfo.title[language]}
          </Text>
        </View>

        <View style={[rs.paddingHorizontal, rs.backgroundWhite]}>
          <View style={[rs.bigMarginVertical]}>
            <Text color={colors.gray} style={s.descriptionText}>
              {tipsInfo.heading[language]}
            </Text>

            <View style={rs.marginVertical}>
              {tipsInfo.list.map(({ label, id }, index) => {
                return (
                  <View key={id} style={[rs.row, rs.smallPaddingHorizontal]}>
                    <Text semiBold style={[s.descriptionText, rs.absolute, rs.smallMarginLeft]}>
                      {isRTL && '.'}
                      {index + 1}
                      {!isRTL && '.'}
                    </Text>
                    <Text color={colors.gray} style={[s.descriptionText, rs.bigMarginHorizontal]}>
                      {label[language]}
                    </Text>
                  </View>
                );
              })}
            </View>

            <Text color={colors.gray} style={s.descriptionText}>
              {tipsInfo.footer[language]}
            </Text>
          </View>
        </View>

        <View style={rs.paddingHorizontal}>
          <Separator />
        </View>

        <TouchableItem style={[rs.bigPaddingVertical, rs.paddingHorizontal]} onPress={onOpenFAQ}>
          <Text style={s.descriptionText}>
            {strings.support.for_anything_else_check_FAQ}{' '}
            <Text semiBold color={colors.blueLight}>
              {strings.support.faq}
            </Text>
          </Text>
        </TouchableItem>
      </Animated.ScrollView>
    </Container>
  );
};

export default PhotoTipsScreen;
