import React, { Fragment } from 'react';
import T from 'prop-types';
import { SelectedBrandsContainer, BrandNameText } from './styles';
import strings from '../../../../../localization';
import { renderNamesFromArrThroughCommas } from '../../../../../utils/stringHelper';
import { TextButton } from '../../../../ReusableComponents';

class SelectedBrands extends React.PureComponent {
  render() {
    const { selectedBrands, onResetSelectedBrands } = this.props;

    return (
      <Fragment>
        <SelectedBrandsContainer>
          <BrandNameText>
            {strings.filters.selected}: {renderNamesFromArrThroughCommas(selectedBrands)}
          </BrandNameText>
          <TextButton
            alignCenter
            small
            title={strings.filters.reset}
            onPress={onResetSelectedBrands}
          />
        </SelectedBrandsContainer>
      </Fragment>
    );
  }
}
SelectedBrands.propTypes = {
  selectedBrands: T.array,
  onResetSelectedBrands: T.func,
};
export default SelectedBrands;
