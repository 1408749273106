import * as React from 'react';
import { View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import screens from '../screens';
import { dimensions, headerStyle } from '../../styles';
import {
  BuyingOffers,
  Categories,
  ChatRoom,
  CommunityRules,
  Conditions,
  CurrentUserItems,
  EditItem,
  EditUserInfo,
  EditWish,
  FAQ,
  GiftsInfo,
  Groups,
  Home,
  Inbox,
  ItemDetails,
  Language,
  Marketplace,
  Menu,
  PendingItems,
  PhotoTips,
  RefundPolicies,
  SavedItems,
  SellingOffers,
  Settings,
  ShippingInfo,
  ShortShareittInfo,
  Sizes,
  Support,
  TransactionDetails,
  Transactions,
  UserProfile,
  WishDetails,
  Wishes,
  WishFulfillmentOffers,
  SendNotifications,
  PromoCode,
  UserLocations,
  CitiesFilter,
  EarnTokens,
  Filter,
  MultipleConditions,
  MultipleBrands,
  MultipleSizes, Followers, Endorsements, MapWithUserLocations, SearchScreen,
} from '../../components/Screens';
import HeaderNavBar from '../components/HeaderNavBar';
import GroupFilterStack from './GroupFilterStack';
import { headerStyleTransparentWithBackground } from '../../styles/headerStyle';
import MarketplaceSearchBar from '../../components/ReusableComponents/MarketplaceSearchBar';
import NavigationService from '../../services/NavigationService';
import strings from '../../localization';

const Stack = createStackNavigator();

const AuthorizedApplicationNavigatorWithoutModals = () => (
  <Stack.Navigator
    initialRouteName={screens.Home}
    screenOptions={{ ...headerStyle, headerRight: () => <View /> }}
  >
    <Stack.Screen name={screens.Home} component={Home} options={{ headerShown: false }} />
    <Stack.Screen
      name={screens.MarketplaceTab}
      component={Marketplace}
      options={({ route }) => ({
        ...headerStyleTransparentWithBackground,
        headerTitle: () => (
          <MarketplaceSearchBar
            value={route.params?.searchTerm ?? ''}
            defaultValue={route.params?.searchTerm ?? ''}
            onFocus={() => NavigationService.navigate(screens.SearchScreen)}
            onSubmitEditing={() => NavigationService.navigate(screens.SearchScreen)}
            placeholder={strings.other.marketplace_search_placeholder}
            showCancel={false}
            inputContainerStyle={{ marginLeft: 0, marginRight: 0 }}
            containerStyle={{ width: dimensions.width - dimensions.doubleMedium }}
            updateOnClear
            showSubmitButton
          />
        ),
      })}
    />
    <Stack.Screen name={screens.Wishes} component={Wishes} options={{ headerShown: false }} />
    <Stack.Screen
      name={screens.ItemDetails}
      component={ItemDetails}
    />
    <Stack.Screen
      name={screens.WishDetails}
      component={WishDetails}
    />
    <Stack.Screen
      name={screens.UserProfile}
      component={UserProfile}
      options={{ headerShown: false }}
    />
    <Stack.Screen name={screens.Inbox} component={Inbox} options={{ headerShown: false }} />
    <Stack.Screen name={screens.ChatRoom} component={ChatRoom} options={{ headerShown: false }} />
    <Stack.Screen name={screens.Menu} component={Menu} options={{ headerShown: false }} />
    <Stack.Screen name={screens.Settings} component={Settings} options={{ headerShown: false }} />
    <Stack.Screen
      name={screens.Transactions}
      component={Transactions}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={screens.PendingItems}
      component={PendingItems}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={screens.CurrentUserItems}
      component={CurrentUserItems}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={screens.BuyingOffers}
      component={BuyingOffers}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={screens.SellingOffers}
      component={SellingOffers}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={screens.SavedItems}
      component={SavedItems}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={screens.WishFulfillmentOffers}
      component={WishFulfillmentOffers}
      options={{ headerShown: false }}
    />
    <Stack.Screen name={screens.Support} component={Support} options={{ headerShown: false }} />
    <Stack.Screen name={screens.FAQ} component={FAQ} options={{ headerShown: false }} />
    <Stack.Screen
      name={screens.ShortShareittInfo}
      component={ShortShareittInfo}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={screens.CommunityRules}
      component={CommunityRules}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={screens.ShippingInfo}
      component={ShippingInfo}
      options={{ headerShown: false }}
    />
    <Stack.Screen name={screens.GiftsInfo} component={GiftsInfo} options={{ headerShown: false }} />
    <Stack.Screen name={screens.PhotoTips} component={PhotoTips} options={{ headerShown: false }} />
    <Stack.Screen name={screens.Language} component={Language} options={{ headerShown: false }} />
    <Stack.Screen
      name={screens.SendNotifications}
      component={SendNotifications}
      options={{ headerShown: true }}
    />
    <Stack.Screen name={screens.PromoCode} component={PromoCode} options={{ headerShown: true }} />
    <Stack.Screen
      name={screens.UserLocations}
      component={UserLocations}
      options={{ headerShown: true }}
    />
    <Stack.Screen
      name={screens.EditItemStack}
      component={EditItem}
    />
    <Stack.Screen
      name={screens.EditWishStack}
      component={EditWish}
    />
    <Stack.Screen
      name={screens.Categories}
      component={Categories}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={screens.Conditions}
      component={Conditions}
      options={{ headerShown: false }}
    />
    <Stack.Screen name={screens.Sizes} component={Sizes} options={{ headerShown: false }} />
    <Stack.Screen
      name={screens.EditUserInfo}
      component={EditUserInfo}
      options={{ headerShown: false }}
    />
    <Stack.Screen name={screens.Groups} component={Groups} options={{ headerShown: false }} />
    <Stack.Screen
      name={screens.RefundPolicies}
      component={RefundPolicies}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={screens.TransactionDetails}
      component={TransactionDetails}
      options={{ headerShown: true }}
    />
    <Stack.Screen
      name={screens.CitiesFilterStack}
      component={CitiesFilter}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={screens.GroupFilterStack}
      component={GroupFilterStack}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={screens.EarnTokens}
      component={EarnTokens}
      options={{ headerShown: false }}
    />
    <Stack.Screen name={screens.FilterStack} component={Filter} options={{ headerShown: false }} />
    <Stack.Screen name={screens.MultipleConditions} component={MultipleConditions} options={{ headerShown: false }} />
    <Stack.Screen name={screens.MultipleBrands} component={MultipleBrands} options={{ headerShown: false }} />
    <Stack.Screen name={screens.MultipleSizes} component={MultipleSizes} options={{ headerShown: false }} />
    <Stack.Screen name={screens.Followers} component={Followers} />
    <Stack.Screen name={screens.Endorsements} component={Endorsements} />
    <Stack.Screen name={screens.MapWithUserLocations} component={MapWithUserLocations} />
    <Stack.Screen name={screens.SearchScreen} component={SearchScreen} options={{ headerShown: false }} />
  </Stack.Navigator>
);

const AuthorizedUserStack = () => (
  <Stack.Navigator
    initialRouteName={screens.AuthorizedApplication}
    screenOptions={{ headerShown: true, header: HeaderNavBar }}
    mode="modal"
  >
    <Stack.Screen
      name={screens.AuthorizedApplication}
      component={AuthorizedApplicationNavigatorWithoutModals}
    />
  </Stack.Navigator>
);

export default AuthorizedUserStack;
