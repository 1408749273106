/* eslint-disable react-native/no-inline-styles */
import React, { Fragment, memo } from 'react';
import * as R from 'ramda';
import T from 'prop-types';
import { FontIcon, Text } from '../../../../ReusableComponents';
import { colors, rs, dimensions } from '../../../../../styles';
import strings from '../../../../../localization';
import { renderArrOfPropsThroughCommas } from '../../../../../utils/stringHelper';
import { Image, StyleSheet, View } from 'react-native';

const s = StyleSheet.create({
  shippingText: {
    paddingTop: 2,
  },
  iconContainer: {
    minWidth: 32,
  },
  boxitImage: {
    height: 20,
    width: 20,
  },
  pickupAddressesText: {
    flexDirection: 'row',
  },
  citiesText: {
    lineHeight: 18,
    paddingTop: 2,
    maxWidth: dimensions.width * 0.8,
  },
});

const ShippingInfo = memo(({ locations, isActiveShipping, isShippersDeliveryActive }) => (
  <Fragment>
    {(isActiveShipping || !R.isEmpty(locations) || !!isShippersDeliveryActive) && (
      <View style={[rs.paddingHorizontal, rs.smallPaddingBottom, rs.backgroundWhite]}>
        {!R.isEmpty(locations) && (
          <View style={[rs.row, rs.smallMarginVertical]}>
            <View style={s.iconContainer}>
              <FontIcon
                withoutRTLScale
                name="Shipping_personal-pickup"
                size={22}
                color={colors.text}
              />
            </View>
            <View>
              <Text>{strings.shipping.meet_in_person}</Text>
              <Text
                xsmall
                color={colors.gray}
                style={s.citiesText}
              >{`${renderArrOfPropsThroughCommas(locations, 'city')}`}</Text>
            </View>
          </View>
        )}

        {isActiveShipping && (
          <View style={[rs.row, rs.smallMarginVertical]}>
            <View style={s.iconContainer}>
              <FontIcon
                withoutRTLScale
                name="Shipping_domestic-shipping"
                size={22}
                color={colors.text}
              />
            </View>
            <View>
              <Text style={s.shippingText}>{strings.shipping.domestic_shipping}</Text>
            </View>
          </View>
        )}

        {!!isShippersDeliveryActive && (
          <View style={[rs.row, rs.smallMarginVertical]}>
            <View style={s.iconContainer}>
              <FontIcon withoutRTLScale name="scooter" size={22} color={colors.text} />
            </View>
            <View>
              <Text style={s.shippingText}>{strings.shipping.shippers_delivery}</Text>
            </View>
          </View>
        )}
      </View>
    )}
  </Fragment>
));

ShippingInfo.propTypes = {
  locations: T.array,
  isActiveShipping: T.bool,
  isShippersDeliveryActive: T.bool,
};

export default ShippingInfo;
