import { createAction } from 'redux-actions';
import * as types from './types';

export const rewardsStart = createAction(types.REWARDS_START);
export const rewardsSuccess = createAction(types.REWARDS_SUCCESS);
export const rewardsError = createAction(types.REWARDS_ERROR);

export const updateReward = createAction(types.UPDATE_REWARD);

export const rewardsProgressStart = createAction(types.REWARDS_PROGRESS_START);
export const rewardsProgressSuccess = createAction(types.REWARDS_PROGRESS_SUCCESS);
export const rewardsProgressError = createAction(types.REWARDS_PROGRESS_ERROR);

export const userInfoRewardsStart = createAction(types.USER_INFO_REWARDS_START);
export const userInfoRewardsSuccess = createAction(types.USER_INFO_REWARDS_SUCCESS);
export const userInfoRewardsError = createAction(types.USER_INFO_REWARDS_ERROR);
