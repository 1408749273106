import tokensExchangeApi from '../../api/tokensExchange';
import { ToastsService } from '../../services';
import { walletsOperations } from '../wallets';
import { offersOperations } from '../offers';
import { BUYER_ASKED_TO_BUY } from '../offers/offerActions';

const getTokensForExchange = (missingAmount, communityId) => async (dispatch, getState) => {
  try {
    return await tokensExchangeApi.getTokensForExchange(communityId, missingAmount);
  } catch (err) {
    ToastsService.showError('Something went wrong when trying to get tokens for exchange');
  }
};

const getTokensWhereExchangeAvailable = () => async () => {
  try {
    return await tokensExchangeApi.getTokensWhereExchangeAvailable();
  } catch (err) {}
};

const getTokensForExchangeFromTokenId = tokenId => async () => {
  try {
    return await tokensExchangeApi.getTokensForExchangeFromTokenId(tokenId);
  } catch (err) {}
};

const performExchange = exchangeParams => async (dispatch, getState) => {
  const { token_id, to_token_id, exchange_amount } = exchangeParams;

  try {
    await tokensExchangeApi.performExchange({
      token_id,
      to_token_id,
      exchange_amount,
    });
    dispatch(walletsOperations.getWallets());
  } catch (err) {
    ToastsService.showError('Something went wrong when trying perform exchange');
  }
};

const triggerAskToBuy = offer => async dispatch => {
  const { id, item_id } = offer;
  try {
    if (id) {
      await dispatch(offersOperations.performAction(id, BUYER_ASKED_TO_BUY));
    } else {
      await dispatch(offersOperations.createOffer(item_id));
    }
    await dispatch(walletsOperations.getWallets());
  } catch (err) {
    ToastsService.showError('Something went wrong when trying perform exchange');
  }
};

export default {
  getTokensForExchange,
  getTokensWhereExchangeAvailable,
  getTokensForExchangeFromTokenId,
  performExchange,
  triggerAskToBuy,
};
