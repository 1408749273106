import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../../styles';
import { Text, TouchableItem } from '../../../../ReusableComponents/index';

/** Brand Item Components * */

export const BrandView = styled(TouchableItem)`
  flex-direction: row;
  align-items: center;
  height: 46px;
  justify-content: space-between;
  padding-right: ${dimensions.medium};
  background-color: ${colors.white};
`;

export const SelectedBrandsContainer = styled.View`
  min-height: 46px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-vertical: ${dimensions.halfMedium};
  padding-right: ${dimensions.medium};
  background-color: ${colors.white};
  border-bottom-width: ${StyleSheet.hairlineWidth};
  border-bottom-color: ${colors.gray};
`;
export const MainContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const BrandNameText = styled(Text)`
  color: ${({ isActive }) => (isActive ? colors.blueLighter : colors.gray)};
  margin-horizontal: ${dimensions.medium};
  max-width: 82%;
`;

/** List Components * */
export const KeyName = styled(Text)`
  color: ${colors.gray};
  padding-horizontal: ${dimensions.medium};
  padding-vertical: ${dimensions.halfMedium};
  width: 100%;
`;

export const Separator = styled.View`
  width: 100%;
  height: ${StyleSheet.hairlineWidth};
  margin-left: ${50 + dimensions.medium * 2};
  background-color: ${colors.gray};
`;

/** Container * */
export const ContentContainer = styled.View`
  flex: 1;
  padding-top: 4px;
  background-color: ${colors.white};
`;

export const SearchBarContainer = styled.View`
  padding-bottom: ${dimensions.halfMedium};
`;

export const NoBrandSeparatorBlock = styled.View`
  height: ${dimensions.halfMedium};
  background-color: ${colors.grayLighter};
`;
