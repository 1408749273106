import React, { Fragment, memo } from 'react';
import { StyleSheet } from 'react-native';
import { View, Text, TouchableItem, FontIcon } from '../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../styles';
import T from 'prop-types';

const s = StyleSheet.create({
  cardButton: {
    width: '100%',
    minHeight: 110,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    marginTop: -20,

    paddingHorizontal: dimensions.medium,
    paddingTop: 20 + 20,
    paddingBottom: dimensions.doubleMedium,
    flexDirection: 'row',
    ...rs.shadow,
  },
  cardContentContainer: {
    paddingHorizontal: dimensions.medium,
  },
});

const CardItem = memo(({ zIndex, color, iconName, children, title, onPress }) => {
  return (
    <TouchableItem
      useOpacity={false}
      onPress={onPress}
      style={[s.cardButton, { zIndex: zIndex, backgroundColor: color }]}
    >
      <Fragment>
        <FontIcon name={iconName} size={28} color={colors.white} withoutRTLScale />
        <View style={s.cardContentContainer}>
          <Text medium semiBold color={colors.white} style={rs.verySmallMarginBottom}>
            {title}
          </Text>
          {children}
        </View>
      </Fragment>
    </TouchableItem>
  );
});

CardItem.propTypes = {
  zIndex: T.number.isRequired,
  color: T.string.isRequired,
  iconName: T.string.isRequired,
  title: T.string.isRequired,
  children: T.any,
  onPress: T.func.isRequired,
};

export default CardItem;
