import React, { Fragment } from 'react';
import T from 'prop-types';
import { StyleSheet, View, Image } from 'react-native';
import strings from '../../../../../localization';
import { ModalsService } from '../../../../../services';
import modalTypes from '../../../../../constants/modalTypes';
import { numberWithCommas } from '../../../../../utils/stringHelper';
import {
  Text,
  TouchableItem,
  AngleIcon,
  Separator,
  FontIcon,
} from '../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../styles';

const s = StyleSheet.create({
  wrapper: {
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.halfMedium,
    marginVertical: dimensions.medium,
    backgroundColor: colors.white,
  },
  buttonHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  separator: {
    marginTop: 15,
  },
  titleWrapper: {
    flexDirection: 'column',
    marginLeft: dimensions.large,
  },
});

const FoundationItem = ({ item, onPress }) => {
  const onWebsitePress = () => {
    ModalsService.showFullScreenModal(modalTypes.WEB_VIEW, {
      url: item.website,
      headerTitle: strings.donate_points.website,
    });
  };

  return (
    <View style={s.wrapper}>
      <TouchableItem onPress={() => onPress(item)}>
        <View style={s.buttonHeader}>
          <Image style={s.image} resizeMode="cover" source={{ uri: item.image }} />

          <View style={s.titleWrapper}>
            <Text medium semiBold numberOfLines={1} ellipsizeMode="tail">
              {item.name}
            </Text>

            {!!item.website && (
              <TouchableItem
                onPress={onWebsitePress}
                style={[rs.row, rs.alignCenter, rs.smallMarginTop]}
              >
                <Fragment>
                  <Text color={colors.blueLight} xsmall>
                    {strings.donate_points.website}
                  </Text>

                  <FontIcon
                    name="Settings_language"
                    size={18}
                    color={colors.darkCyan}
                    style={rs.smallMarginLeft}
                  />
                </Fragment>
              </TouchableItem>
            )}
          </View>

          <AngleIcon color={colors.gray} />
        </View>

        {!!item.description && (
          <Text small color={colors.gray} style={rs.marginTop}>
            {item.description}
          </Text>
        )}

        {!!item.totalDonationAmount && (
          <Text semiBold color={colors.blueLight} style={rs.marginTop}>
            {strings.formatString(
              strings.donate_points.total_donated,
              numberWithCommas(item.totalDonationAmount),
            )}
          </Text>
        )}

        <Separator style={s.separator} />
      </TouchableItem>
    </View>
  );
};

FoundationItem.propTypes = {
  items: T.shape({
    id: T.oneOfType([T.number, T.string]).isRequired,
    name: T.string.isRequired,
    description: T.string,
    image: T.string.isRequired,
    totalDonationAmount: T.number,
  }),
  onPress: T.func.isRequired,
};

export default FoundationItem;
