import React, { Fragment } from 'react';
import { SectionList, View } from 'react-native';
import { connect } from 'react-redux';
import T from 'prop-types';
import { KeyName } from './styles';
import BrandItem from './BrandItem';
import SelectedBrands from './SelectedBrands';
import { EmptyList } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import { getBrandsSectionList } from '../../../../../store/group/selectors';
import { colors, rs } from '../../../../../styles';

const List = ({ selectedBrands, filteredBrands, selectBrand, onResetSelectedBrands }) => (
  <Fragment>
    <SelectedBrands selectedBrands={selectedBrands} onResetSelectedBrands={onResetSelectedBrands} />
    <SectionList
      sections={filteredBrands}
      keyExtractor={(item) => item.id}
      initialNumToRender={20}
      style={rs.backgroundGray}
      keyboardShouldPersistTaps="handled"
      stickySectionHeadersEnabled={false}
      renderSectionHeader={({ section: { title } }) => (
        <View style={[rs.backgroundGray, rs.smallPaddingTop]}>
          <KeyName semiBold medium>
            {title}
          </KeyName>
        </View>
      )}
      ListEmptyComponent={<EmptyList text={strings.emptyLists.emptyContactsList} />}
      renderItem={({ item }) => (
        <BrandItem
          brand={item}
          onSelect={() => selectBrand(item)}
          selectedBrands={selectedBrands}
        />
      )}
    />
  </Fragment>
);

List.propTypes = {
  selectBrand: T.func,
  onResetSelectedBrands: T.func,
  filteredBrands: T.array,
  selectedBrands: T.array,
};

export default connect((state, { searchValue }) => ({
  filteredBrands: getBrandsSectionList(state, searchValue),
}))(List);
