import { AppRegistry } from 'react-native';
import * as Sentry from '@sentry/react-native';
import { name as appName } from './app.json';
import App from './src/navigation';

import FeatherIcons from 'react-native-vector-icons/Fonts/Feather.ttf';
import MaterialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import FontAwesomeIcons from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import Ionicons from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import { ENV } from './config/env';

Sentry.init({
  dsn: 'https://614626c6f6d74733ae148f26e4790ef7@sentry.io/1509096',
  environment: ENV,
  attachStacktrace: true,
  debug: true,
  enableNative: false,
});

if (module.hot) {
  module.hot.accept();
}

const iconFontStyles = `@font-face {
  src: url(${FeatherIcons});
  font-family: Feather;
}
@font-face {
  src: url(${MaterialCommunityIcons});
  font-family: MaterialCommunityIcons;
}
@font-face {
  src: url(${FontAwesomeIcons});
  font-family: FontAwesome;
}
@font-face {
  src: url(${Ionicons});
  font-family: Ionicons;
}
`;

const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

document.head.appendChild(style);

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('app-root'),
});
