import { StyleSheet } from 'react-native';
import { dimensions } from '../../../../styles';

export default StyleSheet.create({
  contentContainer: {
    flex: 1,
    paddingHorizontal: dimensions.doubleMedium,
    paddingVertical: dimensions.doubleMedium,
  },
  itemContainer: {
    flexDirection: 'row',
    marginVertical: dimensions.halfMedium,
    paddingHorizontal: dimensions.medium,
  },
  numberContainer: {
    width: 24,
  },
});
