import React, { Fragment, useState, useEffect } from 'react';
import s from './styles';
import { TouchableItem, Container, Text } from '../../../../ReusableComponents';
import { colors, rs } from '../../../../../styles';

const ConditionItem = ({ condition, isSelected, onSelectCondition }) => (
  <TouchableItem
    style={[s.itemContainer, isSelected && s.activeItemContainer]}
    useOpacity={false}
    onPress={() => {
      onSelectCondition(condition.id);
    }}
  >
    <Fragment>
      <Text style={rs.verySmallMarginBottom} medium semiBold>
        {condition.title}
      </Text>
      <Text color={colors.gray}>{condition.subtitle}</Text>
    </Fragment>
  </TouchableItem>
);

const MultipleConditions = ({ navigation, route }) => {
  const conditions = route.params.conditions;
  const selectedConditionIds = route.params.selectedConditionIds;
  const onSelectConditions = route.params.onSelectCondition;

  const [selectedConditions, setSelectedConditions] = useState(selectedConditionIds);

  useEffect(() => {
    navigation.setParams({ onSubmit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConditions]);

  const onSubmit = () => {
    onSelectConditions(selectedConditions);
    navigation.goBack();
  };

  const toggleCondition = (conditionId) => {
    const updatedSelectedConditions = selectedConditions.includes(conditionId)
      ? selectedConditions.filter((id) => id !== conditionId)
      : [...selectedConditions, conditionId];

    setSelectedConditions(updatedSelectedConditions);
  };

  return (
    <Container>
      {conditions.map((condition) => {
        const isSelected = selectedConditions.includes(condition.id);

        return (
          <ConditionItem
            key={condition.id}
            condition={condition}
            isSelected={isSelected}
            onSelectCondition={toggleCondition}
          />
        );
      })}
    </Container>
  );
};

export default MultipleConditions;
