import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import * as R from 'ramda';
import { chatsOperations, chatsSelectors } from '../../../../store/chats';
import { Container, EmptyListWithImage } from '../../../ReusableComponents';
import ChatsList from './components/ChatsList';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import screens from '../../../../navigation/screens';

const ArchivedChats = ({
  archivedChats,

  isLoading,
  isLoadingMore,

  getArchivedChats,
  unarchiveChats,
  navigation,
  route,
}) => {
  const [isRefreshing, setRefreshing] = useState(false);
  const isEditMode = route.params?.isEditMode ?? false;
  const [selectedChatIds, setSelectedChatIds] = useState([]);

  useEffect(() => {
    navigation.setParams({
      onDonePress: () => {
        setSelectedChatIds([]);
        navigation.setParams({ isEditMode: false, selectedChatsLength: 0 });
      },
      onUnarchivePress: () => {
        setSelectedChatIds([]);
        navigation.setParams({ isEditMode: false, selectedChatsLength: 0 });
        unarchiveChats(selectedChatIds);
      },
    });
  }, [selectedChatIds]);

  const onSelectChat = (chatId) => {
    if (!isEditMode) {
      NavigationService.navigate(screens.ChatRoom, { chatId });
      return;
    }
    let newSelectedChatIds = null;

    if (R.includes(chatId, selectedChatIds)) {
      newSelectedChatIds = selectedChatIds.filter((element) => element !== chatId);
    } else {
      newSelectedChatIds = [...selectedChatIds, chatId];
    }

    setSelectedChatIds(newSelectedChatIds);
    navigation.setParams({ selectedChatsLength: newSelectedChatIds.length });
  };

  useEffect(() => {
    getArchivedChats();
  }, []);

  const onOpenUserProfile = (userId, name, avatar) => {
    NavigationService.navigate(screens.UserProfile, {
      name,
      userId,
      profile_image: avatar,
    });
  };

  const onRefresh = async () => {
    setRefreshing(true);
    await getArchivedChats();
    setRefreshing(false);
  };

  return (
    <Container>
      <ChatsList
        isEditMode={isEditMode}
        selectedChatIds={selectedChatIds}
        isHideArchiveButton
        isLoading={isLoading}
        isLoadingMore={isLoadingMore}
        chatList={archivedChats}
        onOpenChat={onSelectChat}
        onOpenUserProfile={onOpenUserProfile}
        onLoadMore={() => getArchivedChats(true)}
        isRefreshing={isRefreshing}
        onRefresh={onRefresh}
        ListEmptyComponent={() => (
          <EmptyListWithImage image="empty_state_chats" text={strings.emptyLists.emptyChatList} />
        )}
      />
    </Container>
  );
};

ArchivedChats.propTypes = {
  archivedChats: T.array,
  isLoading: T.bool,
  isLoadingMore: T.bool,
  getArchivedChats: T.func,
};

export default connect(
  (state) => ({
    isLoading: state.chats.archivedChats.isLoading,
    isLoadingMore: state.chats.archivedChats.isLoadingMore,
    archivedChats: chatsSelectors.getArchivedChats(state),
  }),
  (dispatch) => ({
    getArchivedChats: (isLoadMore) =>
      dispatch(chatsOperations.getArchivedChats([], [], isLoadMore)),
    unarchiveChats: (chatIds) => dispatch(chatsOperations.unarchiveChats(chatIds)),
  }),
)(ArchivedChats);
