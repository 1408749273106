import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';
import { colors } from '../../styles';

const getHeight = ({ small, medium, big }) => {
  let height = StyleSheet.hairlineWidth;

  if (small) height = '1px';
  if (medium) height = '6px';
  if (big) height = '9px';

  return `height: ${height}`;
};

const Separator = styled.View`
  ${props => getHeight(props)};
  width: 100%;
  background-color: ${({ color }) => color || colors.grayLight};
`;
export default Separator;
