import React, { Fragment } from 'react';
import styled from 'styled-components/native';
import Text from '../Text';
import Spinner from '../Spinner';
import TouchableItem from '../TouchableItem';
import { colors, dimensions } from '../../../styles';

const ButtonContainer = styled(TouchableItem)`
  height: 28px;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  padding-horizontal: ${dimensions.doubleMedium};
  background-color: ${({ disabled }) => (disabled ? colors.grayLighter : colors.blueLight)};
  border-radius: 16px;
  margin-right: ${({ withMarginRight }) => (withMarginRight ? dimensions.medium : 0)};
  ${({ fullWidth }) => (fullWidth ? 'flex: 1' : '')}
`;

const ButtonText = styled(Text)`
  color: ${({ disabled }) => (disabled ? colors.gray : colors.white)};
`;

const SecondaryButtonContainer = styled(ButtonContainer)`
  background-color: ${colors.transparent};
  border-width: 1px;
  border-color: ${({ disabled }) => (disabled ? colors.grayLighter : colors.blueLight)};
`;

const SecondaryButtonText = styled(Text)`
  color: ${({ disabled }) => (disabled ? colors.gray : colors.blueLight)};
`;

const Button = ({
  isLoading,
  disabled,
  title,
  onPress,
  withMarginRight,
  secondaryType = false,
  fullWidth = false,
}) => (
  <Fragment>
    {secondaryType ? (
      <SecondaryButtonContainer
        useOpacity={false}
        disabled={disabled}
        withMarginRight={withMarginRight}
        onPress={onPress}
        fullWidth={fullWidth}
      >
        {isLoading ? (
          <Spinner color={colors.white} />
        ) : (
          <SecondaryButtonText disabled={disabled}>{title}</SecondaryButtonText>
        )}
      </SecondaryButtonContainer>
    ) : (
      <ButtonContainer
        useOpacity={false}
        fullWidth={fullWidth}
        disabled={disabled}
        withMarginRight={withMarginRight}
        onPress={onPress}
      >
        {isLoading ? (
          <Spinner color={colors.white} />
        ) : (
          <ButtonText disabled={disabled}>{title}</ButtonText>
        )}
      </ButtonContainer>
    )}
  </Fragment>
);

export default Button;
