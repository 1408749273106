export default {
  title: {
    en: 'Coin-earning options',
    he: 'כל הדרכים לקבל נקודות Shareitt',
    br: 'Opções para ganhar moedas',
    de: 'Wie man Punkte verdienen kann',
  },
  body: {
    en:
      'Shareitt commerce uses its own unique currency. Items are bought and sold with Shareitt points, rather than shekels. The entire Marketplace is based on Shareitt coins which are at par with the shekel. For example, if in the \u0027real world\u0027 an item costs 10 shekels, then its value on the Shareitt app will be 10 Shareitt points, provided that it is uploaded for sale in new condition.\n\nThere are a number of ways to earn or receive Shareitt coins. The best way is to upload and sell as many items as possible to as many users as possible. Just like in the \u0027real world\u0027.\n\nThe more you interact on the app, the more gifts you\u0027ll receive. Just click the Gifts icon on the upper part of the Home Page for more information.\n\nSo what do I need to do?\nEvery action is registered on the system: each item you upload, each item you sell or buy, each wish you grant, and all the friends and family members sign up. At Shareitt, we\u0027ve developed a cumulative tool that tallies your actions and will grant you Shareitt coins and a special gift each time you reach a certain target. \n',
    he:
      'פעילות המסחר באפליקציית Shareitt אינה מבוססת על כסף אמיתי. הפריטים אינם נמכרים בשקלים, אלא במה שאנחנו מכנים: נקודות Shareitt. המרקטפלייס כולו מבוסס על הנקודות הייחודיות, השווים בערכם לערך השקל. כלומר אם בעולם האמיתי פריט מסוים עלה בחנות 10 שקלים, באפליקציית Shareitt (בהנחה והפריט עולה במצב חדש), השווי שלו יהיה עד 10 נקודות Shareitt. \nקיימות מספר דרכים לקבל או להרוויח נקודות Shareitt. הדרך הטובה ביותר היא להעלות ולמסור כמה שיותר פריטים לכמה שיותר משתמשים. בדיוק כמו שמרוויחים כסף בעולם האמיתי. \nבנוסף, ככל שתבצעו יותר פעולות במערכת, תקבלו מאיתנו אינספור מתנות המבוססות על מנגנון צבירה ייחודי אותו ניתן למצוא בלחיצה על אייקון המתנות הנמצא בחלקו העליון של מסך עמוד הבית. \nאז מהן אותן פעולות? \nכל פריט שתעלו נרשם במערכת, גם כל פריט שתמסרו או תקבלו. אפילו את המשאלות שתגשימו למשתמשים אחרים. במיוחד עבורכם פיתחנו מנגנון אוטומטי שמעניק לכם מתנה מפנקת בכל פעם שתגיעו ליעד מסוים. מעבר לזה, ככל שתצרפו לאפליקצייה יותר חברים ובני משפחה באמצעות קוד ייחודי הנמצא באפליקציה, כך תוכלו לזכות בנקודות נוספות. ',
    br:
      'Shareitt usa sua própria moeda. Os itens são comprados e vendidos com moedas Shareitt, ao invés de reais. Todo o mercado é baseado em moedas Shareittl. Por exemplo, se no \u0027mundo real\u0027 um item custa 10 reais, seu valor no aplicativo Shareitt será de 10 moedas Shareitt, desde que seja enviado para venda em estado novo.\n\nExistem várias maneiras de ganhar ou receber moedas Shareitt. A melhor maneira é fazer upload e vender o maior número possível de itens para o maior número de usuários. Assim como no \u0027mundo real\u0027.\n\nQuanto mais você interagir no aplicativo, mais presentes você receberá. Basta clicar no ícone Presentes na parte superior da Página inicial para obter mais informações.\n\nEntão o que eu preciso fazer?\nToda ação é registrada no sistema: cada item que você carrega, cada item que você vende ou troca, cada desejo que você concede e todos os amigos e familiares que se inscrevem. No Shareitt, desenvolvemos uma ferramenta cumulativa que registra suas ações e concede moedas Shareitt e um presente especial cada vez que você atingir um determinado alvo. \n',
    de: `Die Shareitt-Plattform verwendet eine eigene, einzigartige Komplementärwährung. Gegenstände werden mit Shareitt-Punkten angeboten und erworben. Der gesamte Marktplatz funktioniert mit Shareitt-Punkten. Wenn zum Beispiel ein Artikel 10 Franken/Euro kostet, beträgt sein Wert in der Shareitt-App 10 Shareitt-Punkte. Der beste Weg Punkte zu sammeln ist, so viele Artikel wie möglich hochzuladen und so vielen Beteiligten wie möglich anzubieten. Es ist wie in der realen Welt: Je aktiver du in der App bist, umso mehr Punkte wirst du generieren. Klicke auf das Geschenke-Symbol im oberen Teil der Startseite um weitere Informationen zu erhalten. \n \n

    Was muss ich tun? \n
    Viele Aktivität werden im System registriert und belohnt: jeder Artikel, den du hochlädst, anbietest oder beziehst; jeder Wunsch, den du erfüllst, und alle Freunde und Familienmitglieder, die deinen persönlichen Link benutzen um sich anzumelden. Bei Shareitt haben wir ein gemeinschaftliches Tool entwickelt, das viele deiner Aktivitäten mit Shareitt-Punkten belohnt und kleine Geschenk gewährt, wenn du ein bestimmtes Ziel erreichst.
    \n`,
  },
};
