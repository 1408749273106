import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import strings from '../../../../localization';
import { ModalsService } from '../../../../services';
import modalTypes from '../../../../constants/modalTypes';
import { TouchableItem, Text, SearchInput } from '../../';
import ContactBookSectionList from '../../Lists/ContactBookSectionList';
import { colors, dimensions, rs } from '../../../../styles';

const s = StyleSheet.create({
  rootContainer: {
    flex: 1,
    paddingTop: 50,
  },
  swipeView: {
    width: 60,
    height: 8,
    alignSelf: 'center',
    marginBottom: dimensions.medium,
    borderRadius: 4,
    backgroundColor: colors.grayLightest,
    opacity: 0.8,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  headerContainer: {
    width: '100%',
    paddingTop: 16,
    paddingBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    left: dimensions.medium,
  },
});

const ContactList = ({ onClose, params }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const onPress = (item) => {
    if (item.phoneNumbers.length === 1) {
      params.onSubmit(item);
      onClose();
    } else {
      ModalsService.showSwipeableModal(modalTypes.CONTACT_DETAILS, { contact: item, onSubmit: params.onSubmit });
    }

  };

  return (
    <View style={s.rootContainer}>
      <View style={s.swipeView} />

      <View style={s.contentContainer}>
        <View style={s.headerContainer}>
          <TouchableItem style={s.closeButton} onPress={onClose}>
            <Text color={colors.blueLight}>{strings.common.cancel}</Text>
          </TouchableItem>

          <Text style={rs.alignCenter} color={colors.secondary} medium bold>
            {strings.modals.share_contact.contacts}
          </Text>
        </View>

        <View style={rs.paddingBottom}>
          <SearchInput
            value={searchTerm}
            onChangeText={setSearchTerm}
            placeholder={strings.modals.share_contact.input_placeholder}
            autoCorrect={false}
          />
        </View>

        <ContactBookSectionList searchTerm={searchTerm} onPress={onPress} />
      </View>
    </View>
  );
};

export default ContactList;
