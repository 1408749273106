import React from 'react';
import { connect } from 'react-redux';
import { FlatList } from 'react-native';
import T from 'prop-types';
import { Container } from '../../../ReusableComponents';
import GroupItem from './components/GroupItem';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import { groupsSelectors } from '../../../../store/groups';
import screens from '../../../../navigation/screens';
import { lotsOperations } from '../../../../store/lots';

const GroupsList = ({ groups, selectAllGroups, totalItemsAmount }) => {
  const onGroupPress = (groupId) => {
    NavigationService.navigate(screens.MultipleCategories, {
      groupId,
    });
  };

  const onSelectAllGroups = () => {
    selectAllGroups();
    NavigationService.pop();
  };

  return (
    <Container>
      <FlatList
        data={groups}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <GroupItem
            onPress={onGroupPress}
            id={item.id}
            name={item.group_name}
            activeItemsCount={item.active_items_count}
            backgroundImage={item.background_image}
          />
        )}
        ListHeaderComponent={
          <GroupItem
            onPress={onSelectAllGroups}
            id="group"
            name={strings.profile.view_all}
            activeItemsCount={totalItemsAmount}
            backgroundImage="all_groups"
            isAllGroups
          />
        }
      />
    </Container>
  );
};

GroupsList.propTypes = {
  getCommunitiesList: T.func,
};

export default connect(
  (state) => ({
    groups: groupsSelectors.getCommunityGroups(state),
    totalItemsAmount: groupsSelectors.getTotalItemsAmountInAllCommunityGroups(state),
  }),
  (dispatch) => ({
    selectAllGroups: () =>
      dispatch(lotsOperations.setFeedLotFilters({ groupIds: [], selectedCategoryIds: [] })),
  }),
)(GroupsList);
