/* eslint-disable */
import React, { Fragment } from 'react';
import { View, Modal, TouchableOpacity, SafeAreaView, FlatList } from 'react-native';
import styles from './styles';
import { dimensions, rs, colors } from '../../../../../styles';
// import { Text, BackButton, Separator } from '../../../../ReusableComponents';
import Text from '../../../../ReusableComponents/Text';
import countries from '../../../../../constants/countries.json';
import primaryCountries from './primaryCountries.json';
import strings from '../../../../../localization';
import { ENV } from '../../../../../../config/env';
import ScreenHeader from "../../../../ReusableComponents/ScreenHeader";

const defaultProps = {
  data: countries,
  primaryCountries: primaryCountries,
};

class ListItem extends React.PureComponent {
  render() {
    const { item, onChange } = this.props;
    return (
      <TouchableOpacity onPress={() => onChange(item)}>
        <View style={styles.optionStyle}>
          <Text medium style={{ maxWidth: '70%' }} color={colors.gray}>
            {item.name}
          </Text>
          <Text semiBold medium color={colors.gray}>
            +{item.dialCode}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
}

export default class ModalPicker extends React.PureComponent {
  state = {
    data: countries,
    primaryCountries: primaryCountries,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  }

  onChange = (item) => {
    const { onChange, onHideModal } = this.props;
    onChange(item);
    onHideModal();
  };

  render() {
    const { modalVisible, onHideModal } = this.props;
    const { data, primaryCountries } = this.state;
    return (
      <Modal transparent visible={modalVisible} onRequestClose={onHideModal} animationType="slide">
        <ScreenHeader headerTitle={strings.login.select_country} onClose={onHideModal} />

          <View style={styles.optionContainer}>
            <FlatList
              keyboardDismissMode="on-drag"
              keyboardShouldPersistTaps="handled"
              contentContainerStyle={{
                paddingBottom: dimensions.doubleMedium,
              }}
              ListHeaderComponent={
                <Fragment>
                  <View>
                    <View style={styles.sectionHeaderContainer}>
                      <Text medium bold>
                        {strings.login.most_popular}
                      </Text>
                    </View>
                    <ListItem item={primaryCountries[0]} onChange={this.onChange} />
                    <ListItem item={primaryCountries[1]} onChange={this.onChange} />
                    {ENV !== 'production' && (
                      <ListItem item={primaryCountries[2]} onChange={this.onChange} />
                    )}
                  </View>
                  <View style={styles.sectionHeaderContainer}>
                    <Text medium bold>
                      {strings.login.all_countries}
                    </Text>
                  </View>
                </Fragment>
              }
              initialNumToRender={30}
              data={data}
              keyExtractor={(item) => item.iso2}
              renderItem={({ item }) => <ListItem item={item} onChange={this.onChange} />}
            />
          </View>
      </Modal>
    );
  }
}

ModalPicker.defaultProps = defaultProps;
