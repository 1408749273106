import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import debounce from 'debounce';
import { connect } from 'react-redux';
import {
  CommunityMembersList,
  Container,
  ScreenHeader,
  SearchInput,
  View,
} from '../../../../ReusableComponents';
import { rs } from '../../../../../styles';
import strings from '../../../../../localization';
import { communityMembersOperations } from '../../../../../store/communityMembers';
import SelectedUsersList from './components/SelectedUsersList';

/* eslint-disable react-hooks/exhaustive-deps */
const IssueMemberList = ({ route, getCommunityMembers }) => {
  const selectedMembers = route.params.selectedMembers || [];
  const [searchValue, setSearchValue] = useState('');
  const [selectedUsers, setSelectedUsers] = useState(selectedMembers);

  useEffect(() => {
    getCommunityMembers();

    return () => {
      getCommunityMembers();
    };
  }, []);

  const getMembersDebounce = debounce((value) => {
    getCommunityMembers(value);
  }, 400);

  const onPressSubmit = () => {
    const onSubmit = route.params.onSubmit;

    onSubmit(selectedUsers);
  };

  const onSelectUser = (user) => {
    const updatedUsers = selectedUsers.find(({ id }) => id === user.id)
      ? selectedUsers.filter(({ id }) => id !== user.id)
      : [...selectedUsers, user];

    setSelectedUsers(updatedUsers);
  };

  const onDeselectUser = (user) => {
    const updatedUsers = selectedUsers.filter(({ id }) => id !== user.id);
    setSelectedUsers(updatedUsers);
  };

  const onChangeText = (value) => {
    setSearchValue(value);
    getMembersDebounce(value);
  };

  return (
    <Container>
      <ScreenHeader
        onSubmit={onPressSubmit}
        headerTitle={strings.bank.group_members}
        submitButtonTitle={strings.common.done}
        withoutBorder
      />

      <View style={rs.smallPaddingVertical}>
        <SearchInput
          placeholder={strings.members.search_members}
          value={searchValue}
          onChangeText={onChangeText}
        />
      </View>

      {!R.isEmpty(selectedUsers) && (
        <SelectedUsersList users={selectedUsers} onPressUser={onDeselectUser} />
      )}

      <CommunityMembersList
        searchValue={searchValue}
        onSelectUser={onSelectUser}
        selectedMemberIds={selectedUsers.map(({ id }) => id)}
        isMulti
      />
    </Container>
  );
};

export default connect(
  () => ({}),
  (dispatch) => {
    return {
      getCommunityMembers: (searchTerm = '') =>
        dispatch(communityMembersOperations.getCommunityMembers({ searchTerm })),
    };
  },
)(IssueMemberList);
