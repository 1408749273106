import React from 'react';
import T from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { colors, dimensions } from '../../../../../styles';
import { Text } from '../../../../ReusableComponents';
import FlagLabel from './FlagLabel';

const s = StyleSheet.create({
  titleContainer: {
    backgroundColor: colors.grayLighter,
    paddingHorizontal: dimensions.medium,
    paddingTop: 20,
    paddingBottom: 10,
  },
  sectionContainer: {
    backgroundColor: colors.white,
  },
  label: {
    position: 'absolute',
    bottom: 10,
    right: 0,
  },
});

const SectionWithTitle = ({ title, label, children }) => {
  return (
    <>
      <View style={s.titleContainer}>
        <Text color={colors.text} semiBold medium>
          {title}
        </Text>
        {!!label && <FlagLabel title={label} style={s.label} />}
      </View>
      <View style={s.sectionContainer}>{children}</View>
    </>
  );
};

SectionWithTitle.propTypes = {
  title: T.string.isRequired,
  children: T.oneOfType([T.element, T.arrayOf(T.element)]),
  label: T.string,
};

export default SectionWithTitle;
