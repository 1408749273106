import React, { memo, Fragment } from 'react';
import T from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { colors, dimensions, rs } from '../../../../../styles';
import { FontIcon, Text } from '../../../../ReusableComponents';

const s = StyleSheet.create({
  headerContainer: {
    paddingTop: 40,
    paddingHorizontal: dimensions.medium,
    paddingBottom: dimensions.medium,
    flexDirection: 'row',
  },
  descriptionText: {
    lineHeight: 16,
  },
});

const SectionHeader = memo(({ icon, title, description }) => {
  return (
    <View style={s.headerContainer}>
      <FontIcon withoutRTLScale color={colors.gray} size={22} name={icon} />

      <View style={[rs.paddingLeft, rs.verySmallPaddingTop]}>
        <Text semiBold style={rs.verySmallMarginBottom}>
          {title}
        </Text>
        <Text xsmall color={colors.grayBorder} style={s.descriptionText}>
          {description}
        </Text>
      </View>
    </View>
  );
});

SectionHeader.propTypes = {
  icon: T.string,
  title: T.string,
  description: T.string,
};

export default SectionHeader;
