import { createSelector } from 'reselect';
import * as R from 'ramda';
import { transactionHeaderDate } from '../../utils/dateHelper';

export const getTransactionsList = createSelector(
  [
    R.pathOr([], ['transactions', 'transactionIds']),
    R.pathOr({}, ['transactions', 'transactionEntities']),
  ],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getTransactionsSectionList = createSelector(
  [getTransactionsList],

  (transactionsList) => {
    const sections = {};

    transactionsList.forEach((transaction) => {
      const date = transactionHeaderDate(transaction.created_at);
      sections[date] = sections[date] ? [...sections[date], transaction] : [transaction];
    });

    const sectionList = [];

    R.forEachObjIndexed((val, key) => {
      sectionList.push({ title: key, data: val });
    }, sections);

    return sectionList;
  },
);
