import { saveShipping } from './actions';

const saveShippingForNextLot = (
  locations = [],
  shippingPrice = null,
  isShippersDeliveryActive = false,
) => async dispatch => {
  dispatch(saveShipping({ locations, shippingPrice, isShippersDeliveryActive }));
};

export default {
  saveShippingForNextLot,
};
