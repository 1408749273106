import React from 'react';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Animated, View } from 'react-native';
import { dimensions, colors, rs } from '../../styles';
import BackButton from './NavigationButtons/BackButton';
import FontIcon from './FontIcon';
import { isIphoneX, isIos } from '../../utils/detectDevice';
import { NavigationService } from '../../services';

const Button = styled.TouchableOpacity`
  padding-horizontal: ${dimensions.halfMedium};
  min-height: 28px;
  align-items: center;
  justify-content: center;
`;

const Header = styled.View`
  height: ${dimensions.headerHeight};
  width: 100%;
  position: absolute;
  z-index: 1;
`;

const Shadow = styled.Image`
  height: 155%;
  width: 100%;
  position: absolute;
`;

const RootContainer = styled.View`
  height: 100%;
  width: 100%;
`;
const ButtonsContainer = styled.View`
  height: 100%;
  width: 100%;
  padding-top: ${isIos ? (isIphoneX ? 44 : 20) : 0};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const OpacityBackGround = styled.View`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: ${colors.blueLighter};
`;

const AnimatedOpacityBackGround = Animated.createAnimatedComponent(OpacityBackGround);

class OpacityHeader extends React.PureComponent {
  render() {
    const { isShowOptionsButton, onOptionsButtonPress, scrollY } = this.props;

    const imageOpacity = scrollY.interpolate({
      inputRange: [0, 50, 300],
      outputRange: [0, 0, 1],
      extrapolate: 'clamp',
    });

    return (
      <Header>
        <Shadow source={{ uri: 'shadow' }} />
        <RootContainer>
          {/* <AnimatedOpacityBackGround style={{ opacity: imageOpacity }} /> */}
          <ButtonsContainer>
            {isIos ? (
              <BackButton isWhite />
            ) : (
              <Button
                onPress={() => {
                  NavigationService.goBack();
                }}
              >
                <Icon name="close" color={colors.white} size={dimensions.iconSize} />
              </Button>
            )}

            <View style={[rs.row, rs.alignCenter]}>
              {/*{isShowOptionsButton && (*/}
              {/*  <Button onPress={onOptionsButtonPress}>*/}
              {/*    <FontIcon*/}
              {/*      name="Upload_grey"*/}
              {/*      color={colors.white}*/}
              {/*      size={16}*/}
              {/*      style={rs.verySmallMarginBottom}*/}
              {/*    />*/}
              {/*  </Button>*/}
              {/*)}*/}
              {isShowOptionsButton && (
                <Button onPress={onOptionsButtonPress}>
                  <Icon name="dots-vertical" color={colors.white} size={22} />
                </Button>
              )}
            </View>
          </ButtonsContainer>
        </RootContainer>
      </Header>
    );
  }
}

export default OpacityHeader;
