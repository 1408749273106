import React, { useState } from 'react';
import { View } from 'react-native';
import * as R from 'ramda';
import { connect } from 'react-redux';
import ChatButton from './ChatButton';
import strings from '../../../../../localization';
import {
  ASK_TO_BUY,
  CONFIRM_CANCEL_PURCHASE,
  ITEM_DELIVERED,
  ITEM_NOT_AVAILABLE,
  ITEM_REMOVED,
  ITEM_SOLD,
  OFFER_CONFIRMED,
} from '../../../../../store/offers/offerStatuses';
import * as offerActions from '../../../../../store/offers/offerActions';
import { rs } from '../../../../../styles';
import { offersOperations } from '../../../../../store/offers';
import { ModalsService } from '../../../../../services';
import modalTypes from '../../../../../constants/modalTypes';

const OfferButtonsContainer = ({
  offer,
  isCurrentUserBuyer,
  isItemActive,
  itemId,
  createOffer,
  performAction,
  hasMultipleSupply,
  onNewChatWasCreatedFromAskToBuy,
}) => {
  const [isLoading, setLoading] = useState(false);

  const createOfferAndPerformAskToBuy = async () => {
    if (!isLoading) {
      setLoading(true);
    } else {
      return;
    }

    const data = await createOffer(itemId);

    if (R.prop('chat_room_id', data)) {
      // wait new chat and after change chatId for avoiding blinking
      setTimeout(() => {
        onNewChatWasCreatedFromAskToBuy(R.prop('chat_room_id', data));
        setLoading(false);
      }, 60);
    } else {
      setLoading(false);
    }
  };

  const onPerformAction = async (offerId, actionType) => {
    if (!isLoading) {
      setLoading(true);
    } else {
      return;
    }

    await performAction(offerId, actionType);

    setLoading(false);
  };

  const performRefundBySeller = async offerId => {
    ModalsService.showSwipeableModal(modalTypes.REFUND, { offerId });
  };

  const performRepostBySeller = async offerId => {
    if (hasMultipleSupply) {
      ModalsService.showModal(modalTypes.REPOST, { offerId });
    } else {
      await onPerformAction(offerId, offerActions.SELLER_CONFIRMED_OFFER);
    }
  };

  if (isCurrentUserBuyer && R.isEmpty(offer) && isItemActive) {
    return (
      <ChatButton
        isLoading={isLoading}
        title={strings.buy_sell.ask_to_buy}
        onPress={createOfferAndPerformAskToBuy}
      />
    );
  }

  const currentStatusId = offer.status_id;
  switch (parseInt(currentStatusId)) {
    case ASK_TO_BUY: {
      if (isCurrentUserBuyer) {
        return (
          <ChatButton
            isLoading={isLoading}
            title={strings.buy_sell.ask_to_buy}
            onPress={() => {
              onPerformAction(offer.id, offerActions.BUYER_ASKED_TO_BUY);
            }}
          />
        );
      }
      return null;
    }
    case CONFIRM_CANCEL_PURCHASE: {
      // Show one or two buttons depending on seller or
      // buyer and use proper actions for each situation
      if (isCurrentUserBuyer) {
        return (
          <ChatButton
            isLoading={isLoading}
            title={strings.buy_sell.cancel_purchase}
            onPress={() => {
              onPerformAction(offer.id, offerActions.BUYER_CANCELLED_OFFER);
            }}
          />
        );
      }
      return (
        <View style={rs.row}>
          {!isLoading && (
            <ChatButton
              withMarginRight
              secondaryType
              title={strings.common.cancel}
              onPress={() => {
                onPerformAction(offer.id, offerActions.SELLER_CANCELLED_OFFER);
              }}
            />
          )}
          <ChatButton
            isLoading={isLoading}
            title={strings.buy_sell.confirm_purchase}
            onPress={() => {
              performRepostBySeller(offer.id, offerActions.SELLER_CONFIRMED_OFFER);
            }}
          />
        </View>
      );
    }
    case OFFER_CONFIRMED: {
      if (isCurrentUserBuyer) {
        return (
          <ChatButton
            isLoading={isLoading}
            title={strings.buy_sell.confirm_delivery}
            onPress={() => {
              onPerformAction(offer.id, offerActions.BUYER_CONFIRMED_DELIVERY);
            }}
          />
        );
      }
      return (
        <ChatButton
          isLoading={isLoading}
          title={strings.buy_sell.refund}
          onPress={() => {
            performRefundBySeller(offer.id);
          }}
        />
      );
    }
    case ITEM_NOT_AVAILABLE: {
      if (isCurrentUserBuyer) {
        return <ChatButton disabled secondaryType title={strings.buy_sell.declined} />;
      }
      return null;
    }
    case ITEM_DELIVERED: {
      return <ChatButton disabled secondaryType title={strings.buy_sell.item_sold} />;
    }
    case ITEM_SOLD: {
      return <ChatButton disabled secondaryType title={strings.buy_sell.item_sold} />;
    }
    case ITEM_REMOVED: {
      return <ChatButton disabled secondaryType title={strings.buy_sell.item_removed} />;
    }
    default: {
      return null;
    }
  }
};

export default connect(
  () => ({}),
  dispatch => ({
    createOffer: itemId => dispatch(offersOperations.createOffer(itemId)),
    performAction: (offerId, actionId, has_multiple_supply) =>
      dispatch(offersOperations.performAction(offerId, actionId, has_multiple_supply)),
  }),
)(OfferButtonsContainer);
