import React from 'react';
import { Image, Animated } from 'react-native';
import { useSelector } from 'react-redux';
import {
  Separator,
  Text,
  View,
  Container,
  AnimatedOpacityHeader,
  TouchableItem,
} from '../../../ReusableComponents';
import { rs, colors } from '../../../../styles';
import s from './styles';
import strings from '../../../../localization';
import tokensInfo from '../../../../constants/tokensInfo';
import { getAppLanguage } from '../../../../store/app/selectors';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';

const GiftsInfo = () => {
  const language = useSelector(getAppLanguage);

  const scrollY = new Animated.Value(0);

  const onOpenFAQ = () => {
    NavigationService.navigate(screens.FAQ);
  };
  return (
    <Container>
      <AnimatedOpacityHeader scrollY={scrollY} title={tokensInfo.title[language]} />

      <Animated.ScrollView
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
          useNativeDriver: true,
        })}
        scrollEventThrottle={8}
      >
        <View style={s.headerImageContainer}>
          <Image source={{ uri: 'presents' }} style={s.headerImage} />
        </View>

        <View style={s.sectionHeaderContainer}>
          <Text semiBold medium>
            {tokensInfo.title[language]}
          </Text>
        </View>

        <View style={[rs.paddingHorizontal, rs.backgroundWhite]}>
          <View style={rs.bigMarginVertical}>
            <Text color={colors.gray} style={s.descriptionText}>
              {tokensInfo.body[language]}
            </Text>
          </View>
        </View>

        <View style={rs.paddingHorizontal}>
          <Separator />
        </View>

        <TouchableItem style={[rs.bigPaddingVertical, rs.paddingHorizontal]} onPress={onOpenFAQ}>
          <Text style={s.descriptionText}>
            {strings.support.for_anything_else_check_FAQ}{' '}
            <Text semiBold color={colors.blueLight}>
              {strings.support.faq}
            </Text>
          </Text>
        </TouchableItem>
      </Animated.ScrollView>
    </Container>
  );
};

export default GiftsInfo;
