import { StyleSheet } from 'react-native';
import colors from '../../../styles/colors';
import { dimensions, rs } from '../../../styles';

export default StyleSheet.create({
  contentContainer: {},
  itemContainer: {
    width: 64 + dimensions.medium,
    paddingHorizontal: dimensions.halfMedium,
    alignItems: 'center',
  },
  image: {
    height: 64,
    width: 64,
    borderRadius: 32,
    marginBottom: dimensions.medium,
  },
  listContentContainerStyle: {
    paddingHorizontal: dimensions.halfMedium,
  },
});
