import * as R from 'ramda';
import accountApi from '../../api/account';
import blockApi from '../../api/block';
import io from '../../api/io';
import { updateChat } from '../chats/actions';
import { getChatsWithUser } from '../chats/selectors';
import { updateItem } from '../lots/actions';
import { getItemsBySellerId } from '../lots/selectors';
import { ToastsService, LoadingService } from '../../services';
import strings from '../../localization';

const blockUser = (userId) => async (dispatch, getState) => {
  try {
    LoadingService.showLoader();
    await blockApi.blockUser(userId);

    const chats = getChatsWithUser(getState(), userId);

    chats.forEach((chat) => {
      dispatch(updateChat({ chatId: chat.id, chat: { is_blocked: true } }));
    });

    const items = getItemsBySellerId(getState(), userId);

    items.forEach((item) => {
      dispatch(updateItem({ item: { id: item.id, isSellerBlocked: true } }));
    });

    ToastsService.showSuccess(strings.success_messages.user_blocked);
  } catch (e) {
    ToastsService.showError(strings.error_messages.error);
  } finally {
    LoadingService.hideLoader();
  }
};

export const onBlockFromAnotherUser = () => async (dispatch, getState) => {
  try {
    io.onBlockUser((data) => {
      const blockerUserId = R.path(['payload', 'blocker_user_id'], data);

      const chats = getChatsWithUser(getState(), blockerUserId);

      chats.forEach((chat) => {
        dispatch(updateChat({ chatId: chat.id, chat: { is_blocked: true } }));
      });

      const items = getItemsBySellerId(getState(), blockerUserId);

      items.forEach((item) => {
        dispatch(updateItem({ item: { id: item.id, isSellerBlocked: true } }));
      });
    });
  } catch (error) {
    console.log(error);
  }
};

const unblockUser = (userId) => async (dispatch, getState) => {
  try {
    LoadingService.showLoader();
    const isCurrentUserBlocked = await blockApi.unblockUser(userId);

    if (!isCurrentUserBlocked) {
      const chats = getChatsWithUser(getState(), userId);

      chats.forEach((chat) => {
        dispatch(updateChat({ chatId: chat.id, chat: { is_blocked: false } }));
      });

      const items = getItemsBySellerId(getState(), userId);

      items.forEach((item) => {
        dispatch(updateItem({ item: { id: item.id, isSellerBlocked: false } }));
      });
    }

    ToastsService.showSuccess(strings.success_messages.user_unblocked);
  } catch (e) {
    ToastsService.showError(strings.error_messages.error);
  } finally {
    LoadingService.hideLoader();
  }
};

const isUserBlocked = (userId) => async () => {
  try {
    return await blockApi.isUserBlocked(userId);
  } catch (e) {}
};

const sendReport = ({
  reporter_id,
  profile_id,
  report_reason,
  item_id = null,
  report_type,
}) => async (dispatch, getState) => {
  try {
    const response = await accountApi.sendReport({
      reporter_id,
      profile_id,
      report_reason,
      item_id,
      report_type,
    });
    ToastsService.showSuccess('Thank you! Your report have been successfully sent');
    return response;
  } catch (e) {}
};

// endorseUser
// unselectUserEndorsement

const getUserEndorsements = (userId) => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  try {
    const response = await accountApi.getUserEndorsements({ communityId, userId });
    return response;
  } catch (e) {}
};

const endorseUser = ({ userId, endorsementId }) => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  try {
    const response = await accountApi.endorseUser({ communityId, userId, endorsementId });
    return response;
  } catch (e) {}
};

const unselectUserEndorsement = ({ userId, endorsementId }) => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  try {
    const response = await accountApi.unselectUserEndorsement({
      communityId,
      userId,
      endorsementId,
    });
    return response;
  } catch (e) {}
};

const getMembersWhoEndorsedUser = ({ userId, endorsementId }) => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  try {
    const response = await accountApi.getMembersWhoEndorsedUser({
      communityId,
      userId,
      endorsementId,
    });
    return response;
  } catch (e) {}
};

const getAllBuyersList = (skip = 0) => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  try {
    const response = await accountApi.getAllBuyersList({ communityId, skip });
    return response;
  } catch (e) {}
};
const getUserCredibilityDetails = (userId) => async () => {
  try {
    const response = await accountApi.getUserCredibilityDetails(userId);
    return response;
  } catch (e) {}
};

export default {
  blockUser,
  unblockUser,
  isUserBlocked,
  sendReport,
  getUserEndorsements,
  endorseUser,
  unselectUserEndorsement,
  getMembersWhoEndorsedUser,
  getAllBuyersList,
  getUserCredibilityDetails,
  onBlockFromAnotherUser,
};
