import styled from 'styled-components/native';
import { isIos } from '../../../utils/detectDevice';
import { colors, dimensions } from '../../../styles';

export default styled.View`
  padding-top: ${isIos ? dimensions.statusBarHeight : 0};
  height: ${dimensions.headerHeight};
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : colors.grayBackground};
  border-bottom-width: ${({ withoutBorderBottom }) =>
    withoutBorderBottom ? 0 : dimensions.hairline};
  border-bottom-color: ${colors.grayLight};
`;
