import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import Text from './Text';
import TouchableItem from './TouchableItem';
import { colors, dimensions, fontSizes, reusableStyles, rs } from '../../styles';
import Icon from 'react-native-vector-icons/FontAwesome';
import strings from '../../localization';
import { isRTL } from '../../utils/rtlHelper';
import T from 'prop-types';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingHorizontal: dimensions.medium,
    alignItems: 'flex-end',
    paddingTop: 30,
    paddingBottom: 12,
  },
  titleText: {
    fontSize: fontSizes.large,
  },
  smallTile: {
    fontSize: fontSizes.medium,
  },
  icon: {
    paddingLeft: isRTL ? 0 : dimensions.halfMedium,
    paddingRight: isRTL ? dimensions.halfMedium : 0,
    ...reusableStyles.rtlScaleStyle,
  },
});

const SectionTitle = memo(({ title, small, onSeeAllPress, contentContainerStyle }) => (
  <View style={[s.contentContainer, contentContainerStyle]}>
    <Text semiBold style={small ? s.smallTile : s.titleText}>
      {title}
    </Text>
    {!!onSeeAllPress && (
      <TouchableItem style={[rs.row, rs.alignCenter]} onPress={onSeeAllPress}>
        <Text color={colors.blueLight}>{strings.common.see_all}</Text>
        {/*<Icon name={'angle-right'} size={22} color={colors.blueLight} style={s.icon} />*/}
      </TouchableItem>
    )}
  </View>
));

SectionTitle.propTypes = {
  title: T.string.isRequired,
  contentContainerStyle: T.shape({}),
  onSeeAllPress: T.func,
};

export default SectionTitle;
