import screens from '../navigation/screens';

const appActionTypes = {
  OPEN_CREATE_WISH: 'OPEN_CREATE_WISH',
  OPEN_CREATE_ITEM: 'OPEN_CREATE_ITEM',
  OPEN_ITEMS_MARKETPLACE: 'OPEN_ITEMS_MARKETPLACE',
  OPEN_WISHES_MARKETPLACE: 'OPEN_WISHES_MARKETPLACE',
  OPEN_WISHES: 'OPEN_WISHES',
  OPEN_INVITE_FRIENDS: 'OPEN_INVITE_FRIENDS',
  OPEN_ADD_EMAIL: 'OPEN_ADD_EMAIL',
  OPEN_ADD_USER_NAME: 'OPEN_ADD_USER_NAME',

  OPEN_ADD_NICKNAME: 'OPEN_ADD_NICKNAME',
  OPEN_ADD_USER_AVATAR: 'OPEN_ADD_USER_AVATAR',
  OPEN_ADD_GENDER: 'OPEN_ADD_GENDER',
  OPEN_ADD_BIRTHDAY: 'OPEN_ADD_BIRTHDAY',
};

export const appActionScreenMap = {
  [appActionTypes.OPEN_CREATE_WISH]: screens.EditWishStack,
  [appActionTypes.OPEN_CREATE_ITEM]: screens.EditItemStack,
  [appActionTypes.OPEN_ITEMS_MARKETPLACE]: screens.MarketplaceTab,
  [appActionTypes.OPEN_WISHES_MARKETPLACE]: screens.Wishes,
  [appActionTypes.OPEN_WISHES]: screens.Wishes,
  [appActionTypes.OPEN_INVITE_FRIENDS]: screens.InviteFriends,
  [appActionTypes.OPEN_ADD_EMAIL]: screens.AddEmail,
  [appActionTypes.OPEN_ADD_USER_NAME]: screens.AddUserName,

  [appActionTypes.OPEN_ADD_NICKNAME]: screens.EditNickname,
  [appActionTypes.OPEN_ADD_USER_AVATAR]: screens.AddProfileImage,
  [appActionTypes.OPEN_ADD_GENDER]: screens.AddGender,
  [appActionTypes.OPEN_ADD_BIRTHDAY]: screens.AddBirthday,
};

export default appActionTypes;
