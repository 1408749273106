import React from 'react';
import * as R from 'ramda';
import { Container, View, MapWithCarousel } from '../../../ReusableComponents';
import strings from '../../../../localization';
import { headerStyle } from '../../../../styles';

const UserLocations = ({ route }) => {
  const locations = route.params.userLocations;
  const buyerLocations = route.params.buyerLocations;
  return (
    <Container>
      <MapWithCarousel
        locations={R.uniqBy(R.prop('latitude'), locations)}
        buyerLocations={
          !R.isNil(buyerLocations) ? R.uniqBy(R.prop('latitude'), buyerLocations) : []
        }
      />
    </Container>
  );
};

export default UserLocations;
