import * as R from 'ramda';
import { getDistance } from 'geolib';

const KM_COEFFICIENT = 0.001;

const convertToKm = (distance) => {
  return Number((distance * KM_COEFFICIENT).toFixed(1));
};

// R.minBy works only with two values and don't work with arrays in current version
const getMinFromArrayByProp = (array, prop) => {
  return array.reduce((acc, item) => {
    if (item[prop] < acc[prop]) {
      return item;
    }

    return acc;
  }, array[0]);
};

export const getNearestBuyerLocation = (buyerLocations, sellerLocations) => {
  const distances = sellerLocations
    .map((sellerLocation) => {
      return buyerLocations.map((buyerLocation) => {
        const buyerLocationCoords = { latitude: buyerLocation.latitude, longitude: buyerLocation.longitude };

        const distance = getDistance(
          buyerLocationCoords,
          { latitude: sellerLocation.latitude, longitude: sellerLocation.longitude },
          100,
        );

        return {
          parentId: sellerLocation.id,
          coords: buyerLocationCoords,
          distance: convertToKm(distance),
        };
      });
    })
    .flat();

  if (R.isEmpty(distances)) {
    return {};
  }

  return getMinFromArrayByProp(distances, 'distance');
};

/**
 * Get bounding box of react-native-maps MapView depends on central point coordinates
 * @source https://github.com/react-native-maps/react-native-maps/issues/356#issuecomment-515694070
 *
 * @param location {{ latitude: number, longitude: number, latitudeDelta?: number, longitudeDelta?: number }} central point coordinates from MapView
 *
 * @returns {{ minLat: number, maxLat: number, minLong: number, maxLong: number }}
 */
export const getMapViewBounds = (location) => {
  const DEFAULT_DELTA = 0.08;
  const latDelta = location.latitudeDelta ?? DEFAULT_DELTA;
  const longDelta = location.longitudeDelta ?? DEFAULT_DELTA;

  const latitudeOffset = latDelta / 2;
  const longitudeOffset = (longDelta < -180 ? 360 + longDelta : longDelta) / 2;

  const getMinLat = (lat, offset) => {
    const value = lat - offset;
    if (value < -90) {
      return (90 + offset) * -1;
    }
    return value;
  };

  const getMaxLat = (lat, offset) => {
    const value = lat + offset;
    if (value > 90) {
      return (90 - lat) * -1;
    }
    return value;
  };

  const getMinLong = (long, offset) => {
    const value = long - offset;
    if (value < -180) {
      return (180 + offset) * -1;
    }
    return value;
  };

  const getMaxLong = (long, offset) => {
    const value = long + offset;
    if (value > 180) {
      return (180 - offset) * -1;
    }
    return value;
  };

  return {
    minLat: getMinLat(location.latitude, latitudeOffset),
    maxLat: getMaxLat(location.latitude, latitudeOffset),
    minLong: getMinLong(location.longitude, longitudeOffset),
    maxLong: getMaxLong(location.longitude, longitudeOffset),
  };
};
