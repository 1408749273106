import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';
import { COMMUNITY_MEMBERS_LIMIT } from '../../constants/listLimits';

import types from './types';
import { RESET_GROUP_STATE } from '../constants';

const initialState = {
  isLoadingMore: false,
  communityMemberEntities: {},
  communityMemberIds: [],
  communityMemberSearchHistoryEntities: {},
  communityMemberSearchHistoryIds: [],
  existMore: true,
};

const communityInfoReducer = handleActions(
  {
    [types.SET_COMMUNITY_MEMBERS]: mergeDeep(({ payload }, state) => ({
      communityMemberEntities: payload.communityMemberEntities,
      communityMemberIds: payload.communityMemberIds,
      existMore: payload.communityMemberIds.length === COMMUNITY_MEMBERS_LIMIT,
      isLoading: false,
    })),

    [types.SET_MORE_COMMUNITY_MEMBERS]: mergeDeep(({ payload }, state) => ({
      communityMemberEntities: payload.communityMemberEntities,
      communityMemberIds: [...state.communityMemberIds, ...payload.communityMemberIds],
      existMore: payload.communityMemberIds.length === COMMUNITY_MEMBERS_LIMIT,
      isLoadingMore: false,
    })),

    [types.START_LOADING_COMMUNITY_MEMBERS]: mergeDeep(() => ({
      isLoading: true,
      communityMemberIds: [],
      existMore: true,
    })),

    [types.START_LOADING_MORE_COMMUNITY_MEMBERS]: mergeDeep(() => ({
      isLoadingMore: true,
    })),

    [types.SET_COMMUNITY_MEMBERS_SEARCH_HISTORY]: mergeDeep(({ payload }) => ({
      communityMemberSearchHistoryEntities: payload.communityMemberSearchHistoryEntities,
      communityMemberSearchHistoryIds: payload.communityMemberSearchHistoryIds,
    })),

    [types.REMOVE_MEMBER_FROM_SEARCH_HISTORY]: mergeDeep(({ payload }, state) => ({
      communityMemberSearchHistoryIds: state.communityMemberSearchHistoryIds.filter(
        id => id !== payload.memberId,
      ),
    })),

    [RESET_GROUP_STATE]: mergeDeep({
      ...initialState,
    }),
  },
  initialState,
);

export default communityInfoReducer;
