import { StyleSheet } from 'react-native';
import { dimensions } from '../../../../styles';
import { isIphoneX } from '../../../../utils/detectDevice';

export default StyleSheet.create({
  image: {
    width: 140,
    height: 140,
  },

  termsAndConditionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginHorizontal: dimensions.medium,
    marginTop: 20,
  },
  buttonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.halfMedium,
    marginBottom: isIphoneX ? dimensions.doubleMedium : dimensions.medium,
  },
});
