import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FlatList, Keyboard } from 'react-native';
import debounce from 'debounce';
import * as R from 'ramda';
import {
  Container,
  Separator,
  View,
  HeaderContainer,
  SearchItem,
  SearchHistoryItem,
  Spinner,
  EmptyListWithImage,
} from '../../../ReusableComponents';
import SearchBar from '../../../ReusableComponents/SearchBar';
import { rs } from '../../../../styles';
import { lotsOperations } from '../../../../store/lots';
import { feedSearchOperations, feedSearchSelector } from '../../../../store/feedSearch';
import { NavigationService } from '../../../../services';
import strings from '../../../../localization';

class WishSearch extends Component {
  state = {
    searchTerm: '',
    titlesSuggestions: [],
    isTitleSuggestionsLoading: false,
  };

  getSuggestionsDebounce = debounce(async (searchTerm) => {
    const { getWishesTitlesSuggestions } = this.props;

    const titlesSuggestions = await getWishesTitlesSuggestions(searchTerm);

    this.setState({ titlesSuggestions, isTitleSuggestionsLoading: false });
  }, 400);

  componentDidMount() {
    const { searchTerm, getWishesSearchHistory } = this.props;
    getWishesSearchHistory();
    if (searchTerm) {
      this.setState({ searchTerm });
    }
  }

  onSearchTermChanged = (searchTerm) => {
    this.setState({ searchTerm, isTitleSuggestionsLoading: true });
    this.getSuggestionsDebounce(searchTerm);
  };

  onSelectSearchResult = (title) => {
    const { setFeedWishFilters } = this.props;
    const { searchTerm } = this.state;

    setFeedWishFilters({
      searchTerm: R.is(String, title) ? title : searchTerm.trim() || null,
    });
    Keyboard.dismiss();
    NavigationService.goBack();
  };

  onRemoveSearchHistory = (searchTermId) => {
    const { removeWishSearchTerm } = this.props;
    removeWishSearchTerm(searchTermId);
  };

  renderEmptyListComponent = () => {
    const { searchTerm, isTitleSuggestionsLoading } = this.state;

    if (isTitleSuggestionsLoading) {
      return (
        <View style={[rs.paddingTop]}>
          <Spinner />
        </View>
      );
    }

    return (
      <EmptyListWithImage
        image="empty_state_search"
        text={
          searchTerm
            ? strings.emptyLists.empty_wishes_search_results
            : strings.emptyLists.empty_wish_history
        }
      />
    );
  };

  render() {
    const { titlesSuggestions, searchTerm, isTitleSuggestionsLoading } = this.state;
    const { searchResults } = this.props;

    const showSearchHistory =
      R.isEmpty(titlesSuggestions) && R.isEmpty(searchTerm) && !isTitleSuggestionsLoading;

    return (
      <Container>
        <HeaderContainer>
          <SearchBar
            autoFocus
            showCancel
            value={searchTerm}
            onCancel={() => NavigationService.goBack()}
            onChangeText={this.onSearchTermChanged}
            enablesReturnKeyAutomatically
            onSubmitEditing={this.onSelectSearchResult}
            placeholder={strings.other.wishes_search_placeholder}
            returnKeyType="search"
          />
        </HeaderContainer>
        <FlatList
          keyboardShouldPersistTaps="handled"
          data={showSearchHistory ? searchResults : titlesSuggestions}
          keyExtractor={(item) => (R.prop('id', item) || item).toString()}
          ItemSeparatorComponent={() => <Separator />}
          ListEmptyComponent={this.renderEmptyListComponent}
          renderItem={({ item }) =>
            showSearchHistory ? (
              <SearchHistoryItem
                title={item.search_term}
                id={item.id}
                onSelectSearchResult={this.onSelectSearchResult}
                onRemoveSearchHistory={this.onRemoveSearchHistory}
              />
            ) : (
              <SearchItem title={item} onSelectSearchResult={this.onSelectSearchResult} />
            )
          }
        />
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    searchTerm: state.lots.feedWishFilters.searchTerm,
    searchResults: feedSearchSelector.getWishSearchHistory(state),
  }),
  (dispatch) => ({
    getWishesSearchHistory: () => dispatch(feedSearchOperations.getWishesSearchHistory()),
    removeWishSearchTerm: (searchTermId) =>
      dispatch(feedSearchOperations.removeWishSearchTerm(searchTermId)),
    getWishesTitlesSuggestions: (searchTerm) =>
      dispatch(feedSearchOperations.getWishesTitlesSuggestions(searchTerm)),
    setFeedWishFilters: (feedLotFilters) =>
      dispatch(lotsOperations.setFeedWishFilters(feedLotFilters)),
  }),
)(WishSearch);
