import React from 'react';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/Feather';
import Text from './Text';
import { dimensions, colors } from '../../styles';

const Circle = styled.View`
  height: 56px;
  width: 56px;
  padding-top: 2px;
  border-radius: 28px;
  border-width: 1px;
  border-color: ${({ achieved }) => (achieved ? colors.success : colors.gray)};
  margin-horizontal: ${dimensions.medium};
  align-items: center;
  justify-content: center;
`;

// const NumberText = styled(Text)`
//   color: ${colors.gray};
//   font-size: 24px;
// `;

const GoalIcon = ({ achieved, number }) => (
  <Circle achieved={achieved}>
    <Icon name="check" color={achieved ? colors.success : colors.gray} size={30} />
  </Circle>
);

export default GoalIcon;
