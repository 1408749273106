import api from './api';
import { NOTIFICATIONS_LIMIT } from '../constants/listLimits';

export default {
  registerDevice: ({ userId, deviceId, deviceToken, deviceLanguage }) =>
    api.post('addDevice', {
      user_id: userId,
      device_id: deviceId,
      device_token: deviceToken,
      device_language: deviceLanguage,
    }),

  refreshDeviceToken: ({ userId, deviceId, deviceToken, newDeviceId }) =>
    api.post('refreshDeviceToken', {
      user_id: userId,
      device_id: deviceId,
      device_token: deviceToken,
      new_device_id: newDeviceId,
    }),

  changeDeviceLanguage: (language, device_id) =>
    api.post('changeDeviceLanguage', { new_device_language: language, device_id }),

  getNotifications: (skip = 0) =>
    api.post('getNotifications', { skip, limit: NOTIFICATIONS_LIMIT }),

  markNotificationsAsRead: () => api.post('markNotificationsAsRead'),

  markNotificationAsOpened: (notificationId) =>
    api.post('markNotificationAsOpened', { notification_id: notificationId }),

  sendNotificationToCommunity: ({ title, body, communityId }) =>
    api.post('sendNotificationToCommunity', { title, body, community_id: communityId }),
  sendMarketingNotification: ({ title, body, communityId, targetGroupId, appAction }) =>
    api.post('admin/marketingNotifications/group', {
      title,
      body,
      community_id: communityId,
      target_group_id: targetGroupId,
      app_action: appAction,
    }),
};
