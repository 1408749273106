import React, { useState } from 'react';
import T from 'prop-types';
import FastImage from '../FastImage';
import { StyleSheet, View } from 'react-native';
import { colors } from '../../../styles';

const s = StyleSheet.create({
  imageContainer: {
    backgroundColor: colors.grayLighter,
  },
});

const AppImage = ({ onError: onErrorFromProps, containerStyle, ...props }) => {
  // TODO: will be used in future for handling image issues
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const onError = (error) => {
    setIsError(true);

    if (!!onErrorFromProps && !!error) {
      onErrorFromProps(error);
    }
  };

  const onLoadEnd = () => {
    if (!isError) {
      setIsLoading(false);
    }
  };

  return (
    <View style={[s.imageContainer, containerStyle]}>
      <FastImage {...props} onError={onError} onLoadEnd={onLoadEnd} progressiveRenderingEnabled />
    </View>
  );
};

AppImage.propTypes = {
  containerStyle: T.any,
  onError: T.func,
};

export default AppImage;
