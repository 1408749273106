const HOME_TOKEN_INFO_BANNER = 'HOME_TOKEN_INFO_BANNER';
const TOKEN_INFO_BANNER = 'TOKEN_INFO_BANNER';
const MARKETPLACE_INVITE_FRIENDS = 'MARKETPLACE_INVITE_FRIENDS';
const MARKETPLACE_LOCATION_PERMISSION = 'MARKETPLACE_LOCATION_PERMISSION';
const MENU_INVITE_FRIENDS = 'MENU_INVITE_FRIENDS';
const WISHES_BANNER = 'WISHES_BANNER';
const ITEM_REVIEW = 'ITEM_REVIEW';

export default {
  HOME_TOKEN_INFO_BANNER,
  TOKEN_INFO_BANNER,
  WISHES_BANNER,
  MARKETPLACE_INVITE_FRIENDS,
  MARKETPLACE_LOCATION_PERMISSION,
  MENU_INVITE_FRIENDS,
  ITEM_REVIEW,
};
