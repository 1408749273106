import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../styles';


export default StyleSheet.create({
  activeButtonContainer: {
    backgroundColor: colors.blueLight,
    borderColor: colors.blueLight,
  },
  buttonContainer: {
    // transform: [{ scaleX: -1 }],
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 50,
    marginHorizontal: 4,
    height: 32,
    paddingHorizontal: 12,

    borderRadius: 16,
    borderWidth: 1,
    backgroundColor: colors.white,
    borderColor: colors.grayLight,
  },
  squareButtonContainer: {
    borderRadius: 5,
  },
  icon: {
    marginLeft: 12,
  },
  squareButtonIcon: {
    marginRight: dimensions.halfMedium,
  },
});
