import React from 'react';
import { connect } from 'react-redux';
import Button from '../../Buttons/Button';
import Text from '../../Text';
import {
  ContentContainer,
  AdminAvatar,
  MainContainer,
  Title,
  Description,
  ButtonContainer,
} from './styles';
import strings from '../../../../localization';
import { colors, rs } from '../../../../styles';
import { groupsOperations, groupsSelectors } from '../../../../store/groups';

const UserLeftCommunity = ({ onPress }) => (
  <ContentContainer>
    <AdminAvatar source={{ uri: 'round_logo' }} />
    <MainContainer>
      <Text large semiBold alignCenter color={colors.blueLighter} style={rs.bigMarginBottom}>
        {strings.buy_sell.user_left_community}
      </Text>
      {/* <Description>{strings.settings.leave_community_text}</Description> */}

      <ButtonContainer>
        <Button withMarginRight title={strings.common.ok} onPress={onPress} />
      </ButtonContainer>
    </MainContainer>
  </ContentContainer>
);

export default UserLeftCommunity;
