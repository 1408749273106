import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import Button from '../../Buttons/Button';
import Text from '../../Text';
import { dimensions, fontSizes } from '../../../../styles';

const ContentContainer = styled.View`
  align-items: center;
`;

const MainContainer = styled.View`
  padding-vertical: ${dimensions.doubleMedium};
  padding-horizontal: ${dimensions.doubleMedium};
  width: ${dimensions.width - 3 * dimensions.medium};
`;

const ButtonContainer = styled.View`
  width: 100%;
  height: 40px;
  margin-top: 72px;
`;

const Title = styled(Text)`
  font-size: 28px;
  margin-bottom: 25px;
  text-align: center;
`;

const Description = styled(Text)`
  font-size: ${fontSizes.medium};
  text-align: center;
  margin-bottom: ${dimensions.halfIndent};
`;

const RemoveItemError = ({ onPress }) => (
  <ContentContainer>
    <MainContainer>
      <Title>Warning</Title>
      <Description>
        {'You cant remove item cause this it has been already purchased by other user'}
      </Description>

      <ButtonContainer>
        <Button title="Ok" onPress={onPress} />
      </ButtonContainer>
    </MainContainer>
  </ContentContainer>
);

export default RemoveItemError;
