/** DEPRECATED remove it ASAP don't expand it */
import {
  USER_CONTACTS,
  CONTACT_LIST,
  LOAD_ADD_MEMBERS_CONTACTS,
  RESET_GROUP_STATE,
} from './constants';

const initialState = {
  userContacts: {},
  contactList: [],
  isLoadingAddMembersContacts: false,
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case USER_CONTACTS:
      return {
        ...state,
        userContacts: action.userContacts,
      };
    case CONTACT_LIST:
      return {
        ...state,
        contactList: action.contactList,
      };
    case LOAD_ADD_MEMBERS_CONTACTS:
      return {
        ...state,
        isLoadingAddMembersContacts: action.isLoadingAddMembersContacts,
      };
    case RESET_GROUP_STATE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default reducers;
