import React, { memo } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import { View } from 'react-native';
import s from './styles';
import { TouchableItem, FontIcon, AngleIcon, Text } from '../../../../../ReusableComponents';

import { colors, rs } from '../../../../../../styles';
import strings from '../../../../../../localization';
import { numberWithCommas } from '../../../../../../utils/stringHelper';
import { NavigationService } from '../../../../../../services';
import screens from '../../../../../../navigation/screens';
import { connect } from 'react-redux';

const Item = memo(({ title, subtitle, iconName, onSelect, withArrow = true }) => (
  <TouchableItem style={s.itemContainer} onPress={onSelect} disabled={!onSelect}>
    <View style={s.iconContainer}>
      <FontIcon name={iconName} size={22} color={colors.secondary} />
    </View>

    <Text semiBold color={colors.blueLight}>
      {' '}
      {subtitle}{' '}
    </Text>

    <Text alignCenter>{title}</Text>

    {withArrow && (
      <AngleIcon />
    )}
  </TouchableItem>
));

const HorizontalInfoList = memo(({ communityInfo }) => {
  const { community_stats, members_count } = communityInfo;

  const onOpenMarketplace = () => {
    NavigationService.navigate(screens.MarketplaceTab);
  };

  const onOpenSoldItems = () => {
    NavigationService.navigate(screens.FeedSoldItems);
  };
  const onOpenMembers = () => {
    NavigationService.navigate(screens.Members);
  };

  return (
    <View style={s.contentContainer}>
      <Item
        key="members"
        title={strings.communities.members}
        subtitle={`${numberWithCommas(members_count)}`}
        iconName="Info_mambers"
        onSelect={onOpenMembers}
      />
      <Item
        key="items_for_sale"
        title={strings.community_info.items_for_sale}
        subtitle={`${numberWithCommas(R.prop('total_uploaded_items_count', community_stats))}`}
        iconName="Info_items-for-sale"
        onSelect={onOpenMarketplace}
      />
      <Item
        key="sold_items"
        title={strings.community_info.sold_items}
        subtitle={`${numberWithCommas(R.prop('total_sold_items_count', community_stats))}`}
        iconName="Info_sold-items"
        withArrow={false}
      />
    </View>
  );
});

HorizontalInfoList.propTypes = {
  communityInfo: T.object,
};

export default connect((state) => ({
  communityInfo: state.communityInfo,
}))(HorizontalInfoList);
