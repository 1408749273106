import io from 'socket.io-client';
import { CHAT_SERVICE_URL } from '../../config/urls';
import { ENV } from '../../config/env';

// todo refactor everything
class Io {
  constructor() {
    this.socket = null;
  }

  setToken(token) {
    if (ENV !== 'production') {
      console.log('setToken', token);
    }
    if (!this.socket) {
      this.socket = io(CHAT_SERVICE_URL, {
        transports: ['websocket'],
        query: `token=bearer ${token}`,
      });
      this.connect();
      // this.newUser();
      // this.newUserConnected();
      // this.disconnect();
    }
  }

  connect() {
    this.socket.on('connect', () => {
      console.log('********** SOCKET ON CONNECT socket.io');

      this.newUserConnected();

      this.newUser();
      this.disconnect();
    });
  }

  onNewMessage(callBack) {
    return this.socket.on('newMessage', callBack);
  }

  onMarkAsRead(callBack) {
    return this.socket.on('markAsRead', callBack);
  }

  onMarkMessageAsRead(callback) {
    return this.socket.on('markMessageAsRead', callback);
  }

  onTypingMessage(callBack) {
    return this.socket.on('typingMessage', callBack);
  }

  onBlockUser(callback) {
    return this.socket.on('blockUser', callback);
  }

  onNewNotification(callBack) {
    return this.socket.on('newNotification', callBack);
  }

  newUserConnected() {
    return this.socket.on('newUserConnected', () => console.log('********** ON newUserConnected'));
  }

  newUser() {
    console.log('********** EMIT newUser');
    return this.socket.emit('newUser');
  }

  reconnect() {
    this.socket.on('reconnect', () => {
      this.disconnect();
      console.log('RECONNECT socket.io');
    });
  }

  connectError() {
    this.socket.on('connect_error', () => {
      this.socket.open();
    });
  }

  disconnect() {
    this.socket.on('disconnect', (data) => {
      console.log('********** ON disconnect -', data);
      this.socket.off('newUserConnected');
      this.socket.off('disconnect');
      // this.socket.removeAllListeners();
      // this.socket.removeAllListeners();
    });
  }

  connectTimeout() {
    this.socket.on('connect_timeout', () => {
      // this.socket.open();
    });
  }

  error() {
    this.socket.on('error', () => {
      console.log('SOCKET ERROROR');
      // this.socket.open();
    });
  }
}

const socket = new Io();

export default socket;
