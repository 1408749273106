import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Image, KeyboardAvoidingView, ScrollView } from 'react-native';
import strings from '../../../../localization';
import { isIos } from '../../../../utils/detectDevice';
import { userOperations } from '../../../../store/userInfo';
import {
  View,
  Text,
  Container,
  Button,
  InputWithTitleAndValidation,
  ScreenHeader,
} from '../../../ReusableComponents';
import { colors } from '../../../../styles';
import s from './style';

const VerifyEmailScreen = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const dispatch = useDispatch();

  const onSubmitCode = async () => {
    await dispatch(userOperations.verifyCampusEmail(verificationCode));
    setVerificationCode('');
  };

  const isSubmitButtonDisabled = !verificationCode;

  return (
    <Container>
      <KeyboardAvoidingView
        keyboardShouldPersistTabs="handled"
        behavior={isIos ? 'padding' : null}
        style={s.keyboardAvoidingView}
      >
        <ScreenHeader withoutBorder headerTitle={strings.login.verify_campus_email} />
        <ScrollView keyboardShouldPersistTabs="handled">
          <Image style={s.image} source={{ uri: 'email_verification' }} resizeMode="contain" />

          <InputWithTitleAndValidation
            onChangeText={setVerificationCode}
            value={verificationCode}
            maxLength={6}
            placeholder={strings.promo_codes.enter_code_here}
            inputStyle={s.input}
            inputContainerStyle={s.inputContainer}
            keyboardType="numeric"
            underlineColorAndroid="transparent"
            autoFocus
          />

          <Text style={s.infoText} color={colors.gray}>
            {strings.login.campus_email_verification_description}
          </Text>
        </ScrollView>

        <View style={s.buttonContainer}>
          <Button
            title={strings.user_info.email_verification_button}
            onPress={onSubmitCode}
            disabled={isSubmitButtonDisabled}
          />
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

export default VerifyEmailScreen;
