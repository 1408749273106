import React, { memo } from 'react';
import { Pressable } from 'react-native';

const TouchableItem = memo(({ style, withoutOpacity = false, ...props }) => {
  return (
    <Pressable
      style={({ pressed }) => [pressed && !withoutOpacity ? { opacity: 0.5 } : {}, style]}
      {...props}
    />
  );
});

export default TouchableItem;
