import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
import T from 'prop-types';
import Text from './Text';
import UserAvatar from './UserAvatar';
import { rs, colors } from '../../styles';
import NavigationService from '../../services/NavigationService';
import { ModalsService } from '../../services';
import modalTypes from '../../constants/modalTypes';

const UserName = styled(Text)`
  max-width: 112px;
`;

const UserInfo = ({ image, name, bottomInfo, id, onPress: onPressFromProps }) => {
  const onPress = () => {
    if (!!onPressFromProps) {
      onPressFromProps();
      return;
    }

    NavigationService.push('UserProfile', {
      name,
      userId: id,
      profile_image: image,
    });
  };

  return (
    <TouchableOpacity style={rs.row} onPress={onPress}>
      <UserAvatar small withoutMargin uri={image} />

      <View style={[rs.smallMarginLeft, rs.justifyCenter]}>
        <UserName color={colors.black} light medium numberOfLines={1} ellipsizeMode="tail">
          {name}
        </UserName>
        {!!bottomInfo && <Text light>{bottomInfo}</Text>}
      </View>
    </TouchableOpacity>
  );
};

UserInfo.propTypes = {
  image: T.string,
  name: T.string.isRequired,
  id: T.string.isRequired,
  bottomInfo: T.string,
  onPress: T.func,
};

export default UserInfo;
