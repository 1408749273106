import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import { View } from 'react-native';
import { offersOperations, offersSelectors } from '../../../store/offers';
import { headerStyleTransparentWithBackground } from '../../../styles/headerStyle';
import { Container, EmptyListWithIcon, EmptyListWithImage } from '../../ReusableComponents';
import strings from '../../../localization';
import WishFulfillmentOfferItem from './components/WishFulfillmentOfferItem';
import OffersList from './components/OffersList';
import { LoadingService } from '../../../services';
import NavigationService from '../../../services/NavigationService';
import screens from '../../../navigation/screens';

const WishFulfillmentOffers = ({
  performAction,
  offers,
  isLoading,
  isLoadingMore,
  getWishFulfillmentOffers,
}) => {
  const [isRefreshing, setRefreshing] = useState(false);

  useEffect(() => {
    getWishFulfillmentOffers();
  }, []);

  const onRefresh = async () => {
    setRefreshing(true);
    await getWishFulfillmentOffers();
    setRefreshing(false);
  };

  const onLoadMore = () => {
    getWishFulfillmentOffers(true);
  };

  const onPerformAction = async (offerId, actionId) => {
    LoadingService.showSuccessLoader();
    await performAction(offerId, actionId);
    LoadingService.hideSuccessLoader();
  };

  const onOpenChat = (chatId) => {
    NavigationService.push(screens.ChatRoom, { chatId });
  };

  const onOpenCreateWish = () => {
    NavigationService.navigate(screens.EditWishStack);
  };

  return (
    <Container>
      <OffersList
        data={offers}
        isRefreshing={isRefreshing}
        isLoading={isLoading}
        isLoadingMore={isLoadingMore}
        onLoadMore={onLoadMore}
        onRefresh={onRefresh}
        ListEmptyComponent={() => (
          <EmptyListWithImage
            image="empty_state_wish_fulfillment"
            text={strings.emptyLists.empty_wish_fulfillments}
          />
        )}
        renderItem={({ item }) => (
          <WishFulfillmentOfferItem
            offer={item}
            onOpenChat={onOpenChat}
            onPerformAction={onPerformAction}
          />
        )}
      />
    </Container>
  );
};

WishFulfillmentOffers.propTypes = {
  performAction: T.func,
  getWishFulfillmentOffers: T.func,
  offers: T.array,
  isLoading: T.bool,
  isLoadingMore: T.bool,
};

export default connect(
  (state) => ({
    offers: offersSelectors.getWishFulfillmentOffers(state),
    isLoading: state.offers.wishFulfillmentOffers.isLoading,
    isLoadingMore: state.offers.wishFulfillmentOffers.isLoadingMore,
  }),
  (dispatch) => ({
    performAction: (offerId, actionId) =>
      dispatch(offersOperations.performAction(offerId, actionId)),
    getWishFulfillmentOffers: (isLoadMore) =>
      dispatch(offersOperations.getWishFulfillmentOffers({ isLoadMore })),
  }),
)(WishFulfillmentOffers);
