import * as React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import strings from '../../localization';
import { NavigationService } from '../../services';
import screens from '../screens';
import Popover, { usePopover } from '../../components/ReusableComponents/PortalPopover';
import TouchableItem from '../../components/ReusableComponents/TouchableItem';
import Text from '../../components/ReusableComponents/Text';
import FontIcon from '../../components/ReusableComponents/FontIcon';
import { colors, dimensions, rs } from '../../styles';

const s = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.halfMedium,
    backgroundColor: colors.blueLighter,
    borderRadius: 24,
  },
  option: {
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.halfMedium,
  },
});

const HeaderPostButton = () => {
  const wrapperRef = React.useRef(null);
  const { isVisible, targetLayout, handleMountPress, hide } = usePopover(wrapperRef);

  const onPostWishPress = () => {
    NavigationService.navigate(screens.EditWishStack);
    hide();
  };

  const onPostItemPress = () => {
    NavigationService.navigate(screens.EditItemStack);
    hide();
  };

  return (
    <View>
      <TouchableOpacity ref={wrapperRef} onPress={handleMountPress} style={s.button}>
        <>
          <FontIcon name="Plus2" size={20} color={colors.white} withoutRTLScale />
          <Text color={colors.white} style={rs.smallMarginLeft} size="medium">
            {strings.common.post}
          </Text>
        </>
      </TouchableOpacity>

      {isVisible && (
        <Popover onPress={hide} targetLayout={targetLayout}>
          <View>
            <TouchableItem onPress={onPostItemPress} style={s.option}>
              <Text semiBold>{strings.items.item}</Text>
            </TouchableItem>
            <TouchableItem onPress={onPostWishPress} style={s.option}>
              <Text semiBold>{strings.wishes.wish}</Text>
            </TouchableItem>
          </View>
        </Popover>
      )}
    </View>
  );
};

export default HeaderPostButton;
