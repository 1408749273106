import React from 'react';
import { FlatList } from 'react-native';
import T from 'prop-types';
import styled from 'styled-components/native';
import { colors, dimensions } from '../../../styles';
import EmptyList from '../EmptyList';
import Spinner from '../Spinner';
import strings from '../../../localization';

const Separator = styled.View`
  width: 100%;
  height: ${dimensions.hairline};
  margin-left: ${50 + dimensions.medium * 2};
  background-color: ${colors.grayLighter};
`;

class List extends React.PureComponent {
  render() {
    const {
      data,
      onLoadMore,
      isLoading,
      isLoadingMore,
      emptyComponentText,
      onSelectUser,
      ListEmptyComponent,
      ListItem,
      ...listProps
    } = this.props;
    return (
      <FlatList
        data={data}
        keyExtractor={item => item.id.toString()}
        ItemSeparatorComponent={() => <Separator />}
        onEndReachedThreshold={0.9}
        onEndReached={onLoadMore}
        ListFooterComponent={isLoadingMore && <Spinner />}
        ListEmptyComponent={
          isLoading ? (
            <Spinner />
          ) : ListEmptyComponent ? (
            <ListEmptyComponent />
          ) : (
            <EmptyList text={emptyComponentText || strings.members.no_results} />
          )
        }
        renderItem={({ item }) => <ListItem item={item} />}
        {...listProps}
      />
    );
  }
}

List.propTypes = {
  onLoadMore: T.func.isRequired,
  onSelectUser: T.func,
  isLoading: T.bool,
  isLoadingMore: T.bool,
};

export default List;
