import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';

import types from './types';

const initialState = {
  userLocationIds: [],
  userLocationEntities: [],
};

const userLocationsReducer = handleActions(
  {
    [types.SAVE_USER_LOCATIONS]: mergeDeep(({ payload }, state) => ({
      userLocationIds: payload.userLocationIds,
      userLocationEntities: payload.userLocationEntities,
    })),

    [types.REMOVE_USER_LOCATION]: mergeDeep(({ payload }, state) => ({
      userLocationEntities: {
        [payload]: { is_active: false },
      },
    })),

    [types.ACTIVATE_USER_LOCATION]: mergeDeep(({ payload }) => ({
      userLocationEntities: {
        [payload]: { is_active: true },
      },
    })),

    [types.ADD_LOCATION]: mergeDeep(({ payload }, state) => ({
      userLocationIds: [...state.userLocationIds, ...payload.userLocationIds],
      userLocationEntities: payload.userLocationEntities,
    })),

    [types.UPDATE_LOCATION]: mergeDeep(({ payload }) => ({
      userLocationEntities: {
        [payload.id]: payload,
      },
    })),

    [types.SET_PRIMARY_LOCATION]: mergeDeep(({ payload }, state) => ({
      userLocationEntities: {
        [payload.previousPrimaryLocation]: { is_primary: false },
        [payload.currentPrimaryLocation]: { is_primary: true },
      },
    })),
  },
  initialState,
);

export default userLocationsReducer;
