import { handleActions } from 'redux-actions';
import { mergeDeep, mergeIn } from '../../utils/stateHelper';
import * as types from './types';
import { RESET_GROUP_STATE } from '../constants';

const initialState = {
  isLoadingRewards: false,

  rewardEntities: {
    // 25: {
    //   app_action: "OPEN_PROFILE",
    //   title: "Reward Title",
    //   description: "When your referral upload his first item you will receive a reward",
    //   goal: 0,
    //   group_id: "49",
    //   id: 25,
    //   is_reached: false,
    //   kind: "count",
    //   progress: 0,
    //   reward_amount: 0,
    //   reward_type: "FIRST_ITEM_REFERRALS_USER",
    //   reward_type_id: 122,
    //   user_id: "3"
    // }
    // 152 :{
    //   reward_type_id: 20
    //   reward_type: "UPLOAD_FIRST_ITEM_COUNT"
    //   reward_amount: 0
    //   reward_interest: 50
    //   mean_price: 100
    //   max_reward_interest: 70
    //   title: "Upload 1st items"
    //   description: "About 80% of the items we own are not in use"
    //   goal: 1
    //   app_action: "OPEN_CREATE_ITEM"
    //   kind: "count"
    //   id: 152
    //   progress: 1
    //   group_id: "3"
    //   given_reward_amount: 5
    //   is_reached: true
    //   max_reward_amount: 70
    //   type: "flexible"
    // }
  },

  isLoadingRewardsProgress: false,
  isLoadingUserInfoRewards: false,

  rewardsProgress: [],
  userInfoRewards: [],
};

const reducer = handleActions(
  {
    [types.REWARDS_START]: mergeDeep(() => ({
      isLoadingRewards: true,
    })),

    [types.REWARDS_SUCCESS]: mergeDeep(({ payload }) => ({
      isLoadingRewards: false,
      rewardEntities: payload.rewardEntities,
      rewardIds: payload.rewardIds,
    })),

    [types.REWARDS_ERROR]: mergeDeep(() => ({
      isLoadingRewards: false,
    })),

    [types.UPDATE_REWARD]: mergeDeep(({ payload }) => ({
      rewardEntities: {
        [payload.reward.id]: payload.reward,
      },
    })),

    /** USER_INFO_REWARDS */
    [types.USER_INFO_REWARDS_START]: mergeDeep(() => ({
      isLoadingUserInfoRewards: true,
    })),

    [types.USER_INFO_REWARDS_SUCCESS]: mergeDeep(({ payload }) => ({
      isLoadingUserInfoRewards: false,
      userInfoRewards: payload,
    })),

    [types.USER_INFO_REWARDS_ERROR]: mergeDeep(() => ({
      isLoadingUserInfoRewards: false,
    })),

    /** REWARDS_PROGRESS */
    [types.REWARDS_PROGRESS_START]: mergeDeep(() => ({
      isLoadingRewardsProgress: true,
    })),

    [types.REWARDS_PROGRESS_SUCCESS]: mergeDeep(({ payload }) => ({
      isLoadingRewardsProgress: false,
      rewardsProgress: payload,
    })),

    [types.REWARDS_PROGRESS_ERROR]: mergeDeep(() => ({
      isLoadingRewardsProgress: false,
    })),

    [RESET_GROUP_STATE]: mergeIn({
      ...initialState,
    }),
  },
  initialState,
);

export default reducer;
