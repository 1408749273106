import React, { Fragment, memo } from 'react';
import T from 'prop-types';
import { TouchableItem, Text, Icon, FastImage } from '../../../../../../ReusableComponents';
import { colors } from '../../../../../../../styles';
import s from './style';

const SelectedMemberLabel = memo(({
  name,
  nickname,
  profileImage,
  onPress,
}) => {
  return (
    <TouchableItem
      style={s.container}
      onPress={onPress}
    >
      <Fragment>
        <FastImage
          source={{ uri: profileImage }}
          style={s.profileImage}
        />

        <Text color={colors.white}>{name}</Text>

        {!!nickname && (
          <Text color={colors.grayLighter} style={s.nickname}>
            {`@${nickname}`}
          </Text>
        )}

        <Icon
          name="x"
          size={18}
          style={s.icon}
          color={colors.white}
        />
      </Fragment>
    </TouchableItem>
  );
});

SelectedMemberLabel.propTypes = {
  name: T.string.isRequired,
  profileImage: T.string.isRequired,
  nickname: T.string,
  onPress: T.func,
};

SelectedMemberLabel.defaultProps = {
  nickname: null,
  onPress: null,
};

export default SelectedMemberLabel;
