import AsyncStorage from '@react-native-community/async-storage';

const ACCESS_TOKEN = 'ACCESS_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';

export default {
  setTokens(accessToken, refreshToken) {
    return AsyncStorage.multiSet([[ACCESS_TOKEN, accessToken], [REFRESH_TOKEN, refreshToken]]);
  },

  getAccessToken() {
    return AsyncStorage.getItem(ACCESS_TOKEN);
  },
  getRefreshToken() {
    return AsyncStorage.getItem(REFRESH_TOKEN);
  },

  removeTokens() {
    return AsyncStorage.multiRemove([ACCESS_TOKEN, REFRESH_TOKEN]);
  },
};
