const PREFIX = 'foundations/';

export const FOUNDATIONS_START = `${PREFIX}FOUNDATIONS_START`;
export const FOUNDATIONS_SUCCESS = `${PREFIX}FOUNDATIONS_SUCCESS`;
export const FOUNDATIONS_ERROR = `${PREFIX}FOUNDATIONS_ERROR`;

export const FOUNDATIONS_MORE_START = `${PREFIX}FOUNDATIONS_MORE_START`;
export const FOUNDATIONS_MORE_SUCCESS = `${PREFIX}FOUNDATIONS_MORE_SUCCESS`;

export const FOUNDATIONS_REFRESH_START = `${PREFIX}FOUNDATIONS_REFRESH_START`;
