import React, { memo, Fragment } from 'react';
import { connect } from 'react-redux';
import { SectionList, StyleSheet } from 'react-native';
import { rewardSelectors } from '../../../../store/rewards';
import {
  Container,
  CenteredView,
  Spinner,
  EmptyList,
  View,
  NavigationButton,
} from '../../../ReusableComponents';
import { Header } from './components';
import { colors, rs } from '../../../../styles';
import RewardsRow from './components/RewardsRow';
import SectionHeader from './components/SectionHeader';
import WelcomeRewardItem from './components/WelcomeRewardItem';
import { headerStyleTransparent } from '../../../../styles/headerStyle';
import strings from '../../../../localization';
import { getParamOr } from '../../../../utils/navHelper';

const s = StyleSheet.create({
  list: {
    backgroundColor: colors.white,
    ...rs.tabBarPadding,
  },
});

const Rewards = memo(
  ({ totalEarnedAmount, welcomeReward, isLoading, rewardsSectionList, communityInfo }) => {
    const IS_SOCIAL_DELIVERY = communityInfo.id === 3;

    return (
      <Container>
        <SectionList
          contentContainerStyle={s.list}
          // style={rs.backgroundWhite}
          sections={rewardsSectionList}
          numColumns={3}
          keyExtractor={(item) => item.id.toString()}
          initialNumToRender={20}
          stickySectionHeadersEnabled={false}
          renderSectionHeader={({ section: { type } }) => <SectionHeader type={type} />}
          renderItem={({ section, index }) => <RewardsRow section={section} index={index} />}
          // ListHeaderComponent={
          //   <Fragment>
          //     {!IS_SOCIAL_DELIVERY && <WelcomeRewardItem reward={welcomeReward} />}
          //     <Header totalEarnedAmount={totalEarnedAmount} />
          //   </Fragment>
          // }
          ListEmptyComponent={
            isLoading ? (
              <CenteredView>
                <Spinner />
              </CenteredView>
            ) : (
              <EmptyList text="There are no rewards available now" />
            )
          }
        />
      </Container>
    );
  },
);
export default connect(
  (state) => ({
    rewardsSectionList: rewardSelectors.getRewardsSectionList(state),
    totalEarnedAmount: rewardSelectors.getRewardsEarnedAmount(state),
    welcomeReward: rewardSelectors.getWelcomeReward(state),
    // totalMaxAmount: getRewardsMaxAmount(state),
    isLoading: state.rewards.isLoadingRewards,
    tokenName: '',
    communityInfo: state.communityInfo,
  }),
  (dispatch) => ({}),
)(Rewards);
