import React, { Fragment, memo } from 'react';
import * as R from 'ramda';
import { View, StyleSheet } from 'react-native';
import strings from '../../../../../localization';
import { Text, IconButton, FontIcon, TouchableItem } from '../../../../ReusableComponents';
import { dimensions, colors, rs } from '../../../../../styles';
import locationsTypes from '../../../../../constants/locationsTypes';
import { onOpenUserProfile } from '../../../../../store/notifications/notificationHelper';
import NavigationService from '../../../../../services/NavigationService';
import screens from '../../../../../navigation/screens';

const s = StyleSheet.create({
  addressContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainContainer: {
    width: '85%',
  },
  contentContainer: {
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.doubleMedium,
    backgroundColor: colors.white,
    justifyContent: 'center',
  },
  inactiveContainer: {
    opacity: 0.6,
  },
  shipperAddressInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  shipperInfoItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: dimensions.doubleMedium,
  },
  shipperInfoItemIconContainer: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: colors.grayLighter,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: dimensions.medium,
  },
  addressText: {
    lineHeight: 22,
  },
});

const ShipperInfoItem = memo(({ icon, title, isLightIcon }) => {
  return (
    <View style={s.shipperInfoItemContainer}>
      <View style={s.shipperInfoItemIconContainer}>
        <FontIcon
          withoutRTLScale
          color={isLightIcon ? colors.grayBorder : colors.gray}
          size={20}
          name={icon}
        />
      </View>
      <Text color={colors.gray}>{title}</Text>
    </View>
  );
});

const LocationItem = memo(({ location, onPressOption }) => {
  const onOpenUserProfile = ({ id, name, profile_image }) => {
    NavigationService.navigate(screens.UserProfile, {
      name,
      userId: id,
      profile_image,
    });
  };

  const getAvailabilityLabel = (availability) => {
    const availabilitiesMap = {
      0: strings.shipping.daily,
      1: strings.shipping.weekly,
      2: strings.shipping.bi_weekly,
      3: strings.shipping.monthly,
      4: strings.shipping.randomly,
    };

    return availabilitiesMap[availability] || availability;
  };

  const availabilityLabel = getAvailabilityLabel(location.availability);

  return (
    <Fragment>
      <View style={[s.contentContainer, !location.is_active && s.inactiveContainer]}>
        {location.is_primary && (
          <Text semiBold medium color={colors.text} style={rs.smallMarginBottom}>
            {strings.locations.primary_address}
          </Text>
        )}
        {!location.is_active && (
          <Text color={colors.gray} style={rs.smallMarginBottom} semiBold xsmall>
            {strings.locations.deactivated}
          </Text>
        )}
        <View style={s.addressContainer}>
          <View style={s.mainContainer}>
            <Text color={colors.gray} style={s.addressText}>
              {location.formatted_address}
            </Text>
            {location.type === locationsTypes.SHIPPER && (
              <Fragment>
                <View style={s.shipperAddressInfo}>
                  <ShipperInfoItem
                    title={location.shippers_count}
                    icon="Pickup_Shipper-3"
                    isLightIcon
                  />
                  <ShipperInfoItem title={location.price} icon={'Token'} isLightIcon />
                  <ShipperInfoItem
                    title={availabilityLabel}
                    icon={'Pickup_availability-2'}
                    isLightIcon
                  />
                </View>
                {location.shipper_profiles && !R.isEmpty(location.shipper_profiles) && (
                  <View style={rs.paddingTop}>
                    <Text>{strings.locations.shippers}:</Text>

                    <View style={rs.row}>
                      {location.shipper_profiles.map(({ id, name, profile_image }) => (
                        <TouchableItem
                          onPress={() => onOpenUserProfile({ id, name, profile_image })}
                        >
                          <Text color={colors.blueLight}>{`@${name}`}, </Text>
                        </TouchableItem>
                      ))}
                    </View>
                  </View>
                )}
                {!!location.internal_note && (
                  <View style={rs.paddingTop}>
                    <Text>{strings.bank.note}:</Text>
                    <Text color={colors.gray}>{location.internal_note}</Text>
                  </View>
                )}
              </Fragment>
            )}
          </View>

          <IconButton
            isMaterialCommunityIcon
            name="dots-vertical"
            color={colors.gray}
            size={dimensions.iconSize}
            onPress={() => onPressOption(location)}
          />
        </View>
      </View>
    </Fragment>
  );
});
export default LocationItem;
