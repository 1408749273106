import { Linking, Platform } from 'react-native';
import { PACKAGE_NAME, APP_STORE_ID } from '../../config/identifiers';

export const openInStore = async () => {
  const APP_STORE_LINK = `https://apps.apple.com/app/id${APP_STORE_ID}`;
  const PLAY_STORE_LINK = `market://details?id=${PACKAGE_NAME}`;

  if (Platform.OS === 'ios') {
    Linking.openURL(APP_STORE_LINK);
  } else {
    Linking.openURL(PLAY_STORE_LINK);
  }
};
