import * as R from 'ramda';
import actions from './actions';
import groupsApi from '../../api/groups';
import { ACCOUNT_SUSPENDED_BY_ADMIN } from '../../../config/appConstants/httpCodes';
import { LoadingService, ModalsService, NavigationService, ToastsService } from '../../services';
import screens from '../../navigation/screens';
import { normalizeCommunities, normalize } from '../../utils/stateHelper';
import AnalyticsService from '../../services/AnalyticsService';
import { appOperations } from '../app';
import { lotsOperations } from '../lots';
import strings from '../../localization';
import modalTypes from '../../constants/modalTypes';

const sendJoinRequest = (groupId) => async (dispatch, getState) => {
  const { userInfo, groups } = getState();
  const userId = userInfo.id;

  const { is_open, is_rejoin_member } = groups.communityEntities[groupId];

  try {
    LoadingService.showSuccessLoader();
    if (is_rejoin_member) {
      await groupsApi.sendRejoinRequest(groupId, userId);
    } else {
      await groupsApi.sendJoinRequest(groupId, userId);
    }
    if (is_open || is_rejoin_member) {
      dispatch(actions.userBecomeCommunityMember({ groupId }));
      dispatch(actions.increaseMembersCount({ groupId }));
    } else {
      dispatch(actions.changeStatusToPendingApproval({ groupId }));
    }
    LoadingService.hideSuccessLoader();
  } catch (e) {}
};

const joinCommunity = (communityId) => async (dispatch, getState) => {
  const { groups } = getState();

  const {
    group_name,
    token_icon,
    group_member_count,
    has_questions,
    is_open,
  } = groups.communityEntities[communityId];

  if (!has_questions) {
    dispatch(sendJoinRequest(communityId));
  } else {
  }
};

const showNotCommunityMemberModal = (communityId) => async (dispatch, getState) => {
  const { groups } = getState();

  const {
    group_name,
    token_icon,
    group_member_count,
    has_questions,
    is_open,
  } = groups.communityEntities[communityId];

  if (!has_questions) {
    dispatch(sendJoinRequest(communityId));
  } else {
  }
};

export const getCommunitiesList = () => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    const data = await groupsApi.getCommunities(userId);

    const normalizedCommunities = normalizeCommunities(data, 'communityIds', 'communityEntities');

    dispatch(actions.setCommunities(normalizedCommunities));
  } catch (e) {
    // ToastsService.showError(strings.error_messages.get_rules);
    if (R.path(['response', 'status'], e) === ACCOUNT_SUSPENDED_BY_ADMIN) {
      NavigationService.reset(screens.SuspendedUser);
    }
  }
};

export const getJoinedCommunities = () => async (dispatch) => {
  try {
    const data = await groupsApi.getJoinedCommunities();

    const normalizedCommunities = normalizeCommunities(data, 'communityIds', 'communityEntities');

    dispatch(actions.setJoinedCommunities(normalizedCommunities));
  } catch (e) {
    // ToastsService.showError(strings.error_messages.get_rules);
    if (R.path(['response', 'status'], e) === ACCOUNT_SUSPENDED_BY_ADMIN) {
      NavigationService.reset(screens.SuspendedUser);
    }
  }
};

export const getCommunityGroups = () => async (dispatch, getState) => {
  const { communityInfo } = getState();

  try {
    const data = await groupsApi.getCommunityGroups(communityInfo.id);

    const normalizedGroups = normalize(data, 'groupIds', 'groupEntities');

    dispatch(actions.setCommunityGroups(normalizedGroups));
  } catch (e) {
    // ToastsService.showError(strings.error_messages.get_rules);
    if (R.path(['response', 'status'], e) === ACCOUNT_SUSPENDED_BY_ADMIN) {
      NavigationService.reset(screens.SuspendedUser);
    }
  }
};

export const getCommunityInfo = () => async (dispatch, getState) => {
  const { groups, userInfo } = getState();
  const communityId = groups.insideCommunityId;
  const userId = userInfo.id;

  if (R.isNil(communityId)) return;

  try {
    const data = await groupsApi.getGroupInfo(communityId, userId);

    dispatch(actions.setCommunityInfo(data));
  } catch (e) {
    // ToastsService.showError(strings.error_messages.get_rules);
    if (R.path(['response', 'status'], e) === ACCOUNT_SUSPENDED_BY_ADMIN) {
      NavigationService.reset(screens.SuspendedUser);
    }
  }
};

export const getCommunityInfoByCommunityId = (communityId) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    const data = await groupsApi.getGroupInfo(communityId, userId);

    return dispatch(actions.setCommunityInfo(data));
  } catch (e) {}
};

const approveCommunityRules = (communityId) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    await groupsApi.approveCommunityRules(communityId, userId);
    dispatch(actions.approveCommunityRules(communityId));
  } catch (e) {
    // ToastsService.showError(strings.error_messages.get_rules);
  }
};

const approveItemRules = (communityId) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    await groupsApi.approveItemRules(communityId, userId);
    dispatch(actions.approveItemRules(communityId));
  } catch (e) {
    // ToastsService.showError(strings.error_messages.get_rules);
  }
};

const approveWishRules = (communityId) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    await groupsApi.approveWishRules(communityId, userId);
    dispatch(actions.approveWishRules(communityId));
  } catch (e) {
    // ToastsService.showError(strings.error_messages.get_rules);
  }
};

const setInsideCommunityId = (communityId) => async (dispatch) => {
  dispatch(actions.setInsideCommunityId(communityId));
  dispatch(appOperations.resetGroupState());
};

const setAllCommunitiesOnFeed = () => async (dispatch) => {
  dispatch(actions.setAllCommunitiesOnFeed(true));
  dispatch(appOperations.resetGroupState());

  setTimeout(() => {
    AnalyticsService.setGroupId(null);
    dispatch(lotsOperations.getItems());
  }, 1);
};

const getJoinGroupQuestions = (groupId) => async () => {
  try {
    return await groupsApi.getJoinGroupQuestions(groupId);
  } catch (e) {}
};

const createJoinGroupAnswers = (groupId, answers) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;
  try {
    await groupsApi.createJoinGroupAnswers(groupId, userId, answers);
  } catch (e) {}
};

const getJoinGroupAnswers = (userId, communityId) => async () => {
  try {
    return await groupsApi.getJoinGroupAnswers(communityId, userId);
  } catch (e) {}
};

const leaveCommunity = (communityId) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    await groupsApi.leaveCommunity(communityId, userId);

    dispatch(actions.userLeftCommunity({ communityId }));
    dispatch(lotsOperations.getItems(true));
  } catch (e) {}
};

const updateGeneralCommunityInfo = ({
  group_id,
  group_name,
  background_image,
  token_name,
  token_icon,
}) => async (dispatch) => {
  try {
    LoadingService.showSuccessLoader();
    await groupsApi.updateGeneralCommunityInfo({
      group_id,
      group_name,
      background_image,
      token_name,
      token_icon,
    });
    LoadingService.hideSuccessLoader({
      callback: () => {
        NavigationService.goBack();
      },
    });
  } catch (e) {
    LoadingService.hideSuccessLoader({ isSuccess: false });
  }
};

const checkTokenNameAvailability = (tokenName) => async (dispatch) => {
  try {
    const { is_available } = await groupsApi.checkTokenNameAvailability(tokenName);
    return is_available;
  } catch (e) {}
};

const getCategoryTemplates = () => async (dispatch) => {
  try {
    const data = await groupsApi.getCategoryTemplates();
    return data;
  } catch (e) {}
};

const createCommunity = (params) => async (dispatch) => {
  try {
    LoadingService.showSuccessLoader();
    const createdCommunity = await groupsApi.createCommunity(params);

    LoadingService.hideSuccessLoader({
      callback: () => {
        dispatch(setInsideCommunityId(createdCommunity.group_id));
        dispatch(actions.setCommunityInfo(createdCommunity));
        dispatch(getCommunitiesList());
      },
    });
  } catch (e) {
    LoadingService.hideSuccessLoader({ isSuccess: false });
  }
};

export default {
  sendJoinRequest,
  joinCommunity,
  setInsideCommunityId,
  getCommunitiesList,
  getJoinedCommunities,
  getCommunityInfo,
  getCommunityInfoByCommunityId,
  approveCommunityRules,
  approveItemRules,
  approveWishRules,
  getJoinGroupQuestions,
  createJoinGroupAnswers,
  getJoinGroupAnswers,
  userBecomeCommunityMember: actions.userBecomeCommunityMember,
  increaseMembersCount: actions.increaseMembersCount,
  markCommunityAsVisited: actions.markCommunityAsVisited,
  setAllCommunitiesOnFeed,
  leaveCommunity,
  updateGeneralCommunityInfo,
  checkTokenNameAvailability,
  getCategoryTemplates,
  createCommunity,
  getCommunityGroups,
};
