import React, { PureComponent } from 'react';
import T from 'prop-types';
import {
  CategoriesContainer,
  CategoriesList,
  CategoryButton,
  CategoryText,
  CheckIcon,
} from './styles';
import { colors } from '../../../styles';
import FontIcon from '../FontIcon';

class Categories extends PureComponent {
  render() {
    const {
      parentCategories,
      selectedCategoryIds,
      onSelectCategory,
      onSelectCategoryObject,
      isShowCheckIcon = true,
    } = this.props;

    return (
      <CategoriesContainer>
        <CategoriesList
          data={parentCategories}
          keyExtractor={(item) => item.id.toString()}
          renderItem={({ item }) => {
            const isActive = selectedCategoryIds.includes(item.id);
            // const isActive = Number(item.id) === Number(activeParentCategoryId);
            return (
              <CategoryButton
                onPress={
                  onSelectCategory
                    ? () => onSelectCategory(item.id)
                    : () => onSelectCategoryObject(item)
                }
              >
                {isActive && isShowCheckIcon && (
                  <CheckIcon name="check-circle" size={14} color={colors.blueLighter} />
                )}
                <FontIcon
                  name={item.icon_name}
                  size={28}
                  color={isActive ? colors.blueLighter : colors.text}
                />

                <CategoryText xsmall isActive={isActive}>
                  {item.name}
                </CategoryText>
              </CategoryButton>
            );
          }}
        />
      </CategoriesContainer>
    );
  }
}

Categories.propTypes = {
  parentCategories: T.array,
  selectedCategoryIds: T.array,
};

export default Categories;
