import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import T from 'prop-types';
import { RefreshControl, View, ScrollView } from 'react-native';
import UserItemsTabBar from './UserItemsTabBar';
import CurrentUserItemsTabBar from './CurrentUserItemsTabBar';
import {
  EmptyListWithIcon,
  EmptyListWithImage,
  LotsList,
  Separator,
} from '../../../ReusableComponents';
import { lotsOperations, lotsSelectors } from '../../../../store/lots';
import strings from '../../../../localization';
import { rs } from '../../../../styles';
import itemTypes from '../../../../constants/itemTypes';
import { sortingTypes } from '../../../../constants/sortingTypes';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';
import { isWeb } from '../../../../utils/detectDevice';

const UserItems = ({
  isCurrentUserItems,
  renderHeader,
  sharedCommunityIds,
  onRefresh,
  doubleColumnLists,
  getUserWishes,
  getUserActiveItems,
  getUserSoldItems,
  getUserPurchasedItems,
  isShowPurchasedItems = false,

  userActiveItems,
  userSoldItems,
  userPurchasedItems,
  userWishes,
  resetUserLots,
  defaultTabIndex = 0,
  sortingType = sortingTypes.by_newest,
}) => {
  const flatListRef = useRef(null);
  const [index, setIndex] = useState(defaultTabIndex);

  const [isRefreshing, setRefreshing] = useState(false);

  useEffect(() => {
    getUserActiveItems(sharedCommunityIds, sortingType);
    getUserSoldItems(sharedCommunityIds, sortingType);
    getUserWishes(sharedCommunityIds, sortingType);
    if (isShowPurchasedItems) getUserPurchasedItems(sharedCommunityIds, sortingType);
  }, [sharedCommunityIds, sortingType, isRefreshing]);

  useEffect(() => {
    return () => {
      if (isCurrentUserItems) {
        resetUserLots();
      }
      // TODO uncomment when search or filtering will be available for user profile
      // else {
      //
      //   getUserActiveItems(sharedCommunityIds, sortingType);
      //   getUserSoldItems(sharedCommunityIds, sortingType);
      //   getUserWishes(sharedCommunityIds, sortingType);
      //   if (isShowPurchasedItems) getUserPurchasedItems(sharedCommunityIds, sortingType);
      // }
    };
  }, []);

  const onRefreshItems = async () => {
    setRefreshing(true);
    if (!R.isNil(onRefresh)) {
      await onRefresh();
    } else {
      await Promise.all([
        getUserActiveItems(sharedCommunityIds, sortingType),
        getUserSoldItems(sharedCommunityIds, sortingType),
        getUserWishes(sharedCommunityIds, sortingType),
        isShowPurchasedItems ? getUserPurchasedItems(sharedCommunityIds, sortingType) : null,
      ]);
    }
    setRefreshing(false);
  };

  const onSelectTab = (id) => {
    setIndex(id);
    flatListRef.current.scrollToOffset({ animated: false, offset: 0 });
  };

  const info = {
    0: {
      data: userActiveItems.data,
      emptyText: isCurrentUserItems
        ? strings.emptyLists.empty_selling_offers_list
        : strings.emptyLists.emptyUserItemList,
      emptyButtonText: strings.emptyLists.upload_an_item,
      emptyImage: isCurrentUserItems
        ? 'empty_state_your_items_for_sale'
        : 'empty_state_your_items_for_sale',
      navigationScreenName: screens.EditItemStack,

      isLoading: userActiveItems.isLoading,
      isLoadingMore: userActiveItems.isLoadingMore,
      onLoadMore: () => getUserActiveItems(sharedCommunityIds, sortingType, true),
      itemType: itemTypes.ACTIVE,
      key: 'id',
    },
    1: {
      data: userSoldItems.data,
      emptyText: isCurrentUserItems
        ? strings.emptyLists.empty_selling_offers_list
        : strings.emptyLists.emptyUserSoldList,
      emptyButtonText: strings.emptyLists.upload_an_item,
      emptyImage: isCurrentUserItems
        ? 'empty_state_your_items_sold'
        : 'empty_state_your_items_sold',
      navigationScreenName: screens.EditItemStack,

      isLoading: userSoldItems.isLoading,
      isLoadingMore: userSoldItems.isLoadingMore,
      onLoadMore: () => getUserSoldItems(sharedCommunityIds, sortingType, true),
      itemType: itemTypes.SOLD,
      key: 'offer_id',
    },
    2: {
      data: userWishes.data,
      emptyText: isCurrentUserItems
        ? strings.emptyLists.empty_current_user_wishes_list
        : strings.emptyLists.emptyWishesList,
      emptyButtonText: strings.wishes.upload_a_wish,
      emptyImage: 'empty_state_your_items_wishes',
      navigationScreenName: screens.EditWishStack,

      isLoading: userWishes.isLoading,
      isLoadingMore: userWishes.isLoadingMore,
      onLoadMore: () => getUserWishes(sharedCommunityIds, sortingType, true),
      key: 'id',
    },
    3: {
      data: userPurchasedItems.data,
      emptyText: isCurrentUserItems
        ? strings.emptyLists.buy_without_spending_money
        : strings.emptyLists.emptyUserPurchasedList,
      emptyImage: isCurrentUserItems
        ? 'empty_state_your_items_purchased'
        : 'empty_state_your_items_purchased',
      navigationScreenName: screens.FeedTab,

      emptyButtonText: strings.emptyLists.start_shopping,
      isLoading: userPurchasedItems.isLoading,
      isLoadingMore: userPurchasedItems.isLoadingMore,
      onLoadMore: () => getUserPurchasedItems(sharedCommunityIds, sortingType, true),
      itemType: itemTypes.PURCHASED,
      key: 'offer_id',
    },
  }[index];

  const ListHeaderComponent = () =>
    !isCurrentUserItems && (
      <Fragment>
        {renderHeader && renderHeader()}
        <UserItemsTabBar
          activeTabIndex={index}
          onTabPress={onSelectTab}
          activeItemsTotalAmount={userActiveItems.totalAmount}
          wishesTotalAmount={userWishes.totalAmount}
          soldItemsTotalAmount={userSoldItems.totalAmount}
          isActiveItemsLoading={userActiveItems.isLoading}
          isWishesLoading={userWishes.isLoading}
          isSoldItemsLoading={userSoldItems.isLoading}
        />
      </Fragment>
    );

  const ListEmptyComponent = useCallback(
    () => (
      <EmptyListWithImage
        text={info.emptyText}
        image={info.emptyImage}
        buttonTitle={isCurrentUserItems ? info.emptyButtonText : null}
        onPress={() => NavigationService.navigate(info.navigationScreenName)}
      />
    ),
    [info.emptyText, info.navigationScreenName, isCurrentUserItems],
  );

  const Wrapper = isWeb ? ScrollView : Fragment;

  return (
    <Wrapper contentContainerStyle={isWeb && rs.webContainer}>
      {isCurrentUserItems && (
        <CurrentUserItemsTabBar
          activeTabIndex={index}
          onTabPress={onSelectTab}
          activeItemsTotalAmount={userActiveItems.totalAmount}
          wishesTotalAmount={userWishes.totalAmount}
          purchasedItemsTotalAmount={userPurchasedItems.totalAmount}
          soldItemsTotalAmount={userSoldItems.totalAmount}
          showPurchasedItems={isShowPurchasedItems}
          isActiveItemsLoading={userActiveItems.isLoading}
          isWishesLoading={userWishes.isLoading}
          isSoldItemsLoading={userSoldItems.isLoading}
          isPurchasedItemsLoading={userPurchasedItems.isLoading}
        />
      )}
      <LotsList
        analyticsEventType={
          info.itemType === itemTypes.ACTIVE ? analyticsEventTypes.user_profile_select_item : null
        }
        onEndReachedThreshold={0.5}
        ListHeaderComponent={ListHeaderComponent}
        ListEmptyComponent={ListEmptyComponent}
        forwardedRef={flatListRef}
        extraData={index}
        doubleColumnLists={doubleColumnLists}
        keyExtractor={(item) => item[info.key]}
        // data={info.isLoading && !isRefreshing ? [] : info.data}
        data={info.isLoading && !isRefreshing ? [] : info.data}
        isLoading={info.isLoading}
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefreshItems} />}
        refreshing={isRefreshing}
        isLoadingMore={info.isLoadingMore}
        onLoadMore={() => info.onLoadMore()}
        itemType={info.itemType}
        contentContainerStyle={[!isCurrentUserItems && { paddingTop: 0 }]}
      />
    </Wrapper>
  );
};

UserItems.propTypes = {
  doubleColumnLists: T.bool.isRequired,
  renderHeader: T.func,
  sharedCommunityIds: T.array,
  getUserWishes: T.func.isRequired,
  getUserActiveItems: T.func.isRequired,
  getUserSoldItems: T.func.isRequired,
  getUserPurchasedItems: T.func.isRequired,
  isShowPurchasedItems: T.bool,
  isCurrentUserItems: T.bool,
  onRefresh: T.func,
  userActiveItems: T.shape({
    data: T.array.isRequired,
    isLoading: T.bool.isRequired,
    isLoadingMore: T.bool.isRequired,
    totalAmount: T.number.isRequired,
  }),
  userSoldItems: T.shape({
    data: T.array.isRequired,
    isLoading: T.bool.isRequired,
    isLoadingMore: T.bool.isRequired,
    totalAmount: T.number.isRequired,
  }),
  userPurchasedItems: T.shape({
    data: T.array.isRequired,
    isLoading: T.bool.isRequired,
    isLoadingMore: T.bool.isRequired,
    totalAmount: T.number.isRequired,
  }),
  userWishes: T.shape({
    data: T.array.isRequired,
    isLoading: T.bool.isRequired,
    isLoadingMore: T.bool.isRequired,
    totalAmount: T.number.isRequired,
  }),
  resetUserLots: T.func,
  defaultTabIndex: T.number,
};

export default connect(
  (state, { userId }) => ({
    doubleColumnLists: state.app.doubleColumnLists,
    userActiveItems: lotsSelectors.getUserActiveItemsData(state, userId),
    userSoldItems: lotsSelectors.getUserSoldItemsData(state, userId),
    userPurchasedItems: lotsSelectors.getUserPurchasedItemsData(state, userId),
    userWishes: lotsSelectors.getUserWishesData(state, userId),
  }),
  (dispatch, { userId, isCurrentUserItems }) => {
    return {
      resetUserLots: () => dispatch(lotsOperations.resetUserLots(userId)),
      getUserWishes: (sharedCommunityIds, sortBy, isLoadMore = false) =>
        dispatch(
          lotsOperations.getUserWishes({
            userId,
            communityIds: sharedCommunityIds,
            isLoadMore,
            sortBy,
            isCurrentUser: isCurrentUserItems,
          }),
        ),
      getUserActiveItems: (sharedCommunityIds, sortBy, isLoadMore = false) =>
        dispatch(
          lotsOperations.getUserActiveItems({
            userId,
            communityIds: sharedCommunityIds,
            isLoadMore,
            sortBy,
          }),
        ),
      getUserSoldItems: (sharedCommunityIds, sortBy, isLoadMore = false) =>
        dispatch(
          lotsOperations.getUserSoldItems({
            userId,
            communityIds: sharedCommunityIds,
            isLoadMore,
            sortBy,
          }),
        ),
      getUserPurchasedItems: (sharedCommunityIds, sortBy, isLoadMore = false) =>
        dispatch(
          lotsOperations.getUserPurchasedItems({
            userId,
            communityIds: sharedCommunityIds,
            isLoadMore,
            sortBy,
          }),
        ),
    };
  },
)(UserItems);
