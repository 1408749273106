import React, { Fragment } from 'react';
import styled from 'styled-components/native';
import Text from '../Text';
import TouchableItem from '../TouchableItem';
import { colors, dimensions, fontSizes } from '../../../styles';

const ButtonContainer = styled(TouchableItem)`
  height: ${({ small }) => (small ? 26 : 40)};
  align-self: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-start')};
  justify-content: center;
`;

const ButtonText = styled(Text)`
  color: ${({ color }) => color || colors.blue}
  font-size: ${({ small }) => (small ? fontSizes.small : fontSizes.medium)}
`;

const TextButton = ({ disabled, title, onPress, small, color, alignCenter }) => (
  <ButtonContainer disabled={disabled} onPress={onPress} small={small} alignCenter={alignCenter}>
    <ButtonText disabled={disabled} color={color} small={small}>
      {title}
    </ButtonText>
  </ButtonContainer>
);

export default TextButton;
