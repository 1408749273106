import api from './api';

export default {
  signIn: ({ phone_number, campaign_name, conversion_type }) =>
    api.post('signIn', {
      phone_number,
      campaign_name,
      conversion_type,
    }),

  codeVerification: (phoneNumber, code, deviceId, isWeb = false) =>
    api.post('codeVerification', {
      user_device_id: deviceId,
      phone_number: phoneNumber,
      code,
      is_web: isWeb,
    }),

  checkAuthentication: ({ accessToken, appVersion, platform }) =>
    api.post('checkAuthentication', {
      access_token: accessToken,
      app_version: appVersion,
      platform,
    }),

  refreshToken: (refresh_token) =>
    api.post('refreshToken', {
      refresh_token,
    }),

  applyLegalDocumentsUpdate: () => api.post('applyLegalDocumentsUpdate', {}),

  generateAuthTokens: (userId, userDeviceId) =>
    api.post('generateAuthTokens', {
      user_id: userId,
      user_device_id: userDeviceId,
    }),
};
