import React, { memo, Fragment } from 'react';
import { RefreshControl, StyleSheet, View, FlatList } from 'react-native';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../Spinner';
import TripleColumnsLotListItem from '../ListItems/Lots/TripleColumnsLotListItem';
import CenteredView from '../Containers/CenteredView';
import { EmptyListWithIcon } from '../EmptyList';
import strings from '../../../localization';
import { dimensions, colors, rs } from '../../../styles';
import DoubleColumnsLotListItem from '../ListItems/Lots/DoubleColumnsLotListItem';
import itemTypes from '../../../constants/itemTypes';
import { NavigationService } from '../../../services';
import screens from '../../../navigation/screens';
import { formatDoubleColumns, formatTripleColumns } from '../../../utils/multipleListColumsHelper';
import { lotsOperations } from '../../../store/lots';

const s = StyleSheet.create({
  contentContainerStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: dimensions.width,
    justifyContent: 'center',
  },
});

const LotsSectionGrid = memo(({ data, contentContainerStyle, ...listProps }) => {
  const userId = useSelector((state) => state.userInfo.id);
  const dispatch = useDispatch();

  let lastCentralIndex = 1;
  const getIsCentral = (index) => {
    if (index === 1 || lastCentralIndex + 3 === index) {
      lastCentralIndex = index;
      return true;
    }
    return false;
  };

  const onPress = (lot) => {
    if (lot.is_wish) {
      NavigationService.push(screens.WishDetails, { wishId: lot.id });
    } else {
      NavigationService.push(screens.ItemDetails, { itemId: lot.id });
    }
  };

  const onToggleFavorites = (item) => {
    const { mark_as_favorite } = item;

    if (mark_as_favorite) {
      dispatch(lotsOperations.removeItemFromFavorites(item.id));
    } else {
      dispatch(lotsOperations.addItemToFavorites(item.id));
    }
  };

  return (
    <View style={s.contentContainerStyle}>
      {data.map((lot, index) => (
        <TripleColumnsLotListItem
          key={lot.id}
          onPress={onPress}
          onToggleFavorites={onToggleFavorites}
          lot={lot}
          index={index}
          isCentral={getIsCentral(index)}
          currentUserId={userId}
        />
      ))}
    </View>
  );
});

LotsSectionGrid.propTypes = {
  data: T.array,
  isLoading: T.bool,
  ListHeaderComponent: T.node,
  emptyText: T.string,
  doubleColumnLists: T.bool,
  isLoadingMore: T.bool,
  onLoadMore: T.func,
  isRefreshing: T.bool,
  onRefresh: T.func,
  isSoldList: T.bool,
};

export default LotsSectionGrid;
