import * as actions from './actions';
import { normalize } from '../../utils/stateHelper';
import communitiesApi from '../../api/communities';
import { COMMON_LIMIT } from '../../constants/listLimits';

export const getCommunityCampuses = ({ searchTerm = '', isLoadMore }) => async (
  dispatch,
  getState,
) => {
  const { ids, isLoading, isLoadingMore, isExistMore } = getState().campuses;
  if (isLoading || isLoadingMore || (isLoadMore && !isExistMore)) return;

  dispatch(actions.campusesStart({ isLoadMore }));

  const skip = isLoadMore ? ids.length : 0;

  try {
    const data = await communitiesApi.getCampuses({ skip, searchTerm });

    const normalizedData = normalize(data, 'ids', 'entities');

    dispatch(
      actions.campusesSuccess({
        ...normalizedData,
        isLoadMore,
        isExistMore: data.length === COMMON_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(actions.campusesError({ isLoadMore }));
  }
};

export default {
  getCommunityCampuses,
};
