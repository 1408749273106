import { StyleSheet } from 'react-native';
import { dimensions, colors } from '../../../../styles';

export default StyleSheet.create({
  contentContainer: {
    // transform: [{ scaleX: -1 }],
    // alignItems: 'flex-end',
  },
  list: {
    backgroundColor: colors.grayBackground,
    height: dimensions.additionalAppBarLayerHeight,
  },
  listContentContainer: {
    paddingHorizontal: dimensions.medium - 4,
    alignItems: 'center',
    minWidth: '100%',
  },
});
