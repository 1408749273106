import React, { memo, useCallback, useRef, useState, useEffect, Fragment } from 'react';
import { getDistance } from 'geolib';
import * as R from 'ramda';
import MapView from 'react-native-maps';
import Carousel from 'react-native-snap-carousel';
import { StyleSheet, Platform, View } from 'react-native';
import T from 'prop-types';
import { colors, rs, dimensions } from '../../../styles';
import { isIphoneX, isAndroid } from '../../../utils/detectDevice';
import { isRTL } from '../../../utils/rtlHelper';
import strings from '../../../localization';
import customMapStyle from '../../../constants/googleMapCustomStyles';
import Text from '../Text';
import locationsTypes from '../../../constants/locationsTypes';
import CarouselPagination from '../CarouselPagination';
import MapMarker from '../MapMarker';
import { FontIcon } from '../';

const MARKER_PRECISION_FIX = 1 / 10000;
const ZONE_RADIUS = 200;
const CARD_WIDTH = dimensions.width - 80;
const DEFAULT_EDGE_PADDING = { top: 50, right: 50, bottom: 250, left: 50 };
const isNeedRTLFixForAndroid = isRTL && isAndroid;

const styles = StyleSheet.create({
  carouselContainer: {
    position: 'absolute',
    bottom: isIphoneX ? dimensions.medium * 3 : dimensions.doubleMedium,
  },
  cardContainer: {
    paddingVertical: dimensions.halfMedium,
    paddingHorizontal: 4,
    width: CARD_WIDTH,
    flex: 1,
    justifyContent: 'flex-end',
  },
  card: {
    backgroundColor: colors.white,
    borderRadius: 12,
    justifyContent: 'center',

    ...Platform.select({
      ios: {
        shadowColor: colors.black,
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
      },
      android: {
        elevation: 4,
      },
    }),
  },
  cardTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.grayLighter,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    paddingHorizontal: dimensions.medium,
    paddingTop: dimensions.large,
    paddingBottom: dimensions.halfXLarge,
  },
  cardInner: {
    padding: dimensions.medium,
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infoItem: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoCircle: {
    width: 32,
    height: 32,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.grayLighter,
  },
});

const CardInfoItem = ({ icon, title, subtitle }) => {
  return (
    <View style={styles.infoItem}>
      <View style={[styles.infoCircle, rs.smallMarginRight]}>
        <FontIcon name={icon} size={20} color={colors.grayBorder} withoutRTLScale />
      </View>

      <View style={[rs.column, rs.justifyCenter, rs.flex]}>
        <Text numberOfLines={1} color={colors.blueLight} semiBold>
          {title}
        </Text>
        {!!subtitle && <Text color={colors.grayBorder}>{subtitle}</Text>}
      </View>
    </View>
  );
};

const LocationItem = memo(({ item }) => {
  const hasNearestBuyerLocation = R.hasPath(['nearestBuyerLocation'], item);
  const getAvailabilityLabel = (availability) => {
    const availabilitiesMap = {
      0: strings.shipping.daily,
      1: strings.shipping.weekly,
      2: strings.shipping.bi_weekly,
      3: strings.shipping.monthly,
      4: strings.shipping.randomly,
    };

    return availabilitiesMap[availability] || availability;
  };

  const availabilityLabel = getAvailabilityLabel(item.availability);

  return (
    <View style={[styles.cardContainer]} key={item.id}>
      <View style={styles.card}>
        <View style={styles.cardTitle}>
          {item.type === locationsTypes.SHIPPER ? (
            <Fragment>
              <FontIcon name="scooter" size={22} color={colors.secondary} style={rs.marginRight} />
              <Text color={colors.secondary} medium semiBold>
                {strings.shipping.shippers_delivery}
              </Text>
            </Fragment>
          ) : (
            <Fragment>
              <FontIcon
                withoutRTLScale
                name="Shipping_personal-pickup"
                size={22}
                color={colors.secondary}
                style={rs.marginRight}
              />
              <Text color={colors.secondary} medium semiBold>
                {strings.shipping.meet_in_person}
              </Text>
            </Fragment>
          )}
        </View>

        <View style={styles.cardInner}>
          {item.type === locationsTypes.USER && (
            <View style={styles.infoContainer}>
              {hasNearestBuyerLocation && (
                <CardInfoItem
                  icon="Settings_Location"
                  title={`${
                    item.nearestBuyerLocation.distance === 0
                      ? '<0.1'
                      : item.nearestBuyerLocation.distance
                  }km`}
                />
              )}
              <CardInfoItem icon="Location_Pin" title={item.city} />
            </View>
          )}

          {item.type === locationsTypes.SHIPPER && (
            <Fragment>
              <View style={styles.infoContainer}>
                <CardInfoItem
                  icon="Pickup_Shipper-3"
                  title={item.shippers_count}
                  subtitle={strings.locations.shippers}
                />
                <CardInfoItem icon="Pickup_availability-2" title={availabilityLabel} />
              </View>

              <View style={[styles.infoContainer, rs.bigMarginTop]}>
                <CardInfoItem
                  icon="Token"
                  title={item.price}
                  subtitle={strings.locations.price_in_tokens}
                />
                <CardInfoItem icon="Location_Pin" title={item.city} />
              </View>
            </Fragment>
          )}
        </View>
      </View>
    </View>
  );
});

const LocationsCarousel = memo(
  ({ forwardRef, locations, onCenterMapOnMarker, selectedMarketIndex }) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const snapToItem = useCallback((index) => {
      const fixedIndex = isNeedRTLFixForAndroid ? locations.length - 1 - index : index;

      setActiveSlideIndex(fixedIndex);
      onCenterMapOnMarker(locations[fixedIndex]);
    });

    return (
      <View style={styles.carouselContainer}>
        <Carousel
          ref={forwardRef}
          data={locations}
          renderItem={({ item, index }) => (
            <LocationItem item={item} index={index} onCenterMapOnMarker={onCenterMapOnMarker} />
          )}
          sliderWidth={dimensions.width}
          itemWidth={CARD_WIDTH}
          firstItem={isNeedRTLFixForAndroid ? locations.length - 1 : 0}
          // snapOnAndroid
          onSnapToItem={snapToItem}
          showsHorizontalScrollIndicator={false}
          inactiveSlideScale={0.95}
          inactiveSlideOpacity={0.9}
          removeClippedSubviews={false}
          // https://github.com/meliorence/react-native-snap-carousel/blob/master/doc/KNOWN_ISSUES.md#rtl-support-experimental
          useScrollView
        />

        {locations.length < 10 && (
          <CarouselPagination dotsLength={locations.length} activeSlideIndex={activeSlideIndex} />
        )}
      </View>
    );
  },
);

const MapWithCarousel = ({ locations, buyerLocations }) => {
  const carouselRef = useRef(null);
  const mapRef = useRef(null);
  const [selectedMarketIndex, setSelectedMarketIndex] = useState(0);
  const [selectedZone, setSelectedZone] = useState(R.isEmpty(locations) ? null : locations[0]);

  useEffect(() => {
    if (!!mapRef && selectedZone && R.hasPath(['nearestBuyerLocation'], selectedZone)) {
      forceCenterMapOnCoordinates(selectedZone);
    }
  }, []);

  const forceCenterMapOnCoordinates = (location) => {
    if (location.latitude !== location.nearestBuyerLocation.latitude) {
      mapRef.current.fitToCoordinates(
        [
          { latitude: location.latitude, longitude: location.longitude },
          {
            latitude: location.nearestBuyerLocation.latitude,
            longitude: location.nearestBuyerLocation.longitude,
          },
        ],
        { edgePadding: DEFAULT_EDGE_PADDING },
      );
    }
  };

  const onCenterMapOnMarker = useCallback((newSelectedLocation) => {
    if (!newSelectedLocation || !mapRef || R.equals(newSelectedLocation, selectedZone)) {
      return;
    }

    mapRef.current.animateToRegion({
      latitude: newSelectedLocation.latitude,
      longitude: newSelectedLocation.longitude,
      latitudeDelta: 0.03,
      longitudeDelta: 0.03,
    });

    if (R.hasPath(['nearestBuyerLocation'], newSelectedLocation)) {
      forceCenterMapOnCoordinates(newSelectedLocation);
    }

    setSelectedZone(newSelectedLocation);

    const newSelectedLocationIndex = R.findIndex(R.propEq('id', newSelectedLocation.id))(locations);

    setSelectedMarketIndex(newSelectedLocationIndex);
    carouselRef.current.snapToItem(
      isNeedRTLFixForAndroid
        ? locations.length - 1 - newSelectedLocationIndex
        : newSelectedLocationIndex,
    );
  });

  const onMarkerPress = (event) => {
    const coordinates = event.nativeEvent.coordinate;

    locations.map((zone) => {
      const distance = getDistance(
        { latitude: coordinates.latitude, longitude: coordinates.longitude },
        { latitude: zone.latitude, longitude: zone.longitude },
      );

      if (distance <= ZONE_RADIUS) {
        onCenterMapOnMarker(zone);
      }
    });
  };

  const getBuyerLocationWithMarkerFix = (buyerLocation) => {
    const similarSellerLocation = R.find(R.propEq('latitude', buyerLocation.latitude))(locations);

    return {
      ...buyerLocation,
      ...(!!similarSellerLocation && { latitude: buyerLocation.latitude + MARKER_PRECISION_FIX }),
    };
  };

  const getAdditionalMarkerProps = () => {
    if (!isAndroid) {
      return {};
    }

    return { onPress: onMarkerPress };
  };

  return (
    <View style={rs.flex}>
      <MapView
        ref={mapRef}
        initialRegion={{
          latitude: locations[0].latitude,
          longitude: locations[0].longitude,
          latitudeDelta: 0.08,
          longitudeDelta: 0.08,
        }}
        // works only on iOS (Android doesn't propagate marker presses to the MapView)
        onPress={onMarkerPress}
        style={rs.flex}
        customMapStyle={customMapStyle}
      >
        {locations.map((location) => {
          const isActive = R.prop('id', selectedZone) === location.id;

          return (
            <MapMarker
              key={location.id}
              location={location}
              isActive={isActive}
              {...getAdditionalMarkerProps()}
            />
          );
        })}

        {!R.isNil(buyerLocations) &&
          !R.isEmpty(buyerLocations) &&
          buyerLocations.map((location) => {
            return (
              <MapMarker
                key={location.id}
                location={getBuyerLocationWithMarkerFix(location)}
                isSpecial
              />
            );
          })}
      </MapView>

      <LocationsCarousel
        forwardRef={carouselRef}
        locations={locations}
        onCenterMapOnMarker={onCenterMapOnMarker}
        selectedMarketIndex={selectedMarketIndex}
      />
    </View>
  );
};

MapWithCarousel.propTypes = {
  locations: T.array.isRequired,
};

export default MapWithCarousel;
