import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';

import types from './types';

const initialState = {
  locations: [],
  shippingPrice: null,
  isShippersDeliveryActive: false,
};

const shippingReducer = handleActions(
  {
    [types.SAVE_SHIPPING]: mergeDeep(({ payload }, state) => ({
      locations: payload.locations,
      shippingPrice: payload.shippingPrice,
      isShippersDeliveryActive: payload.isShippersDeliveryActive,
    })),
  },
  initialState,
);

export default shippingReducer;
