import moment from 'moment';
import 'moment/min/locales';
import strings from '../localization';

const setLocale = (locale) => {
  const parsedLocale = locale === 'br' ? 'pt-br' : locale;

  moment.locale(parsedLocale);
};

const formats = {
  time: 'MM/DD/YYYY [at] h:mm a',
  date: 'dddd, MMMM DD',
  day: 'MMM D, YYYY',
  hourAndMinutes: 'H:mm',
  minutesAgo: 'H:mm',
  testimonial: 'MMM, D, YYYY',
};

const getTestimonialDate = (t) => {
  return moment(t).format(formats.testimonial);
};

const calendar = (t) =>
  moment(t).calendar(null, {
    lastWeek: formats.date,
    sameDay: `[${strings.dates.today}], HH:mm`,
    lastDay: `[${strings.dates.yesterday}], HH:mm`,
    sameElse: formats.day,
  });

const transactionHeaderDate = (t) => moment(t).format('D MMM YYYY').toUpperCase();

const time = (t) => moment(t).format('D MMMM YYYY, HH:mm');

const monthAndYear = (t) => moment(t, 'x').format('MMM YYYY');

const dayAndMonth = (t) => moment(t).format('DD/MM');

const dayAndTime = (t) => moment(t).format('DD MMM, HH:mm');

const day = (t) => moment(t, 'x').format(formats.day);

const dateAfterXDays = (date, days) => moment(date).add(days, 'days').format('dddd, MMMM DD, H:mm');
const lessThanWeekAgo = (date) => moment().diff(date, 'days') < 7;

const getTimeForChatItem = (date) => {
  const t = moment(date);
  const oneHourAgo = moment().subtract(1, 'hours');
  const twelveHoursAgo = moment().subtract(12, 'hours');

  if (oneHourAgo < t) {
    const minutes = moment().diff(t, 'minutes');
    return strings.dates.min_ago.replace('XX', minutes);
  }
  if (twelveHoursAgo < t) {
    const hours = moment().diff(t, 'hours');
    return strings.dates.hours_ago.replace('XX', hours);
  }
  return calendar(t);
};

const getTimeForNotification = (date) => {
  const t = moment(date);

  const oneHourAgo = moment().subtract(1, 'hours');
  const dayAgo = moment().subtract(24, 'hours');
  const weekAgo = moment().subtract(7, 'days');

  if (oneHourAgo < t) {
    const minutes = moment().diff(t, 'minutes');
    return `${minutes}${strings.dates.min}`;
  }
  if (dayAgo < t) {
    const hours = moment().diff(t, 'hours');
    return `${hours}${strings.dates.hour}`;
  }
  if (weekAgo < t) {
    const days = moment().diff(t, 'days');
    return `${days}${strings.dates.day}`;
  }
  const weeks = moment().diff(t, 'weeks');
  return `${weeks}${strings.dates.week}`;
};

export {
  setLocale,
  calendar,
  transactionHeaderDate,
  day,
  getTimeForChatItem,
  getTimeForNotification,
  time,
  monthAndYear,
  dateAfterXDays,
  lessThanWeekAgo,
  dayAndMonth,
  getTestimonialDate,
  dayAndTime,
};
