import { StyleSheet } from 'react-native';
import { dimensions, colors, fontSizes } from '../../../../../styles';

export default StyleSheet.create({
  overlayStyle: {
    flex: 1,
  },
  optionContainer: {
    flex: 1,
    backgroundColor: colors.white,
  },
  cancelContainer: {
    paddingVertical: dimensions.medium,
    width: '100%',
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLighter,
    justifyContent: 'center',
  },
  headerTitle: {
    position: 'absolute',
    alignSelf: 'center',
    fontSize: fontSizes.large,
  },
  optionStyle: {
    paddingVertical: dimensions.medium,
    paddingRight: 8,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: dimensions.medium,
  },
  primaryCountriesContainer: {
    marginVertical: dimensions.doubleMedium,
  },
  sectionHeaderContainer: {
    backgroundColor: colors.grayLighter,
    paddingHorizontal: dimensions.medium,
    paddingTop: 26,
    paddingBottom: 18,
  },
});
