export default {
  ALL_USERS: 'all_users',
  ONLY_VERIFIED: 'only_verified',
  ONLY_UNVERIFIED: 'only_unverified',
  ONLY_NEW_USERS: 'only_new_users',
  ONLY_NON_ACTIVE_WEEK: 'only_non_active_week',
  ONLY_NON_ACTIVE_MONTH: 'only_non_active_month',
  ONLY_WITHOUT_UPDATED_PROFILE: 'only_without_updated_profile',
  ONLY_INACTIVE_WITH_MARKETPLACE_ACTIVITIES: 'only_inactive_with_marketplace_activities',
  WITHOUT_UPLOADED_ITEMS: 'without_uploaded_items',
  WITHOUT_PURCHASED_ITEMS: 'without_purchased_items',
  WITH_BUYING_OFFER_CONFIRMED: 'with_buying_offer_confirmed',
  WITH_SELLING_OFFER_CONFIRMED: 'with_selling_offer_confirmed',
  ONLY_UPLOAD_WITHOUT_SALE: 'only_upload_without_sale',
  ONLY_BUY_WITHOUT_UPLOAD: 'only_buy_without_upload',
  WITH_UNUSED_REFERRAL_CODE: 'with_unused_referral_code',
  WITHOUT_CREATING_WISH: 'without_creating_wish',
  WITHOUT_FULFILLING_WISH: 'without_fulfilling_wish',
};
