import React, { Component } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { connect } from 'react-redux';
import T from 'prop-types';
import StaticHeightButton from '../../Buttons/StaticHeightButton';
import Container from '../../Containers/Container';
import Text from '../../Text';
import RulesList from '../../Rules';
import { dimensions, colors, rs } from '../../../../styles';
import ruleTypes from '../../../../constants/ruleTypes';
import { isIos } from '../../../../utils/detectDevice';
import strings from '../../../../localization';

export const HeaderContainer = styled.View`
  padding-top: ${isIos ? dimensions.statusBarHeight : 0};
  height: ${dimensions.headerHeight};
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom-width: ${StyleSheet.hairlineWidth};
  border-bottom-color: ${colors.gray};
`;

export const ButtonContainer = styled.View`
  padding: ${dimensions.medium}px;
  padding-bottom: 40px;
`;

class Rules extends Component {
  render() {
    const {
      params: { type },
      communityRules,
      itemRules,
      wishRules,
      onClose,
    } = this.props;

    const rules = {
      [ruleTypes.COMMUNITY_RULES]: communityRules,
      [ruleTypes.ITEM_RULES]: itemRules,
      [ruleTypes.WISH_RULES]: wishRules,
    }[type];

    const title = {
      [ruleTypes.COMMUNITY_RULES]: strings.rules.community_rules,
      [ruleTypes.ITEM_RULES]: strings.rules.item_rules_title,
      [ruleTypes.WISH_RULES]: strings.rules.wish_rules_title,
    }[type];

    return (
      <Container>
        <HeaderContainer>
          <Text large>{title}</Text>
        </HeaderContainer>
        <ScrollView
          bounces={false}
          style={[rs.backgroundWhite, rs.paddingHorizontal, rs.paddingVertical]}
        >
          <RulesList rules={rules} />
        </ScrollView>
        <ButtonContainer>
          <StaticHeightButton title={strings.common.ok} onPress={onClose} />
        </ButtonContainer>
      </Container>
    );
  }
}

Rules.propTypes = {
  params: T.shape({
    type: T.oneOf([ruleTypes.COMMUNITY_RULES, ruleTypes.ITEM_RULES, ruleTypes.WISH_RULES])
      .isRequired,
  }),
  approveItemRules: T.func,
  approveCommunityRules: T.func,
  approveWishRules: T.func,
  getCommunityRules: T.func,
  getItemRules: T.func,
  getWishRules: T.func,
  onClose: T.func,
  communityRules: T.array,
  itemRules: T.array,
  wishRules: T.array,
};

export default connect((state) => ({
  communityRules: state.communityInfo.community_rules,
  wishRules: state.communityInfo.wish_rules,
  itemRules: state.communityInfo.item_rules,
}))(Rules);
