import React, { memo, useEffect, useState } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import { OpenGraphContentView } from '../../../../ReusableComponents';
import { OpenGraphParser } from '../../../../../libraries/OpenGraph';

const UrlSocialMetadata = memo(({ currentMessage, user }) => {
  const [metaArray, setMetaArray] = useState([]);

  const isCurrentUserMessage = user._id === currentMessage.user._id;

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const meta = await OpenGraphParser.extractMeta(currentMessage.text);
    setMetaArray(meta);
  };

  if (R.isEmpty(metaArray)) return null;

  return <OpenGraphContentView data={metaArray[0]} isLight={isCurrentUserMessage} />;
});

UrlSocialMetadata.propTypes = {
  currentMessage: T.object,
  user: T.object,
};

export default UrlSocialMetadata;
