import React, { memo } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { StyleSheet, SectionList, View } from 'react-native';
import strings from '../../../localization';
import { getContactsSectionList } from '../../../store/app/selectors';
import { EmptyList, Separator, Text, TouchableItem } from '../';
import { colors, dimensions, rs } from '../../../styles';

const s = StyleSheet.create({
  separator: {
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.halfMedium,
  },
  sectionTitle: {
    width: '100%',
    paddingVertical: dimensions.halfMedium,
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.grayLighter,
  },
  contactWrapper: {
    paddingHorizontal: dimensions.medium,
    flexDirection: 'column',
    justifyContent: 'center',
    height: 64,
  },
});

const ContactItem = memo(({ item, onPress }) => (
  <TouchableItem onPress={() => onPress(item)} style={s.contactWrapper}>
    <View style={rs.row}>
      <Text style={rs.smallMarginBottom} large>
        {item.firstName}
      </Text>

      {!!item.lastName && (
        <Text style={rs.smallMarginLeft} large semiBold>
          {item.lastName}
        </Text>
      )}
    </View>

    <Text color={colors.gray} medium>
      {item.phoneNumbers[0].number}
    </Text>
  </TouchableItem>
));

const ContactBookSectionList = memo(({ sections, onPress }) => (
  <SectionList
    sections={sections}
    keyExtractor={(item) => item.id}
    initialNumToRender={20}
    stickySectionHeadersEnabled
    keyboardShouldPersistTaps="handled"
    ItemSeparatorComponent={() => (
      <View style={s.separator}>
        <Separator />
      </View>
    )}
    renderSectionHeader={({ section: { title } }) => (
      <Text color={colors.gray} style={s.sectionTitle} large>
        {title}
      </Text>
    )}
    ListEmptyComponent={<EmptyList text={strings.emptyLists.emptyContactsList} />}
    renderItem={({ item }) => <ContactItem item={item} onPress={onPress} />}
  />
));

ContactBookSectionList.propTypes = {
  searchTerm: T.string,
  onPress: T.func,
};

ContactBookSectionList.defaultProps = {
  searchTerm: '',
  onPress: () => {},
};

export default connect((state, { searchTerm }) => ({
  sections: getContactsSectionList(state, searchTerm),
}))(ContactBookSectionList);
