import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { colors, dimensions } from '../../styles';
import Icon from './Icon';

const s = StyleSheet.create({
  checkboxContainer: {
    width: 16,
    height: 16,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.grayBorder,
    backgroundColor: colors.white,
    marginHorizontal: dimensions.halfMedium,
  },
  checkboxContainerSelected: {
    borderColor: colors.blueLight,
    backgroundColor: colors.blueLight,
  },
});

const SquareCheckBox = ({ selected, style }) => {
  return (
    <View style={[s.checkboxContainer, selected && s.checkboxContainerSelected, style]}>
      <Icon name="check" size={14} color={colors.white} withoutRTLScale />
    </View>
  );
};

export default SquareCheckBox;
