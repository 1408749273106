import { createAction } from 'redux-actions';
import types from './types';

export const setCommunityMembers = createAction(types.SET_COMMUNITY_MEMBERS);
export const setMoreCommunityMembers = createAction(types.SET_MORE_COMMUNITY_MEMBERS);
export const startLoadingCommunityMembers = createAction(types.START_LOADING_COMMUNITY_MEMBERS);
export const errorLoadingCommunityMembers = createAction(types.ERROR_LOADING_COMMUNITY_MEMBERS);
export const startLoadingMoreCommunityMembers = createAction(
  types.START_LOADING_MORE_COMMUNITY_MEMBERS,
);
export const errorLoadingMoreCommunityMembers = createAction(
  types.ERROR_LOADING_MORE_COMMUNITY_MEMBERS,
);
export const setCommunityMembersSearchHistory = createAction(
  types.SET_COMMUNITY_MEMBERS_SEARCH_HISTORY,
);
export const removeMemberFromSearchHistory = createAction(types.REMOVE_MEMBER_FROM_SEARCH_HISTORY);
