export default {
  title: {
    en: 'Taking pictures of items',
    he: 'המדריך השלם לצילום איכותי של פריטים',
    br: 'Tirando fotos de itens',
    de: 'Fotografieren von Artikeln',
  },
  heading: {
    en:
      'To increase your chances of selling items and to make your usage of Shareitt convenient and effective, we\u0027ve put together a list of recommended tips for taking pictures of items before you upload them to the Marketplace:',
    he:
      'כדי להגדיל את הסיכוי שלכם למכור פריטים וכדי שהחוויה ב-Shareitt תהיה נוחה ויעילה, ריכזנו עבורכם את הטיפים החשובים ביותר לצילום פריטים, רגע לפני שהם עולים למרקטפלייס:',
    br:
      'Para aumentar suas chances de vender itens e tornar o uso do Shareitt conveniente e eficaz, reunimos uma lista de dicas recomendadas para tirar fotos de itens antes de enviá-los para o Marketplace',
    de:
      'Um das Interesse an deinen Artikeln zu erhöhen und die Nutzung von Shareitt bequem und effektiv zu gestalten, haben wir eine Liste von Tipps zum Fotografieren von Artikeln zusammengestellt, bevor du sie auf den Marktplatz hochlädst:',
  },
  list: [
    {
      id: '0',
      label: {
        en: 'Shoot your item at a straight (180˚) angle, so that the camera is level with it',
        he: 'השתדלו לצלם את הפריטים בזווית ישרה (180 מעלות), כך שהמצלמה תהיה באותו הגובה של הפריט.',
        br:
          'Fotografe seu item em um ângulo reto (180˚), de modo que a câmera fique nivelada com ele',
        de:
          'Nimm den Artikel in einem geraden (180˚) Winkel auf, so dass die Kamera in der gleiche Ebene mit dem Objekt ist.',
      },
    },
    {
      id: '1',
      label: {
        en:
          'Place the item on a clean smooth surface, and use a clear, preferably white background',
        he: 'הניחו את הפריט על משטח בצבע אחיד ובהיר, עדיף לבן, ודאגו שגם הרקע יהיה בהתאם. ',
        br:
          'Coloque o item em uma superfície lisa e limpa e use um fundo claro, de preferência branco',
        de:
          'Lege den Artikel auf eine saubere, glatte Oberfläche und verwende einen klaren, vorzugsweise weissen Hintergrund.',
      },
    },
    {
      id: '2',
      label: {
        en:
          'Outside light (daylight) is preferable to indoor light. Either way, lighting should be white and bright',
        he:
          'תאורת חוץ (אור יום) עדיפה על פני תאורת פנים. כך או כך, חשוב שתהיה תאורה לבנה ואיכותית.',
        br:
          'A luz externa (luz do dia) é preferível à luz interna. De qualquer forma, a iluminação deve ser branca e brilhante',
        de:
          'Tageslicht ist meistens besser als künstliches Licht. Die Beleuchtung sollte jedenfalls weiss und hell sein.',
      },
    },
    {
      id: '3',
      label: {
        en:
          'Place or arrange the item in such a way that the picture best reflects its actual condition',
        he: 'מקמו את הפריט בצורה ישרה ויציבה, כדי שהתמונה תמחיש בצורה הטובה ביותר את מצב הפריט.',
        br: 'Coloque ou organize o item de forma que a imagem reflita melhor sua condição real',
        de:
          'Lege oder ordne den Artikel so an, dass das Bild seinen tatsächlichen Zustand am besten wiedergibt',
      },
    },
    {
      id: '4',
      label: {
        en: 'Photograph the entire item taking different shots and from as many angles as possible',
        he: 'צלמו את הפריט בשלמותו, והשתמשו בכמה שיותר זוויות צילום.',
        br: 'Fotografe o item inteiro tirando fotos diferentes e de tantos ângulos quanto possível',
        de:
          'Fotografiere den gesamten Artikel aus verschiedenen Blickwinkeln und aus möglichst vielen Perspektiven',
      },
    },
    {
      id: '5',
      label: {
        en: 'Make sure the pictures are sharp and in focus, not blurry',
        he: 'חשוב לוודא שהתמונות יוצאות חדות ולא מטושטשות, לכן ודאו שהמצלמה נמצאת בפוקוס מלא.',
        br: 'Certifique-se de que as fotos estejam nítidas e em foco, não borradas',
        de: 'Achte darauf, dass die Bilder scharf und nicht verschwommen sind.',
      },
    },
    {
      id: '6',
      label: {
        en:
          'If the item is at all damaged, it\u0027s best to take separate pictures of the flaws and specify them in the item description',
        he: 'במידה וקיימים פגמים במוצר, מומלץ לצלם אותם בנפרד ולהדגיש אותם במצב תיאור הפריט.',
        br:
          'Se o item estiver danificado, é melhor tirar fotos separadas das falhas e especificá-las na descrição do item',
        de:
          'Wenn der Artikel beschädigt ist, mach am besten separate Fotos von den Mängeln und gib diese in der Beschreibung an.',
      },
    },
    {
      id: '7',
      label: {
        en:
          'Don\u0027t use preexisting pictures from the internet since these won\u0027t reflect the item\u0027s current condition',
        he: 'אל תשתמשו בתמונות מהאינטרנט שכן הם אינם מעבירים את מצבו הנוכחי של הפריט.',
        br:
          'Não use imagens preexistentes da internet, pois elas não refletirão a condição atual do item',
        de:
          'Verwende keine bereits existierenden Bilder aus dem Internet, da diese den aktuellen Zustand des Artikels nicht wiedergeben.',
      },
    },
  ],
  footer: {
    en:
      'The more high quality pictures of your items you upload the better: the more exposure you\u0027ll gain and the more you\u0027ll sell!',
    he:
      'כאמור, ככל שתעלו יותר תמונות, המתארות את הפריט בצורה הטובה ביותר, שמצולמות באופן איכותי - כך תגדילו את החשיפה שלכם לפריטים ותמכרו יותר.',
    br:
      'Quanto mais fotos de alta qualidade de seus itens você enviar, melhor: mais exposição você ganhará e mais você venderá!',
    de:
      'Je mehr qualitativ hochwertige Bilder du von deinen Artikeln hochlädst, desto mehr Aufmerksamkeit erhalten deine Artikel!',
  },
};
