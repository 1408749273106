import React, { memo } from 'react';
import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';
import { numberWithCommas } from '../../../../../utils/stringHelper';
import { Text, TokenIcon, ItemAvatar } from '../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../styles';

const GroupHeader = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom-width: ${StyleSheet.hairlineWidth};
  border-bottom-color: ${colors.gray};
  align-items: center;
  padding-right: 12px;
`;

const GroupIconContainer = styled.TouchableOpacity`
  padding-vertical: ${dimensions.halfMedium};
  padding-left: 12px;
  padding-right: ${dimensions.small};
`;

const GroupDetailsContainer = styled.View`
  flex: 1;
  padding-top: 2px;
  padding-right: ${dimensions.halfMedium};
`;

const PriceContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin-vertical: 2px;
`;

const ItemHeader = memo(
  ({ itemImage, itemTitle, tokenIcon, itemPrice, onOpenItemDetails, children }) => (
    <GroupHeader>
      <GroupIconContainer onPress={onOpenItemDetails}>
        <ItemAvatar small uri={itemImage} />
      </GroupIconContainer>
      <GroupDetailsContainer>
        <Text numberOfLines={1} ellipsizeMode="tail">
          {itemTitle}
        </Text>
        <PriceContainer>
          <TokenIcon isVerySmall customUrl={tokenIcon} />
          {
            <Text medium color={colors.blueLighter} style={rs.verySmallPaddingHorizontal}>
              {itemPrice ? numberWithCommas(itemPrice) : ' '}
            </Text>
          }
        </PriceContainer>
      </GroupDetailsContainer>
      {children}
    </GroupHeader>
  ),
);

export default ItemHeader;
