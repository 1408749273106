import React from 'react';
import T from 'prop-types';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import styled from 'styled-components/native';
import TouchableItem from '../TouchableItem';
import FontIcon from '../FontIcon';
import { dimensions } from '../../../styles';

const ContentContainer = styled(TouchableItem)`
  padding-horizontal: ${({ withSmallPadding }) => (withSmallPadding ? dimensions.medium : 0)};
  justify-content: center;
`;

const IconButton = ({
  color,
  name,
  selected,
  selectedColor,
  withSmallPadding,
  onPress,
  containerStyle,
  isMaterialCommunityIcon,
  disabled,
  ...iconProps
}) => (
  <ContentContainer
    withSmallPadding={withSmallPadding}
    style={containerStyle}
    onPress={onPress}
    disabled={disabled}
  >
    {isMaterialCommunityIcon ? (
      <MaterialCommunityIcons color={selected ? selectedColor : color} name={name} {...iconProps} />
    ) : (
      <FontIcon color={selected ? selectedColor : color} name={name} {...iconProps} />
    )}
  </ContentContainer>
);

IconButton.propTypes = {
  withSmallPadding: T.bool,
  color: T.string,
  name: T.string,
  onPress: T.func,
  containerStyle: T.any,
};

export default IconButton;
