import React, { Fragment } from 'react';
import { View } from 'react-native';
import { numberWithCommas } from '../../../../../../utils/stringHelper';
import { colors, rs } from '../../../../../../styles';
import { ItemAvatar, UserAvatar, Text, FontIcon } from '../../../../../ReusableComponents';
import s, {
  TransactionInfoContainer,
  TransactionTypeContainer,
  ProductContainer,
  ContentContainer,
  MainContainer,
} from './styles';
import transactionInfo from '../../getTransactionInfo';

const TransactionIssueItem = ({ transaction, onPress }) => {
  if (!transaction.type) return null;
  const info = transactionInfo(transaction);
  const isDonation = info.type === 'DONATION';

  return (
    <ContentContainer useOpacity={false} onPress={onPress}>
      <Fragment>
        {info.type === 'TRANSFER' ? (
          <ItemAvatar uri={transaction.item_image} withMargin />
        ) : (
          <UserAvatar withLogoPlaceholder />
        )}

        <View
          style={[
            s.iconContainer,
            isDonation && s.whiteBackground,
            !isDonation && info.isIncome && s.greenBackground,
          ]}
        >
          <FontIcon
            name={info.iconName}
            size={16}
            color={isDonation ? colors.text : colors.white}
          />
        </View>
        <MainContainer>
          <TransactionInfoContainer>
            <View style={[rs.flex, rs.paddingHorizontal]}>
              {/*<Text medium semiBold numberOfLines={1} ellipsizeMode="tail">*/}
              {/*  {info.title}*/}
              {/*</Text>*/}
              <Text
                semiBold
                color={colors.gray}
                numberOfLines={2}
                ellipsizeMode="tail"
                style={s.descriptionText}
              >
                {info.description}
              </Text>
            </View>

            <ProductContainer>
              <TransactionTypeContainer>
                <Text medium bold color={info.isIncome ? colors.green : colors.orange}>
                  {info.isIncome ? '+' : '-'}
                  {info.type === 'EXCHANGE_FROM'
                    ? numberWithCommas(transaction.initial_amount)
                    : numberWithCommas(transaction.amount)}
                </Text>
              </TransactionTypeContainer>
            </ProductContainer>
          </TransactionInfoContainer>
        </MainContainer>
      </Fragment>
    </ContentContainer>
  );
};

export default TransactionIssueItem;
