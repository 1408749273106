import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { mergeDeep } from '../../utils/stateHelper';
import * as types from './types';
import { ADD_NEW_SELLING_OFFER, ADD_NEW_BUYING_OFFER } from '../offers/types';

const initialState = {
  totalUnreadCount: 0,

  adminSupportChatsTotalUnreadCount: 0,

  isLoadingChatInfo: false,

  allChats: {
    isLoading: false,
    isLoadingMore: false,
    isExistMore: true,

    chatIds: [],
  },

  buyChats: {
    isLoading: false,
    isLoadingMore: false,
    isExistMore: true,

    chatIds: [],
  },

  sellChats: {
    isLoading: false,
    isLoadingMore: false,
    isExistMore: true,

    chatIds: [],
  },

  personalChats: {
    isLoading: false,
    isLoadingMore: false,
    isExistMore: true,

    chatIds: [],
  },

  searchedChats: {
    isLoading: false,
    isLoadingMore: false,
    isExistMore: true,

    chatIds: [],
  },

  archivedChats: {
    isLoading: false,
    isLoadingMore: false,
    isExistMore: true,

    chatIds: [],
  },

  adminSupportChats: {
    isLoading: false,
    isLoadingMore: false,
    isExistMore: true,

    chatIds: [],
  },

  chatMessages: {
    // [chatId]: {
    //   isLoading: false,
    //   isLoadingMore: false,
    //   isExistMore: true,
    //   messageIds: [],
    // }
  },

  messageEntities: {},

  chatEntities: {
    TEMPORARY_CHAT_ID: null,
  },

  insideChatRoomId: null, // null or roomId
};

const authReducer = handleActions(
  {
    [types.CHATS_START]: mergeDeep(({ payload }) => ({
      [payload.entityName]: {
        [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: true,
      },
    })),
    [types.CHAT_INFO_START]: mergeDeep(({ payload }) => ({
      isLoadingChatInfo: true,
    })),

    [types.CHAT_INFO_SUCCESS]: mergeDeep(({ payload }) => ({
      isLoadingChatInfo: false,
    })),

    [types.CHATS_SUCCESS]: mergeDeep(({ payload }, state) => {
      const {
        entityName,
        isLoadMore,
        chatIds,
        messageEntities,
        chatEntities,
        isExistMore,
      } = payload;
      return {
        [entityName]: {
          [isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
          isExistMore,
          chatIds: isLoadMore
            ? R.uniq([...R.path([entityName, 'chatIds'], state), ...chatIds])
            : chatIds,
        },
        messageEntities,
        chatEntities,
      };
    }),

    [types.CHATS_ERROR]: mergeDeep(({ payload }, state) => ({
      [payload.entityName]: {
        isLoadingMore: false,
        isLoading: false,
      },
    })),

    [types.MESSAGES_START]: mergeDeep(({ payload }) => ({
      chatMessages: {
        [payload.chatId]: {
          [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: true,
        },
      },
    })),

    [types.MESSAGES_SUCCESS]: mergeDeep(({ payload }, state) => ({
      chatMessages: {
        [payload.chatId]: {
          [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
          isExistMore: payload.isExistMore,
          messageIds: payload.isLoadMore
            ? R.uniq([
                ...R.path(['chatMessages', payload.chatId, 'messageIds'], state),
                ...payload.messageIds,
              ])
            : payload.messageIds,
        },
      },
      messageEntities: payload.messageEntities,
    })),

    [types.SET_INSIDE_ROOM_ID]: mergeDeep(({ payload }) => ({
      insideChatRoomId: payload,
    })),

    [types.ADD_CHAT_ENTITY]: mergeDeep(({ payload }) => ({
      chatEntities: {
        [payload.chat.id]: payload.chat,
      },
    })),

    [ADD_NEW_SELLING_OFFER]: mergeDeep(({ payload }) => ({
      chatEntities: {
        [payload.offer.chat_room_id]: {
          offer_id: payload.offer.id,
        },
      },
    })),

    [ADD_NEW_BUYING_OFFER]: mergeDeep(({ payload }) => ({
      chatEntities: {
        [payload.offer.chat_room_id]: {
          offer_id: payload.offer.id,
        },
      },
    })),

    [types.RESET_TEMPORARY_CHAT]: mergeDeep(({ payload }) => ({
      chatEntities: {
        TEMPORARY_CHAT_ID: null,
      },
      chatMessages: {
        TEMPORARY_CHAT_ID: null,
      },
    })),

    [types.MARK_CHAT_AS_READ]: mergeDeep(({ payload }, state) => ({
      chatEntities: {
        [payload.chatId]: {
          unread_count: 0,
        },
      },
      [payload.isSupportChatForAdmin ? 'adminSupportChatsTotalUnreadCount' : 'totalUnreadCount']:
        state[
          [payload.isSupportChatForAdmin ? 'adminSupportChatsTotalUnreadCount' : 'totalUnreadCount']
        ] - 1,
    })),

    [types.MARK_CHAT_MESSAGES_AS_READ]: mergeDeep(({ payload }) => ({
      messageEntities: payload,
    })),

    [types.INCREASE_CHAT_UNREAD_COUNT]: mergeDeep(({ payload }, state) => ({
      chatEntities: {
        [payload.chatId]: {
          unread_count: state.chatEntities[payload.chatId].unread_count + 1,
        },
      },
      totalUnreadCount:
        state.chatEntities[payload.chatId].unread_count === 0
          ? state.totalUnreadCount + 1
          : state.totalUnreadCount,
    })),

    [types.INCREASE_SUPPORT_CHAT_UNREAD_COUNT]: mergeDeep(({ payload }, state) => ({
      chatEntities: {
        [payload.chatId]: {
          unread_count: state.chatEntities[payload.chatId].unread_count + 1,
        },
      },
      adminSupportChatsTotalUnreadCount:
        state.chatEntities[payload.chatId].unread_count === 0
          ? state.adminSupportChatsTotalUnreadCount + 1
          : state.adminSupportChatsTotalUnreadCount,
    })),

    [types.INCREASE_TOTAL_UNREAD_COUNT]: mergeDeep(({ payload }, state) => ({
      totalUnreadCount: state.totalUnreadCount + 1,
    })),
    [types.INCREASE_SUPPORT_CHATS_TOTAL_UNREAD_COUNT]: mergeDeep(({ payload }, state) => ({
      adminSupportChatsTotalUnreadCount: state.totalUnreadCount + 1,
    })),

    [types.RESET_SEARCHED_CHATS]: mergeDeep(({ payload }) => ({
      searchedChats: initialState.searchedChats,
    })),

    [types.ADD_NEW_CHAT]: mergeDeep(({ payload }, state) => {
      const { entityName, chat, message } = payload;
      return {
        [entityName]: {
          chatIds: R.uniq([chat.id, ...R.path([entityName, 'chatIds'], state)]),
        },

        chatEntities: {
          [chat.id]: {
            ...chat,
            last_message_id: message.id,
          },
        },

        chatMessages: {
          [chat.id]: {
            isExistMore: false,
            messageIds: R.uniq([
              message.id,
              ...R.pathOr([], ['chatMessages', chat.id, 'messageIds'], state),
            ]),
          },
        },

        messageEntities: {
          [message.id]: message,
        },
      };
    }),

    [types.SET_MESSAGE]: mergeDeep(({ payload }, state) => ({
      chatMessages: {
        [payload.message.chat_id]: {
          messageIds: R.uniq([
            payload.message.id,
            ...R.pathOr([], ['chatMessages', payload.message.chat_id, 'messageIds'], state),
          ]),
        },
      },
      chatEntities: {
        [payload.message.chat_id]: {
          last_message_id: payload.message.id,
        },
      },
      messageEntities: {
        [payload.message.id]: payload.message,
      },
    })),

    [types.UPDATE_MESSAGE]: mergeDeep(({ payload }, state) => ({
      messageEntities: {
        [payload.message.id]: payload.message,
      },
    })),

    [types.REMOVE_MESSAGE]: mergeDeep(({ payload }, state) => ({
      chatMessages: {
        [payload.chatId]: {
          messageIds: state.chatMessages[payload.chatId].messageIds.filter(
            (id) => id !== payload.messageId,
          ),
        },
      },
    })),

    [types.SET_TOTAL_UNREAD_COUNT]: mergeDeep(({ payload }, state) => ({
      totalUnreadCount: payload.unreadCount,
    })),

    [types.SET_SUPPORT_CHATS_TOTAL_UNREAD_COUNT]: mergeDeep(({ payload }, state) => ({
      adminSupportChatsTotalUnreadCount: payload.unreadCount,
    })),

    [types.ARCHIVE_CHATS]: mergeDeep(({ payload }, state) => ({
      buyChats: {
        chatIds: R.without(payload.chatIds, R.path(['buyChats', 'chatIds'], state)),
      },
      sellChats: {
        chatIds: R.without(payload.chatIds, R.path(['sellChats', 'chatIds'], state)),
      },
      personalChats: {
        chatIds: R.without(payload.chatIds, R.path(['personalChats', 'chatIds'], state)),
      },
      allChats: {
        chatIds: R.without(payload.chatIds, R.path(['allChats', 'chatIds'], state)),
      },
    })),

    [types.UNARCHIVE_CHATS]: mergeDeep(({ payload }, state) => ({
      archivedChats: {
        chatIds: R.without(payload.chatIds, R.path(['archivedChats', 'chatIds'], state)),
      },
    })),

    [types.SET_USER_TYPING_MESSAGE]: mergeDeep(({ payload }) => ({
      chatEntities: {
        [payload.chatId]: { isTypingMessage: payload.isTypingMessage },
      },
    })),

    [types.UPDATE_CHAT]: mergeDeep(({ payload }) => ({
      chatEntities: {
        [payload.chatId]: payload.chat,
      },
    })),

    [types.RESET_CHATS_STATE]: () => ({
      ...initialState,
    }),
  },
  initialState,
);

export default authReducer;
