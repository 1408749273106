import actions from './actions';

const saveGroupForNextLot = (groupId) => async (dispatch) => {
  dispatch(actions.saveGroupForNextLot({ groupId }));
};

export default {
  saveGroupForNextLot,
  saveRefundPolicyForNextLot: actions.saveRefundPolicyForNextLot,
};
