import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { View, RefreshControl, SectionList } from 'react-native';
import T from 'prop-types';
import { colors, rs } from '../../../../../styles';
import { EmptyList, Text } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import { transactionOperations } from '../../../../../store/transactions';
import { getTransactionsSectionList } from '../../../../../store/transactions/selectors';
import TransactionItem from './components/TransactionItem';
import UserInfoHeader from './components/UserInfoHeader';
import s from './styles';
import { NavigationService } from '../../../../../services';
import screens from '../../../../../navigation/screens';
import Spinner from '../../../../ReusableComponents/Spinner';
import useOnce from '../../../../../utils/useOnce';
import { isIos, isWeb } from '../../../../../utils/detectDevice';
import { shareDynamicLink } from '../../../../../utils/shareHelper';
import { OPEN_USER_PROFILE } from '../../../../../constants/dynamicalLinkActionTypes';
import { walletsOperations } from '../../../../../store/wallets';
import { userOperations } from '../../../../../store/userInfo';

const SectionHeader = ({ section: { title } }) => (
  <View style={s.sectionHeaderContainer}>
    <Text xsmall semiBold color={colors.gray}>
      {title}
    </Text>
  </View>
);
const Transactions = ({
  transactions,
  isLoading,
  isLoadingMore,
  getTransactions,
  getWallets,
  onLoadMoreTransactions,
  onRefreshTransactions,
  navigation,
  userInfo,
  getCurrentUserInfo,
}) => {
  const [isRefreshing, setRefreshing] = useState(false);

  useEffect(() => {
    getTransactions();
    getCurrentUserInfo();
    navigation.setParams({
      onShareProfile,
      onEditProfile,
      nickname: userInfo.nickname,
    });
  }, []);

  useEffect(() => {
    navigation.setParams({
      nickname: userInfo.nickname,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.nickname]);

  const onShareProfile = useOnce(async () => {
    await shareDynamicLink({
      actionType: OPEN_USER_PROFILE,
      id: userInfo.id,
      socialImageUrl: userInfo.profile_image,
      socialTitle: userInfo.name,
      linkDescription: strings.deep_linking.my_profile_link_info,
    });
  });

  const onEditProfile = () => {
    NavigationService.navigate(screens.EditUserInfo);
  };

  const onTransactionPress = (transaction) => {
    NavigationService.navigate(screens.TransactionDetails, {
      transaction,
    });
  };
  const onRefresh = async () => {
    setRefreshing(true);
    await getCurrentUserInfo();
    await onRefreshTransactions();
    await getWallets();
    setRefreshing(false);
  };

  return (
    <SectionList
      style={s.contentContainer}
      contentContainerStyle={isWeb && rs.webContainer}
      sections={transactions}
      keyExtractor={(item) => item.id.toString()}
      initialNumToRender={20}
      stickySectionHeadersEnabled={false}
      refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
      refreshing={isRefreshing}
      onEndReachedThreshold={0.7}
      onEndReached={onLoadMoreTransactions}
      // ItemSeparatorComponent={() => <Separator color={colors.grayLight} />}
      renderSectionHeader={SectionHeader}
      ListEmptyComponent={
        <View style={s.grayBackground}>
          {isLoading ? <Spinner /> : <EmptyList text={strings.emptyLists.emptyTransactionsList} />}
        </View>
      }
      ListFooterComponent={
        <Fragment>
          {isLoadingMore && (
            <View style={s.grayBackground}>
              <Spinner />
            </View>
          )}
          {isIos && <View style={s.iosBounceBackground} />}
        </Fragment>
      }
      ListHeaderComponent={<UserInfoHeader />}
      renderItem={({ item }) => (
        <TransactionItem transaction={item} onPress={() => onTransactionPress(item)} />
      )}
    />
  );
};

Transactions.propTypes = {
  transactions: T.array,
  getTransactions: T.func.isRequired,
  onLoadMoreTransactions: T.func.isRequired,
  onRefreshTransactions: T.func.isRequired,
  isLoading: T.bool.isRequired,
  isLoadingMore: T.bool.isRequired,
};

export default connect(
  (state) => ({
    transactions: getTransactionsSectionList(state),
    isLoading: state.transactions.isLoading,
    isLoadingMore: state.transactions.isLoadingMore,
    userInfo: state.userInfo,
  }),
  (dispatch) => ({
    onLoadMoreTransactions: () => dispatch(transactionOperations.getMoreTransactions()),
    getTransactions: () => dispatch(transactionOperations.getTransactions()),
    onRefreshTransactions: () => dispatch(transactionOperations.getTransactions(true)),
    getWallets: () => dispatch(walletsOperations.getWallets()),
    getCurrentUserInfo: () => dispatch(userOperations.getCurrentUserInfo()),
  }),
)(Transactions);
