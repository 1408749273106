import { createSelector } from 'reselect';
import * as R from 'ramda';

export const getUserInfo = (state) => {
  return R.pathOr({}, ['userInfo'], state);
};

export const getIsUnverifiedUserEmail = createSelector(
  [getUserInfo],
  (userInfo) => !!userInfo.email && !userInfo.is_email_verified,
);

export const getIsUSDiscoveryZone = createSelector(
  [getUserInfo],
  ({ phone_number }) => !!phone_number && phone_number.startsWith('+1'),
);

export const getSchoolExtension = createSelector([getUserInfo], ({ email }) => {
  if (!email) {
    return null;
  }

  const [_, extension] = email.split('@');

  return extension;
});

export const getIsUserDefaultProfileImage = createSelector(
  [getUserInfo],
  (userInfo) => {
    return !!userInfo.is_default_profile_image;
  },
);

export const getHasQuickSupportAccess = createSelector(
  [getUserInfo],
  (userInfo) => userInfo.has_quick_support_access,
);
