import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';

import types from './types';

const initialState = {
  discounts: {
    max_discounts_per_item: 1,
    max_discounts_per_period: 1,
    period_in_days: 7,
    discounts_used: 0,
  },
};

const reducer = handleActions(
  {
    [types.SET_SUBSCRIPTIONS_DETAILS]: mergeDeep(({ payload }, state) => ({
      ...payload,
    })),

    [types.INCREASE_USED_DISCOUNTS_COUNTER]: mergeDeep(({ payload }, state) => ({
      discounts: {
        discounts_used: state.discounts.discounts_used + 1,
      },
    })),
  },
  initialState,
);

export default reducer;
