import React, { Fragment, memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { FontIcon, GoalIcon, Text, TouchableItem, FastImage } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import rewardAmauntTypes from '../../../../../constants/rewardAmauntTypes';
import { colors, dimensions, rs } from '../../../../../styles';
import { NavigationService } from '../../../../../services';
import screens from '../../../../../navigation/screens';

const itemSize = Math.round(dimensions.width / 3) - 1.5 * dimensions.medium;

const s = StyleSheet.create({
  contentContainer: {
    width: '100%',
    alignItems: 'center',
    paddingBottom: dimensions.doubleMedium,
    paddingTop: dimensions.medium,
    backgroundColor: colors.white,
  },
  mainContainer: {
    paddingBottom: 6,
  },
  image: {
    height: 150,
    width: 150,
  },
  titleText: {
    fontSize: 20,
  },
});

const WelcomeRewardItem = memo(({ reward, onPress }) => {
  const {
    icon_url,
    achieved_icon_url,
    is_reached,

    amountType,
    title,
    text,
    achieved,
    number,
    max_reward_amount,
    given_reward_amount,
    tokenName,
  } = reward;

  const onRewardPress = () => {
    NavigationService.navigate(
      is_reached ? screens.AchievedRewardDetails : screens.NotAchievedRewardDetails,
      { reward },
    );
  };

  return (
    <View style={s.contentContainer}>
      <TouchableItem onPress={onRewardPress} style={rs.alignCenter} disabled={!is_reached}>
        <Fragment>
          <FastImage
            style={s.image}
            resizeMode={'contain'}
            priority={FastImage.priority.high}
            source={{ uri: is_reached ? achieved_icon_url : icon_url }}
          />

          <View style={s.mainContainer}>
            <Text alignCenter bold style={s.titleText}>
              {title}
            </Text>
          </View>
        </Fragment>
      </TouchableItem>

      <Text style={rs.verySmallPaddingTop}>
        {strings.rewards.you_get}{' '}
        <Text color={colors.blueLight} bold>
          {given_reward_amount} {strings.bank.tokens} {strings.main.app_name}
        </Text>{' '}
        {strings.rewards.just_for_joining_us}
      </Text>
      <Text style={rs.paddingHorizontal} alignCenter>
        {strings.rewards.upload_buy_and_sell_items_to_get_more_tokens}
      </Text>
    </View>
  );
});

export default WelcomeRewardItem;
