import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { PortalProvider } from '@gorhom/portal';
import { store, persistor } from '../store';
import screens from './screens';
import { FilterService, LoadingService, ModalsService, NavigationService } from '../services';
import { appOperations } from '../store/app';
import { authOperations } from '../store/auth';
import UnauthorizedUserStack from './stacks/UnauthorizedUserStack';
import { FilterBottomSheet, FullScreenLoader, RootModal } from '../components/ReusableComponents';
import SuspendedUserStack from './stacks/SuspendedUserStack';
import AuthorizedUserStack from './stacks/AuthorizedUserStack';

const linking = {
  prefixes: ['http://localhost*', 'https://app-dev.shareittapp.com/'],
  config: {
    screens: {
      [screens.AuthorizedApplication]: {
        path: '/app',
        screens: {
          [screens.SignIn]: 'login',
          [screens.CodeVerification]: 'code_verification',
          [screens.Home]: 'home',
          [screens.ItemDetails]: 'item',
          [screens.MarketplaceTab]: 'marketplace',
          [screens.UserProfile]: 'user',
          [screens.Inbox]: 'chats',
          [screens.Menu]: 'menu',
          [screens.PendingItems]: 'menu/pending_items',
          [screens.Settings]: 'settings',
          [screens.Support]: 'support',
          [screens.SuspendedUser]: '*',
        },
      },
      NotFound: '*',
    },
  },
};

const App = () => {
  const dispatch = useDispatch();
  const { isAuthorized, isSuspended } = useSelector((state) => state.auth);
  const [isCheckingAuthorization, setCheckingAuthorization] = React.useState(true);

  React.useEffect(() => {
    dispatch(appOperations.setupLocalization());
    checkAuthorization();
  }, []);

  const checkAuthorization = async () => {
    await setCheckingAuthorization(true);
    await dispatch(authOperations.checkAuthorization());
    await setCheckingAuthorization(false);
  };

  return (
    <NavigationContainer linking={linking} ref={(ref) => NavigationService.init(ref)}>
      {!isCheckingAuthorization && (
        <>
          {isSuspended ? (
            <SuspendedUserStack />
          ) : isAuthorized ? (
            <AuthorizedUserStack />
          ) : (
            <UnauthorizedUserStack />
          )}
        </>
      )}
    </NavigationContainer>
  );
};

const AppWithStore = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <PortalProvider>
          <ActionSheetProvider>
            <App />
          </ActionSheetProvider>

          <RootModal ref={(ref) => ModalsService.init(ref)} />
          <FilterBottomSheet ref={(ref) => FilterService.init(ref)} />
          <FullScreenLoader ref={(ref) => LoadingService.init(ref)} />
        </PortalProvider>
      </PersistGate>
    </Provider>
  );
};

export default AppWithStore;
