import React from 'react';
import T from 'prop-types';
import Svg, { G, Path, ClipPath, Rect, Defs } from 'react-native-svg';
import { isWeb } from '../../../../../../utils/detectDevice';
import { rs } from '../../../../../../styles';

const RewardBackground = ({ height = 145, width = 264 }) => (
  <Svg width={width} height={height} viewBox="0 0 514 308" preserveAspectRatio="none" style={rs.rtlScale}>
    <Defs>
      <ClipPath id="clip-Artboard_6">
        <Rect width={width} height={height} />
      </ClipPath>
    </Defs>
    <G id="Artboard_6" data-name="Artboard – 6" clipPath="url(#clip-Artboard_6)">
      <Path
        data-name="Subtraction 36"
        d="M25075-353h-490a5 5 0 01-4.994-5v-114.056l21.209-25.454a10.346 10.346 0 000-12.727L24580-535.7V-648a5 5 0 014.994-5h490a5 5 0 015 5v112.3l-21.213 25.46a10.329 10.329 0 000 12.727l21.219 25.457V-358a5 5 0 01-5 5zm-281-19v10h3v-10h-3zm0-20v10h3v-10h-3zm0-20v10h3v-10h-3zm0-20v10h3v-10h-3zm0-20v10h3v-10h-3zm0-20v10h3v-10h-3zm0-20v10h3v-10h-3zm0-20v10h3v-10h-3zm0-20v10h3v-10h-3zm0-20v10h3v-10h-3zm0-20v10h3v-10h-3zm0-20v10h3v-10h-3zm0-20v10h3v-10h-3zm0-20v10h3v-10h-3zm0-20v10h3v-10h-3z"
        transform="translate(-24580.006 652.997)"
        fill="#eff1f2"
      />
    </G>
  </Svg>
);

RewardBackground.propTypes = {
  width: T.number,
  height: T.number,
};

RewardBackground.defaultProps = {
  width: isWeb ? 528 : 264,
  height: isWeb ? 290 : 145,
};

export default RewardBackground;
