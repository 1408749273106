import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Button from '../../Buttons/Button';
import {
  ContentContainer,
  AdminAvatar,
  MainContainer,
  Title,
  Description,
  ButtonContainer,
} from './styles';
import strings from '../../../../localization';
import { groupsOperations, groupsSelectors } from '../../../../store/groups';

const JoinCommunity = ({ onPress, joinCommunity, params, communityInfo }) => {
  const isPendingApproval = communityInfo.is_pending_approval;

  const title = isPendingApproval
    ? strings.modals.pending_approval_title
    : strings.not_community_member.join_community_title;

  const description = isPendingApproval
    ? strings.modals.pending_approval_description
    : strings.not_community_member.join_community_description;

  return (
    <ContentContainer>
      <AdminAvatar source={{ uri: 'round_logo' }} />
      <MainContainer>
        <Title semiBold>{title}</Title>
        <Description>{description}</Description>

        <ButtonContainer>
          {isPendingApproval ? (
            <Button withMarginRight title={strings.common.ok} onPress={onPress} />
          ) : (
            <Fragment>
              <Button
                secondaryType
                withMarginRight
                title={strings.common.no_thanks}
                onPress={onPress}
              />

              <Button
                title={strings.community_info.ask_to_join}
                onPress={() => {
                  onPress();
                  joinCommunity(communityInfo.group_id);
                }}
              />
            </Fragment>
          )}
        </ButtonContainer>
      </MainContainer>
    </ContentContainer>
  );
};

export default connect(
  state => ({
    communityInfo: {},
  }),
  dispatch => ({
    joinCommunity: communityId => dispatch(groupsOperations.joinCommunity(communityId)),
  }),
)(JoinCommunity);
