import React from 'react';
import { connect } from 'react-redux';
import { headerStyle, rs } from '../../../../../styles';
import { View, ScrollView } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import { NavigationService } from '../../../../../services';
import BankItem from './components/BankItem';
import screens from '../../../../../navigation/screens';
import { communitySelectors } from '../../../../../store/communityInfo';

const Bank = ({ isAdmin }) => {
  return (
    <ScrollView style={rs.backgroundWhite}>
      {isAdmin && (
        <BankItem
          title={strings.bank.issue_token}
          description={strings.bank.give_members_token}
          iconName="chevron-double-right"
          onPress={() => NavigationService.navigate(screens.IssueToken)}
        />
      )}

      {/*<BankItem*/}
      {/*  title={strings.bank.transfer_tokens}*/}
      {/*  // description={strings.bank.send_tokens_description}*/}
      {/*  iconName="chevron-double-right"*/}
      {/*  onPress={() => NavigationService.navigate(screens.SendTokensToBank)}*/}
      {/*/>*/}
      {/*<BankItem*/}
      {/*  title={strings.tokens_exchange.title}*/}
      {/*  isFontIcon*/}
      {/*  iconName="transactions"*/}
      {/*  onPress={() => NavigationService.navigate(screens.TokensExchange)}*/}
      {/*/>*/}
    </ScrollView>
  );
};

export default connect((state) => ({
  isAdmin: communitySelectors.getIsUserAdmin(state),
}))(Bank);
