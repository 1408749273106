import React from 'react';
import * as R from 'ramda';
import Icon from 'react-native-vector-icons/Feather';
import { Container, Title } from './styles';
import FontIcon from '../FontIcon';
import { colors, dimensions } from '../../../styles';

export default ({ disabled, onButtonPress, title }) => (
  <Container onPress={R.is(Function, onButtonPress) ? onButtonPress : null} disabled={disabled}>
    <Title disabled={disabled}>{title}</Title>
  </Container>
);

export const NavigationIconButton = ({ name, onPress, disabled, color, style, isFontIcon }) => (
  <Container onPress={R.is(Function, onPress) ? onPress : null} disabled={disabled}>
    {isFontIcon ? (
      <FontIcon
        size={dimensions.iconSize}
        name={name}
        style={[{ color: color || colors.gray }, style]}
      />
    ) : (
      <Icon
        size={dimensions.iconSize}
        name={name}
        style={[{ color: color || colors.gray }, style]}
      />
    )}
  </Container>
);
