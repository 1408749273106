import styled from 'styled-components/native';
import { colors, dimensions, fontSizes } from '../../../../styles';
import Text from '../../Text';

export const ContentContainer = styled.View`
  align-items: center;
`;

export const AdminAvatar = styled.Image`
  height: 68px;
  width: 68px;
  margin-top: -34px;
`;

export const MainContainer = styled.View`
  padding-vertical: ${dimensions.doubleMedium};
  padding-horizontal: ${dimensions.medium};
  width: ${dimensions.width - 3 * dimensions.medium};
`;

export const ButtonContainer = styled.View`
  width: 100%;
  height: 40px;
  margin-top: 40px;
  flex-direction: row;
`;

export const Title = styled(Text)`
  font-size: 28px;
  margin-bottom: 30px;
  text-align: center;
  color: ${colors.blueLighter};
`;

export const Description = styled(Text)`
  font-size: ${fontSizes.medium};
  text-align: center;
`;
