import React from 'react';
import T from 'prop-types';
import { StyleSheet, ImageBackground, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { View, Text, TouchableItem, AngleIcon, FontIcon, FastImage } from '../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../styles';
import strings from '../../../../localization';
import { numberWithCommas } from '../../../../utils/stringHelper';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';

const s = StyleSheet.create({
  safeAreaContainer: {
    backgroundColor: colors.secondary,
  },
  contentContainer: {
    flexDirection: 'row',
    paddingHorizontal: dimensions.medium,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: dimensions.appBarHeight + dimensions.additionalAppBarLayerHeight,
  },
  infoContainer: {
    flexDirection: 'row',
    paddingTop: dimensions.medium,
    paddingBottom: dimensions.medium,
    alignItems: 'center',
  },
  avatarBackground: {
    borderRadius: 40,
  },
  avatar: {
    height: 60,
    width: 60,
    borderRadius: 30,
  },
});

const Info = ({ profileImage, name, balance, isUserDefaultProfileImage }) => {
  const onOpenSettings = ({}) => {
    NavigationService.navigate(screens.Settings);
  };
  const onOpenUserProfile = ({}) => {
    NavigationService.navigate(screens.Transactions);
  };
  return (
    <SafeAreaView style={s.safeAreaContainer} edges={['top']}>
      <View style={s.contentContainer}>
        <TouchableItem style={s.infoContainer} onPress={onOpenUserProfile}>
          <ImageBackground
            source={{ uri: isUserDefaultProfileImage ? profileImage : 'user_avatar_placeholder' }}
            resizeMode="cover"
            style={s.avatar}
            imageStyle={[
              s.avatarBackground,
              isUserDefaultProfileImage && {
                backgroundColor: colors.secondary,
                tintColor: colors.white,
              },
            ]}
          >
            {!!profileImage && !isUserDefaultProfileImage && (
              <FastImage source={{ uri: profileImage }} style={s.avatar} resizeMode="cover" />
            )}
          </ImageBackground>

          <View style={rs.paddingHorizontal}>
            <Text large semiBold color={colors.white}>
              {name}
            </Text>
            <Text medium color={colors.white} style={rs.verySmallPaddingTop}>
              {strings.bank.balance}:{' '}
              <Text medium semiBold color={colors.white}>
                {numberWithCommas(balance)}
              </Text>
            </Text>
          </View>
        </TouchableItem>
        <TouchableItem onPress={onOpenSettings}>
          <FontIcon color={colors.white} size={26} name="Menu_settings" />
        </TouchableItem>
      </View>
    </SafeAreaView>
  );
};

Info.propTypes = {
  profileImage: T.string,
  name: T.string,
  balance: T.number,
  isUserDefaultProfileImage: T.bool,
};

Info.defaultProps = {
  isUserDefaultProfileImage: false,
};

export default Info;
