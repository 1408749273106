import { createSelector } from 'reselect';
import * as R from 'ramda';
import chatTypes from '../../constants/chatTypes';
import { communitySelectors } from '../communityInfo';

const sortAndFilterChats = (chats) => {
  const sortChatsByLastMessage = (a, b) => {
    const first = R.pathOr(null, ['last_message', 'created_at'], a);
    const second = R.pathOr(null, ['last_message', 'created_at'], b);

    return new Date(second).getTime() - new Date(first).getTime();
  };

  return R.sort(sortChatsByLastMessage, chats);
};

export const getChats = createSelector(
  [
    R.pathOr([], ['chats', 'allChats', 'chatIds']),
    R.pathOr({}, ['chats', 'chatEntities']),
    R.pathOr({}, ['chats', 'messageEntities']),
    R.pathOr(null, ['userInfo', 'id']),
  ],
  (chatIds, chatEntities, messageEntities, userId) => {
    const chats = chatIds.map((id) => {
      const chat = chatEntities[id];

      let chat_type = null;

      if (chat.type === 'personal') {
        chat_type = chatTypes.PERSONAL_CHAT;
      } else if (chat.type === 'support') {
        chat_type = chatTypes.SUPPORT;
      } else if (chat.seller_id === userId) {
        chat_type = chatTypes.SELL_CHAT;
      } else {
        chat_type = chatTypes.BUY_CHAT;
      }

      return {
        ...chat,
        last_message: messageEntities[chatEntities[id].last_message_id],
        chat_type,
      };
    });

    return sortAndFilterChats(chats);
  },
);

const getSupportChatsForAdmin = createSelector(
  [
    R.pathOr([], ['chats', 'adminSupportChats', 'chatIds']),
    R.pathOr({}, ['chats', 'chatEntities']),
    R.pathOr({}, ['chats', 'messageEntities']),
  ],
  (chatIds, chatEntities, messageEntities) => {
    const chats = chatIds.map((id) => {
      const chat = chatEntities[id];

      return {
        ...chat,
        last_message: messageEntities[chatEntities[id].last_message_id],
        chat_type: chatTypes.SUPPORT,
      };
    });

    return sortAndFilterChats(chats);
  },
);

export const getBuyChats = createSelector(
  [
    R.pathOr([], ['chats', 'buyChats', 'chatIds']),
    R.pathOr({}, ['chats', 'chatEntities']),
    R.pathOr({}, ['chats', 'messageEntities']),
  ],
  (chatIds, chatEntities, messageEntities) => {
    const chats = chatIds.map((id) => ({
      ...chatEntities[id],
      last_message: messageEntities[chatEntities[id].last_message_id],
    }));

    return sortAndFilterChats(chats);
  },
);

export const getSellChats = createSelector(
  [
    R.pathOr([], ['chats', 'sellChats', 'chatIds']),
    R.pathOr({}, ['chats', 'chatEntities']),
    R.pathOr({}, ['chats', 'messageEntities']),
  ],
  (chatIds, chatEntities, messageEntities) => {
    const chats = chatIds.map((id) => ({
      ...chatEntities[id],
      last_message: messageEntities[chatEntities[id].last_message_id],
    }));

    return sortAndFilterChats(chats);
  },
);

export const getPersonalChats = createSelector(
  [
    R.pathOr([], ['chats', 'personalChats', 'chatIds']),
    R.pathOr({}, ['chats', 'chatEntities']),
    R.pathOr({}, ['chats', 'messageEntities']),
  ],
  (chatIds, chatEntities, messageEntities) => {
    const chats = chatIds.map((id) => ({
      ...chatEntities[id],
      last_message: messageEntities[chatEntities[id].last_message_id],
    }));

    return sortAndFilterChats(chats);
  },
);

export const getSearchedChats = createSelector(
  [
    R.pathOr([], ['chats', 'searchedChats', 'chatIds']),
    R.pathOr({}, ['chats', 'chatEntities']),
    R.pathOr({}, ['chats', 'messageEntities']),
    R.pathOr(null, ['userInfo', 'id']),
  ],
  (chatIds, chatEntities, messageEntities, userId) => {
    const chats = chatIds.map((id) => {
      const chat = chatEntities[id];

      let chat_type = null;

      if (chat.type === 'personal') {
        chat_type = chatTypes.PERSONAL_CHAT;
      } else if (chat.type === 'support') {
        chat_type = chatTypes.SUPPORT;
      } else if (chat.seller_id === userId) {
        chat_type = chatTypes.SELL_CHAT;
      } else {
        chat_type = chatTypes.BUY_CHAT;
      }

      return {
        ...chat,
        last_message: messageEntities[chatEntities[id].last_message_id],
        chat_type,
      };
    });

    return sortAndFilterChats(chats);
  },
);

export const getArchivedChats = createSelector(
  [
    R.pathOr([], ['chats', 'archivedChats', 'chatIds']),
    R.pathOr({}, ['chats', 'chatEntities']),
    R.pathOr({}, ['chats', 'messageEntities']),
    R.pathOr(null, ['userInfo', 'id']),
  ],
  (chatIds, chatEntities, messageEntities, userId) => {
    const chats = chatIds.map((id) => {
      const chat = chatEntities[id];

      let chat_type = null;

      if (chat.type === 'personal') {
        chat_type = chatTypes.PERSONAL_CHAT;
      } else if (chat.seller_id === userId) {
        chat_type = chatTypes.SELL_CHAT;
      } else {
        chat_type = chatTypes.BUY_CHAT;
      }

      return {
        ...chat,
        last_message: messageEntities[chatEntities[id].last_message_id],
        chat_type,
      };
    });

    return sortAndFilterChats(chats);
  },
);

export const getChatInfoById = createSelector(
  [R.pathOr([], ['chats', 'chatEntities']), (_, chatId) => chatId],
  (entities, id) => R.propOr({}, id, entities),
);

export const getIsUnreadInBuyTab = createSelector([getBuyChats], (chats) => {
  return R.any((chat) => chat.unread_count !== 0)(chats);
});

export const getIsUnreadInSellTab = createSelector([getSellChats], (chats) => {
  return R.any((chat) => chat.unread_count !== 0)(chats);
});

export const getIsUnreadInPersonalTab = createSelector([getPersonalChats], (chats) => {
  return R.any((chat) => chat.unread_count !== 0)(chats);
});

export const getMessagesList = createSelector(
  [
    (state, chatId) => R.pathOr([], ['chats', 'chatMessages', chatId, 'messageIds'], state),
    R.pathOr([], ['chats', 'messageEntities']),
    (state, chatId) => R.pathOr([], ['chats', 'chatEntities', chatId], state),
    R.pathOr({}, ['userInfo', 'id']),
    communitySelectors.getIsUserAdmin,
  ],
  (messageIds, messageEntities, chatInfo, currentUserId, isAdmin) => {
    const messages = messageIds.map((messageId) => {
      const {
        id,
        payload,
        created_at,
        sender_id,
        sender_name,
        sender_image,
        received,
        is_read,
      } = messageEntities[messageId];

      let message = null;

      if (payload.is_system) {
        message = {
          _id: id,
          text: payload.content,
          createdAt: created_at,
          type: payload.system_message_type,
          system: true,
        };
      } else {
        let text;

        switch (payload.content_type) {
          case 'text':
          case 'contact':
            text = payload.content;
            break;
          default:
            text = '';
        }

        message = {
          _id: id,
          text,
          createdAt: created_at,
          user: {
            _id: Number(sender_id),
            name: sender_name,
            avatar: chatInfo.type === 'support' && !isAdmin ? chatInfo.chat_avatar : sender_image, // for regular user remove admin avatar in support chat
          },
          sent: Number(sender_id) === Number(currentUserId),
          received: R.isNil(received) ? true : received,
          read: R.isNil(is_read) ? false : is_read,
          image: payload.content_type === 'image' ? payload.content : '',
          extra: payload.extra ?? null,
          type: payload.content_type,
        };
      }

      return message;
    });

    return messages;
  },
);

export const getMessagesStateByChatId = createSelector(
  [
    (state, chatId) => R.pathOr(false, ['chats', 'chatMessages', chatId, 'isLoading'], state),
    (state, chatId) => R.pathOr(false, ['chats', 'chatMessages', chatId, 'isLoadingMore'], state),
    (state, chatId) => R.pathOr(false, ['chats', 'chatMessages', chatId, 'isExistMore'], state),
  ],

  (isLoading, isLoadingMore, isExistMore) => ({
    isLoading,
    isLoadingMore,
    isExistMore,
  }),
);

export const getIsTypingMessageByChatId = createSelector(
  [(state, chatId) => R.pathOr(false, ['chats', 'chatEntities', chatId, 'isTypingMessage'], state)],
  (isTypingMessage) => isTypingMessage,
);

export const getChatsWithUser = createSelector(
  [(state) => R.pathOr({}, ['chats', 'chatEntities'], state), (_, userId) => userId],
  (entities, userId) => {
    return Object.values(entities).filter((entity) => {
      return parseInt(entity?.interlocutor_id, 10) === parseInt(userId, 10);
    });
  },
);

export default {
  getChats,
  getSupportChatsForAdmin,
  getBuyChats,
  getSellChats,
  getPersonalChats,
  getSearchedChats,
  getArchivedChats,
  getChatInfoById,
  getMessagesList,
  getMessagesStateByChatId,
  getIsUnreadInBuyTab,
  getIsUnreadInSellTab,
  getIsUnreadInPersonalTab,
  getIsTypingMessageByChatId,
  getChatsWithUser,
};
