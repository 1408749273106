import { css } from 'styled-components';
import { Platform, I18nManager } from 'react-native';
import colors from './colors';
import { isRTL } from '../utils/rtlHelper';

const shadowIos = css`
  shadow-color: ${colors.black};
  shadow-opacity: 0.3;
  shadow-radius: 2;
  text-shadow-offset: 6px 14px;
`;

const shadowAndroid = css`
  elevation: 6;
`;

export const rtlScale = css`
  transform: ${I18nManager.isRTL ? 'scaleX(-1)' : 'scaleX(1)'};
`;

export const shadow = Platform.OS === 'ios' ? shadowIos : shadowAndroid;

export const rotate =
  Platform.OS === 'ios'
    ? css`
        transform: rotate(180deg);
      `
    : '';

export const rtlScaleStyle = {
  transform: [{ scaleX: isRTL ? -1 : 1 }],
};
