import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../styles';

export const BUTTON_ITEM_HEIGHT = 60;

export default StyleSheet.create({
  panelHeader: {
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderTopWidth: dimensions.hairline,
    borderLeftWidth: dimensions.hairline,
    borderRightWidth: dimensions.hairline,
    borderColor: colors.gray,
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayLight,
    paddingTop: dimensions.halfMedium / 2,
  },
  panel: {
    backgroundColor: colors.white,
  },
  panelContainer: {
    height: 40,
    paddingHorizontal: dimensions.medium,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  panelButton: {
    height: BUTTON_ITEM_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: dimensions.medium,
  },
  background: {
    backgroundColor: colors.black,
    flex: 1,
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  itemImageBackground: {
    width: 36,
    height: 36,
    borderRadius: 18,
    marginRight: dimensions.medium,
  },
  itemImage: {
    width: 36,
    height: 36,
    borderRadius: 18,
  },
  iphoneXBottomPadding: {
    height: 24,
  },
});
