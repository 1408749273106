import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import { FlatList } from 'react-native';
import { Container, EmptyList, HeaderContainer, Text } from '../../../ReusableComponents';
import { CityItem, AdditionalItem } from './components';
import { rs } from '../../../../styles';
import strings from '../../../../localization';
import { lotsOperations } from '../../../../store/lots';
import {
  LoadingService,
  ModalsService,
  NavigationService,
  PermissionService,
  ToastsService,
} from '../../../../services';
import { citiesSelector, citiesOperations } from '../../../../store/communityCities';
import { useDebouncedCallback } from 'use-debounce';
import Spinner from '../../../ReusableComponents/Spinner';
import SearchBar from '../../../ReusableComponents/SearchBar';
import screens from '../../../../navigation/screens';
import { userLocationsSelectors } from '../../../../store/userLocations';
import modalTypes from '../../../../constants/modalTypes';
import { appOperations } from '../../../../store/app';
import { sortingTypes } from '../../../../constants/sortingTypes';

const CitiesFilter = ({
  isLoading,
  isLoadingMore,
  cities,
  getCommunityCities,
  setFeedLotFilters,
  hasPrimaryLocation,
  isLocationPermissionGranted,
  handleGeolocation,
  setLocationPermission,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    return () => {
      getCommunityCities({ isLoadMore: false });
    };
  }, []);

  const [getOffersDebounce] = useDebouncedCallback((newSearchTerm) => {
    getCommunityCities({
      searchTerm: newSearchTerm,
    });
  }, 400);

  const onLoadMore = () => {
    getCommunityCities({
      isLoadMore: true,
      searchTerm,
    });
  };

  const onChangeSearch = async (value) => {
    setSearchTerm(value);
    getOffersDebounce(value);
  };

  const onSelect = (city) => {
    setFeedLotFilters({
      selectedCities: [city],
      isSearchByPrimaryLocation: false,
      coordinates: null,
    });
    NavigationService.navigate(screens.MarketplaceTab, {
      screen: screens.Marketplace,
    });
  };

  const getCoordinatesAndFetchItems = async () => {
    LoadingService.showLoader();
    const coordinates = await handleGeolocation();
    LoadingService.hideLoader();

    if (!coordinates) {
      ToastsService.showError();
      return;
    }
    setFeedLotFilters({
      selectedCities: [],
      isSearchByPrimaryLocation: false,
      coordinates,
      sortBy: sortingTypes.by_distance,
    });
    NavigationService.navigate(screens.MarketplaceTab, {
      screen: screens.Marketplace,
    });
  };

  const onSelectNearMe = async () => {
    if (isLocationPermissionGranted) {
      await getCoordinatesAndFetchItems();
    } else {
      const isGrantedLocationPermission = await PermissionService.checkAndRequestGeolocationPermission();
      setLocationPermission(isGrantedLocationPermission);
      if (isGrantedLocationPermission) {
        await getCoordinatesAndFetchItems();
      }
    }
  };
  const onSelectNearPrimaryLocation = () => {
    if (hasPrimaryLocation) {
      setFeedLotFilters({
        selectedCities: [],
        isSearchByPrimaryLocation: true,
        coordinates: null,
        sortBy: sortingTypes.by_distance,
      });
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    } else {
      ModalsService.showModal(modalTypes.ADD_LOCATION);
    }
  };

  return (
    <Container>
      <HeaderContainer withoutBorderBottom>
        <SearchBar
          showCancel
          alwaysShowCancel
          value={searchTerm}
          placeholder={strings.other.search}
          onCancel={() => NavigationService.goBack()}
          onChangeText={onChangeSearch}
          enablesReturnKeyAutomatically
          returnKeyType="search"
        />
      </HeaderContainer>

      <FlatList
        keyExtractor={(element) => element.id.toString()}
        keyboardShouldPersistTaps={'handled'}
        data={isLoading ? [] : cities}
        initialNumToRender={20}
        onEndReachedThreshold={0.5}
        onEndReached={onLoadMore}
        ListEmptyComponent={
          isLoading ? <Spinner /> : <EmptyList text={strings.emptyLists.empty_cities_list} />
        }
        ListHeaderComponent={
          <Fragment>
            <AdditionalItem
              image="explore_nearby"
              title={strings.marketplace.explore_near_current_location}
              onPress={onSelectNearMe}
            />

            <AdditionalItem
              image="explore_near_home"
              title={strings.marketplace.explore_near_primary_location}
              onPress={onSelectNearPrimaryLocation}
            />

            <Text
              style={[rs.bigPaddingTop, rs.paddingBottom, rs.paddingHorizontal]}
              medium
              semiBold
            >
              {strings.marketplace.popular_cities}
            </Text>
          </Fragment>
        }
        renderItem={({ item, index }) => <CityItem item={item} onPress={onSelect} />}
        ListFooterComponent={isLoadingMore && <Spinner />}
      />
    </Container>
  );
};

CitiesFilter.propTypes = {
  cities: T.array,
};

export default connect(
  (state) => ({
    isLocationPermissionGranted: state.app.isLocationPermissionGranted,
    isLoading: state.communityCities.isLoading,
    isLoadingMore: state.communityCities.isLoadingMore,
    cities: citiesSelector.getCommunityCities(state),
    hasPrimaryLocation: userLocationsSelectors.hasPrimaryLocation(state),
  }),
  (dispatch) => ({
    setFeedLotFilters: (feedLotFilters) =>
      dispatch(lotsOperations.setFeedLotFilters(feedLotFilters)),
    handleGeolocation: () => dispatch(appOperations.handleGeolocation()),
    setLocationPermission: (isGranted) => dispatch(appOperations.setLocationPermission(isGranted)),
    getCommunityCities: ({ searchTerm, isLoadMore }) =>
      dispatch(
        citiesOperations.getCommunityCities({
          searchTerm,
          isLoadMore,
        }),
      ),
  }),
)(CitiesFilter);
