import React, { Fragment, memo } from 'react';
import * as R from 'ramda';
import T from 'prop-types';
import { FontIcon, Separator, Sizes } from '../../../../ReusableComponents';
import { colors } from '../../../../../styles';
import Title from './Title';
import { Box, TitleContainer } from './styles';
import strings from '../../../../../localization';

const SizesSelector = memo(
  ({ sizes, activeSize, onSelectSize }) =>
    !R.isEmpty(sizes) &&
    !!sizes && (
      <Fragment>
        <Box withoutPadding>
          <TitleContainer>
            <FontIcon
              size={20}
              withLeftPadding
              withRightSmallPadding
              name="size"
              color={colors.text}
            />
            <Title withPadding>{strings.lots.size}</Title>
          </TitleContainer>

          <Sizes sizes={sizes} activeSize={activeSize} onSelectSize={onSelectSize} />
        </Box>
        <Separator medium />
      </Fragment>
    ),
);

SizesSelector.propTypes = {
  sizes: T.array,
  activeSize: T.string,
  onSelectSize: T.func,
};

export default SizesSelector;
