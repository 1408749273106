import foundationsAPI from '../../api/foundations';
import * as actions from './actions';
import { normalize } from '../../utils/stateHelper';
import { COMMON_LIMIT } from '../../constants/listLimits';

const getFoundations = (isRefresh = false) => async (dispatch, getState) => {
  if (isRefresh) {
    dispatch(actions.foundationsRefreshStart());
  } else {
    dispatch(actions.foundationsStart());
  }

  try {
    const { id: communityId } = getState().communityInfo;
    const data = await foundationsAPI.getFoundations(communityId);

    const normalizedFoundations = normalize(data, 'ids', 'entities');

    dispatch(
      actions.foundationsSuccess({
        normalizedFoundations,
        isExistMore: data.length === COMMON_LIMIT,
      }),
    );
  } catch (error) {
    dispatch(actions.foundationsError(error));
  }
};

const getMoreFoundations = () => async (dispatch, getState) => {
  const { foundations, communityInfo } = getState();
  const { ids, isLoading, isLoadingMore, isExistMore } = foundations;
  const skip = ids.length;

  if (isLoadingMore || isLoading || !isExistMore) {
    return;
  }

  dispatch(actions.foundationsMoreStart());

  try {
    const data = await foundationsAPI.getFoundations(communityInfo.id, skip);

    const normalizedFoundations = normalize(data, 'ids', 'entities');

    dispatch(
      actions.foundationsMoreSuccess({
        normalizedFoundations,
        isExistMore: data.length === COMMON_LIMIT,
      }),
    );
  } catch (error) {
    dispatch(actions.foundationsError(error));
  }
};

export default {
  getFoundations,
  getMoreFoundations,
};
