import React from 'react';
import T from 'prop-types';
import { StyleSheet, View, Linking } from 'react-native';
import { connect } from 'react-redux';
import { colors, dimensions, rs } from '../../../../styles';
import strings from '../../../../localization';
import { Button, Text } from '../../';
import { isIphoneX } from '../../../../utils/detectDevice';
import { PRIVACY_POLICY_URL } from '../../../../api/urls';
import { authOperations } from '../../../../store/auth';
import { LoadingService } from '../../../../services';

const s = StyleSheet.create({
  rootContainer: {
    flex: 1,
    paddingTop: 30,
    justifyContent: 'flex-end',
  },
  contentContainer: {
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingTop: dimensions.medium * 3,
    paddingHorizontal: dimensions.medium,
    paddingBottom: isIphoneX ? 60 : 40,
  },
  description: {
    marginBottom: 50,
    marginTop: dimensions.doubleMedium,
    lineHeight: 22,
  },
  submitButtonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
  },
});

const LegalInfo = ({ applyLegalDocumentsUpdate, onClose }) => {
  const onSubmit = async () => {
    LoadingService.showLoader();
    await applyLegalDocumentsUpdate();
    LoadingService.hideLoader();
    onClose();
  };

  const onOpenUrl = () => {
    Linking.openURL(PRIVACY_POLICY_URL);
  };

  return (
    <View style={s.rootContainer}>
      <View style={s.contentContainer}>
        <Text semiBold medium>
          {strings.login.legal_documents_title}
        </Text>
        <Text color={colors.gray} style={s.description}>
          {strings.login.legal_documents_description}
        </Text>

        <View style={s.submitButtonContainer}>
          <Button title={strings.login.i_agree} onPress={onSubmit} />
        </View>

        <View style={[s.submitButtonContainer, rs.marginTop]}>
          <Button
            title={strings.login.legal_documents_button_title}
            onPress={onOpenUrl}
            secondaryType
          />
        </View>
      </View>
    </View>
  );
};

LegalInfo.propTypes = {
  applyLegalDocumentsUpdate: T.func.isRequired,
  onClose: T.func.isRequired,
};

export default connect(null, (dispatch) => ({
  applyLegalDocumentsUpdate: () => dispatch(authOperations.applyLegalDocumentsUpdate()),
}))(LegalInfo);
