import { I18nManager } from 'react-native';
import { isWeb } from './detectDevice';

// https://github.com/necolas/react-native-web/issues/2350
export const isRTL = isWeb ? I18nManager.getConstants().isRTL : I18nManager.isRTL;

export const getIsRTLString = (str) => {
  const hebrewRegExp = new RegExp(/^[\u0590-\u05fe]+/i);

  return hebrewRegExp.test(str);
};
