import React, { Fragment } from 'react';
import * as R from 'ramda';
import T from 'prop-types';
import { CheckBox } from '../../../../ReusableComponents';
import { BrandView, BrandNameText } from './styles';

class BrandItem extends React.PureComponent {
  render() {
    const { brand, onSelect, selectedBrands } = this.props;

    const isActive = R.includes(brand, selectedBrands);

    return (
      <Fragment>
        <BrandView onPress={onSelect} useOpacity={false}>
          <Fragment>
            <BrandNameText isActive={isActive}>{brand.name}</BrandNameText>
            <CheckBox checked={isActive} onToggle={onSelect} />
          </Fragment>
        </BrandView>
      </Fragment>
    );
  }
}

BrandItem.propTypes = {
  brand: T.object,
  onSelect: T.func,
  selectedBrands: T.array,
};

export default BrandItem;
