import React, { Fragment, memo } from 'react';
import { Text, TouchableItem, Container, Image } from '../../ReusableComponents';
import s from './styles';
import { ScrollView, View } from 'react-native';
import { rs, colors } from '../../../styles';
import { NavigationService } from '../../../services';
import screens from '../../../navigation/screens';
import strings from '../../../localization';
import { useDispatch } from 'react-redux';
import { chatsOperations } from '../../../store/chats';
import { isWeb } from '../../../utils/detectDevice';

const Item = memo(({ title, description, image, onSelect }) => (
  <TouchableItem style={[s.itemContainer]} onPress={onSelect}>
    <Fragment>
      <Image
        style={s.image}
        resizeMode="cover"
        source={{
          uri: image,
        }}
      />
      <View style={[rs.paddingHorizontal, rs.flex, rs.justifyCenter]}>
        <Text semiBold medium>
          {title}
        </Text>
        <Text color={colors.gray} style={rs.verySmallPaddingTop}>
          {description}
        </Text>
      </View>
    </Fragment>
  </TouchableItem>
));

const HorizontalGroupsList = memo(() => {
  const dispatch = useDispatch();

  const onOpenShortShareittInfo = () => {
    NavigationService.navigate(screens.ShortShareittInfo);
  };
  const onOpenSupportChat = () => {
    dispatch(chatsOperations.openSupportChat());
  };
  const onOpenFAQ = () => {
    NavigationService.navigate(screens.FAQ);
  };
  const onOpenRules = () => {
    NavigationService.navigate(screens.CommunityRules);
  };
  const onOpenShippingInfo = () => {
    NavigationService.navigate(screens.ShippingInfo);
  };
  const onOpenGiftsInfo = () => {
    NavigationService.navigate(screens.GiftsInfo);
  };
  const onOpenPhotoTips = () => {
    NavigationService.navigate(screens.PhotoTips);
  };

  return (
    <Container>
      <ScrollView contentContainerStyle={[s.listContentContainerStyle, isWeb && rs.webContainer]}>
        {/*<Item*/}
        {/*  title={strings.home.why_shareitt}*/}
        {/*  description={strings.support.shareitt_description}*/}
        {/*  image="all_you_need_to_know_in_10_secs"*/}
        {/*  onSelect={onOpenShortShareittInfo}*/}
        {/*/>*/}

        <Item
          title={strings.support.chat}
          description={strings.support.chat_description}
          image="all_you_need_to_know_in_10_secs"
          onSelect={onOpenSupportChat}
        />

        <Item
          title={strings.support.faq}
          description={strings.support.faq_description}
          image="faq"
          onSelect={onOpenFAQ}
        />

        <Item
          title={strings.rules.conduct_rules}
          description={strings.support.rules_description}
          image="community_rules"
          onSelect={onOpenRules}
        />

        <Item
          title={strings.home.tokens}
          description={strings.support.tokens_description}
          image="presents"
          onSelect={onOpenGiftsInfo}
        />

        <Item
          title={strings.home.shipping}
          description={strings.support.delivery_description}
          image="shipping"
          onSelect={onOpenShippingInfo}
        />

        <Item
          title={strings.home.image_pictures}
          description={strings.support.image_pictures_description}
          image="rules"
          onSelect={onOpenPhotoTips}
        />
      </ScrollView>
    </Container>
  );
});

export default HorizontalGroupsList;
