import React from 'react';
import { FlatList } from 'react-native';
import T from 'prop-types';
import { Icon, Separator, TouchableItem } from '../../../ReusableComponents';
import { colors, rs } from '../../../../styles';
import { isRTL } from '../../../../utils/rtlHelper';
import ListItem from '../../../ReusableComponents/Lists/CommunityMembersList/ListItem/ListItem';
import strings from '../../../../localization';
import { getTimeForNotification } from '../../../../utils/dateHelper';

const MembersHistory = ({
  communityMembersSearchHistory,
  forceNavigateToProfile,
  onSaveMemberSearch,
  onRemoveMemberSearch,
}) => {
  const showRemoveMemberSearch = !!onRemoveMemberSearch;

  return (
    <FlatList
      data={communityMembersSearchHistory}
      keyExtractor={({ id }) => id.toString()}
      ItemSeparatorComponent={() => <Separator />}
      renderItem={({ item }) => {
        return (
          <ListItem
            userId={item.user_id}
            name={item.user_name}
            nickname={item.nickname}
            profileImage={item.profile_image}
            forceNavigateToProfile={forceNavigateToProfile}
            additionalInfo={`${strings.profile.last_seen.replace(
              /__TIME__/,
              getTimeForNotification(item.last_seen_at),
            )}`}
            onPress={() => onSaveMemberSearch(item.user_id, item)}
            RightComponent={() => {
              if (!showRemoveMemberSearch) {
                return null;
              }

              return (
                <TouchableItem
                  onPress={() => onRemoveMemberSearch({ memberId: item.id, userId: item.user_id })}
                  style={[rs.verySmallPaddingHorizontal, rs.verySmallPaddingVertical]}
                >
                  <Icon
                    size={22}
                    name="x"
                    style={{
                      color: colors.grayLight,
                      transform: [{ scaleX: isRTL ? -1 : 1 }],
                    }}
                  />
                </TouchableItem>
              );
            }}
          />
        );
      }}
      style={rs.paddingBottom}
    />
  );
};

MembersHistory.propTypes = {
  communityMembersSearchHistory: T.array,
  forceNavigateToProfile: T.bool,
  onSaveMemberSearch: T.func,
  onRemoveMemberSearch: T.func,
};

MembersHistory.defaultProps = {
  forceNavigateToProfile: true,
};

export default MembersHistory;
