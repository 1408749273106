import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';

import types from './types';

const initialState = {
  language: null,
  doubleColumnLists: false,

  isOnline: true,
  isNotificationsAuthorized: false,
  isLocationPermissionGranted: false,
  isContactsPermissionGranted: false,

  lastAccessDateInCurrentSession: null,

  geolocationCoordinates: {
    longitude: null,
    latitude: null,
    // longitude: 34.9872828,
    // latitude: 32.8254101,
  },
  contacts: [],
};

const authReducer = handleActions(
  {
    [types.SET_LANGUAGE]: mergeDeep(({ payload }) => ({
      language: payload,
    })),
    [types.SET_FEED_COLUMN_STYLE]: mergeDeep(({ payload }, state) => ({
      doubleColumnLists: payload === 2,
    })),
    [types.SET_CONNECTION]: mergeDeep(({ payload }) => ({
      isOnline: payload,
    })),
    [types.SET_NOTIFICATIONS_PERMISSION]: mergeDeep(({ payload }) => ({
      isNotificationsAuthorized: payload,
    })),
    [types.SET_LOCATION_PERMISSION]: mergeDeep(({ payload }) => ({
      isLocationPermissionGranted: payload,
    })),
    [types.SET_GEOLOCATION_COORDINATES]: mergeDeep(({ payload }) => ({
      geolocationCoordinates: {
        longitude: payload.longitude,
        latitude: payload.latitude,
      },
    })),
    [types.SET_LAST_ACCESS_DATE_IN_CURRENT_SESSION]: mergeDeep(({ payload }) => ({
      lastAccessDateInCurrentSession: new Date().getTime(),
    })),
    [types.SET_CONTACTS_PERMISSION]: mergeDeep(({ payload }) => ({
      isContactsPermissionGranted: payload.isContactsPermissionGranted,
    })),
    [types.SET_CONTACTS]: mergeDeep(({ payload }) => ({
      contacts: payload.contacts,
    })),
  },
  initialState,
);

export default authReducer;
