import React, { memo, Fragment } from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import { Placeholder, PlaceholderLine, Fade } from 'rn-placeholder';
import strings from '../../../../localization';
import { TouchableItem, Text } from '../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../styles';

const s = StyleSheet.create({
  list: {
    backgroundColor: colors.white,
    marginTop: dimensions.halfMedium,
  },
  listContentContainer: {
    paddingHorizontal: dimensions.medium - 4,
    marginTop: dimensions.halfMedium,
    marginBottom: dimensions.medium,
    alignItems: 'center',
    minWidth: '100%',
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayBorder,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    marginHorizontal: 4,
    height: 36,
    paddingHorizontal: 10,
    borderBottomWidth: 2,
    borderBottomColor: colors.transparent,
    backgroundColor: colors.white,
  },
  activeButtonContainer: {
    borderBottomColor: colors.blueLight,
  },
  amountContainer: {
    height: 16,
    minWidth: 18,
    marginLeft: dimensions.halfMedium,
    borderRadius: 4,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  placeholderContainer: {
    width: 16,
    marginLeft: dimensions.halfMedium,
  },
});

const TabBarOption = memo(({ title, count, isActive, onPress, isLoading }) => (
  <TouchableItem style={[s.buttonContainer, isActive && s.activeButtonContainer]} onPress={onPress}>
    <Fragment>
      <Text semiBold xsmall color={isActive ? colors.blueLight : colors.gray}>
        {title}
      </Text>

      {isLoading ? (
        <View style={s.placeholderContainer}>
          <Placeholder Animation={Fade}>
            <PlaceholderLine height={16} noMargin />
          </Placeholder>
        </View>
      ) : (
        <View style={s.amountContainer}>
          <Text xsmall semiBold color={isActive ? colors.blueLight : colors.gray}>
            {`(${count})`}
          </Text>
        </View>
      )}
    </Fragment>
  </TouchableItem>
));

const UserItemsTabBar = memo(
  ({
    activeTabIndex,
    onTabPress,
    activeItemsTotalAmount,
    soldItemsTotalAmount,
    wishesTotalAmount,
    isActiveItemsLoading,
    isWishesLoading,
    isSoldItemsLoading,
  }) => {
    const options = [
      {
        index: 0,
        count: activeItemsTotalAmount,
        title: strings.profile.for_sale,
        isLoading: isActiveItemsLoading,
      },
      {
        index: 1,
        count: soldItemsTotalAmount,
        title: strings.profile.sold,
        isLoading: isSoldItemsLoading,
      },
      {
        index: 2,
        count: wishesTotalAmount,
        title: strings.profile.wishes,
        isLoading: isWishesLoading,
      },
    ];

    return (
      <View style={rs.row}>
        <ScrollView
          horizontal
          bounces={false}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={s.listContentContainer}
          style={s.list}
        >
          {options.map(({ index, title, count, isLoading }) => {
            const isActive = activeTabIndex === index;
            const onPress = () => {
              onTabPress(index);
            };

            return (
              <TabBarOption
                key={index}
                title={title}
                count={count}
                isActive={isActive}
                onPress={onPress}
                isLoading={isLoading}
              />
            );
          })}
        </ScrollView>
      </View>
    );
  },
);

UserItemsTabBar.propTypes = {};

export default UserItemsTabBar;
