import React, { memo } from 'react';
import T from 'prop-types';
import s from './styles';
import { colors } from '../../../../styles';
import TouchableItem from '../../TouchableItem';
import Icon from '../../Icon';
import Text from '../../Text';

const FilterOption = memo(
  ({ isActive, isSquare = false, primaryButtonTitle, onPress, onDismissPress }) => {
    return (
      <TouchableItem
        key="SELECTOR"
        style={[
          s.buttonContainer,
          isSquare && s.squareButtonContainer,
          isActive && s.activeButtonContainer,
        ]}
        onPress={onPress}
      >
        <Text semiBold color={isActive ? colors.white : colors.gray}>
          {primaryButtonTitle}
        </Text>

        <TouchableItem onPress={onDismissPress} disabled={!isActive}>
          <Icon
            name={isActive ? 'x' : 'chevron-down'}
            size={18}
            color={isActive ? colors.white : colors.text}
            style={s.icon}
          />
        </TouchableItem>
      </TouchableItem>
    );
  },
);

FilterOption.propTypes = {
  isActive: T.bool,
  primaryButtonTitle: T.string,
  onPress: T.func,
  onDismissPress: T.func,
};

export default FilterOption;
