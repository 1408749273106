import React, { memo } from 'react';
import { View } from 'react-native';
import T from 'prop-types';
import s from './styles';
import { colors } from '../../../../../../../styles';
import { Text, TouchableItem } from '../../../../../../ReusableComponents';
import strings from '../../../../../../../localization';

const DISTANCE_OPTIONS = [1, 5, 10, 20];

const Distance = memo(({ distanceValue, onSelectDistance }) => {
  return (
    <View style={s.contentContainer}>
      {DISTANCE_OPTIONS.map((distance) => {
        const isActive = distance === distanceValue;

        return (
          <TouchableItem
            key={distance}
            style={[s.itemContainer, isActive && s.activeItemContainer]}
            onPress={() => onSelectDistance(distance)}
          >
            <Text color={isActive ? colors.white : null}>
              {distance} {strings.locations.km}
            </Text>
          </TouchableItem>
        );
      })}
    </View>
  );
});

Distance.propTypes = {
  distanceValue: T.number,
  onSelectDistance: T.func,
};

export default Distance;
