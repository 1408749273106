import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FlatList, RefreshControl, View } from 'react-native';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import strings from '../../../../localization';
import { foundationOperations } from '../../../../store/foundations';
import { getFoundationsList } from '../../../../store/foundations/selectors';
import { Spinner, Container, EmptyList } from '../../../ReusableComponents';
import FoundationItem from './components/FoundationItem';

const FoundationsList = ({
  foundations,
  isLoading,
  isLoadingMore,
  getFoundations,
  onLoadMoreFoundations,
  onRefreshFoundations,
}) => {
  const [isRefreshing, setRefreshing] = useState(false);

  useEffect(() => {
    getFoundations();
  }, []);

  const onRefresh = async () => {
    setRefreshing(true);
    await onRefreshFoundations();
    setRefreshing(false);
  };

  const onFoundationPress = (item) => {
    NavigationService.navigate(screens.DonatePoints, { item });
  };

  return (
    <Container>
      <FlatList
        data={foundations}
        keyExtractor={({ id }) => id.toString()}
        renderItem={({ item }) => (
          <FoundationItem item={item} onPress={onFoundationPress} />
        )}
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
        refreshing={isRefreshing}
        onEndReachedThreshold={0.7}
        onEndReached={onLoadMoreFoundations}
        ListFooterComponent={
          <Fragment>
            {isLoadingMore && (
              <View>
                <Spinner />
              </View>
            )}
          </Fragment>
        }
        ListEmptyComponent={
          <View>
            {isLoading ? <Spinner /> : <EmptyList text={strings.emptyLists.empty_search_results} />}
          </View>
        }
      />
    </Container>
  );
};

export default connect(
  (state) => ({
    foundations: getFoundationsList(state),
    isLoading: state.foundations.isLoading,
    isLoadingMore: state.foundations.isLoadingMore,
  }),
  (dispatch) => ({
    getFoundations: () => dispatch(foundationOperations.getFoundations()),
    onLoadMoreFoundations: () => dispatch(foundationOperations.getMoreFoundations()),
    onRefreshFoundations: () => dispatch(foundationOperations.getFoundations(true)),
  }),
)(FoundationsList);
