import React, { PureComponent } from 'react';
import T from 'prop-types';
import { StyleSheet, View, LayoutAnimation } from 'react-native';
import Text from './Text';
import TextButton from './Buttons/TextButton';
import strings from '../../localization';
import { colors } from '../../styles';

const styles = StyleSheet.create({
  contentContainer: {
    backgroundColor: colors.white,
  },
});

class ExpandableText extends PureComponent {
  constructor(props) {
    super(props);

    this.onLayout = this.onLayout.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);

    const { numberOfLines, fontSize, lineHeight } = props;

    this.state = {
      expanded: false,
      initialHeight: numberOfLines * (fontSize + (lineHeight - fontSize)),
      showMore: false,
      initialized: false,
      numberOfLines: undefined,
      ellipsizeMode: undefined,
    };
  }

  onLayout(evt) {
    const { initialized, initialHeight } = this.state;
    const { numberOfLines, ellipsizeMode } = this.props;

    if (!initialized) {
      const showMore = evt.nativeEvent.layout.height > initialHeight;

      this.setState({
        showMore,
        initialized: true,
      });
      if (showMore) {
        this.setState({
          numberOfLines,
          ellipsizeMode,
        });
      }
    }
  }

  // animations
  toggleExpand() {
    const { expanded } = this.state;
    LayoutAnimation.easeInEaseOut();
    this.setState({
      expanded: !expanded,
    });
  }

  render() {
    const { style, containerStyle, fontSize, children, fontStyle } = this.props;

    const { expanded, showMore, numberOfLines, ellipsizeMode } = this.state;

    return (
      <View style={[styles.contentContainer, containerStyle]}>
        <Text
          style={[style, fontStyle, { fontSize }]}
          numberOfLines={expanded ? undefined : numberOfLines}
          ellipsizeMode={expanded ? undefined : ellipsizeMode}
          onLayout={this.onLayout}
        >
          {children}
        </Text>
        {showMore && (
          <TextButton
            title={expanded ? strings.common.less : strings.common.see_more}
            onPress={this.toggleExpand}
          />
        )}
      </View>
    );
  }
}

ExpandableText.defaultProps = {
  numberOfLines: 3,
  fontSize: 14,
  lineHeight: 16,
};

ExpandableText.propTypes = {
  fontSize: T.number,
  lineHeight: T.number,
  style: T.any,
  containerStyle: T.any,
  children: T.string,
  numberOfLines: T.number,
  fontStyle: T.any,
  ellipsizeMode: T.string,
};

export default ExpandableText;
