import React, { useState } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import * as R from 'ramda';
import Button from '../../Buttons/Button';
import Text from '../../Text';
import { MainContainer } from './styles';
import strings from '../../../../localization';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import { colors, rs, dimensions } from '../../../../styles';
import { connect } from 'react-redux';
import guideTypes from '../../../../constants/guidelTypes';
import { guideOperations } from '../../../../store/guides';
import TouchableItem from '../../TouchableItem';
import SquareCheckBox from '../../SquareCheckBox';

const s = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.white,
    height: 100,
    width: 100,
    borderRadius: 50,
    position: 'absolute',
    top: -40,
    alignItems: 'center',
    paddingTop: dimensions.medium,
  },

  buttonContainer: {
    width: '100%',
    height: 40,
    marginTop: dimensions.doubleMedium,
    paddingHorizontal: dimensions.medium,
    flexDirection: 'row',
  },

  dontShowAgainContainer: {
    paddingTop: 20,
    alignSelf: 'center',
  },
});

const ItemReview = ({ onPress, neverShowGuideAgain, params }) => {
  const [isChecked, setChecked] = useState(false);
  const isWish = R.pathOr(false, ['isWish'], params);

  const onConfirm = () => {
    if (isChecked) {
      neverShowGuideAgain({ guideType: guideTypes.ITEM_REVIEW });
    }
    onPress();

    if (!!isWish) {
      NavigationService.navigate(screens.EditWishStack, { screen: screens.EditWish });
    } else {
      NavigationService.navigate(screens.EditItemStack, {
        screen: screens.EditItem,
      });
    }
  };

  return (
    <View style={rs.alignCenter}>
      <View style={s.mainContainer}>
        <Image source={{ uri: 'owl_security' }} style={{ height: 64, width: 54 }} />
      </View>
      <MainContainer>
        <Text semiBold medium alignCenter style={[rs.bigPaddingTop, rs.bigPaddingHorizontal]}>
          {strings.modals.item_review.title}
        </Text>
        <Text alignCenter style={rs.paddingTop}>
          {strings.modals.item_review.description}
        </Text>
        <View style={s.buttonContainer}>
          <Button title={strings.common.confirm} onPress={onConfirm} />
        </View>

        <View style={s.dontShowAgainContainer}>
          <TouchableItem style={[rs.row, rs.alignCenter]} onPress={() => setChecked(!isChecked)}>
            <SquareCheckBox selected={isChecked} />
            <Text color={colors.gray} xsmall>
              {strings.common.dont_show_again}
            </Text>
          </TouchableItem>
        </View>
      </MainContainer>
    </View>
  );
};

export default connect(
  (state) => ({
    isShowWishesBanner: state.guides.showAgain[guideTypes.ITEM_REVIEW],
  }),
  (dispatch) => ({
    neverShowGuideAgain: (prop) => dispatch(guideOperations.neverShowGuideAgain(prop)),
  }),
)(ItemReview);
