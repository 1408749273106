import React, { Fragment, memo } from 'react';
import T from 'prop-types';
import { AngleIcon, TouchableItem, Separator, Text } from '../../../../ReusableComponents';
import { colors, rs } from '../../../../../styles';
import Title from './Title';
import s from './styles';
import NavigationService from '../../../../../services/NavigationService';
import strings from '../../../../../localization';

const CategoriesSelector = memo(({ selectedCategory, categories, onSelectCategory }) => (
  <TouchableItem
    style={s.buttonContainer}
    useOpacity={false}
    onPress={() =>
      NavigationService.navigate('Categories', {
        selectedCategory,
        categories,
        onSelectCategory,
      })
    }
  >
    <Fragment>
      <Text medium style={s.buttonTitle}>
        {strings.lots.category}
      </Text>
      {selectedCategory.parentCategory ? (
        <Text semiBold color={colors.blueLight}>
          {selectedCategory.parentCategory.name} : {selectedCategory.childCategory.name}
        </Text>
      ) : (
        <Text color={colors.gray}>{strings.filters.select_category}</Text>
      )}
      <AngleIcon />
    </Fragment>
  </TouchableItem>
));

CategoriesSelector.propTypes = {
  selectedCategory: T.object,
};

export default CategoriesSelector;
