import styled from 'styled-components/native';
import { StyleSheet, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Text, TouchableItem } from '../../../ReusableComponents';
import { colors, dimensions, fontSizes } from '../../../../styles';

export const ContentContainer = styled(KeyboardAwareScrollView)`
  margin-vertical: ${dimensions.medium};
  margin-horizontal: ${dimensions.doubleMedium};
`;

export const Title = styled(Text)``;

export const Subtitle = styled(Text)`
  margin-top: ${dimensions.halfMedium};
  margin-bottom: 40px;
  line-height: 20px;
`;

export const InfoText = styled(Text)`
  margin-top: ${dimensions.medium};
`;

export const Separator = styled.View`
  height: ${dimensions.doubleMedium};
`;

export const LocationsContainer = styled.View`
  padding-vertical: ${dimensions.halfMedium};
  padding-horizontal: ${dimensions.halfMedium};
`;

const s = StyleSheet.create({
  shippingOptionInner: {
    marginLeft: 36,
  },
  locationsListItem: {
    marginBottom: dimensions.medium,
  },
  shippingPriceInput: {
    fontSize: dimensions.medium,
    maxWidth: 140,
    marginTop: dimensions.halfMedium,
    paddingVertical: dimensions.halfMedium,
    paddingHorizontal: dimensions.halfMedium,
    borderBottomWidth: 1,
    borderBottomColor: colors.gray,
  },
  addressText: {
    lineHeight: 18,
  },
});

export default s;
