import React, { Component } from 'react';
import { Alert, Animated, View } from 'react-native';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import moment from 'moment';
import { offersOperations, offersSelectors } from '../../../../store/offers';
import { chatsOperations } from '../../../../store/chats';
import { lotsOperations, lotsSelectors } from '../../../../store/lots';
import { getUserPickupLocations } from '../../../../store/userLocations/selectores';
import { dateAfterXDays, lessThanWeekAgo } from '../../../../utils/dateHelper';
import { createDynamicLink, shareExistingLink } from '../../../../utils/shareHelper';
import { OPEN_ITEM_DETAILS } from '../../../../constants/dynamicalLinkActionTypes';
import { subscriptionsOperations } from '../../../../store/subscriptions';
import { Container } from '../../../ReusableComponents';
import NavigationService from '../../../../services/NavigationService';
import screens from '../../../../navigation/screens';
import strings from '../../../../localization';
import { ModalsService, LoadingService } from '../../../../services';
import modalTypes from '../../../../constants/modalTypes';
import { reportTypes } from '../../../../constants/reports';
import itemStatuses from '../../../../constants/itemStatuses';
import ItemDetailsView from './components/ItemDetailsView';
import BottomBar from './components/BottomBar';
import OfferButtonsContainer from './components/OfferButtonsContainer';
import { communitySelectors } from '../../../../store/communityInfo';

@connectActionSheet
class ItemDetails extends Component {
  state = {
    scrollY: new Animated.Value(0),
    itemLink: null,
    isLoading: true,
  };

  async componentDidMount() {
    const { getItemDetails, route, item, getItemInfo, getActiveOfferByItemId, user } = this.props;

    const itemId = route.params.itemId;

    if (R.isEmpty(item)) {
      LoadingService.showLoader();
      await getItemInfo(itemId);
      LoadingService.hideLoader();

      getActiveOfferByItemId(itemId);
    } else if (Number(item.seller_id) !== user.id) {
      getActiveOfferByItemId(itemId);
    }

    await getItemDetails(itemId);
    this.setState({ isLoading: false });
  }

  setItemLink = async () => {
    const { item } = this.props;

    const link = await createDynamicLink({
      actionType: OPEN_ITEM_DETAILS,
      id: item.id,
      communityId: item.group_id,
      socialImageUrl: item.item_image,
      socialTitle: item.title,
      socialDescription: item.description,
      linkDescription: strings.deep_linking.item_link_info,
    });

    this.setState({ itemLink: link });
  };

  removeItem = () => {
    const { removeItem, item } = this.props;

    Alert.alert(strings.items.delete_item, strings.items.sure_delete, [
      {
        text: strings.common.delete,
        onPress: () => {
          removeItem({ item_id: item.id, seller_id: item.seller_id });
          NavigationService.goBack();
        },
      },
      {
        text: strings.common.cancel,
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
    ]);
  };

  handleHideItem = () => {
    const { deactivateItem, activateItem, item } = this.props;

    if (item.status === itemStatuses.ACTIVE) {
      Alert.alert(strings.items.hide_item, strings.items.sure_hide, [
        {
          text: 'Hide',
          onPress: () => {
            deactivateItem({ item_id: item.id, account_id: item.seller_id });
            NavigationService.goBack();
          },
        },
        {
          text: strings.common.cancel,
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
      ]);
    } else {
      Alert.alert(strings.items.unhide_item, strings.items.sure_unhide, [
        {
          text: 'Unhide',
          onPress: () => {
            activateItem({ item_id: item.id, account_id: item.seller_id });
            NavigationService.goBack();
          },
        },
        {
          text: strings.common.cancel,
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
      ]);
    }
  };

  onOpenActionSheet = () => {
    const { item, wish, showActionSheetWithOptions } = this.props;
    const options = [
      strings.common.delete,
      strings.common.edit,
      strings.common.cancel,
    ];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 2;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          setTimeout(() => this.removeItem(), 500);
        }
        if (buttonIndex === 1) {
          NavigationService.navigate(screens.EditItemStack, {
            screen: screens.EditItem,
            params: {
              item,
              wish,
              images: item.images, // take from item in the future
              locations: item.locations,
            },
          });
        }
      },
    );
  };

  openItemReportModal = () => {
    const { item, user } = this.props;

    ModalsService.showSwipeableModal(modalTypes.REPORT, {
      reportType: reportTypes.item,
      reporterUserId: user.id,
      reportedUserId: item.seller_id,
      itemId: item.id,
    });
  };

  openUserReportModal = () => {
    const { item, user } = this.props;

    ModalsService.showSwipeableModal(modalTypes.REPORT, {
      reportType: reportTypes.profile,
      reporterUserId: user.id,
      reportedUserId: item.seller_id,
    });
  };

  onOpenBuyerActionSheet = () => {
    const { item, isUserAdmin, showActionSheetWithOptions } = this.props;

    const isActive = item.status === itemStatuses.ACTIVE;
    const isHidden = item.status === itemStatuses.HIDDEN;

    const options = [
      strings.user.report_item,
      strings.user.report_user,
      strings.common.cancel,
    ];

    if (isUserAdmin) {
      if (isActive) {
        options.splice(2, 0, strings.items.hide_item);
      } else if (isHidden) {
        options.splice(2, 0, strings.items.unhide_item);
      }
    }

    const cancelButtonIndex = options.length - 1;
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          this.openItemReportModal();
        }
        if (buttonIndex === 1) {
          this.openUserReportModal();
        }
        if (isUserAdmin && buttonIndex === 2 && (isActive || isHidden)) {
          setTimeout(() => this.handleHideItem(), 500);
        }
      },
    );
  };

  onOpenChatRoom = async () => {
    const { item, openItemChat } = this.props;

    openItemChat(item);
  };

  onAddItemToFavorites = () => {
    const { item, addItemToFavorites, removeItemFromFavorites } = this.props;
    const { mark_as_favorite } = item;

    if (mark_as_favorite) {
      removeItemFromFavorites(item.id);
    } else {
      addItemToFavorites(item.id);
    }
  };

  onAddItemToTop = () => {
    const { item, unmarkItemAsTop, markItemAsTop } = this.props;
    const { is_top_item } = item;

    if (is_top_item) {
      unmarkItemAsTop(item.id);
    } else {
      markItemAsTop(item.id);
    }
  };

  onApplyDiscount = (discountRate) => {
    const { item, applyDiscount } = this.props;

    applyDiscount(item.id, discountRate);
  };

  onDiscountPress = async () => {
    const { item } = this.props;

    ModalsService.showModal(modalTypes.DISCOUNT, {
      price: item.price,
      discountsUsedForItem: item.discounts_used_for_item,
      onSubmit: this.onApplyDiscount,
    });
  };

  onDiscountUnavailablePress = async () => {
    const { item, discountDetails } = this.props;

    const isNoItemDiscountLeft =
      item.discounts_used_for_item >= discountDetails.max_discounts_per_item;
    const isNoAccountDiscountLeft =
      discountDetails.discounts_used >= discountDetails.max_discounts_per_period;
    const isNewItem = lessThanWeekAgo(item.created_at);

    if (isNoItemDiscountLeft) {
      Alert.alert(
        strings.discounts.alert_no_item_discount_left_title,
        strings.discounts.alert_no_item_discount_left_text
          .replace('XXX', item.discounts_used_for_item)
          .replace('YYY', discountDetails.max_discounts_per_item),
        [{ text: strings.common.ok, onPress: () => {} }],
      );
    } else if (isNoAccountDiscountLeft) {
      Alert.alert(
        strings.discounts.alert_no_account_discount_left_title,
        strings.discounts.alert_no_account_discount_left_text.replace(
          'XXX',
          discountDetails.period_in_days,
        ),
        [{ text: strings.common.ok, onPress: () => {} }],
      );
    } else if (isNewItem) {
      Alert.alert(
        strings.discounts.alert_use_discount_for_new_item_title,
        strings.discounts.alert_use_discount_for_new_item_text.replace(
          'XXX',
          dateAfterXDays(item.created_at, 7),
        ),
        [{ text: strings.common.ok, onPress: () => {} }],
      );
    }
  };

  onItemShare = async () => {
    ModalsService.showSwipeableModal(modalTypes.IN_APP_SHARING, {
      onSubmit: (user) => {
        this.props.openPersonalChat(
          {
            userId: user.id,
            profileImage: user.profile_image,
            selectedUserName: user.name,
          },
          this.state.itemLink,
        );
      },
      onOutsideSharePress: () => {
        shareExistingLink(this.state.itemLink, strings.deep_linking.item_link_info);
      },
    });

    await this.setItemLink();
  };

  render() {
    const { scrollY } = this.state;
    const {
      item,
      wish,
      offer,
      user,
      discountDetails,
      isUserAdmin,
      isLoadingOffer,
      userPickupLocations,
      route,
    } = this.props;

    const { seller_image, seller_name, seller_id, id, status, isSellerBlocked } = item;

    const isActive = status === itemStatuses.ACTIVE;
    const isHidden = status === itemStatuses.HIDDEN;

    const isUnavailableDiscount =
      item.discounts_used_for_item >= discountDetails.max_discounts_per_item ||
      discountDetails.discounts_used >= discountDetails.max_discounts_per_period ||
      moment().diff(item.created_at, 'days') < 7;

    const isCurrentUser = Number(item.seller_id) === user.id;
    const isWisher = Number(R.prop('wisher_id', wish)) === user.id;

    const isVisibleWishInfo = (isCurrentUser && R.prop('wish_id', item)) || isWisher;

    let onOptionsButtonPress = null;
    if (isCurrentUser && (isActive || isHidden)) {
      onOptionsButtonPress = this.onOpenActionSheet;
    } else if (!isCurrentUser && (isUserAdmin || isActive)) {
      onOptionsButtonPress = this.onOpenBuyerActionSheet;
    }
    return (
      <Container>
        <ItemDetailsView
          scrollY={scrollY}
          item={item}
          onOptionsButtonPress={onOptionsButtonPress}
          isCurrentUser={isCurrentUser}
          isAdmin={isUserAdmin}
          onAddItemToFavorites={this.onAddItemToFavorites}
          onAddItemToTop={this.onAddItemToTop}
          onItemShare={this.onItemShare}
          wishTitle={R.prop('title', wish)}
          isVisibleWishInfo={isVisibleWishInfo}
          userLocations={userPickupLocations}
          previousScreenName={route.params.screenName}
        />

        <BottomBar
          userAvatar={seller_image}
          userName={seller_name}
          isActive={isActive}
          userId={seller_id}
          isCurrentUser={isCurrentUser}
          onOpenChatRoom={this.onOpenChatRoom}
          onDiscountPress={
            isUnavailableDiscount ? this.onDiscountUnavailablePress : this.onDiscountPress
          }
          isUnavailableDiscount={isUnavailableDiscount}
          isUserBlocked={isSellerBlocked}
          isLoading={this.state.isLoading}
        >
          <OfferButtonsContainer
            isLoadingOffer={isLoadingOffer}
            offer={offer}
            isCurrentUserBuyer={!isCurrentUser}
            isItemActive={isActive}
            itemId={id}
          />
        </BottomBar>
      </Container>
    );
  }
}

export default connect(
  (state, { route }) => ({
    user: state.userInfo,
    discountDetails: state.subscriptions.discounts,
    item: lotsSelectors.getItemInfoById(state, route.params.itemId),
    wish: lotsSelectors.getWishInfoByItemId(state, route.params.itemId),
    isUserAdmin: communitySelectors.getIsUserAdmin(state),
    offer: offersSelectors.getBuyingOfferByItemId(state, route.params.itemId),
    isLoadingOffer: state.offers.isLoadingOffer,
    userPickupLocations: getUserPickupLocations(state),
  }),
  (dispatch) => ({
    getActiveOfferByItemId: (itemId) => dispatch(offersOperations.getActiveOfferByItemId(itemId)),
    openItemChat: (item) => dispatch(chatsOperations.openItemChat(item)),
    openPersonalChat: (user, initialMessage) =>
      dispatch(chatsOperations.openPersonalChat(user, initialMessage)),
    createOffer: (itemId) => dispatch(offersOperations.createOffer(itemId)),
    performAction: (offerId, actionId) =>
      dispatch(offersOperations.performAction(offerId, actionId)),
    removeItem: ({ item_id, seller_id }) =>
      dispatch(lotsOperations.removeItem({ item_id, seller_id })),
    deactivateItem: ({ item_id, account_id }) =>
      dispatch(lotsOperations.deactivateItem({ item_id, account_id })),
    activateItem: ({ item_id, account_id }) =>
      dispatch(lotsOperations.activateItem({ item_id, account_id })),
    getItemDetails: (itemId) => dispatch(lotsOperations.getItemDetails(itemId)),
    activateSoldItem: (itemId) => dispatch(lotsOperations.activateSoldItem(itemId)),
    getItemInfo: (id) => dispatch(lotsOperations.getItemInfo(id)),
    applyDiscount: (itemId, discountRate) =>
      dispatch(subscriptionsOperations.applyDiscount(itemId, discountRate)),
    addItemToFavorites: (itemId) => dispatch(lotsOperations.addItemToFavorites(itemId)),
    removeItemFromFavorites: (itemId) => dispatch(lotsOperations.removeItemFromFavorites(itemId)),
    unmarkItemAsTop: (itemId) => dispatch(lotsOperations.unmarkItemAsTop(itemId)),
    markItemAsTop: (itemId) => dispatch(lotsOperations.markItemAsTop(itemId)),
  }),
)(ItemDetails);
