import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { View, Text, BackButton, FastImage } from '../../ReusableComponents';
import { colors, dimensions, rs } from '../../../styles';
import { chatsOperations } from '../../../store/chats';
import LinearGradient from 'react-native-linear-gradient';

const s = StyleSheet.create({
  linearGradient: {
    justifyContent: 'center',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    paddingTop: dimensions.doubleMedium,
    paddingHorizontal: dimensions.doubleMedium,
  },
  image: {
    marginTop: dimensions.doubleMedium,
    marginBottom: dimensions.doubleMedium,
    width: 260,
    height: 260,
  },
});

const AchievedRewardDetails = ({ route }) => {
  const {
    subtitle,
    unachieved_description,
    icon_url,
    achieved_icon_url,
    is_reached,
  } = route.params.reward;

  return (
    <LinearGradient colors={[colors.white, colors.white]} style={rs.flex}>
      <SafeAreaView edges={['top']} />

      <BackButton isClose />
      <View style={s.contentContainer}>
        <Text title bold alignCenter color={colors.gray}>
          {subtitle.toUpperCase()}
        </Text>

        <FastImage
          style={s.image}
          resizeMode={'contain'}
          priority={FastImage.priority.high}
          source={{ uri: is_reached ? achieved_icon_url : icon_url }}
        />

        {!!unachieved_description && (
          <Text large bold alignCenter color={colors.gray} style={rs.paddingTop}>
            {unachieved_description}
          </Text>
        )}
      </View>
    </LinearGradient>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    openPersonalChat: (user) => dispatch(chatsOperations.openPersonalChat(user)),
  }),
)(AchievedRewardDetails);
