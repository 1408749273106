import React, { Fragment, memo } from 'react';
import { StyleSheet, View } from 'react-native';
import T from 'prop-types';
import { FontIcon, Input, Text } from '../../../../ReusableComponents';
import { colors, rs, dimensions } from '../../../../../styles';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    paddingHorizontal: dimensions.medium,
    paddingVertical: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  input: { width: '84%' },
});

const TitleInput = memo(({ value, placeholder, onChangeText }) => (
  <View style={s.contentContainer}>
    <Input
      maxLength={30}
      style={s.input}
      onChangeText={onChangeText}
      value={value}
      placeholder={placeholder}
      underlineColorAndroid="transparent"
    />
    <Text xsmall color={colors.gray}>{value.length}/30</Text>
  </View>
));

TitleInput.propTypes = {
  value: T.string,
  placeholder: T.string,
  onChangeText: T.func,
};

export default TitleInput;
