import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image, KeyboardAvoidingView, ScrollView, LayoutAnimation } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { View, Text, Button, InputWithTitleAndValidation } from '../../../ReusableComponents';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import { userOperations } from '../../../../store/userInfo';
import strings from '../../../../localization';
import { isIos } from '../../../../utils/detectDevice';
import { rs, colors } from '../../../../styles';
import s from './styles';

const RequiredReferralCode = () => {
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const isReferralRequired = useSelector((state) => state?.userInfo?.isReferralRequired);
  const rewardAmount = useSelector((state) => state?.userInfo?.welcome_reward_amount ?? 0);

  useEffect(() => {
    return () => {
      LayoutAnimation.easeInEaseOut();
    };
  }, []);

  useEffect(() => {
    if (!isReferralRequired) {
      NavigationService.reset(screens.AuthorizedApplication, {
        screen: screens.WelcomeScreen,
        params: { rewardAmount },
      });
    }
  }, [isReferralRequired]);

  const onSubmit = () => {
    dispatch(userOperations.applyReferralCode(code, true));
    setCode('');
  };

  return (
    <View style={[rs.flex, rs.backgroundWhite]}>
      <SafeAreaView />
      <KeyboardAvoidingView
        behavior={isIos ? 'padding' : null}
        style={s.keyboardAvoidingView}
        keyboardVerticalOffset={isIos ? 20 : 0}
      >
        <ScrollView>
          <Image style={s.image} source={{ uri: 'promo_code' }} resizeMode="contain" />

          <InputWithTitleAndValidation
            inputStyle={s.input}
            inputContainerStyle={s.inputContainer}
            underlineColorAndroid="transparent"
            autoFocus
            autoCapitalize={'characters'}
            maxLength={16}
            placeholder={strings.referral_gate.placeholder}
            onChangeText={setCode}
            value={code}
          />

          <Text style={s.infoText} color={colors.gray}>
            {strings.referral_gate.description}
          </Text>
        </ScrollView>

        <View style={s.buttonContainer}>
          <Button title={strings.common.submit} onPress={onSubmit} disabled={!code} />
        </View>
      </KeyboardAvoidingView>
    </View>
  );
};

export default RequiredReferralCode;
