import React from 'react';
import { StyleSheet } from 'react-native';
import T from 'prop-types';
import { Pagination } from 'react-native-snap-carousel';
import { colors, dimensions } from '../../styles';

const s = StyleSheet.create({
  paginationDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginHorizontal: 0,
    backgroundColor: colors.blueLighter,
  },
  paginationInactiveDot: {
    backgroundColor: colors.gray,
  },
  paginationContainer: {
    paddingVertical: dimensions.medium,
  },
});

const CarouselPagination = ({ activeSlideIndex, dotsLength }) => (
  <Pagination
    dotsLength={dotsLength}
    activeDotIndex={activeSlideIndex}
    dotStyle={s.paginationDot}
    inactiveDotStyle={s.paginationInactiveDot}
    containerStyle={s.paginationContainer}
    inactiveDotOpacity={0.4}
    inactiveDotScale={0.6}
  />
);

CarouselPagination.propTypes = {
  activeSlideIndex: T.number,
  dotsLength: T.number,
};

export default CarouselPagination;
